import React, { useEffect } from 'react';
import { getAnalytics } from 'firebase/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { fetchBrokerData, getBrokerData } from 'src/store/broker';
import { getContinueRouteSelector, getUserType, getUserTypeSelector } from 'src/store/app';
import {
  fetchRepAccountData,
  getIsFetchRepDataErrorSelector,
  getIssuerDataSelector,
  getIssuerTokenSelector,
  setIsFetchRepDataError,
} from 'src/store/issuer';
import {
  agencyTeamIdSelector,
  brokerAgencySelector,
  getAgencyUserRole,
  getBrokerAgency,
} from 'src/store/agency';

import { app, auth } from './database';

import { ERROR } from 'src/constants/errorNames';

import { Home } from 'src/features/pages/home/Home';
import { Login } from 'src/features/pages/login/Login';
import { Signup } from 'src/features/pages/signup/Signup';
import { ForgotPassword } from 'src/features/pages/forgotPassword/ForgotPassword';
import { BrokerAccounts } from 'src/features/broker/brokerAccounts/BrokerAccounts';
import { IssuerRequests } from 'src/features/issuer/issuerRequests/IssuerRequests';
import { BrokerProposals } from 'src/features/broker/brokerProposals/BrokerProposals';
import { ManageAccount } from 'src/features/broker/manageAccount/ManageAccount';
import { AccountView } from 'src/features/broker/accountView/AccountView';
import { Settings } from 'src/features/broker/settings/Settings';
import { ProposalBuilder } from 'src/features/proposal/proposalBuilder/ProposalBuilder';
import { Verification } from 'src/features/pages/verification/Verification';
import { EmailVerification } from 'src/features/pages/emailVerification/EmailVerification';
import { NotAuthorized } from 'src/features/pages/notAuthorized/NotAuthorized';
import { IssuerSettings } from 'src/features/issuer/issuerSettings/IssuerSettings';
import { IssuerMyRequests } from 'src/features/issuer/issuerMyRequests/IssuerMyRequests';
// import { IssuerCompanyRequests } from 'src/features/issuerCompanyRequests/IssuerCompanyRequests';
import { Contacts } from 'src/features/broker/contacts/Contacts';
import { BrowseBrokers } from 'src/features/issuer/browseBrokers/BrowseBrokers';
import { AccountInfo } from 'src/features/proposal/accountInfo/AccountInfo';
import { ProposalSetup } from 'src/features/proposal/proposalSetup/ProposalSetup';
import { ProposalCensus } from 'src/features/proposal/proposalCensus/ProposalCensus';
import { ProposalQuotes } from 'src/features/proposal/proposalQuotes/ProposalQuotes';
import { ProposalFinalize } from 'src/features/proposal/proposalFinalize/ProposalFinalize';

import { CreateAgency } from 'src/features/agency/createAgency/CreateAgency';
import { AgencyAccounts } from 'src/features/agency/agencyAccounts/AgencyAccounts';
import { AgencyProposals } from 'src/features/agency/agencyProposals/AgencyProposals';
import { AgencyReporting } from 'src/features/agency/agencyReporting/AgencyReporting';
import { AgencyContacts } from 'src/features/agency/agencyContacts/AgencyContacts';

import { AdminLogin } from 'src/features/admin/adminLogin/AdminLogin';
import { AdminDashboard } from 'src/features/admin/adminDashboard/AdminDashboard';
import { AdminImpersonate } from 'src/features/admin/adminImpersonate/AdminImpersonate';

import { Terms, Privacy, Pricing, AboutCompany, BenefitsQuoting } from 'src/features/pages';

import { IssuerProposalAccountInfo } from 'src/features/issuer/issuerProposalAccountInfo/IssuerProposalAccountInfo';
import { IssuerProposalCensus } from 'src/features/issuer/issuerProposalCensus/IssuerProposalCensus';
import { IssuerProposalQuotes } from 'src/features/issuer/issuerProposalQuotes/IssuerProposalQuotes';
import { IssuerProposalFinalize } from 'src/features/issuer/issuerProposalFinalize/IssuerProposalFinalize';
import { SpringForward } from 'src/features/pages/springForward/SpringForward';
import { AdminRepImpersonate } from 'src/features/admin/adminRepImpersonate/AdminRepImpersonate';

import { ProtectedRoute } from 'src/hoc/protectedRoute';
import { ProtectedAdminRoute } from 'src/hoc/protectedAdminRoute';
import { ProtectedBrokerRoute } from 'src/hoc/protectedBrokerRoute';
import { ProtectedRepRoute } from 'src/hoc/protectedRepRoute';

import { ExportFullProposalPDF, ExportMemberWorksheetPDF } from 'src/features/exportPDF';

import { ROUTE } from 'src/constants/routes';
import { Layout, LayoutMain } from 'src/components';

import classes from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

import 'swiper/css';
import { ProposalChat } from 'src/features/proposal/proposalChat/ProposalChat';

getAnalytics(app);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [user] = useAuthState(auth);

  const brokerData = useSelector(getBrokerData);
  const agencyData = useSelector(brokerAgencySelector);
  const issuerData = useSelector(getIssuerDataSelector);
  const issuerToken = useSelector(getIssuerTokenSelector);
  const userType = useSelector(getUserTypeSelector);
  const route = useSelector(getContinueRouteSelector);
  const agencyTeamId = useSelector(agencyTeamIdSelector);
  const isFetchRepDataError = useSelector(getIsFetchRepDataErrorSelector);

  useEffect(() => {
    if (!_.isEmpty(user) && !userType) {
      dispatch(getUserType());
    }
  }, [dispatch, user, userType]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (!brokerData?.id && user && userType === 'broker' && !isFetchRepDataError) {
        dispatch(fetchBrokerData());
      }
    }
  }, [dispatch, brokerData, user, userType, agencyTeamId, isFetchRepDataError]);

  useEffect(() => {
    if (!_.isEmpty(brokerData) && !_.isEmpty(user)) {
      if (brokerData?.id !== user?.uid) {
        dispatch(fetchBrokerData(user?.uid));
      }
    }
  }, [dispatch, brokerData, user]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (brokerData?.id && agencyTeamId) {
        dispatch(getBrokerAgency(agencyTeamId));
        dispatch(getAgencyUserRole(agencyTeamId));
      }
    }
  }, [dispatch, brokerData, user, userType, agencyTeamId]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (_.isEmpty(issuerData) && userType === 'rep') {
        dispatch(fetchRepAccountData({ navigate, issuerToken }));
      }
    }
  }, [dispatch, navigate, issuerData, user, userType, issuerToken]);

  useEffect(() => {
    if (pathname.includes('/teams') && !_.isEmpty(agencyData) && agencyData.status === 'inactive') {
      navigate(`/teams/${agencyData.id}/settings?activeTab=billing`);
    }
  }, [navigate, pathname, agencyData]);

  useEffect(() => {
    if (isFetchRepDataError) {
      dispatch(setIsFetchRepDataError(false));
      toast(ERROR.SYSTEM_ERROR, {
        type: 'warning',
      });
    }
  }, [dispatch, isFetchRepDataError]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (!user?.emailVerified && pathname !== '/verification') {
        navigate(route ? route : ROUTE.EMAIL_VERIFICATION);
      }
    }
  }, [navigate, pathname, user, route]);

  return (
    <div className={classes.App}>
      <Sentry.ErrorBoundary>
        <ScrollToTop />
        <Routes>
          <Route element={<LayoutMain />}>
            <Route path={ROUTE.DEFAULT} element={<Home />} />
            <Route path={ROUTE.TERMS} element={<Terms />} />
            <Route path={ROUTE.PRIVACY} element={<Privacy />} />
            <Route path={ROUTE.PRICING} element={<Pricing />} />
            <Route path={ROUTE.ABOUT} element={<AboutCompany />} />
            <Route path={ROUTE.BENEFITSQUOTING} element={<BenefitsQuoting />} />
          </Route>

          <Route element={<LayoutMain withComponents={false} />}>
            {/* BROKER */}
            <Route path={ROUTE.LOGIN} element={<Login />} />
            <Route path={ROUTE.SIGNUP} element={<Signup />} />
            <Route path={ROUTE.FORGOT_PASSWORD} element={<ForgotPassword />} />

            {/* ISSUER */}
            <Route path={ROUTE.ISSUER_LOGIN} element={<Login />} />
            <Route path={ROUTE.ISSUER_SIGNUP} element={<Signup />} />
            <Route path={ROUTE.ISSUER_FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTE.VERIFICATION} element={<Verification />} />
          </Route>

          <Route path={ROUTE.EMAIL_VERIFICATION} element={<ProtectedRoute />}>
            <Route path={ROUTE.EMAIL_VERIFICATION} element={<EmailVerification />} />
          </Route>

          <Route path={ROUTE.NOT_AUTHORIZED} element={<NotAuthorized />} />

          {/* ADMIN */}
          <Route path={ROUTE.ADMIN_LOGIN} element={<AdminLogin />} />
          <Route element={<Layout />}>
            <Route element={<ProtectedAdminRoute />}>
              <Route path={ROUTE.ADMIN_DASHBOARD} element={<AdminDashboard />} />
              <Route path={ROUTE.ADMIN_IMPERSONATE} element={<AdminImpersonate />} />
              <Route path={ROUTE.ADMIN_REP_IMPERSONATE} element={<AdminRepImpersonate />} />
            </Route>
          </Route>

          {/* BROKER / BROKER_AGENCY */}
          <Route element={<Layout />}>
            <Route element={<ProtectedBrokerRoute />}>
              {/* BROKER */}
              <Route path={ROUTE.BROKER} element={<BrokerAccounts />} />
              <Route path={ROUTE.PROPOSALS} element={<BrokerProposals />} />
              <Route path={ROUTE.BROKER_SETTIGNS} element={<Settings />} />
              <Route path={ROUTE.BROKER_CREATE} element={<ManageAccount />} />
              <Route path={ROUTE.BROKER_EDIT_ACCOUNT} element={<ManageAccount />} />
              <Route path={ROUTE.BROKER_ACCOUNT_VIEW} element={<AccountView />} />
              <Route path={ROUTE.BROKER_CREATE_PROPOSAL} element={<ProposalSetup />} />
              <Route path={ROUTE.BROKER_PROPOSAL_CENSUS_NEW} element={<ProposalBuilder />} />
              <Route path={ROUTE.BROKER_PROPOSAL_ACCOUNT_INFO} element={<AccountInfo />} />
              <Route path={ROUTE.BROKER_PROPOSAL_SETUP} element={<ProposalSetup />} />
              <Route path={ROUTE.BROKER_PROPOSAL_CENSUS} element={<ProposalCensus />} />
              <Route path={ROUTE.BROKER_PROPOSAL_QUOTES} element={<ProposalQuotes />} />
              <Route path={ROUTE.BROKER_PROPOSAL_FINALIZE} element={<ProposalFinalize />} />
              <Route path={ROUTE.CONTACTS} element={<Contacts />} />

              {/* <Route path={ROUTE.BROKER_PROPOSAL_DOWNLOADS} element={<TestPage />} /> */}

              {/* BROKER_AGENCY */}
              <Route path={ROUTE.CREATE_AGENCY} element={<CreateAgency />} />
              <Route path={ROUTE.AGENCY_ACCOUNTS} element={<AgencyAccounts />} />
              <Route path={ROUTE.CREATE_AGENCY_ACCOUNT} element={<ManageAccount />} />
              <Route path={ROUTE.EDIT_AGENCY_ACCOUNT} element={<ManageAccount />} />
              <Route path={ROUTE.AGENCY_ACCOUNT_VIEW} element={<AccountView />} />
              <Route path={ROUTE.AGENCY_PROPOSALS} element={<AgencyProposals />} />
              <Route path={ROUTE.AGENCY_CREATE_PROPOSAL} element={<ProposalSetup />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_CENSUS_NEW} element={<ProposalBuilder />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_ACCOUNT_INFO} element={<AccountInfo />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_SETUP} element={<ProposalSetup />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_CENSUS} element={<ProposalCensus />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_QUOTES} element={<ProposalQuotes />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_FINALIZE} element={<ProposalFinalize />} />
              <Route path={ROUTE.AGENCY_PROPOSAL_CHAT} element={<ProposalChat />} />
              <Route path={ROUTE.AGENCY_REPORTING} element={<AgencyReporting />} />
              <Route path={ROUTE.AGENCY_CONTACTS} element={<AgencyContacts />} />
              <Route path={ROUTE.AGENCY_SETTINGS} element={<Settings />} />
            </Route>
          </Route>

          <Route element={<ProtectedBrokerRoute />}>
            <Route path={ROUTE.BROKER_PROPOSAL_DOWNLOADS} element={<ExportFullProposalPDF />} />
            <Route path={ROUTE.BROKER_WORKSHEET_DOWNLOADS} element={<ExportMemberWorksheetPDF />} />
          </Route>

          {/* ISSUER */}
          <Route path={ROUTE.ISSUER_REQUESTS} element={<IssuerRequests />} />
          <Route element={<Layout />}>
            <Route element={<ProtectedRepRoute />}>
              <Route path={ROUTE.ISSUER_SETTINGS} element={<IssuerSettings />} />
              <Route path={ROUTE.REP_MY_RFPS} element={<IssuerMyRequests />} />
              <Route path={ROUTE.REP_BROWSE_BROKERS} element={<BrowseBrokers />} />
              <Route
                path={ROUTE.REP_PROPOSAL_ACCOUNT_INFO}
                element={<IssuerProposalAccountInfo />}
              />
              <Route path={ROUTE.REP_PROPOSAL_CENSUS} element={<IssuerProposalCensus />} />
              <Route path={ROUTE.REP_PROPOSAL_QUOTES} element={<IssuerProposalQuotes />} />
              <Route path={ROUTE.REP_PROPOSAL_CHAT} element={<ProposalChat />} />
              <Route path={ROUTE.REP_PROPOSAL_FINALIZE} element={<IssuerProposalFinalize />} />
            </Route>
          </Route>

          <Route element={<Layout />}>
            <Route path={ROUTE.READ_ONLY} element={<ProposalFinalize />} />
            <Route path={ROUTE.READ_ONLY_CHAT} element={<ProposalChat />} />
          </Route>

          <Route path="/springforward" element={<SpringForward />} />
          <Route path="*" element={<Navigate to={ROUTE.DEFAULT} />} />
        </Routes>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default App;
