import { createSlice } from '@reduxjs/toolkit';
import {
  createCoveragePlan,
  createCoverageTemplate,
  createMultipleCoveragePlans,
  deleteCoveragePlan,
  getCoveragePlans,
  getCoverageQuotePlans,
  getCoverageTemplates,
  updateCoveragePlan,
} from './coverageBuilder.actions';

const initialState = {
  coverage: {
    medical_proposals: [],
    medical_quotes: [],
    medical_templates: [],

    dental_proposals: [],
    dental_quotes: [],
    dental_templates: [],

    vision_proposals: [],
    vision_quotes: [],
    vision_templates: [],

    life_proposals: [],
    life_quotes: [],
    life_templates: [],

    disability_proposals: [],
    disability_quotes: [],
    disability_templates: [],

    accident_proposals: [],
    accident_quotes: [],
    accident_templates: [],

    hospital_proposals: [],
    hospital_quotes: [],
    hospital_templates: [],

    critical_illness_proposals: [],
    critical_illness_quotes: [],
    critical_illness_templates: [],

    templates_meta: {},
  },

  isLoadingCoverage: false,
  isLoadingCreatePlan: false,
  isLoadingDeletePlan: false,
  isLoadingCreateTemplate: false,
  isLoadingTemplate: false,
};

const coverageBuilderSlice = createSlice({
  name: 'coverageBuilder',
  initialState,
  reducers: {
    clearCoveragesBuilderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createCoveragePlan.pending, (state) => {
      state.isLoadingCreatePlan = true;
    });
    builder.addCase(createCoveragePlan.fulfilled, (state) => {
      state.isLoadingCreatePlan = false;
    });
    builder.addCase(createCoveragePlan.rejected, (state) => {
      state.isLoadingCreatePlan = false;
    });

    builder.addCase(createMultipleCoveragePlans.pending, (state) => {
      state.isLoadingCreatePlan = true;
    });
    builder.addCase(createMultipleCoveragePlans.fulfilled, (state) => {
      state.isLoadingCreatePlan = false;
    });
    builder.addCase(createMultipleCoveragePlans.rejected, (state) => {
      state.isLoadingCreatePlan = false;
    });

    builder.addCase(getCoveragePlans.pending, (state) => {
      state.isLoadingCoverage = true;
    });
    builder.addCase(getCoveragePlans.fulfilled, (state, action) => {
      const { coverage, response } = action.payload;

      state.coverage[`${coverage}_proposals`] = response;
      state.isLoadingCoverage = false;
    });
    builder.addCase(getCoveragePlans.rejected, (state) => {
      state.isLoadingCoverage = false;
    });

    builder.addCase(updateCoveragePlan.pending, (state) => {
      state.isLoadingCreatePlan = true;
    });
    builder.addCase(updateCoveragePlan.fulfilled, (state) => {
      state.isLoadingCreatePlan = false;
    });
    builder.addCase(updateCoveragePlan.rejected, (state) => {
      state.isLoadingCreatePlan = false;
    });

    builder.addCase(deleteCoveragePlan.pending, (state) => {
      state.isLoadingDeletePlan = true;
    });
    builder.addCase(deleteCoveragePlan.fulfilled, (state) => {
      state.isLoadingDeletePlan = false;
    });
    builder.addCase(deleteCoveragePlan.rejected, (state) => {
      state.isLoadingDeletePlan = false;
    });

    builder.addCase(createCoverageTemplate.pending, (state) => {
      state.isLoadingCreateTemplate = true;
    });
    builder.addCase(createCoverageTemplate.fulfilled, (state) => {
      state.isLoadingCreateTemplate = false;
    });
    builder.addCase(createCoverageTemplate.rejected, (state) => {
      state.isLoadingCreateTemplate = false;
    });

    builder.addCase(getCoverageTemplates.pending, (state) => {
      state.isLoadingTemplate = true;
    });
    builder.addCase(getCoverageTemplates.fulfilled, (state, action) => {
      const { coverage, response } = action.payload;

      state.coverage[`${coverage}_templates`] = response.items;
      state.coverage['templates_meta'] = response.meta;
      state.isLoadingTemplate = false;
    });
    builder.addCase(getCoverageTemplates.rejected, (state) => {
      state.isLoadingTemplate = false;
    });

    builder.addCase(getCoverageQuotePlans.pending, (state) => {
      state.isLoadingCoverage = true;
    });
    builder.addCase(getCoverageQuotePlans.fulfilled, (state, action) => {
      const { coverage, response } = action.payload;

      state.coverage[`${coverage}_quotes`] = response;
      state.isLoadingCoverage = false;
    });
    builder.addCase(getCoverageQuotePlans.rejected, (state) => {
      state.isLoadingCoverage = false;
    });
  },
});

export const { clearCoveragesBuilderState } = coverageBuilderSlice.actions;

export default coverageBuilderSlice.reducer;
