const spanStyles = {
  height: '50px',
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid #dbdbdb',
};

const divStyles = {
  width: '100%',
  padding: '15px 10px',
};

export const renderUniTobaccoRates = (rates, UNI_NAMES) => {
  return Object.keys(rates).map((item, index) => (
    <span key={index} style={spanStyles}>
      <div style={divStyles}>{`$${rates[item] || '0'} (${UNI_NAMES[item]})`}</div>
    </span>
  ));
};

export const renderTobaccoRates = (tobaccoRates, nonTobaccoRates, TOBACCO_NAMES) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        {Object.keys(tobaccoRates).map((item, index) => (
          <span key={index} style={spanStyles}>
            <div style={{ ...divStyles, paddingRight: 0 }}>
              {`$${tobaccoRates[item] || '0'}`} <span>/</span>
            </div>
          </span>
        ))}
      </div>
      <div style={{ flex: '1' }}>
        {Object.keys(nonTobaccoRates).map((item, index) => (
          <span key={index} style={spanStyles}>
            <div style={divStyles}>{`$${nonTobaccoRates[item] || '0'} (${TOBACCO_NAMES[
              item
            ].replace(' Non-Tobacco', '')})`}</div>
          </span>
        ))}
      </div>
    </div>
  );
};

export const renderTierUniRates = (config) => {
  const { rate_ee_uni_tobacco, rate_sp_uni_tobacco, rate_ch_uni_tobacco, rate_fam_uni_tobacco } =
    config;

  return (
    <>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_ee_uni_tobacco || 0} (Employee)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_sp_uni_tobacco || 0} (Spouse)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_ch_uni_tobacco || 0} (Child)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_fam_uni_tobacco || 0} (Family)`}</div>
      </span>
    </>
  );
};

export const renderTierTobaccoRates = (config) => {
  const {
    rate_ee_tobacco,
    rate_ee_non_tobacco,
    rate_sp_tobacco,
    rate_sp_non_tobacco,
    rate_ch_tobacco,
    rate_ch_non_tobacco,
    rate_fam_tobacco,
    rate_fam_non_tobacco,
  } = config;

  return (
    <>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_ee_tobacco || 0} / $${
          rate_ee_non_tobacco || 0
        } (Employee)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_sp_tobacco || 0} / $${
          rate_sp_non_tobacco || 0
        } (Spouse)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_ch_tobacco || 0} / $${
          rate_ch_non_tobacco || 0
        } (Child)`}</div>
      </span>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_fam_tobacco || 0} / $${
          rate_fam_non_tobacco || 0
        } (Family)`}</div>
      </span>
    </>
  );
};
