import Base from './base';

class CritialConfiguration extends Base {
  getCriticalPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/critical_illness/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getCriticalQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/critical_illness/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };
}

export default CritialConfiguration;
