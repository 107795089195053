import React from 'react';

import classes from './errorMessage.module.scss';

export const ErrorMessage = (props) => {
  const { onClose, title, children } = props;

  return (
    <div className={classes.ErrorMessage}>
      <div className={classes.ErrorMessageHeader}>
        <div className={classes.ErrorMessageTitle}>{title}</div>
        <div className={classes.ErrorMessageCloseIcon} onClick={onClose}>
          <i className="fas fa-times" />
        </div>
      </div>
      <div className={classes.ErrorMessageContent}>{children}</div>
    </div>
  );
};
