export const formatPhoneNumber = (number) => {
  const phoneNumber = number.toString();

  const countryCode = phoneNumber.slice(0, 1);
  const areaCode = phoneNumber.slice(1, 4);
  const firstPart = phoneNumber.slice(4, 7);
  const secondPart = phoneNumber.slice(7);

  return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
};
