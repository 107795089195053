import React, { useEffect } from 'react';

import { Main, Carriers, Plans, About } from './sections';
import { BenefitsQuoting } from '../benefitsQuoting/BenefitsQuoting';

export const Home = () => {
  useEffect(() => {
    document.title = 'Monark Benefits Quoting Platform';
  }, []);

  return (
    <>
      <Main />
      <Carriers />
      <Plans />
      <About />
      <BenefitsQuoting />
    </>
  );
};
