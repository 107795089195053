import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  getBrokerAccountData,
  getAccountData,
  setAccountMode,
  deleteBrokerAccount,
  setIsDeleteAccountModal,
  getIsDeleteAccountModal,
  getIsLoadingBrokerAccountData,
  getBrokerData,
  setAccountData,
} from 'src/store/broker';

import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { ROUTE } from 'src/constants/routes';

import {
  Button,
  ProposalTable,
  Modal,
  Loader,
  AccountDetails,
  LoaderWrapper,
} from 'src/components';

import classes from './accountView.module.scss';

export const AccountView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params?.id;
  const teamId = params?.teamId;

  const accountData = useSelector(getAccountData);
  const brokerData = useSelector(getBrokerData);
  const isDeleteAccountModal = useSelector(getIsDeleteAccountModal);
  const isLoadingAccount = useSelector(getIsLoadingBrokerAccountData);

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBrokerAccountData({ paramsId, navigate }));
  }, [dispatch, navigate, paramsId, brokerData]);

  useEffect(() => {
    if (accountData?.company_name) {
      document.title = `${accountData?.company_name} | Monark`;
    }
  }, [accountData]);

  const onClickEdit = useCallback(() => {
    dispatch(setAccountMode('EDIT'));
    if (!teamId) {
      navigate(`${ROUTE.BROKER}/edit/${paramsId}`);
    }

    if (teamId) {
      navigate(`/teams/${teamId}/accounts/edit/${paramsId}`);
    }
  }, [dispatch, navigate, teamId, paramsId]);

  const onOpenDeleteAccountModal = useCallback(() => {
    dispatch(setIsDeleteAccountModal(true));
  }, [dispatch]);

  const onCloseDeleteAccountModal = useCallback(() => {
    dispatch(setIsDeleteAccountModal(false));
  }, [dispatch]);

  const onDeleteBrokerAccount = useCallback(() => {
    const payload = {
      accountId: paramsId,
      navigate,
    };
    dispatch(deleteBrokerAccount(payload));
  }, [dispatch, navigate, paramsId]);

  const onClickBackButton = useCallback(() => {
    if (!teamId) {
      navigate(`${ROUTE.BROKER}`);
    }

    if (teamId) {
      navigate(`/teams/${teamId}/accounts`);
    }
  }, [navigate, teamId]);

  return (
    <>
      {isDeleteAccountModal && (
        <Modal closeButtonType="inside" onClose={onCloseDeleteAccountModal}>
          Are you sure you want to delete this account <strong>{accountData?.company_name}</strong>?
          <div className={classes.DeleteModalContent}>
            <Button title="Yes, I'm sure" type="danger" onClick={onDeleteBrokerAccount} />
            <Button title="Cancel" type="secondary" onClick={onCloseDeleteAccountModal} />
          </div>
        </Modal>
      )}
      {isLoadingAccount ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <div className={classes.AccountView}>
          <AccountDetails
            previewImage={accountData?.logo_url}
            companyName={accountData?.company_name}
            addressStreet={accountData?.address_street}
            addressCity={accountData?.address_city}
            addressState={accountData?.address_state}
            addressZip={accountData?.address_zip_code}
            contactName={accountData?.contact_name}
            contactEmail={accountData?.contact_email}
            contactPhone={accountData?.contact_phone}
            fipsCode={accountData?.fips_code}
            effectiveDate={moment(accountData?.renewal_date).format('l')}
            brokerName={accountData?.broker?.display_name}
            brokerEmail={accountData?.broker?.email}
            brokerPhone={accountData?.broker?.phone_number}
            brokerData={accountData?.broker}
            sicCode={accountData?.sic_code}
            ratingArea={accountData?.rating_area_id}
            onChangeAccountImage={onClickEdit}
            onEditAccount={onClickEdit}
            onClickBackButton={onClickBackButton}
            onDeleteAccount={onOpenDeleteAccountModal}
          />
          <div className={classes.AccountViewContent}>
            <ProposalTable />
          </div>
        </div>
      )}
    </>
  );
};
