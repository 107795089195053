import { currencyFormatter } from 'src/constants/currency';
import { styles } from '../styles';
import { Text, View } from '@react-pdf/renderer';
// import { renderRow } from './renderRow';

const renderRow = (item, value) => {
  return (
    <View key={item.id}>
      <Text style={styles.tableCell}>{value}</Text>
    </View>
  );
};

export const renderSingleRow = (value_1, value_2) => {
  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={[styles.tableCell, styles.tableTitle]}>{value_1}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={[styles.tableCell, styles.tableTitle]}>{value_2}</Text>
      </View>
    </View>
  );
};

export const renderWorksheetRow = (groupMember, item, property, type = 'default') => {
  // return groupMembers?.map((groupMember) => {
  const memeberFullname = `${groupMember?.first_name} ${groupMember?.last_name}`;

  const calculatedRate =
    type === 'employee'
      ? item.calculation[groupMember.family_id]?.rate || item.calculation[groupMember.id]
      : item.calculation[groupMember.family_id]?.find(
          (calculatedMember) => calculatedMember.id === groupMember.id,
        )[property] || item.calculation[groupMember.id];

  if (type === 'employee') {
    return renderRow(
      memeberFullname,
      groupMember?.relationship === 'employee' ? currencyFormatter(calculatedRate) : '--',
    );
  }

  return renderRow(memeberFullname, currencyFormatter(calculatedRate));
  // });
};

// export const renderTableValue = (item, index, section, value) => {
//   return (
//     <View
//       style={[styles.tableCol, index === section.length - 1 ? styles.lastTableCol : {}]}
//       key={item.id}
//     >
//       <Text style={styles.tableCell}>{value}</Text>
//     </View>
//   );
// };
