import { createSlice } from '@reduxjs/toolkit';
import {
  getProposalsList,
  getAccountProposal,
  fetchQuotesRates,
  shareProposal,
  getReadOnly,
  getChatMessages,
  getLastChatMessages,
  sendChatMessage,
  getCarrierIssuers,
  createProposal,
  getExecutiveSummaryProposals,
} from './proposal.actions';

const initialState = {
  proposalsList: [],
  proposalsMeta: {},

  accountProposal: {},
  readOnlyUser: {},
  carrierIssuers: [],
  isLoadingCarrierIssuers: false,
  quotesRates: [],
  quotesMeta: {},
  quotes: [],
  responseQuotes: [],

  executiveSummary: {},
  isLoadingExecutiveSummary: false,

  selectedQuotes: [],
  chatMessages: [],
  lastChatMessages: {},
  medicalPlanType: '',
  selectedMedicalPlans: {},
  selectedEditQuote: {},
  readOnlyPickedQuote: '',

  isLoadingCreateProposal: false,
  isLoadingProposals: false,
  isLoadingAccountProposal: false,
  isLoadingQuotesRates: false,
  isShareProposalModal: false,
  isLoadingShaisLoadingShareProposalreProposal: false,
  isSendingMessage: false,
  isLoadingMessage: false,
  isLoadingLastMessage: false,

  basePlanQuote: {},
  basePlanName: '',

  isProposalFinalize: false,
  isExpandedQuotes: true,
};

const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    setAccountProposal: (state, action) => {
      state.accountProposal = action.payload;
    },
    setProposalsMeta: (state, action) => {
      state.proposalsMeta = action.payload;
    },
    setCarrierIssuers: (state, action) => {
      state.carrierIssuers = action.payload;
    },
    setQuotesRates: (state, action) => {
      state.quotesRates = action.payload;
    },
    setQuotesMeta: (state, action) => {
      state.quotesMeta = action.payload;
    },
    setQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    setResponseQuotes: (state, action) => {
      state.responseQuotes = action.payload;
    },

    setSelectedQuotes: (state, action) => {
      state.selectedQuotes = action.payload;
    },
    setSelectedEditQuote: (state, action) => {
      state.selectedEditQuote = action.payload;
    },

    setIsShareProposalModal: (state, action) => {
      state.isShareProposalModal = action.payload;
    },

    setMedicalPlanType: (state, action) => {
      state.medicalPlanType = action.payload;
    },
    setSelectedMedicalPlans: (state, action) => {
      state.selectedMedicalPlans = action.payload;
    },

    setReadOnlyPickedQuote: (state, action) => {
      state.readOnlyPickedQuote = action.payload;
    },

    setReadOnlyUser: (state, action) => {
      state.readOnlyUser = action.payload;
    },

    setBasePlanQuote: (state, action) => {
      state.basePlanQuote = action.payload;
    },
    setBasePlanName: (state, action) => {
      state.basePlanName = action.payload;
    },

    setIsProposalFinalize: (state, action) => {
      state.isProposalFinalize = action.payload;
    },
    setIsExpandedQuotes: (state, action) => {
      state.isExpandedQuotes = action.payload;
    },

    clearProposalState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createProposal.pending, (state) => {
      state.isLoadingCreateProposal = true;
    });
    builder.addCase(createProposal.fulfilled, (state, action) => {
      state.isLoadingCreateProposal = false;
    });
    builder.addCase(createProposal.rejected, (state) => {
      state.isLoadingCreateProposal = false;
    });

    builder.addCase(getProposalsList.pending, (state) => {
      state.isLoadingProposals = true;
    });
    builder.addCase(getProposalsList.fulfilled, (state, action) => {
      state.proposalsList = action.payload;
      state.isLoadingProposals = false;
    });
    builder.addCase(getProposalsList.rejected, (state) => {
      state.isLoadingProposals = false;
    });

    builder.addCase(getCarrierIssuers.pending, (state) => {
      state.isLoadingCarrierIssuers = true;
    });
    builder.addCase(getCarrierIssuers.fulfilled, (state, action) => {
      state.carrierIssuers = action.payload;
      state.isLoadingCarrierIssuers = false;
    });
    builder.addCase(getCarrierIssuers.rejected, (state) => {
      state.isLoadingCarrierIssuers = false;
    });

    builder.addCase(getAccountProposal.pending, (state) => {
      state.isLoadingAccountProposal = true;
    });
    builder.addCase(getAccountProposal.fulfilled, (state, action) => {
      state.accountProposal = action.payload;
      state.isLoadingAccountProposal = false;
    });
    builder.addCase(getAccountProposal.rejected, (state) => {
      state.isLoadingAccountProposal = false;
    });

    builder.addCase(getReadOnly.pending, (state) => {
      state.isLoadingAccountProposal = true;
    });
    builder.addCase(getReadOnly.fulfilled, (state) => {
      state.isLoadingAccountProposal = false;
    });
    builder.addCase(getReadOnly.rejected, (state) => {
      state.isLoadingAccountProposal = false;
    });

    builder.addCase(fetchQuotesRates.pending, (state) => {
      state.isLoadingQuotesRates = true;
    });
    builder.addCase(fetchQuotesRates.fulfilled, (state) => {
      state.isLoadingQuotesRates = false;
    });
    builder.addCase(fetchQuotesRates.rejected, (state) => {
      state.isLoadingQuotesRates = false;
    });

    builder.addCase(shareProposal.pending, (state) => {
      state.isLoadingShareProposal = true;
    });
    builder.addCase(shareProposal.fulfilled, (state) => {
      state.isLoadingShareProposal = false;
      state.isShareProposalModal = false;
    });
    builder.addCase(shareProposal.rejected, (state) => {
      state.isLoadingShareProposal = false;
    });

    builder.addCase(getChatMessages.pending, (state) => {
      state.isLoadingMessage = true;
    });
    builder.addCase(getChatMessages.fulfilled, (state, action) => {
      state.chatMessages = action.payload;
      state.isLoadingMessage = false;
    });
    builder.addCase(getChatMessages.rejected, (state) => {
      state.isLoadingMessage = false;
    });

    builder.addCase(getLastChatMessages.pending, (state) => {
      state.isLoadingLastMessage = true;
    });
    builder.addCase(getLastChatMessages.fulfilled, (state, action) => {
      state.lastChatMessages = action.payload;
      state.isLoadingLastMessage = false;
    });
    builder.addCase(getLastChatMessages.rejected, (state) => {
      state.isLoadingLastMessage = false;
    });

    builder.addCase(sendChatMessage.pending, (state) => {
      state.isSendingMessage = true;
    });
    builder.addCase(sendChatMessage.fulfilled, (state) => {
      state.isSendingMessage = false;
    });
    builder.addCase(sendChatMessage.rejected, (state) => {
      state.isSendingMessage = false;
    });

    builder.addCase(getExecutiveSummaryProposals.pending, (state) => {
      state.isLoadingExecutiveSummary = true;
    });
    builder.addCase(getExecutiveSummaryProposals.fulfilled, (state, action) => {
      state.executiveSummary = action.payload;
      state.isLoadingExecutiveSummary = false;
    });
    builder.addCase(getExecutiveSummaryProposals.rejected, (state) => {
      state.isLoadingExecutiveSummary = false;
    });
  },
});

export const {
  setCarrierIssuers,
  setQuotesRates,
  setQuotesMeta,
  setQuotes,
  setAccountProposal,
  setIsShareProposalModal,
  setSelectedQuotes,
  setMedicalPlanType,
  setSelectedMedicalPlans,
  setReadOnlyPickedQuote,
  setResponseQuotes,
  setSelectedEditQuote,
  setReadOnlyUser,
  setBasePlanQuote,
  setBasePlanName,
  setProposalsMeta,
  setIsProposalFinalize,
  setIsExpandedQuotes,
  clearProposalState,
} = proposalSlice.actions;

export default proposalSlice.reducer;
