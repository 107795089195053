import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAccountData } from 'src/store/broker';
import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { BrokerContactsTable } from 'src/components';

import classes from './contacts.module.scss';

export const Contacts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Monark | Contacts';
  }, []);

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  return (
    <div className={classes.Contacts}>
      <div className={classes.AccountTableWrapper}>
        <BrokerContactsTable />
      </div>
    </div>
  );
};
