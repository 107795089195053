import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Chip, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import { getBrokerContactsSelector } from 'src/store/contacts';
import { getSharedContactsSelector } from 'src/store/planConfiguration';
import { chatRoomsSelector, startChat } from 'src/store/chat';
import { getBrokerData } from 'src/store/broker';
import { getAccountProposalSelector } from 'src/store/proposals';

import classes from './rooms.module.scss';

export const Rooms = (props) => {
  const { selectedRoom, setSelectedRoom } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const teamId = params?.teamId;

  const contacts = useSelector(getBrokerContactsSelector);
  const sharedContacts = useSelector(getSharedContactsSelector);
  const chatRooms = useSelector(chatRoomsSelector);
  const brokerData = useSelector(getBrokerData);
  const proposalData = useSelector(getAccountProposalSelector);

  const accountId = params?.id ? params?.id : proposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : proposalData?.id;

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isCreateRoom, setIsCreateRoom] = useState(false);

  const updatedContacts =
    !_.isEmpty(contacts) && !_.isEmpty(sharedContacts)
      ? contacts.filter((item) => sharedContacts[item?.rep_id]?.length)
      : [];

  const onSelectRoom = useCallback(
    (room) => () => {
      setSelectedRoom(room);
    },
    [setSelectedRoom],
  );

  const handleCreateRoom = useCallback(() => {
    setIsCreateRoom((prev) => !prev);
  }, []);

  const onSelectContact = useCallback((event) => {
    setSelectedContacts(event.target.value);
  }, []);

  const onBlurSelect = useCallback(async () => {
    const findedRoom = chatRooms?.find((item) => {
      const filteredMembers = item?.members?.filter((member) => member?.user_id !== item?.owner_id);

      const roomMembers = filteredMembers?.map((member) => member?.user_id)?.sort();
      const selectedContactsId = selectedContacts?.map((contact) => contact?.rep_id)?.sort();

      if (_.isEqual(roomMembers, selectedContactsId)) {
        return item;
      }

      return undefined;
    });

    if (findedRoom) {
      setSelectedRoom(findedRoom);
      setSelectedContacts([]);
      setIsCreateRoom(false);
      return;
    }

    // TODO: TEMPORARY SOLUTION.
    if (selectedContacts?.length) {
      const payload = {
        owner_id: brokerData?.id,
        owner_type: 'broker',
        room_type: 'carrier',
        proposal_id: proposalId,
        name: proposalData?.name,
      };

      const updatedSelectedContacts = selectedContacts?.map((item) => ({
        id: item?.rep_id,
        contact_type: 'rep',
      }));

      await dispatch(
        startChat({
          teamId,
          accountId,
          contacts: [{ id: brokerData?.id, contact_type: 'broker' }, ...updatedSelectedContacts],
          payload,
          setSelectedRoom,
        }),
      );

      setSelectedContacts([]);
      setIsCreateRoom(false);
    }
  }, [
    dispatch,
    chatRooms,
    selectedContacts,
    setSelectedRoom,
    brokerData,
    proposalId,
    proposalData,
    teamId,
    accountId,
  ]);

  return (
    <>
      {isCreateRoom && teamId && (
        <div className={classes.RoomsCreate}>
          <Select
            sx={{ width: '100%' }}
            multiple
            size="small"
            value={selectedContacts}
            onChange={onSelectContact}
            onBlur={onBlurSelect}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((item) => (
                  <Chip key={item.display_name} label={item.display_name} size="small" />
                ))}
              </Box>
            )}
          >
            {updatedContacts.map((item) => (
              <MenuItem
                key={item?.id}
                value={item}
                style={{
                  fontWeight: selectedContacts.indexOf(item.display_name) === -1 ? '500' : '800',
                }}
              >
                {item.display_name} ({item.issuer_name})
              </MenuItem>
            ))}
          </Select>
          <Button variant="contained" sx={{ ml: 1.5 }} onClick={handleCreateRoom}>
            Cancel
          </Button>
        </div>
      )}

      <div className={classes.RoomsWrapper}>
        <div className={classes.RoomsContacts}>
          {teamId && (
            <div className={classes.RoomsContactsHeader}>
              <TextField
                className={classes.RoomsContactsHeaderInput}
                size="small"
                label="Search"
                variant="outlined"
              />
              <Button variant="contained" onClick={handleCreateRoom}>
                <i className="fas fa-plus" />
              </Button>
            </div>
          )}
          {chatRooms?.map((item) => {
            const chatContactsStyles = classNames({
              [classes.RoomsContactsItem]: true,
              [classes.RoomsContactsItemSelected]: selectedRoom?.id === item.id,
            });

            const roomName = item.members.map((member) => member.name.split(' ')[0]).join(', ');

            return (
              <div
                className={chatContactsStyles}
                key={item?.id}
                onClick={selectedRoom?.id !== item?.id ? onSelectRoom(item) : () => {}}
              >
                <div className={classes.RoomsContactsItemImage}>{roomName?.slice(0, 1)}</div>
                <div className={classes.RoomsContactsItemBadge}>
                  <Chip
                    label={item.room_type}
                    size="small"
                    sx={{
                      backgroundColor: item.room_type === 'employer' ? '#7833f3' : '#00000014',
                      color: item.room_type === 'employer' ? 'white' : 'black',
                      textTransform: 'capitalize',
                    }}
                  />
                </div>
                <div className={classes.RoomsContactsItemContent}>
                  <div className={classes.RoomsContactsItemHeader}>
                    <div className={classes.RoomsContactsItemMessage}>
                      <Tooltip title={roomName}>{roomName}</Tooltip>
                    </div>
                    <div className={classes.RoomsContactsItemDate}>
                      {moment(item.updated_timestamp).format('HH:mm')}
                    </div>
                  </div>
                  <div className={classes.RoomsContactsItemMessage}>{item.last_message}</div>
                </div>
                <div className={classes.RoomsContactsItemBorder} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
