import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { storage } from 'src/app/database';

import { Loader } from '../Loader/Loader';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

import DownloadIcon from 'src/assets/dropZone/download.svg';
import CloseIcon from 'src/assets/dropZone/circleClose.svg';
import FileUploadIcon from 'src/assets/dropZone/fileUpload.svg';
import CheckIcon from 'src/assets/dropZone/checkIcon.svg';

import classes from './dropZone.module.scss';

export const DropZone = (props) => {
  const { label, path, maxFiles = 100, isDisabled } = props;

  const [files, setFiles] = useState([]);
  const [fileFullPath, setFileFullPath] = useState('');
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const onDrop = useCallback(
    async (droppedFile) => {
      setIsLoadingFiles(true);
      try {
        for (const file of droppedFile) {
          const reader = new FileReader();

          reader.onabort = () => console.warn('file reading was aborted');
          reader.onerror = () => console.warn('file reading has failed');
          reader.onload = async () => {
            let payload = {};

            const binaryStr = reader.result;

            const storageRef = storage.ref();
            const fileRef = storageRef.child(`${path}${file.path}`);

            const metaData = {
              contentType: file.type,
            };

            const response = await fileRef.put(binaryStr, metaData);
            const meta = response.metadata;

            payload.downloadUrl = await response.ref.getDownloadURL();
            payload.fullPath = meta.fullPath;
            payload.fileName = meta.name;
            payload.contentType = meta.contentType;
            payload.isNewFile = true;
            payload.id = uuid();

            setFiles((state) => [...state, payload]);

            if (!_.isEmpty(payload)) {
              setIsLoadingFiles(false);
            }
          };
          reader.readAsArrayBuffer(file);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [path],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    disabled: isDisabled,
    multiple: maxFiles === 1 ? false : true,
    accept:
      'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf',
  });

  useEffect(() => {
    if (fileRejections.length) {
      let errorNames = {};
      for (const item of fileRejections) {
        for (const error of item.errors) {
          errorNames = {
            ...errorNames,
            [error.code]: error.message,
          };
        }
      }

      for (const errorName in errorNames) {
        if (errorName === 'file-invalid-type') {
          toast('File should be one of type - CSV, XLS, XLSX or PDF', { type: 'error' });
        } else {
          toast(errorNames[errorName], { type: 'error' });
        }
      }

      setIsLoadingFiles(false);
    }
  }, [fileRejections]);

  const getDropZoneFiles = useCallback(async () => {
    const storageRef = storage.ref();
    const listRef = storageRef.child(`${path}`);
    let convertedFiles = [];

    setIsLoadingFiles(true);

    try {
      const res = await listRef.listAll();

      for (const itemRef of res.items) {
        let payload = {};

        payload.downloadUrl = await itemRef.getDownloadURL();

        const meta = await itemRef.getMetadata();

        payload.fullPath = meta.fullPath;
        payload.fileName = meta.name;
        payload.contentType = meta.contentType;
        payload.id = uuid();

        convertedFiles.push(payload);
        if (!_.isEmpty(itemRef) && maxFiles === 1) {
          setFiles([payload]);
        }

        if (!_.isEmpty(itemRef) && maxFiles !== 1) {
          setFiles([...convertedFiles]);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoadingFiles(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, maxFiles, isLoadingFiles]);

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (isMounted) {
      getDropZoneFiles();
    }

    return () => {
      setIsMounted(false);
    };
  }, [getDropZoneFiles, files, isMounted]);

  const onOpenOpenDeleteModal = useCallback(
    (fullPath) => () => {
      setFileFullPath(fullPath);
      setIsDeleteModal(true);
    },
    [],
  );

  const onCloseDeleteModal = useCallback(() => {
    setFileFullPath('');
    setIsDeleteModal(false);
  }, []);

  const onRemoveFile = useCallback(() => {
    const storageRef = storage.ref();
    storageRef.child(fileFullPath).delete();
    setFiles(_.reject(files, (el) => el.fullPath === fileFullPath));
    setIsDeleteModal(false);
  }, [files, fileFullPath]);

  const onDownloadFile = useCallback(
    (fullPath) => () => {
      const { downloadUrl, fileName } = files.find((item) => item.fullPath === fullPath);

      saveAs(downloadUrl, fileName);
    },
    [files],
  );

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this file?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onRemoveFile} />
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.DropZoneWrapper}>
        {label && <div className={classes.DropZoneLabel}>{label}</div>}
        <section className={!isDisabled ? classes.DropZone : classes.DropZoneDisabled}>
          {isLoadingFiles ? (
            <div className={classes.DropZoneArea}>
              <Loader size="small" />
            </div>
          ) : (
            <>
              {files.length > 0 && (
                <aside
                  className={
                    maxFiles === 1 ? classes.DropZoneContentSingle : classes.DropZoneContent
                  }
                >
                  {files.map((file) => (
                    <div className={classes.DropZoneContentItem} key={file.fullPath}>
                      <div
                        className={file.isNewFile && classes.DropZoneContentItemWrapper}
                        onClick={onDownloadFile(file.fullPath)}
                      >
                        <img
                          className={classes.DropZoneContentItemDownload}
                          id="downloadIcon"
                          src={DownloadIcon}
                          alt="DownloadIcon"
                        />
                        <img
                          className={classes.DropZoneContentItemCheck}
                          id="checkIcon"
                          src={CheckIcon}
                          alt="CheckIcon"
                        />
                      </div>
                      {file.fileName.length > 12 ? (
                        <Tooltip title={file.fileName}>
                          {file.fileName.slice(0, 13)}
                          ...
                        </Tooltip>
                      ) : (
                        file.fileName
                      )}
                      {!isDisabled && (
                        <img
                          className={classes.DropZoneContentItemClose}
                          onClick={onOpenOpenDeleteModal(file.fullPath)}
                          src={CloseIcon}
                          alt="CloseIcon"
                        />
                      )}
                    </div>
                  ))}
                </aside>
              )}

              {files.length === 0 && maxFiles === 1 && (
                <div
                  {...getRootProps({
                    className: `dropzone ${
                      files.length > 0 ? classes.DropZoneAreaGrey : classes.DropZoneArea
                    }`,
                  })}
                  style={{ cursor: !isDisabled ? 'pointer' : 'default' }}
                >
                  {!isDisabled ? (
                    <>
                      <input {...getInputProps()} />
                      <div className={classes.DropZoneUpload}>
                        <img
                          className={classes.DropZoneUploadImage}
                          src={FileUploadIcon}
                          alt="FileUpload"
                        />
                        Drop File Here
                      </div>
                    </>
                  ) : (
                    <div className={classes.DropZoneNotUploaded}>No files uploaded</div>
                  )}
                </div>
              )}
              {maxFiles !== 1 && (
                <div
                  {...getRootProps({
                    className: `dropzone ${
                      files.length > 0 ? classes.DropZoneAreaGrey : classes.DropZoneArea
                    }`,
                  })}
                  style={{ cursor: !isDisabled ? 'pointer' : 'default' }}
                >
                  {!isDisabled ? (
                    <>
                      <input {...getInputProps()} />
                      <div className={classes.DropZoneUpload}>
                        <img
                          className={classes.DropZoneUploadImage}
                          src={FileUploadIcon}
                          alt="FileUpload"
                        />
                        Drop Files Here
                      </div>
                    </>
                  ) : (
                    <div className={classes.DropZoneNotUploaded}>Here is no files uploaded</div>
                  )}
                </div>
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
};
