export const sicCodes = [
  'Abrasive Product Mfg. [3291]',
  'Adhesive Mfg. [2891]',
  'Administration of Air and Water Resource and Solid Waste Management Programs [9511]',
  'Administration of Housing Programs [9531]',
  'Administrative Management and General Management Consulting Services [8742]',
  'Advertising Agencies [7311]',
  'Air Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Mfg. [3585]',
  'Air and Gas Compressor Mfg. [3563]',
  'Aircraft Engine and Engine Parts Mfg. [3724]',
  'Airplane Mfg. [3721]',
  'All Other Amusement and Recreation Industries [7911]',
  'All Other Amusement and Recreation Industries [7997]',
  'All Other Amusement and Recreation Industries [7999]',
  'All Other Automotive Repair and Maintenance [7539]',
  'All Other Basic Organic Chemical Mfg. [2869]',
  'All Other Business Support Services [7389]',
  'All Other Consumer Goods Rental [7359]',
  'All Other Converted Paper Product Mfg. [2679]',
  'All Other General Merchandise Stores [5331]',
  'All Other General Merchandise Stores [5399]',
  'All Other Grain Farming [119]',
  'All Other Health and Personal Care Stores [5999]',
  'All Other Home Furnishings Stores [7699]',
  'All Other Information Services [7389]',
  'All Other Insurance Related Activities [6411]',
  'All Other Leather Good and Allied Product Mfg. [3131]',
  'All Other Leather Good and Allied Product Mfg. [3161]',
  'All Other Leather Good and Allied Product Mfg. [3172]',
  'All Other Leather Good and Allied Product Mfg. [3199]',
  'All Other Legal Services [7389]',
  'All Other Metal Ore Mining [1099]',
  'All Other Misc. Ambulatory Health Care Services [8099]',
  'All Other Misc. Chemical Product and Preparation Mfg. [2819]',
  'All Other Misc. Chemical Product and Preparation Mfg. [2899]',
  'All Other Misc. Chemical Product and Preparation Mfg. [3999]',
  'All Other Misc. Chemical Product and Preparation Mfg. [7389]',
  'All Other Misc. Crop Farming [139]',
  'All Other Misc. Crop Farming [191]',
  'All Other Misc. Electrical Equipment and Component Mfg. [3629]',
  'All Other Misc. Electrical Equipment and Component Mfg. [3699]',
  'All Other Misc. Fabricated Metal Product Mfg. [3291]',
  'All Other Misc. Fabricated Metal Product Mfg. [3431]',
  'All Other Misc. Fabricated Metal Product Mfg. [3497]',
  'All Other Misc. Fabricated Metal Product Mfg. [3499]',
  'All Other Misc. Fabricated Metal Product Mfg. [3543]',
  'All Other Misc. Fabricated Metal Product Mfg. [3999]',
  'All Other Misc. Food Mfg. [2032]',
  'All Other Misc. Food Mfg. [2087]',
  'All Other Misc. Food Mfg. [2099]',
  'All Other Misc. Mfg. [2499]',
  'All Other Misc. Mfg. [3999]',
  'All Other Misc. Nonmetallic Mineral Product Mfg. [3292]',
  'All Other Misc. Nonmetallic Mineral Product Mfg. [3299]',
  'All Other Misc. Store Retailers (Except Tobacco Stores) [5999]',
  'All Other Misc. Textile Product Mills [2299]',
  'All Other Misc. Textile Product Mills [2399]',
  'All Other Misc. Waste Management Services [4959]',
  'All Other Misc. Wood Product Mfg. [2426]',
  'All Other Misc. Wood Product Mfg. [2429]',
  'All Other Misc. Wood Product Mfg. [2449]',
  'All Other Misc. Wood Product Mfg. [2499]',
  'All Other Misc. Wood Product Mfg. [3543]',
  'All Other Misc. Wood Product Mfg. [3999]',
  'All Other Nondepository Credit Intermediation [5932]',
  'All Other Nonmetallic Mineral Mining [1499]',
  'All Other Outpatient Care Centers [8093]',
  'All Other Personal Services [7299]',
  'All Other Petroleum and Coal Products Mfg. [2999]',
  'All Other Petroleum and Coal Products Mfg. [3312]',
  'All Other Pipeline Transportation [4619]',
  'All Other Plastics Product Mfg. [3069]',
  'All Other Plastics Product Mfg. [3089]',
  'All Other Plastics Product Mfg. [3996]',
  'All Other Plastics Product Mfg. [3999]',
  'All Other Publishers [2741]',
  'All Other Rubber Product Mfg. [3069]',
  'All Other Specialty Food Stores [5451]',
  'All Other Specialty Food Stores [5499]',
  'All Other Specialty Trade Contractors [1771]',
  'All Other Specialty Trade Contractors [1796]',
  'All Other Specialty Trade Contractors [1799]',
  'All Other Specialty Trade Contractors [7353]',
  'All Other Support Activities for Transportation [4789]',
  'All Other Support Services [7389]',
  'All Other Telecommunications [4899]',
  'All Other Transit and Ground Transportation [4111]',
  'All Other Transportation Equipment Mfg. [3799]',
  'All Other Travel Arrangement and Reservation Services [4729]',
  'All Other Traveler Accommodations [7011]',
  'Alumina Refining and Primary Aluminum Production [2819]',
  'Alumina Refining and Primary Aluminum Production [3334]',
  'Aluminum Die-Casting Foundries [3363]',
  'Aluminum Foundries (Except Die-Casting) [3365]',
  'Aluminum Sheet, Plate, and Foil Mfg. [3353]',
  'Ambulance Services [4119]',
  'Ammunition (Except Small Arms) Mfg. [3483]',
  'Amusement Arcades [7993]',
  'Amusement and Theme Parks [7996]',
  'Analytical Laboratory Instrument Mfg. [3826]',
  'Animal (Except Poultry) Slaughtering [2011]',
  'Animal Production NOC [219]',
  'Anthracite Mining [1231]',
  'Apiculture [279]',
  'Apparel Accessories and Other Apparel Mfg. [2323]',
  'Apparel Accessories and Other Apparel Mfg. [2353]',
  'Apparel Accessories and Other Apparel Mfg. [2381]',
  'Apparel Accessories and Other Apparel Mfg. [2389]',
  'Apparel Accessories and Other Apparel Mfg. [2396]',
  'Apparel Accessories and Other Apparel Mfg. [3151]',
  'Apple Orchards [175]',
  'Appliance Repair and Maintenance [7623]',
  'Apprenticeship Training [8249]',
  'Architectural Services [8712]',
  'Armored Car Services [7381]',
  'Art Dealers [5999]',
  'Artificial and Synthetic Fibers and Filaments Mfg. [2823]',
  'Artificial and Synthetic Fibers and Filaments Mfg. [2824]',
  'Asphalt Paving Mixture and Block Mfg. [2951]',
  'Asphalt Shingle and Coating Materials Mfg. [2952]',
  'Assisted Living Facilities for the Elderly [8361]',
  'Audio and Video Equipment Mfg. [3651]',
  'Automatic Environment Control Mfg. for Residential, Commercial and Appliance Use [3822]',
  'Automobile Driving Schools [8299]',
  'Automobile Mfg. [3711]',
  'Automobile and Other Motor Vehicle Merchant Wholesalers [5012]',
  'Automotive Body, Paint, and Interior Repair and Maintenance [7532]',
  'Automotive Exhaust System Repair [7533]',
  'Automotive Glass Replacement Shops [7536]',
  'Automotive Oil Change and Lubrication Shops [7549]',
  'Automotive Parts and Accessories Stores [5531]',
  'Automotive Transmission Repair [7537]',
  'Baked Goods Stores [5461]',
  'Ball and Roller Bearing Mfg. [3562]',
  'Barber Shops [7241]',
  'Barbershop, Beauty Parlor, or Hair Styling Salon [7299]',
  'Bare Printed Circuit Board Mfg. [3672]',
  'Beauty Salons [7231]',
  'Bed-and-Breakfast Inns [7011]',
  'Beef Cattle Ranching and Farming [212]',
  'Beer and Ale Merchant Wholesalers [5181]',
  'Beer, Wine, and Liquor Stores [5921]',
  'Beet Sugar Mfg. [2063]',
  'Berry (Except Strawberry) Farming [171]',
  'Biological Product (Except Diagnostic) Mfg. [2836]',
  'Bituminous Coal Underground Mining [1222]',
  'Bituminous Coal and Lignite Surface Mining [1221]',
  'Blank Magnetic and Optical Recording Media Mfg. [3695]',
  'Blind and Shade Mfg. [2591]',
  'Blood and Organ Banks [8099]',
  'Boat Building [3732]',
  'Boat Dealers [5551]',
  'Bolt, Nut, Screw, Rivet, and Washer Mfg. [3452]',
  'Book Publishers [2731]',
  'Book Stores [5942]',
  'Book, Periodical, and Newspaper Merchant Wholesalers [5192]',
  'Books Printing [2732]',
  'Books Printing [2782]',
  'Bottled Water Mfg. [2086]',
  'Bowling Centers [7933]',
  'Breakfast Cereal Mfg. [2043]',
  'Breweries [2082]',
  'Brick, Stone, and Related Construction Material Merchant Wholesalers [5032]',
  'Broadwoven Fabric Mills [2211]',
  'Broadwoven Fabric Mills [2221]',
  'Broadwoven Fabric Mills [2231]',
  'Broadwoven Fabric Mills [2299]',
  'Broilers and Other Meat Type Chicken Production [251]',
  'Broom, Brush, and Mop Mfg. [3991]',
  'Building Equipment Contractors [1796]',
  'Building Inspection Services [7389]',
  'Burial Casket Mfg. [3995]',
  'Bus and Other Motor Vehicle Transit Systems [4111]',
  'Cable and Other Subscription Programming [4841]',
  'Cafeterias, Grill Buffets, and Buffets [5812]',
  'Cane Sugar Mfg. [2061]',
  'Cane Sugar Mfg. [2062]',
  'Capacitor, Resistor, Coil, Transformer, and Other Inductor Mfg. [3675]',
  'Capacitor, Resistor, Coil, Transformer, and Other Inductor Mfg. [3676]',
  'Car Washes [7542]',
  'Carbon and Graphite Product Mfg. [3624]',
  'Carpet and Rug Mills [2273]',
  'Carpet and Upholstery Cleaning Services [7217]',
  'Casino Hotels [7011]',
  'Casinos (Except Casino Hotels) [7999]',
  'Caterers [5812]',
  'Cattle Feedlots [211]',
  'Cement Mfg. [3241]',
  'Cemeteries and Crematories [6553]',
  'Cemeteries and Crematories [7261]',
  'Cheese Mfg. [2022]',
  'Chicken Egg Production [252]',
  'Child Day Care Services [8351]',
  'Child and Youth Services [8641]',
  'Childrens and Infants Clothing Stores [5641]',
  'Chocolate and Confectionery Mfg. from Cacao Beans [2066]',
  'Citrus (Except Orange) Groves [174]',
  'Civic and Social Organizations [8641]',
  'Claims Adjusting [6411]',
  'Clay Building Material and Refractories Mfg. [3251]',
  'Clay Building Material and Refractories Mfg. [3253]',
  'Clay Building Material and Refractories Mfg. [3255]',
  'Clay Building Material and Refractories Mfg. [3259]',
  'Clay Building Material and Refractories Mfg. [3297]',
  'Clay and Ceramic and Refractory Minerals Mining [1459]',
  'Clothing Accessories Stores [5699]',
  'Coal and Other Mineral and Ore Merchant Wholesalers [5052]',
  'Coastal and Great Lakes Passenger Transportation [4482]',
  'Coffee and Tea Mfg. [2095]',
  'Coin-Operated Laundries and Drycleaners [7215]',
  'Collection Agencies [7322]',
  'Colleges, Universities, and Professional Schools [8221]',
  'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing [4499]',
  'Commercial Bakeries [2051]',
  'Commercial Banking [6029]',
  'Commercial Gravure Printing [2754]',
  'Commercial Printing (Except Screen and Books) [2752]',
  'Commercial Printing (Except Screen and Books) [2754]',
  'Commercial Printing (Except Screen and Books) [2759]',
  'Commercial Printing (Except Screen and Books) [2761]',
  'Commercial Screen Printing [2396]',
  'Commercial and Industrial Machinery and Equipment (Except Automotive and Electronic) Repair and Maintenance [7623]',
  'Commercial and Industrial Machinery and Equipment (Except Automotive and Electronic) Repair and Maintenance [7699]',
  'Commercial and Institutional Building Construction [1799]',
  'Commercial, Industrial, and Institutional Electric Lighting Fixture Mfg. [3646]',
  'Commodity Contracts Brokerage [6221]',
  'Communication Equipment Repair and Maintenance [7622]',
  'Community Food Services [8322]',
  'Commuter Rail Systems [4111]',
  'Computer Facilities Management Services [7376]',
  'Computer Storage Device Mfg. [3572]',
  'Computer Systems Design Services [7373]',
  'Computer Terminal and Other Computer Peripheral Equipment Mfg. [3575]',
  'Computer Training [8243]',
  'Computer and Computer Peripheral Equipment And Saftware Merchant Wholesalers [5045]',
  'Computer and Office Machine Repair and Maintenance [7378]',
  'Computer and Office Machine Repair and Maintenance [7629]',
  'Computer and Peripheral Equipment Mfg. [3579]',
  'Concrete Block and Brick Mfg. [3271]',
  'Concrete Pipe Mfg. [3272]',
  'Confectionery Merchant Wholesalers [5145]',
  'Confectionery Mfg. from Purchased Chocolate [2066]',
  'Confectionery and Nut Stores [5441]',
  'Construction Machinery Mfg. [3531]',
  'Construction Sand and Gravel Mining [1442]',
  'Construction and Mining (Except Oil Well) Machinery and Equipment Merchant Wholesalers [5082]',
  'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing [7353]',
  'Consumer Electronics Repair and Maintenance [7629]',
  'Consumer Electronics and Appliances Rental [7359]',
  'Consumer Lending [6141]',
  'Continuing Care Retirement Centers [8059]',
  'Convenience Stores [5411]',
  'Convention and Trade Show Organizers [7389]',
  'Convention and Visitors Bureaus [7389]',
  'Conveyor and Conveying Equipment Mfg. [3535]',
  'Cookie and Cracker Mfg. [2052]',
  'Copper Ore and Nickel Ore Mining [1021]',
  'Copper Rolling, Drawing, Extruding, and Alloying [3351]',
  'Copper Rolling, Drawing, Extruding, and Alloying [3357]',
  'Corn Farming [115]',
  'Correctional Institutions [9223]',
  'Corrugated and Solid Fiber Box Mfg. [2653]',
  'Cosmetics, Beauty Supplies, and Perfume Stores [5999]',
  'Cosmetology and Barber Schools [7231]',
  'Cotton Farming [131]',
  'Cotton Ginning [724]',
  'Courier and Express Delivery Services [4215]',
  'Court Reporting and Stenotype Services [7338]',
  'Creamery Butter Mfg. [2021]',
  'Credit Bureaus [7323]',
  'Credit Card Issuing [6141]',
  'Credit Unions [6061]',
  'Crop Harvesting, Primarily by Machine [722]',
  'Crude Petroleum and Natural Gas Extraction [1311]',
  'Crushed and Broken Granite Mining and Quarrying [1423]',
  'Crushed and Broken Limestone Mining and Quarrying [1422]',
  'Current-Carrying Wiring Device Mfg. [3643]',
  'Curtain and Linen Mills [2391]',
  'Curtain and Linen Mills [2392]',
  'Custom Architectural Woodwork and Millwork Mfg. [2511]',
  'Custom Compounding of Purchased Resins [3087]',
  'Custom Computer Programming Services [7371]',
  'Custom Roll Forming [3449]',
  'Cut Stock, Resawing Lumber, and Planing [2439]',
  'Cut Stone and Stone Product Mfg. [3281]',
  'Cutting Tool and Machine Tool Accessory Mfg. [3545]',
  'Cyclic Crude, Intermediate, and Gum and Wood Chemical Mfg. [2861]',
  'Cyclic Crude, Intermediate, and Gum and Wood Chemical Mfg. [2865]',
  'Dairy Cattle and Milk Production [241]',
  'Dairy Product (Except Dried or Canned) Merchant Wholesalers [5143]',
  'Dance Cos. [7922]',
  'Data Processing, Hosting, and Related Services [7374]',
  'Deep Sea Freight Transportation [4412]',
  'Deep Sea Passenger Transportation [4481]',
  'Dental Equipment and Supplies Mfg. [3845]',
  'Dental Laboratories [8072]',
  'Department Stores (Except Discount Department Stores) [5311]',
  'Depository Credit Intermediation [6022]',
  'Diagnostic Imaging Centers [8071]',
  'Diet and Weight Reducing Centers [7299]',
  'Dimension Stone Mining and Quarrying [1411]',
  'Direct Life Insurance Carriers [6311]',
  'Direct Mail Advertising [7331]',
  'Direct Property and Casualty Insurance Carriers [6331]',
  'Direct Title Insurance Carriers [6361]',
  'Discount Department Stores [5311]',
  'Distilleries [2085]',
  'Document Preparation Services [7338]',
  'Dog and Cat Food Mfg. [2047]',
  'Doll, Toy, and Game Mfg. [3942]',
  'Doll, Toy, and Game Mfg. [3944]',
  'Drafting Services [7389]',
  'Dredging: Gold--Endless Bucket or Ladder Type [1081]',
  'Dried and Dehydrated Food Mfg. [2034]',
  'Drilling Oil and Gas Wells [1381]',
  'Drinking Places (Alcoholic Beverages) [5813]',
  'Drive-in Motion Picture Theaters [7833]',
  'Drugs and Druggists Sundries Merchant Wholesalers [5122]',
  "Drugs and Druggists' Sundries Merchant Wholesalers [5122]",
  'Dry Pasta, Dough, and Flour Mixes Mfg. from Purchased Flour [2045]',
  'Dry Pasta, Dough, and Flour Mixes Mfg. from Purchased Flour [2098]',
  'Dry Pea and Bean Farming [119]',
  'Dry, Condensed, and Evaporated Dairy Product Mfg. [2023]',
  'Drycleaning and Laundry Services (Except Coin-Operated) [7211]',
  'Drycleaning and Laundry Services (Except Coin-Operated) [7216]',
  'Drywall and Insulation Contractors [1742]',
  'Electric Lamp Bulb and Part Mfg. [3641]',
  'Electric Power Distribution [4911]',
  'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers [5063]',
  'Electrical Contractors and Other Wiring Installation Contractors [1731]',
  'Electromedical and Electrotherapeutic Apparatus Mfg. [3845]',
  'Electronic Auctions [5961]',
  'Electronic Coil, Transformer, and Other Inductor Mfg. [3677]',
  'Electronic Computer Mfg. [3571]',
  'Electronic Connector Mfg. [3678]',
  'Electronic Shopping [5961]',
  'Electronics Stores [5045]',
  'Electronics Stores [5731]',
  'Electronics Stores [5734]',
  'Electronics Stores [5946]',
  'Electroplating, Plating, Polishing, Anodizing, and Coloring [3471]',
  'Elementary and Secondary Schools [8211]',
  'Elevator and Moving Stairway Mfg. [3534]',
  'Emergency and Other Relief Services [8322]',
  'Engineered Wood Member (Except Truss) Mfg. [2439]',
  'Engineering Services [8711]',
  'Environment, Conservation, and Wildlife Organizations [8399]',
  'Environmental Consulting Services [8999]',
  'Ethyl Alcohol Mfg. [2869]',
  'Exam Preparation and Tutoring [8299]',
  'Explosives Mfg. [2892]',
  'Exterminating and Pest Control Services [4959]',
  'Exterminating and Pest Control Services [7342]',
  'Fabric Coating Mills [2295]',
  'Fabricated Pipe and Pipe Fitting Mfg. [3498]',
  'Fabricated Structural Metal Mfg. [3441]',
  'Fabricated Structural Metal Mfg. [3449]',
  'Facilities Support Services [8744]',
  'Family Clothing Stores [5651]',
  'Family Planning Centers [8093]',
  'Farm Labor Contractors and Crew Leaders [761]',
  'Farm Machinery and Equipment Mfg. [3523]',
  'Farm Management Services [762]',
  'Farm Product Warehousing and Storage [4221]',
  'Farm Supplies Merchant Wholesalers [5191]',
  'Farm Supplies Merchant Wholesalers [5261]',
  'Farm and Garden Machinery and Equipment Merchant Wholesalers [5083]',
  'Fastener, Button, Needle, and Pin Mfg. [3965]',
  'Fats and Oils Refining and Blending [2079]',
  'Fertilizer (Mixing Only) Mfg. [2875]',
  'Fiber Optic Cable Mfg. [3357]',
  'Fiber, Yarn, and Thread Mills [2281]',
  'Fiber, Yarn, and Thread Mills [2282]',
  'Fiber, Yarn, and Thread Mills [2284]',
  'Finfish Farming and Fish Hatcheries [921]',
  'Finfish Fishing [912]',
  'Finish Carpentry Contractors [1751]',
  'Fire Protection [9224]',
  'Fish and Seafood Markets [5421]',
  'Fish and Seafood Merchant Wholesalers [5146]',
  'Fitness and Recreational Sports Centers [7991]',
  'Fitness and Recreational Sports Centers [7997]',
  'Fitness and Recreational Sports Centers [7999]',
  'Flat Glass Mfg. [3211]',
  'Flavoring Syrup and Concentrate Mfg. [2087]',
  'Flight Training [8249]',
  'Floor Covering Stores [5713]',
  'Flooring Contractors [1752]',
  'Floriculture Production [181]',
  'Florists [5992]',
  'Flour Milling [2041]',
  'Flower, Nursery Stock, and Florists Supplies Merchant Wholesalers [5193]',
  'Fluid Milk Mfg. [2026]',
  'Fluid Power Cylinder and Actuator Mfg. [3593]',
  'Fluid Power Pump and Motor Mfg. [3594]',
  'Fluid Power Valve and Hose Fitting Mfg. [3492]',
  'Folding Paperboard Box Mfg. [2657]',
  'Food (Health) Supplement Stores [5499]',
  'Food Product Machinery Mfg. [3556]',
  'Food Service Contractors [5812]',
  'Footwear Merchant Wholesalers [5139]',
  'Footwear Mfg. [3021]',
  'Footwear Mfg. [3142]',
  'Footwear Mfg. [3143]',
  'Footwear Mfg. [3144]',
  'Footwear Mfg. [3149]',
  'Footwear and Leather Goods Repair [7251]',
  'Forest Nurseries and Gathering of Forest Products [831]',
  'Formal Wear and Costume Rental [7299]',
  'Framing Contractors [1751]',
  'Freestanding Ambulatory Surgical and Emergency Centers [8011]',
  'Freight Transportation Arrangement [4731]',
  'Fresh Fruit and Vegetable Merchant Wholesalers [5148]',
  'Frozen Cakes, Pies, and Other Pastries Mfg. [2051]',
  'Frozen Fruit, Juice, and Vegetable Mfg. [2034]',
  'Frozen Fruit, Juice, and Vegetable Mfg. [2037]',
  'Frozen Specialty Food Mfg. [2038]',
  'Fruit and Tree Nut Combination Farming [179]',
  'Fruit and Vegetable Canning [2033]',
  'Fruit and Vegetable Canning [2035]',
  'Fruit and Vegetable Markets [5431]',
  'Fuel Dealers [5171]',
  'Fuel Dealers [5983]',
  'Fuel Dealers [5984]',
  'Fuel Dealers [5989]',
  'Full-Service Restaurants [5812]',
  'Funeral Homes and Funeral Services [7261]',
  'Fur-Bearing Animal and Rabbit Production [271]',
  'Furniture Merchant Wholesalers [5021]',
  'Furniture Stores [5712]',
  'Gasket, Packing, and Sealing Device Mfg. [3053]',
  'Gasoline Stations with Convenience Stores [5541]',
  'General Automotive Repair [7538]',
  'General Freight Trucking, Local [4212]',
  'General Freight Trucking, Long Distance, Less Than Truckload [4213]',
  'General Freight Trucking, Long-Distance, Truckload [4213]',
  'General Line Grocery Merchant Wholesalers [5141]',
  'General Medical and Surgical Hospitals [8062]',
  'General Rental Centers [7359]',
  'General Warehousing and Storage [4225]',
  'Geophysical Surveying and Mapping Services [1382]',
  'Gift, Novelty, and Souvenir Stores [5947]',
  'Glass Container Mfg. [3221]',
  'Glass Product Mfg. Made of Purchased Glass [3231]',
  'Glass and Glazing Contractors [1793]',
  'Goat Farming [214]',
  'Gold Ore Mining [1041]',
  'Golf Courses and Country Clubs [7992]',
  'Golf Courses and Country Clubs [7997]',
  'Grain and Field Bean Merchant Wholesalers [5153]',
  'Grantmaking Foundations [6732]',
  'Grape Vineyards [172]',
  'Greeting Card Publishers [2771]',
  'Ground or Treated Mineral and Earth Mfg. [3295]',
  'Guided Missile and Space Vehicle Mfg. [3761]',
  'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Mfg. [3764]',
  'Gypsum Product Mfg. [3299]',
  'HMO Medical Centers [8011]',
  'Hardware Merchant Wholesalers [5072]',
  'Hardware Mfg. [3429]',
  'Hardware Mfg. [3499]',
  'Hardware Stores [5251]',
  'Hardwood Veneer and Plywood Mfg. [2435]',
  'Hay Farming [139]',
  'Hazardous Waste Collection [4212]',
  'Hazardous Waste Treatment and Disposal [4953]',
  'Health and Welfare Funds [6371]',
  'Heating Equipment (Except Warm Air Furnaces) Mfg. [3433]',
  'Heavy Duty Truck Mfg. [3711]',
  'Highway, Street, and Bridge Construction [1611]',
  'Highway, Street, and Bridge Construction [1622]',
  'Highway, Street, and Bridge Construction [1623]',
  'Highway, Street, and Bridge Construction [1721]',
  'Historical Sites [8412]',
  'Hobby, Toy, and Game Stores [5945]',
  'Hog and Pig Farming [213]',
  'Home Centers [5211]',
  'Home Furnishing Merchant Wholesalers [5023]',
  'Home Health Care Services [8082]',
  'Home Health Equipment Rental [7352]',
  'Home and Garden Equipment Repair and Maintenance [7699]',
  'Horses and Other Equine Production [272]',
  'Hosiery and Sock Mills [2251]',
  'Hosiery and Sock Mills [2252]',
  'Hotels (Except Casino Hotels) and Motels [7011]',
  'Household Appliance Stores [5722]',
  'Household Appliance Stores [7623]',
  'Household Appliance Stores [7629]',
  'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers [5064]',
  'Household Cooking Appliance Mfg. [3631]',
  'Household Furniture (Except Wood and Metal) Mfg. [2519]',
  'Household Laundry Equipment Mfg. [3633]',
  'Household Refrigerator and Home Freezer Mfg. [3632]',
  'Human Rights Organizations [8399]',
  'Hunting and Trapping [971]',
  'Ice Cream and Frozen Dessert Mfg. [2024]',
  'Ice Mfg. [2097]',
  'In-Vitro Diagnostic Substance Mfg. [2835]',
  'Industrial Design Services [7389]',
  'Industrial Gas Mfg. [2813]',
  'Industrial Launderers [7218]',
  'Industrial Machinery and Equipment Merchant Wholesalers [5084]',
  'Industrial Mold Mfg. [3544]',
  'Industrial Pattern Mfg. [3543]',
  'Industrial Process Furnace and Oven Mfg. [3567]',
  'Industrial Sand Mining [1446]',
  'Industrial Supplies Merchant Wholesalers [5085]',
  'Industrial Truck, Tractor, Trailer, and Stacker Machinery Mfg. [3537]',
  'Industrial Valve Mfg. [3491]',
  'Industrial and Commercial Fan and Blower and Air Purification Equipment Mfg. [3564]',
  'Industrial and Personal Service Paper Merchant Wholesalers [5113]',
  'Inland Water Freight Transportation [4449]',
  'Inland Water Passenger Transportation [4489]',
  'Instrument Mfg. for Measuring and Testing Electricity and Electrical Signals [3825]',
  'Instruments and Related Products Mfg. for Measuring, Displaying, and Controlling Industrial Process Variables [3823]',
  'Instruments and Related Products Mfg. for Measuring, Displaying, and Controlling Industrial Process Variables [3829]',
  'Insurance Agencies & Brokerages [6411]',
  'Insurance Agencies and Brokerages [6411]',
  'Integrated Record Production/Distribution [3652]',
  'Interior Design Services [7389]',
  'Interurban and Rural Bus Transportation [4131]',
  'Investigation Services [7381]',
  'Investment Advice [6282]',
  'Investment Banking and Securities Dealing [6211]',
  'Iron Foundries [3321]',
  'Iron Foundries [3322]',
  'Iron Ore Mining [1011]',
  'Iron and Steel Forging [3462]',
  'Iron and Steel Mills and Ferroalloy Mfg. [3312]',
  'Iron and Steel Mills and Ferroalloy Mfg. [3313]',
  'Iron and Steel Pipe and Tube Mfg. from Purchased Steel [3317]',
  'Irradiation Apparatus Mfg. [3844]',
  'Janitorial Services [7349]',
  'Jewelry Stores [5944]',
  'Jewelry and Silverware Mfg. [3911]',
  'Jewelry and Silverware Mfg. [3914]',
  'Jewelry and Silverware Mfg. [3915]',
  'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers [5094]',
  'Junior Colleges [8221]',
  'Kidney Dialysis Centers [8092]',
  'Knit Fabric Mills [2258]',
  'Knit Fabric Mills [2259]',
  'Labor Unions and Similar Labor Organizations [8631]',
  'Laminated Plastics Plate, Sheet (Except Packaging), and Shape Mfg. [3083]',
  'Land Subdivision [6552]',
  'Landscape Architectural Services [781]',
  'Landscaping Services [782]',
  'Landscaping Services [783]',
  'Language Schools [8299]',
  'Lawn and Garden Tractor and Home Lawn and Garden Equipment Mfg. [3524]',
  'Lead Ore and Zinc Ore Mining [1031]',
  'Leather and Hide Tanning and Finishing [3111]',
  'Leather and Hide Tanning and Finishing [3999]',
  'Lessors of Miniwarehouses and Self-Storage Units [4225]',
  'Lessors of Nonresidential Buildings (Except Miniwarehouses) [6512]',
  'Lessors of Other Real Estate Property [6515]',
  'Lessors of Other Real Estate Property [6519]',
  'Lessors of Residential Buildings and Dwellings [6513]',
  'Lessors of Residential Buildings and Dwellings [6514]',
  'Libraries and Archives [8231]',
  'Light Truck and Utility Vehicle Mfg. [3711]',
  'Lime Mfg. [3274]',
  'Limited-Service Restaurants [5812]',
  'Limousine Service [4119]',
  'Line-Haul Railroads [4011]',
  'Linen Supply [7213]',
  'Linen Supply [7219]',
  'Livestock Merchant Wholesalers [5154]',
  'Local Messengers and Local Delivery [4215]',
  'Locksmiths [7699]',
  'Logging [2411]',
  'Luggage and Leather Goods Stores [5948]',
  'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers [5031]',
  'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers [5039]',
  'Machine Shops [3599]',
  'Machine Tool Mfg. [3541]',
  'Machine Tool Mfg. [3542]',
  'Mail Order Houses [5961]',
  'Malt Mfg. [2083]',
  'Manufactured (Mobile) Home Dealers [5271]',
  'Manufactured Home (Mobile Home) Mfg. [2451]',
  'Marinas [4493]',
  'Marine Cargo Handling [4491]',
  'Marketing Consulting Services [8742]',
  'Marketing Research and Public Opinion Polling [8732]',
  'Masonry Contractors [1741]',
  'Masonry Contractors [1771]',
  'Materials Recovery Facilities [4953]',
  'Mattress Mfg. [2515]',
  'Mayonnaise, Dressing, and Other Prepared Sauce Mfg. [2099]',
  'Measuring and Dispensing Pump Mfg. [3586]',
  'Meat Markets [5421]',
  'Meat Processed from Carcasses [2013]',
  'Meat and Meat Product Merchant Wholesalers [5147]',
  'Mechanical Power Transmission Equipment Mfg. [3568]',
  'Media Buying Agencies [7319]',
  'Media Representatives [7313]',
  'Medical Laboratories [8071]',
  'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers [5047]',
  'Medicinal and Botanical Mfg. [2833]',
  "Men's and Boys' Cut and Sew Apparel Mfg. [2321]",
  "Men's and Boys' Cut and Sew Apparel Mfg. [2326]",
  "Men's and Boys' Cut and Sew Apparel Mfg. [2329]",
  'Mens Clothing Stores [5611]',
  'Mens and Boys Clothing and Furnishings Merchant Wholesalers [5136]',
  'Metal Can Mfg. [3411]',
  'Metal Coating, Engraving (Except Jewelry and Silverware), and Allied Services to Manufacturers [3479]',
  'Metal Crown, Closure, and Other Metal Stamping (Except Automotive) [3466]',
  'Metal Crown, Closure, and Other Metal Stamping (Except Automotive) [3469]',
  'Metal Heat Treating [3398]',
  'Metal Household Furniture Mfg. [2514]',
  'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (Except Precious) Mfg. [3421]',
  'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (Except Precious) Mfg. [3469]',
  'Metal Service Centers and Other Metal Merchant Wholesalers [5051]',
  'Metal Tank (Heavy Gauge) Mfg. [3443]',
  'Metal Window and Door Mfg. [3442]',
  'Military Armored Vehicle, Tank, and Tank Component Mfg. [3795]',
  'Mineral Wool Mfg. [3296]',
  'Mining Machinery and Equipment Mfg. [3532]',
  'Mixed Mode Transit Systems [4111]',
  'Monetary Authorities--Central Bank [6011]',
  'Mortgage and Nonmortgage Loan Brokers [6163]',
  'Motion Picture Theaters (Except Drive-Ins) [7832]',
  'Motion Picture and Video Distribution [7822]',
  'Motion Picture and Video Distribution [7829]',
  'Motion Picture and Video Production [7812]',
  'Motor Home Mfg. [3716]',
  'Motor Vehicle Body Mfg. [3711]',
  'Motor Vehicle Brake System Mfg. [3714]',
  'Motor Vehicle Electrical and Electronic Equipment Mfg. [3647]',
  'Motor Vehicle Gasoline Engine and Engine Parts Mfg. [3592]',
  'Motor Vehicle Gasoline Engine and Engine Parts Mfg. [3714]',
  'Motor Vehicle Metal Stamping [3465]',
  'Motor Vehicle Parts (Used) Merchant Wholesalers [5015]',
  'Motor Vehicle Steering and Suspension Components (Except Spring) Mfg. [3714]',
  'Motor Vehicle Supplies and New Parts Merchant Wholesalers [5013]',
  'Motor Vehicle Towing [7549]',
  'Motor Vehicle Transmission and Power Train Parts Mfg. [3714]',
  'Motor and Generator Mfg. [3621]',
  'Motorcycle, ATV, and All Other Motor Vehicle Dealers [5571]',
  'Motorcycle, ATV, and All Other Motor Vehicle Dealers [5599]',
  'Motorcycle, Bicycle, and Parts Mfg. [3751]',
  'Motorcycle, Bicycle, and Parts Mfg. [3944]',
  'Museums [8412]',
  'Mushroom Production [182]',
  'Musical Groups and Artists [7922]',
  'Musical Instrument Mfg. [3931]',
  'Musical Instrument and Supplies Stores [5735]',
  'Musical Instrument and Supplies Stores [5736]',
  'Nail Salons [7231]',
  'Narrow Fabric Mills and Schiffli Machine Embroidery [2241]',
  'Narrow Fabric Mills and Schiffli Machine Embroidery [2397]',
  'National Security [9711]',
  'Natural Gas Distribution [4924]',
  'Natural Gas Liquid Extraction [1311]',
  'Natural Gas Liquid Extraction [1321]',
  'Natural Gas Liquid Extraction [1389]',
  'Nature Parks and Other Similar Institutions [7999]',
  'Navigational Services to Shipping [4499]',
  'New Car Dealers [5511]',
  'New Housing For-Sale Builders [1531]',
  'New Multifamily Housing Construction (Except For-Sale Builders) [1522]',
  'New Single-Family Housing Construction (Except For-Sale Builders) [1521]',
  'News Dealers and Newsstands [5994]',
  'News Syndicates [7383]',
  'Newspaper Publishers [2711]',
  'Newsprint Mills [2621]',
  'Nitrogenous Fertilizer Mfg. [2843]',
  'Nitrogenous Fertilizer Mfg. [2873]',
  'Nonchocolate Confectionery Mfg. [2064]',
  'Nonchocolate Confectionery Mfg. [2067]',
  'Noncurrent-Carrying Wiring Device Mfg. [3644]',
  'Nonferrous Forging [3463]',
  'Nonferrous Metal (Except Aluminum) Smelting and Refining [3331]',
  'Nonferrous Metal (Except Aluminum) Smelting and Refining [3339]',
  'Nonferrous Metal (Except Copper and Aluminum) Rolling, Drawing, and Extruding [3356]',
  'Nonferrous Metal (Except Copper and Aluminum) Rolling, Drawing, and Extruding [3357]',
  'Nonferrous Metal Die-Casting Foundries [3364]',
  'Nonresidential Property Managers [6531]',
  'Nonscheduled Chartered Freight Air Transportation [4522]',
  'Nonscheduled Chartered Passenger Air Transportation [4522]',
  'Nonupholstered Wood Household Furniture Mfg. [2511]',
  'Nonupholstered Wood Household Furniture Mfg. [2517]',
  'Nonwoven Fabric Mills [2299]',
  'Nursery and Tree Production [181]',
  'Nursery, Garden Center, and Farm Supply Stores [5191]',
  'Nursing Care Facilities (Skilled Nursing Facilities) [8051]',
  'Nursing Care Facilities (Skilled Nursing Facilities) [8052]',
  'Nursing Care Facilities (Skilled Nursing Facilities) [8059]',
  'Nursing Care Facilities (Skilled Nursing Facilities) [8361]',
  'Office Administrative Services [8741]',
  'Office Equipment Merchant Wholesalers [5044]',
  'Office Furniture (Except Wood) Mfg. [2522]',
  'Office Supplies (Except Paper) Mfg. [2531]',
  'Office Supplies (Except Paper) Mfg. [3951]',
  'Office Supplies (Except Paper) Mfg. [3952]',
  'Office Supplies (Except Paper) Mfg. [3953]',
  'Office Supplies (Except Paper) Mfg. [3955]',
  'Office Supplies and Stationery Stores [5112]',
  'Offices of All Other Misc. Health Practitioners [8049]',
  'Offices of Bank Holding Cos. [6712]',
  'Offices of Certified Public Accountants [8721]',
  'Offices of Chiropractors [8041]',
  'Offices of Dentists [8021]',
  'Offices of Lawyers [8111]',
  'Offices of Mental Health Practitioners (Except Physicians) [8049]',
  'Offices of Optometrists [8042]',
  'Offices of Other Holding Cos. [6719]',
  'Offices of Physical, Occupational and Speech Therapists, and Audiologists [8049]',
  'Offices of Physicians (Except Mental Health Specialists) [8011]',
  'Offices of Physicians, Mental Health Specialists [8011]',
  'Offices of Podiatrists [8043]',
  'Offices of Real Estate Agents and Brokerages [6531]',
  'Offices of Real Estate Appraisers [6531]',
  'Oil and Gas Field Machinery and Equipment Mfg. [3533]',
  'Oil and Gas Pipeline and Related Structures Construction [1623]',
  'Oilseed (Except Soybean) Farming [119]',
  'Oilseed and Grain Combination Farming [119]',
  'One-Hour Photofinishing [7384]',
  'Open-End Investment Funds [6722]',
  'Ophthalmic Goods Merchant Wholesalers [5048]',
  'Ophthalmic Goods Mfg. [3851]',
  'Optical Goods Stores [5995]',
  'Optical Instrument and Lens Mfg. [3827]',
  'Orange Groves [174]',
  'Ornamental and Architectural Metal Work Mfg. [3446]',
  'Other Accounting Services [8721]',
  'Other Activities Related to Credit Intermediation [6099]',
  'Other Activities Related to Real Estate [6531]',
  'Other Aircraft Parts and Auxiliary Equipment Mfg. [3728]',
  'Other Airport Operations [4581]',
  'Other Aluminum Rolling, Drawing, and Extruding [3354]',
  'Other Animal Food Mfg. [2048]',
  'Other Apparel Knitting Mills [2253]',
  'Other Apparel Knitting Mills [2254]',
  'Other Apparel Knitting Mills [2259]',
  'Other Aquaculture [279]',
  'Other Automotive Mechanical and Electrical Repair and Maintenance [7539]',
  'Other Basic Inorganic Chemical Mfg. [2812]',
  'Other Basic Inorganic Chemical Mfg. [2819]',
  'Other Building Equipment Contractors [1629]',
  'Other Building Equipment Contractors [1796]',
  'Other Building Finishing Contractors [1761]',
  'Other Building Finishing Contractors [1796]',
  'Other Building Finishing Contractors [1799]',
  'Other Building Material Dealers [5032]',
  'Other Building Material Dealers [5063]',
  'Other Building Material Dealers [5074]',
  'Other Building Material Dealers [5211]',
  'Other Building Material Dealers [5231]',
  'Other Business Service Centers (Including Copy Shops) [7334]',
  'Other Chemical and Allied Products Merchant Wholesalers [5169]',
  'Other Clothing Stores [5699]',
  'Other Commercial Equipment Merchant Wholesalers [5046]',
  'Other Commercial and Industrial Machinery and Equipment Rental and Leasing [7352]',
  'Other Commercial and Service Industry Machinery Mfg. [3581]',
  'Other Commercial and Service Industry Machinery Mfg. [3582]',
  'Other Commercial and Service Industry Machinery Mfg. [3589]',
  'Other Communication and Energy Wire Mfg. [3357]',
  'Other Communications Equipment Mfg. [3669]',
  'Other Community Housing Services [8322]',
  'Other Computer Related Services [7379]',
  'Other Concrete Product Mfg. [3272]',
  'Other Construction Material Merchant Wholesalers [5039]',
  'Other Crushed and Broken Stone Mining and Quarrying [1429]',
  'Other Cut and Sew Apparel Mfg. [2371]',
  'Other Cut and Sew Apparel Mfg. [2389]',
  'Other Direct Selling Establishments [5963]',
  'Other Electronic Component Mfg. [3671]',
  'Other Electronic Component Mfg. [3679]',
  'Other Electronic Parts and Equipment Merchant Wholesalers [5065]',
  'Other Electronic and Precision Equipment Repair and Maintenance [7699]',
  'Other Engine Equipment Mfg. [3519]',
  'Other Fabricated Wire Product Mfg. [3315]',
  'Other Farm Product Raw Material Merchant Wholesalers [5159]',
  'Other Food Crops Grown under Cover [182]',
  'Other Foundation, Structure, and Building Exterior Contractors [1761]',
  'Other Foundation, Structure, and Building Exterior Contractors [1791]',
  'Other Foundation, Structure, and Building Exterior Contractors [1796]',
  'Other Foundation, Structure, and Building Exterior Contractors [1799]',
  'Other Gambling Industries [7999]',
  'Other Gasoline Stations [5541]',
  'Other General Government Support [9199]',
  'Other Grantmaking and Giving Services [8399]',
  'Other Grocery and Related Products Merchant Wholesalers [5149]',
  'Other Grocery and Related Products Merchant Wholesalers [5999]',
  'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Mfg. [3769]',
  'Other Heavy and Civil Engineering Construction [1622]',
  'Other Heavy and Civil Engineering Construction [1629]',
  'Other Heavy and Civil Engineering Construction [1799]',
  'Other Heavy and Civil Engineering Construction [8741]',
  'Other Individual and Family Services [8322]',
  'Other Industrial Machinery Mfg. [3552]',
  'Other Industrial Machinery Mfg. [3559]',
  'Other Industrial Machinery Mfg. [3639]',
  'Other Lighting Equipment Mfg. [3648]',
  'Other Major Household Appliance Mfg. [3639]',
  'Other Management Consulting Services [8748]',
  'Other Marine Fishing [919]',
  'Other Measuring and Controlling Device Mfg. [3495]',
  'Other Measuring and Controlling Device Mfg. [3829]',
  'Other Measuring and Controlling Device Mfg. [3873]',
  'Other Metal Container Mfg. [3412]',
  'Other Millwork (Including Flooring) [2421]',
  'Other Misc. Durable Goods Merchant Wholesalers [5099]',
  'Other Misc. Nondurable Goods Merchant Wholesalers [5199]',
  'Other Motion Picture and Video Industries [7819]',
  'Other Motor Vehicle Parts Mfg. [3585]',
  'Other Motor Vehicle Parts Mfg. [3714]',
  'Other Noncitrus Fruit Farming [175]',
  'Other Nonferrous Metal Foundries (Except Die-Casting) [3366]',
  'Other Nonferrous Metal Foundries (Except Die-Casting) [3369]',
  'Other Nonhazardous Waste Treatment and Disposal [4953]',
  'Other Nonscheduled Air Transportation [7335]',
  'Other Paperboard Container Mfg. [2652]',
  'Other Paperboard Container Mfg. [2655]',
  'Other Paperboard Container Mfg. [2656]',
  'Other Performing Arts Cos. [7999]',
  'Other Personal and Household Goods Repair and Maintenance [7692]',
  'Other Personal and Household Goods Repair and Maintenance [7699]',
  'Other Poultry Production [259]',
  'Other Pressed and Blown Glass and Glassware Mfg. [3229]',
  'Other Professional Equipment and Supplies Merchant Wholesalers [5049]',
  'Other Residential Care Facilities [8361]',
  'Other Services Related to Advertising [7389]',
  'Other Services to Buildings and Dwellings [7349]',
  'Other Services to Buildings and Dwellings [7389]',
  'Other Services to Buildings and Dwellings [7699]',
  'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations) [7999]',
  'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations) [8641]',
  'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations) [8699]',
  'Other Snack Food Mfg. [2096]',
  'Other Sound Recording Industries [7389]',
  'Other Specialized Design Services [7389]',
  'Other Spectator Sports [7048]',
  'Other Spectator Sports [7948]',
  'Other Support Activities for Air Transportation [4581]',
  'Other Support Activities for Road Transportation [4785]',
  'Other Support Activities for Water Transportation [4499]',
  'Other Support Activities for Water Transportation [4785]',
  'Other Support Activities for Water Transportation [7699]',
  'Other Urban Transit Systems [4111]',
  'Other Vegetable (Except Potato) and Melon Farming [161]',
  'Other Warehousing and Storage [4226]',
  'Other Waste Collection [4212]',
  'Outdoor Advertising [7312]',
  'Outdoor Power Equipment Stores [5261]',
  'Outpatient Mental Health and Substance Abuse Centers [8093]',
  'Overhead Traveling Crane, Hoist, and Monorail System Mfg. [3536]',
  'Packaged Frozen Food Merchant Wholesalers [5142]',
  'Packaging Machinery Mfg. [3565]',
  'Packaging and Labeling Services [7389]',
  'Packing and Crating [4783]',
  'Paint and Coating Mfg. [2851]',
  'Paint and Wallpaper Stores [5231]',
  'Paint, Varnish, and Supplies Merchant Wholesalers [5198]',
  'Painting and Wall Covering Contractors [1721]',
  'Paper (Except Newsprint) Mills [2621]',
  'Paper Bag and Coated and Treated Paper Mfg. [2671]',
  'Paper Bag and Coated and Treated Paper Mfg. [2672]',
  'Paper Bag and Coated and Treated Paper Mfg. [2673]',
  'Paper Bag and Coated and Treated Paper Mfg. [2674]',
  'Paper Bag and Coated and Treated Paper Mfg. [2675]',
  'Paper Bag and Coated and Treated Paper Mfg. [2679]',
  'Paper Bag and Coated and Treated Paper Mfg. [3497]',
  'Paperboard Mills [2631]',
  'Parking Lots and Garages [7521]',
  'Passenger Car Leasing [7515]',
  'Passenger Car Rental [7514]',
  'Peanut Farming [139]',
  'Pension Funds [6371]',
  'Periodical Publishers [2721]',
  'Perishable Prepared Food Mfg. [2099]',
  'Pesticide and Other Agricultural Chemical Mfg. [2879]',
  'Pet Care (Except Veterinary) Services [752]',
  'Pet and Pet Supplies Stores [5999]',
  'Petroleum Bulk Stations and Terminals [5171]',
  'Petroleum Lubricating Oil and Grease Mfg. [2992]',
  'Petroleum Refineries [2911]',
  'Petroleum and Petroleum Products Merchant Wholesalers (Except Bulk Stations and Terminals) [5172]',
  'Pharmaceutical Preparation Mfg. [2834]',
  'Pharmacies and Drug Stores [5912]',
  'Phosphate Rock Mining [1475]',
  'Phosphatic Fertilizer Mfg. [2874]',
  'Photofinishing Laboratories (Except One-Hour) [7384]',
  'Photographic Equipment and Supplies Merchant Wholesalers [5043]',
  'Photographic Film, Paper, Plate, and Chemical Mfg. [3861]',
  'Photographic and Photocopying Equipment Mfg. [3861]',
  'Photography Studios, Portrait [7221]',
  'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers [5131]',
  'Pipeline Transportation of Crude Oil [4612]',
  'Pipeline Transportation of Natural Gas [4922]',
  'Pipeline Transportation of Refined Petroleum Products [4613]',
  'Plastics Bag and Pouch Mfg. [2673]',
  'Plastics Bottle Mfg. [3085]',
  'Plastics Material and Resin Mfg. [2821]',
  'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers [5162]',
  'Plastics Packaging Film and Sheet (Including Laminated) Mfg. [2671]',
  'Plastics Pipe and Pipe Fitting Mfg. [3084]',
  'Plastics Pipe and Pipe Fitting Mfg. [3086]',
  'Plastics Plumbing Fixture Mfg. [3088]',
  'Plate Work Mfg. [3443]',
  'Plumbing Fixture Fitting and Trim Mfg. [3432]',
  'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers [5074]',
  'Plumbing, Heating, and Air Conditioning Contractors [1711]',
  'Police Protection [9221]',
  'Polish and Other Sanitation Good Mfg. [2842]',
  'Political Organizations [8651]',
  'Polystyrene Foam Product Mfg. [3086]',
  'Port and Harbor Operations [4491]',
  'Postharvest Crop Activities (Except Cotton Ginning) [723]',
  'Potash, Soda, and Borate Mineral Mining [1474]',
  'Potato Farming [134]',
  'Pottery, Ceramics, and Plumbing Fixture Mfg. [3261]',
  'Pottery, Ceramics, and Plumbing Fixture Mfg. [3263]',
  'Pottery, Ceramics, and Plumbing Fixture Mfg. [3264]',
  'Pottery, Ceramics, and Plumbing Fixture Mfg. [3269]',
  'Poultry Hatcheries [254]',
  'Poultry Processing [2015]',
  'Poultry and Poultry Product Merchant Wholesalers [5144]',
  'Poured Concrete Foundation and Structure Contractors [1611]',
  'Poured Concrete Foundation and Structure Contractors [1629]',
  'Poured Concrete Foundation and Structure Contractors [1771]',
  'Powder Metallurgy Part Mfg. [3469]',
  'Power Boiler and Heat Exchanger Mfg. [3443]',
  'Power and Communication Line and Related Structures Construction [1623]',
  'Power and Communication Line and Related Structures Construction [1629]',
  'Power, Distribution, and Specialty Transformer Mfg. [3612]',
  'Power-Driven Handtool Mfg. [3546]',
  'Precision Turned Product Mfg. [3451]',
  'Prefabricated Metal Building and Component Mfg. [3448]',
  'Prefabricated Wood Building Mfg. [2452]',
  'Prerecorded Compact Disc (Except Software), Tape, and Record Producing [7819]',
  'Primary Battery Mfg. [3692]',
  'Printed Circuit Assembly (Electronic Assembly) Mfg. [3679]',
  'Printing Ink Mfg. [2893]',
  'Printing Machinery and Equipment Mfg. [3555]',
  'Printing and Writing Paper Merchant Wholesalers [5111]',
  'Private Households [8811]',
  'Private Mail Centers [7389]',
  'Professional Employer Organizations [7363]',
  'Professional Organizations [8621]',
  'Professional and Management Development Training [8299]',
  'Promoters of Performing Arts, Sports, and Similar Events with Facilities [7941]',
  'Promoters of Performing Arts, Sports, and Similar Events with Facilities [7999]',
  'Promoters of Performing Arts, Sports, and Similar Events without Facilities [7941]',
  'Psychiatric and Substance Abuse Hospitals [8063]',
  'Psychiatric and Substance Abuse Hospitals [8069]',
  'Public Relations Agencies [8743]',
  'Pulp Mills [2611]',
  'Pump and Pumping Equipment Mfg. [3561]',
  'RV (Recreational Vehicle) Parks and Campgrounds [7033]',
  'Racetracks [7948]',
  'Radio Stations [4832]',
  'Radio and Television Broadcasting and Wireless Communications Equipment Mfg. [3663]',
  'Railroad Rolling Stock Mfg. [3743]',
  'Ready-Mix Concrete Mfg. [3273]',
  'Real Estate Credit [6162]',
  'Reconstituted Wood Product Mfg. [2493]',
  'Record Production [8999]',
  'Recreational Goods Rental [7999]',
  'Recreational Vehicle Dealers [5561]',
  'Recreational and Vacation Camps (Except Campgrounds) [7032]',
  'Recyclable Material Merchant Wholesalers [5093]',
  'Refrigerated Warehousing and Storage [4222]',
  'Refrigeration Equipment and Supplies Merchant Wholesalers [5078]',
  'Relay and Industrial Control Mfg. [3625]',
  'Religious Organizations [8661]',
  'Remediation Services [1799]',
  'Rendering and Meat Byproduct Processing [2077]',
  'Repossession Services [7389]',
  'Research and Development in Biotechnology [8732]',
  'Residential Electric Lighting Fixture Mfg. [3645]',
  'Residential Intellectual and Developmental Disability Facilities [8052]',
  'Residential Mental Health and Substance Abuse Facilities [8361]',
  'Residential Property Managers [6531]',
  'Residential Remodelers [1521]',
  'Retail Bakeries [5461]',
  'Reupholstery and Furniture Repair [7641]',
  'Rice Farming [112]',
  'Rice Milling [2044]',
  'Roasted Nuts and Peanut Butter Mfg. [2068]',
  'Roasted Nuts and Peanut Butter Mfg. [2099]',
  'Rolled Steel Shape Mfg. [3316]',
  'Rolling Mill and Other Metalworking Machinery Mfg. [3547]',
  'Rolling Mill and Other Metalworking Machinery Mfg. [3549]',
  'Roofing Contractors [1761]',
  'Roofing, Siding, and Insulation Material Merchant Wholesalers [5033]',
  'Rooming and Boarding Houses [7021]',
  'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills [2296]',
  'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills [2298]',
  'Rubber Product Mfg. for Mechanical Use [3061]',
  'Rubber and Plastics Hoses and Belting Mfg. [3052]',
  'Sanitary Paper Product Mfg. [2676]',
  'Satellite Telecommunications [4899]',
  'Saw Blade and Handtool Mfg. [3421]',
  'Saw Blade and Handtool Mfg. [3423]',
  'Saw Blade and Handtool Mfg. [3425]',
  'Saw Blade and Handtool Mfg. [3544]',
  'Sawmill, Woodworking, and Paper Machinery Mfg. [3553]',
  'Sawmill, Woodworking, and Paper Machinery Mfg. [3554]',
  'Sawmills [2421]',
  'Scale and Balance Mfg. [3596]',
  'Scenic and Sightseeing Transportation, Other [4522]',
  'Scenic and Sightseeing Transportation, Water [4489]',
  'Scheduled Freight Air Transportation [4512]',
  'Scheduled Passenger Air Transportation [4512]',
  'School and Employee Bus Transportation [4151]',
  'Seafood Product Preparation and Packaging [2032]',
  'Seafood Product Preparation and Packaging [2091]',
  'Seafood Product Preparation and Packaging [2092]',
  'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Mfg. [3812]',
  'Secondary Smelting and Alloying of Aluminum [3341]',
  'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (Except Copper and Aluminum) [3313]',
  'Securities Brokerage [6211]',
  'Securities and Commodity Exchanges [6231]',
  'Security Guards and Patrol Services [7381]',
  'Security Systems Services (Except Locksmiths) [1731]',
  'Security Systems Services (Except Locksmiths) [7382]',
  'Semiconductor Machinery Mfg. [3559]',
  'Semiconductor and Related Device Mfg. [3674]',
  'Septic Tank and Related Services [7699]',
  'Service Establishment Equipment and Supplies Merchant Wholesalers [5087]',
  'Services for the Elderly and Persons with Disabilities [8322]',
  'Sewage Treatment Facilities [4952]',
  'Sewing, Needlewoork, and Piece Goods Stores [5949]',
  'Sheep Farming [214]',
  'Sheet Metal Work Mfg. [3444]',
  'Shellfish Farming [921]',
  'Shellfish Fishing [913]',
  'Ship Building and Repairing [3731]',
  'Shoe Stores [5661]',
  'Short Line Railroads [4013]',
  'Siding Contractors [1761]',
  'Sign Mfg. [3993]',
  'Silver Ore Mining [1044]',
  'Site Preparation Contractors [1081]',
  'Site Preparation Contractors [1611]',
  'Site Preparation Contractors [1629]',
  'Site Preparation Contractors [1794]',
  'Site Preparation Contractors [1795]',
  'Skiing Facilities [7999]',
  'Small Arms Ammunition Mfg. [3482]',
  'Small Arms, Ordnance, and Ordnance Accessories Mfg. [3484]',
  'Small Arms, Ordnance, and Ordnance Accessories Mfg. [3489]',
  'Small Electrical Appliance Mfg. [3634]',
  'Snack and Nonalcoholic Beverage Bars [5461]',
  'Snack and Nonalcoholic Beverage Bars [5812]',
  'Soap and Other Detergent Mfg. [2841]',
  'Soft Drink Mfg. [2086]',
  'Software Publishers [7372]',
  'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing [3635]',
  'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing [3652]',
  'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing [7372]',
  'Softwood Veneer and Plywood Mfg. [2436]',
  'Soil Preparation, Planting, and Cultivating [721]',
  'Solid Waste Collection [4953]',
  'Solid Waste Combustors and Incinerators [4953]',
  'Solid Waste Landfill [4953]',
  'Sound Recording Studios [7389]',
  'Soybean Farming [116]',
  'Soybean and Other Oilseed Processing [2074]',
  'Soybean and Other Oilseed Processing [2075]',
  'Soybean and Other Oilseed Processing [2076]',
  'Space Research and Technology [9661]',
  'Special Die and Tool, Die Set, Jig, and Fixture Mfg. [3544]',
  'Special Needs Transportation [4119]',
  'Specialized Freight (Except Used Goods) Trucking, Local [4212]',
  'Specialized Freight (Except Used Goods) Trucking, Long Distance [4213]',
  'Specialized Freight (Except Used Goods) Trucking, Long-Distance [4213]',
  'Specialty (Except Psychiatric and Substance Abuse) Hospitals [8069]',
  'Specialty Canning [2032]',
  'Speed Changer, Industrial High-Speed Drive, and Gear Mfg. [3566]',
  'Spice and Extract Mfg. [2087]',
  'Spice and Extract Mfg. [2099]',
  'Spice and Extract Mfg. [2899]',
  'Sporting Goods Stores [5941]',
  'Sporting and Athletic Goods Mfg. [3949]',
  'Sporting and Recreational Goods and Supplies Merchant Wholesalers [5091]',
  'Sports and Recreation Instruction [7999]',
  'Spring Mfg. [3493]',
  'Spring Mfg. [3495]',
  'Stationery Product Mfg. [2675]',
  'Stationery Product Mfg. [2677]',
  'Stationery Product Mfg. [2678]',
  'Stationery and Office Supplies Merchant Wholesalers [5112]',
  'Steam and Air Conditioning Supply [4961]',
  'Steel Foundries (Except Investment) [3325]',
  'Steel Investment Foundries [3324]',
  'Steel Wire Drawing [3315]',
  'Storage Battery Mfg. [3691]',
  'Strawberry Farming [171]',
  'Structural Steel and Precast Concrete Contractors [1771]',
  'Structural Steel and Precast Concrete Contractors [1791]',
  'Structural Steel and Precast Concrete Contractors [1794]',
  'Sugar Beet Farming [133]',
  'Sugarcane Farming [133]',
  'Supermarkets and Other Grocery (Except Convenience) Stores [5411]',
  'Support Activities for Animal Production [212]',
  'Support Activities for Animal Production [272]',
  'Support Activities for Animal Production [7699]',
  'Support Activities for Coal Mining [1241]',
  'Support Activities for Forestry [851]',
  'Support Activities for Nonmetallic Minerals (Except Fuels) Mining [1481]',
  'Support Activities for Oil and Gas Operations [1381]',
  'Support Activities for Oil and Gas Operations [1382]',
  'Support Activities for Oil and Gas Operations [1389]',
  'Support Activities for Printing [2789]',
  'Support Activities for Printing [2791]',
  'Support Activities for Rail Transportation [4013]',
  'Support Activities for Rail Transportation [4789]',
  'Surface Active Agent Mfg. [2843]',
  'Surgical Appliance and Supplies Mfg. [3842]',
  'Surgical and Medical Instrument Mfg. [3841]',
  'Surveying and Mapping (Except Geophysical) Services [8713]',
  'Switchgear and Switchboard Apparatus Mfg. [3613]',
  'Synthetic Dye and Pigment Mfg. [2816]',
  'Synthetic Dye and Pigment Mfg. [2865]',
  'Synthetic Rubber Mfg. [2822]',
  'Tax Preparation Services [7291]',
  'Taxi Service [4121]',
  'Telecommunications NOC [4899]',
  'Telecommunications Resellers [4813]',
  'Telemarketing Bureaus and Other Contact Centers [7338]',
  'Telephone Answering Services [7389]',
  'Telephone Apparatus Mfg. [3661]',
  'Teleproduction and Other Postproduction Services [7819]',
  'Television Broadcasting [4833]',
  'Temporary Help Services [7363]',
  'Temporary Shelters [8322]',
  'Testing Laboratories [8734]',
  'Textile Bag and Canvas Mills [2393]',
  'Textile Bag and Canvas Mills [2394]',
  'Textile and Fabric Finishing Mills [2269]',
  'Textile and Fabric Finishing Mills [2281]',
  'Textile and Fabric Finishing Mills [2284]',
  'Theater Cos. and Dinner Theaters [5812]',
  'Theater Cos. and Dinner Theaters [7922]',
  'Tile and Terrazzo Contractors [1743]',
  'Timber Tract Operations [811]',
  'Tire Dealers [5531]',
  'Tire Mfg. (Except Retreading) [3011]',
  'Tire Retreading [7534]',
  'Tire and Tube Merchant Wholesalers [5014]',
  'Title Abstract and Settlement Offices [6541]',
  'Tobacco Farming [132]',
  'Tobacco Mfg. [2111]',
  'Tobacco Mfg. [2121]',
  'Tobacco Mfg. [2131]',
  'Tobacco Mfg. [2141]',
  'Tobacco Stores [5993]',
  'Tobacco and Tobacco Product Merchant Wholesalers [5194]',
  'Toilet Preparation Mfg. [2844]',
  'Tortilla Mfg. [2099]',
  'Totalizing Fluid Meter and Counting Device Mfg. [3824]',
  'Tour Operators [4725]',
  'Toy and Hobby Goods and Supplies Merchant Wholesalers [5092]',
  'Translation and Interpretation Services [7389]',
  'Transportation Equipment and Supplies (Except Motor Vehicles) Merchant Wholesalers [5088]',
  'Travel Agencies [4724]',
  'Travel Trailer and Camper Mfg. [3792]',
  'Tree Nut Farming [173]',
  'Truck Trailer Mfg. [3715]',
  'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing [7513]',
  'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing [7519]',
  'Truss Mfg. [2439]',
  'Trusts, Estates, and Agency Accounts [6733]',
  'Turbine and Turbine Generator Set Units Mfg. [3511]',
  'Turkey Production [253]',
  'Unlaminated Plastics Film and Sheet (Except Packaging) Mfg. [3081]',
  'Unlaminated Plastics Profile Shape Mfg. [3082]',
  'Uranium, Radium, Vanadium Ore Mining [1094]',
  'Urethane and Other Foam Product (Except Polystyrene) Mfg. [3086]',
  'Used Car Dealers [5521]',
  'Used Household and Office Goods Moving [4213]',
  'Used Merchandise Stores [5932]',
  'Vending Machine Operators [5962]',
  'Veterinary Services [741]',
  'Veterinary Services [742]',
  'Video Tape and Disc Rental [7841]',
  'Vocational Rehabilitation Services [8331]',
  'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers [5075]',
  'Water Supply and Irrigation Systems [4941]',
  'Water Supply and Irrigation Systems [4971]',
  'Water and Sewer Line and Related Structures Construction [1623]',
  'Water and Sewer Line and Related Structures Construction [1629]',
  'Water and Sewer Line and Related Structures Construction [1781]',
  'Wet Corn Milling [2046]',
  'Wheat Farming [111]',
  'Window Treatment Stores [5714]',
  'Wine and Distilled Alcoholic Beverage Merchant Wholesalers [5182]',
  'Wineries [2084]',
  'Wired Telecommunications Carriers [4813]',
  'Wired Telecommunications Carriers [4822]',
  'Wireless Telecommunications Carriers (Except Satellite) [4812]',
  "Women's Handbag and Purse Mfg. [3171]",
  "Women's, Girls', and Infants' Cut and Sew Apparel Mfg. [2331]",
  'Womens Clothing Stores [5621]',
  'Womens, Childrens, and Infants Clothing and Accessories Merchant Wholesalers [5137]',
  'Wood Container and Pallet Mfg. [2441]',
  'Wood Container and Pallet Mfg. [2448]',
  'Wood Container and Pallet Mfg. [2449]',
  'Wood Container and Pallet Mfg. [2499]',
  'Wood Kitchen Cabinet and Countertop Mfg. [2434]',
  'Wood Office Furniture Mfg. [2521]',
  'Wood Preservation [2491]',
  'Wood Window and Door Mfg. [2431]',
  'Zoos and Botanical Gardens [8422]',
];
