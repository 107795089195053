import React, { useCallback, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';

import { ReactComponent as PurpleCircle } from 'src/assets/app/purpleCircle.svg';
import { ReactComponent as CheckedPurpleCircle } from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './permissionsTable.module.scss';

export const PermissionsTable = () => {
  const [permissionTypes, setPermissionsTypes] = useState({
    owner: ['quoting', 'reporting', 'billing', 'all_quotes', 'user_access'],
    agency: ['quoting', 'reporting', 'all_quotes'],
    member: ['quoting', 'reporting'],
  });

  // eslint-disable-next-line no-unused-vars
  const onSelectPermissionType = useCallback(
    (permissionName, permissionType) => () => {
      const payload = {
        ...permissionTypes,
        [permissionName]: permissionTypes[permissionName]?.length
          ? [...permissionTypes[permissionName]]
          : [],
      };

      payload[permissionName] = [...payload[permissionName], permissionType];

      setPermissionsTypes(payload);
    },
    [permissionTypes],
  );

  // eslint-disable-next-line no-unused-vars
  const onRemovePermissionType = useCallback(
    (permissionName, permissionType) => () => {
      const payload = {
        ...permissionTypes,
      };

      payload[permissionName] = _.reject(payload[permissionName], (el) => el === permissionType);

      setPermissionsTypes(payload);
    },
    [permissionTypes],
  );

  const renderTableBodyItem = (permission, type) => {
    return (
      <TableCell align="center">
        {permissionTypes[permission]?.includes(type) ? (
          <span
            className={classes.PermissionsTableCheckIconDisabled}
            // onClick={onRemovePermissionType(permission, type)}
          >
            <CheckedPurpleCircle />
          </span>
        ) : (
          <span
            className={classes.PermissionsTableCircleIconDisabled}
            // onClick={onSelectPermissionType(permission, type)}
          >
            <PurpleCircle />
          </span>
        )}
      </TableCell>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">Owner</TableCell>
            <TableCell align="center">Agency</TableCell>
            <TableCell align="center">Member</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Quoting</TableCell>
            {renderTableBodyItem('owner', 'quoting')}
            {renderTableBodyItem('agency', 'quoting')}
            {renderTableBodyItem('member', 'quoting')}
          </TableRow>
          <TableRow>
            <TableCell>Reporting</TableCell>
            {renderTableBodyItem('owner', 'reporting')}
            {renderTableBodyItem('agency', 'reporting')}
            {renderTableBodyItem('member', 'reporting')}
          </TableRow>
          <TableRow>
            <TableCell>Access to all quotes</TableCell>
            {renderTableBodyItem('owner', 'all_quotes')}
            {renderTableBodyItem('agency', 'all_quotes')}
            {renderTableBodyItem('member', 'all_quotes')}
          </TableRow>
          <TableRow>
            <TableCell>User Access</TableCell>
            {renderTableBodyItem('owner', 'user_access')}
            {renderTableBodyItem('agency', 'user_access')}
            {renderTableBodyItem('member', 'user_access')}
          </TableRow>
          <TableRow>
            <TableCell>Billing</TableCell>
            {renderTableBodyItem('owner', 'billing')}
            {renderTableBodyItem('agency', 'billing')}
            {renderTableBodyItem('member', 'billing')}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
