import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';

import App from './app/App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';

import './index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0493f3fabd5efd338edf13e8e5f2ad7e@o4506690150924288.ingest.sentry.io/4506690155446272',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/monark-api-prod\.herokuapp\.com/],
        // monark-api-prod\.herokuapp\.com
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#1496e9',
        },
        colorSecondary: {
          backgroundColor: '#7833f3',
        },
        colorError: {
          backgroundColor: '#ff0000',
        },
        colorSuccess: {
          backgroundColor: '#2ecf00',
        },
        colorWarning: {
          backgroundColor: '#e9d314',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          a: {
            textDecoration: 'none',
            color: '#3273dc',

            '&:hover': {
              color: '#363636',
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '&.MuiTablePagination-root': {
            paddingTop: 15,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgb(25 118 210 / 20%)',
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
