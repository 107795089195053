import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import moment from 'moment';

import {
  getIssuerBrokers,
  isLoadingIssuerBrokersSelector,
  issuerBrokersMetaSelector,
  issuerBrokersSelector,
} from 'src/store/browseBrokers';

import { coverageOptions, dateOptions } from 'src/features/issuer/issuerRequests/issuerOptions';

import { EmptyField } from '../EmptyField/EmptyField';
import { Loader, LoaderWrapper } from '../Loader/Loader';
import { PageHeader } from '../PageHeader/PageHeader';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { MUISelect } from '../MuiSelect/MuiSelect';

import classes from './browseBrokersTable.module.scss';

const headerRows = [
  { id: 'sic_code', name: 'Account SIC', sortable: true },
  { id: 'acc_zip_code', name: 'Account Zip Code', sortable: false },
  { id: 'coverage', name: 'Coverage', sortable: false },
  { id: 'ee_count', name: 'Employee Count', sortable: false },
  { id: 'broker', name: 'Broker', sortable: false },
  { id: 'effective_date', name: 'Effective Date', sortable: true },
  { id: 'due_date', name: 'Due Date', sortable: true },
];

export const BrowseBrokersTable = () => {
  const dispatch = useDispatch();

  const brokersData = useSelector(issuerBrokersSelector);
  const brokersDataMeta = useSelector(issuerBrokersMetaSelector);
  const isLoading = useSelector(isLoadingIssuerBrokersSelector);

  const [selectedCoverage, setSelectedCoverage] = useState('all');
  const [selectedDate, setSelectedDate] = useState('all');

  const [currentPage, setCurrentPage] = useState(brokersDataMeta?.page ? brokersDataMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    brokersDataMeta?.per_page ? brokersDataMeta?.per_page : 10,
  );
  const [searchValue, setSearchValue] = useState('');
  const [sortMethod, setSortMethod] = useState('-effective_date');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  useEffect(() => {
    if (!searchValue) {
      const payload = {
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
        due_date: selectedDate === 'all' ? '' : selectedDate,
        coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
      };

      dispatch(getIssuerBrokers(payload));
    }
  }, [
    dispatch,
    currentPage,
    searchValue,
    selectedDate,
    selectedCoverage,
    selectedPageSize,
    sortMethod,
  ]);

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  const onSelectOption = useCallback((event, setSelectedOption) => {
    setSelectedOption(event.target.value);
  }, []);

  const onChangeSearchInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearchButton = useCallback(() => {
    if (searchValue) {
      dispatch(
        getIssuerBrokers({
          q: searchValue,
          sort: sortMethod,
          page: currentPage,
          per_page: selectedPageSize,
          due_date: selectedDate === 'all' ? '' : selectedDate,
          coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
        }),
      );
    }
  }, [
    dispatch,
    searchValue,
    sortMethod,
    currentPage,
    selectedPageSize,
    selectedDate,
    selectedCoverage,
  ]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        dispatch(
          getIssuerBrokers({
            q: searchValue,
            sort: sortMethod,
            page: currentPage,
            per_page: selectedPageSize,
            due_date: selectedDate === 'all' ? '' : selectedDate,
            coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
          }),
        );
      }
    },
    [
      dispatch,
      searchValue,
      sortMethod,
      currentPage,
      selectedPageSize,
      selectedDate,
      selectedCoverage,
    ],
  );

  const onClearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  return (
    <div className={classes.BrowseBrokersTable}>
      <PageHeader title="Browse RFPs">
        <div className={classes.BrowseBrokersTablePageContent}>
          <div className={classes.BrowseBrokersTablePageContentSelect}>
            <MUISelect
              label="Coverage"
              value={selectedCoverage}
              options={coverageOptions}
              onChange={(event) => onSelectOption(event, setSelectedCoverage)}
              minWidth={140}
            />
          </div>
          <div className={classes.BrowseBrokersTablePageContentSelect}>
            <MUISelect
              label="Due Date"
              value={selectedDate}
              options={dateOptions}
              onChange={(event) => onSelectOption(event, setSelectedDate)}
            />
          </div>
          <div className={classes.BrowseBrokersTableSearch}>
            <div className={classes.BrowseBrokersTableSearchWrapper}>
              <Input
                label="Search"
                value={searchValue}
                onChange={onChangeSearchInput}
                onKeyDown={searchOnKeyDown}
              />
              {searchValue && (
                <i
                  onClick={onClearInput}
                  className={`fas fa-plus ${classes.BrowseBrokersTableSearchClear}`}
                />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-search"
              onClick={onClickSearchButton}
              isLoading={isLoading}
            />
          </div>
        </div>
      </PageHeader>

      <Paper>
        {isLoading ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <>
            {brokersData?.length > 0 ? (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        {headerRows?.map((item) => (
                          <TableCell key={item.id}>
                            {item.sortable ? (
                              <TableSortLabel
                                active={selectedCell === item.id}
                                direction={cellDirection}
                                onClick={onChangeCellSort(item.id)}
                              >
                                {item.name}
                              </TableSortLabel>
                            ) : (
                              item.name
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {brokersData.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                        >
                          <TableCell component="th" scope="row">
                            {item?.account_sic_code}
                          </TableCell>
                          <TableCell component="th" scope="row" width="150">
                            {item?.account_zip_code}
                          </TableCell>
                          <TableCell component="th" scope="row" width="200">
                            {item?.lines_of_coverage?.join(', ')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item?.group_size}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item?.broker?.display_name}
                            <br />
                            {item?.broker?.address_street && `${item?.broker?.address_street} `}
                            {item?.broker?.address_city && `${item?.broker?.address_city}, `}
                            {item?.broker?.address_state && `${item?.broker?.address_state} `}
                            {item?.broker?.address_zip_code && `${item?.broker?.address_zip_code}`}
                            <br />
                            {item?.broker?.email && `${item?.broker?.email}`}
                            <br />
                            {item?.broker?.phone_number && `${item?.broker?.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(item?.effective_date).format('l')}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              color: moment().isAfter(moment(item?.due_date).format('MM-DD-YYYY'))
                                ? 'red'
                                : 'black',
                            }}
                          >
                            {moment(item?.due_date).format('l')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  component="div"
                  count={brokersDataMeta?.total ?? 0}
                  rowsPerPage={selectedPageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onChangePageSize}
                />
              </>
            ) : (
              <EmptyField title="There are no available RFPs." />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
