import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CircleCloseIcon from '../../assets/proposal/circleClose.svg';

import classes from './modal.module.scss';

export const Modal = (props) => {
  const {
    onClose,
    title = '',
    children,
    type = 'small',
    closeButtonType = 'default',
    isScrollable = true,
  } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const onEscapeClose = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', onEscapeClose);

    return () => {
      window.removeEventListener('keydown', onEscapeClose);
    };
  }, [onClose]);

  const modalStyles = classNames({
    [classes.Modal]: type === 'small',
    [classes.ModalLarge]: type === 'large',
    [classes.ModalXLarge]: type === 'xlarge',
    [classes.ModalFit]: type === 'fit',
    [classes.ModalAuto]: type === 'auto',
  });

  return (
    <div className={classes.ModalContent}>
      <div className={classes.ModalBackdrop} onClick={onClose} />
      {closeButtonType === 'default' && (
        <div className={classes.ModalClose} onClick={onClose}>
          <i className="fas fa-times" />
        </div>
      )}
      <div className={modalStyles} id="modal" style={{ overflowY: isScrollable ? 'auto' : 'none' }}>
        {closeButtonType === 'inside' && (
          <img
            src={CircleCloseIcon}
            className={classes.ModalCloseInside}
            onClick={onClose}
            alt="CircleCloseIcon"
            id="modalCloseIcon"
          />
        )}
        {title && <div className={classes.ModalTitle}>{title}</div>}
        <div className={classes.ModalWrapper}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  closeButtonType: PropTypes.string,
  isScrollable: PropTypes.bool,
};
