import React from 'react';
import { Outlet } from 'react-router-dom';
import moment from 'moment';

import { Header } from 'src/features/header/Header';
import { Footer } from 'src/features/footer/Footer';

import classes from './layoutMain.module.scss';
import classNames from 'classnames';

export const LayoutMain = (props) => {
  const { withComponents = true, children } = props;

  const layoutMainStyles = classNames({
    [classes.LayoutMain]: withComponents,
    [classes.LayoutMainHidden]: !withComponents,
  });

  const layoutMainWrapperStyles = classNames({
    [classes.LayoutMainWrapper]: withComponents,
    [classes.LayoutMainWrapperHidden]: !withComponents,
  });

  const layoutMainContentStyles = classNames({
    [classes.LayoutMainContent]: withComponents,
    [classes.LayoutMainContentHidden]: !withComponents,
  });

  return (
    <div className={layoutMainStyles}>
      <div className={layoutMainWrapperStyles}>
        {withComponents && <Header />}
        <div className={layoutMainContentStyles}>{children ?? <Outlet />}</div>
        {withComponents && <Footer />}
        {!withComponents && (
          <div className={classes.LayoutMainFooter}>
            <div className={classes.LayoutMainFooterCopyright}>
              <i className="far fa-copyright" /> Powered by Monark HQ {moment().year()}
            </div>

            <div className={classes.LayoutMainFooterCookies}>cookies preference</div>
          </div>
        )}
      </div>
    </div>
  );
};
