import {
  annualMaxOptions,
  basicOptions,
  booleanOptions,
  compMethodOptions,
  deductibleOptions,
  dentalPlanTypeOptions,
  fundingTypeOptions,
  majorOptions,
  networkCoverageOptions,
  orthodonticCoverageOptions,
  orthodonticOptions,
  preventativeOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';

export const useDentalIssuerBuild = () => {
  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'dental',
    title: 'Dental',
    form_options: {
      contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Type',
          key: 'plan_type',
        },
        {
          title: 'Orthodontics',
          key: 'orthodontics',
        },
        {
          title: 'Implant Coverage',
          key: 'implant_coverage',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          title: 'Annual Max',
          key: 'dppo_annual_max',
          format: (value) => (value ? value : 'No Max'),
        },
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    offer_details: [
      {
        key: 'funding_type',
        format: (value) => normalizeFundingType(value),
        options: { render_type: 'select', render_data: fundingTypeOptions },
      },
      {
        key: 'waiting_period',
        format: (value) => (value ? 'Yes' : 'No'),
        options: { render_type: 'select', render_data: booleanOptions },
      },
      {
        key: 'plan_type',
        render: (value) =>
          value === 'PPO'
            ? [
                {
                  key: 'dppo_out_coverage',
                  options: { render_type: 'select', render_data: networkCoverageOptions },
                  format: (value) => value,
                },
                {
                  key: 'dppo_annual_max',
                  options: {
                    render_type: 'select',
                    render_data: annualMaxOptions,
                    type: 'other',
                    symbol: '$',
                    inputType: 'number',
                    minValue: 0,
                  },
                  format: (value) => formattedValue(value),
                },
                {
                  key: 'dppo_deductible',
                  options: { render_type: 'select', render_data: deductibleOptions },
                  format: (value) => formattedValue(value),
                },
                {
                  key: 'dppo_preventative',
                  options: { render_type: 'select', render_data: preventativeOptions },
                  format: (value) => `${value}%`,
                },
                {
                  key: 'dppo_basic',
                  options: { render_type: 'select', render_data: basicOptions },
                  format: (value) => `${value}%`,
                },
                {
                  key: 'dppo_major',
                  options: { render_type: 'select', render_data: majorOptions },
                  format: (value) => `${value}%`,
                },
              ]
            : [],
        options: { render_type: 'select', render_data: dentalPlanTypeOptions, disabled: true },
      },
      {
        key: 'orthodontics',
        options: { render_type: 'select', render_data: orthodonticOptions, disabled: true },
        render: (value) =>
          value !== 'none'
            ? [
                {
                  key: 'orthodontics_coverage',
                  options: { render_type: 'select', render_data: orthodonticCoverageOptions },
                  format: (value) => formattedValue(value),
                },
              ]
            : [],
      },
      {
        key: 'implant_coverage',
        format: (value) => (value ? 'Yes' : 'No'),
        options: { render_type: 'select', render_data: booleanOptions },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
