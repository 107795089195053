import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import './phoneInput.scss';
import 'react-phone-input-2/lib/style.css';
import classes from './phoneInput.module.scss';

export const InputPhone = (props) => {
  const { value, label, onChange, isRequired, tooltipText } = props;

  return (
    <div className={classes.PhoneInputWrapper}>
      {label && (
        <label>
          {label}
          {isRequired && <span className={classes.RequiredStart}>*</span>}
          {tooltipText && (
            <Tooltip title={tooltipText} className={classes.Tooltip}>
              <i className="fas fa-info-circle" />
            </Tooltip>
          )}
        </label>
      )}
      <div className={classes.PhoneInputContent}>
        <PhoneInput
          country={'us'}
          onlyCountries={['us']}
          inputClass={classes.PhoneInputNumber}
          value={value}
          onChange={onChange}
          inputProps={{
            required: isRequired,
          }}
        />
      </div>
    </div>
  );
};

InputPhone.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  tooltipText: PropTypes.string,
};
