import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';
import { setAccountData } from 'src/store/broker';

import { AccountTable } from 'src/components';

import classes from './agencyAccounts.module.scss';

export const AgencyAccounts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Monark | Accounts';
  }, []);

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  return (
    <div className={classes.AgencyAccounts}>
      <div className={classes.AgencyAccountsTableWrapper}>
        <AccountTable />
      </div>
    </div>
  );
};
