import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  agencyMembersSelector,
  agencyUserRoleSelector,
  deleteAgencyMember,
  isLoadingDeleteMemberSelector,
  setAgencyMembers,
  updateUserRole,
} from 'src/store/agency';

import { Button, Loader, LoaderWrapper, MUISelect, Modal } from 'src/components';

import classes from './agencyTeam.module.scss';

export const AgencyTeam = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const teamId = params?.teamId;

  const agencyMembers = useSelector(agencyMembersSelector);
  const agencyUserRole = useSelector(agencyUserRoleSelector);
  const isLoadingDelete = useSelector(isLoadingDeleteMemberSelector);

  const [selectedMember, setSelectedMember] = useState({});
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const userPermissionsData = [
    { title: 'Owner', value: 'owner' },
    { title: 'Agency', value: 'admin' },
    { title: 'Member', value: 'member' },
  ];

  const onOpenModal = useCallback(
    (item) => () => {
      setIsVisibleModal(true);
      setSelectedMember(item);
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    setIsVisibleModal(false);
    setSelectedMember({});
  }, []);

  const onSelectOption = useCallback(
    (event, id) => {
      const payload = {
        teamId,
        memberId: id,
        payload: {
          role: event.target.value,
        },
      };

      const updatedMembers = [];

      for (const member of agencyMembers) {
        if (member.id === id) {
          const payload = {
            ...member,
            role: event.target.value,
          };
          updatedMembers.push(payload);
        } else {
          updatedMembers.push(member);
        }
      }

      dispatch(setAgencyMembers(updatedMembers));

      dispatch(updateUserRole(payload));
    },
    [dispatch, teamId, agencyMembers],
  );

  const onSubmitDeleteMember = useCallback(async () => {
    const payload = {
      teamId,
      memberId: selectedMember.id,
    };

    await dispatch(deleteAgencyMember(payload));

    onCloseModal();
  }, [dispatch, onCloseModal, teamId, selectedMember]);

  return (
    <>
      {isVisibleModal && (
        <Modal onClose={onCloseModal} closeButtonType="inside">
          {isLoadingDelete ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <div className={classes.ModalContent}>
              <div className={classes.ModalContentTitle}>
                Are you sure you want to delete this member?
              </div>
              <div
                className={classes.ModalContentName}
              >{`${selectedMember?.display_name} (${selectedMember?.email})`}</div>
              <div className={classes.ModalContentFooter}>
                <Button title="Yes" type="danger" onClick={onSubmitDeleteMember} />
                <Button title="No" type="secondary" onClick={onCloseModal} />
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className={classes.AgencyTeam}>
        <div className={classes.AgencyTeamWrapper}>
          <Paper>
            {agencyMembers?.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell width="120">User Type</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {agencyMembers?.map((item) => {
                      const permissionsData =
                        item.role !== 'owner'
                          ? userPermissionsData.filter((item) => item.value !== 'owner')
                          : userPermissionsData;

                      return (
                        <TableRow key={item.id}>
                          <TableCell>
                            <div className={classes.AgencyTeamUserImageWrapper}>
                              {item.photo_url ? (
                                <img
                                  src={item.photo_url}
                                  alt={item.photo_url}
                                  className={classes.AgencyTeamUserImage}
                                />
                              ) : (
                                <div className={classes.AgencyTeamUserName}>
                                  {item.display_name?.match(/[A-Z]/g, '')?.join('') ??
                                    item.display_name?.slice(0, 1)}
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{item.display_name}</TableCell>
                          <TableCell className={classes.AgencyTeamUserEmail}>
                            {item.email}
                          </TableCell>
                          <TableCell>
                            <div className={classes.AgencyTeamPermission}>
                              {agencyUserRole === 'owner' ? (
                                <MUISelect
                                  label="User Type"
                                  value={item.role}
                                  options={permissionsData}
                                  onChange={(event) => onSelectOption(event, item.id)}
                                  disabled={item.role === 'owner'}
                                />
                              ) : (
                                item.role
                              )}
                            </div>
                          </TableCell>
                          {agencyUserRole === 'owner' && (
                            <TableCell>
                              {item.role !== 'owner' && (
                                <i
                                  className={`fa fa-trash-o ${classes.AgencyTeamTrashIcon}`}
                                  onClick={onOpenModal(item)}
                                />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
};
