import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { setAccountData } from 'src/store/broker';

import { BrokerContactsTable } from 'src/components';

import classes from './agencyContacts.module.scss';

export const AgencyContacts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Monark | Contacts';
  }, []);

  return (
    <>
      <div className={classes.AgencyContacts}>
        <div className={classes.AccountTableWrapper}>
          <BrokerContactsTable />
        </div>
      </div>
    </>
  );
};
