import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useMedicalBuild } from './useMedicalBuild/useMedicalBuild';

export const MedicalQuotes = () => {
  const { build } = useMedicalBuild();

  return <CoverageBuilder build={build} />;
};
