import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  createNewQuote,
  fetchQuotesRates,
  getAccountProposalSelector,
  getBasePlanQuote,
  getCarrierIssuersSelector,
  getIsLoadingQutesRates,
  getMedicalPlanType,
  getQuotesMeta,
  getQuotesRates,
  getSelectedEditQuote,
  getSelectedQuotes,
  isProposalFinalizeSelector,
  setQuotesRates,
  setSelectedEditQuote,
  setSelectedMedicalPlans,
  setSelectedQuotes,
  updateQuote,
} from 'src/store/proposals';
import { getAccountData } from 'src/store/broker';

import {
  Button,
  DropdownComponent,
  Loader,
  LoaderWrapper,
  Modal,
  QuotesTable,
  SelectedQuotesTable,
} from 'src/components';
import { CoverageForm } from 'src/features/proposal/coverageForm/CoverageForm';
import { Pagination } from 'src/features/pagination/Pagination';

import classes from './createMedicalQuote.module.scss';

export const CreateMedcialQuote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const isReadOnlyMode = pathname.includes('/read-only');

  const accountProposalData = useSelector(getAccountProposalSelector);
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const isProposalFinalize = useSelector(isProposalFinalizeSelector);
  const isQuotes = pathname?.includes('/quotes');

  const quotesRates = useSelector(getQuotesRates);
  const quotesMeta = useSelector(getQuotesMeta);
  const planToMap = useSelector(getMedicalPlanType);
  const basePlanQuote = useSelector(getBasePlanQuote);
  const selectedEditQuote = useSelector(getSelectedEditQuote);
  const selectedQoutes = useSelector(getSelectedQuotes);
  const carrierIssuers = useSelector(getCarrierIssuersSelector);
  const isLoadingQuotesRates = useSelector(getIsLoadingQutesRates);
  const accountData = useSelector(getAccountData);

  const isMedicalQuoting = accountData?.address_state === 'CA';

  const [isVirginCoverage, setIsVirginCoverage] = useState(false);
  const [quotesMetaData, setQuotesMetaData] = useState({});
  const [quoteType, setQuoteType] = useState('standard');

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!_.isEmpty(selectedEditQuote)) {
      setIsVirginCoverage(true);
    }
  }, [selectedEditQuote]);

  useEffect(() => {
    if (!_.isEmpty(selectedEditQuote)) {
      if (selectedEditQuote.quote_type === 'standard') {
        setIsVirginCoverage(true);
        setQuoteType('standard');
      }
      if (selectedEditQuote.quote_type === 'advanced') {
        setIsVirginCoverage(true);
        setQuoteType('advanced');
      }
    }
  }, [selectedEditQuote]);

  const onCloseOptions = useCallback(async () => {
    setQuotesMetaData({});
    setIsVirginCoverage(false);

    setQuoteType('');
    await dispatch(setSelectedQuotes([]));
    await dispatch(setSelectedMedicalPlans({}));
    await dispatch(setSelectedEditQuote({}));
    await dispatch(setQuotesRates([]));
  }, [dispatch]);

  const onChangePage = useCallback(
    (page) => {
      setCurrentPage(page);

      const { payload, quote } = quotesMetaData;

      payload.page = page;

      dispatch(fetchQuotesRates({ accountId, payload, quote, planToMap }));
    },
    [dispatch, quotesMetaData, accountId, planToMap],
  );

  const renderPagination = useCallback(() => {
    return (
      <Pagination
        currentPage={currentPage}
        totalCount={quotesMeta?.total ?? 0}
        onPageChange={onChangePage}
      />
    );
  }, [currentPage, quotesMeta?.total, onChangePage]);

  const onCreateNewQuote = useCallback(() => {
    const { payload, quote } = quotesMetaData;

    let plans = [];
    if (quoteType === 'standard') {
      for (const quote of selectedQoutes) {
        plans.push(quote.id);
      }
    }

    let convertedQuotes = {};
    if (quoteType === 'advanced') {
      for (const quote of selectedQoutes) {
        convertedQuotes = {
          ...convertedQuotes,
          [quote.current_plan_name]: quote.id,
        };
      }
    }
    if (quoteType === 'advanced') {
      const quoteMappingLength = Object.keys(quotesMetaData.quote.mapping).length;
      const convertedQuoteMappingLength = Object.keys(convertedQuotes).length;

      if (quoteMappingLength !== convertedQuoteMappingLength) {
        return toast('All quote plans should be mapped!', { type: 'warning' });
      }
    }

    if (!quote?.quote_name) {
      return toast('Quote name cannot be empty!', { type: 'warning' });
    }

    if (quote?.quote_name?.length > 25) {
      return toast('Quote name cannot be more than 25 characters', { type: 'warning' });
    }

    const newQuote = {
      contribution_dep: quote?.contribution_dep,
      contribution_ee: quote?.contribution_ee,
      contribution_type: quote?.contribution_type,
      contribution_source: quote?.contribution_source,
      effective_date: payload?.filters?.effective_date,
      mapping: convertedQuotes,
      name: quote?.quote_name,
      quote_type: quoteType,
      base_plan_id: basePlanQuote.id ?? '',
      plans,
    };

    onCloseOptions();
    if (_.isEmpty(selectedEditQuote)) {
      dispatch(createNewQuote({ accountId, proposalId, payload: newQuote }));
    }

    if (!_.isEmpty(selectedEditQuote)) {
      dispatch(
        updateQuote({ accountId, proposalId, quoteId: selectedEditQuote.id, payload: newQuote }),
      );
    }
  }, [
    dispatch,
    onCloseOptions,
    basePlanQuote,
    accountId,
    proposalId,
    quotesMetaData,
    selectedQoutes,
    quoteType,
    selectedEditQuote,
  ]);

  const onOpenOptions = useCallback(() => {
    setIsVirginCoverage(true);
    setQuoteType('standard');
  }, []);

  const onClickCreateQuote = useCallback(() => {
    navigate('?activeTab=medical');
  }, [navigate]);

  return (
    <>
      {isVirginCoverage && (
        <Modal type="fit" closeButtonType="inside" onClose={onCloseOptions}>
          <div className={classes.VirginCoverage}>
            <div className={classes.VirginCoverageFormWrapper}>
              <div className={classes.VirginCoverageTitle}>Create New Quote</div>
              <CoverageForm
                isVirginCoverage={isVirginCoverage}
                setIsVirginCoverage={setIsVirginCoverage}
                setQuotesMetaData={setQuotesMetaData}
                quoteType={quoteType}
                setQuoteType={setQuoteType}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            {isLoadingQuotesRates ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : quotesRates.length > 0 ? (
              <DropdownComponent title="Results">
                {renderPagination()}
                <QuotesTable
                  tableData={quotesRates}
                  quoteType={quoteType}
                  selectedQoutes={selectedQoutes}
                  chooseQuote={setSelectedQuotes}
                />
                <div className={classes.Spacer}>{renderPagination()}</div>
              </DropdownComponent>
            ) : (
              <>
                {quotesMeta?.total === 0 && (
                  <div className={classes.SearchResultWarning}>
                    There are no results available (yet) for your effective date.
                  </div>
                )}
                {carrierIssuers?.length === 0 && (
                  <div className={classes.SearchResultWarning}>
                    No plans available for this effective date
                  </div>
                )}
              </>
            )}
            {selectedQoutes.length > 0 && (
              <DropdownComponent title={`Selected Rates (${selectedQoutes.length})`}>
                <SelectedQuotesTable
                  selectedQoutes={selectedQoutes}
                  accountProposalData={accountProposalData}
                  quotesMetaData={quotesMetaData}
                  isEdit
                />
              </DropdownComponent>
            )}
            {selectedQoutes.length > 0 && (
              <div className={classes.CreateQuoteButton}>
                <Button
                  title={!_.isEmpty(selectedEditQuote) ? 'Update Quote' : 'Create Quote'}
                  type="primary"
                  onClick={onCreateNewQuote}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <div className={classes.ProposalViewQuotesContent}>
        {!isReadOnlyMode && !isProposalFinalize && (
          <div className={classes.ProposalViewQuotesButton}>
            {isMedicalQuoting && (
              <Button
                title="Instant Quote"
                type="primary"
                onClick={onOpenOptions}
                tooltip="Instant quoting is currently only available in CA"
              />
            )}
          </div>
        )}
        {isProposalFinalize && isQuotes && (
          <div className={classes.ProposalViewQuotesWrapper}>
            <Button title="Create Medical Quote" type="primary" onClick={onClickCreateQuote} />
          </div>
        )}
      </div>
    </>
  );
};
