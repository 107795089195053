import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';
import { toast } from 'react-toastify';

export const getBrokerLicenses = createAsyncThunk(
  'broker-licenses/get-broker-licenses',
  async (data, thunkApi) => {
    try {
      const { brokerId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.brokerLicenses.getBrokerLicenses(brokerId);

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const createBrokerLicense = createAsyncThunk(
  'broker-licenses/create-broker-license',
  async (data, thunkApi) => {
    try {
      const { brokerId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.brokerLicenses.createBrokerLicense(brokerId, payload);

      thunkApi.dispatch(getBrokerLicenses({ brokerId }));

      toast('License created successfully', { type: 'success' });

      return response.items;
    } catch (err) {
      if (err?.error === 'Error: resource already exists') {
        return toast('You already have a license for this state', { type: 'error' });
      }

      toast(err?.error, { type: 'error' });
    }
  },
);

export const updateBrokerLicense = createAsyncThunk(
  'broker-licenses/update-broker-license',
  async (data, thunkApi) => {
    try {
      const { brokerId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.brokerLicenses.updateBrokerLicense(brokerId, payload);

      thunkApi.dispatch(getBrokerLicenses({ brokerId }));

      toast('License updated successfully', { type: 'success' });

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const deleteBrokerLicense = createAsyncThunk(
  'broker-licenses/delete-broker-license',
  async (data, thunkApi) => {
    try {
      const { brokerId, licenseId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.brokerLicenses.deleteBrokerLicense(brokerId, licenseId);

      thunkApi.dispatch(getBrokerLicenses({ brokerId }));

      toast('License deleted successfully', { type: 'success' });

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);
