import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { auth } from 'src/app/database';

import { Loader, LoaderWrapper } from 'src/components';

import { ROUTE } from 'src/constants/routes';
import { getUserClaimsSelector } from 'src/store/app';

export const ProtectedAdminRoute = () => {
  const storedClaims = useSelector(getUserClaimsSelector);

  const [customClaims, setCustomClaims] = useState();

  const [firebaseUser, loadingFirebaseUser] = useAuthState(auth, {
    onUserChanged: async (user) => {
      if (_.isEmpty(storedClaims)) {
        const response = await user.getIdTokenResult();

        setCustomClaims(response?.claims);
      }
    },
  });

  useEffect(() => {
    if (!_.isEmpty(storedClaims)) {
      setCustomClaims(storedClaims);
    }
  }, [storedClaims]);

  useEffect(() => {
    if (!_.isEmpty(customClaims)) {
      if (!customClaims?.is_superadmin) {
        toast('You don`t have access to this page', { type: 'error' });
      }
    }
  }, [customClaims]);

  return loadingFirebaseUser ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : firebaseUser && (customClaims?.is_superadmin || storedClaims?.is_superadmin) ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE.ADMIN_LOGIN} />
  );
};
