import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { agencyUserRoleSelector, brokerAgencySelector } from 'src/store/agency';
import { getAccountData, getBrokerAccountData, getBrokerData } from 'src/store/broker';
import { groupMembersSelector } from 'src/store/groupMembers';
import {
  getAccountProposal,
  getAccountProposalSelector,
  getlastChatMessagesSelector,
} from 'src/store/proposals';
import {
  getRequestedProposalsStatus,
  requestedProposalsStatusSelector,
} from 'src/store/planConfiguration';
import { getPetQuotes, petInsuranceDataSelector } from 'src/store/pet';
import {
  chatRoomsSelector,
  getChatRooms,
  isLoadingStartChatSelector,
  startChat,
} from 'src/store/chat';
import { DATE_TYPE } from 'src/constants/dateTypes';

import { MenuItem } from '../MenuItem/MenuItem';
import { MenuSubItem } from '../MenuISubtem/MenuISubtem';

import classes from './agencyMenu.module.scss';

export const AgencyMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');

  const userRole = useSelector(agencyUserRoleSelector);
  const brokerData = useSelector(getBrokerData);
  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getAccountProposalSelector);
  const proposalMembers = useSelector(groupMembersSelector);
  const agencyData = useSelector(brokerAgencySelector);
  const requestedProposalsStatus = useSelector(requestedProposalsStatusSelector);
  const petInsuranceData = useSelector(petInsuranceDataSelector);
  const lastChatMessages = useSelector(getlastChatMessagesSelector);
  const chatRooms = useSelector(chatRoomsSelector);
  const isLoadingStartChat = useSelector(isLoadingStartChatSelector);

  const accountId = params?.id;
  const proposalId = params?.proposalId;
  const teamId = params?.teamId;

  const isActiveProposals = pathname?.includes('accounts') && pathname?.includes('proposals');
  const isActiveAccounts = pathname?.includes('accounts') && !pathname?.includes('proposals');
  const isActiveReporting = pathname?.includes('reporting');
  const isSettings = pathname?.includes('settings');

  const medicalInstantQuotes = proposalData?.groups?.length ? proposalData?.groups[0]?.quotes : [];
  const isNewMessage =
    lastChatMessages?.user_last_message_id !== lastChatMessages?.chat_last_message_id;

  const isProposalData = !_.isEmpty(proposalData);
  const isGroupMembers = !_.isEmpty(proposalMembers);

  const isProposalMode = !_.isEmpty(accountData) && !_.isEmpty(proposalData);

  const updatedPathname = `${pathname}${search ? search : ''}`;

  const isVisibleChat = !_.isEmpty(agencyData) && agencyData.status === 'active';
  // (proposalData?.status === 'submitted' || proposalData?.status === 'approved');

  const [isVisibleSettings, setIsVisibleSettings] = useState(false);

  // const [isVisibleQuotes, setIsVisibleQuotes] = useState(true);

  useEffect(() => {
    if (userRole) {
      if (userRole === 'owner') {
        setIsVisibleSettings(true);
      } else {
        setIsVisibleSettings(false);
      }
    }
  }, [userRole]);

  useEffect(() => {
    if (_.isEmpty(proposalData) && accountId && proposalId) {
      dispatch(getAccountProposal({ accountId, proposalId }));
      dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
      dispatch(getPetQuotes({ accountId, proposalId }));

      const payload = {
        'f[proposal_id]': proposalId,
      };
      dispatch(getChatRooms(payload));
    }
  }, [dispatch, proposalData, accountId, proposalId]);

  useEffect(() => {
    if (_.isEmpty(accountData) && accountId && proposalId) {
      dispatch(getBrokerAccountData({ paramsId: accountId }));
    }
  }, [dispatch, accountData, accountId, proposalId]);

  useEffect(() => {
    if (
      !_.isEmpty(proposalData) &&
      (proposalData?.status === 'submitted' || proposalData?.status === 'approved')
    ) {
      const period = setInterval(() => {
        let payload = {
          'f[proposal_id]': proposalId,
        };

        if (accountId && proposalId) {
          dispatch(getChatRooms(payload));
        }
      }, 10000);

      return () => {
        clearInterval(period);
      };
    }
  }, [dispatch, accountId, proposalData, proposalId]);

  const isVisibleProposalMenu = useCallback(() => {
    switch (updatedPathname) {
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/new`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/census/new`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=medical`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=dental`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=vision`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=life`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=disability`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=accident`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=hospital`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=critical_illness`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=pet`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/chat`:
      case `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`:
        return true;
      default:
        return false;
    }
  }, [updatedPathname, teamId, accountData, proposalData]);

  const onClickQuotes = useCallback(() => {
    // setIsVisibleQuotes((prev) => !prev);

    navigate(`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`);
  }, [navigate, teamId, accountData?.id, proposalData?.id]);

  const onClickRouteTo = useCallback(
    (tab) => {
      return `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=${tab}`;
    },
    [teamId, accountData?.id, proposalData?.id],
  );

  const onClickSettings = useCallback(() => {
    navigate(`/teams/${teamId}/settings?activeTab=profile`);
  }, [navigate, teamId]);

  const onClickStartChat = useCallback(() => {
    const payload = {
      owner_id: brokerData.id,
      owner_type: 'broker',
      room_type: 'employer',
      proposal_id: proposalId,
      name: proposalData.name,
    };

    dispatch(startChat({ teamId, accountId, contacts: accountData.contacts, payload, navigate }));
  }, [dispatch, navigate, brokerData, teamId, accountId, accountData, proposalId, proposalData]);

  const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');

  return (
    <div className={classes.AgencyMenu}>
      <MenuItem
        title="Accounts"
        routeTo={`/teams/${teamId}/accounts`}
        isActive={isActiveAccounts}
        isDisabled={agencyData?.status === 'inactive'}
      />
      <MenuItem
        title="Proposals"
        routeTo={`/teams/${teamId}/proposals`}
        isActive={isActiveProposals}
        isDisabled={agencyData?.status === 'inactive'}
      />
      <MenuItem
        title="Contacts"
        routeTo={`/teams/${teamId}/contacts`}
        isDisabled={agencyData?.status === 'inactive'}
      />
      <MenuItem
        title="Reporting"
        routeTo={`/teams/${teamId}/reporting?start=${startDate}&end=${endDate}&interval=${DATE_TYPE.day}`}
        isActive={isActiveReporting}
        isDisabled={agencyData?.status === 'inactive'}
      />
      {isVisibleSettings && (
        <MenuItem
          title="Settings"
          isLink={false}
          onClick={onClickSettings}
          isDisabled={agencyData?.status === 'inactive'}
        />
      )}
      {isSettings && isVisibleSettings && (
        <>
          <MenuSubItem
            title="Profile"
            routeTo="?activeTab=profile"
            isActive={activeTab === 'profile'}
            isDisabled={agencyData?.status === 'inactive'}
          />
          <MenuSubItem
            title="Users"
            routeTo="?activeTab=users"
            isActive={activeTab === 'users'}
            isDisabled={agencyData?.status === 'inactive'}
          />
          <MenuSubItem
            title="Billing"
            routeTo="?activeTab=billing"
            isActive={activeTab === 'billing'}
          />
        </>
      )}
      {isVisibleProposalMenu() && (
        <>
          <hr className={classes.AgencyMenuLine} />
          <MenuItem
            title="Account Info"
            routeTo={`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`}
            isDisabled={!isProposalData}
          />
          {_.isEmpty(proposalData) && (
            <MenuItem
              title="Proposal Setup"
              routeTo={
                isProposalMode
                  ? `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`
                  : `/teams/${teamId}/accounts/${accountData?.id}/proposals/new`
              }
            />
          )}

          <MenuItem
            title="Census"
            routeTo={
              isProposalMode && isGroupMembers
                ? `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`
                : `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/census/new`
            }
            isDisabled={!isProposalData}
            isWarning={isProposalData && !isGroupMembers}
          />
          <MenuItem
            title="Quotes"
            onClick={onClickQuotes}
            isDisabled={!isGroupMembers}
            routeTo={`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`}
            isLink={false}
          />
          {/* {isVisibleQuotes && (
            <> */}
          <MenuSubItem
            title="Medical"
            routeTo={onClickRouteTo('medical')}
            isActive={activeTab === 'medical'}
            isVisibleStatus={
              requestedProposalsStatus?.medical > 0 || medicalInstantQuotes?.length > 0
            }
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Dental"
            routeTo={onClickRouteTo('dental')}
            isActive={activeTab === 'dental'}
            isVisibleStatus={requestedProposalsStatus?.dental > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Vision"
            routeTo={onClickRouteTo('vision')}
            isActive={activeTab === 'vision'}
            isVisibleStatus={requestedProposalsStatus?.vision > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Life"
            routeTo={onClickRouteTo('life')}
            isActive={activeTab === 'life'}
            isVisibleStatus={requestedProposalsStatus?.life > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Disability"
            routeTo={onClickRouteTo('disability')}
            isActive={activeTab === 'disability'}
            isVisibleStatus={requestedProposalsStatus?.disability > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Accident"
            routeTo={onClickRouteTo('accident')}
            isActive={activeTab === 'accident'}
            isVisibleStatus={requestedProposalsStatus?.accident > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Hospital"
            routeTo={onClickRouteTo('hospital')}
            isActive={activeTab === 'hospital'}
            isVisibleStatus={requestedProposalsStatus?.hospital > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Critical Illness"
            routeTo={onClickRouteTo('critical_illness')}
            isActive={activeTab === 'critical_illness'}
            isVisibleStatus={requestedProposalsStatus?.critical_illness > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuSubItem
            title="Pet"
            routeTo={onClickRouteTo('pet')}
            isActive={activeTab === 'pet'}
            isVisibleStatus={petInsuranceData?.quotes?.length > 0}
            isDisabled={!isGroupMembers}
          />
          <MenuItem
            title="Final Proposal"
            routeTo={`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`}
            isDisabled={!isGroupMembers}
          />

          {isVisibleChat && (
            <>
              {chatRooms?.length ? (
                <MenuItem
                  title="Chat"
                  subTitle="New"
                  routeTo={`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/chat`}
                  isDisabled={!isGroupMembers}
                  isVisibleStatus={isNewMessage}
                />
              ) : (
                <MenuItem
                  title="Start Chat"
                  subTitle="New"
                  onClick={onClickStartChat}
                  isDisabled={!isGroupMembers}
                  isLoading={isLoadingStartChat}
                  isLink={false}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
