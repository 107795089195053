import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import xlsx from 'xlsx';
import _ from 'lodash';

import {
  getAccountProposal,
  getAccountProposalSelector,
  isProposalFinalizeSelector,
} from 'src/store/proposals';
import {
  createGroupMembers,
  getGroupMembers,
  isLoadingCreateGroupMembersSelector,
} from 'src/store/groupMembers';

import { AddCensusMember } from '../addCensusMember/AddCensusMember';
import {
  Button,
  ErrorMessage,
  Loader,
  MembersTable,
  Modal,
  PageHeader,
  SearchSelect,
} from 'src/components';
import { UploadCensus } from '../proposalBuilder/components';

import { validateCensus, validateCensusHousehold } from 'src/constants/validateCensus';

import classes from './groupMembers.module.scss';

export const GroupMembers = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isReadOnlyMode = pathname.includes('/read-only');

  const accountProposalData = useSelector(getAccountProposalSelector);
  const isProposalFinalize = useSelector(isProposalFinalizeSelector);
  const isLoadingSave = useSelector(isLoadingCreateGroupMembersSelector);

  const [membersData, setMembersData] = useState([]);
  const [censusData, setCensusData] = useState([]);

  const [censusErros, setCensusErrors] = useState({});
  const [censusHouseholdErros, setCensusHouseholdErrors] = useState({});

  const [missingNames, setMissingNames] = useState([]);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [selectedNames, setSelectedNames] = useState({});
  const [errorCensusNames, setErrorCensusNames] = useState([]);
  const [isMissingNamesModal, setIsMissingNamesModal] = useState(false);

  const [isAcknowledgeModal, setIsAcknowledgeModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isVisibleGroupMembers, setIsVisibleGroupMembers] = useState(true);

  const onClickUplaod = useCallback((event) => {
    return (event.target.value = '');
  }, []);

  useEffect(() => {
    if (!isUpdateModal) {
      setMembersData([]);
      setCensusData([]);
    }
  }, [isUpdateModal]);

  useEffect(() => {
    if (missingNames) {
      let updatedPayload = {};
      for (const item of missingNames) {
        updatedPayload = {
          ...updatedPayload,
          [item]: '',
        };
      }
      setSelectedNames(updatedPayload);
    }
  }, [missingNames]);

  const validateTobaccoUser = (value) => {
    if (
      typeof value === 'string' &&
      (value?.toString().toLowerCase() === 'y' ||
        value?.toString().toLowerCase() === 'yes' ||
        value?.toString().toLowerCase() === 't' ||
        value?.toString().toLowerCase() === 'true')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateNumberValue = (value) => {
    if (typeof value === 'number' && value) {
      return value;
    } else {
      return 0;
    }
  };

  const uploadCsv = (e) => {
    e.preventDefault();
    setIsLoadingFile(true);

    const localCensus = {
      meta: {
        total: 0,
        limit: 10,
        offset: 0,
      },
      items: [],
    };

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array', cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: true });
        const importedHeaders = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        const originalFields = [
          'ee_id',
          'first_name',
          'last_name',
          'zip_code',
          'date_of_birth',
          'gender',
          'relationship',
          'current_medical_plan_name',
          'dental_plan_name',
          'vision_plan_name',
          'life_benefit_amount',
          'salary_amount',
          'job_title',
          'is_tobacco_user',
        ];

        let missingNames = originalFields.filter((item) => !importedHeaders.includes(item));

        if (missingNames.length > 0) {
          let missedValues = _.cloneDeep(importedHeaders);
          setMissingNames(missingNames);
          setCensusData(_.cloneDeep(json));

          for (const item of originalFields) {
            _.reject(missedValues, (val) => val === item);
          }

          if (missedValues.length > 0) {
            setErrorCensusNames(['Ignore', ...missedValues]);
            return setIsMissingNamesModal(true);
          }
        }

        let rowErrors = {};
        let householdErrors = {};

        try {
          rowErrors = validateCensus(json, localCensus, setIsLoadingFile);
        } catch (error) {
          console.warn('error', error);
        }

        try {
          householdErrors = validateCensusHousehold(json, householdErrors);
        } catch (error) {
          console.warn('error', error);
        }

        if (!_.isEmpty(rowErrors)) {
          setCensusErrors(rowErrors);
        }

        if (!_.isEmpty(householdErrors)) {
          setCensusHouseholdErrors(householdErrors);
        }

        if (_.isEmpty(rowErrors) && _.isEmpty(householdErrors)) {
          let updatedMembers = [];

          for (const member of localCensus.items) {
            if (member?.relationship === 'employee') {
              let payload = {
                ...member,
              };

              payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
              payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
              payload.salary_amount = validateNumberValue(payload.salary_amount);

              delete payload['Medical enrollment'];
              delete payload['Vision enrollment'];
              delete payload['Dental enrollment'];

              updatedMembers.push(payload);
            } else {
              let payload = {
                ...member,
                salary_amount: 0,
              };

              payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
              payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
              payload.salary_amount = validateNumberValue(payload.salary_amount);

              delete payload['Medical enrollment'];
              delete payload['Vision enrollment'];
              delete payload['Dental enrollment'];

              updatedMembers.push(payload);
            }
          }

          localCensus.meta.total = localCensus.items.length;
          setMembersData(updatedMembers);
          setCensusErrors({});
          setCensusHouseholdErrors({});
        }

        setIsLoadingFile(false);
      };

      reader.onerror = () => {
        e.target.files = null;
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    }
    setIsLoadingFile(false);
  };

  const onCloseErrorMessage = useCallback(
    (error) => () => {
      let payload = {
        ...censusErros,
      };

      delete payload[error];

      setCensusErrors(payload);
    },
    [censusErros],
  );

  const onCloseHouseholdaErrorMessage = useCallback(
    (error) => () => {
      let payload = {
        ...censusHouseholdErros,
      };

      delete payload[error];

      setCensusHouseholdErrors(payload);
    },
    [censusHouseholdErros],
  );

  const onSubmitCensus = useCallback(
    async (event) => {
      event.preventDefault();

      const localCensus = {
        meta: {
          total: 0,
          limit: 10,
          offset: 0,
        },
        items: [],
      };

      let rowErrors = {};
      let householdErrors = {};

      try {
        rowErrors = validateCensus(censusData, localCensus, setIsLoadingFile);
      } catch (error) {
        console.warn('error', error);
      }

      try {
        householdErrors = validateCensusHousehold(censusData, householdErrors);
      } catch (error) {
        console.warn('error', error);
      }

      if (!_.isEmpty(rowErrors)) {
        setCensusErrors(rowErrors);
      }

      if (!_.isEmpty(householdErrors)) {
        setCensusHouseholdErrors(householdErrors);
      }

      if (_.isEmpty(rowErrors) && _.isEmpty(householdErrors)) {
        let updatedMembers = [];

        for (const member of localCensus.items) {
          if (member?.relationship === 'employee') {
            let payload = {
              ...member,
            };

            payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
            payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
            payload.salary_amount = validateNumberValue(payload.salary_amount);

            delete payload['Medical enrollment'];
            delete payload['Vision enrollment'];
            delete payload['Dental enrollment'];

            updatedMembers.push(payload);
          } else {
            let payload = {
              ...member,
              salary_amount: 0,
            };

            payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
            payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
            payload.salary_amount = validateNumberValue(payload.salary_amount);

            delete payload['Medical enrollment'];
            delete payload['Vision enrollment'];
            delete payload['Dental enrollment'];

            updatedMembers.push(payload);
          }
        }

        localCensus.meta.total = localCensus.items.length;
        setMembersData(updatedMembers);
        setCensusErrors({});
        setCensusHouseholdErrors({});
        setIsMissingNamesModal(false);
      }

      setIsLoadingFile(false);
    },
    [censusData],
  );

  const onSelectValue = useCallback(
    (value, key) => {
      const payload = {
        ...selectedNames,
        [key]: value,
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [key]: value === 'Ignore' ? '' : item[value],
        };

        delete payload[value];

        updatedPayload.push(payload);
      }

      if (value !== 'Ignore') {
        setErrorCensusNames(errorCensusNames.filter((name) => name !== value));
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [selectedNames, censusData, errorCensusNames],
  );

  const onChangeValue = useCallback(
    (event, key) => {
      const payload = {
        ...selectedNames,
        [key]: event.target.value,
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [key]: item[event.target.value],
        };

        updatedPayload.push(payload);
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [censusData, selectedNames],
  );

  const onClearValue = useCallback(
    (key) => () => {
      setErrorCensusNames([...errorCensusNames, selectedNames[key]]);

      const payload = {
        ...selectedNames,
        [key]: '',
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [selectedNames[key]]: item[key],
        };

        delete payload[key];

        updatedPayload.push(payload);
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [errorCensusNames, selectedNames, censusData],
  );

  const handleShowGroupMembers = useCallback(() => {
    setIsVisibleGroupMembers((prev) => !prev);
  }, []);

  const handleShowUpload = useCallback(() => {
    setIsAcknowledgeModal((prev) => !prev);
  }, []);

  const onClickAcknowledge = useCallback(() => {
    setIsAcknowledgeModal(false);
    setIsUpdateModal((prev) => !prev);
  }, []);

  const onCloseModal = useCallback(() => {
    setErrorCensusNames([]);
    setCensusData([]);
    setMissingNames([]);
    setIsMissingNamesModal(false);
  }, []);

  const onClickSave = useCallback(async () => {
    if (membersData?.length > 0) {
      await dispatch(
        createGroupMembers({ accountId: accountProposalData?.account_id, members: membersData }),
      );

      toast('Members updated successfully', { type: 'success' });
      await dispatch(
        getGroupMembers({
          accountId: accountProposalData?.account_id,
          groupId: accountProposalData?.group_ids[0],
        }),
      );

      await dispatch(
        getAccountProposal({
          accountId: accountProposalData?.account_id,
          proposalId: accountProposalData?.id,
        }),
      );

      setMembersData([]);
      setCensusData([]);
      setMissingNames([]);
      setIsLoadingFile(false);
      setSelectedNames({});
      setErrorCensusNames([]);
      setIsMissingNamesModal(false);
      setIsAcknowledgeModal(false);
      setIsUpdateModal(false);
      setIsVisibleGroupMembers(true);
    }
  }, [dispatch, accountProposalData, membersData]);

  return (
    <>
      {isAcknowledgeModal && (
        <Modal type="small" closeButtonType="inside" onClose={handleShowUpload}>
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Acknowledge</div>
            <div>
              Existing RFPs should be resubmitted to carriers for updated underwriting approval
            </div>
            <div className={classes.ModalFooter}>
              <Button type="primary" title="Acknowledge" onClick={onClickAcknowledge} />
            </div>
          </div>
        </Modal>
      )}

      {isUpdateModal && (
        <Modal type="auto" closeButtonType="inside" onClose={onClickAcknowledge}>
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Upload Members Census</div>
            <div>
              {!membersData?.length && <UploadCensus isReUpload={true} />}
              {membersData?.length > 0 && <MembersTable membersData={membersData} />}
              {isLoadingFile ? (
                <Loader />
              ) : (
                <div className={classes.UploadFileWrapper}>
                  <label className={classes.UploadFile}>
                    <span>
                      <i className="fas fa-upload" />
                    </span>
                    <span>Choose a file...</span>
                    <input
                      type="file"
                      accept=".csv, .xls, .xlsx"
                      onChange={uploadCsv}
                      onClick={onClickUplaod}
                    />
                  </label>
                </div>
              )}
            </div>
            {Object.keys(censusErros)?.map((item) => (
              <ErrorMessage
                title={`Error Row: ${item}`}
                key={item}
                onClose={onCloseErrorMessage(item)}
              >
                <ul>
                  {censusErros[item]?.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </ErrorMessage>
            ))}

            {Object.keys(censusHouseholdErros)?.map((item) => (
              <ErrorMessage
                title={`Household Error: ee_id ${item}`}
                key={item}
                onClose={onCloseHouseholdaErrorMessage(item)}
              >
                <ul>
                  {censusHouseholdErros[item]?.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </ErrorMessage>
            ))}
            <div className={classes.ModalUploadFooter}>
              <Button type="primary" title="Save" onClick={onClickSave} isLoading={isLoadingSave} />
              <Button type="secondary" title="Cancel" onClick={onClickAcknowledge} />
            </div>
          </div>
        </Modal>
      )}

      {isMissingNamesModal && (
        <Modal closeButtonType="inside" onClose={onCloseModal} isScrollable={true}>
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Missed Column Names</div>
            <form onSubmit={onSubmitCensus}>
              <div className={classes.ModalBody}>
                {missingNames?.map((item) => (
                  <div key={item} className={classes.ModalSelect}>
                    <SearchSelect
                      label={item}
                      value={selectedNames[item]}
                      onClick={(value) => onSelectValue(value, item)}
                      onChange={(event) => onChangeValue(event, item)}
                      onClear={onClearValue(item)}
                      options={errorCensusNames}
                      isRequired
                    />
                  </div>
                ))}
              </div>
              <div className={classes.ModalUploadFooter}>
                <Button type="primary" title="Save" buttonType="submit" />
                <Button type="secondary" title="Cancel" onClick={onCloseModal} />
              </div>
            </form>
          </div>
        </Modal>
      )}

      <div className={classes.GroupMembers}>
        <PageHeader
          title="Group Members"
          isVisibleContent={isVisibleGroupMembers}
          onClickArrowButton={handleShowGroupMembers}
        >
          {!isReadOnlyMode && !isProposalFinalize && (
            <div className={classes.GroupMembersPageHeader}>
              <div className={classes.GroupMembersUpload}>
                <Button title="Upload" type="primary" onClick={handleShowUpload} />
              </div>
              <AddCensusMember />
            </div>
          )}
        </PageHeader>
        {isVisibleGroupMembers && (
          <>
            {accountProposalData?.groups?.map((group) => (
              <MembersTable key={group.id} membersData={group.members} isProposalTable={true} />
            ))}
          </>
        )}
      </div>
    </>
  );
};
