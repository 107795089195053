export const COVERAGE_NAMES = {
  contribution_ee: 'Contribution to Employees',
  contribution_dep: 'Contribution to Dependents',
  plan_type: 'Plan Type',
  network_size: 'Network Size',
  level: 'Tier',
  primary_care_physician: 'Primary Care Physician Copay',
  specialist: 'Specialist Copay',
  individual_medical_deductible: 'Individual Dectible',
  family_medical_deductible: 'Family Deductible',
  inpatient_facility_is_percent: 'Inpatient Facility',
  inpatient_facility_percent_value: '% Per Day',
  inpatient_facility_dollar_amount: 'Inpatient cost per day',
  inpatient_facility_dollar_days: 'Inpatient number of days',
  plan_coinsurance: 'Coinsurance',
  coinsurance: 'Coinsurance',
  emergency_room: 'Emergency Room',
  urgent_care: 'Urgent Care',
  individual_medical_moop: 'Max Out-of-Pocket',
  comp_method: 'Compensation Method',
  comp_amount: 'Compensation Amount',
  funding_type: 'Funding Type',
  waiting_period: 'Waiting Period',
  orthodontics: 'Orthodontics',
  orthodontics_coverage: 'Orthodontics Coverage',
  implant_coverage: 'Implant Coverage',
  dppo_out_coverage: 'DPPO: Out of Network Coverage',
  dppo_annual_max: 'DPPO: Annual Max',
  dppo_deductible: 'DPPO: Deductible',
  dppo_preventative: 'DPPO: Preventative',
  dppo_basic: 'DPPO: Basic',
  dppo_major: 'DPPO: Major',
  frequency: 'Frequency',
  exam_copay: 'Exam Copay',
  materials_copay: 'Materials Copay',
  frame_allowance: 'Frame Allowance',
  // plan_type: '24 hour / Off the Job',
  contribution_type: 'Employer Contribution',
  death_benefit: 'Death Benefit',
  rate_per_unit: 'Rate Per Unit',
  rate_basis: 'Rate Basis',
  hospital_admission: 'Hospital Admission',
  icu_confinement: 'ICU Confinement',
  daily_hospital_confinement: 'Daily Hospital Confinement',
  confinement_days_covered: 'Confinement Days Covered',
  daily_icu_confinement: 'Daily ICU Confinement',
  icu_days_covered: 'ICU Days Covered',
  accidental_death: 'Accidental Death',
  initial_hospital_confinement: 'Initial Hospital Confinement',
  per_day_hospitalization: 'Per Day Hospitalization',
  lump_sum_benefits: 'Lump Sum Benefit',
  gi_amount: 'GI Amount',
  include_cancer: 'Include Cancer',
  wellness_benefit: 'Wellness Benefit',
  recurrence_benefit: 'Recurrance Benefit',
  spouse_coverage: 'Spouse Coverage',
  child_coverage: 'Child Coverage',

  income_pct: '% of Income Requested',
  std_weekly_max: 'STD Weekly Max',
  std_elimination_period: 'STD Elimination Period',
  std_benefit_period: 'STD Benefit Period',
  ltd_monthly_max: 'LTD Monthly Max',
  ltd_elimination_period: 'LTD Elimination Period',
  ltd_benefit_period: 'LTD Benefit Period',

  medical: 'Medical',
  dental: 'Dental',
  vision: 'Vision',
  life: 'Life',
  disability: 'Disability',
  accident: 'Accident',
  hospital: 'Hospital',
  critical_illness: 'Critical Illness',

  uni_tobacco: 'Uni-tobacco',
  tobacco: 'Tobacco/Non-tobacco',
};

export const AGE_LABELS = {
  age_0: 'Age 0',
  age_1: 'Age 1',
  age_2: 'Age 2',
  age_3: 'Age 3',
  age_4: 'Age 4',
  age_5: 'Age 5',
  age_6: 'Age 6',
  age_7: 'Age 7',
  age_8: 'Age 8',
  age_9: 'Age 9',
  age_10: 'Age 10',
  age_11: 'Age 11',
  age_12: 'Age 12',
  age_13: 'Age 13',
  age_14: 'Age 14',
  age_15: 'Age 15',
  age_16: 'Age 16',
  age_17: 'Age 17',
  age_18: 'Age 18',
  age_19: 'Age 19',
  age_20: 'Age 20',
  age_21: 'Age 21',
  age_22: 'Age 22',
  age_23: 'Age 23',
  age_24: 'Age 24',
  age_25: 'Age 25',
  age_26: 'Age 26',
  age_27: 'Age 27',
  age_28: 'Age 28',
  age_29: 'Age 29',
  age_30: 'Age 30',
  age_31: 'Age 31',
  age_32: 'Age 32',
  age_33: 'Age 33',
  age_34: 'Age 34',
  age_35: 'Age 35',
  age_36: 'Age 36',
  age_37: 'Age 37',
  age_38: 'Age 38',
  age_39: 'Age 39',
  age_40: 'Age 40',
  age_41: 'Age 41',
  age_42: 'Age 42',
  age_43: 'Age 43',
  age_44: 'Age 44',
  age_45: 'Age 45',
  age_46: 'Age 46',
  age_47: 'Age 47',
  age_48: 'Age 48',
  age_49: 'Age 49',
  age_50: 'Age 50',
  age_51: 'Age 51',
  age_52: 'Age 52',
  age_53: 'Age 53',
  age_54: 'Age 54',
  age_55: 'Age 55',
  age_56: 'Age 56',
  age_57: 'Age 57',
  age_58: 'Age 58',
  age_59: 'Age 59',
  age_60: 'Age 60',
  age_61: 'Age 61',
  age_62: 'Age 62',
  age_63: 'Age 63',
  age_64: 'Age 64',
  age_65: 'Age 65',
  age_66: 'Age 66',
  age_67: 'Age 67',
  age_68: 'Age 68',
  age_69: 'Age 69',
  age_70: 'Age 70',
  age_71: 'Age 71',
  age_72: 'Age 72',
  age_73: 'Age 73',
  age_74: 'Age 74',
  age_75: 'Age 75',
  age_76: 'Age 76',
  age_77: 'Age 77',
  age_78: 'Age 78',
  age_79: 'Age 79',
  age_80: 'Age 80 +',

  age_0_14: 'Age 0-14',
  age_0_17: 'Age 0-17',
  age_18_24: 'Age 18-24',
  age_25_29: 'Age 25-29',
  age_30_34: 'Age 30-34',
  age_35_39: 'Age 35-39',
  age_40_44: 'Age 40-44',
  age_45_49: 'Age 45-49',
  age_50_54: 'Age 50-54',
  age_55_59: 'Age 55-59',
  age_60_64: 'Age 60-64',
  age_65_69: 'Age 65-69',
  age_70_74: 'Age 70-74',
  age_75_79: 'Age 75-79',

  age_18_29: 'Age 18-29',
  age_30_39: 'Age 30-39',
  age_40_49: 'Age 40-49',
  age_50_59: 'Age 50-59',
  age_60_69: 'Age 60-69',
  age_70_79: 'Age 70-79',

  age_80_80: 'Age 80 +',
  age_60_65: 'Age 60-65 +',
  age_65_65: 'Age 65 +',
};

export const AGES_65 = {
  age_0: null,
  age_1: null,
  age_2: null,
  age_3: null,
  age_4: null,
  age_5: null,
  age_6: null,
  age_7: null,
  age_8: null,
  age_9: null,
  age_10: null,
  age_11: null,
  age_12: null,
  age_13: null,
  age_14: null,
  age_15: null,
  age_16: null,
  age_17: null,
  age_18: null,
  age_19: null,
  age_20: null,
  age_21: null,
  age_22: null,
  age_23: null,
  age_24: null,
  age_25: null,
  age_26: null,
  age_27: null,
  age_28: null,
  age_29: null,
  age_30: null,
  age_31: null,
  age_32: null,
  age_33: null,
  age_34: null,
  age_35: null,
  age_36: null,
  age_37: null,
  age_38: null,
  age_39: null,
  age_40: null,
  age_41: null,
  age_42: null,
  age_43: null,
  age_44: null,
  age_45: null,
  age_46: null,
  age_47: null,
  age_48: null,
  age_49: null,
  age_50: null,
  age_51: null,
  age_52: null,
  age_53: null,
  age_54: null,
  age_55: null,
  age_56: null,
  age_57: null,
  age_58: null,
  age_59: null,
  age_60: null,
  age_61: null,
  age_62: null,
  age_63: null,
  age_64: null,
  age_65: null,
};

export const AGES_80 = {
  age_0: null,
  age_1: null,
  age_2: null,
  age_3: null,
  age_4: null,
  age_5: null,
  age_6: null,
  age_7: null,
  age_8: null,
  age_9: null,
  age_10: null,
  age_11: null,
  age_12: null,
  age_13: null,
  age_14: null,
  age_15: null,
  age_16: null,
  age_17: null,
  age_18: null,
  age_19: null,
  age_20: null,
  age_21: null,
  age_22: null,
  age_23: null,
  age_24: null,
  age_25: null,
  age_26: null,
  age_27: null,
  age_28: null,
  age_29: null,
  age_30: null,
  age_31: null,
  age_32: null,
  age_33: null,
  age_34: null,
  age_35: null,
  age_36: null,
  age_37: null,
  age_38: null,
  age_39: null,
  age_40: null,
  age_41: null,
  age_42: null,
  age_43: null,
  age_44: null,
  age_45: null,
  age_46: null,
  age_47: null,
  age_48: null,
  age_49: null,
  age_50: null,
  age_51: null,
  age_52: null,
  age_53: null,
  age_54: null,
  age_55: null,
  age_56: null,
  age_57: null,
  age_58: null,
  age_59: null,
  age_60: null,
  age_61: null,
  age_62: null,
  age_63: null,
  age_64: null,
  age_65: null,
  age_66: null,
  age_67: null,
  age_68: null,
  age_69: null,
  age_70: null,
  age_71: null,
  age_72: null,
  age_73: null,
  age_74: null,
  age_75: null,
  age_76: null,
  age_77: null,
  age_78: null,
  age_79: null,
  age_80: null,
};

export const LIFE_AGES = {
  age_0_non_tobacco: null,
  age_0_tobacco: null,
  age_0_uni_tobacco: null,
  age_1_non_tobacco: null,
  age_1_tobacco: null,
  age_1_uni_tobacco: null,
  age_2_non_tobacco: null,
  age_2_tobacco: null,
  age_2_uni_tobacco: null,
  age_3_non_tobacco: null,
  age_3_tobacco: null,
  age_3_uni_tobacco: null,
  age_4_non_tobacco: null,
  age_4_tobacco: null,
  age_4_uni_tobacco: null,
  age_5_non_tobacco: null,
  age_5_tobacco: null,
  age_5_uni_tobacco: null,
  age_6_non_tobacco: null,
  age_6_tobacco: null,
  age_6_uni_tobacco: null,
  age_7_non_tobacco: null,
  age_7_tobacco: null,
  age_7_uni_tobacco: null,
  age_8_non_tobacco: null,
  age_8_tobacco: null,
  age_8_uni_tobacco: null,
  age_9_non_tobacco: null,
  age_9_tobacco: null,
  age_9_uni_tobacco: null,
  age_10_non_tobacco: null,
  age_10_tobacco: null,
  age_10_uni_tobacco: null,
  age_11_non_tobacco: null,
  age_11_tobacco: null,
  age_11_uni_tobacco: null,
  age_12_non_tobacco: null,
  age_12_tobacco: null,
  age_12_uni_tobacco: null,
  age_13_non_tobacco: null,
  age_13_tobacco: null,
  age_13_uni_tobacco: null,
  age_14_non_tobacco: null,
  age_14_tobacco: null,
  age_14_uni_tobacco: null,
  age_15_non_tobacco: null,
  age_15_tobacco: null,
  age_15_uni_tobacco: null,
  age_16_non_tobacco: null,
  age_16_tobacco: null,
  age_16_uni_tobacco: null,
  age_17_non_tobacco: null,
  age_17_tobacco: null,
  age_17_uni_tobacco: null,
  age_18_non_tobacco: null,
  age_18_tobacco: null,
  age_18_uni_tobacco: null,
  age_19_non_tobacco: null,
  age_19_tobacco: null,
  age_19_uni_tobacco: null,
  age_20_non_tobacco: null,
  age_20_tobacco: null,
  age_20_uni_tobacco: null,
  age_21_non_tobacco: null,
  age_21_tobacco: null,
  age_21_uni_tobacco: null,
  age_22_non_tobacco: null,
  age_22_tobacco: null,
  age_22_uni_tobacco: null,
  age_23_non_tobacco: null,
  age_23_tobacco: null,
  age_23_uni_tobacco: null,
  age_24_non_tobacco: null,
  age_24_tobacco: null,
  age_24_uni_tobacco: null,
  age_25_non_tobacco: null,
  age_25_tobacco: null,
  age_25_uni_tobacco: null,
  age_26_non_tobacco: null,
  age_26_tobacco: null,
  age_26_uni_tobacco: null,
  age_27_non_tobacco: null,
  age_27_tobacco: null,
  age_27_uni_tobacco: null,
  age_28_non_tobacco: null,
  age_28_tobacco: null,
  age_28_uni_tobacco: null,
  age_29_non_tobacco: null,
  age_29_tobacco: null,
  age_29_uni_tobacco: null,
  age_30_non_tobacco: null,
  age_30_tobacco: null,
  age_30_uni_tobacco: null,
  age_31_non_tobacco: null,
  age_31_tobacco: null,
  age_31_uni_tobacco: null,
  age_32_non_tobacco: null,
  age_32_tobacco: null,
  age_32_uni_tobacco: null,
  age_33_non_tobacco: null,
  age_33_tobacco: null,
  age_33_uni_tobacco: null,
  age_34_non_tobacco: null,
  age_34_tobacco: null,
  age_34_uni_tobacco: null,
  age_35_non_tobacco: null,
  age_35_tobacco: null,
  age_35_uni_tobacco: null,
  age_36_non_tobacco: null,
  age_36_tobacco: null,
  age_36_uni_tobacco: null,
  age_37_non_tobacco: null,
  age_37_tobacco: null,
  age_37_uni_tobacco: null,
  age_38_non_tobacco: null,
  age_38_tobacco: null,
  age_38_uni_tobacco: null,
  age_39_non_tobacco: null,
  age_39_tobacco: null,
  age_39_uni_tobacco: null,
  age_40_non_tobacco: null,
  age_40_tobacco: null,
  age_40_uni_tobacco: null,
  age_41_non_tobacco: null,
  age_41_tobacco: null,
  age_41_uni_tobacco: null,
  age_42_non_tobacco: null,
  age_42_tobacco: null,
  age_42_uni_tobacco: null,
  age_43_non_tobacco: null,
  age_43_tobacco: null,
  age_43_uni_tobacco: null,
  age_44_non_tobacco: null,
  age_44_tobacco: null,
  age_44_uni_tobacco: null,
  age_45_non_tobacco: null,
  age_45_tobacco: null,
  age_45_uni_tobacco: null,
  age_46_non_tobacco: null,
  age_46_tobacco: null,
  age_46_uni_tobacco: null,
  age_47_non_tobacco: null,
  age_47_tobacco: null,
  age_47_uni_tobacco: null,
  age_48_non_tobacco: null,
  age_48_tobacco: null,
  age_48_uni_tobacco: null,
  age_49_non_tobacco: null,
  age_49_tobacco: null,
  age_49_uni_tobacco: null,
  age_50_non_tobacco: null,
  age_50_tobacco: null,
  age_50_uni_tobacco: null,
  age_51_non_tobacco: null,
  age_51_tobacco: null,
  age_51_uni_tobacco: null,
  age_52_non_tobacco: null,
  age_52_tobacco: null,
  age_52_uni_tobacco: null,
  age_53_non_tobacco: null,
  age_53_tobacco: null,
  age_53_uni_tobacco: null,
  age_54_non_tobacco: null,
  age_54_tobacco: null,
  age_54_uni_tobacco: null,
  age_55_non_tobacco: null,
  age_55_tobacco: null,
  age_55_uni_tobacco: null,
  age_56_non_tobacco: null,
  age_56_tobacco: null,
  age_56_uni_tobacco: null,
  age_57_non_tobacco: null,
  age_57_tobacco: null,
  age_57_uni_tobacco: null,
  age_58_non_tobacco: null,
  age_58_tobacco: null,
  age_58_uni_tobacco: null,
  age_59_non_tobacco: null,
  age_59_tobacco: null,
  age_59_uni_tobacco: null,
  age_60_non_tobacco: null,
  age_60_tobacco: null,
  age_60_uni_tobacco: null,
  age_61_non_tobacco: null,
  age_61_tobacco: null,
  age_61_uni_tobacco: null,
  age_62_non_tobacco: null,
  age_62_tobacco: null,
  age_62_uni_tobacco: null,
  age_63_non_tobacco: null,
  age_63_tobacco: null,
  age_63_uni_tobacco: null,
  age_64_non_tobacco: null,
  age_64_tobacco: null,
  age_64_uni_tobacco: null,
  age_65_non_tobacco: null,
  age_65_tobacco: null,
  age_65_uni_tobacco: null,
  age_66_non_tobacco: null,
  age_66_tobacco: null,
  age_66_uni_tobacco: null,
  age_67_non_tobacco: null,
  age_67_tobacco: null,
  age_67_uni_tobacco: null,
  age_68_non_tobacco: null,
  age_68_tobacco: null,
  age_68_uni_tobacco: null,
  age_69_non_tobacco: null,
  age_69_tobacco: null,
  age_69_uni_tobacco: null,
  age_70_non_tobacco: null,
  age_70_tobacco: null,
  age_70_uni_tobacco: null,
  age_71_non_tobacco: null,
  age_71_tobacco: null,
  age_71_uni_tobacco: null,
  age_72_non_tobacco: null,
  age_72_tobacco: null,
  age_72_uni_tobacco: null,
  age_73_non_tobacco: null,
  age_73_tobacco: null,
  age_73_uni_tobacco: null,
  age_74_non_tobacco: null,
  age_74_tobacco: null,
  age_74_uni_tobacco: null,
  age_75_non_tobacco: null,
  age_75_tobacco: null,
  age_75_uni_tobacco: null,
  age_76_non_tobacco: null,
  age_76_tobacco: null,
  age_76_uni_tobacco: null,
  age_77_non_tobacco: null,
  age_77_tobacco: null,
  age_77_uni_tobacco: null,
  age_78_non_tobacco: null,
  age_78_tobacco: null,
  age_78_uni_tobacco: null,
  age_79_non_tobacco: null,
  age_79_tobacco: null,
  age_79_uni_tobacco: null,
  age_80_non_tobacco: null,
  age_80_tobacco: null,
  age_80_uni_tobacco: null,
};
