import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getAccountMode } from 'src/store/broker';
import { accountContactsSelector, isLoadingDeleteContactSelector } from 'src/store/accountContacts';

import { Loader } from 'src/components';

import classes from './manageAccount.module.scss';

export const ManageAccountContactsTable = (props) => {
  const {
    //
    contactsData,
    onChangeContactsInput,
    //
    selectedDeleteId,
    createContactsData,
    onSelectRow,
    onChangeInput,
    onDeleteRow,
    selectedEditRow,
    onChangeEditInput,
    onClickEdit,
    onClickDeleteContact,
    onClickUpdateContact,
    onClickCancelRow,
    onClickSaveNewContact,
    onClickAddContact,
  } = props;

  const accountMode = useSelector(getAccountMode);
  const accountContacts = useSelector(accountContactsSelector);
  const isLoadingDeleteContact = useSelector(isLoadingDeleteContactSelector);

  return (
    <>
      <div className={classes.ManageAccountBody}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Contact Type</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountMode === 'CREATE' && (
                <>
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={contactsData.primary_name}
                        label="Contact Name"
                        placeholder="eg. John Doe"
                        onChange={(event) => onChangeContactsInput(event, 'primary_name')}
                        size="small"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={contactsData.primary_email}
                        label="Contact Email"
                        placeholder="john.doe@mail.com"
                        type="email"
                        onChange={(event) => onChangeContactsInput(event, 'primary_email')}
                        size="small"
                        required
                      />
                    </TableCell>
                    <TableCell>Primary</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={contactsData.broker_name}
                        label="Contact Name"
                        onChange={(event) => onChangeContactsInput(event, 'broker_name')}
                        size="small"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={contactsData.broker_email}
                        type="email"
                        label="Contact Email"
                        onChange={(event) => onChangeContactsInput(event, 'broker_email')}
                        size="small"
                        required
                      />
                    </TableCell>
                    <TableCell>Broker</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {createContactsData?.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell>
                        <TextField
                          value={item.name || ''}
                          onFocus={onSelectRow(item)}
                          label="Contact Name"
                          placeholder="eg. John Doe"
                          onChange={(event) => onChangeInput(event, 'name')}
                          size="small"
                          required
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={item.email || ''}
                          onFocus={onSelectRow(item)}
                          label="Contact Email"
                          placeholder="john.doe@mail.com"
                          onChange={(event) => onChangeInput(event, 'email')}
                          size="small"
                          required
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <span className={classes.ManageAccountFooter}>
                          <i className="fa fa-trash-o" onClick={onDeleteRow(item?.id)} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {accountMode === 'EDIT' && (
                <>
                  {accountContacts?.map((item) => {
                    const isEditMode =
                      !_.isEmpty(selectedEditRow) && selectedEditRow.id === item.id;

                    return (
                      <TableRow key={item?.id}>
                        <TableCell>
                          {!isEditMode ? (
                            item?.name
                          ) : (
                            <TextField
                              value={selectedEditRow.name || ''}
                              label="Contact Name"
                              placeholder="eg. John Doe"
                              onChange={(event) => onChangeEditInput(event, 'name')}
                              size="small"
                              required
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {!isEditMode ? (
                            item?.email
                          ) : (
                            <TextField
                              value={selectedEditRow.email || ''}
                              label="Contact Email"
                              placeholder="john.doe@mail.com"
                              onChange={(event) => onChangeEditInput(event, 'email')}
                              size="small"
                              required
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.ManageAccountBodyTableContact}>
                          {item.contact_type !== 'cc' && item.contact_type}
                        </TableCell>
                        <TableCell style={{ width: '120px' }}>
                          {!isEditMode ? (
                            <span className={classes.ManageAccountContactsButton}>
                              <Button
                                variant="contained"
                                onClick={onClickEdit(item)}
                                disabled={isLoadingDeleteContact && item.id === selectedDeleteId}
                                size="small"
                              >
                                Edit
                              </Button>
                              {item.contact_type === 'cc' && (
                                <span className={classes.ManageAccountBodyTableDelete}>
                                  {isLoadingDeleteContact && item.id === selectedDeleteId ? (
                                    <Loader size="small" />
                                  ) : (
                                    <i
                                      className="fa fa-trash-o"
                                      onClick={onClickDeleteContact(item?.account_id, item.id)}
                                    />
                                  )}
                                </span>
                              )}
                            </span>
                          ) : (
                            <span className={classes.ManageAccountContactsButton}>
                              <Button
                                variant="outlined"
                                onClick={onClickUpdateContact}
                                size="small"
                              >
                                Save
                              </Button>
                              <Button variant="outlined" onClick={onClickCancelRow} size="small">
                                Cancel
                              </Button>
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {createContactsData?.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell>
                        <TextField
                          value={item.name || ''}
                          label="Contact Name"
                          placeholder="eg. John Doe"
                          onFocus={onSelectRow(item)}
                          onChange={(event) => onChangeInput(event, 'name')}
                          size="small"
                          required
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={item.email || ''}
                          label="Contact Email"
                          placeholder="john.doe@mail.com"
                          onFocus={onSelectRow(item)}
                          onChange={(event) => onChangeInput(event, 'email')}
                          size="small"
                          required
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <span className={classes.ManageAccountContactsButton}>
                          <Button variant="contained" onClick={onClickSaveNewContact} size="small">
                            Save
                          </Button>
                          <i className="fa fa-trash-o" onClick={onDeleteRow(item?.id)} />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={classes.ManageAccountBodyFooter}>
        {accountMode === 'CREATE' && (
          <Button variant="contained" onClick={onClickAddContact}>
            Add Contact
          </Button>
        )}
        {accountMode === 'EDIT' && !createContactsData.length && (
          <Button variant="contained" onClick={onClickAddContact}>
            Add Contact
          </Button>
        )}
      </div>
    </>
  );
};
