import Base from './base';

class Broker extends Base {
  getBrokerData = (id) => {
    return this.apiClient.get(`brokers/${id}`);
  };

  createBrokerAccount = (payload) => {
    return this.apiClient.post(`accounts`, payload);
  };

  getBrokerAccounts = (payload) => {
    return this.apiClient.get(`accounts`, payload);
  };

  updateProfileData = (id, payload) => {
    return this.apiClient.put(`brokers/${id}`, payload);
  };

  deleteBillingSubscription = (id) => {
    return this.apiClient.delete(`billing/${id}`);
  };

  updateAccount = ({ id, payload }) => {
    return this.apiClient.put(`accounts/${id}`, payload);
  };

  deleteAccountContact = (id, contactId) => {
    return this.apiClient.delete(`accounts/${id}/contacts/${contactId}`);
  };

  getBrokerAccountData = (id) => {
    return this.apiClient.get(`accounts/${id}`);
  };

  deleteBrokerAccount = (accountId) => {
    return this.apiClient.delete(`accounts/${accountId}`);
  };

  getPaymentMethods = () => {
    return this.apiClient.get('billing/payment-methods');
  };

  changePaymentMethod = () => {
    return this.apiClient.post('billing/change-payment-method');
  };

  createCheckoutSession = (payload) => {
    return this.apiClient.post('billing/create-checkout-session', payload);
  };

  getProposalsList = (payload) => {
    return this.apiClient.get(`proposals`, payload);
  };
}

export default Broker;
