import Base from './base';

class Proposal extends Base {
  getProposalList = (id, payload) => {
    return this.apiClient.get(`accounts/${id}/proposals`, payload);
  };

  createProposal = (payload) => {
    const { accountId, name, due_date, effective_date, org_id } = payload;

    const payloadData = {
      name,
      due_date,
      effective_date,
      group_ids: [],
      org_id,
    };

    return this.apiClient.post(`accounts/${accountId}/proposals`, payloadData);
  };

  updateProposal = (accountId, proposalId, payload, read_token) => {
    delete payload.broker;
    delete payload.created_timestamp;
    delete payload.updated_timestamp;

    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}${read_token ? `?token=${read_token}` : ''}`,
      payload,
    );
  };

  getAccountProposal = (payload, read_token) => {
    const { accountId, proposalId } = payload;

    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}${read_token ? `?token=${read_token}` : ''}`,
    );
  };

  getGroup = (accountId, groupId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/groups/${groupId}${read_token ? `?token=${read_token}` : ''}`,
    );
  };

  getGroupQuotes = (accountId, groupId) => {
    return this.apiClient.get(
      `accounts/${accountId}/groups/${groupId}/quotes?f[coverage_type]=medical`,
    );
  };

  getQuotePlans = (accountId, groupId, quoteId) => {
    return this.apiClient.get(`accounts/${accountId}/groups/${groupId}/quotes/${quoteId}/plans`);
  };

  getCarrierIssuers = (payload) => {
    return this.apiClient.get('issuers', payload);
  };

  fetchQuotesRates = (accountId, groupId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/groups/${groupId}/quotes/rates`, payload);
  };

  createQuote = (accountId, groupId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/groups/${groupId}/quotes`, payload);
  };

  updateQuote = (accountId, groupId, quoteId, payload, read_token) => {
    return this.apiClient.put(
      `accounts/${accountId}/groups/${groupId}/quotes/${quoteId}${
        read_token ? `?token=${read_token}` : ''
      }`,
      payload,
    );
  };

  updateMedicalQuotePlanOrder = (accountId, groupId, payload) => {
    return this.apiClient.put(`accounts/${accountId}/groups/${groupId}/quotes`, payload);
  };

  deleteQuote = (accountId, groupId, quoteId) => {
    return this.apiClient.delete(`accounts/${accountId}/groups/${groupId}/quotes/${quoteId}`);
  };

  shareProposal = (accountId, proposalId) => {
    return this.apiClient.post(`accounts/${accountId}/proposals/${proposalId}/submit-proposal`);
  };

  sendChatMessage = (accountId, proposalId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/proposals/${proposalId}/chat/send`, payload);
  };

  getChatMessages = (accountId, proposalId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/proposals/${proposalId}/chat/get`, payload);
  };

  getReadOnlyData = (payload) => {
    return this.apiClient.get(`read-only/${payload}`);
  };

  proposalManagement = (token, payload) => {
    return this.apiClient.post(`read-only/${token}`, payload);
  };

  deleteProposal = (accountId, proposalId) => {
    return this.apiClient.delete(`accounts/${accountId}/proposals/${proposalId}`);
  };
}

export default Proposal;
