export const statusOptions = [
  { title: 'All', value: 'all' },
  { title: 'Completed', value: 'completed' },
  { title: 'Incomplete', value: 'incomplete' },
];

export const coverageOptions = [
  { title: 'All', value: 'all' },
  { title: 'Medical', value: 'medical' },
  { title: 'Dental', value: 'dental' },
  { title: 'Vision', value: 'vision' },
  { title: 'Life', value: 'life' },
  { title: 'Disability', value: 'disability' },
  { title: 'Accident', value: 'accident' },
  { title: 'Hospital', value: 'hospital' },
  { title: 'Critical Illness', value: 'critical_illness' },
];

export const dateOptions = [
  { title: 'All', value: 'all' },
  { title: 'Active', value: 'active' },
  { title: 'Expired', value: 'expired' },
];
