import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  agencyUserRoleSelector,
  deletePendingInvite,
  isLoadingDeleteMemberSelector,
  isLoadingResendInviteSelector,
  pendingInvitesSelector,
  resendInviteTeamMember,
} from 'src/store/agency';

import { Button, Loader, LoaderWrapper, Modal, Status } from 'src/components';

import classes from './agencyPendingInvites.module.scss';

export const AgencyPendingInvites = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const teamId = params?.teamId;

  const pendingInvites = useSelector(pendingInvitesSelector);
  const agencyUserRole = useSelector(agencyUserRoleSelector);
  const isLoadingResend = useSelector(isLoadingResendInviteSelector);
  const isLoadingDelete = useSelector(isLoadingDeleteMemberSelector);

  const [selectedMember, setSelectedMember] = useState({});
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const onOpenModal = useCallback(
    (item) => () => {
      setIsVisibleModal(true);
      setSelectedMember(item);
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    setIsVisibleModal(false);
    setSelectedMember({});
  }, []);

  const onClickResendInvite = useCallback(
    (email) => () => {
      const payload = { id: teamId, payload: { target_email: email } };
      setSelectedEmail(email);
      dispatch(resendInviteTeamMember(payload));
    },
    [dispatch, teamId],
  );

  const onSubmitDeleteMember = useCallback(async () => {
    const payload = {
      teamId,
      memberId: selectedMember.invite_id,
    };

    await dispatch(deletePendingInvite(payload));

    onCloseModal();
  }, [dispatch, onCloseModal, teamId, selectedMember]);

  return (
    <>
      {isVisibleModal && (
        <Modal onClose={onCloseModal} closeButtonType="inside">
          {isLoadingDelete ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <div className={classes.ModalContent}>
              <div className={classes.ModalContentTitle}>
                Are you sure you want to delete this member?
              </div>
              <div className={classes.ModalContentName}>{`${selectedMember?.target_email}`}</div>
              <div className={classes.ModalContentFooter}>
                <Button title="Yes" type="danger" onClick={onSubmitDeleteMember} />
                <Button title="No" type="secondary" onClick={onCloseModal} />
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className={classes.AgencyPendingInvites}>
        <div className={classes.AgencyPendingInvitesWrapper}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableHead>
              {pendingInvites?.length !== 0 && (
                <TableBody>
                  {pendingInvites?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <div className={classes.AgencyPendingInvitesUserImageWrapper}>
                          <div className={classes.AgencyTeamUserName}>
                            {item.target_email?.match(/[A-Z]/g, '')?.join('') ??
                              item.target_email?.slice(0, 1).toUpperCase()}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.AgencyPendingInvitesUserEmail}>
                        {item.target_email}
                      </TableCell>
                      <TableCell style={{ width: '230px' }}>
                        <div className={classes.AgencyPendingInvitesUserStatus}>
                          <Status status={item.status} />

                          <Button
                            type="primary"
                            title="Resend Invite"
                            onClick={onClickResendInvite(item.target_email)}
                            isLoading={isLoadingResend && item.target_email === selectedEmail}
                          />
                        </div>
                      </TableCell>
                      {agencyUserRole === 'owner' && (
                        <TableCell>
                          {item.role !== 'owner' && (
                            <i
                              className={`fa fa-trash-o ${classes.AgencyPendingInvitesTrashIcon}`}
                              onClick={onOpenModal(item)}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
