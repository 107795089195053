import moment from 'moment';

export const normalizeAccountExport = (accountWorkSheet, accountData, brokerData) => {
  accountWorkSheet.addRow(['Company Name', accountData?.company_name]).font = {
    bold: true,
    size: 18,
  };
  accountWorkSheet
    .addRow([
      'Address',
      `${accountData?.address_street && `${accountData?.address_street}, `}${
        accountData?.address_city && `${accountData?.address_city}, `
      }${accountData?.address_state} ${accountData?.address_zip_code}`,
    ])
    .getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['Company Contact Name', accountData?.contact_name]).getCell(1).font = {
    bold: true,
  };
  accountWorkSheet.addRow(['Company Contact Email', accountData?.contact_email]).getCell(1).font = {
    bold: true,
  };
  accountWorkSheet
    .addRow(['Company Contat Phone Number', accountData?.contact_phone])
    .getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['Broker Name', brokerData?.display_name]).getCell(1).font = {
    bold: true,
  };
  accountWorkSheet.addRow(['Broker Email', brokerData?.email]).getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['Broker Phone Number']).getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['FIPS Code', accountData?.fips_code]).getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['SIC Code', accountData?.sic_code]).getCell(1).font = { bold: true };
  accountWorkSheet
    .addRow(['Effective Date', moment(accountData?.effective_date).format('l')])
    .getCell(1).font = { bold: true };
  accountWorkSheet.addRow(['Rating Area ID', accountData?.rating_area_id]).getCell(1).font = {
    bold: true,
  };
};
