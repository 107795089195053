import React, { useCallback } from 'react';
import _ from 'lodash';

import { Input } from 'src/components';

import classes from './extraRows.module.scss';

export const ExtraRows = (props) => {
  const {
    extraRows,
    setExtraRows,
    selectedRow,
    setSelectedRow,
    type = 'issuer',
    isDisabled,
  } = props;

  const onChangeExtraRow = useCallback(
    (event, rowField) => {
      const row = extraRows.find((item) => item.id === selectedRow.id);
      row[rowField] = event.target.value;

      if (row.value.length > 250) {
        row['value'] = row['value'].slice(0, 250);
        return;
      }

      const payload = extraRows.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj[rowField] === o[rowField])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setExtraRows([...payload]);
    },
    [extraRows, selectedRow.id, setExtraRows],
  );

  const onSelectExtraRow = useCallback(
    (row) => () => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const onDeleteExtraRow = useCallback(
    (id) => () => {
      const payload = _.reject(extraRows, (row) => row.id === id);

      setExtraRows(payload);
    },
    [extraRows, setExtraRows],
  );

  return (
    <div>
      {extraRows?.map((item) => (
        <div key={item.id} className={classes.ExtraRows}>
          <div className={classes.ExtraRowsWrapper}>
            {type === 'broker' && (
              <div className={classes.ExtraRowsTitle}>
                <Input
                  value={item.title}
                  label="Title"
                  onFocus={onSelectExtraRow(item)}
                  onChange={(event) => onChangeExtraRow(event, 'title')}
                  isDisabled={isDisabled}
                />
                <div className={classes.ExtraRowsMaxLength}>{item.title.length}/250</div>
              </div>
            )}
            <div className={classes.ExtraRowsValue}>
              <Input
                value={item.value}
                label="Description"
                onFocus={onSelectExtraRow(item)}
                onChange={(event) => onChangeExtraRow(event, 'value')}
                isDisabled={isDisabled}
              />
              <div className={classes.ExtraRowsMaxLength}>{item.value.length}/250</div>
            </div>
          </div>
          {!isDisabled && (
            <i
              className={`fa fa-trash-o ${classes.ExtraRowsDeleteIcon}`}
              onClick={onDeleteExtraRow(item.id)}
            />
          )}
        </div>
      ))}
    </div>
  );
};
