import Base from './base';

class HospitalConfiguration extends Base {
  getHospitalPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/hospital/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getHospitalQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/hospital/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  deleteHospitalPlanConfiguration = (accountId, proposalId, planId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/hospital/config/${planId}`,
    );
  };
}

export default HospitalConfiguration;
