import _ from 'lodash';
import {
  compMethodOptions,
  contributionTypeOptions,
  formattedValue,
  rateBasisOptions,
} from 'src/constants/coverage';

export const useLifeIssuerBuild = () => {
  const build = {
    coverage: 'life',
    title: 'Life',
    form_options: {},
    card_options: {
      plan: [
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Death Benefit',
          key: 'death_benefit',
          format: (value) => formattedValue(value),
        },
      ],
    },
    offer_details: [
      {
        key: 'contribution_type',
        options: { render_type: 'select', render_data: contributionTypeOptions },
      },
      {
        key: 'death_benefit',
        format: (value) => (_.isNumber(Number(value)) ? formattedValue(value) : value),
        options: { render_type: 'input', inputType: 'number', symbol: '$', minValue: 0 },
      },
      {
        key: 'rate_per_unit',
        format: (value) => formattedValue(value),
        options: { render_type: 'input', inputType: 'number', symbol: '$', minValue: 0 },
      },
      { key: 'rate_basis', options: { render_type: 'select', render_data: rateBasisOptions } },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
