import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getAccountData, getIsLoadingBrokerAccountData } from 'src/store/broker';
import { getIssuerAccountProposalData } from 'src/store/issuer';

import { AccountDetails } from 'src/components';
import { IssuerProposalHeader } from '../issuerProposalHeader/IssuerProposalHeader';

import classes from './issuerProposalAccountInfo.module.scss';

export const IssuerProposalAccountInfo = () => {
  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getIssuerAccountProposalData);
  const isLoadingAccountData = useSelector(getIsLoadingBrokerAccountData);

  return (
    <div className={classes.IssuerProposalAccountInfo}>
      <IssuerProposalHeader />

      <AccountDetails
        previewImage={accountData?.logo_url}
        companyName={accountData?.company_name}
        addressStreet={accountData?.address_street}
        addressCity={accountData?.address_city}
        addressState={accountData?.address_state}
        addressZip={accountData?.address_zip_code}
        fipsCode={accountData?.fips_code}
        effectiveDate={moment(proposalData?.effective_date).format('l')}
        dueDate={moment(proposalData?.due_date).format('l')}
        brokerName={accountData?.broker?.display_name}
        brokerEmail={accountData?.broker?.email}
        brokerPhone={accountData?.broker?.phone_number}
        brokerData={accountData?.broker}
        sicCode={accountData?.sic_code}
        ratingArea={accountData?.rating_area_id}
        isLoading={isLoadingAccountData}
        readOnlyMode
      />
    </div>
  );
};
