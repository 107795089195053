import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useCriticalBuild } from './useCriticalBuild/useCriticalBuild';

export const CriticalQuotes = () => {
  const { build } = useCriticalBuild();

  return <CoverageBuilder build={build} />;
};
