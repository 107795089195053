import React, { useState, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { deleteCensusMember, isLoadingDeleteCensusMemberSelector } from 'src/store/groupMembers';
import { getAccountProposal, isProposalFinalizeSelector } from 'src/store/proposals';

import { AddCensusMember } from 'src/features/proposal/addCensusMember/AddCensusMember';

import { currencyFormatter } from 'src/constants/currency';

import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

import classes from './membersTable.module.scss';

const headerRows = [
  { id: 'eid', name: 'EID', sortable: false },
  { id: 'first_name', name: 'First Name', sortable: false },
  { id: ';ast_name', name: 'Last Name', sortable: false },
  { id: 'gender', name: 'Gender', sortable: false },
  { id: 'relationship', name: 'Relationship', sortable: false },
  { id: 'zipcode', name: 'Zip Code', sortable: false },
  { id: 'dob', name: 'Date of Birth', sortable: false },
  { id: 'cmp', name: 'Current Medical Plan', sortable: false },
  { id: 'cd', name: 'Current Dental', sortable: false },
  { id: 'cv', name: 'Current Vision', sortable: false },
  { id: 'cl', name: 'Current Life', sortable: false },
  { id: 's', name: 'Salary', sortable: false },
  { id: 'jt', name: 'Job Title', sortable: false },
  { id: 'tu', name: 'Tobacco User', sortable: false },
  { id: '1', name: '', sortable: false },
];

export const MembersTable = (props) => {
  const { membersData, isProposalTable = false } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();

  const isReadOnlyMode = pathname.includes('/read-only');

  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const isLoadingDelete = useSelector(isLoadingDeleteCensusMemberSelector);
  const isProposalFinalize = useSelector(isProposalFinalizeSelector);

  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [selectedMember, setSelectedMember] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);

  const currentMembersData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * selectedPageSize;
    const lastPageIndex = firstPageIndex + selectedPageSize;

    return membersData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, membersData, selectedPageSize]);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  const normalizeTobaccoValue = (value) => {
    if (typeof value === 'boolean') {
      if (value) {
        return 'Yes';
      }

      if (!value) {
        return 'No';
      }
    } else {
      return '';
    }
  };

  const onClickDeleteIcon = useCallback(
    (item) => () => {
      setIsDeleteModal(true);
      setSelectedMember(item);
    },
    [],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedMember({});
  }, []);

  const onClickEdit = useCallback(
    (item) => () => {
      setIsEditModal(true);
      setSelectedMember(item);
    },
    [],
  );

  const onCloseEditModal = useCallback(() => {
    setIsEditModal(false);
    setSelectedMember({});
  }, []);

  const onClickDelete = useCallback(async () => {
    const payload = {
      accountId,
      groupId: selectedMember?.group_id,
      memberId: selectedMember?.id,
    };

    await dispatch(deleteCensusMember(payload));
    await dispatch(getAccountProposal({ accountId, proposalId }));

    onCloseDeleteModal();
  }, [
    dispatch,
    accountId,
    proposalId,
    selectedMember?.group_id,
    selectedMember?.id,
    onCloseDeleteModal,
  ]);

  return (
    <>
      {isDeleteModal && (
        <Modal onClose={onCloseDeleteModal} closeButtonType="inside">
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Delete Member</div>
            <div className={classes.ModalContent}>
              Are you sure you want to delete this user:
              <div className={classes.ModalContentMember}>
                <div className={classes.ModalContentMemberItem}>
                  First Name: {selectedMember?.first_name}
                </div>
                <div className={classes.ModalContentMemberItem}>
                  Last Name: {selectedMember?.last_name}
                </div>
                <div className={classes.ModalContentMemberItem}>
                  Family ID: {selectedMember?.family_id}
                </div>
              </div>
            </div>
            <div className={classes.ModalFooter}>
              <Button
                title="Delete"
                type="primary"
                onClick={onClickDelete}
                isLoading={isLoadingDelete}
              />
              <Button title="Cancel" type="secondary" onClick={onCloseDeleteModal} />
            </div>
          </div>
        </Modal>
      )}

      {isEditModal && !_.isEmpty(selectedMember) && (
        <AddCensusMember selectedMember={selectedMember} onCloseEditModal={onCloseEditModal} />
      )}

      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {headerRows?.map((item) => (
                  <TableCell key={item.id}>
                    {/* {item.sortable ? (
                          <TableSortLabel
                            active={selectedCell === item.id}
                            direction={cellDirection}
                            onClick={onChangeCellSort(item.id)}
                          >
                            {item.name}
                          </TableSortLabel>
                        ) : ( */}
                    {item.name}
                    {/* )} */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentMembersData.map((item) => (
                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{item.family_id || item.ee_id}</TableCell>
                  <TableCell>{item.first_name}</TableCell>
                  <TableCell>{item.last_name}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.relationship || 'employee'}</TableCell>
                  <TableCell>{item.zip_code}</TableCell>
                  <TableCell>
                    {moment(item.date_of_birth, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>{item.current_medical_plan_name}</TableCell>
                  <TableCell>{item.dental_plan_name}</TableCell>
                  <TableCell>{item.vision_plan_name}</TableCell>
                  <TableCell>
                    {currencyFormatter(item.life_benefit_amount).replace('.00', '')}
                  </TableCell>
                  <TableCell>{currencyFormatter(item.salary_amount).replace('.00', '')}</TableCell>
                  <TableCell>{item.job_title}</TableCell>
                  <TableCell>{normalizeTobaccoValue(item.is_tobacco_user)}</TableCell>
                  {isProposalTable && !isReadOnlyMode && !isProposalFinalize && (
                    <TableCell>
                      <span className={classes.MembersTableIcons}>
                        <i
                          className={`${classes.EditIcon} fas fa-edit`}
                          onClick={onClickEdit(item)}
                        />
                        <i
                          className={`${classes.TrashIcon} fa fa-trash-o`}
                          onClick={onClickDeleteIcon(item)}
                        />
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={membersData.length}
          rowsPerPage={selectedPageSize}
          page={currentPage - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangePageSize}
        />
      </Paper>
    </>
  );
};
