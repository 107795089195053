import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearIssuerCoveragesBuilderState } from './issuerCoverageBuilder.slice';
import { toast } from 'react-toastify';
import { ERROR } from 'src/constants/errorNames';

export const getIssuerCoveragePlans = createAsyncThunk(
  'issuer-coverage/get-coverage-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, read_token } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.issuerCoverageBuilder.getPlans(
        accountId,
        proposalId,
        coverage,
        read_token,
      );

      return { coverage, response };
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getIssuerCoverageOffers = createAsyncThunk(
  'issuer-coverage/get-coverage-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, coverage, setOffers, setIsLoading } = data;
      setIsLoading(true);

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const response = await api.issuerCoverageBuilder.getOffers(
        accountId,
        proposalId,
        coverage,
        configId,
      );

      // if (userType === 'broker') {
      //   const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      //   const brokerOffer = {
      //     ...brokerConfig,
      //     issuer_name: 'broker',
      //     status: 'selected',
      //   };

      //   const updatedResponse = [brokerOffer, ...response];

      //   const calculatedResponse = updatedResponse?.map((item) => {
      //     if (item?.status !== 'pending') {
      //       const calculatedRates = calculateDentalVisionRates(
      //         groupMembers,
      //         item,
      //         {},
      //         item?.selected_plan_name,
      //         'dental',
      //       );

      //       return { ...item, calculation: calculatedRates };
      //     }
      //     return item;
      //   });

      //   return setOffers(calculatedResponse);
      // }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const createIssuerCoverageOffer = createAsyncThunk(
  'issuer-coverage/create-offer',
  async (data, thunkApi) => {
    try {
      const { options, payload } = data;

      const { accountId, proposalId, configId, coverage, read_token } = options;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      // const payload = {
      //   ...data,
      // };

      delete payload.created_timestamp;
      delete payload.comp_amount;
      delete payload.calculation;

      delete payload.accountId;
      delete payload.author;
      delete payload.created_timestamp;
      delete payload.updated_timestamp;
      delete payload.proposalId;
      delete payload.is_current_rate;
      delete payload.proposal_id;
      delete payload.order_id;
      delete payload.issuer_name;

      const response = await api.issuerCoverageBuilder.createOffer(
        accountId,
        proposalId,
        configId,
        coverage,
        payload,
      );

      toast(`Your ${coverage} offer was successfully saved!`, { type: 'success' });

      thunkApi.dispatch(
        getIssuerCoveragePlans({
          accountId: accountId,
          proposalId: proposalId,
          coverage,
          read_token,
        }),
      );

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const updateIssuerCoverageOffer = createAsyncThunk(
  'issuer-coverage/update-offer',
  async (data, thunkApi) => {
    try {
      const { options, payload } = data;

      const { accountId, proposalId, configId, coverage, read_token } = options;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      delete payload.created_timestamp;
      // delete payload.comp_amount;

      delete payload.accountId;
      delete payload.calculation;
      delete payload.author;
      delete payload.created_timestamp;
      delete payload.updated_timestamp;
      delete payload.proposalId;
      delete payload.is_current_rate;
      delete payload.proposalId;
      delete payload.order_id;
      delete payload.issuer_name;

      const response = await api.issuerCoverageBuilder.updateOffer(
        accountId,
        proposalId,
        configId,
        coverage,
        payload,
        payload?.id,
        read_token,
      );

      toast(`Your ${coverage} offer was successfully updated!`, { type: 'success' });
      thunkApi.dispatch(
        getIssuerCoveragePlans({
          accountId: accountId,
          proposalId: proposalId,
          coverage,
          read_token,
        }),
      );

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const deleteIssuerCoverageOffer = createAsyncThunk(
  'issuer-coverage/delete-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, configId, offerId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.issuerCoverageBuilder.deleteOffer(
        accountId,
        proposalId,
        coverage,
        configId,
        offerId,
      );

      thunkApi.dispatch(
        getIssuerCoveragePlans({
          accountId: accountId,
          proposalId: proposalId,
          coverage,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  },
);

export const clearCoverageBuilderState = createAsyncThunk(
  'issuer-coverage/clear-state',
  (data, thunkApi) => {
    thunkApi.dispatch(clearIssuerCoveragesBuilderState());
  },
);
