const placeholderImage =
  'https://res.cloudinary.com/dlocussey/image/upload/v1589832771/website/128x128-placeholder_hvdami.png';

export const urlToImageFile = async (url) => {
  const image = url || placeholderImage;

  try {
    const response = await fetch(image);

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => {
        console.error('Error reading blob as DataURL');
        reject(new Error('Error converting blob to DataURL'));
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image URL to base64:', error);
    throw error;
  }
};
