import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

import { getCityByZipCode, getCityStateData, setCityStateData } from 'src/store/app';
import {
  getAccountMode,
  setAccountMode,
  getAccountData,
  getBrokerAccountData,
  createBrokerAccount,
  updateBrokerAccount,
  getBrokerData,
  getIsLoadingCreateAccount,
  getIsLoadingUpdateAccount,
} from 'src/store/broker';
import {
  createAccountContact,
  getAccountContacts,
  deleteAccountContact,
  updateAccountContact,
  isLoadingCreateContactSelector,
  isLoadingUpdateContactSelector,
  accountContactsSelector,
} from 'src/store/accountContacts';

import { UploadImage } from '../../uploadImage/UploadImage';
import { ManageAccountContactsTable } from './ManageAccountContactsTable';

import { ROUTE } from 'src/constants/routes';
import { sicCodes } from 'src/constants/sicCodes';
import { fipCodes } from 'src/constants/fipCodes';
import { stateCodes } from 'src/constants/stateCodes';
import { ERROR } from 'src/constants/errorNames';
import { emailRegex } from 'src/constants/regularExpression';
import { accountFormValues, normalizeAccountData } from './manageAccountConstants';

import classes from './manageAccount.module.scss';
import { Loader } from 'src/components';

// TODO: Needs further refactoring

export const ManageAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params?.id;
  const teamId = params?.teamId;

  const location = useLocation();
  const isCreateAccount = location.pathname?.includes('/create');

  const accountMode = useSelector(getAccountMode);
  const accountData = useSelector(getAccountData);
  const accountContacts = useSelector(accountContactsSelector);
  const brokerData = useSelector(getBrokerData);

  const isLoadingCreateAccount = useSelector(getIsLoadingCreateAccount);
  const isLoadingUpdateAccount = useSelector(getIsLoadingUpdateAccount);
  const isLoadingCreateContact = useSelector(isLoadingCreateContactSelector);
  const isLoadingUpdateContact = useSelector(isLoadingUpdateContactSelector);

  const cityStateData = useSelector(getCityStateData);
  const isCityStateData = !_.isEmpty(cityStateData);

  const [contactsData, setContactsData] = useState({
    primary_name: '',
    primary_email: '',
    broker_name: '',
    broker_email: '',
  });

  const [formData, setFormData] = useState(accountFormValues);

  const [createContactsData, setCreateContactsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedEditRow, setSelectedEditRow] = useState({});
  const [selectedDeleteId, setSelectedDeleteId] = useState('');

  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    if (accountData?.company_name) {
      document.title = `${accountData?.company_name} | Monark`;
    } else {
      document.title = `Accounts | Monark`;
    }
  }, [accountData]);

  useEffect(() => {
    if (isCreateAccount && !accountMode) {
      dispatch(setAccountMode('CREATE'));
    }

    if (!isCreateAccount && !accountMode) {
      dispatch(setAccountMode('EDIT'));
    }
  }, [accountMode, dispatch, isCreateAccount]);

  useEffect(() => {
    if (accountMode === 'CREATE') {
      const accountId = uuid();

      setAccountId(accountId);
    }
  }, [accountMode]);

  useEffect(() => {
    if (accountMode === 'EDIT') {
      dispatch(getAccountContacts({ accountId: paramsId }));
    }
  }, [dispatch, accountMode, paramsId]);

  useEffect(() => {
    if (location.pathname.includes('/edit')) {
      dispatch(setAccountMode('EDIT'));
      dispatch(getBrokerAccountData({ paramsId, navigate }));
    }
  }, [dispatch, navigate, location, paramsId]);

  useEffect(() => {
    if (accountMode === 'EDIT') {
      setFormData(normalizeAccountData(accountData));
    }
  }, [accountMode, accountData]);

  useEffect(() => {
    return () => {
      dispatch(setAccountMode(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!formData.address_zip_code) {
      dispatch(setCityStateData({}));
    }
  }, [dispatch, formData.address_zip_code]);

  useEffect(() => {
    if (!isCityStateData) {
      setFormData((prev) => ({
        ...prev,
        address_city: '',
        address_state: '',
        fips_code: '',
      }));
    }
  }, [isCityStateData]);

  useEffect(() => {
    if (formData.address_zip_code) {
      const debounceSearch = setTimeout(() => {
        dispatch(getCityByZipCode(formData.address_zip_code));
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, formData.address_zip_code]);

  useEffect(() => {
    if (isCityStateData) {
      setFormData((prev) => ({
        ...prev,
        address_city: cityStateData?.primary_city,
        address_state: cityStateData?.state,
        fips_code: cityStateData?.county,
      }));
    }
  }, [isCityStateData, cityStateData]);

  useEffect(() => {
    if (!_.isEmpty(brokerData)) {
      setContactsData((prev) => ({
        ...prev,
        broker_name: brokerData?.display_name,
        broker_email: brokerData?.email,
      }));
    }
  }, [brokerData]);

  const onChangeFormValue = useCallback((event, key) => {
    setFormData((prev) => ({
      ...prev,
      [key]: event?.target?.value ?? event,
    }));
  }, []);

  const onChangeContactsInput = useCallback((event, key) => {
    setContactsData((prev) => ({
      ...prev,
      [key]: event?.target?.value ?? event,
    }));
  }, []);

  const onClickAddContact = useCallback(() => {
    const payload = {
      id: uuid(),
      name: '',
      email: '',
      contact_type: 'cc',
    };

    setCreateContactsData((prev) => [...prev, payload]);
  }, []);

  const onChangeInput = useCallback(
    (event, rowField) => {
      const row = createContactsData.find((item) => item.id === selectedRow.id);
      row[rowField] = event.target.value;

      const payload = createContactsData.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj[rowField] === o[rowField])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setCreateContactsData([...payload]);
    },
    [createContactsData, selectedRow.id],
  );

  const onChangeEditInput = useCallback(
    (event, target) => {
      const payload = {
        ...selectedEditRow,
        [target]: event.target.value,
      };

      setSelectedEditRow(payload);
    },
    [selectedEditRow],
  );

  const onSelectRow = useCallback(
    (row) => () => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const onDeleteRow = useCallback(
    (id) => () => {
      const payload = _.reject(createContactsData, (row) => row.id === id);

      setCreateContactsData(payload);
      setSelectedRow({});
    },
    [createContactsData],
  );

  const onClickEdit = useCallback(
    (item) => () => {
      setSelectedEditRow(item);
    },
    [],
  );

  const onClickSaveNewContact = useCallback(async () => {
    for (const item of createContactsData) {
      if (!item.name) {
        return toast('Name cannot be empty', { type: 'warning' });
      }

      if (!item.email) {
        return toast('Email Number cannot be empty', { type: 'warning' });
      }

      if (!item.email.match(emailRegex)) {
        return toast('Please, enter valid email', { type: 'warning' });
      }
    }

    const payload = {
      account_id: paramsId,
      ...createContactsData[0],
    };

    delete payload.id;

    await dispatch(createAccountContact({ accountId: paramsId, payload }));

    setCreateContactsData([]);
  }, [dispatch, createContactsData, paramsId]);

  const onClickUpdateContact = useCallback(async () => {
    await dispatch(
      updateAccountContact({
        accountId: selectedEditRow?.account_id,
        contactId: selectedEditRow?.id,
        payload: selectedEditRow,
      }),
    );

    setSelectedEditRow({});
  }, [dispatch, selectedEditRow]);

  const onClickCancelRow = useCallback(() => {
    setSelectedEditRow({});
  }, []);

  const onClickDeleteContact = useCallback(
    (accountId, contactId) => () => {
      setSelectedDeleteId(contactId);
      dispatch(deleteAccountContact({ accountId, contactId }));
    },
    [dispatch],
  );

  const renderTextField = useCallback(
    (label, key, required = false) => {
      return (
        <TextField
          className={classes.ManageAccountFormItem}
          label={label}
          variant="outlined"
          value={formData[key]}
          onChange={(event) => onChangeFormValue(event, key)}
          size="small"
          required={required}
        />
      );
    },
    [formData, onChangeFormValue],
  );

  const renderAutoComplete = useCallback(
    (options, key, label) => {
      return (
        <Autocomplete
          disablePortal
          options={options}
          getOptionSelected={(option, value) => option === value}
          value={formData[key]}
          onChange={(event, newValue) => {
            onChangeFormValue({ target: { value: newValue ?? '' } }, key);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.ManageAccountFormItem}
              label={label}
              size="small"
              required
            />
          )}
        />
      );
    },
    [formData, onChangeFormValue],
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!sicCodes.includes(formData.sic_code)) {
        return toast(`Is not valid sic code: ${formData.sic_code}`, { type: 'error' });
      }

      const primaryContact = accountContacts?.find((item) => item.contact_type === 'primary');

      const payload = {
        ...formData,
        contact_name: accountMode === 'CREATE' ? contactsData.primary_name : primaryContact?.name,
        contact_email:
          accountMode === 'CREATE' ? contactsData.primary_email : primaryContact?.email,
      };

      if (teamId) {
        payload.org_id = teamId;
      }

      if (accountMode === 'CREATE') {
        payload.id = accountId;
        if (!payload.contact_phone) delete payload.contact_phone;
        if (!payload.tax_id) delete payload.tax_id;
        if (!payload.address_city) delete payload.address_city;
        if (!payload.address_state) delete payload.address_state;
        if (!payload.address_street) delete payload.address_street;
        if (!payload.logo_url) delete payload.logo_url;
      }

      if (accountMode === 'EDIT') {
        return dispatch(updateBrokerAccount({ id: paramsId, payload, navigate }));
      }

      if (isCityStateData) {
        let updatedContacts = [];
        let contacts = [
          {
            name: contactsData.primary_name,
            email: contactsData.primary_email,
            contact_type: 'primary',
          },
          {
            id: brokerData.id,
            name: contactsData.broker_name,
            email: contactsData.broker_email,
            contact_type: 'broker',
          },
          ...createContactsData,
        ];

        for (const contact of contacts) {
          let payload = { ...contact, account_id: accountId };
          updatedContacts.push(payload);
        }

        return dispatch(createBrokerAccount({ payload, navigate, contacts: updatedContacts }));
      }

      if (!isCityStateData) {
        toast(ERROR.ZIP_CODE_ERROR, { type: 'error' });
      }
    },
    [
      dispatch,
      navigate,
      formData,
      teamId,
      accountMode,
      accountContacts,
      isCityStateData,
      accountId,
      paramsId,
      contactsData,
      brokerData.id,
      createContactsData,
    ],
  );

  const onClickCancel = useCallback(() => {
    if (!teamId) {
      if (accountMode === 'EDIT') {
        return navigate(`${ROUTE.BROKER}/${paramsId}`);
      }

      navigate(ROUTE.BROKER);
    }

    if (teamId) {
      if (accountMode === 'EDIT') {
        return navigate(`/teams/${teamId}/accounts/${paramsId}`);
      }

      navigate(`/teams/${teamId}/accounts`);
    }
  }, [navigate, paramsId, accountMode, teamId]);

  const uploadImagePath =
    accountMode === 'EDIT'
      ? `accounts/${paramsId}/client-logo`
      : `accounts/${accountId}/client-logo`;

  return (
    <>
      {(isLoadingCreateAccount ||
        isLoadingUpdateAccount ||
        isLoadingCreateContact ||
        isLoadingUpdateContact) && (
        <div className={classes.LoaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.ManageAccount}>
        <div className={classes.ManageAccountTitle}>General Information</div>
        <div className={classes.ManageAccountContent}>
          <form onSubmit={onSubmit}>
            <div className={classes.ManageAccountUploadImage}>
              <UploadImage
                path={uploadImagePath}
                setPreviewImage={setFormData}
                imageKey="logo_url"
                previewImage={formData.logo_url}
              />
            </div>
            <div className={classes.ManageAccountFormContent}>
              {renderTextField('Account Name', 'company_name', true)}
              <DatePicker
                label="Renewal Date"
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                    className: classes.ManageAccountFormItem,
                  },
                }}
                value={formData.renewal_date ? moment(formData.renewal_date) : null}
                format="YYYY-MM-DD"
                onChange={(value) =>
                  onChangeFormValue(moment(value).format('YYYY-MM-DD'), 'renewal_date')
                }
              />
              {renderTextField('Contact Phone', 'contact_phone')}
              {renderTextField('Tax ID', 'tax_id')}
              {renderAutoComplete(sicCodes, 'sic_code', 'SIC code')}
            </div>
            <div className={classes.ManageAccountTitle}>Account Contacts</div>
            <div className={classes.ManageAccountContacts}>
              <ManageAccountContactsTable
                contactsData={contactsData}
                selectedDeleteId={selectedDeleteId}
                createContactsData={createContactsData}
                selectedEditRow={selectedEditRow}
                onChangeContactsInput={onChangeContactsInput}
                onSelectRow={onSelectRow}
                onChangeInput={onChangeInput}
                onDeleteRow={onDeleteRow}
                onChangeEditInput={onChangeEditInput}
                onClickEdit={onClickEdit}
                onClickDeleteContact={onClickDeleteContact}
                onClickUpdateContact={onClickUpdateContact}
                onClickCancelRow={onClickCancelRow}
                onClickSaveNewContact={onClickSaveNewContact}
                onClickAddContact={onClickAddContact}
              />
            </div>

            <div className={classes.ManageAccountTitle}>Headquarter Location</div>
            <div className={classes.ManageAccountFormContent}>
              {renderTextField('Address', 'address_street')}
              {renderTextField('Zip Code', 'address_zip_code', true)}
              {isCityStateData && (
                <div className={classes.ManageAccountAddress}>
                  <TextField
                    className={classes.ManageAccountFormItem}
                    label="City"
                    variant="outlined"
                    value={formData.address_city}
                    onChange={(event) => onChangeFormValue(event, 'address_city')}
                    size="small"
                  />
                  {renderAutoComplete(stateCodes, 'address_state', 'State')}
                </div>
              )}
              {isCityStateData && renderAutoComplete(fipCodes, 'fips_code', 'County')}
            </div>
            <div className={classes.ManageAccountFooter}>
              <Button variant="contained" type="submit">
                {accountMode === 'EDIT' ? 'Save' : 'Create'}
              </Button>
              <Button variant="outlined" onClick={onClickCancel}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
