import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { getBrokerData } from 'src/store/broker';
import { getDentalIssuersSelector } from 'src/store/planConfiguration';
import {
  createAgencyContact,
  createBrokerContact,
  getCarrierContactSelector,
  getUserByContactEmail,
  isLoadingCarrierContactSelector,
  isLoadingCreateContactSelector,
  setCarrierContact,
} from 'src/store/contacts';

import { emailDomains } from 'src/constants/emailDomains';
import { emailRegex } from 'src/constants/regularExpression';

import { Button, Input, MUISelect, Modal } from 'src/components';

import classes from './addContacts.module.scss';

export const AddContacts = (props) => {
  const { isBodyScroll = true } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const org_id = params?.teamId;

  const brokerData = useSelector(getBrokerData);
  const carrierContact = useSelector(getCarrierContactSelector);
  const issuersData = useSelector(getDentalIssuersSelector);
  const isLoadingCreateContact = useSelector(isLoadingCreateContactSelector);
  const isLoadingCarrierContact = useSelector(isLoadingCarrierContactSelector);

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [selectedOption, setSelectedOption] = useState('selectCarrier');
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [isContactsModal, setIsContactsModal] = useState(false);

  useEffect(() => {
    if (!isContactsModal && !isBodyScroll) {
      document.body.style.overflow = 'hidden';
    }
  }, [isContactsModal, isBodyScroll]);

  useEffect(() => {
    if (issuersData.length > 0) {
      let updatedIssuers = [
        { title: 'Select Carrier', value: 'selectCarrier' },
        { title: 'Custom', value: 'custom' },
      ];

      for (const issuer of issuersData) {
        const payload = {
          title: issuer.name,
          value: issuer.ids[0],
        };

        updatedIssuers.push(payload);
      }
      setCarrierOptions(updatedIssuers);
    }
  }, [issuersData]);

  useEffect(() => {
    if (_.isEmpty(carrierContact)) {
      setContactName('');
      setCarrierName('');
    }
  }, [carrierContact]);

  useEffect(() => {
    if (!_.isEmpty(carrierContact)) {
      setContactName(carrierContact.display_name);
      setContactEmail(carrierContact.email);
      setSelectedOption(carrierContact.issuer_id ? carrierContact.issuer_id : 'custom');

      if (!carrierContact.issuer_id) {
        setCarrierName(carrierContact.issuer_name);
      }
    }
  }, [carrierContact]);

  useEffect(() => {
    if (issuersData.length > 0 && contactEmail) {
      const emailDomain = contactEmail.split('@')[1];

      const debounceSearch = setTimeout(() => {
        const findedIssuer = issuersData.find((item) => item.email_domains.includes(emailDomain));

        if (findedIssuer) {
          setIsDisabled(true);
          setSelectedOption(findedIssuer.ids[0]);
        }

        if (!findedIssuer && emailDomain && emailDomain.includes('.')) {
          setIsDisabled(true);
          setSelectedOption('custom');
        }
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [issuersData, contactEmail]);

  useEffect(() => {
    if (!contactEmail) {
      setSelectedOption('selectCarrier');
      setIsDisabled(false);
      dispatch(setCarrierContact({}));
    }
  }, [dispatch, contactEmail]);

  useEffect(() => {
    if (contactEmail?.match(emailRegex)) {
      const debounceSearch = setTimeout(() => {
        dispatch(getUserByContactEmail(contactEmail));
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, contactEmail]);

  const onSelectOption = useCallback((event) => {
    setSelectedOption(event.target.value);
    setCarrierName('');
  }, []);

  const onChangeContactName = useCallback((event) => {
    setContactName(event.target.value);
  }, []);

  const onChangeContactEmail = useCallback((event) => {
    setContactEmail(event.target.value);
  }, []);

  const onChangeCarrierName = useCallback((event) => {
    setCarrierName(event.target.value);
  }, []);

  const onClickAddAccount = useCallback(async () => {
    const selectedCarrier = issuersData.find((item) => item.ids[0] === selectedOption);

    if (selectedOption === 'selectCarrier') {
      return toast('Please select your carrier', { type: 'warning' });
    }

    if (!contactName) {
      return toast('Please enter Contact Name', { type: 'warning' });
    }

    if (!contactEmail.match(emailRegex)) {
      return toast('Please, enter valid contactEmail', { type: 'warning' });
    }

    if (selectedOption === 'custom') {
      if (!carrierName) {
        return toast('Please, enter Carrier Name', { type: 'warning' });
      }
    }

    let payload = {
      email: contactEmail,
      issuer_name: carrierName,
      broker_id: brokerData.id,
      contact_name: contactName,
      selectedOption,
    };

    if (selectedCarrier?.ids[0]) {
      payload.issuer_id = selectedCarrier?.ids[0];
      payload.issuer_name = selectedCarrier.name;
    }

    const emailDomain = contactEmail?.split('@')[1];

    if (emailDomains.includes(emailDomain)) {
      return toast('Please use your work email', { type: 'warning' });
    }

    if (!org_id) {
      await dispatch(createBrokerContact(payload));
    }

    if (org_id) {
      await dispatch(createAgencyContact({ org_id, ...payload }));
    }

    setContactName('');
    setContactEmail('');
    setCarrierName('');
    setIsContactsModal(false);
  }, [
    dispatch,
    org_id,
    issuersData,
    selectedOption,
    brokerData,
    carrierName,
    contactEmail,
    contactName,
  ]);

  const onOpenContactsModal = useCallback(() => {
    setIsContactsModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsContactsModal(false);
    setContactName('');
    setContactEmail('');
    setCarrierName('');
    setSelectedOption('selectCarrier');
  }, []);

  return (
    <>
      {isContactsModal && (
        <Modal type="large" closeButtonType="inside" onClose={onCloseModal}>
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Add Contacts</div>

            <div className={classes.ModalContent}>
              <div className={classes.ModalContentOption}>
                <MUISelect
                  label="Carrier Name"
                  value={selectedOption}
                  options={carrierOptions}
                  onChange={onSelectOption}
                  minWidth={160}
                  disabled={isDisabled}
                />
              </div>
              <Input
                label="Contact Email"
                placeholder="Contact Email"
                value={contactEmail}
                onChange={onChangeContactEmail}
                isLoading={isLoadingCarrierContact}
              />
              <Input
                label="Contact Name"
                placeholder="Contact Name"
                value={contactName}
                onChange={onChangeContactName}
              />
              {selectedOption === 'custom' && (
                <Input
                  label="Carrier Name"
                  placeholder="Carrier Name"
                  value={carrierName}
                  onChange={onChangeCarrierName}
                />
              )}
            </div>
            <div className={classes.ModalFooter}>
              <Button
                type="primary"
                title="Save"
                onClick={onClickAddAccount}
                isLoading={isLoadingCreateContact}
              />
              <Button type="secondary" title="Cancel" onClick={onCloseModal} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.Contacts}>
        <Button type="primary" title="Add Contact" onClick={onOpenContactsModal} />
      </div>
    </>
  );
};
