import Base from './base';

class CoverageBuilder extends Base {
  createCoveragePlan = (accountId, proposalId, coverage, payload) => {
    delete payload?.is_configured;

    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config`,
      payload,
    );
  };

  getCoveragePlans = (accountId, proposalId, coverage, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  updateCoveragePlan = (accountId, proposalId, coverage, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${payload.id}`,
      payload,
    );
  };

  deleteCoveragePlan = (accountId, proposalId, coverage, planId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${planId}`,
    );
  };

  updateCoveragePlanOrder = (accountId, proposalId, coverage, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config`,
      payload,
    );
  };

  getCoverageQuotePlans = (accountId, proposalId, coverage, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  updateCoverageQuotePlanOrder = (accountId, proposalId, coverage, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config?selected_offers=true`,
      payload,
    );
  };

  createCoverageTemplate = (accountId, proposalId, coverage, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/templates`,
      payload,
    );
  };

  getCoverageTemplates = (accountId, proposalId, coverage, payload) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/templates`,
      payload,
    );
  };

  deleteCoverageTemplate = (accountId, proposalId, coverage, templateId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/templates/${templateId}`,
    );
  };

  updateCoverageOffer = (
    accountId,
    proposalId,
    configId,
    medicalType,
    payload,
    offerId,
    read_token,
  ) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalType}/config/${configId}/offers/${offerId}${
        read_token ? `?token=${read_token}` : ''
      }`,
      payload,
    );
  };

  getCoverageOffers = (accountId, proposalId, coverage, configId, params) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${configId}/offers`,
      params,
    );
  };
}

export default CoverageBuilder;
