import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { BuilderSelect, BuilderCASelect } from 'src/features/coverageBuilder';

import {
  compMethodOptions,
  offJobOptions,
  hospitalOptions,
  icuOptions,
  dailyOptions,
  confinementOptions,
  dailyIcuOptions,
  icuDaysOptions,
  accidentalOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeAccidentType } from 'src/constants/requestProposal';
import { AGES_80 } from 'src/features/coverageBuilder/cb_constants';

import GeneralRatesExample from 'src/files/general/general-rates-example.xlsx';

export const useAccidentBuild = () => {
  const initialState = {
    id: uuid(),
    ...AGES_80,
    is_current_rate: false,
    plan_type: '',
    hospital_admission: '',
    icu_confinement: '',
    daily_hospital_confinement: '',
    confinement_days_covered: '',
    daily_icu_confinement: '',
    icu_days_covered: '',
    accidental_death: '',
    comp_method: '',
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'accident',
    title: 'Accident',
    card_options: {
      proposal: [
        {
          title: 'Type',
          key: 'plan_type',
          format: (value) => normalizeAccidentType(value),
        },
        {
          title: 'Hospital Admission',
          key: 'hospital_admission',
          format: (value) => formattedValue(value),
        },
        {
          title: 'ICU Confinement',
          key: 'icu_confinement',
          format: (value) => formattedValue(value),
        },
      ],
    },
    form_options: {
      initial_state: initialState,
      import_data: [
        {
          title: 'Download sample template for Accident age rates',
          file: GeneralRatesExample,
          file_name: 'accident-rates-example',
          key: 'accident',
        },
      ],
      form_data: formData,
      setFormData,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="24 Hour / Off the Job"
        value={formData.plan_type}
        options={offJobOptions}
        onChange={(event) => onChange(event, 'plan_type')}
      />,
      <BuilderSelect
        key="2"
        label="Hospital Admission"
        value={formData.hospital_admission}
        options={hospitalOptions}
        onChange={(event) => onChange(event, 'hospital_admission')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="3"
        label="ICU Confinement"
        value={formData.icu_confinement}
        options={icuOptions}
        onChange={(event) => onChange(event, 'icu_confinement')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="4"
        label="Daily Hospital Confinement"
        value={formData.daily_hospital_confinement}
        options={dailyOptions}
        onChange={(event) => onChange(event, 'daily_hospital_confinement')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="5"
        label="Confinement Days Covered"
        value={formData.confinement_days_covered}
        options={confinementOptions}
        onChange={(event) => onChange(event, 'confinement_days_covered')}
        type="other"
        inputType="number"
      />,
      <BuilderSelect
        key="6"
        label="Daily ICU Confinement"
        value={formData.daily_icu_confinement}
        options={dailyIcuOptions}
        onChange={(event) => onChange(event, 'daily_icu_confinement')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="7"
        label="ICU Days Covered"
        value={formData.icu_days_covered}
        options={icuDaysOptions}
        onChange={(event) => onChange(event, 'icu_days_covered')}
        type="other"
        inputType="number"
      />,
      <BuilderSelect
        key="8"
        label="Accidental Death"
        value={formData.accidental_death}
        options={accidentalOptions}
        onChange={(event) => onChange(event, 'accidental_death')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="9"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="10"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'plan_type', format: (value) => normalizeAccidentType(value) },
      { key: 'hospital_admission', format: (value) => currencyFormatter(value) },
      { key: 'icu_confinement', format: (value) => currencyFormatter(value) },
      { key: 'daily_hospital_confinement', format: (value) => currencyFormatter(value) },
      { key: 'confinement_days_covered', format: (value) => `${value} Days` },
      { key: 'daily_icu_confinement', format: (value) => currencyFormatter(value) },
      { key: 'icu_days_covered', format: (value) => `${value} Days` },
      { key: 'accidental_death', format: (value) => currencyFormatter(value) },
    ],
  };

  return { build };
};
