import Base from './base';

// GET /accounts/:accountId/contacts/:contactId

class Admin extends Base {
  getAdminBrokers = (payload) => {
    return this.apiClient.get('brokers', payload);
  };

  getAdminReps = (payload) => {
    return this.apiClient.get('reps', payload);
  };

  createCustomToken = (payload) => {
    return this.apiClient.post('users/custom-token', payload);
  };
}

export default Admin;
