import {
  accidentalOptions,
  compMethodOptions,
  confinementOptions,
  dailyIcuOptions,
  dailyOptions,
  formattedValue,
  hospitalOptions,
  icuDaysOptions,
  icuOptions,
  offJobOptions,
} from 'src/constants/coverage';
import { normalizeAccidentType } from 'src/constants/requestProposal';

export const useAccidentIssuerBuild = () => {
  const build = {
    coverage: 'accident',
    title: 'Accident',
    form_options: {
      // contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Type',
          key: 'plan_type',
          format: (value) => normalizeAccidentType(value),
        },
        {
          title: 'Hospital Admission',
          key: 'hospital_admission',
          format: (value) => formattedValue(value),
        },
        {
          title: 'ICU Confinement',
          key: 'icu_confinement',
          format: (value) => formattedValue(value),
        },
      ],
    },
    offer_details: [
      {
        key: 'plan_type',
        format: (value) => normalizeAccidentType(value),
        options: { render_type: 'select', render_data: offJobOptions },
      },
      {
        key: 'hospital_admission',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: hospitalOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'icu_confinement',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: icuOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'daily_hospital_confinement',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: dailyOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'confinement_days_covered',
        format: (value) => `${value} Days`,
        options: {
          render_type: 'select',
          render_data: confinementOptions,
          type: 'other',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'daily_icu_confinement',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: dailyIcuOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'icu_days_covered',
        format: (value) => `${value} Days`,
        options: {
          render_type: 'select',
          render_data: icuDaysOptions,
          type: 'other',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'accidental_death',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: accidentalOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
