import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getIssuerAccountProposalData, getIssuerDataSelector } from 'src/store/issuer';

import { ROUTE } from 'src/constants/routes';

import { Button, PageHeader } from 'src/components';

import classes from './issuerProposalHeader.module.scss';
import { IssuerExportModal } from '../issuerExportModal/IssuerExportModal';

export const IssuerProposalHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const issuerData = useSelector(getIssuerDataSelector);
  const proposalData = useSelector(getIssuerAccountProposalData);

  const isRep = pathname.includes('rep');

  const onClickBackButton = useCallback(() => {
    if (isRep) {
      return navigate(ROUTE.REP_MY_RFPS);
    }

    navigate(`/issuer/${issuerData?.issuer_id}/rfps`);
  }, [navigate, isRep, issuerData]);

  return (
    <div className={classes.IssuerProposalHeader}>
      <PageHeader title={proposalData?.name} onClickBackButton={onClickBackButton}>
        <div className={classes.IssuerProposalHeaderButtons}>
          <IssuerExportModal />
          <Button type="danger" title="Decline" />
        </div>
      </PageHeader>
    </div>
  );
};
