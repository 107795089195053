import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { BuilderSelect, BuilderCASelect, BuilderInput } from 'src/features/coverageBuilder';

import {
  compMethodOptions,
  disabilityPlanTypeOptions,
  disabilityCompanyContributionOptions,
  pctIncomeRequestOptions,
  stdEliminationPeriodOptions,
  ltdEliminationPeriodOptions,
  stdBenefitPeriodOptions,
  ltdBenefitPeriodOptions,
  rateBasisOptions,
} from 'src/constants/coverage';
import { AGES_80 } from 'src/features/coverageBuilder/cb_constants';
import {
  normalizeDisabilityPlanType,
  normalizeLtdBenefitPeriod,
  normalizeStdBenefitPeriod,
} from 'src/constants/requestProposal';

import GeneralRatesExample from 'src/files/general/general-rates-example.xlsx';

export const useDisabilityBuild = () => {
  const initialState = {
    id: uuid(),
    ...AGES_80,
    is_current_rate: false,
    plan_type: null,
    contribution_type: null,
    income_pct: null,
    std_weekly_max: null,
    std_benefit_period: null,
    std_elimination_period: null,
    ltd_monthly_max: null,
    ltd_benefit_period: null,
    ltd_elimination_period: null,
    rate_per_unit: null,
    rate_basis: null,
    comp_method: null,
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    custom_fields: [],
    rate_tier_type: 'employee',
    rate_ee: null,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    if (key === 'plan_type') {
      return setFormData((prev) => ({
        ...prev,
        [key]: event.target.value,
        rate_per_unit: event.target.value === 'std' ? 10 : 100,
      }));
    }

    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const build = {
    coverage: 'disability',
    title: 'Disability',
    card_options: {
      proposal: [
        {
          title: 'Contribution',
          key: 'contribution_type',
        },
        {
          title: '% of Income',
          key: 'income_pct',
          format: (value) => `${value} %`,
        },
        {
          title: 'Plan Type',
          key: 'plan_type',
          format: (value) => value.toUpperCase(),
          render: (value) =>
            value === 'std'
              ? [
                  { title: 'Elimination Period', key: 'std_elimination_period' },
                  {
                    title: 'Benefit Period',
                    key: 'std_benefit_period',
                    format: (value) => normalizeStdBenefitPeriod(value),
                  },
                ]
              : [
                  {
                    title: 'Elimination Period',
                    key: 'ltd_elimination_period',
                    format: (value) => `${value} Days`,
                  },
                  {
                    title: 'Benefit Period',
                    key: 'ltd_benefit_period',
                    format: (value) => normalizeLtdBenefitPeriod(value),
                  },
                ],
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    form_options: {
      initial_state: initialState,
      import_data: [
        {
          title: 'Download sample template for Disability age rates',
          file: GeneralRatesExample,
          file_name: 'disability-rates-example',
          key: 'disability',
        },
      ],
      form_data: formData,
      setFormData,
    },
    form_content: (mode) => [
      <BuilderSelect
        key="1"
        label="Plan Type"
        value={formData.plan_type}
        options={disabilityPlanTypeOptions}
        onChange={(event) => onChange(event, 'plan_type')}
        disabled={mode === 'EDIT'}
      />,
      <BuilderSelect
        key="2"
        label="Company Contribution"
        value={formData.contribution_type}
        options={disabilityCompanyContributionOptions}
        onChange={(event) => onChange(event, 'contribution_type')}
      />,
      <BuilderSelect
        key="3"
        label="% of Income Requested"
        value={formData.income_pct}
        options={pctIncomeRequestOptions}
        onChange={(event) => onChange(event, 'income_pct')}
        type="other"
        symbol="%"
        symbolPosition="end"
      />,
      ...(formData.plan_type === 'std'
        ? [
            <BuilderInput
              key="4"
              label="STD Weekly Max"
              value={formData.std_weekly_max}
              onChange={(event) => onChange(event, 'std_weekly_max')}
              symbol="$"
              inputType="number"
            />,
            <BuilderSelect
              key="5"
              label="STD Elimination Period (Accident/Sickness)"
              value={formData.std_elimination_period}
              options={stdEliminationPeriodOptions}
              onChange={(event) => onChange(event, 'std_elimination_period')}
              type="other"
            />,
            <BuilderSelect
              key="6"
              label="STD Benefit Period"
              value={formData.std_benefit_period}
              options={stdBenefitPeriodOptions}
              onChange={(event) => onChange(event, 'std_benefit_period')}
              type="other"
              inputType="number"
            />,
          ]
        : []),
      ...(formData.plan_type === 'ltd'
        ? [
            <BuilderInput
              key="7"
              label="LTD Monthly Max"
              value={formData.ltd_monthly_max}
              onChange={(event) => onChange(event, 'ltd_monthly_max')}
              symbol="$"
              inputType="number"
            />,
            <BuilderSelect
              key="8"
              label="LTD Elimination Period"
              value={formData.ltd_elimination_period}
              options={ltdEliminationPeriodOptions}
              onChange={(event) => onChange(event, 'ltd_elimination_period')}
              type="other"
            />,
            <BuilderSelect
              key="9"
              label="LTD Benefit Period"
              value={formData.ltd_benefit_period}
              options={ltdBenefitPeriodOptions}
              onChange={(event) => onChange(event, 'ltd_benefit_period')}
            />,
          ]
        : []),
      <BuilderInput
        key="10"
        label="$ per unit"
        value={formData.rate_per_unit}
        onChange={(event) => onChange(event, 'rate_per_unit')}
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="11"
        label="Rate Basis"
        value={formData.rate_basis}
        options={rateBasisOptions}
        onChange={(event) => onChange(event, 'rate_basis')}
      />,
      <BuilderSelect
        key="12"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="13"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'income_pct', format: (value) => `${value} %` },
      { key: 'contribution_type' },
      {
        key: 'plan_type',
        format: (value) => normalizeDisabilityPlanType(value),
        render: (value) =>
          value === 'std'
            ? [
                {
                  key: 'std_weekly_max',
                  format: (value) => `$${value}`,
                },
                {
                  key: 'std_elimination_period',
                },
                {
                  key: 'std_benefit_period',
                  format: (value) => normalizeStdBenefitPeriod(value),
                },
              ]
            : [
                {
                  key: 'ltd_monthly_max',
                  format: (value) => `$${value}`,
                },
                {
                  key: 'ltd_elimination_period',
                  format: (value) => `${value} Days`,
                },
                {
                  key: 'ltd_benefit_period',
                  format: (value) => normalizeLtdBenefitPeriod(value),
                },
              ],
      },
      { key: 'rate_per_unit', format: (value) => `$${value}` },
      { key: 'rate_basis' },
    ],
  };

  return { build };
};
