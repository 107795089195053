import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ROUTE } from 'src/constants/routes';
import { getAccountData, getBrokerAccountData } from 'src/store/broker';
import {
  getIssuerAccountMembers,
  getIssuerAccountProposalData,
  getIssuerCoveragePlans,
  getIssuerDataSelector,
  isLoadingIssuerCoveragePlansSelector,
  issuerCoveragePlansSelector,
} from 'src/store/issuer';
import {
  chatRoomsSelector,
  getChatRooms,
  isLoadingStartChatSelector,
  startChat,
} from 'src/store/chat';

import { MenuSubItem } from '../MenuISubtem/MenuISubtem';
import { MenuItem } from '../MenuItem/MenuItem';

import classes from './repMenu.module.scss';

export const RepMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const issuerData = useSelector(getIssuerDataSelector);
  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getIssuerAccountProposalData);
  const coveragePlans = useSelector(issuerCoveragePlansSelector);
  const chatRooms = useSelector(chatRoomsSelector);
  const isLoadingStartChat = useSelector(isLoadingStartChatSelector);
  const isLoadingCoveragePlans = useSelector(isLoadingIssuerCoveragePlansSelector);

  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const isMyRfps = pathname?.includes('accounts') && pathname?.includes('proposals');
  const isSettings = pathname?.includes('settings');
  const isActiveQuotes =
    pathname?.includes('accounts') &&
    pathname?.includes('proposals') &&
    pathname?.includes('quotes');

  const activeTab = searchParams.get('activeTab');

  useEffect(() => {
    if (accountId) {
      dispatch(getBrokerAccountData({ paramsId: accountId }));
    }
  }, [dispatch, accountId]);

  useEffect(() => {
    if (accountId && proposalId) {
      dispatch(getIssuerAccountMembers({ accountId, proposalId, navigate }));

      const payload = {
        'f[proposal_id]': proposalId,
        'f[room_type]': 'carrier',
        'f[user_id]': issuerData.id,
      };
      dispatch(getChatRooms(payload));
    }
  }, [dispatch, navigate, accountId, proposalId, issuerData]);

  useEffect(() => {
    if (accountId && proposalId && chatRooms?.length) {
      const period = setInterval(() => {
        let payload = {
          'f[proposal_id]': proposalId,
          'f[room_type]': 'carrier',
          'f[user_id]': issuerData.id,
        };

        if (accountId && proposalId) {
          dispatch(getChatRooms(payload));
        }
      }, 10000);

      return () => {
        clearInterval(period);
      };
    }
  }, [dispatch, accountId, proposalId, chatRooms, issuerData]);

  useEffect(() => {
    if (accountId && proposalId) {
      dispatch(getIssuerCoveragePlans({ accountId, proposalId }));
    }
  }, [dispatch, accountId, proposalId]);

  const updatedPathname = `${pathname}${search ? search : ''}`;

  const isVisibleProposalMenu = useCallback(() => {
    switch (updatedPathname) {
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=medical`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=dental`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=vision`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=life`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=disability`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=accident`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=hospital`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=critical_illness`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/chat`:
      case `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`:
        return true;
      default:
        return false;
    }
  }, [updatedPathname, accountData, proposalData]);

  const onClickQuotes = useCallback(() => {
    navigate(
      `/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes?activeTab=${coveragePlans?.active_tab}`,
    );
  }, [accountData?.id, coveragePlans?.active_tab, navigate, proposalData?.id]);

  const onClickSettings = useCallback(() => {
    navigate(`${ROUTE.ISSUER_SETTINGS}?activeTab=profile`);
  }, [navigate]);

  const onClickStartChat = useCallback(() => {
    const payload = {
      owner_id: issuerData.id,
      owner_type: 'rep',
      room_type: 'carrier',
      proposal_id: proposalId,
      name: proposalData.name,
    };

    const accountContacts = accountData.contacts.filter((item) => item.contact_type === 'broker');

    dispatch(
      startChat({
        accountId,
        contacts: [{ contact_type: 'rep', id: issuerData.id }, ...accountContacts],
        payload,
        navigate,
      }),
    );
  }, [dispatch, navigate, proposalId, accountData, accountId, issuerData, proposalData]);

  return (
    <div className={classes.RepMenu}>
      <MenuItem title="My RFPs" routeTo={ROUTE.REP_MY_RFPS} isActive={isMyRfps} />
      <MenuItem title="Browse RFPs" subTitle="Free Beta" routeTo={ROUTE.REP_BROWSE_BROKERS} />
      <MenuItem title="Settings" onClick={onClickSettings} isLink={false} />
      {isSettings && (
        <MenuSubItem
          title="Profile"
          routeTo="?activeTab=profile"
          isActive={activeTab === 'profile'}
        />
      )}
      {isVisibleProposalMenu() && (
        <>
          <hr className={classes.RepMenuLine} />
          <MenuItem
            title="Account Info"
            routeTo={`/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/account-info`}
          />
          <MenuItem
            title="Census"
            routeTo={`/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/census`}
          />
          <MenuItem
            title="Quotes"
            isDisabled={isLoadingCoveragePlans}
            isLoading={isLoadingCoveragePlans}
            onClick={onClickQuotes}
            isLink={false}
          />
          {isActiveQuotes && (
            <>
              {Object.keys(coveragePlans)?.map(
                (item) =>
                  item !== 'active_tab' && (
                    <MenuSubItem
                      title={item?.replace('_', ' ')}
                      routeTo={`?activeTab=${item}`}
                      isActive={activeTab === item}
                      key={item}
                    />
                  ),
              )}
            </>
          )}
          <MenuItem
            title="Final Proposal"
            routeTo={`/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/finalize`}
          />
          {chatRooms?.length ? (
            <MenuItem
              title="Chat"
              subTitle="New"
              routeTo={`/rep/my-rfps/accounts/${accountData?.id}/proposals/${proposalData?.id}/chat`}
            />
          ) : (
            <MenuItem
              title="Start Chat"
              subTitle="New"
              onClick={onClickStartChat}
              isLoading={isLoadingStartChat}
              isLink={false}
            />
          )}
        </>
      )}
    </div>
  );
};
