import { createSlice } from '@reduxjs/toolkit';

import {
  getAccountContacts,
  createAccountContact,
  updateAccountContact,
  deleteAccountContact,
} from './accountContacts.actions';

const initialState = {
  accountContacts: [],
  isLoadingAccountContacts: false,

  isLoadingCreateContact: false,
  isLoadingUpdateContact: false,
  isLoadingDeleteContact: false,
};

const accountContactsSlice = createSlice({
  name: 'accountContacts',
  initialState,
  reducers: {
    clearAccountContactsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createAccountContact.pending, (state) => {
      state.isLoadingCreateContact = true;
    });
    builder.addCase(createAccountContact.fulfilled, (state, action) => {
      state.isLoadingCreateContact = false;
    });
    builder.addCase(createAccountContact.rejected, (state) => {
      state.isLoadingCreateContact = false;
    });

    builder.addCase(getAccountContacts.pending, (state) => {
      state.isLoadingAccountContacts = true;
    });
    builder.addCase(getAccountContacts.fulfilled, (state, action) => {
      state.accountContacts = action.payload;
      state.isLoadingAccountContacts = false;
    });
    builder.addCase(getAccountContacts.rejected, (state) => {
      state.isLoadingAccountContacts = false;
    });

    builder.addCase(updateAccountContact.pending, (state) => {
      state.isLoadingUpdateContact = true;
    });
    builder.addCase(updateAccountContact.fulfilled, (state) => {
      state.isLoadingUpdateContact = false;
    });
    builder.addCase(updateAccountContact.rejected, (state) => {
      state.isLoadingUpdateContact = false;
    });

    builder.addCase(deleteAccountContact.pending, (state) => {
      state.isLoadingDeleteContact = true;
    });
    builder.addCase(deleteAccountContact.fulfilled, (state) => {
      state.isLoadingDeleteContact = false;
    });
    builder.addCase(deleteAccountContact.rejected, (state) => {
      state.isLoadingDeleteContact = false;
    });
  },
});

export const { clearAccountContactsState } = accountContactsSlice.actions;

export default accountContactsSlice.reducer;
