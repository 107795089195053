import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Loader } from 'src/components/Loader/Loader';

import classes from './menuItem.module.scss';

export const MenuItem = (props) => {
  const {
    title,
    subTitle,
    routeTo,
    onClick,
    isLink = true,
    isActive,
    isDisabled,
    isLoading,
    isVisibleStatus,
    isWarning,
  } = props;

  const { pathname, search } = useLocation();

  const updatedPathname = pathname + search;

  const renderMenuItem = useCallback(() => {
    return (
      <>
        <div className={classes.MenuItemTitle}>
          {title} {isVisibleStatus && <div className={classes.MenuItemStatus} />}
          {isWarning && <i className={`${classes.MenuItemWarning} fas fa-exclamation-triangle`} />}
          {subTitle && <div className={classes.MenuItemSubTitle}>{subTitle}</div>}
          {isLoading && (
            <div className={classes.MenuItemLoader}>
              <Loader size="small" />
            </div>
          )}
        </div>
        <div
          className={
            updatedPathname === routeTo || isActive
              ? classes.MenuItemSelected
              : classes.MenuItemSelectedHidden
          }
        />
      </>
    );
  }, [isActive, isLoading, isVisibleStatus, isWarning, routeTo, subTitle, title, updatedPathname]);

  const linkStyles = classNames({
    [classes.MenuItemActive]: updatedPathname === routeTo || isActive,
    [classes.MenuItem]: updatedPathname !== routeTo || !isActive,
    [classes.MenuItemDisabled]: (updatedPathname !== routeTo || !isActive) && isDisabled,
  });

  return isLink ? (
    <Link className={linkStyles} to={!isDisabled ? routeTo : '#'}>
      {renderMenuItem()}
    </Link>
  ) : (
    <div className={linkStyles} onClick={!isDisabled ? onClick : () => {}}>
      {renderMenuItem()}
    </div>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  routeTo: PropTypes.string,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isVisibleStatus: PropTypes.bool,
  isWarning: PropTypes.bool,
};
