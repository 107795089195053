import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';
import { HOSPITAL_FORM_NAMES } from 'src/constants/formNames';
import { normalizeRateTierType } from 'src/constants/requestProposal';
import {
  coverageAllAges,
  COVERAGE_ALL_AGES_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  coverageFiveYearAges,
  COVERAGE_FIVE_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  coverageTenYearAges,
  COVERAGE_TEN_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

export const normalizeHospitalMemberExport = (worksheet, hospitalQuotes, member, groupMembers) => {
  worksheet.addRow(['Hospital']).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'e6b8b7',
    },
  };

  for (const quote in hospitalQuotes) {
    const isAllAgesRate = !_.isEmpty(
      hospitalQuotes[quote].find((item) => item?.rate_tier_type === 'all_ages'),
    );

    let carrierRow = ['Carrier'];
    let hospitalAdmission = [HOSPITAL_FORM_NAMES.initial_hospital_confinement];
    let icuConfinement = [HOSPITAL_FORM_NAMES.per_day_hospitalization];
    let currentlyOfferedRow = ['Currently Offered Benefit'];

    let quoteRows = [carrierRow, hospitalAdmission, icuConfinement, currentlyOfferedRow, []];

    let rateTierTypeRow = ['Rate Tier Type'];

    for (const hospitalQuote of hospitalQuotes[quote]) {
      carrierRow.push(hospitalQuote.issuer_name);
      hospitalAdmission.push(currencyFormatter(hospitalQuote.initial_hospital_confinement));
      icuConfinement.push(currencyFormatter(hospitalQuote.per_day_hospitalization));
      currentlyOfferedRow.push(hospitalQuote.is_current_rate ? 'Yes' : 'No');
      rateTierTypeRow.push(normalizeRateTierType(hospitalQuote.rate_tier_type));
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        worksheet.addRow(row).font = { bold: true };
      } else {
        worksheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    let rateRows = [];

    for (let i = 1; i <= 81; i++) {
      rateRows.push(['']);
    }

    for (const hospitalQuote of hospitalQuotes[quote]) {
      let ageBandedRates = {};

      if (
        hospitalQuote.rate_tier_type === 'all_ages' ||
        hospitalQuote.rate_tier_type === 'banded_5' ||
        hospitalQuote.rate_tier_type === 'banded_10'
      ) {
        for (const item of Object.keys(hospitalQuote)) {
          if (item.includes('age')) {
            ageBandedRates = {
              ...ageBandedRates,
              [item]: hospitalQuote[item],
            };
          }
        }
      }

      let allAgesRatesPayload = coverageAllAges(ageBandedRates);
      let fiveYearsRatesPayload = coverageFiveYearAges(ageBandedRates);
      let tenYearRatesPayload = coverageTenYearAges(ageBandedRates);

      if (hospitalQuote.rate_tier_type === '3tier' || hospitalQuote.rate_tier_type === '4tier') {
        rateRows[0].push(`${currencyFormatter(hospitalQuote.rate_ee) || '--'} (EE)`);
        if (hospitalQuote.rate_tier_type === '3tier') {
          rateRows[1].push(`${currencyFormatter(hospitalQuote.rate_ee_1) || '--'} (EE + 1)`);
          rateRows[2].push(`${currencyFormatter(hospitalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            // 66 - because range for all ages is from 0 to 14
            for (let i = 3; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          }
        }
        if (hospitalQuote.rate_tier_type === '4tier') {
          rateRows[1].push(`${currencyFormatter(hospitalQuote.rate_ee_sp) || '--'} (EE + SP)`);
          rateRows[2].push(`${currencyFormatter(hospitalQuote.rate_ee_ch) || '--'} (EE + CH)`);
          rateRows[3].push(`${currencyFormatter(hospitalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            for (let i = 4; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }

      if (hospitalQuote?.rate_tier_type === 'all_ages') {
        rateRows[0].push(
          `${currencyFormatter(allAgesRatesPayload.age_0_14) || '--'} (${
            COVERAGE_ALL_AGES_NAMES.age_0_14
          })`,
        );
        for (let i = 1; i < 66; i++) {
          rateRows[i].push(
            `${currencyFormatter(allAgesRatesPayload[`age_${i + 14}`]) || '--'} (${
              COVERAGE_ALL_AGES_NAMES[`age_${i + 14}`]
            })`,
          );
        }
      }

      if (hospitalQuote?.rate_tier_type === 'banded_5') {
        rateRows[0].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_0_17) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_18_24) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_18_24
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_25_29) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_25_29
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_30_34) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_30_34
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_35_39) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_35_39
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_40_44) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_40_44
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_45_49) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_45_49
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_50_54) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_50_54
          })`,
        );
        rateRows[8].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_55_59) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_55_59
          })`,
        );
        rateRows[9].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_60_64) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_60_64
          })`,
        );
        rateRows[10].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_65_69) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_65_69
          })`,
        );
        rateRows[11].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_70_74) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_70_74
          })`,
        );
        rateRows[12].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_75_79) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_75_79
          })`,
        );
        rateRows[13].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_80) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }

      if (hospitalQuote?.rate_tier_type === 'banded_10') {
        rateRows[0].push(
          `${currencyFormatter(tenYearRatesPayload.age_0_17) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(tenYearRatesPayload.age_18_29) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_18_29
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(tenYearRatesPayload.age_30_39) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_30_39
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(tenYearRatesPayload.age_40_49) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_40_49
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(tenYearRatesPayload.age_50_59) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_50_59
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(tenYearRatesPayload.age_60_69) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_60_69
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(tenYearRatesPayload.age_70_79) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_70_79
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(tenYearRatesPayload.age_80) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 7; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.length !== 1), []]) {
      worksheet.addRow(row).getCell(1).font = { bold: true };
    }

    const filteredMembers = groupMembers?.filter((item) => item.family_id === member.family_id);

    const updatedQuotes = [];

    for (const item of hospitalQuotes[quote]) {
      let payload = { ...item };

      let total = 0;

      for (const groupMember of filteredMembers) {
        for (const item of hospitalQuotes[quote]) {
          if (!item.rate_tier_type.includes('tier')) {
            total += item.calculation[groupMember.id];
          }

          if (item.rate_tier_type.includes('tier')) {
            if (groupMember.relationship === 'employee') {
              total += item.calculation[member.family_id].rate;
            }
          }
        }
      }

      payload.calculation = {
        ...item.calculation,
        total,
      };

      updatedQuotes.push(payload);
    }

    const addSection = (sectionTitle, sectionValue) => {
      let sectionRow = [sectionTitle];

      for (const item of updatedQuotes) {
        sectionRow.push(currencyFormatter(item.calculation[sectionValue]));
      }

      const sectionRowStyle = worksheet.addRow(sectionRow);
      sectionRowStyle.eachCell(
        (cell) =>
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'ffff00',
            },
          }),
      );
      sectionRowStyle.font = { bold: true };

      const rates = [];

      for (const groupMember of filteredMembers) {
        let updatedMember = [`${groupMember.first_name} ${groupMember.last_name}`];

        for (const item of updatedQuotes) {
          if (!item.rate_tier_type.includes('tier')) {
            updatedMember.push(currencyFormatter(item.calculation[groupMember.id]));
          }

          if (item.rate_tier_type.includes('tier')) {
            if (groupMember.relationship === 'employee') {
              updatedMember.push(currencyFormatter(item.calculation[member.family_id].rate));
            } else {
              updatedMember.push('--');
            }
          }
        }

        rates.push(updatedMember);
      }

      for (const rate of rates) {
        worksheet.addRow(rate).getCell(1).font = { bold: true };
      }
    };

    addSection('Total', 'total');
    for (let rowIndex = 2; rowIndex <= worksheet.rowCount; rowIndex++) {
      worksheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }

    worksheet.addRow([]);
  }
};
