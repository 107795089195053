export const normalizeAccountData = (accountData) => {
  return {
    logo_url: accountData.logo_url ?? '',
    company_name: accountData.company_name ?? '',
    renewal_date: accountData.renewal_date ?? '',
    contact_name: accountData.contact_name ?? '',
    contact_email: accountData.contact_email ?? '',
    contact_phone: accountData.contact_phone ?? '',
    sic_code: accountData.sic_code ?? '',
    address_street: accountData.address_street ?? '',
    tax_id: accountData.tax_id ?? '',
    address_city: accountData.address_city ?? '',
    address_state: accountData.address_state ?? '',
    address_zip_code: accountData.address_zip_code ?? '',
    fips_code: accountData.fips_code ?? '',
  };
};

export const accountFormValues = {
  logo_url: '',
  company_name: '',
  renewal_date: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  sic_code: '',
  address_street: '',
  tax_id: '',
  address_city: '',
  address_state: '',
  address_zip_code: '',
  fips_code: '',
};
