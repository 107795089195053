import ApiClient from './apiClient';
import App from './app';
import Broker from './broker';
import Proposal from './proposal';
import PlanConfiguration from './planConfiguration';
import Issuer from './issuer';
import Agency from './agency';
import Contats from './contacts';
import AccidentConfiguration from './accidentConfiguration';
import HospitalConfiguration from './hospitalConfiguration';
import CritialConfiguration from './criticalConfiguration';
import BrokerLicenses from './brokerLicenses';
import BrowseBrokers from './browseBrokers';
import AccountContacts from './accountContacts';
import GroupMembers from './groupMembers';
import Chat from './chat';
import Admin from './admin';
import Pet from './pet';

// NEW LOGIC

import CoverageBuilder from './coverageBuilder';
import IssuerCoverageBuilder from './issuerCoverageBuilder';

export default function apiFactory({ apiUrl } = {}) {
  if (!apiUrl) {
    throw new Error('[apiUrl] required');
  }

  const api = new ApiClient({ apiUrl });

  return {
    apiClient: api,
    app: new App({ apiClient: api }),
    broker: new Broker({ apiClient: api }),
    proposal: new Proposal({ apiClient: api }),
    planConfiguration: new PlanConfiguration({ apiClient: api }),
    accidentConfiguration: new AccidentConfiguration({ apiClient: api }),
    hospitalConfiguration: new HospitalConfiguration({ apiClient: api }),
    criticalConfiguration: new CritialConfiguration({ apiClient: api }),
    issuer: new Issuer({ apiClient: api }),
    agency: new Agency({ apiClient: api }),
    contacts: new Contats({ apiClient: api }),
    brokerLicenses: new BrokerLicenses({ apiClient: api }),
    browseBrokers: new BrowseBrokers({ apiClient: api }),
    accountContacts: new AccountContacts({ apiClient: api }),
    groupMembers: new GroupMembers({ apiClient: api }),
    coverageBuilder: new CoverageBuilder({ apiClient: api }),
    issuerCoverageBuilder: new IssuerCoverageBuilder({ apiClient: api }),
    chat: new Chat({ apiClient: api }),
    admin: new Admin({ apiClient: api }),
    pet: new Pet({ apiClient: api }),
  };
}
