import moment from 'moment';
import { normalizeRateValue } from './lifeRates';

const percent = (value) => {
  return value / 100;
};

const calculateEmployeeRate = (householdFamilies, selectedOffer) => {
  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    const yearWeeks = 52;
    const yearMonths = 12;

    let householdPayload = [];
    for (const member of household) {
      if (member?.relationship === 'employee') {
        let memberPayload = { ...member, type: 'Employee' };

        if (selectedOffer?.plan_type === 'std') {
          if (selectedOffer?.rate_basis === 'earnings') {
            const weeklyMax = normalizeRateValue(selectedOffer?.std_weekly_max);
            let weeklyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearWeeks;

            if (weeklyBenefit > weeklyMax) {
              weeklyBenefit = weeklyMax;
            }

            const monthlyCost =
              (((member?.salary_amount / yearWeeks / selectedOffer?.rate_per_unit) *
                selectedOffer?.rate_ee ?? 0) *
                yearWeeks) /
              yearMonths;

            memberPayload.rate = normalizeRateValue(monthlyCost, 2);
          }

          if (selectedOffer?.rate_basis === 'benefit') {
            const weeklyMax = normalizeRateValue(selectedOffer?.std_weekly_max);
            let weeklyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearWeeks;

            if (weeklyBenefit > weeklyMax) {
              weeklyBenefit = weeklyMax;
            }

            const montlyCost =
              (((weeklyBenefit / selectedOffer?.rate_per_unit) * selectedOffer?.rate_ee ?? 0) *
                yearWeeks) /
              yearMonths;

            memberPayload.rate = normalizeRateValue(montlyCost, 2);
          }
        }
        if (selectedOffer?.plan_type === 'ltd') {
          if (selectedOffer?.rate_basis === 'earnings') {
            const monthlyMax = selectedOffer?.ltd_monthly_max;

            let monthlyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearMonths;

            if (monthlyBenefit > monthlyMax) {
              monthlyBenefit = monthlyMax;
            }

            const monthlyCost =
              (member?.salary_amount / yearMonths / selectedOffer?.rate_per_unit) *
                selectedOffer?.rate_ee ?? 0;

            memberPayload.rate = normalizeRateValue(monthlyCost, 2);
          }

          if (selectedOffer?.rate_basis === 'benefit') {
            const monthlyMax = selectedOffer?.ltd_monthly_max;

            let monthlyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearMonths;

            if (monthlyBenefit > monthlyMax) {
              monthlyBenefit = monthlyMax;
            }

            const montlyCost =
              (monthlyBenefit / selectedOffer?.rate_per_unit) * selectedOffer?.rate_ee ?? 0;

            memberPayload.rate = normalizeRateValue(montlyCost, 2);
          }
        }
        householdPayload?.push(memberPayload);
      } else {
        householdPayload.push(member);
      }
    }
    payload = {
      ...payload,
      [family_id]: householdPayload,
    };
  }
  return payload;
};

const calculateEmployeeAgeRates = (householdFamilies, selectedOffer) => {
  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];
    const yearWeeks = 52;
    const yearMonths = 12;

    let householdPayload = [];
    for (const member of household) {
      let memberAge = moment().diff(moment(member.date_of_birth), 'years');

      const memberRate = selectedOffer[`age_${memberAge}`];

      if (member?.relationship === 'employee') {
        let memberPayload = { ...member, type: 'Employee' };

        if (selectedOffer?.plan_type === 'std') {
          if (selectedOffer?.rate_basis === 'earnings') {
            const weeklyMax = normalizeRateValue(selectedOffer?.std_weekly_max);
            let weeklyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearWeeks;

            if (weeklyBenefit > weeklyMax) {
              weeklyBenefit = weeklyMax;
            }

            const monthlyCost =
              (((member?.salary_amount / yearWeeks / selectedOffer?.rate_per_unit) * memberRate ??
                0) *
                yearWeeks) /
              yearMonths;

            memberPayload.rate = normalizeRateValue(monthlyCost, 2);
          }

          if (selectedOffer?.rate_basis === 'benefit') {
            const weeklyMax = normalizeRateValue(selectedOffer?.std_weekly_max);
            let weeklyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearWeeks;

            if (weeklyBenefit > weeklyMax) {
              weeklyBenefit = weeklyMax;
            }

            const montlyCost =
              (((weeklyBenefit / selectedOffer?.rate_per_unit) * memberRate ?? 0) * yearWeeks) /
              yearMonths;

            memberPayload.rate = normalizeRateValue(montlyCost, 2);
          }
        }
        if (selectedOffer?.plan_type === 'ltd') {
          if (selectedOffer?.rate_basis === 'earnings') {
            const monthlyMax = selectedOffer?.ltd_monthly_max;

            let monthlyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearMonths;

            if (monthlyBenefit > monthlyMax) {
              monthlyBenefit = monthlyMax;
            }

            const monthlyCost =
              (member?.salary_amount / yearMonths / selectedOffer?.rate_per_unit) * memberRate ?? 0;

            memberPayload.rate = normalizeRateValue(monthlyCost, 2);
          }

          if (selectedOffer?.rate_basis === 'benefit') {
            const monthlyMax = selectedOffer?.ltd_monthly_max;

            let monthlyBenefit =
              (member?.salary_amount * percent(selectedOffer?.income_pct)) / yearMonths;

            if (monthlyBenefit > monthlyMax) {
              monthlyBenefit = monthlyMax;
            }

            const montlyCost = (monthlyBenefit / selectedOffer?.rate_per_unit) * memberRate ?? 0;

            memberPayload.rate = normalizeRateValue(montlyCost, 2);
          }
        }
        householdPayload?.push(memberPayload);
      } else {
        householdPayload.push(member);
      }
    }
    payload = {
      ...payload,
      [family_id]: householdPayload,
    };
  }
  return payload;
};

export const calculateDisabilityRates = (accountMembers, selectedOffer) => {
  let householdTierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  householdTierTypes = calculateEmployeeRate(householdFamilies, selectedOffer);

  householdTierTypes.total = 0;
  for (const family_id in householdTierTypes) {
    if (family_id !== 'total') {
      for (const member of householdTierTypes[family_id]) {
        householdTierTypes.total += member?.rate || 0;
      }
    }
  }

  householdTierTypes.total = normalizeRateValue(householdTierTypes.total, 2);

  return householdTierTypes;
};

export const calculateDisabilityAgeRates = (accountMembers, selectedOffer) => {
  let householdTierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  householdTierTypes = calculateEmployeeAgeRates(householdFamilies, selectedOffer);

  householdTierTypes.total = 0;
  for (const family_id in householdTierTypes) {
    if (family_id !== 'total') {
      for (const member of householdTierTypes[family_id]) {
        householdTierTypes.total += member?.rate || 0;
      }
    }
  }

  householdTierTypes.total = normalizeRateValue(householdTierTypes.total, 2);

  return householdTierTypes;
};
