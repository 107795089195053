import React, { useCallback } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { selectedPetTableRows } from '../instantPetQuote/table';
import { currencyFormatter } from 'src/constants/currency';

export const PetPlansTable = (props) => {
  const { selectedPlans, mode = 'default' } = props;

  const renderTableRow = useCallback(
    (props) => {
      const { title, key, type = 'default' } = props;

      return (
        <TableRow key={key}>
          <TableCell sx={{ maxWidth: 80, fontWeight: 'bold' }}>{title}</TableCell>
          {selectedPlans.map((item) => (
            <TableCell sx={{ minWidth: 250 }} key={item.id}>
              {type === 'image' && (
                <img style={{ height: '40px' }} src={item[key]} alt="selected-pet-plan" />
              )}
              {(!type || type === 'default') && item[key]}
              {type === 'currency' && currencyFormatter(item[key])}
              {type === 'boolean' && `${item[key] ? 'Yes' : 'No'}`}
            </TableCell>
          ))}
        </TableRow>
      );
    },
    [selectedPlans],
  );

  return (
    <Paper sx={{ margin: '15px 0 15px 0' }} elevation={mode !== 'default' ? 0 : 1}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>{selectedPetTableRows.map((item) => renderTableRow(item))}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
