import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.coverageBuilder;

export const coverageSelector = createSelector(getState, (state) => state.coverage);

export const isLoadingCreatePlanSelector = createSelector(
  getState,
  (state) => state.isLoadingCreatePlan,
);
export const isLoadingDeletePlanSelector = createSelector(
  getState,
  (state) => state.isLoadingDeletePlan,
);

export const isLoadingCreateTemplateSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateTemplate,
);
