import React from 'react';

import { useCriticalIssuerBuild } from './useCriticalIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const CriticalPlans = () => {
  const { build } = useCriticalIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
