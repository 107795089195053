import { Page, Text, View } from '@react-pdf/renderer';

import { renderTableRow, renderTableValue } from './renderRow';

import { styles } from '../styles';

export const renderPetPage = (groupQuotes, fullname) => {
  return groupQuotes?.map((quote) => {
    const updatedQuotes = quote?.plans;

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    return updatedData?.map((section, index) => {
      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            {quote.name} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Coverage Waiting Period', section, 'coverage_waiting_period')}
            {renderTableRow('Dental Cleanings', section, 'dental_cleanings')}
            {renderTableRow('Diagnostic Testing', section, 'diagnostic_testing')}
            {renderTableRow('Eligible Pets', section, 'eligible_pets')}
            {renderTableRow('Employee Payment Metho', section, 'employee_payment_method')}
            {renderTableRow('Group Rate Discount', section, 'group_rate_discount')}
            {renderTableRow('Group Size Requirement', section, 'group_size_requirement')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Hereditary Conditions</Text>
              </View>
              {renderTableValue(
                section,
                index,
                section,
                section.hereditary_conditions ? 'Yes' : 'No',
              )}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Medical History Required</Text>
              </View>
              {renderTableValue(
                section,
                index,
                section,
                section.medical_history_required ? 'Yes' : 'No',
              )}
            </View>
            {renderTableRow('Medications', section, 'medications')}
            {renderTableRow('Office Visits', section, 'office_visits')}
            {renderTableRow('Old Age', section, 'old_age')}
            {renderTableRow('Participation Requirement', section, 'participation_requirement')}
            {renderTableRow('Portability', section, 'portability')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Pre Existing Conditions</Text>
              </View>
              {renderTableValue(
                section,
                index,
                section,
                section.pre_existing_conditions ? 'Yes' : 'No',
              )}
            </View>
            {renderTableRow('Puppy Kittens Vaccines', section, 'puppy_kittens_vaccines')}
            {renderTableRow('Radiology', section, 'radiology')}
            {renderTableRow('Spay Neuter', section, 'spay_neuter')}
            {renderTableRow('Surgeries Hospitalization', section, 'surgeries_hospitalization')}
            {renderTableRow('Vaccinations', section, 'vaccinations')}
            {renderTableRow('Value Addons', section, 'value_addons')}
            {renderTableRow('Veterinary Access', section, 'veterinary_access')}
            {renderTableRow('Virtual Vet Car', section, 'virtual_vet_car')}
            {renderTableRow('Well Care Visits', section, 'well_care_visits')}
            {renderTableRow('1x Pet', section, 'pet_1', null, '$')}
            {renderTableRow('2x Pet', section, 'pet_2', null, '$')}
            {renderTableRow('3x Pet', section, 'pet_3', null, '$')}
            {renderTableRow('4x Pet', section, 'pet_4', null, '$')}
            {renderTableRow('5x Pet', section, 'pet_5', null, '$')}
            {renderTableRow('Unlimited Pets', section, 'pet_n', null, '$')}
          </View>
        </Page>
      );
    });
  });
};
