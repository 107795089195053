import Base from './base';

class AccidentConfiguration extends Base {
  getAccidentPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/accident/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getAccidentQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/accident/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };
}

export default AccidentConfiguration;
