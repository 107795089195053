import { createSlice } from '@reduxjs/toolkit';
import { adminSignin, getAdminBrokers, getAdminReps } from './admin.actions';

const initialState = {
  brokerUsers: [],
  brokerUsersMeta: {},

  repUsers: [],
  repUsersMeta: [],

  isLoadingAdminSignin: false,
  isLoadingBrokerUsers: false,
  isLoadingRepUsers: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setBrokerUsersMeta: (state, action) => {
      state.brokerUsersMeta = action.payload;
    },
    setRepUsersMeta: (state, action) => {
      state.repUsersMeta = action.payload;
    },

    clearAdminState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(adminSignin.pending, (state) => {
      state.isLoadingAdminSignin = true;
    });
    builder.addCase(adminSignin.fulfilled, (state) => {
      state.isLoadingAdminSignin = false;
    });
    builder.addCase(adminSignin.rejected, (state) => {
      state.isLoadingAdminSignin = false;
    });

    builder.addCase(getAdminBrokers.pending, (state) => {
      state.isLoadingBrokerUsers = true;
    });
    builder.addCase(getAdminBrokers.fulfilled, (state, action) => {
      state.brokerUsers = action.payload;
      state.isLoadingBrokerUsers = false;
    });
    builder.addCase(getAdminBrokers.rejected, (state) => {
      state.isLoadingBrokerUsers = false;
    });

    builder.addCase(getAdminReps.pending, (state) => {
      state.isLoadingRepUsers = true;
    });
    builder.addCase(getAdminReps.fulfilled, (state, action) => {
      state.repUsers = action.payload;
      state.isLoadingRepUsers = false;
    });
    builder.addCase(getAdminReps.rejected, (state) => {
      state.isLoadingRepUsers = false;
    });
  },
});

export const { setBrokerUsersMeta, setRepUsersMeta, clearAdminState } = adminSlice.actions;

export default adminSlice.reducer;
