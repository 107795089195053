import { validateCensusRow } from './validateCensusRow';

export const validateCensus = (json, localCensus, setIsLoadingFile) => {
  let rows = {};

  for (const [i, value] of json.entries()) {
    const life = value;

    let errors = [];

    if (life?.current_medical_plan_name?.toString().toLowerCase()?.includes('waive')) {
      life.current_medical_plan_name = '';
    }
    if (life?.dental_plan_name?.toString().toLowerCase()?.includes('waive')) {
      life.dental_plan_name = '';
    }
    if (life?.vision_plan_name?.toString().toLowerCase()?.includes('waive')) {
      life.vision_plan_name = '';
    }

    if (!life?.first_name) {
      errors?.push(`Error: Row ${i + 1}: Missed 'first_name' value`);
    }
    if (!life?.last_name) {
      errors?.push(`Error: Row ${i + 1}: Missed 'last_name' value`);
    }

    if (!life?.gender) {
      errors?.push(`Error: Row ${i + 1}: Missed 'gender' value`);
    }

    if (life?.gender?.toString().toLowerCase() === 'male') {
      life.gender = 'M';
    }
    if (life?.gender?.toString().toLowerCase() === 'female') {
      life.gender = 'F';
    }

    if (!life.relationship) {
      errors?.push(`Error: Row ${i + 1}: Missed 'relationship' value`);
    }

    if (life.relationship?.toString().toLowerCase() === 'ee' || life.relationship === 'Employee') {
      life.relationship = 'employee';
    }
    if (life.relationship?.toString().toLowerCase() === 'self') {
      life.relationship = 'employee';
    }
    if (life.relationship?.toString().toLowerCase() === 'sp' || life.relationship === 'Spouse') {
      life.relationship = 'spouse';
    }

    if (
      life.relationship?.toString().toLowerCase() === 'domestic_partner' ||
      life.relationship?.toString().toLowerCase() === 'domestic partner'
    ) {
      life.relationship = 'life_partner';
    }

    if (life.relationship?.toString().toLowerCase() === 'ch' || life.relationship === 'Child') {
      life.relationship = 'child';
    }

    if (life?.salary_amount) {
      life.salary_amount = !isNaN(parseInt(life?.salary_amount, 10))
        ? parseInt(life?.salary_amount, 10)
        : life?.salary_amount;
    }

    if (life?.life_benefit_amount) {
      life.life_benefit_amount = !isNaN(parseInt(life?.life_benefit_amount, 10))
        ? parseInt(life?.life_benefit_amount, 10)
        : life?.life_benefit_amount;
    }

    try {
      validateCensusRow(life, errors);
    } catch (err) {
      setIsLoadingFile(false);
      errors?.push(`Error: Row ${i + 1}: ${err?.toString()}`);
    }

    if (!life.ee_id) {
      errors?.push(`Error: Row ${i + 1}: Missed 'ee_id' value`);
    }

    if (life?.ee_id?.toString()?.match(/[!@#\\$%\\^\\&*\\)\\[\]{}\\/\\|(+=.,:;?_'"`<>~-]/g)) {
      errors?.push(
        `Error: Row ${
          i + 1
        }: Enter valid 'ee_id' value.characters that should not exist: !?@#$%^&*()_+-{}[]\\|/:;"'<>,.`,
      );
    }

    if (!life.ee_id) {
      errors?.push(`Error: Row ${i + 1}: Missed 'ee_id' value`);
    }

    life.ee_id = life.ee_id?.toString();

    const zipcodeLengthRegex = /^([0-9]){5,5}$/;
    const vaildZipcodeRegex = /^(\d{5,5})+$/;

    if (!life?.zip_code?.toString()?.match(vaildZipcodeRegex)) {
      errors?.push(`Error: Row ${i + 1}: 'zip_code' value should be a number`);
    }

    if (!life?.zip_code?.toString()?.match(zipcodeLengthRegex)) {
      errors?.push(`Error: Row ${i + 1}: 'zip_code' value must be a 5 digits`);
    }

    if (!life.zip_code) {
      errors?.push(`Error: Row ${i + 1}: Missed 'zip_code' value`);
    }

    life.zip_code = life.zip_code?.toString();

    life.job_title = life.job_title ?? '';

    rows = { ...rows, [i + 1]: [...new Set(errors)] };

    localCensus.items.push(life);
  }

  for (const row in rows) {
    if (!rows[row]?.length) {
      delete rows[row];
    }
  }

  return rows || {};
};

export const validateCensusHousehold = (members) => {
  const householdFamilies = members?.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.ee_id] === 'undefined') {
      previousValue[currentValue.ee_id] = [];
    }

    previousValue[currentValue.ee_id].push(currentValue);

    return previousValue;
  }, {});

  let payload = {};

  for (const ee_id in householdFamilies) {
    let errors = [];
    const householdMemberZipCode = householdFamilies[ee_id]?.find((item) => item.zip_code);
    const householdZipCode = householdMemberZipCode?.zip_code;

    const filteredEmployee = householdFamilies[ee_id].filter((item) => item === 'employee');

    if (filteredEmployee.length > 1) {
      errors?.push(`There cannot be two employees in the same household: ee_id ${ee_id}`);
    }

    const invalidZipCode = householdFamilies[ee_id]?.find(
      (item) => item.zip_code !== householdZipCode,
    );

    if (invalidZipCode) {
      errors?.push(`All members with ee_id ${invalidZipCode?.ee_id} must have the same zip_code`);
    }

    // MEDICAL
    const householdMemberMedical = householdFamilies[ee_id]?.find(
      (item) => item.current_medical_plan_name,
    );
    const householdMedical = householdMemberMedical?.current_medical_plan_name;
    const invalidMedical = householdFamilies[ee_id]?.find(
      (item) => item.current_medical_plan_name !== householdMedical,
    );

    if (
      invalidMedical &&
      !invalidMedical?.current_medical_plan_name &&
      invalidMedical?.relationship !== 'employee'
    ) {
      continue;
    }

    if (invalidMedical && householdMemberMedical) {
      errors?.push(
        `All members with ee_id ${invalidMedical?.ee_id} must have the same current_medical_plan_name`,
      );
    }

    // DENTAL
    const Dental = householdFamilies[ee_id]?.find((item) => item.dental_plan_name);
    const householdDental = Dental?.dental_plan_name;
    const invalidDental = householdFamilies[ee_id]?.find(
      (item) => item.dental_plan_name !== householdDental,
    );

    if (
      invalidDental &&
      !invalidDental?.dental_plan_name &&
      invalidDental?.relationship !== 'employee'
    ) {
      continue;
    }

    if (invalidDental && Dental) {
      errors?.push(
        `All members with ee_id ${invalidDental?.ee_id} must have the same dental_plan_name`,
      );
    }

    // VISION
    const householdMemberVision = householdFamilies[ee_id]?.find((item) => item.vision_plan_name);
    const householdVision = householdMemberVision?.vision_plan_name;
    const invalidVision = householdFamilies[ee_id]?.find(
      (item) => item.vision_plan_name !== householdVision,
    );

    if (
      invalidVision &&
      !invalidVision?.vision_plan_name &&
      invalidVision?.relationship !== 'employee'
    ) {
      continue;
    }

    if (invalidVision && householdMemberVision) {
      errors?.push(
        `All members with ee_id ${invalidVision?.ee_id} must have the same vision_plan_name`,
      );
    }

    payload = {
      ...payload,
      [ee_id]: errors,
    };
  }

  for (const ee_id in payload) {
    if (!payload[ee_id]?.length) {
      delete payload[ee_id];
    }
  }

  return payload || {};
};
