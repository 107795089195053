export const MONARK_API = process.env.REACT_APP_MONARK_API;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSENGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_YEARLY_KEY = process.env.REACT_APP_STRIPE_YEARLY_KEY;
export const STRIPE_MONTHLY_KEY = process.env.REACT_APP_STRIPE_MONTHLY_KEY;
export const MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
