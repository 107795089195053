import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearDentalConfigurationState } from './dentalConfiguration.slice';

export const getDentalPlans = createAsyncThunk(
  'dental-configuration/get-dental-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDentalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getDentalQuotePlans = createAsyncThunk(
  'dental-configuration/get-dental-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDentalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearDentalState = createAsyncThunk('dental/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearDentalConfigurationState());
});
