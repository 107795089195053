import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserTypeSelector } from 'src/store/app';

import { Button } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import { HomeMain } from 'src/assets/pages';
import classes from './main.module.scss';

export const Main = () => {
  const navigate = useNavigate();

  const userType = useSelector(getUserTypeSelector);

  const renderButton = useCallback(() => {
    let title = '';

    const onClickGetStrted = () => navigate(ROUTE.SIGNUP);
    const onClickDashboard = () => navigate(userType === 'broker' ? ROUTE.BROKER : '/rep/my-rfps');

    if (!userType) {
      title = 'Get Started';
    }

    if (userType) {
      title = 'Go to Dashboard';
    }

    return (
      <Button
        onClick={!userType ? onClickGetStrted : onClickDashboard}
        title={title}
        type="primaryPurple"
        size="l"
      />
    );
  }, [navigate, userType]);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />

      <div className={classes.Main}>
        <div className={classes.MainTitle}>
          Quote any employee benefits plan. <br />
          From any carrier.
        </div>
        <div className={classes.MainSubTitle}>
          Monark HQ is the first employee benefits insurance quoting system allowing brokers to
          request custom quotes from every insurance carrier in the USA. Quote across 8 lines of
          coverage and define all elements of the plans exactly the way you want.
        </div>

        <div className={classes.MainContent}>
          <div className={classes.MainButton}>{renderButton()}</div>
          <img src={HomeMain} alt="HomeMain" className={classes.MainContentImage} />
        </div>
      </div>
    </div>
  );
};
