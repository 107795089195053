import React, { useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { auth } from 'src/app/database';
import { adminSignin, isLoadingAdminSigninSelector } from 'src/store/admin';
import { setUserClaims } from 'src/store/app';

import { Input, Button } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './adminLogin.module.scss';

export const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(isLoadingAdminSigninSelector);

  const [customClaims, setCustomClaims] = useState();

  const [firebaseUser] = useAuthState(auth, {
    onUserChanged: async (user) => {
      const response = await user.getIdTokenResult();

      return setCustomClaims(response?.claims);
    },
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (firebaseUser) {
      if (customClaims?.is_superadmin) {
        dispatch(setUserClaims(customClaims));
        return navigate(ROUTE.ADMIN_DASHBOARD);
      }

      if (!customClaims?.is_superadmin) {
        if (customClaims?.user_type === 'broker') {
          return navigate(ROUTE.BROKER);
        }

        if (customClaims?.user_type === 'rep') {
          return navigate(ROUTE.REP_MY_RFPS);
        }
      }
    }
  }, [dispatch, navigate, customClaims, firebaseUser]);

  const onClickLogo = useCallback(() => {
    navigate(ROUTE.DEFAULT);
  }, [navigate]);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onSubmitAdminLogin = useCallback(
    async (event) => {
      event.preventDefault();

      const payload = {
        email,
        password,
        navigation: navigate,
      };

      dispatch(adminSignin(payload));
    },
    [dispatch, navigate, email, password],
  );

  return (
    <div className={classes.AdminLogin}>
      <div className={classes.AdminLoginCard}>
        <div className={classes.AdminLoginCardHeader}>
          <div className={classes.AdminLoginCardHeaderTitle}>Admin</div>
        </div>
        <div className={classes.AdminLoginLogo}>
          <img src={MonarkLogo} alt="loginMonarkLogo" onClick={onClickLogo} />
        </div>
        <form onSubmit={onSubmitAdminLogin}>
          <Input
            label="Email"
            value={email}
            type="email"
            onChange={onChangeEmail}
            autoFocus
            isRequired
          />
          <Input
            label="Password"
            value={password}
            type="password"
            onChange={onChangePassword}
            isRequired
          />
          <Button
            type="primary"
            title="Sign in"
            buttonType="submit"
            icon="fas fa-lock"
            iconColor="#fff"
            isLoading={isLoading}
          />
        </form>
      </div>
      <div className={classes.AdminLoginCopyright}>
        <i className="far fa-copyright" />
        {moment().year()} Monark
      </div>
    </div>
  );
};
