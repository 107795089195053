import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import {
  AccidentIcon,
  CriticalIcon,
  DentalIcon,
  DisabilityIcon,
  HeartIcon,
  HospitalIcon,
  LifeIcon,
  VisionIcon,
} from 'src/assets/pages';
import './swiper.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import classes from './plans.module.scss';

const PlansItem = (props) => {
  const { icon, title } = props;

  return (
    <div className={classes.PlansItem}>
      <div className={classes.PlansItemIconWrapper}>
        <img className={classes.PlansItemIcon} src={icon} alt={icon} />
      </div>
      <span className={classes.PlansItemTitle}>{title}</span>
    </div>
  );
};

export const Plans = () => {
  return (
    <div className={classes.Plans}>
      <div className={classes.PlansHeader}>PLANS</div>
      <div className={classes.PlansTitle}>
        Empowering Insurance Brokers With Group Plan Quoting That Works for All Clients
      </div>
      <div className={classes.PlansSubTitle}>
        Quoting all lines of employee benefits used to be a challenge. Now with Monark HQ you can
        easily save your favorite plan configurations and request quotes from all your favorite
        carriers while keeping your personal carrier contacts! With the widest range of custom
        plans, we ensure that every plan aligns seamlessly with your client's goals and their
        employees' needs.
        <br />
        Small Group, Large Group, Fully Insured, Level Funded, Self Funded, Voluntary, you name it,
        Monark can handle it!
      </div>
      <div className={classes.PlansWrapper}>
        <Swiper
          className={classes.PlansSwiper}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          spaceBetween={1}
          slidesPerView={1}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide className={classes.SwiperItem}>
            <PlansItem icon={HeartIcon} title="Medical" />
            <PlansItem icon={DentalIcon} title="Dental" />
            <PlansItem icon={VisionIcon} title="Vision" />
            <PlansItem icon={LifeIcon} title="Life" />
          </SwiperSlide>
          <SwiperSlide className={classes.SwiperItem}>
            <PlansItem icon={DisabilityIcon} title="Disability" />
            <PlansItem icon={AccidentIcon} title="Accident" />
            <PlansItem icon={HospitalIcon} title="Hospital" />
            <PlansItem icon={CriticalIcon} title="Critical Illness" />
          </SwiperSlide>
        </Swiper>

        <div className={classes.PlansItemWrapper}>
          <PlansItem icon={HeartIcon} title="Medical" />
          <PlansItem icon={DentalIcon} title="Dental" />
          <PlansItem icon={VisionIcon} title="Vision" />
          <PlansItem icon={LifeIcon} title="Life" />
        </div>

        <div className={classes.PlansItemWrapper}>
          <PlansItem icon={DisabilityIcon} title="Disability" />
          <PlansItem icon={AccidentIcon} title="Accident" />
          <PlansItem icon={HospitalIcon} title="Hospital" />
          <PlansItem icon={CriticalIcon} title="Critical Illness" />
        </div>
      </div>
    </div>
  );
};
