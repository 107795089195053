export const fundingOptions = [
  { title: 'Fully Insured', value: 'fully_insured' },
  { title: 'Level Funded', value: 'level_funded' },
];

export const frequencyOptions = [
  { title: '12/12/12', value: '12/12/12' },
  { title: '12/12/24', value: '12/12/24' },
  { title: '12/24/24', value: '12/24/24' },
];

export const examCopayOptions = [
  { title: '$5', value: 5 },
  { title: '$10', value: 10 },
  { title: '$15', value: 15 },
  { title: '$20', value: 20 },
];

export const materialsCopayOptions = [
  { title: '$10', value: 10 },
  { title: '$15', value: 15 },
  { title: '$20', value: 20 },
  { title: '$25', value: 25 },
];

export const frameAllowanceOptions = [
  { title: '$100', value: 100 },
  { title: '$120', value: 120 },
  { title: '$130', value: 130 },
  { title: '$150', value: 150 },
  { title: '$200', value: 200 },
];
