export const prepareStatusColor = (status) => {
  // CHIP colors - 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  switch (status) {
    case 'draft':
    case 'trialing':
    case 'inactive':
    case 'open':
    case 'pending':
    case 'sent':
      return 'default';
    case 'rejected':
    case 'expired':
    case 'declined':
      return 'error';
    case 'in-progress':
      return 'warning';
    case 'selected':
      return 'secondary';
    case 'approved':
    case 'done':
      return 'success';
    case 'active':
    case 'submitted':
    case 'completed':
      return 'primary';

    default:
      return 'default';
  }
};
