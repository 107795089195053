import React from 'react';

import { useHospitalIssuerBuild } from './useHospitalIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const HospitalPlans = () => {
  const { build } = useHospitalIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
