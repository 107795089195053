import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { employeeActiveTabSelector, setEmployeeActiveTab } from 'src/store/employee';
import { getAccountProposalSelector } from 'src/store/proposals';
import { chatRoomsSelector, getChatRooms } from 'src/store/chat';

import { MenuItem } from '../MenuItem/MenuItem';
import { MenuSubItem } from '../MenuISubtem/MenuISubtem';

import classes from './employeeMenu.module.scss';
import { petInsuranceDataSelector } from 'src/store/pet';
import { coverageSelector } from 'src/store/coverageBuilder';

export const EmployeeMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const accountProposalData = useSelector(getAccountProposalSelector);
  const coverageData = useSelector(coverageSelector);
  const medicalQuotes = !_.isEmpty(accountProposalData)
    ? accountProposalData?.groups[0]?.quotes?.filter((item) => item.coverage_type !== 'pet')
    : [];

  const isMedicalQuotes = medicalQuotes?.length > 0 || coverageData['medical_quotes']?.length > 0;

  const petInsuranceData = useSelector(petInsuranceDataSelector);
  const chatRooms = useSelector(chatRoomsSelector);
  const activeTab = useSelector(employeeActiveTabSelector);

  const token = params.token;
  const code = searchParams.get('code');
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;

  useEffect(() => {
    if (accountId && proposalId) {
      const payload = {
        'f[proposal_id]': proposalId,
        'f[room_type]': 'employer',
      };
      dispatch(getChatRooms(payload));
    }
  }, [dispatch, navigate, accountId, proposalId]);

  useEffect(() => {
    if (accountId && proposalId && !_.isEmpty(accountProposalData)) {
      const period = setInterval(() => {
        const payload = {
          'f[proposal_id]': proposalId,
          'f[room_type]': 'employer',
        };

        if (accountId && proposalId) {
          dispatch(getChatRooms(payload));
        }
      }, 10000);

      return () => {
        clearInterval(period);
      };
    }
  }, [dispatch, accountId, proposalId, accountProposalData]);

  const onTabClick = useCallback(
    (tab) => () => {
      if (location.pathname.includes('chat')) {
        navigate(`/read-only/${token}?code=${code}`);
      }

      dispatch(setEmployeeActiveTab(tab));
    },
    [dispatch, navigate, location, token, code],
  );

  useEffect(() => {
    if (location.pathname.includes('chat')) {
      dispatch(setEmployeeActiveTab(''));
    }

    return () => {
      dispatch(setEmployeeActiveTab(''));
    };
  }, [dispatch, location]);

  return (
    <div className={classes.EmployeeMenu}>
      <MenuItem title="Quotes" onClick={onTabClick('medical')} isLink={false} />
      {isMedicalQuotes && (
        <MenuSubItem
          title="Medical"
          onClick={onTabClick('medical')}
          isActive={activeTab === 'medical'}
          isLink={false}
        />
      )}
      {coverageData['dental_quotes']?.length > 0 && (
        <MenuSubItem
          title="Dental"
          onClick={onTabClick('dental')}
          isActive={activeTab === 'dental'}
          isLink={false}
        />
      )}
      {coverageData['vision_quotes']?.length > 0 && (
        <MenuSubItem
          title="Vision"
          onClick={onTabClick('vision')}
          isActive={activeTab === 'vision'}
          isLink={false}
        />
      )}
      {coverageData['life_quotes']?.length > 0 && (
        <MenuSubItem
          title="Life"
          onClick={onTabClick('life')}
          isActive={activeTab === 'life'}
          isLink={false}
        />
      )}
      {coverageData['disability_quotes']?.length > 0 && (
        <MenuSubItem
          title="Disability"
          onClick={onTabClick('disability')}
          isActive={activeTab === 'disability'}
          isLink={false}
        />
      )}
      {coverageData['accident_quotes']?.length > 0 && (
        <MenuSubItem
          title="Accident"
          onClick={onTabClick('accident')}
          isActive={activeTab === 'accident'}
          isLink={false}
        />
      )}
      {coverageData['hospital_quotes']?.length > 0 && (
        <MenuSubItem
          title="Hospital"
          onClick={onTabClick('hospital')}
          isActive={activeTab === 'hospital'}
          isLink={false}
        />
      )}
      {coverageData['critical_illness_quotes']?.length > 0 && (
        <MenuSubItem
          title="Critical Illness"
          onClick={onTabClick('critical_illness')}
          isActive={activeTab === 'critical_illness'}
          isLink={false}
        />
      )}
      {petInsuranceData?.quotes?.length > 0 && (
        <MenuSubItem
          title="Pet"
          onClick={onTabClick('pet')}
          isActive={activeTab === 'pet'}
          isLink={false}
        />
      )}

      {chatRooms?.length ? (
        <MenuItem title="Chat" subTitle="New" routeTo={`/read-only/${token}/chat?code=${code}`} />
      ) : null}
    </div>
  );
};
