import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import { auth } from 'src/app/database';

import { ROUTE } from 'src/constants/routes';
import { REACT_APP_VERSION } from 'src/constants/env';

import { getUserClaimsSelector, logout } from 'src/store/app';
import { brokerAgenciesSelector, brokerAgencySelector, setBrokerAgency } from 'src/store/agency';
import { getBrokerData } from 'src/store/broker';
import { clearCoverageBuilderState } from 'src/store/coverageBuilder';
import { clearPlanConfigurationState } from 'src/store/planConfiguration';
import { clearPetState } from 'src/store/pet';

import { Profile } from './components/Profile/Profile';
import { AdminMenu } from './components/AdminMenu/AdminMenu';
import { BrokerMenu } from './components/BrokerMenu/BrokerMenu';
import { AgencyMenu } from './components/AgencyMenu/AgencyMenu';
import { RepMenu } from './components/RepMenu/RepMenu';
import { EmployeeMenu } from './components/EmployeeMenu/EmployeeMenu';

import MonarkLogo from 'src/assets/app/MonarkLogo.svg';
import classes from './layout.module.scss';

export const Layout = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const teamId = params?.teamId;
  const proposalId = params?.proposalId;

  const [cookie, , removeCookie] = useCookies(['is_impersonate']);

  const brokerData = useSelector(getBrokerData);
  const brokerAgencies = useSelector(brokerAgenciesSelector);
  const agencyData = useSelector(brokerAgencySelector);
  const agencyOwner = brokerAgencies?.find((item) => item?.owner_id === brokerData?.id);

  const customClaims = useSelector(getUserClaimsSelector);

  const [, loadingFirebaseUser] = useAuthState(auth);
  const isImpersonate = cookie?.is_impersonate;

  const isAdmin = customClaims?.is_superadmin && pathname?.includes('admin');
  const isBroker = customClaims?.user_type === 'broker' && pathname?.includes('broker');
  const isAgency =
    customClaims?.user_type === 'broker' &&
    pathname?.includes('teams') &&
    pathname !== '/teams/create';
  const isIssuer = customClaims?.user_type === 'rep';
  const isReadOnlyMode = pathname.includes('/read-only');

  const isChat = pathname.includes('/chat');

  useEffect(() => {
    if (!teamId) {
      dispatch(setBrokerAgency({}));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (!proposalId) {
      dispatch(clearCoverageBuilderState());
      dispatch(clearPlanConfigurationState());
      dispatch(clearPetState());
    }
  }, [dispatch, proposalId]);

  const onClickLogout = useCallback(() => {
    const payload = { navigate, route: ROUTE.LOGIN };

    dispatch(logout(payload));

    removeCookie('is_impersonate', { path: '/' });
  }, [dispatch, navigate, removeCookie]);

  const onClickUpgrade = useCallback(() => {
    if (agencyOwner) {
      return navigate(`/teams/${agencyOwner.id}/settings?activeTab=billing`);
    }

    navigate(`/teams/create`);
  }, [navigate, agencyOwner]);

  const contentStyles = classNames({
    [classes.LayoutContent]: true,
    [classes.LayoutRemovePadding]: isChat,
  });

  const isInactiveAgency = !_.isEmpty(agencyData)
    ? agencyData && agencyData.status === 'inactive'
    : !agencyOwner;

  return (
    <div className={classes.Layout}>
      {!loadingFirebaseUser && (
        <div className={classes.LayoutMenu}>
          {isImpersonate && <div className={classes.LayoutImpersonate}>IMPERSONATE</div>}

          <div className={classes.LayoutMenuLogo}>
            <img src={MonarkLogo} alt="MonarkLogo" />
            {!_.isEmpty(brokerData) && !isReadOnlyMode && (
              <>
                {isInactiveAgency && (
                  <div className={classes.LayoutMenuItem} onClick={onClickUpgrade}>
                    Upgrade to PRO
                  </div>
                )}
                {agencyData && agencyData.status === 'active' && (
                  <div className={classes.LayoutMenuItemPro}>PRO</div>
                )}
              </>
            )}
          </div>
          {!isAdmin && !isReadOnlyMode && <Profile />}
          <div className={classes.LayoutMenuWrapper}>
            {isAdmin && (
              <>
                <div className={classes.LayoutMenuAdmin}>Admin</div>
                <AdminMenu />
              </>
            )}
            {isBroker && <BrokerMenu />}
            {isAgency && <AgencyMenu />}
            {isIssuer && <RepMenu />}
            {isReadOnlyMode && <EmployeeMenu />}
          </div>
          {!isReadOnlyMode && (
            <div className={classes.LayoutLogout} onClick={onClickLogout}>
              Logout
            </div>
          )}
          <div className={classes.LayoutFooter}>
            MonarkHQ © {moment().year()} | v{REACT_APP_VERSION}
          </div>
        </div>
      )}
      <div className={contentStyles}>{children ?? <Outlet />}</div>
    </div>
  );
};
