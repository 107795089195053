export const fundingTypeOptions = [
  { title: 'Fully Insured', value: 'fully_insured' },
  { title: 'Level Funded', value: 'level_funded' },
];

export const planTypeOptions = [
  { title: 'PPO', value: 'PPO' },
  { title: 'POS', value: 'POS' },
  { title: 'EPO', value: 'EPO' },
  { title: 'HMO', value: 'HMO' },
];

export const networkSizeOptions = [
  { title: 'Full', value: 'Full' },
  { title: 'Mid', value: 'Mid' },
  { title: 'Low', value: 'Low' },
];

export const tierOptions = [
  { title: 'Platinum', value: 'Platinum' },
  { title: 'Gold', value: 'Gold' },
  { title: 'Silver', value: 'Silver' },
  { title: 'Bronze', value: 'Bronze' },
];

export const primaryCareOptions = [
  { title: '$0', value: '0' },
  { title: '$5', value: '5' },
  { title: '$10', value: '10' },
  { title: '$15', value: '15' },
  { title: '$20', value: '20' },
  { title: '$25', value: '25' },
  { title: '$30', value: '30' },
  { title: '$35', value: '35' },
  { title: '$40', value: '40' },
  { title: '$45', value: '45' },
  { title: '$50', value: '50' },
];

export const specialistCopayOptions = [
  { title: '$25', value: '25' },
  { title: '$30', value: '30' },
  { title: '$35', value: '35' },
  { title: '$40', value: '40' },
  { title: '$45', value: '45' },
  { title: '$50', value: '50' },
  { title: '$75', value: '75' },
];

export const coinsuranceOptions = [
  { title: '100%', value: '100' },
  { title: '90%', value: '90' },
  { title: '80%', value: '80' },
  { title: '70%', value: '70' },
  { title: '60%', value: '60' },
  { title: '50%', value: '50' },
  { title: '0%', value: '0' },
];

export const inpatientFacilityOptions = [
  { title: '% per day', value: true },
  { title: '$ per day', value: false },
];

export const percentInpatientFacilityOptions = [
  { title: '0%', value: '0' },
  { title: '10%', value: '10' },
  { title: '20%', value: '20' },
  { title: '30%', value: '30' },
  { title: '40%', value: '40' },
  { title: '45%', value: '45' },
  { title: '50%', value: '50' },
  { title: '100%', value: '100' },
];

export const inpatientFacilityDaysOptions = [
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: '4', value: '4' },
  { title: '5', value: '5' },
  { title: '6', value: '6' },
];

export const inpatientFacilityCostOptions = [
  { title: '$0', value: '0' },
  { title: '$100', value: '100' },
  { title: '$150', value: '150' },
  { title: '$200', value: '200' },
  { title: '$250', value: '250' },
  { title: '$300', value: '300' },
  { title: '$400', value: '400' },
  { title: '$500', value: '500' },
  { title: '$600', value: '600' },
  { title: '$700', value: '700' },
  { title: '$750', value: '750' },
];

export const emergencyRoomOptions = [
  {
    title: '',
    value: 'dollar',
    type: 'numeric',
  },
  {
    title: '',
    value: 'percent',
    type: 'percentage',
  },
];

export const urgentCareOptions = [
  {
    title: '',
    value: 'dollar',
    type: 'numeric',
  },
  {
    title: '',
    value: 'percent',
    type: 'percentage',
  },
];
