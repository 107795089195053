import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import {
  createCoveragePlan,
  createCoverageTemplate,
  createMultipleCoveragePlans,
  isLoadingCreatePlanSelector,
  isLoadingCreateTemplateSelector,
  updateCoveragePlan,
} from 'src/store/coverageBuilder';
import { groupMembersSelector } from 'src/store/groupMembers';
import { brokerAgencySelector } from 'src/store/agency';

import {
  Button,
  DropZone,
  ExtraRows,
  Input,
  Loader,
  LoaderWrapper,
  Modal,
  RangeInput,
} from 'src/components';
import { PlanConfigAgeRates } from '../PlanConfigAgeRates/PlanConfigAgeRates';

import { DROP_ZONE_NAMES } from 'src/constants/dropZoneNames';
import { onUploadAgeRates, onUploadLifeAgeRates } from 'src/constants/proposalFormField';
import { COVERAGE_NAMES } from '../../cb_constants';

import classes from '../planConfig.module.scss';

export const PlanConfigForm = (props) => {
  const { build, mode, selectedRate, setSelectedRate, onClose } = props;

  const { coverage, form_content, form_options, coverage_plan_name } = build;
  const {
    // initial_state,
    form_data,
    contribution,
    contribution_title,
    plan_names,
    import_data,
    setFormData,
    planRates,
    setPlanRates,
  } = form_options;

  const dispatch = useDispatch();
  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const members = useSelector(groupMembersSelector);
  const brokerAgency = useSelector(brokerAgencySelector);
  const isLoadingCreatePlan = useSelector(isLoadingCreatePlanSelector);
  const isLoadingCreateTemplate = useSelector(isLoadingCreateTemplateSelector);

  const age_banded_coverages = [
    'medical',
    'life', // (tobacco/non-tobacco)
    'disability',
    'accident',
    'hospital',
    'critical_illness',
  ];

  const rate_tier_type = form_data.rate_tier_type;
  const is_current_rate = form_data.is_current_rate;

  const [templateName, setTemplateName] = useState('');
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (mode === 'EDIT') {
      setSelectedRate(form_data);
    }
  }, [mode, form_data, setSelectedRate]);

  const onOpenModal = useCallback(() => {
    setIsTemplateModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsTemplateModal(false);
  }, []);

  const onChangeTemplateName = useCallback((event) => {
    setTemplateName(event.target.value);
  }, []);

  const onChangeInput = useCallback(
    (event, target) => {
      setFormData((prev) => ({
        ...prev,
        [target]: event.target.value,
      }));
    },
    [setFormData],
  );

  const onChangeToggle = useCallback(
    (event, key) => {
      setSelectedRate({});

      if (plan_names) {
        setFormData((prev) => ({
          ...prev,
          selected_plan_name: '',
        }));
      }

      setFormData((prev) => ({
        ...prev,
        [key]: ['is_current_rate', 'is_uni_tobacco'].includes(key)
          ? JSON.parse(event.target.value)
          : event.target.value,
      }));
    },
    [plan_names, setFormData, setSelectedRate],
  );

  const onChangeRangeInput = useCallback(
    (value, target) => {
      setFormData((prev) => ({
        ...prev,
        [target]: value,
      }));
    },
    [setFormData],
  );

  const onChangeSelect = useCallback(
    (event, target) => {
      if (target === 'rate_tier_type') {
        if (event.target.value === '3tier') {
          setFormData((prev) => ({
            ...prev,
            rate_ee: null,
          }));
        }

        if (event.target.value === '4tier') {
          setFormData((prev) => ({
            ...prev,
            rate_ee_ch: null,
            rate_ee_sp: null,
          }));
        }
      }

      setFormData((prev) => ({
        ...prev,
        [target]: event.target.value,
      }));
    },
    [setFormData],
  );

  const renderRateInput = useCallback(
    (target) => {
      return (
        <TextField
          size="small"
          onChange={(event) => onChangeInput(event, target)}
          value={form_data[target]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
          required
        />
      );
    },
    [onChangeInput, form_data],
  );

  const onSubmitForm = useCallback(
    async (event) => {
      event.preventDefault();

      if (mode === 'CREATE') {
        if (!_.isEmpty(planRates) && !_.isEmpty(selectedRate)) {
          const updatedPlanRates = planRates.map((item) =>
            item.selected_plan_name === form_data.selected_plan_name
              ? { ...form_data, is_configured: true }
              : item,
          );

          setPlanRates(updatedPlanRates);

          setSelectedRate({});
        }

        if (_.isEmpty(selectedRate)) {
          await dispatch(
            createCoveragePlan({ accountId, proposalId, coverage, payload: form_data }),
          );

          onClose();
        }
      }

      if (mode === 'EDIT') {
        await dispatch(updateCoveragePlan({ accountId, proposalId, coverage, payload: form_data }));

        onClose();
      }
    },
    [
      mode,
      planRates,
      selectedRate,
      setPlanRates,
      setSelectedRate,
      form_data,
      dispatch,
      accountId,
      proposalId,
      coverage,
      onClose,
    ],
  );

  const onClickAddConfigurations = useCallback(async () => {
    await dispatch(
      createMultipleCoveragePlans({ accountId, proposalId, coverage, plans: planRates }),
    );

    onClose();
  }, [dispatch, onClose, accountId, coverage, planRates, proposalId]);

  const onSaveTemplate = useCallback(async () => {
    if (!templateName) {
      return toast('Template name cannot be empty', { type: 'warning' });
    }

    const payload = { ...form_data, id: uuid(), template_name: templateName };

    await dispatch(createCoverageTemplate({ accountId, proposalId, coverage, payload }));

    setIsTemplateModal(false);
    setTemplateName('');
  }, [dispatch, accountId, proposalId, templateName, form_data, coverage]);

  const onSelectRenewalRate = useCallback(
    (rate) => () => {
      setFormData(rate);
      setSelectedRate(rate);
    },
    [setFormData, setSelectedRate],
  );

  const onAddExtraRow = useCallback(() => {
    const payload = {
      id: uuid(),
      title: '',
      value: '',
    };

    const custom_fields = _.cloneDeep(form_data.custom_fields);

    setFormData((state) => ({ ...state, custom_fields: [...custom_fields, payload] }));
  }, [setFormData, form_data]);

  const normalizeButtonTitle = (item) => {
    if (selectedRate?.selected_plan_name === item.selected_plan_name) {
      return 'Configuring';
    }

    if (item.is_configured) {
      return 'Configured';
    } else {
      return 'Configure';
    }
  };

  const isVisibleSaveConfig = form_data.is_current_rate && selectedRate?.selected_plan_name;

  const isVisibleAddConfigs = planRates?.find((item) => item.is_configured);

  const extraRows = _.cloneDeep(form_data?.custom_fields);

  const onSetExtraRows = useCallback(
    (payload) => {
      setFormData((state) => ({ ...state, custom_fields: payload }));
    },
    [setFormData],
  );

  const onClickUpload = useCallback((event) => {
    return (event.target.value = '');
  }, []);

  const renderRateTierType = () => {
    return (
      <>
        <div className={classes.PlanConfigFormOptions}>
          <FormControl fullWidth>
            <InputLabel size="small" id="select-rtt">
              Rate Tier Type
            </InputLabel>

            <Select
              size="small"
              label="Rate Tier Type"
              labelId="select-rtt"
              onChange={(event) => onChangeSelect(event, 'rate_tier_type')}
              value={rate_tier_type}
              sx={{ width: '100%', maxWidth: 200, textAlign: 'left' }}
            >
              {!['life', 'disability'].includes(coverage) && (
                <MenuItem value="3tier">3 Tier</MenuItem>
              )}
              {coverage !== 'disability' && <MenuItem value="4tier">4 Tier</MenuItem>}
              {coverage === 'disability' && <MenuItem value="employee">Employee</MenuItem>}
              {age_banded_coverages.includes(coverage) && (
                <MenuItem value="all_ages">All Ages</MenuItem>
              )}
              {age_banded_coverages.includes(coverage) && (
                <MenuItem value="banded_5">5 Year Banded</MenuItem>
              )}
              {age_banded_coverages.includes(coverage) && (
                <MenuItem value="banded_10">10 Year Banded</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        {!['3tier', '4tier', 'employee']?.includes(rate_tier_type) && (
          <div className={classes.PlanConfigFormImport}>
            <ul>
              {import_data?.map((item) => (
                <li>
                  {item.title}{' '}
                  <a
                    className={classes.PlanConfigFormFile}
                    href={item.file}
                    download={item.file_name}
                  >
                    {COVERAGE_NAMES[item.key]}
                  </a>
                </li>
              ))}
            </ul>
            <div className={classes.UploadFileWrapper}>
              <label className={classes.UploadFile}>
                <Button type="primary" title="Upload" />
                <input
                  type="file"
                  accept=".csv, .xls, .xlsx"
                  onChange={(event) =>
                    coverage !== 'life'
                      ? onUploadAgeRates(event, setFormData)
                      : onUploadLifeAgeRates(event, setFormData, form_data.is_uni_tobacco)
                  }
                  onClick={onClickUpload}
                />
              </label>
            </div>
          </div>
        )}
        {form_data.is_current_rate && coverage === 'life' && (
          <div className={classes.PlanConfigContribution}>
            <ToggleButtonGroup
              color="primary"
              value={form_data.is_uni_tobacco}
              exclusive
              onChange={(event) => onChangeToggle(event, 'is_uni_tobacco')}
              aria-label="Platform"
              size="small"
              disabled={mode === 'EDIT'}
            >
              <ToggleButton value={true}>Uni-Tobacco</ToggleButton>
              <ToggleButton value={false}>Tobacco/Non-tobacco</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        {is_current_rate && (
          <div className={classes.PlanConfigFormTable}>
            {(rate_tier_type.includes('tier') || rate_tier_type.includes('employee')) && (
              <TableContainer sx={{ maxWidth: '500px' }}>
                <Table size="small">
                  <TableBody>
                    <>
                      {coverage === 'life' && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center">
                            {form_data.is_uni_tobacco ? 'Uni-Tobacco' : 'Tobacco'}
                          </TableCell>
                          {!form_data.is_uni_tobacco && (
                            <TableCell align="center">Non-tobacco</TableCell>
                          )}
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell>Employee</TableCell>
                        {coverage !== 'life' && (
                          <TableCell align="center">{renderRateInput('rate_ee')}</TableCell>
                        )}
                        {coverage === 'life' && (
                          <>
                            <TableCell align="center">
                              {form_data.is_uni_tobacco && renderRateInput('rate_ee_uni_tobacco')}
                              {!form_data.is_uni_tobacco && renderRateInput('rate_ee_tobacco')}
                            </TableCell>
                            {!form_data.is_uni_tobacco && (
                              <TableCell align="center">
                                {renderRateInput('rate_ee_non_tobacco')}
                              </TableCell>
                            )}
                          </>
                        )}
                      </TableRow>
                      {rate_tier_type === '3tier' && (
                        <TableRow>
                          <TableCell>Employee + 1</TableCell>
                          <TableCell align="center">{renderRateInput('rate_ee_1')}</TableCell>
                        </TableRow>
                      )}
                      {rate_tier_type === '4tier' && (
                        <>
                          <TableRow>
                            <TableCell>
                              {coverage !== 'life' ? 'Employee + Spouse' : 'Spouse'}
                            </TableCell>
                            {coverage !== 'life' && (
                              <TableCell align="center">{renderRateInput('rate_ee_sp')}</TableCell>
                            )}
                            {coverage === 'life' && (
                              <>
                                <TableCell align="center">
                                  {form_data.is_uni_tobacco &&
                                    renderRateInput('rate_sp_uni_tobacco')}
                                  {!form_data.is_uni_tobacco && renderRateInput('rate_sp_tobacco')}
                                </TableCell>
                                {!form_data.is_uni_tobacco && (
                                  <TableCell align="center">
                                    {renderRateInput('rate_sp_non_tobacco')}
                                  </TableCell>
                                )}
                              </>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {coverage !== 'life' ? 'Employee + Child(ren)' : 'Child(ren)'}
                            </TableCell>
                            {coverage !== 'life' && (
                              <TableCell align="center">{renderRateInput('rate_ee_ch')}</TableCell>
                            )}
                            {coverage === 'life' && (
                              <>
                                <TableCell align="center">
                                  {form_data.is_uni_tobacco &&
                                    renderRateInput('rate_ch_uni_tobacco')}
                                  {!form_data.is_uni_tobacco && renderRateInput('rate_ch_tobacco')}
                                </TableCell>
                                {!form_data.is_uni_tobacco && (
                                  <TableCell align="center">
                                    {renderRateInput('rate_ch_non_tobacco')}
                                  </TableCell>
                                )}
                              </>
                            )}
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        {!['life', 'disability'].includes(coverage) && (
                          <>
                            <TableCell>Family</TableCell>
                            <TableCell align="center">{renderRateInput('rate_fam')}</TableCell>
                          </>
                        )}
                        {coverage === 'life' && (
                          <>
                            <TableCell align="center">
                              {form_data.is_uni_tobacco && renderRateInput('rate_fam_uni_tobacco')}
                              {!form_data.is_uni_tobacco && renderRateInput('rate_fam_tobacco')}
                            </TableCell>
                            {!form_data.is_uni_tobacco && (
                              <TableCell align="center">
                                {renderRateInput('rate_fam_non_tobacco')}
                              </TableCell>
                            )}
                          </>
                        )}
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <PlanConfigAgeRates build={build} />

            <div className={classes.PlanConfigFormTableDropZone}>
              <DropZone
                label={DROP_ZONE_NAMES.BROKER}
                path={`/proposals/${proposalId}/uploads/broker/plan-configs/${form_data.id}/`}
                maxFiles={100}
                // isDisabled={isReadOnly}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isTemplateModal && (
        <Modal onClose={onCloseModal} closeButtonType="inside">
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>Save Template</div>
            <div>
              <Input
                onChange={onChangeTemplateName}
                value={templateName}
                placeholder="Please enter template name"
              />
            </div>
            <div className={classes.ModalFooter}>
              <Button
                title="Save"
                type="primary"
                onClick={onSaveTemplate}
                isLoading={isLoadingCreateTemplate}
              />
              <Button title="Cancel" type="secondary" onClick={onCloseModal} />
            </div>
          </div>
        </Modal>
      )}
      {isLoadingCreatePlan ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <form onSubmit={onSubmitForm}>
          {contribution && (
            <div className={classes.PlanConfigContributionWrapper}>
              <div className={classes.PlanConfigContribution}>
                <ToggleButtonGroup
                  color="primary"
                  value={form_data.contribution_type}
                  exclusive
                  onChange={(event) => onChangeToggle(event, 'contribution_type')}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value="percent">Percent</ToggleButton>
                  <ToggleButton value="dollar">Dollar</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.PlanConfigSliders}>
                <div className={classes.PlanConfigSlidersItem}>
                  {form_data.contribution_type === 'percent' && (
                    <RangeInput
                      id="Vision1"
                      label={COVERAGE_NAMES.contribution_ee}
                      value={form_data.contribution_ee}
                      setValue={(value) => onChangeRangeInput(value, 'contribution_ee')}
                      onChange={(event) =>
                        onChangeRangeInput(event.target.value, 'contribution_ee')
                      }
                    />
                  )}
                  {form_data.contribution_type === 'dollar' && (
                    <Input
                      label={COVERAGE_NAMES.contribution_ee}
                      type="number"
                      value={form_data?.contribution_ee}
                      setValue={(value) => onChangeRangeInput(value, 'contribution_ee')}
                      onChange={(event) =>
                        onChangeRangeInput(event.target.value, 'contribution_ee')
                      }
                    />
                  )}
                </div>

                <div className={classes.PlanConfigSlidersItem}>
                  {form_data.contribution_type === 'percent' && (
                    <RangeInput
                      id="Vision2"
                      label={COVERAGE_NAMES.contribution_dep}
                      value={form_data?.contribution_dep}
                      setValue={(value) => onChangeRangeInput(value, 'contribution_dep')}
                      onChange={(event) =>
                        onChangeRangeInput(event.target.value, 'contribution_dep')
                      }
                    />
                  )}
                  {form_data.contribution_type === 'dollar' && (
                    <Input
                      label={COVERAGE_NAMES.contribution_dep}
                      type="number"
                      value={form_data?.contribution_dep}
                      setValue={(value) => onChangeRangeInput(value, 'contribution_dep')}
                      onChange={(event) =>
                        onChangeRangeInput(event.target.value, 'contribution_dep')
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={classes.PlanConfigFormSection}>
            <div className={classes.PlanConfigFormSectionTitle}>
              {contribution_title ?? 'Currently Offered Benefit'}
            </div>
            {/* TODO MAKE IT DYNAMICALLY */}
            <ToggleButtonGroup
              color="primary"
              value={form_data.is_current_rate}
              exclusive
              onChange={(event) => onChangeToggle(event, 'is_current_rate')}
              aria-label="Platform"
              size="small"
              disabled={mode === 'EDIT'}
            >
              <ToggleButton value={false}>No</ToggleButton>
              <ToggleButton value={true}>Yes</ToggleButton>
            </ToggleButtonGroup>
          </div>

          {mode === 'EDIT' && form_data.is_current_rate && plan_names && (
            <div className={classes.PlanConfigFormSection}>
              <div className={classes.PlanConfigFormSectionTitle}>
                Selected Plan: {form_data.selected_plan_name}
              </div>
            </div>
          )}

          {mode === 'CREATE' &&
            form_data.is_current_rate &&
            plan_names &&
            planRates?.map((item) => (
              <div key={item.selected_plan_name} className={classes.RenewalRates}>
                <div className={classes.RenewalRatesName}>
                  <strong>{item.selected_plan_name}</strong>
                  <div className={classes.RenewalRatesTotal}>
                    (
                    {
                      members.filter(
                        (member) => member[coverage_plan_name] === item.selected_plan_name,
                      ).length
                    }
                    /{members.length})
                  </div>
                </div>

                <div className={classes.RenewalRatesButton}>
                  <Button
                    title={normalizeButtonTitle(item)}
                    buttonStyle={{
                      borderColor:
                        selectedRate?.selected_plan_name === item.selected_plan_name
                          ? '#209cee'
                          : '',
                    }}
                    type={item.is_configured ? 'primary' : 'secondary'}
                    onClick={onSelectRenewalRate(item)}
                  />
                  {item.is_configured && (
                    <div className={classes.RenewalRatesButtonItem}>
                      <i className={`fas fa-check-circle fa-lg`} />
                    </div>
                  )}
                </div>
              </div>
            ))}

          {!plan_names && (
            <>
              <div className={classes.PlanConfigForm}>{form_content(mode)}</div>
              {renderRateTierType()}
            </>
          )}
          {plan_names && (
            <>
              {!form_data.is_current_rate && _.isEmpty(selectedRate) && (
                <>
                  <div className={classes.PlanConfigForm}>{form_content(mode)}</div>
                  {renderRateTierType()}
                </>
              )}

              {!_.isEmpty(selectedRate) && (
                <>
                  <div className={classes.PlanConfigForm}>{form_content(mode)}</div>
                  {renderRateTierType()}
                </>
              )}
            </>
          )}
          {brokerAgency?.status === 'active' && !form_data.is_current_rate && (
            <>
              <div style={{ margin: '15px 0' }}>
                <Button title="Add Extra Row" type="secondary" onClick={onAddExtraRow} />
              </div>

              <ExtraRows
                type="broker"
                extraRows={extraRows}
                setExtraRows={onSetExtraRows}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </>
          )}

          {brokerAgency?.status === 'active' &&
            form_data.is_current_rate &&
            !_.isEmpty(selectedRate) && (
              <>
                <div style={{ margin: '15px 0' }}>
                  <Button title="Add Extra Row" type="secondary" onClick={onAddExtraRow} />
                </div>

                <ExtraRows
                  type="broker"
                  extraRows={extraRows}
                  setExtraRows={onSetExtraRows}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </>
            )}

          <div className={classes.PlanConfigFormFooter}>
            {!plan_names && (
              <>
                <Button
                  title={`${mode === 'CREATE' ? 'Add' : 'Save'} Configuration`}
                  type="primary"
                  buttonType="submit"
                />
                <Button title="Save as Template" type="primary" onClick={onOpenModal} />
              </>
            )}

            {plan_names && (
              <>
                {!form_data.is_current_rate && (
                  <>
                    <Button
                      title={`${mode === 'CREATE' ? 'Add' : 'Save'} Configuration`}
                      type="primary"
                      buttonType="submit"
                    />
                    <Button title="Save as Template" type="primary" onClick={onOpenModal} />
                  </>
                )}

                {form_data.is_current_rate && (
                  <>
                    {isVisibleAddConfigs && (
                      <Button
                        title="Add Configurations"
                        type="primary"
                        onClick={onClickAddConfigurations}
                      />
                    )}
                    {isVisibleSaveConfig && (
                      <>
                        <Button
                          title="Save Config"
                          type="primary"
                          buttonType="submit"
                          // onClick={onSubmitForm('benefit-config')}
                        />
                        <Button
                          type="primary"
                          title="Save as Template"
                          onClick={onOpenModal}
                          // onClick={onSubmitForm('template')}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <Button onClick={onClose} title="Cancel" type="secondary" />
          </div>
        </form>
      )}
    </>
  );
};
