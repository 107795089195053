import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useAccidentBuild } from './useAccidentBuild/useAccidentBuild';

export const AccidentQuotes = () => {
  const { build } = useAccidentBuild();

  return <CoverageBuilder build={build} />;
};
