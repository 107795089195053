import { v4 as uuid } from 'uuid';

import { COVERAGE_ALL_AGES_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import { COVERAGE_FIVE_YEAR_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import { COVERAGE_TEN_YEAR_NAMES } from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

const spanStyles = {
  height: '38px',
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid #dbdbdb',
};

const divStyles = {
  width: '100%',
  padding: '7px 10px',
};

export const renderCoverageAllAges = (allAgesRates) => {
  return Object.keys(allAgesRates).map((item) => (
    <span key={uuid()} style={spanStyles}>
      <div style={divStyles}>{`$${allAgesRates[item] || '0'} (${
        COVERAGE_ALL_AGES_NAMES[item]
      })`}</div>
    </span>
  ));
};

export const renderCoverageFiveYearAges = (fiveYearAgesRates) => {
  return Object.keys(fiveYearAgesRates).map((item) => (
    <span key={uuid()} style={spanStyles}>
      <div style={divStyles}>
        {`$${fiveYearAgesRates[item] || '0'} (${COVERAGE_FIVE_YEAR_NAMES[item]})`}
      </div>
    </span>
  ));
};

export const renderCoverageTenYearAges = (tenYearAgesRates) => {
  return Object.keys(tenYearAgesRates).map((item) => (
    <span key={uuid()} style={spanStyles}>
      <div style={divStyles}>
        {`$${tenYearAgesRates[item] || '0'} (${COVERAGE_TEN_YEAR_NAMES[item]})`}
      </div>
    </span>
  ));
};
