import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import classes from './tabs.module.scss';

export const TabItem = (props) => {
  const { title, onClick, type = 'default', isActive } = props;

  const tabItemStyles = classnames({
    [classes.TabItemActive]: isActive && type === 'default',
    [classes.TabItemDisabled]: !isActive && type === 'default',
    [classes.TabItemUnderlineActive]: isActive && type === 'underline',
    [classes.TabItemUnderlineDisabled]: !isActive && type === 'underline',
  });

  return (
    <button className={tabItemStyles} onClick={onClick}>
      <div className={classes.TabItemTitle}>{title}</div>
    </button>
  );
};

TabItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
