import { CRITICAL_FORM_NAMES } from 'src/constants/formNames';
import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';
import _ from 'lodash';
import {
  coverageAllAges,
  COVERAGE_ALL_AGES_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  coverageFiveYearAges,
  COVERAGE_FIVE_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  coverageTenYearAges,
  COVERAGE_TEN_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';
import { currencyFormatter } from 'src/constants/currency';
import { prepareCustomFields } from './prepareCustomFields';
import { capitalize } from './exportXlsx';

export const normalizeCriticalExport = (ExcelJSWorkbook, criticalQuotes, groupMembers, type) => {
  let criticalCounter = 1;

  for (const quote in criticalQuotes) {
    const criticalQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Critical RFP Quote ${criticalCounter++}`
        : `Sold Critical Quote ${criticalCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );
    const preparedCustomFields = prepareCustomFields(criticalQuotes[quote]);

    const isAllAgesRate = !_.isEmpty(
      criticalQuotes[quote].find((item) => item?.rate_tier_type === 'all_ages'),
    );
    const isFiveAgesRate = !_.isEmpty(
      criticalQuotes[quote].find((item) => item?.rate_tier_type === 'banded_5'),
    );
    const isTenAgesRate = !_.isEmpty(
      criticalQuotes[quote].find((item) => item?.rate_tier_type === 'banded_10'),
    );

    let offerRow = [''];
    let carrierRow = ['Carrier'];
    let lumpSumBenefit = [CRITICAL_FORM_NAMES.lump_sum_benefits];
    let giAmount = [CRITICAL_FORM_NAMES.gi_amount];
    let includeCancer = [CRITICAL_FORM_NAMES.include_cancer];
    let wellnessBenefit = [CRITICAL_FORM_NAMES.wellness_benefit];
    let reccuranceBenefit = [CRITICAL_FORM_NAMES.recurrence_benefit];
    let spouseCoverage = [CRITICAL_FORM_NAMES.spouse_coverage];
    let childCoverage = [CRITICAL_FORM_NAMES.child_coverage];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];

    for (const criticalQuote of criticalQuotes[quote]) {
      if (criticalQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }
    let quoteRows = [
      offerRow,
      carrierRow,
      lumpSumBenefit,
      giAmount,
      includeCancer,
      wellnessBenefit,
      reccuranceBenefit,
      spouseCoverage,
      childCoverage,
      compMethodRow,
      compAmountRow,
      currentlyOfferedRow,
      [],
    ];

    let rateTierTypeRow = ['Rate Tier Type'];

    for (const criticalQuote of criticalQuotes[quote]) {
      carrierRow.push(capitalize(criticalQuote.issuer_name));
      lumpSumBenefit.push(currencyFormatter(criticalQuote.lump_sum_benefits));
      giAmount.push(currencyFormatter(criticalQuote.gi_amount));
      includeCancer.push(criticalQuote.include_cancer ? 'Yes' : 'No');
      wellnessBenefit.push(criticalQuote.wellness_benefit ? 'Yes' : 'No');
      reccuranceBenefit.push(criticalQuote.recurrence_benefit ? 'Yes' : 'No');
      spouseCoverage.push(`${criticalQuote.spouse_coverage}%`);
      childCoverage.push(`${criticalQuote.child_coverage}%`);
      compMethodRow.push(capitalize(criticalQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(criticalQuote));
      rateTierTypeRow.push(normalizeRateTierType(criticalQuote.rate_tier_type));
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        criticalQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        criticalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    criticalQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const criticalQuote of preparedCustomFields) {
      for (let [index, field] of criticalQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      criticalQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    criticalQuoteWorkSheet.addRow(rateTierTypeRow).getCell(1).font = { bold: true };
    let rateRows = [];

    for (let i = 1; i <= 81; i++) {
      rateRows.push(['']);
    }

    for (const criticalQuote of criticalQuotes[quote]) {
      let ageBandedRates = {};

      if (
        criticalQuote.rate_tier_type === 'all_ages' ||
        criticalQuote.rate_tier_type === 'banded_5' ||
        criticalQuote.rate_tier_type === 'banded_10'
      ) {
        for (const item of Object.keys(criticalQuote)) {
          if (item.includes('age')) {
            ageBandedRates = {
              ...ageBandedRates,
              [item]: criticalQuote[item],
            };
          }
        }
      }

      let allAgesRatesPayload = coverageAllAges(ageBandedRates);
      let fiveYearsRatesPayload = coverageFiveYearAges(ageBandedRates);
      let tenYearRatesPayload = coverageTenYearAges(ageBandedRates);

      if (criticalQuote.rate_tier_type === '3tier' || criticalQuote.rate_tier_type === '4tier') {
        rateRows[0].push(`${currencyFormatter(criticalQuote.rate_ee) || '--'} (EE)`);
        if (criticalQuote.rate_tier_type === '3tier') {
          rateRows[1].push(`${currencyFormatter(criticalQuote.rate_ee_1) || '--'} (EE + 1)`);
          rateRows[2].push(`${currencyFormatter(criticalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            // 66 - because range for all ages is from 0 to 14
            for (let i = 3; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 3; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 7; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
        if (criticalQuote.rate_tier_type === '4tier') {
          rateRows[1].push(`${currencyFormatter(criticalQuote.rate_ee_sp) || '--'} (EE + SP)`);
          rateRows[2].push(`${currencyFormatter(criticalQuote.rate_ee_ch) || '--'} (EE + CH)`);
          rateRows[3].push(`${currencyFormatter(criticalQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            for (let i = 4; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 4; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 7; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
      }

      if (criticalQuote?.rate_tier_type === 'all_ages') {
        rateRows[0].push(
          `${currencyFormatter(allAgesRatesPayload.age_0_14) || '--'} (${
            COVERAGE_ALL_AGES_NAMES.age_0_14
          })`,
        );
        for (let i = 1; i < 66; i++) {
          rateRows[i].push(
            `${currencyFormatter(allAgesRatesPayload[`age_${i + 14}`]) || '--'} (${
              COVERAGE_ALL_AGES_NAMES[`age_${i + 14}`]
            })`,
          );
        }
      }

      if (criticalQuote?.rate_tier_type === 'banded_5') {
        rateRows[0].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_0_17) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_18_24) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_18_24
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_25_29) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_25_29
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_30_34) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_30_34
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_35_39) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_35_39
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_40_44) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_40_44
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_45_49) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_45_49
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_50_54) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_50_54
          })`,
        );
        rateRows[8].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_55_59) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_55_59
          })`,
        );
        rateRows[9].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_60_64) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_60_64
          })`,
        );
        rateRows[10].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_65_69) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_65_69
          })`,
        );
        rateRows[11].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_70_74) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_70_74
          })`,
        );
        rateRows[12].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_75_79) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_75_79
          })`,
        );
        rateRows[13].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_80) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }

      if (criticalQuote?.rate_tier_type === 'banded_10') {
        rateRows[0].push(
          `${currencyFormatter(tenYearRatesPayload.age_0_17) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(tenYearRatesPayload.age_18_29) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_18_29
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(tenYearRatesPayload.age_30_39) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_30_39
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(tenYearRatesPayload.age_40_49) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_40_49
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(tenYearRatesPayload.age_50_59) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_50_59
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(tenYearRatesPayload.age_60_69) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_60_69
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(tenYearRatesPayload.age_70_79) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_70_79
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(tenYearRatesPayload.age_80) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 8; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        } else {
          if (isFiveAgesRate) {
            for (let i = 8; i <= 13; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.length !== 1), []]) {
      criticalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    const totalRows = ['Total'];

    for (const criticalQuote of criticalQuotes[quote]) {
      totalRows.push(currencyFormatter(criticalQuote?.calculation?.total));
    }

    const totalRowStyle = criticalQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= criticalQuoteWorkSheet.rowCount; rowIndex++) {
      criticalQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
