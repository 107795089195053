import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';

export const BuilderInput = (props) => {
  const {
    label,
    value,
    onChange,
    symbol = '',
    symbolPosition = 'start',
    inputType = 'text',
    minValue,
    maxValue,
  } = props;

  return (
    <TextField
      style={{ width: '100%' }}
      label={label}
      onChange={onChange}
      value={value}
      type={inputType}
      size="small"
      required
      InputProps={{
        inputProps:
          inputType === 'number' ? { min: minValue ? minValue : 0, max: maxValue ?? 999999 } : {},
        startAdornment:
          symbol && symbolPosition === 'start' ? (
            <InputAdornment position={symbolPosition}>{symbol}</InputAdornment>
          ) : (
            <></>
          ),
        endAdornment:
          symbol && symbolPosition === 'end' ? (
            <InputAdornment position={symbolPosition}>{symbol}</InputAdornment>
          ) : (
            <></>
          ),
      }}
    />
  );
};

BuilderInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  symbol: PropTypes.string,
  symbolPosition: PropTypes.string,
  inputType: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};
