export const stateCodes = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const updatedStateCodes = [
  { option: 'AL', label: 'AL' },
  { option: 'AK', label: 'AK' },
  { option: 'AZ', label: 'AZ' },
  { option: 'AR', label: 'AR' },
  { option: 'CA', label: 'CA' },
  { option: 'CO', label: 'CO' },
  { option: 'CT', label: 'CT' },
  { option: 'DE', label: 'DE' },
  { option: 'DC', label: 'DC' },
  { option: 'FL', label: 'FL' },
  { option: 'GA', label: 'GA' },
  { option: 'HI', label: 'HI' },
  { option: 'ID', label: 'ID' },
  { option: 'IL', label: 'IL' },
  { option: 'IN', label: 'IN' },
  { option: 'IA', label: 'IA' },
  { option: 'KS', label: 'KS' },
  { option: 'KY', label: 'KY' },
  { option: 'LA', label: 'LA' },
  { option: 'ME', label: 'ME' },
  { option: 'MD', label: 'MD' },
  { option: 'MA', label: 'MA' },
  { option: 'MI', label: 'MI' },
  { option: 'MN', label: 'MN' },
  { option: 'MS', label: 'MS' },
  { option: 'MO', label: 'MO' },
  { option: 'MT', label: 'MT' },
  { option: 'NE', label: 'NE' },
  { option: 'NV', label: 'NV' },
  { option: 'NH', label: 'NH' },
  { option: 'NJ', label: 'NJ' },
  { option: 'NM', label: 'NM' },
  { option: 'NY', label: 'NY' },
  { option: 'NC', label: 'NC' },
  { option: 'ND', label: 'ND' },
  { option: 'OH', label: 'OH' },
  { option: 'OK', label: 'OK' },
  { option: 'OR', label: 'OR' },
  { option: 'PA', label: 'PA' },
  { option: 'RI', label: 'RI' },
  { option: 'SC', label: 'SC' },
  { option: 'SD', label: 'SD' },
  { option: 'TN', label: 'TN' },
  { option: 'TX', label: 'TX' },
  { option: 'UT', label: 'UT' },
  { option: 'VT', label: 'VT' },
  { option: 'VA', label: 'VA' },
  { option: 'WA', label: 'WA' },
  { option: 'WV', label: 'WV' },
  { option: 'WI', label: 'WI' },
  { option: 'WY', label: 'WY' },
];
