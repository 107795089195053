import Base from './base';

// GET /accounts/:accountId/contacts/:contactId

class AccountContacts extends Base {
  createAccountContact = (accountId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/contacts`, payload);
  };

  getAccountContacts = (accountId) => {
    return this.apiClient.get(`accounts/${accountId}/contacts`);
  };

  updateAccountContact = (accountId, contactId, payload) => {
    return this.apiClient.put(`accounts/${accountId}/contacts/${contactId}`, payload);
  };

  deleteAccountContact = (accountId, contactId) => {
    return this.apiClient.delete(`accounts/${accountId}/contacts/${contactId}`);
  };
}

export default AccountContacts;
