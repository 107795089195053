import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBrokerData,
  createBrokerAccount,
  getBrokerAccounts,
  getBrokerAccountData,
  getPaymentMethods,
  getProposalsList,
  updateBrokerAccount,
  updateProfileData,
  getRfpQuotes,
} from './broker.actions';

const initialState = {
  brokerData: {},
  accountData: {},
  brokerAccounts: [],
  brokerAccountsMeta: {},
  paymentMethods: [],
  proposalsList: [],
  proposalsMeta: {},
  accountMode: '',
  isLoadingBrokerData: false,
  isLoadingCreateAccount: false,
  isLoadingUpdateAccount: false,
  isLoadingBrokerAccounts: false,
  isLoadingBrokerAccountData: false,
  isLoadingAddEmails: false,
  isLoadingProposals: false,

  isLoadingUpdateProfile: false,

  isEditProfile: false,

  isSubscriptionModal: false,
  isDeleteAccountModal: false,

  fullProposalData: {},
  isLoadingFullProposalData: false,
};

const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    setBrokerData: (state, action) => {
      state.brokerData = action.payload;
    },

    setBrokerAccountsMeta: (state, action) => {
      state.brokerAccountsMeta = action.payload;
    },

    setProposalsMeta: (state, action) => {
      state.proposalsMeta = action.payload;
    },

    setAccountMode: (state, action) => {
      state.accountMode = action.payload;
    },

    setAccountData: (state, action) => {
      state.accountData = action.payload;
    },

    setIsEditProfile: (state, action) => {
      state.isEditProfile = action.payload;
    },

    setIsSubscriptionModal: (state, action) => {
      state.isSubscriptionModal = action.payload;
    },
    setIsDeleteAccountModal: (state, action) => {
      state.isDeleteAccountModal = action.payload;
    },

    clearBrokerState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrokerData.pending, (state) => {
      state.isLoadingLogin = true;
    });
    builder.addCase(fetchBrokerData.fulfilled, (state, action) => {
      state.brokerData = action.payload;
      state.isLoadingLogin = false;
    });
    builder.addCase(fetchBrokerData.rejected, (state) => {
      state.isLoadingLogin = false;
    });

    builder.addCase(createBrokerAccount.pending, (state) => {
      state.isLoadingCreateAccount = true;
    });
    builder.addCase(createBrokerAccount.fulfilled, (state, action) => {
      state.accountData = action.payload;
      state.isLoadingCreateAccount = false;
    });
    builder.addCase(createBrokerAccount.rejected, (state) => {
      state.isLoadingCreateAccount = false;
    });

    builder.addCase(updateBrokerAccount.pending, (state) => {
      state.isLoadingUpdateAccount = true;
    });
    builder.addCase(updateBrokerAccount.fulfilled, (state) => {
      state.isLoadingUpdateAccount = false;
    });
    builder.addCase(updateBrokerAccount.rejected, (state) => {
      state.isLoadingUpdateAccount = false;
    });

    builder.addCase(getBrokerAccounts.pending, (state) => {
      state.isLoadingBrokerAccounts = true;
    });
    builder.addCase(getBrokerAccounts.fulfilled, (state, action) => {
      state.brokerAccounts = action.payload;
      state.isLoadingBrokerAccounts = false;
    });
    builder.addCase(getBrokerAccounts.rejected, (state) => {
      state.isLoadingBrokerAccounts = false;
    });

    builder.addCase(getBrokerAccountData.pending, (state) => {
      state.isLoadingBrokerAccountData = true;
    });
    builder.addCase(getBrokerAccountData.fulfilled, (state, action) => {
      state.accountData = action.payload;
      state.isLoadingBrokerAccountData = false;
    });
    builder.addCase(getBrokerAccountData.rejected, (state) => {
      state.isLoadingBrokerAccountData = false;
    });

    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
    });

    builder.addCase(getProposalsList.pending, (state) => {
      state.isLoadingProposals = true;
    });
    builder.addCase(getProposalsList.fulfilled, (state, action) => {
      state.proposalsList = action.payload;
      state.isLoadingProposals = false;
    });
    builder.addCase(getProposalsList.rejected, (state) => {
      state.isLoadingProposals = false;
    });

    builder.addCase(updateProfileData.pending, (state) => {
      state.isLoadingUpdateProfile = true;
    });
    builder.addCase(updateProfileData.fulfilled, (state) => {
      state.isLoadingUpdateProfile = false;
    });
    builder.addCase(updateProfileData.rejected, (state) => {
      state.isLoadingUpdateProfile = false;
    });

    // DOWNLOADS
    builder.addCase(getRfpQuotes.pending, (state) => {
      state.isLoadingFullProposalData = true;
    });
    builder.addCase(getRfpQuotes.fulfilled, (state, action) => {
      state.fullProposalData = action.payload;
      state.isLoadingFullProposalData = false;
    });
    builder.addCase(getRfpQuotes.rejected, (state) => {
      state.isLoadingFullProposalData = false;
    });
  },
});

export const {
  setBrokerData,
  setAccountMode,
  setIsSubscriptionModal,
  setIsDeleteAccountModal,
  setIsEditProfile,
  setAccountData,
  setBrokerAccountsMeta,
  setProposalsMeta,
  clearBrokerState,
} = brokerSlice.actions;

export default brokerSlice.reducer;
