import React, { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { Loader } from '../Loader/Loader';

import './input.scss';
import classes from './input.module.scss';

export const onFocusInput = (event, setValue) => {
  if (Number(event.target.value) === 0) {
    setValue('');
  }
};

export const onBlurInput = (event, setValue) => {
  if (!event.target.value) {
    setValue(0);
  }
  if (
    !event.target.value.includes('.') &&
    (event.target.value[0] === '0' || event.target.value[0] === 0)
  ) {
    setValue(event.target.value.slice(1) ? event.target.value.slice(1) : 0);
  }
};

export const Input = (props) => {
  const {
    type = 'default',
    value,
    label,
    onChange,
    isRequired,
    onKeyDown,
    onBlur,
    onFocus,
    minRange,
    maxRange,
    isDisabled,
    isLoading,
    tooltipText,
    autoFocus = false,
    refValue,
    title,
  } = props;

  const normalizeInputType = useCallback(() => {
    switch (type) {
      case 'default-number':
      case 'percentage':
        return 'number';

      default:
        return type;
    }
  }, [type]);

  return (
    <div className={classes.InputWrapper}>
      <div className={classes.InputContent}>
        {type === 'number' && !title && type !== 'default-number' && (
          <div className={classes.InputContentDollar}>$</div>
        )}
        <div className={classes.Content}>
          <div className="input-contain">
            <input
              className="Input"
              type={normalizeInputType()}
              value={value ?? ''}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required={isRequired}
              onBlur={onBlur}
              onFocus={onFocus}
              min={minRange}
              max={maxRange}
              disabled={isDisabled}
              autoFocus={autoFocus}
              ref={refValue}
            />
            <label className="placeholder-text">
              <div className="text">
                {label}
                {isRequired && <span className={classes.RequiredStart}>*</span>}
                {tooltipText && (
                  <Tooltip title={tooltipText} className={classes.Tooltip}>
                    <i className="fas fa-info-circle" />
                  </Tooltip>
                )}
              </div>
            </label>
          </div>
          {isLoading && (
            <div className={classes.LoaderWrapper}>
              <Loader size="small" />
            </div>
          )}
        </div>
        {type === 'percentage' && <div className={classes.InputContentPercentage}>%</div>}
        {type === 'number' && title && <div className={classes.InputContentTitle}>{title}</div>}
      </div>
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  tooltipText: PropTypes.string,
};
