import { createSlice } from '@reduxjs/toolkit';
import {
  getAllBrokerContacts,
  createBrokerContact,
  updateBrokerContact,
  getUserByContactEmail,
  getAllAgencyContacts,
  createAgencyContact,
  updateAgencyContact,
  getCarrierContacts,
} from './contacts.actions';

const initialState = {
  // BROKER
  brokerContacts: [],
  brokerContactsMeta: {},
  carrierContact: {},
  isLoadingBrokerContacts: false,
  isLoadingCreateContact: false,
  isLoadingUpdateContact: false,
  isLoadingCarrierContact: false,
  isLoadingContactEmail: false,

  carrierContacts: {},
  isLoadingCarrierContacts: false,

  // AGENCY
  agencyContacts: [],
  agencyContactsMeta: {},
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setCarrierContact: (state, action) => {
      state.carrierContact = action.payload;
    },
    setBrokerContactsMeta: (state, action) => {
      state.brokerContactsMeta = action.payload;
    },

    clearContactsState: () => initialState,
  },
  extraReducers: (builder) => {
    // BROKER
    builder.addCase(getAllBrokerContacts.pending, (state) => {
      state.isLoadingBrokerContacts = true;
    });
    builder.addCase(getAllBrokerContacts.fulfilled, (state, action) => {
      state.brokerContacts = action.payload;
      state.isLoadingBrokerContacts = false;
    });
    builder.addCase(getAllBrokerContacts.rejected, (state) => {
      state.isLoadingBrokerContacts = false;
    });

    builder.addCase(createBrokerContact.pending, (state) => {
      state.isLoadingCreateContact = true;
    });
    builder.addCase(createBrokerContact.fulfilled, (state, action) => {
      state.isLoadingCreateContact = false;
    });
    builder.addCase(createBrokerContact.rejected, (state) => {
      state.isLoadingCreateContact = false;
    });

    builder.addCase(updateBrokerContact.pending, (state) => {
      state.isLoadingUpdateContact = true;
    });
    builder.addCase(updateBrokerContact.fulfilled, (state, action) => {
      state.isLoadingUpdateContact = false;
    });
    builder.addCase(updateBrokerContact.rejected, (state) => {
      state.isLoadingUpdateContact = false;
    });

    // AGENCY
    builder.addCase(getAllAgencyContacts.pending, (state) => {
      state.isLoadingBrokerContacts = true;
    });
    builder.addCase(getAllAgencyContacts.fulfilled, (state, action) => {
      state.brokerContacts = action.payload;
      state.isLoadingBrokerContacts = false;
    });
    builder.addCase(getAllAgencyContacts.rejected, (state) => {
      state.isLoadingBrokerContacts = false;
    });

    builder.addCase(createAgencyContact.pending, (state) => {
      state.isLoadingCreateContact = true;
    });
    builder.addCase(createAgencyContact.fulfilled, (state, action) => {
      state.isLoadingCreateContact = false;
    });
    builder.addCase(createAgencyContact.rejected, (state) => {
      state.isLoadingCreateContact = false;
    });

    builder.addCase(updateAgencyContact.pending, (state) => {
      state.isLoadingUpdateContact = true;
    });
    builder.addCase(updateAgencyContact.fulfilled, (state, action) => {
      state.isLoadingUpdateContact = false;
    });
    builder.addCase(updateAgencyContact.rejected, (state) => {
      state.isLoadingUpdateContact = false;
    });

    builder.addCase(getUserByContactEmail.pending, (state) => {
      state.isLoadingCarrierContact = true;
    });
    builder.addCase(getUserByContactEmail.fulfilled, (state, action) => {
      state.carrierContact = action.payload;
      state.isLoadingCarrierContact = false;
    });
    builder.addCase(getUserByContactEmail.rejected, (state) => {
      state.isLoadingCarrierContact = false;
    });

    builder.addCase(getCarrierContacts.pending, (state) => {
      state.isLoadingCarrierContacts = true;
    });
    builder.addCase(getCarrierContacts.fulfilled, (state, action) => {
      state.carrierContacts = action.payload;
      state.isLoadingCarrierContacts = false;
    });
    builder.addCase(getCarrierContacts.rejected, (state) => {
      state.isLoadingCarrierContacts = false;
    });
  },
});

export const {
  setCarrierContact,
  setBrokerContactsMeta,
  setAgencyCarrierContact,
  clearContactsState,
} = contactsSlice.actions;

export default contactsSlice.reducer;
