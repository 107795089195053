import { createSlice } from '@reduxjs/toolkit';

import {
  getBrokerLicenses,
  updateBrokerLicense,
  deleteBrokerLicense,
} from './brokerLicenses.actions';

const initialState = {
  brokerLicenses: [],
  isLoadingBrokerLicenses: false,

  isLoadingUpdateLicense: false,
  isLoadingDeleteLicense: false,
};

const brokerLicensesSlice = createSlice({
  name: 'brokerLicenses',
  initialState,
  reducers: {
    clearBrokerLicensesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getBrokerLicenses.pending, (state) => {
      state.isLoadingBrokerLicenses = true;
    });
    builder.addCase(getBrokerLicenses.fulfilled, (state, action) => {
      state.brokerLicenses = action.payload;
      state.isLoadingBrokerLicenses = false;
    });
    builder.addCase(getBrokerLicenses.rejected, (state) => {
      state.isLoadingBrokerLicenses = false;
    });

    builder.addCase(updateBrokerLicense.pending, (state) => {
      state.isLoadingUpdateLicense = true;
    });
    builder.addCase(updateBrokerLicense.fulfilled, (state, action) => {
      state.isLoadingUpdateLicense = false;
    });
    builder.addCase(updateBrokerLicense.rejected, (state) => {
      state.isLoadingUpdateLicense = false;
    });

    builder.addCase(deleteBrokerLicense.pending, (state) => {
      state.isLoadingDeleteLicense = true;
    });
    builder.addCase(deleteBrokerLicense.fulfilled, (state, action) => {
      state.isLoadingDeleteLicense = false;
    });
    builder.addCase(deleteBrokerLicense.rejected, (state) => {
      state.isLoadingDeleteLicense = false;
    });
  },
});

export const { clearBrokerLicensesState } = brokerLicensesSlice.actions;

export default brokerLicensesSlice.reducer;
