import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTE } from 'src/constants/routes';

import {
  validateOobCode,
  confirmPasswordReset,
  getIsLoadingOobCode,
  getIsLoadingPasswordReset,
} from 'src/store/app';

import { Button, Loader, LoaderWrapper, InputV2 } from 'src/components';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './resetPassword.module.scss';
import { toast } from 'react-toastify';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  const domain = new URL(window.location).origin;
  const continueUrl = decodeURIComponent(searchParams.get('continueUrl'));
  const replacedUrl = continueUrl?.replaceAll(domain, '') ?? '';

  const isLoadingOobCode = useSelector(getIsLoadingOobCode);
  const isLoadingPasswordReset = useSelector(getIsLoadingPasswordReset);

  useEffect(() => {
    dispatch(validateOobCode({ oobCode, navigate }));
  }, [dispatch, navigate, oobCode]);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onChangeConfirmPassword = useCallback((event) => {
    setConfirmPassword(event.target.value);
  }, []);

  const onSubmitResetPassword = useCallback(
    async (event) => {
      event.preventDefault();

      if (password.length < 6 || confirmPassword.length < 6) {
        return toast('Passwords should have at least 6 characters!', { type: 'warning' });
      }

      if (password !== confirmPassword) {
        return toast('Passwords must be the same!', { type: 'warning' });
      }

      if (password === confirmPassword) {
        const payload = {
          oobCode,
          password,
          navigate,
          replacedUrl,
        };

        dispatch(confirmPasswordReset(payload));
      }
    },
    [dispatch, navigate, password, confirmPassword, oobCode, replacedUrl],
  );

  return (
    <>
      {isLoadingOobCode ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <div className={classes.Layout}>
          <div className={classes.LayoutEllipseLeft} />
          <div className={classes.LayoutEllipseRight} />
          <div className={classes.LayoutLightLine} />

          <div className={classes.ResetPassword}>
            <div className={classes.ResetPasswordCard}>
              <div className={classes.ResetPasswordLogo} onClick={() => navigate(ROUTE.DEFAULT)}>
                <img src={MonarkLogo} alt="loginMonarkLogo" />
              </div>
              {isLoadingPasswordReset ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <form onSubmit={onSubmitResetPassword}>
                  <InputV2
                    label="Password"
                    value={password}
                    type="password"
                    onChange={onChangePassword}
                    autoFocus
                    required
                  />
                  <InputV2
                    label="Confirm Password"
                    value={confirmPassword}
                    type="password"
                    onChange={onChangeConfirmPassword}
                    autoFocus
                    required
                  />
                  <Button
                    type="primaryPurple"
                    size="m"
                    title="Reset Password"
                    buttonType="submit"
                    icon="fas fa-lock"
                    iconColor="#fff"
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
