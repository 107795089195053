import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.medical;

export const getMedicalPlansSelector = createSelector(getState, (state) => state.medicalPlans);

export const getMedicalQuotePlansSelector = createSelector(
  getState,
  (state) => state.medicalQuotePlans,
);
export const isLoadingMedicalQuotePlansSelector = createSelector(
  getState,
  (state) => state.isLoadingMedicalQuotePlans,
);
