import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';

import { normalizeFundingType } from 'src/constants/proposalFormField';
import {
  normalizeCompensationAmount,
  normalizeOrthodonticsValue,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { capitalize } from './exportXlsx';
import { prepareCustomFields } from './prepareCustomFields';

export const normalizeDentalExport = (ExcelJSWorkbook, dentalQuotes, groupMembers, type) => {
  let dentalCounter = 1;
  for (const quote in dentalQuotes) {
    const dentalQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Dental RFP Quote ${dentalCounter++}`
        : `Sold Dental Quote ${dentalCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );

    const preparedCustomFields = prepareCustomFields(dentalQuotes[quote]);

    const isOnly3Tier = _.isEmpty(
      dentalQuotes[quote].find((item) => item.rate_tier_type === '4tier'),
    );
    const isOnly4Tier = _.isEmpty(
      dentalQuotes[quote].find((item) => item.rate_tier_type === '3tier'),
    );
    const isTierTypes = !isOnly3Tier && !isOnly4Tier;
    const isOrthodontics = !_.isEmpty(
      dentalQuotes[quote].find((item) => item.orthodontics !== 'none'),
    );

    const isPPO = !_.isEmpty(dentalQuotes[quote].find((item) => item.plan_type === 'PPO'));
    const isHMO = !_.isEmpty(dentalQuotes[quote].find((item) => item.plan_type === 'HMO'));

    let offerRow = [''];
    let carrierNameRow = ['Carrier'];
    let contributionEeRow = ['Contribution EE'];
    let contributionDepRow = ['Contribution DEP'];
    let fundingTypeRow = ['Funding Type'];
    let planTypesRow = ['Plan Type'];
    let waitingPeriodRow = ['Waiting Period'];
    let dppoOutOfNetworkRow = ['DPPO: Out of Network Coverage'];
    let dppoAnnualMaxRow = ['DPPO: Annual Max'];
    let dppoDeductibleRow = ['DPPO: Deductible'];
    let dppoPreventativeRow = ['DPPO: Preventative'];
    let dppoBasicRow = ['DPPO: Basic'];
    let dppoMajorRow = ['DPPO: Major'];
    let orthodonticsRow = ['Orthodontics'];
    let orthodonticsCoverageRow = ['Orthodontics Coverage'];
    let implantCoverageRow = ['Implant Coverage'];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let selectedPlanRow = ['Selected Plan Name'];

    for (const dentalQuote of dentalQuotes[quote]) {
      if (dentalQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    let quoteRows = [
      offerRow,
      carrierNameRow,
      contributionEeRow,
      contributionDepRow,
      fundingTypeRow,
      planTypesRow,
      waitingPeriodRow,
    ];

    let orthodonticsRows = [];

    if (isOrthodontics) {
      orthodonticsRows = [orthodonticsRow, orthodonticsCoverageRow];
    }

    if (!isOrthodontics) {
      orthodonticsRows = [orthodonticsRow];
    }

    if (isPPO) {
      quoteRows = [
        ...quoteRows,
        dppoOutOfNetworkRow,
        dppoAnnualMaxRow,
        dppoDeductibleRow,
        dppoPreventativeRow,
        dppoBasicRow,
        dppoMajorRow,
        ...orthodonticsRows,
        implantCoverageRow,
        compMethodRow,
        compAmountRow,
      ];
    }

    if (isHMO) {
      quoteRows = [
        ...quoteRows,
        ...orthodonticsRows,
        implantCoverageRow,
        compMethodRow,
        compAmountRow,
      ];
    }

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['EE'];
    let eeOneRow = ['EE + 1'];
    let eeSpRow = ['EE + SP'];
    let eeChRow = ['EE + CH'];
    let famRow = ['FAM'];

    let rateRows = [];

    for (const dentalQuote of dentalQuotes[quote]) {
      carrierNameRow.push(capitalize(dentalQuote.issuer_name));
      contributionEeRow.push(
        dentalQuote.contribution_type === 'percent'
          ? `${dentalQuote.contribution_ee}%`
          : `${currencyFormatter(dentalQuote.contribution_ee)}`,
      );
      contributionDepRow.push(
        dentalQuote.contribution_type === 'percent'
          ? `${dentalQuote.contribution_dep}%`
          : `${currencyFormatter(dentalQuote.contribution_dep)}`,
      );
      fundingTypeRow.push(normalizeFundingType(dentalQuote.funding_type));
      planTypesRow.push(dentalQuote.plan_type);
      waitingPeriodRow.push(dentalQuote.waiting_perid ? 'Yes' : 'No');

      if (dentalQuote.plan_type === 'PPO') {
        dppoOutOfNetworkRow.push(dentalQuote.dppo_out_coverage);
        dppoAnnualMaxRow.push(currencyFormatter(dentalQuote.dppo_annual_max));
        dppoDeductibleRow.push(currencyFormatter(dentalQuote.dppo_deductible));
        dppoPreventativeRow.push(`${dentalQuote.dppo_preventative}%`);
        dppoBasicRow.push(`${dentalQuote.dppo_basic}%`);
        dppoMajorRow.push(`${dentalQuote.dppo_major}%`);
      }

      orthodonticsRow.push(normalizeOrthodonticsValue(dentalQuote.orthodontics));

      if (dentalQuote.orthodontics !== 'none') {
        orthodonticsCoverageRow.push(currencyFormatter(dentalQuote.orthodontics_coverage));
      }

      implantCoverageRow.push(dentalQuote.implant_coverage ? 'Yes' : 'No');
      compMethodRow.push(capitalize(dentalQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(dentalQuote));
      currentlyOfferedRow.push(dentalQuote.is_current_rate ? 'Yes' : 'No');
      selectedPlanRow.push(dentalQuote.selected_plan_name);
      rateTierTypeRow.push(normalizeRateTierType(dentalQuote.rate_tier_type));
      eeRow.push(currencyFormatter(dentalQuote.rate_ee));

      if (dentalQuote.rate_tier_type === '3tier') {
        eeOneRow.push(dentalQuote.rate_ee_1 ? currencyFormatter(dentalQuote.rate_ee_1) : '');
        eeSpRow.push('');
        eeChRow.push('');
      }

      if (dentalQuote.rate_tier_type === '4tier') {
        eeSpRow.push(dentalQuote.rate_ee_sp ? currencyFormatter(dentalQuote.rate_ee_sp) : '');
        eeChRow.push(dentalQuote.rate_ee_ch ? currencyFormatter(dentalQuote.rate_ee_ch) : '');
        eeOneRow.push('');
      }

      famRow.push(dentalQuote.rate_fam ? currencyFormatter(dentalQuote.rate_fam) : '');

      if (isOnly3Tier && dentalQuote.rate_tier_type === '3tier') {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, famRow];
      }

      if (isOnly4Tier && dentalQuote.rate_tier_type === '4tier') {
        rateRows = [rateTierTypeRow, eeRow, eeSpRow, eeChRow, famRow];
      }

      if (isTierTypes) {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, eeSpRow, eeChRow, famRow];
      }
    }

    quoteRows = [...quoteRows, currentlyOfferedRow, selectedPlanRow, []];

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        dentalQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        dentalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    dentalQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const dentalQuote of preparedCustomFields) {
      for (let [index, field] of dentalQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      dentalQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    for (const row of rateRows) {
      dentalQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    dentalQuoteWorkSheet.addRow([]);

    const employee = ['Employee'];

    for (const dentalQuote of dentalQuotes[quote]) {
      employee.push(currencyFormatter(dentalQuote?.calculation?.totalEmployee) || '--');
    }

    const employeeRowStyle = dentalQuoteWorkSheet.addRow(employee);
    employeeRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employeeRowStyle.font = { bold: true };

    const employer = ['Employer'];

    for (const dentalQuote of dentalQuotes[quote]) {
      employer.push(currencyFormatter(dentalQuote?.calculation?.totalEmployer) || '--');
    }

    const employerRowStyle = dentalQuoteWorkSheet.addRow(employer);
    employerRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employerRowStyle.font = { bold: true };

    const totalRows = ['Total'];

    for (const dentalQuote of dentalQuotes[quote]) {
      totalRows.push(currencyFormatter(dentalQuote?.calculation?.total));
    }

    const totalRowStyle = dentalQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= dentalQuoteWorkSheet.rowCount; rowIndex++) {
      dentalQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
