export const dentalPlanTypeOptions = [
  { title: 'HMO', value: 'HMO' },
  { title: 'PPO', value: 'PPO' },
];

export const networkCoverageOptions = [
  { title: 'MAC', value: 'MAC' },
  { title: 'UCR', value: 'UCR' },
];

export const annualMaxOptions = [
  { title: '$1,000', value: 1000 },
  { title: '$1,500', value: 1500 },
  { title: '$2,000', value: 2000 },
  { title: '$2,500', value: 2500 },
  { title: '$5,000', value: 5000 },
  { title: 'No Max', value: 99999 },
];

export const deductibleOptions = [
  { title: '$0', value: '0' },
  { title: '$25', value: '25' },
  { title: '$50', value: '50' },
  { title: '$75', value: '75' },
  { title: '$100', value: '100' },
];

export const preventativeOptions = [
  { title: '100%', value: 100 },
  { title: '90%', value: 90 },
  { title: '80%', value: 80 },
];

export const basicOptions = [
  { title: '100%', value: 100 },
  { title: '90%', value: 90 },
  { title: '80%', value: 80 },
  { title: '70%', value: 70 },
  { title: '60%', value: 60 },
  { title: '50%', value: 50 },
];

export const majorOptions = [
  { title: '80%', value: 80 },
  { title: '70%', value: 70 },
  { title: '60%', value: 60 },
  { title: '50%', value: 50 },
];

export const orthodonticOptions = [
  { title: 'None', value: 'none' },
  { title: 'Child Only', value: 'child' },
  { title: 'Adult + Child', value: 'adult_child' },
];

export const orthodonticCoverageOptions = [
  { title: '$1,000', value: 1000 },
  { title: '$1,500', value: 1500 },
  { title: '$2,000', value: 2000 },
  { title: '$2,500', value: 2500 },
];

export const implantCoverageOptions = [
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const compensationMethodOptions = [
  { title: 'Levelized', value: 'levelized' },
  { title: 'Heaped', value: 'heaped' },
];
