import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { SelectV2 } from '../SelectV2/SelectV2';

import classes from './phoneInput.module.scss';

const phoneOptions = [{ option: '+1', label: '+1' }];

export const PhoneInput = (props) => {
  const { label, onChange } = props;

  const [phonePrefix, setPhonePrefix] = useState('+1');

  const onSelectPrefix = useCallback((value) => {
    setPhonePrefix(value);
  }, []);

  const onChangeInput = useCallback(
    (event) => {
      const value = event.target.value;

      if (onChange) {
        onChange(phonePrefix, value);
      }
    },
    [onChange, phonePrefix],
  );

  return (
    <div className={classes.PhoneInputWrapper}>
      <div className={classes.PhoneInputSelect}>
        <SelectV2
          label={label}
          options={phoneOptions}
          selectedOption={phonePrefix}
          onOptionClick={onSelectPrefix}
        />
      </div>
      <InputMask
        {..._.omit(props, ['label'])}
        mask={'(999) 999-9999'}
        placeholder="(123) 456-7890"
        className={classes.PhoneInput}
        onChange={onChangeInput}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  // isLoading: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  // tooltipText: PropTypes.string,
};
