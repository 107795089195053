import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import { groupMembersSelector } from 'src/store/groupMembers';
import { getAccountData, getBrokerAccountData } from 'src/store/broker';
import {
  createProposal,
  getAccountProposalSelector,
  isLoadingCreateProposalSelector,
  updateProposal,
} from 'src/store/proposals';

import { Button, Input, PageHeader } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import classes from './proposalSetup.module.scss';

export const ProposalSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const paramsId = params?.id;
  const proposalId = params?.proposalId;
  const teamId = params?.teamId;

  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getAccountProposalSelector);
  const groupMembers = useSelector(groupMembersSelector);

  const isLoadingCreate = useSelector(isLoadingCreateProposalSelector);

  const isProposalMode =
    !_.isEmpty(accountData) && !_.isEmpty(proposalData) && !_.isEmpty(groupMembers);

  const [proposalName, setProposalName] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [dueDate, setDueDate] = useState('');

  useEffect(() => {
    if (accountData?.company_name) {
      document.title = `${accountData?.company_name} | Monark`;
    }
  }, [accountData]);

  useEffect(() => {
    if (_.isEmpty(accountData)) {
      dispatch(getBrokerAccountData({ paramsId, navigate }));
    }
  }, [accountData, dispatch, navigate, paramsId]);

  useEffect(() => {
    if (!isProposalMode) {
      if (accountData?.renewal_date) {
        let accountRenewalDate = accountData?.renewal_date;
        let currentDate = moment().format('YYYY-MM-DD');
        const yearsDiff = moment(currentDate).diff(accountRenewalDate, 'Y');

        for (let i = 0; i < yearsDiff + 1; i++) {
          if (moment(accountRenewalDate).isBefore(currentDate)) {
            accountRenewalDate = moment(accountRenewalDate).add(1, 'Y').format('YYYY-MM-DD');
          }
        }

        setEffectiveDate(accountRenewalDate);
        setDueDate(moment(accountRenewalDate).subtract(1, 'months').format('YYYY-MM-DD'));
      }
    }
  }, [isProposalMode, accountData?.renewal_date]);

  useEffect(() => {
    if (isProposalMode) {
      setProposalName(proposalData?.name);
      setEffectiveDate(proposalData?.effective_date);
      setDueDate(proposalData?.due_date);
    }
  }, [isProposalMode, proposalData]);

  const onChangeProposalName = useCallback((event) => {
    setProposalName(event.target.value);
  }, []);

  const onChangeEffectiveDate = useCallback((event) => {
    setEffectiveDate(event.target.value);
  }, []);

  const onChangeDueDate = useCallback((event) => {
    setDueDate(event.target.value);
  }, []);

  const onCreateProposal = useCallback(
    async (event) => {
      event.preventDefault();

      if (!isProposalMode) {
        let payload = {
          accountId: params?.id,
          name: proposalName,
          due_date: dueDate,
          effective_date: effectiveDate,
          navigate,
        };

        if (teamId) {
          payload.org_id = teamId;
        }

        await dispatch(createProposal(payload));
      }

      if (isProposalMode) {
        const updatedAccountProposal = {
          ...proposalData,
        };

        updatedAccountProposal.name = proposalName;
        updatedAccountProposal.effective_date = effectiveDate;
        updatedAccountProposal.due_date = dueDate;

        delete updatedAccountProposal.groups;

        const payload = {
          accountId: paramsId,
          proposalId,
          accountProposalData: updatedAccountProposal,
        };

        await dispatch(updateProposal(payload));

        toast('Proposal was updated successfully', { type: 'success' });
      }
    },
    [
      dispatch,
      navigate,
      isProposalMode,
      params?.id,
      proposalName,
      dueDate,
      effectiveDate,
      teamId,
      proposalData,
      paramsId,
      proposalId,
    ],
  );

  const onClickCancel = useCallback(() => {
    if (!isProposalMode) {
      setProposalName('');
      if (!teamId) {
        return navigate(`${ROUTE.BROKER}/${paramsId}`);
      }

      if (teamId) {
        return navigate(`/teams/${teamId}/accounts/${paramsId}`);
      }
    }

    if (isProposalMode) {
      setProposalName(proposalData?.name);
      setEffectiveDate(proposalData?.effective_date);
      setDueDate(proposalData?.due_date);

      if (!teamId) {
        return navigate(`/broker/accounts/${paramsId}/proposals/${proposalId}/account-info`);
      }

      if (teamId) {
        return navigate(`/teams/${teamId}/accounts/${paramsId}`);
      }
    }
  }, [navigate, isProposalMode, teamId, paramsId, proposalId, proposalData]);

  const onClickNext = useCallback(() => {
    if (teamId) {
      navigate(
        `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/census${
          groupMembers?.length > 0 ? '' : '/new'
        }`,
      );
    }

    if (!teamId) {
      navigate(
        `/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/census${
          groupMembers?.length > 0 ? '' : '/new'
        }`,
      );
    }
  }, [navigate, teamId, accountData, proposalData, groupMembers]);

  return (
    <div className={classes.ProposalSetup}>
      <PageHeader title="Proposal Setup" />
      <div className={classes.ProposalSetupContent}>
        <form onSubmit={onCreateProposal}>
          <Input
            value={proposalName}
            label="Proposal Name"
            placeholder="Red Bull, Inc. Q1 2020 Medical"
            onChange={onChangeProposalName}
            isRequired
          />
          <div className={classes.ProposalSetupDate}>
            <div className={classes.ProposalSetupDateItem}>
              <Input
                label="Effective Date"
                type="date"
                value={effectiveDate}
                onChange={onChangeEffectiveDate}
                tooltipText="Start date of coverage used for quoting"
                isRequired
              />
            </div>
            <div className={classes.ProposalSetupDateItem}>
              <Input
                label="Proposal Due Date"
                type="date"
                value={dueDate}
                onChange={onChangeDueDate}
                tooltipText="Deadline for RFP replies"
                isRequired
              />
            </div>
          </div>

          <div className={classes.ProposalSetupCancel}>
            <Button
              title={!isProposalMode ? 'Continue' : 'Save'}
              type="primary"
              buttonType="submit"
              isLoading={isLoadingCreate}
            />
            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
          </div>
        </form>
      </div>
      {isProposalMode && (
        <div className={classes.ProposalSetupNavigate}>
          <Button title="Next" type="primary" onClick={onClickNext} />
        </div>
      )}
    </div>
  );
};
