import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ArrowImage from 'src/assets/broker/arrow.svg';

import classes from './searchSelect.module.scss';
import { Input } from '../Input/Input';

export const SearchSelect = (props) => {
  const { options, onClick, value, onChange, onClear, label, isRequired } = props;

  const inputRef = useRef();
  const focusRef = useRef();

  const [isVisibleList, setIsVisibleList] = useState(false);
  const [isVisibleCloseIcon, setIsVisibleCloseIcon] = useState(false);

  const handleVisibleList = useCallback(() => {
    setIsVisibleList(true);
  }, []);

  const searchOptions = options.filter((item) =>
    item?.toLowerCase().includes(value?.toLowerCase()),
  );

  const onClickListItem = useCallback(
    (item) => () => {
      onClick(item);
      setIsVisibleList(false);
    },
    [onClick],
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (inputRef && !inputRef.current?.contains(event.target)) {
        setIsVisibleList(false);
      }
    },
    [inputRef],
  );

  const onClearInput = useCallback(() => {
    setIsVisibleList(true);
    onClear();
    if (focusRef) {
      focusRef?.current?.focus();
    }
  }, [onClear]);

  const onMouseEnter = useCallback(() => {
    setIsVisibleCloseIcon(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsVisibleCloseIcon(false);
  }, []);

  useEffect(() => {
    if (!isVisibleCloseIcon) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisibleCloseIcon, handleClickOutside]);

  const onClickTabButton = useCallback((event) => {
    if (event.keyCode === 9) {
      setIsVisibleList(false);
    }
  }, []);

  return (
    <div className={classes.SearchSelect} ref={inputRef}>
      <div
        className={classes.SearchSelectInputWrapper}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Input
          value={value || ''}
          label={label}
          onChange={onChange}
          onFocus={handleVisibleList}
          onKeyDown={onClickTabButton}
          isRequired={isRequired}
          refValue={focusRef}
        />
        {value && isVisibleCloseIcon && (
          <div className={classes.SearchSelectClear} onClick={onClearInput}>
            <i className="fas fa-times" />
          </div>
        )}
        <div className={classes.SearchSelectArrowWrapper}>
          <img
            src={ArrowImage}
            alt="ArrowImage"
            onClick={handleVisibleList}
            className={isVisibleList ? classes.SearchSelectArrowRotated : classes.SearchSelectArrow}
          />
        </div>
      </div>

      {isVisibleList && (
        <div className={classes.SearchSelectList} ref={inputRef}>
          {searchOptions.length ? (
            searchOptions.map((item) => {
              return (
                <div
                  className={classes.SearchSelectListItem}
                  key={item}
                  onClick={onClickListItem(item)}
                >
                  {item}
                </div>
              );
            })
          ) : (
            <div className={classes.SearchSelectNotFind}>Sorry, no match options.</div>
          )}
        </div>
      )}
    </div>
  );
};

SearchSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  isRequired: PropTypes.bool,
};
