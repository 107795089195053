import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';

import {
  getAgencyReporting,
  agencyReportingSelector,
  isLoadingAgencyReportingSelector,
} from 'src/store/agency';

import { DATE_TYPE } from 'src/constants/dateTypes';

import { Loader, PageHeader, SelectV2 } from 'src/components';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import './customDatepickerStyles.scss';
import classes from './agencyReporting.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const premiumOptions = [{ label: 'Sold Premium', option: 'sold_premium' }];

// http://localhost:3000/teams/a26869ac-6e8b-4561-8e65-0327f68723ed/reporting?start=2022-02-01&end=2022-10-01&interval=day

export const AgencyReporting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const teamId = params?.teamId;
  const [searchParams] = useSearchParams();

  const urlStartDate = searchParams.get('start');
  const urlEndDate = searchParams.get('end');
  const urlIntervalType = searchParams.get('interval');

  const agencyReportingData = useSelector(agencyReportingSelector);
  const isLoadingAgencyReporting = useSelector(isLoadingAgencyReportingSelector);

  const [chartData, setChartData] = useState({});
  const [originalChartData, setOriginalChartData] = useState({});
  const [selectedLegends, setSelectedLegends] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const [intervalType, setIntervalType] = useState(
    urlIntervalType ? urlIntervalType : DATE_TYPE.day,
  );

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const startWeek = moment().subtract(1, 'months').format('YYYY-MM-DD');
  const endWeek = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (!urlStartDate && !urlEndDate) {
      navigate(`?start=${startWeek}&end=${endWeek}&interval=${DATE_TYPE.day}`);
    }
  }, [navigate, endWeek, startWeek, urlEndDate, urlStartDate]);

  useEffect(() => {
    setDateRange([
      urlStartDate ? moment(urlStartDate).toDate() : moment().toDate(),
      urlEndDate ? moment(urlEndDate).toDate() : moment().toDate(),
    ]);
    setIntervalType(urlIntervalType);
  }, [urlEndDate, urlStartDate, urlIntervalType]);

  useEffect(() => {
    if (!urlStartDate && !urlEndDate && !urlIntervalType) {
      const payload = {
        startDate: startWeek,
        endDate: endWeek,
        interval: intervalType,
        org_id: teamId,
      };

      dispatch(getAgencyReporting(payload));
    }
  }, [
    dispatch,
    navigate,
    endWeek,
    intervalType,
    urlEndDate,
    urlIntervalType,
    urlStartDate,
    startWeek,
    teamId,
  ]);

  useEffect(() => {
    if (urlStartDate && urlEndDate && urlIntervalType) {
      const payload = {
        startDate: urlStartDate,
        endDate: urlEndDate,
        interval: urlIntervalType,
        org_id: teamId,
      };

      dispatch(getAgencyReporting(payload));
    }
  }, [dispatch, urlEndDate, urlIntervalType, urlStartDate, teamId]);

  useEffect(() => {
    if (!_.isEmpty(agencyReportingData)) {
      setOriginalChartData(agencyReportingData);
      setChartData(agencyReportingData);
    }
  }, [agencyReportingData]);

  useEffect(() => {
    if (selectedLegends.length) {
      setChartData({ labels: agencyReportingData?.labels, datasets: selectedLegends });
    }

    if (!selectedLegends.length) {
      setChartData(originalChartData);
    }
  }, [selectedLegends, originalChartData, agencyReportingData?.labels]);

  useEffect(() => {
    if (selectedLegends?.length && originalChartData?.datasets?.length) {
      if (selectedLegends?.length === originalChartData?.datasets?.length) {
        setSelectedLegends([]);
        setChartData(originalChartData);
      }
    }
  }, [selectedLegends, originalChartData]);

  useEffect(() => {
    if (!_.isEmpty(agencyReportingData)) {
      const response = [];

      for (const item of agencyReportingData?.datasets) {
        const payload = {
          option: item.label,
          label: item.label,
          selected: true,
        };

        response.push(payload);
      }

      setOptionsData(response);
    }
  }, [agencyReportingData]);

  const onUserOptionClick = useCallback(
    (value) => {
      const response = optionsData?.map((item) =>
        item.option === value ? { ...item, selected: !item.selected } : item,
      );

      const member = originalChartData?.datasets?.find((item) => item.label === value);

      if (member) {
        const response = {
          ...originalChartData,
          datasets: _.reject(originalChartData.datasets, (item) => item.label === value),
        };

        setOriginalChartData(response);
      }

      if (!member) {
        const originalMember = agencyReportingData?.datasets?.find((item) => item.label === value);

        const response = {
          ...originalChartData,
          datasets: [...originalChartData.datasets, originalMember],
        };

        setOriginalChartData(response);
      }

      setOptionsData(response);
    },
    [optionsData, originalChartData, agencyReportingData],
  );

  const onClickLegend = useCallback(
    (item) => () => {
      setSelectedLegends([...selectedLegends, item]);

      if (selectedLegends.find((legend) => legend.backgroundColor === item.backgroundColor)) {
        const filteredSelectedLegends = selectedLegends.filter(
          (legend) => legend.backgroundColor !== item.backgroundColor,
        );

        setSelectedLegends(filteredSelectedLegends);
      }
    },
    [selectedLegends],
  );

  const normalizeLegendTextDecoration = useCallback(
    (item) => {
      if (selectedLegends.length) {
        if (selectedLegends?.find((legend) => legend.backgroundColor === item.backgroundColor)) {
          return 'none';
        } else {
          return 'line-through';
        }
      }
    },
    [selectedLegends],
  );

  const onClickRange = useCallback(
    (item) => () => {
      setIntervalType(item);

      const start = moment().subtract(1, 'months').format('MM/DD/YYYY');
      const end = moment().format('MM/DD/YYYY');

      navigate(
        `?start=${moment(startDate || start).format('YYYY-MM-DD')}&end=${moment(
          endDate || end,
        ).format('YYYY-MM-DD')}&interval=${item}`,
      );
    },
    [navigate, endDate, startDate],
  );

  const onChangeDate = useCallback(
    (value) => {
      setDateRange(value);

      const [start, end] = value;

      if (start && end) {
        navigate(
          `?start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format(
            'YYYY-MM-DD',
          )}&interval=${intervalType}`,
        );
      }
    },
    [navigate, intervalType],
  );

  const CustomDatepickerButton = forwardRef(({ value, onClick }, ref) => (
    <button
      className={value ? classes.CustomDatepickerButton : classes.CustomDatepickerButtonSecondary}
      onClick={onClick}
      ref={ref}
    >
      {value
        ? value
        : `${moment().subtract(1, 'months').format('MM/DD/YYYY')} - ${moment().format(
            'MM/DD/YYYY',
          )}`}
    </button>
  ));

  const selectedUsersLength = optionsData?.filter((item) => item.selected)?.length;
  const usersSelectPlaceholder = selectedUsersLength
    ? `${selectedUsersLength} User(s) Selected`
    : 'Select User';

  return (
    <div className={classes.AgencyReporting}>
      <PageHeader title="Agency Reporting">
        <div className={classes.AgencyReportingPageHeader}>
          <div className={classes.AgencyReportingPageHeaderDatepicker}>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeDate}
              isClearable={true}
              customInput={<CustomDatepickerButton />}
            />
          </div>
          <div className={classes.AgencyReportingPageHeaderRange}>
            <div
              className={
                intervalType === DATE_TYPE.month
                  ? classes.AgencyReportingPageHeaderMonthSelected
                  : classes.AgencyReportingPageHeaderMonth
              }
              onClick={onClickRange(DATE_TYPE.month)}
            >
              M
            </div>
            <div className={classes.AgencyReportingPageHeaderLine}>|</div>
            <div
              className={
                intervalType === DATE_TYPE.day
                  ? classes.AgencyReportingPageHeaderDaySelected
                  : classes.AgencyReportingPageHeaderDay
              }
              onClick={onClickRange(DATE_TYPE.day)}
            >
              D
            </div>
          </div>
        </div>
      </PageHeader>
      <div className={classes.AgencyReportingContent}>
        {!_.isEmpty(agencyReportingData?.datasets) && (
          <div className={classes.AgencyReportingContentOptions}>
            <div className={classes.AgencyReportingContentOptionsItem}>
              <SelectV2
                options={optionsData}
                onOptionClick={onUserOptionClick}
                placeholder={usersSelectPlaceholder}
                type="multiple"
              />
            </div>
            <div className={classes.AgencyReportingContentOptionsItem}>
              <SelectV2
                selectedOption="sold_premium"
                options={premiumOptions}
                onOptionClick={() => {}}
              />
            </div>
          </div>
        )}
        <div className={classes.Dataset}>
          {originalChartData?.datasets?.map((item) => (
            <div
              key={item.backgroundColor}
              className={classes.DatasetLegend}
              onClick={onClickLegend(item)}
            >
              <div
                className={classes.DatasetLegendColor}
                style={{ backgroundColor: item.backgroundColor }}
              ></div>
              <div
                className={classes.DatasetLegendLabel}
                style={{ textDecoration: normalizeLegendTextDecoration(item) }}
              >
                {item.label}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.AgencyReportingChart}>
          {isLoadingAgencyReporting && (
            <div className={classes.AgencyReportingChartLoader}>
              <Loader />
            </div>
          )}
          {!_.isEmpty(chartData) && (
            <Bar className={classes.AgencyReportingChartBar} options={options} data={chartData} />
          )}
        </div>
      </div>
    </div>
  );
};
