import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

export const startChat = createAsyncThunk('chat/start-chat', async (data, thunkApi) => {
  try {
    const { teamId, accountId, contacts, payload, navigate, setSelectedRoom } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const response = await api.chat.startChat(payload);

    for (const contact of contacts) {
      const payload = {
        room_id: response.id,
        user_id: contact.id,
        user_type: contact.contact_type,
      };

      await api.chat.addRoomMember(response.id, payload);
    }

    // console.log('startChat[response]', response);

    let roomsPayload = {
      'f[proposal_id]': payload.proposal_id,
    };

    if (!teamId) {
      roomsPayload = {
        ...roomsPayload,
        'f[room_type]': 'carrier',
      };
    }

    await thunkApi.dispatch(getChatRooms(roomsPayload));

    if (teamId && navigate) {
      navigate(`/teams/${teamId}/accounts/${accountId}/proposals/${payload?.proposal_id}/chat`);
    }

    if (!teamId && navigate) {
      navigate(`/rep/my-rfps/accounts/${accountId}/proposals/${payload?.proposal_id}/chat`);
    }

    if (setSelectedRoom) {
      const chatRooms = await api.chat.getChatRooms({ 'f[proposal_id]': payload.proposal_id });
      const selectedRoom = chatRooms.items.find((item) => item.id === response.id);

      await setSelectedRoom(selectedRoom);
    }

    //
  } catch (err) {
    console.warn(err);
  }
});

export const getChatRooms = createAsyncThunk('chat/get-chat-rooms', async (data, thunkApi) => {
  try {
    // const { payload } = data;

    // const token = await auth.currentUser.getIdToken();

    // api.apiClient.setToken(token);

    // console.log('getChatRooms[payload]', data);

    const response = await api.chat.getChatRooms(data);

    // console.log('response[getChatRooms]', response);

    return response.items;
  } catch (err) {
    console.warn(err);
  }
});

export const getChatRoomMessages = createAsyncThunk(
  'chat/get-chat-room-messages',
  async (data, thunkApi) => {
    try {
      const { room_id } = data;

      // const token = await auth.currentUser.getIdToken();

      // api.apiClient.setToken(token);

      const response = await api.chat.getChatRoomMessages(room_id);

      // console.log('response[getChatRoomMessages]', response);

      return response.items;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const sendChatRoomMessage = createAsyncThunk(
  'chat/send-chat-room-message',
  async (data, thunkApi) => {
    try {
      const { room_id, payload } = data;

      // const token = await auth.currentUser.getIdToken();

      // api.apiClient.setToken(token);

      const response = await api.chat.sendChatRoomMessage(room_id, payload);

      // console.log('response[sendChatRoomMessage]', response);

      thunkApi.dispatch(getChatRoomMessages({ room_id }));

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getAllChatRooms = createAsyncThunk(
  'chat/get-all-chat-rooms',
  async (data, thunkApi) => {
    try {
      // const { roomId } = data;

      // const token = await auth.currentUser.getIdToken();

      // api.apiClient.setToken(token);

      const response = await api.chat.getAllChatRooms();

      // console.log('response[getAllChatRooms]', response);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);
