import { createSlice } from '@reduxjs/toolkit';
import {
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  validateOobCode,
  confirmPasswordReset,
  reSendEmailVerification,
  verifyEmailAddress,
  getEmailDomains,
  getUserType,
  sendPasswordResetEmail,
  getCityByZipCode,
  getIssuerEmailDomain,
} from './app.actions';

const initialState = {
  isLoadingLogin: false,
  isLoadingRegister: false,
  loginError: '',
  passwordResetError: '',
  registerError: '',
  isLoadingOobCode: false,
  isLoadingPasswordReset: false,
  isLoadingSendPasswordReset: false,
  isLoadingVerificationLink: false,
  isLoadingVerifyEmail: false,
  isVerifiedEmail: false,
  emailDomains: [],
  userType: '',

  issuerEmailDomain: {},

  isLoadingZipCode: false,

  userClaims: {},
  isLoadingUserClaims: false,

  cityStateData: {},

  continueRoute: '',
  registrationEmail: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoadingLogin: false,
    isLoadingRegister: false,
    loginError: '',
    passwordResetError: '',
    registerError: '',
    isLoadingOobCode: false,
    isLoadingPasswordReset: false,
    isLoadingSendPasswordReset: false,
    isLoadingVerificationLink: false,
    isLoadingVerifyEmail: false,
    isVerifiedEmail: false,
    emailDomains: [],
    userType: '',

    isSubscription: false,

    issuerEmailDomain: {},

    isLoadingZipCode: false,

    userClaims: {},
    isLoadingUserClaims: false,

    cityStateData: {},

    continueRoute: '',
    registrationEmail: '',
  },
  reducers: {
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setPasswordResetError: (state, action) => {
      state.passwordResetError = action.payload;
    },
    setRegisterError: (state, action) => {
      state.registerError = action.payload;
    },
    setIsVerifiedEmail: (state, action) => {
      state.isVerifiedEmail = action.payload;
    },
    setContinueRoute: (state, action) => {
      state.continueRoute = action.payload;
    },
    setRegistrationEmail: (state, action) => {
      state.registrationEmail = action.payload;
    },

    setUserClaims: (state, action) => {
      state.userClaims = action.payload;
    },

    setUserType: (state, action) => {
      state.userType = action.payload;
    },

    setCityStateData: (state, action) => {
      state.cityStateData = action.payload;
    },

    setIssuerEmailDomain: (state, action) => {
      state.issuerEmailDomain = action.payload;
    },
    setIsSubscription: (state, action) => {
      state.isSubscription = action.payload;
    },
    clearAppState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signInWithEmailAndPassword.pending, (state) => {
      state.loginError = '';
      state.isLoadingLogin = true;
    });
    builder.addCase(signInWithEmailAndPassword.fulfilled, (state) => {
      state.isLoadingLogin = false;
    });
    builder.addCase(signInWithEmailAndPassword.rejected, (state) => {
      state.isLoadingLogin = false;
    });

    builder.addCase(registerWithEmailAndPassword.pending, (state) => {
      state.registerError = '';
      state.isLoadingRegister = true;
    });
    builder.addCase(registerWithEmailAndPassword.fulfilled, (state) => {
      state.isLoadingRegister = false;
    });
    builder.addCase(registerWithEmailAndPassword.rejected, (state) => {
      state.isLoadingRegister = false;
    });

    builder.addCase(sendPasswordResetEmail.pending, (state) => {
      state.isLoadingSendPasswordReset = true;
    });
    builder.addCase(sendPasswordResetEmail.fulfilled, (state) => {
      state.isLoadingSendPasswordReset = false;
    });
    builder.addCase(sendPasswordResetEmail.rejected, (state) => {
      state.isLoadingSendPasswordReset = false;
    });

    builder.addCase(validateOobCode.pending, (state) => {
      state.isLoadingOobCode = true;
    });
    builder.addCase(validateOobCode.fulfilled, (state) => {
      state.isLoadingOobCode = false;
    });
    builder.addCase(validateOobCode.rejected, (state) => {
      state.isLoadingOobCode = false;
    });

    builder.addCase(confirmPasswordReset.pending, (state) => {
      state.isLoadingPasswordReset = true;
    });
    builder.addCase(confirmPasswordReset.fulfilled, (state) => {
      state.isLoadingPasswordReset = false;
    });
    builder.addCase(confirmPasswordReset.rejected, (state) => {
      state.isLoadingPasswordReset = false;
    });

    builder.addCase(reSendEmailVerification.pending, (state) => {
      state.isLoadingVerificationLink = true;
    });
    builder.addCase(reSendEmailVerification.fulfilled, (state) => {
      state.isLoadingVerificationLink = false;
    });
    builder.addCase(reSendEmailVerification.rejected, (state) => {
      state.isLoadingVerificationLink = false;
    });

    builder.addCase(verifyEmailAddress.pending, (state) => {
      state.isLoadingVerifyEmail = true;
    });
    builder.addCase(verifyEmailAddress.fulfilled, (state) => {
      state.isLoadingVerifyEmail = false;
    });
    builder.addCase(verifyEmailAddress.rejected, (state) => {
      state.isLoadingVerifyEmail = false;
    });

    builder.addCase(getEmailDomains.fulfilled, (state, action) => {
      state.emailDomains = action.payload;
    });

    builder.addCase(getUserType.pending, (state) => {
      state.isLoadingUserClaims = true;
    });
    builder.addCase(getUserType.fulfilled, (state, action) => {
      state.userType = action.payload;
      state.isLoadingUserClaims = false;
    });
    builder.addCase(getUserType.rejected, (state) => {
      state.userType = '';
      state.isLoadingUserClaims = false;
    });

    builder.addCase(getCityByZipCode.pending, (state) => {
      state.cityStateData = {};
      state.isLoadingZipCode = true;
    });
    builder.addCase(getCityByZipCode.fulfilled, (state, action) => {
      state.cityStateData = action.payload;
      state.isLoadingZipCode = false;
    });
    builder.addCase(getCityByZipCode.rejected, (state, action) => {
      state.cityStateData = action.payload;
      state.isLoadingZipCode = false;
    });

    builder.addCase(getIssuerEmailDomain.fulfilled, (state, action) => {
      state.issuerEmailDomain = action.payload;
    });
  },
});

export const {
  setLoginError,
  setPasswordResetError,
  setRegisterError,
  setIsVerifiedEmail,
  setContinueRoute,
  setRegistrationEmail,
  setUserClaims,
  setUserType,
  setCityStateData,
  setIssuerEmailDomain,
  setIsSubscription,
  clearAppState,
} = appSlice.actions;

export default appSlice.reducer;
