import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import xlsx from 'xlsx';
import _ from 'lodash';

import { getAccountData, getBrokerAccountData, getBrokerData } from 'src/store/broker';
import { createProposalCensus, isLoadingCreateCensusSelector } from 'src/store/groupMembers';

import { ROUTE } from 'src/constants/routes';
import { validateCensus, validateCensusHousehold } from 'src/constants/validateCensus';

import {
  Loader,
  MembersTable,
  ErrorMessage,
  Button,
  Modal,
  SearchSelect,
  PageHeader,
} from 'src/components';
import { UploadCensus } from './components';
import { AddCensusMember } from '../addCensusMember/AddCensusMember';

import classes from './proposalBuilder.module.scss';

export const ProposalBuilder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;
  const teamId = params?.teamId;

  const accountData = useSelector(getAccountData);
  const brokerData = useSelector(getBrokerData);
  const isLoadingCreate = useSelector(isLoadingCreateCensusSelector);

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [membersData, setMembersData] = useState([]);

  const [censusErros, setCensusErrors] = useState({});
  const [censusHouseholdErros, setCensusHouseholdErrors] = useState({});

  const [censusData, setCensusData] = useState([]);
  const [missingNames, setMissingNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState({});
  const [errorCensusNames, setErrorCensusNames] = useState([]);
  const [isMissingNamesModal, setIsMissingNamesModal] = useState(false);

  useEffect(() => {
    if (accountData?.company_name) {
      document.title = `${accountData?.company_name} | Monark`;
    }
  }, [accountData]);

  useEffect(() => {
    if (_.isEmpty(accountData)) {
      dispatch(getBrokerAccountData({ paramsId: accountId, navigate }));
    }
  }, [accountData, dispatch, navigate, accountId]);

  useEffect(() => {
    if (missingNames) {
      let updatedPayload = {};
      for (const item of missingNames) {
        updatedPayload = {
          ...updatedPayload,
          [item]: '',
        };
      }
      setSelectedNames(updatedPayload);
    }
  }, [missingNames]);

  const validateTobaccoUser = (value) => {
    if (
      typeof value === 'string' &&
      (value?.toString().toLowerCase() === 'y' ||
        value?.toString().toLowerCase() === 'yes' ||
        value?.toString().toLowerCase() === 't' ||
        value?.toString().toLowerCase() === 'true')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateNumberValue = (value) => {
    if (typeof value === 'number' && value) {
      return value;
    } else {
      return 0;
    }
  };

  const uploadCsv = (e) => {
    e.preventDefault();
    setIsLoadingFile(true);

    const localCensus = {
      meta: {
        total: 0,
        limit: 10,
        offset: 0,
      },
      items: [],
    };

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array', cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: true });
        const importedHeaders = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        const originalFields = [
          'ee_id',
          'first_name',
          'last_name',
          'zip_code',
          'date_of_birth',
          'gender',
          'relationship',
          'current_medical_plan_name',
          'dental_plan_name',
          'vision_plan_name',
          'life_benefit_amount',
          'salary_amount',
          'job_title',
          'is_tobacco_user',
        ];

        let missingNames = originalFields.filter((item) => !importedHeaders.includes(item));

        if (missingNames.length > 0) {
          let missedValues = _.cloneDeep(importedHeaders);
          setMissingNames(missingNames);
          setCensusData(_.cloneDeep(json));

          for (const item of originalFields) {
            _.reject(missedValues, (val) => val === item);
          }

          if (missedValues.length > 0) {
            setErrorCensusNames(['Ignore', ...missedValues]);
            return setIsMissingNamesModal(true);
          }
        }

        let rowErrors = {};
        let householdErrors = {};

        try {
          rowErrors = validateCensus(json, localCensus, setIsLoadingFile);
        } catch (error) {
          console.warn('error', error);
        }

        try {
          householdErrors = validateCensusHousehold(json, householdErrors);
        } catch (error) {
          console.warn('error', error);
        }

        if (!_.isEmpty(rowErrors)) {
          setCensusErrors(rowErrors);
        }

        if (!_.isEmpty(householdErrors)) {
          setCensusHouseholdErrors(householdErrors);
        }

        if (_.isEmpty(rowErrors) && _.isEmpty(householdErrors)) {
          let updatedMembers = [];

          for (const member of localCensus.items) {
            if (member?.relationship === 'employee') {
              let payload = {
                ...member,
              };

              payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
              payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
              payload.salary_amount = validateNumberValue(payload.salary_amount);

              delete payload['Medical enrollment'];
              delete payload['Vision enrollment'];
              delete payload['Dental enrollment'];

              updatedMembers.push(payload);
            } else {
              let payload = {
                ...member,
                salary_amount: 0,
              };

              payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
              payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
              payload.salary_amount = validateNumberValue(payload.salary_amount);

              delete payload['Medical enrollment'];
              delete payload['Vision enrollment'];
              delete payload['Dental enrollment'];

              updatedMembers.push(payload);
            }
          }

          setCensusErrors({});
          setCensusHouseholdErrors({});
          localCensus.meta.total = localCensus.items.length;
          setMembersData(updatedMembers);
        }
        setIsLoadingFile(false);
      };

      reader.onerror = () => {
        e.target.files = null;
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    }
    setIsLoadingFile(false);
  };

  const onSubmitCensus = useCallback(
    async (event) => {
      event.preventDefault();

      const localCensus = {
        meta: {
          total: 0,
          limit: 10,
          offset: 0,
        },
        items: [],
      };

      let rowErrors = {};
      let householdErrors = {};

      try {
        rowErrors = validateCensus(censusData, localCensus, setIsLoadingFile);
      } catch (error) {
        console.warn('error', error);
      }

      try {
        householdErrors = validateCensusHousehold(censusData, householdErrors);
      } catch (error) {
        console.warn('error', error);
      }

      if (!_.isEmpty(rowErrors)) {
        setCensusErrors(rowErrors);
      }

      if (!_.isEmpty(householdErrors)) {
        setCensusHouseholdErrors(householdErrors);
      }

      if (_.isEmpty(rowErrors) && _.isEmpty(householdErrors)) {
        let updatedMembers = [];

        for (const member of localCensus.items) {
          if (member?.relationship === 'employee') {
            let payload = {
              ...member,
            };

            payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
            payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
            payload.salary_amount = validateNumberValue(payload.salary_amount);

            delete payload['Medical enrollment'];
            delete payload['Vision enrollment'];
            delete payload['Dental enrollment'];

            updatedMembers.push(payload);
          } else {
            let payload = {
              ...member,
              salary_amount: 0,
            };

            payload.is_tobacco_user = validateTobaccoUser(member?.is_tobacco_user);
            payload.life_benefit_amount = validateNumberValue(payload.life_benefit_amount);
            payload.salary_amount = validateNumberValue(payload.salary_amount);

            delete payload['Medical enrollment'];
            delete payload['Vision enrollment'];
            delete payload['Dental enrollment'];

            updatedMembers.push(payload);
          }
        }

        localCensus.meta.total = localCensus.items.length;

        setMembersData(updatedMembers);

        setIsMissingNamesModal(false);
        setCensusErrors({});
        setCensusHouseholdErrors({});
      }
      setIsLoadingFile(false);
    },
    [censusData],
  );

  const onCloseErrorMessage = useCallback(
    (error) => () => {
      let payload = {
        ...censusErros,
      };

      delete payload[error];

      setCensusErrors(payload);
    },
    [censusErros],
  );

  const onCloseHouseholdaErrorMessage = useCallback(
    (error) => () => {
      let payload = {
        ...censusHouseholdErros,
      };

      delete payload[error];

      setCensusHouseholdErrors(payload);
    },
    [censusHouseholdErros],
  );

  const onSelectValue = useCallback(
    (value, key) => {
      const payload = {
        ...selectedNames,
        [key]: value,
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [key]: value === 'Ignore' ? '' : item[value],
        };

        delete payload[value];

        updatedPayload.push(payload);
      }

      if (value !== 'Ignore') {
        setErrorCensusNames(errorCensusNames.filter((name) => name !== value));
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [selectedNames, censusData, errorCensusNames],
  );

  const onChangeValue = useCallback(
    (event, key) => {
      const payload = {
        ...selectedNames,
        [key]: event.target.value,
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [key]: item[event.target.value],
        };

        updatedPayload.push(payload);
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [censusData, selectedNames],
  );

  const onClearValue = useCallback(
    (key) => () => {
      setErrorCensusNames([...errorCensusNames, selectedNames[key]]);

      const payload = {
        ...selectedNames,
        [key]: '',
      };

      let updatedPayload = [];

      for (const item of censusData) {
        const payload = {
          ...item,
          [selectedNames[key]]: item[key],
        };

        delete payload[key];

        updatedPayload.push(payload);
      }

      setCensusData(updatedPayload);
      setSelectedNames(payload);
    },
    [errorCensusNames, selectedNames, censusData],
  );

  const onClickUplaod = useCallback((event) => {
    return (event.target.value = '');
  }, []);

  const onClickCancel = useCallback(() => {
    setMembersData([]);
    if (!teamId) {
      return navigate(`${ROUTE.BROKER}/${params?.id}`);
    }

    if (teamId) {
      return navigate(`/teams/${teamId}/accounts/${params?.id}`);
    }
  }, [navigate, params, teamId]);

  const onCloseModal = useCallback(() => {
    setErrorCensusNames([]);
    setCensusData([]);
    setMissingNames([]);
    setIsMissingNamesModal(false);
  }, []);

  const onCreateCensus = useCallback(
    async (event) => {
      event.preventDefault();

      const updatedMembers = [];

      for (const item of membersData) {
        delete item.id;
        delete item.family_id;

        updatedMembers.push(item);
      }

      const payload = {
        name: 'Default Group',
        accountId,
        proposalId,
        brokerId: brokerData?.id,
        members: updatedMembers,
        org_id: teamId,
        navigate,
      };

      await dispatch(createProposalCensus(payload));
    },
    [dispatch, navigate, accountId, proposalId, membersData, brokerData, teamId],
  );

  return (
    <>
      {isMissingNamesModal && (
        <Modal closeButtonType="inside" onClose={onCloseModal} isScrollable={true}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>Missed Column Names</div>
            <form onSubmit={onSubmitCensus}>
              <div className={classes.ModalContentBody}>
                {missingNames?.map((item) => (
                  <div key={item} className={classes.ModalContentSelect}>
                    <SearchSelect
                      label={item}
                      value={selectedNames[item]}
                      onClick={(value) => onSelectValue(value, item)}
                      onChange={(event) => onChangeValue(event, item)}
                      onClear={onClearValue(item)}
                      options={errorCensusNames}
                      isRequired
                    />
                  </div>
                ))}
              </div>
              <div className={classes.ModalContentFooter}>
                <Button type="primary" title="Save" buttonType="submit" />
                <Button type="secondary" title="Cancel" onClick={onCloseModal} />
              </div>
            </form>
          </div>
        </Modal>
      )}
      <div className={classes.Proposal}>
        <div className={classes.ProposalContent}>
          <PageHeader title="Upload Census">
            <AddCensusMember
              uploadMembers={membersData}
              setUploadMembers={setMembersData}
              type="custom"
            />
          </PageHeader>
          <form onSubmit={onCreateCensus}>
            {membersData?.length !== 0 ? (
              <div className={classes.ProposalTableWrapper}>
                <MembersTable title="Group Members" membersData={membersData} />
              </div>
            ) : (
              <>
                <UploadCensus />
                {isLoadingFile ? (
                  <Loader />
                ) : (
                  <div className={classes.UploadFileWrapper}>
                    <label className={classes.UploadFile}>
                      <span>
                        <i className="fas fa-upload" />
                      </span>
                      <span>Choose a file...</span>
                      <input
                        type="file"
                        accept=".csv, .xls, .xlsx"
                        onChange={uploadCsv}
                        onClick={onClickUplaod}
                      />
                    </label>
                  </div>
                )}
              </>
            )}
            <div className={classes.ProposalCancel}>
              {membersData?.length !== 0 && (
                <Button
                  title="Continue"
                  type="primary"
                  buttonType="submit"
                  isLoading={isLoadingCreate}
                />
              )}
              <Button title="Cancel" type="secondary" onClick={onClickCancel} />
            </div>
          </form>
        </div>
        {Object.keys(censusErros)?.map((item) => (
          <ErrorMessage title={`Error Row: ${item}`} key={item} onClose={onCloseErrorMessage(item)}>
            <ul>
              {censusErros[item]?.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </ErrorMessage>
        ))}

        {Object.keys(censusHouseholdErros)?.map((item) => (
          <ErrorMessage
            title={`Household Error: ee_id ${item}`}
            key={item}
            onClose={onCloseHouseholdaErrorMessage(item)}
          >
            <ul>
              {censusHouseholdErros[item]?.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </ErrorMessage>
        ))}
      </div>
    </>
  );
};
