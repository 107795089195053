import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

import {
  createCensusMember,
  groupDataSelector,
  groupMembersSelector,
  isLoadingCreateCensusMemberSelector,
  isLoadingUpdateCensusMemberSelector,
  updateCensusMember,
} from 'src/store/groupMembers';
import { getAccountProposal } from 'src/store/proposals';

import { Button, MUISelect, Modal } from 'src/components';

import { validateEmptyValue } from 'src/constants/validateEmptyValue';

import classes from './addCensusMember.module.scss';

const member = {
  family_id: '',
  first_name: '',
  last_name: '',
  gender: '',
  relationship: '',
  zip_code: '',
  date_of_birth: '',
  current_medical_plan_name: '',
  dental_plan_name: '',
  vision_plan_name: '',
  life_benefit_amount: '',
  salary_amount: '',
  job_title: '',
  is_tobacco_user: false,
};

export const AddCensusMember = (props) => {
  const {
    selectedMember,
    onCloseEditModal,
    uploadMembers,
    setUploadMembers,
    type = 'default',
  } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const groupData = useSelector(groupDataSelector);
  const groupId = groupData?.id;

  const accountMembers = useSelector(groupMembersSelector);

  const isLoadingCreate = useSelector(isLoadingCreateCensusMemberSelector);
  const isLoadingUpdate = useSelector(isLoadingUpdateCensusMemberSelector);

  const [householdFamilies, setHouseholdFamilies] = useState({});

  const [censusMember, setCensusMember] = useState(
    !_.isEmpty(selectedMember) ? selectedMember : member,
  );
  const [medicalOptions, setMedicalOptions] = useState([]);
  const [dentalOptions, setDentalOptions] = useState([]);
  const [visionOptions, setVisionOptions] = useState([]);
  const [householdEmployee, setHouseholdEmployee] = useState({});

  const [isVisibleModal, setIsVisibleModal] = useState(!_.isEmpty(selectedMember) ? true : false);

  const genderOptions = [
    { title: 'Male', value: 'M' },
    { title: 'Female', value: 'F' },
  ];

  const relationshipOptions = [
    { title: 'Employee', value: 'employee' },
    { title: 'Spouse', value: 'spouse' },
    { title: 'Life Partner', value: 'life_partner' },
    { title: 'Child', value: 'child' },
  ];

  const tobaccoOptions = [
    { title: 'Yes', value: 'yes' },
    { title: 'No', value: 'no' },
  ];

  useEffect(() => {
    if (type === 'default') {
      const payload = accountMembers?.reduce((previousValue, currentValue) => {
        if (typeof previousValue[currentValue.family_id] === 'undefined') {
          previousValue[currentValue.family_id] = [];
        }

        previousValue[currentValue.family_id].push(currentValue);

        return previousValue;
      }, {});

      setHouseholdFamilies(payload);
    }

    if (type !== 'default') {
      const payload = uploadMembers?.reduce((previousValue, currentValue) => {
        if (typeof previousValue[currentValue.family_id] === 'undefined') {
          previousValue[currentValue.family_id] = [];
        }

        previousValue[currentValue.family_id].push(currentValue);

        return previousValue;
      }, {});

      setHouseholdFamilies(payload);
    }
  }, [type, accountMembers, uploadMembers]);

  useEffect(() => {
    if (!_.isEmpty(householdEmployee)) {
      if (householdEmployee?.current_medical_plan_name) {
        const payload = [{ name: 'Waived', key: '' }];
        const options = {
          title: householdEmployee?.current_medical_plan_name,
          value: householdEmployee?.current_medical_plan_name,
        };

        payload.push(options);

        setMedicalOptions(payload);
      }
    }
    if (_.isEmpty(householdEmployee)) {
      setMedicalOptions([]);
    }
  }, [householdEmployee]);

  useEffect(() => {
    if (!_.isEmpty(householdEmployee)) {
      if (householdEmployee?.dental_plan_name) {
        const payload = [{ name: 'Waived', key: '' }];
        const options = {
          title: householdEmployee?.dental_plan_name,
          value: householdEmployee?.dental_plan_name,
        };

        payload.push(options);

        setDentalOptions(payload);
      }
    }
    if (_.isEmpty(householdEmployee)) {
      setDentalOptions([]);
    }
  }, [householdEmployee]);

  useEffect(() => {
    if (!_.isEmpty(householdEmployee)) {
      if (householdEmployee?.vision_plan_name) {
        const payload = [{ name: 'Waived', key: '' }];
        const options = {
          title: householdEmployee?.vision_plan_name,
          value: householdEmployee?.vision_plan_name,
        };

        payload.push(options);

        setVisionOptions(payload);
      }
    }
    if (_.isEmpty(householdEmployee)) {
      setVisionOptions([]);
    }
  }, [householdEmployee]);

  useEffect(() => {
    if (_.isEmpty(selectedMember)) {
      const memberId = censusMember?.family_id;
      const employee = householdFamilies[memberId]?.find(
        (item) => item?.relationship === 'employee',
      );

      if (employee && censusMember?.family_id) {
        const payload = {
          ...censusMember,
          zip_code: validateEmptyValue(employee?.zip_code),
          current_medical_plan_name: validateEmptyValue(employee?.current_medical_plan_name),
          dental_plan_name: validateEmptyValue(employee?.dental_plan_name),
          vision_plan_name: validateEmptyValue(employee?.vision_plan_name),
        };

        setCensusMember(payload);
      }

      if (!employee) {
        const payload = {
          ...censusMember,
          zip_code: '',
          current_medical_plan_name: '',
          dental_plan_name: '',
          vision_plan_name: '',
        };

        setCensusMember(payload);
      }

      setHouseholdEmployee(employee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember, censusMember?.family_id]);

  const handleModal = useCallback(() => {
    setIsVisibleModal((prev) => !prev);
    if (onCloseEditModal) {
      onCloseEditModal();
    }
  }, [onCloseEditModal]);

  const onChangeInput = useCallback(
    (event, target, type = 'text') => {
      const payload = {
        ...censusMember,
        [target]: type === 'text' ? event.target.value : event,
      };

      setCensusMember(payload);
    },
    [censusMember],
  );

  const onChangeNumberInput = useCallback(
    (event, target) => {
      if (event.target.value < 0) {
        const payload = {
          ...censusMember,
          [target]: '',
        };

        return setCensusMember(payload);
      }

      const payload = {
        ...censusMember,
        [target]: event.target.value,
      };

      setCensusMember(payload);
    },
    [censusMember],
  );

  const onSelectOption = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value === 'yes' || event.target.value === 'no') {
        payload = {
          ...censusMember,
          [target]: event.target.value === 'yes' ? true : false,
        };
        return setCensusMember(payload);
      }

      payload = {
        ...censusMember,
        [target]: event.target.value,
      };
      setCensusMember(payload);
    },
    [censusMember],
  );

  const onCreateCensusMember = useCallback(
    async (event) => {
      event.preventDefault();

      if (!censusMember.gender) {
        return toast('Gender should be selected', { type: 'warning' });
      }

      if (!censusMember.relationship) {
        return toast('Relationship should be selected', { type: 'warning' });
      }

      let payload = {
        ...censusMember,
        group_id: groupId,
      };

      if (!payload?.life_benefit_amount) {
        payload.life_benefit_amount = null;
      }

      if (!payload?.salary_amount) {
        payload.salary_amount = null;
      }

      if (payload?.life_benefit_amount) {
        payload.life_benefit_amount = Number(payload?.life_benefit_amount);
      }

      if (payload?.salary_amount) {
        payload.salary_amount = Number(payload?.salary_amount);
      }

      const household = householdFamilies[payload?.family_id];

      if (household?.length > 0) {
        const householdEmployee = household?.find((item) => item?.relationship === 'employee');
        const householdSpouse = household?.find(
          (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
        );

        if (householdEmployee?.id !== payload.id) {
          if (householdEmployee?.life_benefit_amount) {
            if (payload?.life_benefit_amount > householdEmployee?.life_benefit_amount) {
              return toast(
                `Life Benefit Amount should be less or equals to Employee Life Benefit Amount`,
                {
                  type: 'warning',
                },
              );
            }
          }

          if (payload.relationship === 'employee' && householdEmployee) {
            return toast(
              `Household with Employee ID ${payload?.family_id} Employee already exist`,
              {
                type: 'warning',
              },
            );
          }
        }

        if (householdSpouse?.id !== payload.id) {
          if (
            (payload.relationship === 'spouse' || payload.relationship === 'life_partner') &&
            householdSpouse
          ) {
            return toast(`Household with Employee ID ${payload?.family_id} Spouse already exist`, {
              type: 'warning',
            });
          }
        }
      }

      if (type === 'default') {
        if (_.isEmpty(selectedMember)) {
          if (!household) {
            if (payload.relationship !== 'employee') {
              return toast('New household must include employee', { type: 'warning' });
            }
          }

          await dispatch(createCensusMember({ accountId, groupId, payload }));
        }

        if (!_.isEmpty(selectedMember)) {
          await dispatch(
            updateCensusMember({
              accountId,
              groupId,
              memberId: censusMember?.id,
              payload,
              member: selectedMember,
              household: householdFamilies,
            }),
          );
        }

        await dispatch(getAccountProposal({ accountId, proposalId }));
      }

      if (type !== 'default') {
        setUploadMembers((prev) => [...prev, { ...payload, id: uuid(), ee_id: payload.family_id }]);
      }

      setCensusMember(member);
      setIsVisibleModal(false);

      if (onCloseEditModal) {
        onCloseEditModal();
      }
    },
    [
      dispatch,
      accountId,
      proposalId,
      groupId,
      type,
      censusMember,
      householdFamilies,
      selectedMember,
      onCloseEditModal,
      setUploadMembers,
    ],
  );

  const tooltipText =
    censusMember?.relationship !== 'employee' || !censusMember?.relationship
      ? 'Please update it on employee level'
      : '';

  return (
    <>
      {isVisibleModal && (
        <Modal type="xlarge" closeButtonType="inside" onClose={handleModal}>
          <div className={classes.Modal}>
            <div className={classes.ModalTitle}>
              {!_.isEmpty(selectedMember) ? 'Update' : 'Add'} Member
            </div>
            <form onSubmit={onCreateCensusMember}>
              <div className={classes.ModalContent}>
                <TextField
                  label="Employee ID"
                  value={censusMember.family_id}
                  onChange={(event) => onChangeInput(event, 'family_id')}
                  size="small"
                  required
                />
                <TextField
                  label="First Name"
                  value={censusMember.first_name}
                  onChange={(event) => onChangeInput(event, 'first_name')}
                  size="small"
                  required
                />
                <TextField
                  label="Last Name"
                  value={censusMember.last_name}
                  onChange={(event) => onChangeInput(event, 'last_name')}
                  size="small"
                  required
                />
                <div className={classes.ModalContentTobaccoSelect}>
                  <MUISelect
                    label="Gender"
                    value={censusMember?.gender}
                    options={genderOptions}
                    onChange={(event) => onSelectOption(event, 'gender')}
                    required
                  />
                </div>
                <div className={classes.ModalContentTobaccoSelect}>
                  <MUISelect
                    label="Relationship"
                    value={censusMember?.relationship}
                    options={relationshipOptions}
                    onChange={(event) => onSelectOption(event, 'relationship')}
                    required
                  />
                </div>
                <Tooltip title={tooltipText}>
                  <TextField
                    label="Zip Code"
                    value={censusMember.zip_code}
                    onChange={(event) => onChangeInput(event, 'zip_code')}
                    size="small"
                    disabled={
                      censusMember?.relationship !== 'employee' || !censusMember?.relationship
                    }
                    required
                  />
                </Tooltip>
                <DatePicker
                  label="Date of Birth"
                  format="MM/DD/YYYY"
                  value={censusMember.date_of_birth ? moment(censusMember.date_of_birth) : null}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: true,
                    },
                  }}
                  onChange={(value) =>
                    onChangeInput(moment(value).format('MM/DD/YYYY'), 'date_of_birth', 'date')
                  }
                />
                <Tooltip title={tooltipText}>
                  {medicalOptions?.length ? (
                    <div className={classes.ModalContentTobaccoSelect}>
                      <MUISelect
                        label="Current Medical Plan Name"
                        value={censusMember?.current_medical_plan_name}
                        options={medicalOptions}
                        onChange={(event) => onSelectOption(event, 'current_medical_plan_name')}
                        disabled={
                          censusMember?.relationship !== 'employee' || !censusMember?.relationship
                        }
                        required
                      />
                    </div>
                  ) : (
                    <TextField
                      label="Current Medical Plan Name"
                      value={censusMember.current_medical_plan_name}
                      onChange={(event) => onChangeInput(event, 'current_medical_plan_name')}
                      disabled={
                        censusMember?.relationship !== 'employee' || !censusMember?.relationship
                      }
                      size="small"
                    />
                  )}
                </Tooltip>
                <Tooltip title={tooltipText}>
                  {dentalOptions?.length ? (
                    <div className={classes.ModalContentTobaccoSelect}>
                      <MUISelect
                        label="Dental Plan Name"
                        value={censusMember?.dental_plan_name}
                        options={dentalOptions}
                        onChange={(event) => onSelectOption(event, 'dental_plan_name')}
                        disabled={
                          censusMember?.relationship !== 'employee' || !censusMember?.relationship
                        }
                        required
                      />
                    </div>
                  ) : (
                    <TextField
                      label="Dental Plan Name"
                      value={censusMember.dental_plan_name}
                      onChange={(event) => onChangeInput(event, 'dental_plan_name')}
                      disabled={
                        censusMember?.relationship !== 'employee' || !censusMember?.relationship
                      }
                      size="small"
                    />
                  )}
                </Tooltip>
                <Tooltip title={tooltipText}>
                  {visionOptions?.length ? (
                    <div className={classes.ModalContentTobaccoSelect}>
                      <MUISelect
                        label="Vision Plan Name"
                        value={censusMember?.vision_plan_name}
                        options={visionOptions}
                        onChange={(event) => onSelectOption(event, 'vision_plan_name')}
                        disabled={
                          censusMember?.relationship !== 'employee' || !censusMember?.relationship
                        }
                        required
                      />
                    </div>
                  ) : (
                    <TextField
                      label="Vision Plan Name"
                      value={censusMember.vision_plan_name}
                      onChange={(event) => onChangeInput(event, 'vision_plan_name')}
                      disabled={
                        censusMember?.relationship !== 'employee' || !censusMember?.relationship
                      }
                      size="small"
                    />
                  )}
                </Tooltip>

                <TextField
                  label="Life Benefit Amount"
                  value={censusMember.life_benefit_amount}
                  onChange={(event) => onChangeNumberInput(event, 'life_benefit_amount')}
                  type="number"
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                {censusMember?.relationship === 'employee' && (
                  <TextField
                    label="Salary Amount"
                    value={censusMember?.salary_amount}
                    onChange={(event) => onChangeNumberInput(event, 'salary_amount')}
                    type="number"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                )}
                <div className={classes.ModalContentTobaccoSelect}>
                  <MUISelect
                    label="Tobacco User"
                    value={censusMember?.is_tobacco_user ? 'yes' : 'no'}
                    options={tobaccoOptions}
                    onChange={(event) => onSelectOption(event, 'is_tobacco_user')}
                    required
                  />
                </div>
              </div>
              <div className={classes.ModalFooter}>
                <Button
                  title={!_.isEmpty(selectedMember) ? 'Update' : 'Save'}
                  type="primary"
                  buttonType="submit"
                  isLoading={isLoadingCreate || isLoadingUpdate}
                />
                <Button title="Cancel" onClick={handleModal} type="secondary" />
              </div>
            </form>
          </div>
        </Modal>
      )}

      {_.isEmpty(selectedMember) && (
        <div className={classes.AddCensusMember}>
          <Button type="primary" title="Add Member" onClick={handleModal} />
        </div>
      )}
    </>
  );
};
