export const contributionTypeOptions = [
  { title: 'Employer Paid', value: 'employer' },
  { title: 'Voluntary', value: 'voluntary' },
];

export const deathBenefitOptions = [
  { title: '$5,000', value: '5000' },
  { title: '$10,000', value: '10000' },
  { title: '$15,000', value: '15000' },
  { title: '$20,000', value: '20000' },
  { title: '$25,000', value: '25000' },
  { title: '$50,000', value: '50000' },
  { title: '$75,000', value: '75000' },
  { title: '$100,000', value: '100000' },
  { title: '1x Salary', value: '1xsalary' },
  { title: '2x Salary', value: '2xsalary' },
];

export const rateBasisOptions = [
  { title: 'Earnings Amount', value: 'earnings' },
  { title: 'Benefit Amount', value: 'benefit' },
];
