import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { getBrokerData } from 'src/store/broker';
import {
  brokerLicensesSelector,
  createBrokerLicense,
  deleteBrokerLicense,
  getBrokerLicenses,
  isLoadingBrokerLicensesSelector,
  isLoadingUpdateLicenseSelector,
  updateBrokerLicense,
} from 'src/store/brokerLicenses';

import { Button, EmptyField, Input, Loader, LoaderWrapper, SearchSelect } from 'src/components';

import { stateCodes } from 'src/constants/stateCodes';

import classes from './brokerLicenses.module.scss';

export const BrokerLicenses = () => {
  const dispatch = useDispatch();

  const brokerData = useSelector(getBrokerData);
  const brokerLicenses = useSelector(brokerLicensesSelector);

  const isLoadingBrokerLicenses = useSelector(isLoadingBrokerLicensesSelector);
  const isLoadingUpdateLicense = useSelector(isLoadingUpdateLicenseSelector);

  const [createLicenseData, setCreateLicenseData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedEditRow, setSelectedEditRow] = useState({});

  useEffect(() => {
    if (!_.isEmpty(brokerData)) {
      dispatch(getBrokerLicenses({ brokerId: brokerData.id }));
    }
  }, [dispatch, brokerData]);

  const onClickAddLicenseNumber = useCallback(() => {
    const payload = {
      id: uuid(),
      state: '',
      license_number: '',
    };

    setCreateLicenseData((prev) => [...prev, payload]);
  }, []);

  const onChangeInput = useCallback(
    (event, rowField) => {
      const row = createLicenseData.find((item) => item.id === selectedRow.id);
      row[rowField] = event.target.value.trim();

      const payload = createLicenseData.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj[rowField] === o[rowField])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setCreateLicenseData([...payload]);
    },
    [createLicenseData, selectedRow.id],
  );

  const onClickState = useCallback(
    (value, rowField) => {
      let row = createLicenseData.find((item) => item.id === selectedRow.id);
      row[rowField] = value;

      const payload = createLicenseData.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj[rowField] === o[rowField])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setCreateLicenseData([...payload]);
    },
    [createLicenseData, selectedRow.id],
  );

  const onClearState = useCallback(() => {
    let row = createLicenseData.find((item) => item.id === selectedRow.id);
    row['state'] = '';

    const payload = createLicenseData.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id && obj['state'] === o['state'])) {
        unique.push(o);
      }
      return unique;
    }, []);

    setCreateLicenseData([...payload]);
  }, [createLicenseData, selectedRow.id]);

  const onSelectRow = useCallback(
    (row) => () => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const onDeleteRow = useCallback(
    (id) => () => {
      const payload = _.reject(createLicenseData, (row) => row.id === id);

      setCreateLicenseData(payload);
      setSelectedRow({});
    },
    [createLicenseData],
  );

  const onClickSave = useCallback(async () => {
    for (const item of createLicenseData) {
      if (!item.state) {
        return toast('State cannot be empty');
      }

      if (!item.license_number) {
        return toast('License Number cannot be empty');
      }
    }

    let licensePayload = _.cloneDeep(createLicenseData);

    for (const item of licensePayload) {
      delete item.id;
    }

    await dispatch(createBrokerLicense({ brokerId: brokerData.id, payload: licensePayload[0] }));
    setCreateLicenseData([]);
    setSelectedRow({});
  }, [dispatch, brokerData.id, createLicenseData]);

  const onChangeLicenseNumber = useCallback(
    (event) => {
      const payload = {
        ...selectedEditRow,
        license_number: event.target.value.trim(),
      };

      setSelectedEditRow(payload);
    },
    [selectedEditRow],
  );

  const onClickLicenseState = useCallback(
    (value) => {
      const payload = {
        ...selectedEditRow,
        state: value,
      };

      setSelectedEditRow(payload);
    },
    [selectedEditRow],
  );

  const onChangeLicenseState = useCallback(
    (event) => {
      const payload = {
        ...selectedEditRow,
        state: event.target.value.trim(),
      };

      setSelectedEditRow(payload);
    },
    [selectedEditRow],
  );

  const onClearLicenseState = useCallback(() => {
    const payload = {
      ...selectedEditRow,
      state: '',
    };

    setSelectedEditRow(payload);
  }, [selectedEditRow]);

  const onClickEdit = useCallback(
    (item) => () => {
      setSelectedEditRow(item);
    },
    [],
  );

  const onClickSaveRow = useCallback(async () => {
    await dispatch(updateBrokerLicense({ brokerId: brokerData.id, payload: selectedEditRow }));

    setSelectedEditRow({});
  }, [dispatch, brokerData, selectedEditRow]);

  const onClickCancelRow = useCallback(() => {
    setSelectedEditRow({});
  }, []);

  const onClickDeleteLicense = useCallback(
    (licenseId) => () => {
      dispatch(deleteBrokerLicense({ brokerId: brokerData.id, licenseId }));
    },
    [dispatch, brokerData.id],
  );

  return (
    <div className={classes.BrokerLicenses}>
      <div className={classes.BrokerLicensesBody}>
        {isLoadingBrokerLicenses ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            {brokerLicenses?.length || createLicenseData?.length ? (
              <div className={classes.BrokerLicensesBodyTable}>
                <table>
                  <thead>
                    <tr>
                      <th className={classes.BrokerLicensesBodyTableState}>State</th>
                      <th>License Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {brokerLicenses?.map((item) => {
                      const isEditMode =
                        !_.isEmpty(selectedEditRow) && selectedEditRow.id === item.id;

                      return (
                        <tr key={item?.id}>
                          <td>
                            {!isEditMode ? (
                              item?.state
                            ) : (
                              <SearchSelect
                                value={selectedEditRow?.state}
                                onClick={(value) => onClickLicenseState(value)}
                                onChange={(event) => onChangeLicenseState(event)}
                                onClear={onClearLicenseState}
                                options={stateCodes}
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              item?.license_number
                            ) : (
                              <Input
                                value={selectedEditRow.license_number || ''}
                                placeholder="License number"
                                onChange={onChangeLicenseNumber}
                              />
                            )}
                          </td>
                          <td style={{ width: '120px' }}>
                            {!isEditMode ? (
                              <span className={classes.BrokerLicensesBodyTableEdit}>
                                <i
                                  className={`${classes.EditIcon} fas fa-edit`}
                                  onClick={onClickEdit(item)}
                                />
                                <i
                                  className="fa fa-trash-o"
                                  onClick={onClickDeleteLicense(item.id)}
                                />
                              </span>
                            ) : (
                              <span className={classes.BrokerLicensesBodyTableEdit}>
                                <Button
                                  type="primary"
                                  title="Save"
                                  onClick={onClickSaveRow}
                                  isLoading={isLoadingUpdateLicense}
                                />
                                <Button
                                  type="secondary"
                                  title="Cancel"
                                  onClick={onClickCancelRow}
                                />
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {createLicenseData?.map((item) => (
                      <tr key={item?.id}>
                        <td onMouseEnter={onSelectRow(item)}>
                          <SearchSelect
                            value={item?.state}
                            onClick={(value) => onClickState(value, 'state')}
                            onChange={(event) => onChangeInput(event, 'state')}
                            onClear={onClearState}
                            options={stateCodes}
                          />
                        </td>
                        <td>
                          <Input
                            value={item.license_number || ''}
                            placeholder="License number"
                            onFocus={onSelectRow(item)}
                            onChange={(event) => onChangeInput(event, 'license_number')}
                          />
                        </td>
                        <td>
                          <span className={classes.BrokerLicensesBodyTableEdit}>
                            <Button type="primary" title="Save" onClick={onClickSave} />
                            <i className="fa fa-trash-o" onClick={onDeleteRow(item?.id)} />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyField title="No licenses created yet." />
            )}
          </>
        )}
      </div>
      <div className={classes.BrokerLicensesFooter}>
        {!createLicenseData?.length && (
          <Button type="primary" title="Add License Number" onClick={onClickAddLicenseNumber} />
        )}

        {/* {createLicenseData?.length > 0 && (
          <div className={classes.BrokerLicensesFooterSave}>
            <Button type="secondary" title="Cancel" onClick={onClickCancel} />
          </div>
        )} */}
      </div>
    </div>
  );
};
