import React from 'react';

import { AetnaImage, CignaImage, KaiserImage, HumanaImage, UnitedImage } from 'src/assets/pages';
import classes from './carriers.module.scss';

export const Carriers = () => {
  return (
    <div className={classes.Carriers}>
      <div className={classes.CarriersContent}>
        <img src={AetnaImage} alt="AetnaImage" />
        <img src={CignaImage} alt="CignaImage" />
        <img src={KaiserImage} alt="KaiserImage" />
        <img src={HumanaImage} alt="HumanaImage" />
        <img src={UnitedImage} alt="UnitedImage" />
      </div>
    </div>
  );
};
