export const disabilityPlanTypeOptions = [
  { title: 'Short Term Disability', value: 'std' },
  { title: 'Long Term Disability', value: 'ltd' },
];

export const disabilityCompanyContributionOptions = [
  { title: 'Employer Paid', value: 'employer' },
  { title: 'Voluntary', value: 'voluntary' },
];

export const pctIncomeRequestOptions = [
  { title: '60%', value: 60 },
  { title: '66%', value: 66 },
  { title: '70%', value: 70 },
  { title: '20% non integrated', value: 20 },
  { title: '25% non integrated', value: 25 },
  { title: '30% non integrated', value: 30 },
];

export const stdEliminationPeriodOptions = [
  { title: '0/7', value: '0/7' },
  { title: '7/7', value: '7/7' },
  { title: '7/14', value: '7/14' },
  { title: '14/14', value: '14/14' },
  { title: '14/30', value: '14/30' },
  { title: '30/30', value: '30/30' },
];

export const stdBenefitPeriodOptions = [
  { title: '30 Days', value: '30' },
  { title: '60 Days', value: '60' },
  { title: '90 Days', value: '90' },
  { title: '180 Days', value: '180' },
  { title: '1 Year', value: '365' },
  { title: '2 Years', value: '730' },
];

export const ltdEliminationPeriodOptions = [
  { title: '30 Days', value: '30' },
  { title: '60 Days', value: '60' },
  { title: '90 Days', value: '90' },
  { title: '180 Days', value: '180' },
];

export const ltdBenefitPeriodOptions = [
  { title: 'Age 65', value: '65' },
  { title: 'Age 67', value: '67' },
  { title: 'SSRA', value: 'SSRA', tooltip: 'Social Security Retirement Age' },
  { title: '2 years', value: '2' },
  { title: '5 years', value: '5' },
];
