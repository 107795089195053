export const ROUTE = {
  // APP
  DEFAULT: '/',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  BENEFITSQUOTING: '/benefits-quoting',
  PRICING: '/pricing',
  ABOUT: '/about',
  VERIFICATION: '/verification',
  EMAIL_VERIFICATION: '/email-verification',
  NOT_AUTHORIZED: '/not-authorized',

  // AUTH
  LOGIN: '/login',
  ISSUER_LOGIN: '/issuer-login',
  SIGNUP: '/signup',
  ISSUER_SIGNUP: '/issuer-signup',
  FORGOT_PASSWORD: '/forgot-password',
  ISSUER_FORGOT_PASSWORD: '/issuer-forgot-password',

  // ADMIN
  ADMIN_LOGIN: '/admin/login',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_IMPERSONATE: '/admin/impersonate',
  ADMIN_REP_IMPERSONATE: '/admin/rep/impersonate',

  // BROKER
  BROKER: '/broker/accounts',
  PROPOSALS: '/broker/proposals',
  CONTACTS: '/broker/contacts',
  BROKER_SETTIGNS: '/broker/settings',
  BROKER_CREATE: '/broker/accounts/create',
  BROKER_EDIT_ACCOUNT: '/broker/accounts/edit/:id',
  BROKER_ACCOUNT_VIEW: '/broker/accounts/:id',
  BROKER_CREATE_PROPOSAL: '/broker/accounts/:id/proposals/new',
  BROKER_PROPOSAL_CENSUS_NEW: '/broker/accounts/:id/proposals/:proposalId/census/new',

  BROKER_PROPOSAL_ACCOUNT_INFO: '/broker/accounts/:id/proposals/:proposalId/account-info',
  BROKER_PROPOSAL_SETUP: '/broker/accounts/:id/proposals/:proposalId/proposal-setup',
  BROKER_PROPOSAL_CENSUS: '/broker/accounts/:id/proposals/:proposalId/census',
  BROKER_PROPOSAL_QUOTES: '/broker/accounts/:id/proposals/:proposalId/quotes',

  BROKER_PROPOSAL_DOWNLOADS: '/broker/accounts/:id/proposals/:proposalId/full-proposal-download',
  BROKER_WORKSHEET_DOWNLOADS:
    '/broker/accounts/:id/proposals/:proposalId/employee-worksheet-download',

  BROKER_PROPOSAL_FINALIZE: '/broker/accounts/:id/proposals/:proposalId/finalize',

  BROKER_PROPOSAL_VIEW: '/broker/accounts/:id/proposals/:proposalId',

  // AGENCY
  AGENCY: '/teams',
  CREATE_AGENCY: '/teams/create',
  AGENCY_ACCOUNTS: '/teams/:teamId/accounts',
  CREATE_AGENCY_ACCOUNT: '/teams/:teamId/accounts/create',
  EDIT_AGENCY_ACCOUNT: '/teams/:teamId/accounts/edit/:id',
  AGENCY_ACCOUNT_VIEW: '/teams/:teamId/accounts/:id',
  AGENCY_PROPOSALS: '/teams/:teamId/proposals',
  AGENCY_CONTACTS: '/teams/:teamId/contacts',

  AGENCY_CREATE_PROPOSAL: '/teams/:teamId/accounts/:id/proposals/new',
  AGENCY_PROPOSAL_CENSUS_NEW: '/teams/:teamId/accounts/:id/proposals/:proposalId/census/new',
  AGENCY_PROPOSAL_ACCOUNT_INFO: '/teams/:teamId/accounts/:id/proposals/:proposalId/account-info',
  AGENCY_PROPOSAL_SETUP: '/teams/:teamId/accounts/:id/proposals/:proposalId/proposal-setup',
  AGENCY_PROPOSAL_CENSUS: '/teams/:teamId/accounts/:id/proposals/:proposalId/census',
  AGENCY_PROPOSAL_QUOTES: '/teams/:teamId/accounts/:id/proposals/:proposalId/quotes',
  AGENCY_PROPOSAL_FINALIZE: '/teams/:teamId/accounts/:id/proposals/:proposalId/finalize',

  AGENCY_PROPOSAL_VIEW: '/teams/:teamId/accounts/:id/proposals/:proposalId',
  AGENCY_PROPOSAL_CHAT: '/teams/:teamId/accounts/:id/proposals/:proposalId/chat',
  AGENCY_REPORTING: '/teams/:teamId/reporting',
  AGENCY_SETTINGS: '/teams/:teamId/settings',

  // BROKER/AGENCY
  PROPOSAL_CREATE: 'proposals/new',
  PROPOSAL: 'proposals',

  // ISSUER/REP
  ISSUER_SETTINGS: '/issuer/settings',
  ISSUER_REQUESTS: '/issuer/:issuerId/rfp',
  ISSUER_ALL_REQUESTS: '/issuer/:issuerId/rfps',
  ISSUER_REQUEST_VIEW: '/issuer/:issuerId/rfps/accounts/:id/proposals/:proposalId',
  ISSUER_RFPS: '/issuer/:issuerId/rfps',
  REP_MY_RFPS: '/rep/my-rfps',
  REP_BROWSE_BROKERS: '/rep/browse-brokers',
  REP_REQUEST_VIEW: '/rep/my-rfps/accounts/:id/proposals/:proposalId',

  REP_PROPOSAL_ACCOUNT_INFO: '/rep/my-rfps/accounts/:id/proposals/:proposalId/account-info',
  REP_PROPOSAL_CENSUS: '/rep/my-rfps/accounts/:id/proposals/:proposalId/census',
  REP_PROPOSAL_QUOTES: '/rep/my-rfps/accounts/:id/proposals/:proposalId/quotes',
  REP_PROPOSAL_CHAT: '/rep/my-rfps/accounts/:id/proposals/:proposalId/chat',
  REP_PROPOSAL_FINALIZE: '/rep/my-rfps/accounts/:id/proposals/:proposalId/finalize',

  // EMPLOYEE
  READ_ONLY: '/read-only/:token',
  READ_ONLY_CHAT: '/read-only/:token/chat',
};
