import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.proposal;

export const getIsLoadingProposals = createSelector(getState, (state) => state.isLoadingProposals);
export const getProposalsListSelector = createSelector(getState, (state) => state.proposalsList);
export const getProposalsMetaSelector = createSelector(getState, (state) => state.proposalsMeta);

export const getAccountProposalSelector = createSelector(
  getState,
  (state) => state.accountProposal,
);
export const getIsLoadingAccountProposal = createSelector(
  getState,
  (state) => state.isLoadingAccountProposal,
);

export const getCarrierIssuersSelector = createSelector(getState, (state) => state.carrierIssuers);
export const isLoadingCarrierIssuersSelector = createSelector(
  getState,
  (state) => state.isLoadingCarrierIssuers,
);

export const getQuotesRates = createSelector(getState, (state) => state.quotesRates);
export const getQuotesMeta = createSelector(getState, (state) => state.quotesMeta);

export const getResponseQuotes = createSelector(getState, (state) => state.responseQuotes);

export const getSelectedQuotes = createSelector(getState, (state) => state.selectedQuotes);
export const getSelectedEditQuote = createSelector(getState, (state) => state.selectedEditQuote);

export const getIsLoadingQutesRates = createSelector(
  getState,
  (state) => state.isLoadingQuotesRates,
);

export const getIsShareProposalModal = createSelector(
  getState,
  (state) => state.isShareProposalModal,
);
export const getIsLoadingShareProposal = createSelector(
  getState,
  (state) => state.isLoadingShareProposal,
);

export const getMedicalPlanType = createSelector(getState, (state) => state.medicalPlanType);
export const getSelectedMedicalPlans = createSelector(
  getState,
  (state) => state.selectedMedicalPlans,
);

export const getReadOnlyPickedQuote = createSelector(
  getState,
  (state) => state.readOnlyPickedQuote,
);

export const getReadOnlyUser = createSelector(getState, (state) => state.readOnlyUser);

export const getlastChatMessagesSelector = createSelector(
  getState,
  (state) => state.lastChatMessages,
);

export const getChatMessagesSelector = createSelector(getState, (state) => state.chatMessages);
export const getIsSendingMessage = createSelector(getState, (state) => state.isSendingMessage);

export const getBasePlanQuote = createSelector(getState, (state) => state.basePlanQuote);
export const getBasePlanName = createSelector(getState, (state) => state.basePlanName);

export const getIsLoadingMessages = createSelector(getState, (state) => state.isLoadingMessage);
export const getIsLoadingLastMessages = createSelector(
  getState,
  (state) => state.isLoadingLastMessage,
);

export const isLoadingCreateProposalSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateProposal,
);

export const isProposalFinalizeSelector = createSelector(
  getState,
  (state) => state.isProposalFinalize,
);
export const isExpandedQuotesSelector = createSelector(getState, (state) => state.isExpandedQuotes);

export const executiveSummarySelector = createSelector(getState, (state) => state.executiveSummary);
export const isLoadingExecutiveSummarySelector = createSelector(
  getState,
  (state) => state.isLoadingExecutiveSummary,
);
