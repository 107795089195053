import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  getAccountProposalSelector,
  getIsLoadingAccountProposal,
  setIsProposalFinalize,
} from 'src/store/proposals';
import {
  getSharedContacts,
  getSharedContactsSelector,
  getSharedPlans,
  isLoadingSharedPlansSelector,
  isShareModalSelector,
  sendContactsProposalRequest,
  setIsShareModal,
} from 'src/store/planConfiguration';
import { getBrokerData } from 'src/store/broker';
import {
  getAllAgencyContacts,
  getAllBrokerContacts,
  getBrokerContactsMetaSelector,
  getBrokerContactsSelector,
} from 'src/store/contacts';

import { ProposalHeader } from '../proposalHeader/ProposalHeader';
import { MedicalQuotes } from '../../medical/medicalQuotes/MedicalQuotes';
import { DentalQuotes } from '../../dental/dentalQuotes/DentalQuotes';
import { VisionQuotes } from '../../vision/visionQuotes/VisionQuotes';
import { LifeQuotes } from '../../life/lifeQuotes/LifeQuotes';
import { DisabilityQuotes } from '../../disability/disabilityQuotes/DisabilityQuotes';
import { AccidentQuotes } from '../../accident/accidentQuotes/AccidentQuotes';
import { HospitalQuotes } from '../../hospital/hospitalQuotes/HospitalQuotes';
import { CriticalQuotes } from '../../critical/criticalQuotes/CriticalQuotes';
import { ModalContent } from './components';

import { Loader, LoaderWrapper, Modal } from 'src/components';

import classes from './proposalQuotes.module.scss';
import { PetQuotes } from 'src/features/pet/petQuotes/PetQuotes';

export const ProposalQuotes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const brokerData = useSelector(getBrokerData);
  const brokerContacts = useSelector(getBrokerContactsSelector);
  const sharedContacts = useSelector(getSharedContactsSelector);
  const contactsMeta = useSelector(getBrokerContactsMetaSelector);

  const accountProposalData = useSelector(getAccountProposalSelector);
  const isShareModal = useSelector(isShareModalSelector);
  const isLoadingSharedPlans = useSelector(isLoadingSharedPlansSelector);
  const isLoadingAccountProposal = useSelector(getIsLoadingAccountProposal);

  const activeTab = searchParams.get('activeTab');
  const isReadOnlyMode = pathname.includes('/read-only');
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const teamId = params?.teamId;

  const [contactsProductLines, setContactsProductLines] = useState({});

  const [copiedContactsProductLines, setCopiedContactsProductLines] = useState({});
  // const [sortMethod, setSortMethod] = useState('');
  const [sortMethod, setSortMethod] = useState('-last_active');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    contactsMeta?.per_page ? contactsMeta?.per_page : 10,
  );

  useEffect(() => {
    if (!_.isEmpty(brokerData)) {
      const sortPayload = {
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(
          getAllBrokerContacts({
            id: brokerData.id,
            ...sortPayload,
          }),
        );
      }

      if (teamId) {
        dispatch(
          getAllAgencyContacts({
            id: teamId,
            ...sortPayload,
          }),
        );
      }
    }
  }, [dispatch, brokerData, teamId, sortMethod, currentPage, selectedPageSize]);

  useEffect(() => {
    dispatch(getSharedContacts({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  useEffect(() => {
    if (sharedContacts) {
      let updatedContactsMedicalData = {};
      for (const contact of brokerContacts) {
        updatedContactsMedicalData = {
          ...updatedContactsMedicalData,
          [contact.rep_id]: [],
        };
      }

      setContactsProductLines({
        ...sharedContacts,
        ...copiedContactsProductLines,
      });
    }
  }, [sharedContacts, copiedContactsProductLines, teamId, brokerContacts]);

  useEffect(() => {
    if (isShareModal) {
      setContactsProductLines(sharedContacts);
    }
  }, [isShareModal, sharedContacts]);

  useEffect(() => {
    if (isShareModal) {
      dispatch(getSharedPlans({ accountId, proposalId }));
    }
  }, [dispatch, accountId, proposalId, isShareModal]);

  useEffect(() => {
    if (!activeTab) {
      dispatch(setIsProposalFinalize(true));
    }

    if (activeTab) {
      dispatch(setIsProposalFinalize(false));
    }
  }, [dispatch, activeTab]);

  const handleCloseModal = useCallback(() => {
    dispatch(setIsShareModal(false));
    setContactsProductLines({});
    setCopiedContactsProductLines({});
  }, [dispatch]);

  const onSelectContactsMedicalType = useCallback(
    (carrierId, medicalType) => () => {
      const payload = {
        ...contactsProductLines,
        [carrierId]: contactsProductLines[carrierId]?.length
          ? [...contactsProductLines[carrierId]]
          : [],
      };

      payload[carrierId] = [...payload[carrierId], medicalType];

      setCopiedContactsProductLines(payload);
    },
    [contactsProductLines],
  );

  const onRemoveContactsMedicalType = useCallback(
    (carrierId, medicalType) => () => {
      const payload = {
        ...contactsProductLines,
      };

      payload[carrierId] = _.reject(payload[carrierId], (el) => el === medicalType);

      setCopiedContactsProductLines(payload);
    },
    [contactsProductLines],
  );

  const sendContactsMedicalData = useCallback(async () => {
    let payload = { ...contactsProductLines };

    for (const item in payload) {
      if (_.isEmpty(payload[item])) {
        delete payload[item];
      }
    }

    await dispatch(sendContactsProposalRequest({ accountId, proposalId, payload }));
  }, [accountId, contactsProductLines, dispatch, proposalId]);

  const onChangePage = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  const onSendMedicalData = useCallback(async () => {
    await sendContactsMedicalData();
    handleCloseModal();
  }, [handleCloseModal, sendContactsMedicalData]);

  return (
    <>
      {isShareModal && (
        <Modal type="fit" closeButtonType="inside" onClose={handleCloseModal}>
          {isLoadingSharedPlans ? (
            <div className={classes.ProposalQuotesLoader}>
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            </div>
          ) : (
            <ModalContent
              contactsProductLines={contactsProductLines}
              originalContactsData={sharedContacts}
              onSelectContactsMedicalType={onSelectContactsMedicalType}
              onRemoveContactsMedicalType={onRemoveContactsMedicalType}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
              onSendMedicalData={onSendMedicalData}
              currentPage={currentPage}
              selectedPageSize={selectedPageSize}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
              selectedCell={selectedCell}
              setSelectedCell={setSelectedCell}
              cellDirection={cellDirection}
              setCellDirection={setCellDirection}
            />
          )}
        </Modal>
      )}

      {isLoadingAccountProposal ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <div className={classes.ProposalQuotes}>
          <ProposalHeader
            isReadOnlyMode={isReadOnlyMode}
            accountId={accountId}
            proposalId={proposalId}
            isEditable={false}
          />
          {activeTab && (
            <>
              {activeTab === 'medical' && <MedicalQuotes />}
              {activeTab === 'dental' && <DentalQuotes />}
              {activeTab === 'vision' && <VisionQuotes />}
              {activeTab === 'life' && <LifeQuotes />}
              {activeTab === 'disability' && <DisabilityQuotes />}
              {activeTab === 'accident' && <AccidentQuotes />}
              {activeTab === 'hospital' && <HospitalQuotes />}
              {activeTab === 'critical_illness' && <CriticalQuotes />}
              {activeTab === 'pet' && <PetQuotes />}
            </>
          )}
          {!activeTab && (
            <>
              <MedicalQuotes />
              <DentalQuotes />
              <VisionQuotes />
              <LifeQuotes />
              <DisabilityQuotes />
              <AccidentQuotes />
              <HospitalQuotes />
              <CriticalQuotes />
            </>
          )}
        </div>
      )}
    </>
  );
};
