import { createSlice } from '@reduxjs/toolkit';

import {
  getDentalIssuers,
  sendProposalRequest,
  sendContactsProposalRequest,
  getSharedCarriers,
  getSharedContacts,
  resendContactsProposalRequest,
  getSharedPlans,
  getRequestedProposalsStatus,
} from './planConfiguration.actions';

const initialState = {
  dentalIssuers: [],

  sharedCarriers: {},
  sharedContacts: {},

  selectedDentalPlans: [],

  isLoadingDentalIssuers: false,

  isLoadingProposalRequest: false,
  isLoadingContactsProposalRequest: false,

  isLoadingResendContact: false,

  isShareModal: false,
  isLoadingSharedPlans: false,

  requestedProposalsStatus: {},
  isLoadingRequstedProposalsStatus: false,
};

const planConfigurationSlice = createSlice({
  name: 'planConfiguration',
  initialState,
  reducers: {
    setSelectedDentalPlan: (state, action) => {
      state.selectedDentalPlans = action.payload;
    },

    setIsShareModal: (state, action) => {
      state.isShareModal = action.payload;
    },

    clearPlanConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDentalIssuers.pending, (state) => {
      state.isLoadingDentalIssuers = true;
    });
    builder.addCase(getDentalIssuers.fulfilled, (state, action) => {
      state.dentalIssuers = action.payload;
      state.isLoadingDentalIssuers = false;
    });
    builder.addCase(getDentalIssuers.rejected, (state) => {
      state.isLoadingDentalIssuers = false;
    });

    builder.addCase(sendProposalRequest.pending, (state) => {
      state.isLoadingProposalRequest = true;
    });
    builder.addCase(sendProposalRequest.fulfilled, (state) => {
      state.isLoadingProposalRequest = false;
    });
    builder.addCase(sendProposalRequest.rejected, (state) => {
      state.isLoadingProposalRequest = false;
    });

    builder.addCase(sendContactsProposalRequest.pending, (state) => {
      state.isLoadingContactsProposalRequest = true;
    });
    builder.addCase(sendContactsProposalRequest.fulfilled, (state) => {
      state.isLoadingContactsProposalRequest = false;
    });
    builder.addCase(sendContactsProposalRequest.rejected, (state) => {
      state.isLoadingContactsProposalRequest = false;
    });

    builder.addCase(getSharedCarriers.fulfilled, (state, action) => {
      state.sharedCarriers = action.payload;
    });
    builder.addCase(getSharedContacts.fulfilled, (state, action) => {
      state.sharedContacts = action.payload;
    });

    builder.addCase(resendContactsProposalRequest.pending, (state) => {
      state.isLoadingResendContact = true;
    });
    builder.addCase(resendContactsProposalRequest.fulfilled, (state, action) => {
      state.sharedContacts = action.payload;
      state.isLoadingResendContact = false;
    });
    builder.addCase(resendContactsProposalRequest.rejected, (state) => {
      state.isLoadingResendContact = false;
    });

    builder.addCase(getSharedPlans.pending, (state) => {
      state.isLoadingSharedPlans = true;
    });
    builder.addCase(getSharedPlans.fulfilled, (state) => {
      state.isLoadingSharedPlans = false;
    });
    builder.addCase(getSharedPlans.rejected, (state) => {
      state.isLoadingSharedPlans = false;
    });

    builder.addCase(getRequestedProposalsStatus.pending, (state) => {
      state.isLoadingRequstedProposalsStatus = true;
    });
    builder.addCase(getRequestedProposalsStatus.fulfilled, (state, action) => {
      state.requestedProposalsStatus = action.payload;
      state.isLoadingRequstedProposalsStatus = false;
    });
    builder.addCase(getRequestedProposalsStatus.rejected, (state) => {
      state.isLoadingRequstedProposalsStatus = false;
    });
  },
});

export const { setSelectedDentalPlan, setIsShareModal, clearPlanConfigurationState } =
  planConfigurationSlice.actions;

export default planConfigurationSlice.reducer;
