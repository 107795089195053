import { Page, Text, View } from '@react-pdf/renderer';

import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';

import { renderTableRow, renderTableValue } from './renderRow';

import { styles } from '../styles';
import { renderWorksheetRow } from './render';

export const renderVisionPage = (data, fullname, groupMembers) => {
  let counter = 0;

  return Object.keys(data).map((id) => {
    counter++;

    let updatedQuotes = !fullname ? data[id] : [];

    const member = groupMembers?.find((item) => item.relationship === 'employee');

    if (fullname) {
      for (const item of data[id]) {
        let payload = { ...item };

        let totalEmployee = 0;
        let totalEmployer = 0;
        let total = 0;

        for (const groupMember of item.calculation[member.family_id]) {
          if (groupMember.rate_employee) {
            totalEmployee += groupMember.rate_employee;
          }
          if (groupMember.rate_employer) {
            totalEmployer += groupMember.rate_employer;
          }
          if (groupMember.rate_total) {
            total += groupMember.rate_total;
          }
        }

        payload.calculation = {
          ...item.calculation,
          totalEmployee,
          totalEmployer,
          total,
        };

        updatedQuotes.push(payload);
      }
    }

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    const brokerFields = updatedData?.map((section) =>
      section.find((item) => item.issuer_name === 'broker'),
    )[0];

    return updatedData?.map((section, index) => {
      const rate_tier_type_3 = section?.find(
        (item) => item.rate_tier_type === '3tier',
      )?.rate_tier_type;

      const rate_tier_type_4 = section?.find(
        (item) => item.rate_tier_type === '4tier',
      )?.rate_tier_type;

      const tier_3 = rate_tier_type_3 === '3tier';
      const tier_4 = rate_tier_type_4 === '4tier';

      const longest_custom_fields = section?.reduce((prev, current) => {
        return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
      });

      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            Vision RFP Quotes {counter} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Carrier', section, 'issuer_name')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Contribution EE</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  `${item?.contribution_type === 'dollar' ? '$' : ''}${item.contribution_ee}${
                    item?.contribution_type === 'percent' ? '%' : ''
                  }`,
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Contribution DEP</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  `${item?.contribution_type === 'dollar' ? '$' : ''}${item.contribution_dep}${
                    item?.contribution_type === 'percent' ? '%' : ''
                  }`,
                );
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Funding Type</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeFundingType(item.funding_type),
                );
              })}
            </View>
            {renderTableRow('Frequency', section, 'frequency')}
            {renderTableRow('Exam Copay', section, 'exam_copay')}
            {renderTableRow('Materials Copay', section, 'materials_copay')}
            {renderTableRow('Frame Allowance', section, 'frame_allowance')}
            {renderTableRow('Compensation Method', section, 'comp_method')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Compensation Amount</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, normalizeCompensationAmount(item));
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Currently Offered Benefit</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, item.is_current_rate ? 'Yes' : 'No');
              })}
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Custom Fields</Text>
              </View>
            </View>
            {longest_custom_fields?.custom_fields?.map((item, index) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>
                    {brokerFields.custom_fields[index]?.title ?? ''}
                  </Text>
                </View>
                {section?.map((item) => (
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>
                      {item.custom_fields[index]?.value ?? ''}
                    </Text>
                  </View>
                ))}
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Rate Tier Type</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeRateTierType(item.rate_tier_type),
                );
              })}
            </View>
            {renderTableRow('EE', section, 'rate_ee', null, '$')}
            {tier_3 && renderTableRow('EE + 1', section, 'rate_ee_1', null, '$')}
            {tier_4 && (
              <>
                {renderTableRow('EE + SP', section, 'rate_ee_sp', null, '$')}
                {renderTableRow('EE + CH', section, 'rate_ee_ch', null, '$')}
              </>
            )}
            {renderTableRow('FAM', section, 'rate_fam', null, '$')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Employee</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.totalEmployee),
                );
              })}
            </View>
            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      return (
                        <View style={styles.tableCol} key={item.id}>
                          {renderWorksheetRow(groupMember, item, 'rate_employee')}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Employer</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.totalEmployer),
                );
              })}
            </View>
            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      return (
                        <View style={styles.tableCol} key={item.id}>
                          {renderWorksheetRow(groupMember, item, 'rate_employer')}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Total</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.total),
                );
              })}
            </View>
            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      return (
                        <View style={styles.tableCol} key={item.id}>
                          {renderWorksheetRow(groupMember, item, 'rate_total')}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
          </View>
        </Page>
      );
    });
  });
};
