import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  sendPasswordResetEmail,
  getEmailDomains,
  getIsLoadingSendPasswordReset,
} from 'src/store/app';

import { Button, InputV2 } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './forgotPassword.module.scss';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const isIssuer = [ROUTE.ISSUER_FORGOT_PASSWORD].includes(pathname);

  const [email, setEmail] = useState('');

  const isLoading = useSelector(getIsLoadingSendPasswordReset);

  useEffect(() => {
    if (isIssuer && token) {
      dispatch(getEmailDomains(token));
    }
  }, [dispatch, isIssuer, token]);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onClickLogo = useCallback(() => {
    navigate(ROUTE.DEFAULT);
  }, [navigate]);

  const onSubmitPasswordReset = useCallback(
    (event) => {
      event.preventDefault();

      if (!isIssuer) {
        const payload = {
          email,
          navigation: navigate,
          replacedUrl: '/login',
          isIssuer,
        };

        dispatch(sendPasswordResetEmail(payload));
      }
    },
    [isIssuer, email, navigate, dispatch],
  );

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />
      <div className={classes.ForgotPassword}>
        <div className={classes.ForgotPasswordCard}>
          <div className={classes.ForgotPasswordLogo}>
            <img src={MonarkLogo} alt="forgotPasswordMonarkLogo" onClick={onClickLogo} />
          </div>
          <form onSubmit={onSubmitPasswordReset}>
            <InputV2
              label="Email Address"
              value={email}
              type="email"
              onChange={onChangeEmail}
              required
            />

            <Button
              type="primaryPurple"
              size="m"
              buttonType="submit"
              title="Reset Password"
              icon="fas fa-lock"
              iconColor="#fff"
              isLoading={isLoading}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
