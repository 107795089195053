import React, { useEffect } from 'react';

import classes from './terms.module.scss';

export const Terms = () => {
  useEffect(() => {
    document.title = 'Monark | Terms of Service Agreement';
  }, []);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />
      <div className={classes.Terms}>
        <div className={classes.TermsContent}>
          <h3>MonarkHQ Inc Terms of Service Agreement</h3>
          <div>
            <p>
              Welcome to www.MonarkHQ.com (together with any related websites, the “Site”). The Site
              is owned and operated by MonarkHQ, Inc. (“MonarkHQ”). Please read these Terms of
              Service (“Terms”) carefully before using the Site. By accessing or using the Site or
              any of the content on the Site you agree to be legally bound by these Terms. If you do
              not accept these Terms, do not use the Site or any of its Content (defined below).
            </p>
            <p>
              You represent to MonarkHQ that you are lawfully able to enter into contracts (e.g.,
              you are not a minor). If you are entering into these Terms for an entity, such as the
              company you work for, you represent that you have authority to bind that entity and
              you agree that “you” as used in these Terms includes both you personally and the
              entity you represent. You and MonarkHQ are collectively referred to as the “Parties”
              and each is a “Party”. On the site we use the terms “Employer” or “Client” to refer to
              the person or organization that receives the quotes and acts on behalf of the
              employees at a company for which quotes and proposals are generated. “Broker” or
              “Advisor” refers to the individual creating the quotes and proposals to share with the
              Employer or Client. “Carrier” or “Vendor” refers to the organization whose plans are
              represented in the software being displayed for purchase.
            </p>
            <ol>
              <li>
                <span>MonarkHQ Content.</span>
                <p>
                  The Site contains HTML, applications, messages, text, files, images, photos,
                  video, sounds, profiles, works of authorship and other content (collectively,
                  “Content”) of MonarkHQ or its licensors (“MonarkHQ Content”). The Site (including
                  the MonarkHQ Content) is protected by copyright, trademark, trade secret and other
                  laws; and as between you and MonarkHQ, MonarkHQ owns and retains all rights in the
                  Site and the MonarkHQ Content. MonarkHQ hereby grants to you a limited, revocable,
                  non-sublicensable license to access, display and perform the MonarkHQ Content
                  (excluding any computer code) solely for your personal, non-commercial use and
                  solely as necessary to access and use the Site. Except as expressly permitted by
                  MonarkHQ in these Terms or on the Site, you may not copy, download, stream,
                  capture, reproduce, duplicate, archive, upload, modify, translate, create
                  derivative works based upon, publish, broadcast, transmit, retransmit, distribute,
                  perform, display, sell or otherwise use or transfer any MonarkHQ Content. You may
                  not, either directly or through the use of any device, software, online resource
                  or other means, remove, alter, bypass, avoid, interfere with or circumvent any
                  copyright, trademark or other proprietary notice on the MonarkHQ Content or any
                  digital rights management mechanism, device, or other content protection or access
                  control measure associated with the MonarkHQ Content.
                </p>
              </li>
              <li>
                <span>Trademarks.</span>
                <p>
                  The trademarks, logos and service marks (“Marks”) displayed on the Site are the
                  property of MonarkHQ or other third parties. You are not permitted to use these
                  Marks without the prior written consent of MonarkHQ or such third party.
                </p>
              </li>
              <li>
                <span>Third-Party Services.</span>
                <p>
                  The Site may make available, or third parties may provide, links to other
                  websites, applications, resources, advertisements, Content or other products or
                  services created, hosted or made available by third parties (“Third-Party
                  Services”), and such third parties may use other third parties to provide portions
                  of the Third-Party Service to you, such as technology, development or payment
                  services. When you access or use a Third-Party Service, you are interacting with
                  the applicable third party, not with MonarkHQ, and you do so at your own risk.
                  MonarkHQ is not responsible for, and makes no warranties, express or implied, as
                  to, the Third-Party Services or the providers of such Third-Party Services
                  (including without limitation the accuracy or completeness of the information
                  provided by such Third-Party Service or the privacy practices of any third party).
                  Inclusion of any Third-Party Service or a link thereto on the Site does not imply
                  approval or endorsement of such Third-Party Service. MonarkHQ is not responsible
                  or liable for the Content or practices of any Third-Party Service or third party,
                  even if such Third-Party Service links to, or is linked by, the Site.
                </p>
              </li>
              <li>
                <span>Privacy.</span>
                <p>
                  Please review MonarkHQ's Privacy Policy for the Site (“Privacy Policy”), available
                  a https://MonarkHQ.com/privacy, to learn about MonarkHQ's information collection,
                  usage and disclosures practices with respect to information collected by MonarkHQ
                  through the Site.
                </p>
              </li>
              <li>
                <span>Your Content.</span>
                <p>
                  By posting, displaying, publishing or making available for download or use any
                  Content on the Site (other than personal information that is subject to the
                  Privacy Policy), you hereby grant MonarkHQ a perpetual, worldwide, nonexclusive,
                  irrevocable, royalty-free,sublicensable (through multiple tiers) license to
                  perform, display, reproduce, prepare derivative works from, distribute, sell,
                  sublicense, transfer and otherwise use without restriction all or any part of such
                  Content.
                </p>
              </li>
              <li>
                <span>Acceptable Use.</span>
                <p>
                  Your use of the Site is subject to MonarkHQ's Acceptable Use Policy available at
                  https://MonarkHQ.com/privacy. MonarkHQ is not responsible or liable for any user
                  Content or conduct on the Site. If you become aware of any misuse of the Site,
                  please report such misuse immediately to MonarkHQ at legal@MonarkHQ.com.
                </p>
              </li>
              <li>
                <span>Compliance with Laws.</span>
                <p>
                  You represent that, in agreeing to, and performing under, these Terms, you are not
                  violating, and will not violate, any governmental laws, rules, regulations or
                  orders that are applicable to your use of the Site (“Applicable Laws”). Without
                  limiting the foregoing, you represent that, in connection with your performance
                  under these Terms, you shall: (a) comply with Applicable Laws relating to
                  anti-bribery and anti-corruption, which may include the US Foreign Corrupt
                  Practices Act of 1977 and the UK Bribery Act 2010; (b) comply with Applicable Laws
                  administered by the U.S. Commerce Bureau of Industry and Security, U.S. Treasury
                  Office of Foreign Assets Control or other governmental entity imposing export
                  controls and trade sanctions (“Export Laws”), including designating countries,
                  entities and persons (“Sanctions Targets”); and (c) not directly or indirectly
                  export, re-export or otherwise deliver any MonarkHQ software, content or services
                  to a Sanctions Target, or broker, finance or otherwise facilitate any transaction
                  in violation of any Export Laws. You represent that you are not a Sanctions Target
                  or prohibited from receiving MonarkHQ software, content or services pursuant to
                  these Terms under Applicable Laws, including Export Laws.
                </p>
              </li>
              <li>
                <span>Global Availability.</span>
                <p>
                  MonarkHQ controls the Site from its Los Angeles, CA, USA offices. If you use this
                  website from other locations, you are responsible for compliance with local
                  Applicable Laws. MonarkHQ makes no representation that the products and services
                  referenced herein are appropriate, or available, worldwide.
                </p>
              </li>
              <li>
                <span>Indemnity.</span>
                <p>
                  You agree to defend, indemnify and hold harmless MonarkHQ, its affiliates and
                  their respective employees, contractors, agents, officers and directors from and
                  against any and all claims, damages, obligations, losses, liabilities, costs, debt
                  or expenses (including without limitation attorneys' fees) arising out of or
                  related to any claim, suit, action or proceeding by a third party arising out of
                  or relating to your use of the Site, breach of these Terms (including any MonarkHQ
                  policy referenced in these Terms), violation of law, or any Content that you post,
                  upload or cause to interface with the Site, or otherwise transfer, process, use or
                  store in connection with the Site.
                </p>
              </li>
              <li>
                <span>Disclaimers.</span>
                <p>
                  THE SITE AND ALL CONTENT AND OTHER ITEMS MADE AVAILABLE BY MonarkHQ ARE PROVIDED
                  ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF
                  TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, TO
                  THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. MonarkHQ ASSUMES NO LIABILITY OR
                  RESPONSIBILITY FOR ANY (i) ERRORS, MISTAKES OR INACCURACIES OF DATA OR INFORMATION
                  POSTED, DISPLAYED, PUBLISHED OR MADE AVAILABLE FOR DOWNLOAD OR USE ON THE SITE,
                  (ii) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                  USE OF THE SITE, (iii) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
                  THE SITE, OR (iv) THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD PARTY
                  NOT UNDER MonarkHQ'S CONTROL.
                </p>
              </li>
              <li>
                <span>Limitation of Liability.</span>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (a) IN NO EVENT SHALL MonarkHQ,
                  ITS AFFILIATES OR THEIR RESPECTIVE EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS OR
                  DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                  OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR BUSINESS
                  INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
                  ARISING OUT OF OR RELATING TO THE SITE; AND (b) IN NO EVENT SHALL MonarkHQ'S
                  CUMULATIVE AND AGGREGATE LIABILITY UNDER THESE TERMS EXCEED TWO HUNDRED U.S.
                  DOLLARS. THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION APPLY WHETHER THE ALLEGED
                  LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER
                  BASIS, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
                </p>
              </li>
              <li>
                <span>Responsibility for End Users.</span>
                <p>
                  You are responsible for violations of these Terms by anyone using the Site with
                  your permission or using your account on an unauthorized basis. Your use of the
                  Site to assist another person in an activity that would violate these Terms if
                  performed by you is a violation of these Terms. These Terms apply to anyone
                  accessing or using the Site; however, each provision in these Terms shall be
                  interpreted to include, and apply to, any action directly or indirectly taken,
                  authorized, facilitated, promoted, encouraged or permitted by a user of the Site,
                  even if such person did not themselves violate the provision.
                </p>
              </li>
              <li>
                <span>Employment Opportunities.</span>
                <p>
                  MonarkHQ may, from time to time, post employment opportunities on the Site and/or
                  invite users to submit resumes to MonarkHQ. If you choose to submit your name,
                  contact information, resume and/or other personal information to MonarkHQ in
                  response to such employment listings, you are authorizing MonarkHQ to use this
                  information for all lawful and legitimate hiring, employment and other business
                  purposes. MonarkHQ also reserves the right, at its discretion, to forward such
                  information to MonarkHQ's affiliates for their legitimate business purposes.
                  Nothing in these Terms or contained on the Site will constitute a promise by
                  MonarkHQ to review any such information, or to contact, interview or employ any
                  individual who submits such information.
                </p>
              </li>
              <li>
                <span>Digital Millennium Copyright Act.</span>
                <ol>
                  <li>
                    The Digital Millennium Copyright Act of 1998 (“DMCA”) provides recourse for
                    copyright owners who believe that material appearing on the Internet infringes
                    their rights under U.S. copyright law. If you believe that any material residing
                    on or linked to from the Site infringes your copyright, please send (or have
                    your agent send) to MonarkHQ's Copyright Agent a notification of claimed
                    infringement with all of the following information: (a) identification of the
                    copyrighted work claimed to have been infringed, or, if multiple copyrighted
                    works are covered by a single notification, a representative list of such works;
                    (b) identification of the claimed infringing material and information reasonably
                    sufficient to permit MonarkHQ to locate the material on the Site (such as the
                    URL(s) of the claimed infringing material); (c) information reasonably
                    sufficient to permit MonarkHQ to contact you, including an address, telephone
                    number and, if available, an email address; (d) the following statement: “I have
                    a good faith belief that use of the copyrighted materials described above as
                    allegedly infringing is not authorized by the copyright owner, its agent, or the
                    law”; (e) the following statement: “I swear, under penalty of perjury, that the
                    information in the notification is accurate and that I am the copyright owner or
                    am authorized to act on behalf of the owner of an exclusive right that is
                    allegedly infringed”; and (f) your physical or electronic signature.
                  </li>
                  <li>
                    To file a counter notification with MonarkHQ, please provide the following
                    information to MonarkHQ's Copyright Agent: (a) a description of the material
                    that MonarkHQ has removed or to which MonarkHQ has disabled access; (b)
                    information reasonably sufficient to permit MonarkHQ to contact you, including
                    an address, telephone number and, if available, an email address, and a
                    statement that you consent to the jurisdiction of the Federal District Court for
                    the judicial district in which your address is located (or the Central District
                    of California if your address is outside of the United States), and that you
                    will accept service of process from the person who provided notification of
                    infringement or an agent of such person; (c) the following statement: “I swear,
                    under penalty of perjury, that I have a good faith belief that the material
                    identified above was removed or disabled as a result of a mistake or
                    misidentification of the material to be removed or disabled”; and (d) your
                    physical or electronic signature.
                  </li>
                  <li>
                    MonarkHQ's Copyright Agent for notification of claimed infringement can be
                    reached as follows: Copyright Agent, MonarkHQ, Inc., Attn: Legal, 1925 Century
                    Park East, 17th FL, Los Angeles, CA, 90067. MonarkHQ's Copyright Agent for
                    notification of claimed infringement can also be reached electronically at:
                    legal@MonarkHQ.com. MonarkHQ reserves the right to terminate infringers' and
                    suspected infringers' access to or use of the Site.
                  </li>
                </ol>
              </li>
              <li>
                <span>U.S. Government Rights.</span>
                <p>
                  The Site is provided to the U.S. Government as “commercial items,” “commercial
                  computer software,” “commercial computer software documentation,” and “technical
                  data” with the same rights and restrictions generally applicable to the Site. If
                  you are using the Site on behalf of the U.S. Government and these terms fail to
                  meet the U.S. Government's needs or are inconsistent in any respect with federal
                  law, you must immediately discontinue use of the Site. The terms listed above are
                  defined in the Federal Acquisition Regulation and the Defense Federal Acquisition
                  Regulation Supplement.
                </p>
              </li>
              <li>
                <span>Notice for California Residents.</span>
                <p>
                  Under California Civil Code Section 1789.3, California users are entitled to the
                  following consumer rights notice: MonarkHQ does not currently charge any fees for
                  Broker or Employer access and use of the Site. If you have a question or complaint
                  regarding the Site, please contact MonarkHQ by writing to: MonarkHQ, Inc., Attn:
                  Legal, 1925 Century Park East, 17th FL, Los Angeles, CA, 90067. California
                  residents may reach the Complaint Assistance Unit of the Division of Consumer
                  Services of the California Department of Consumer Affairs by mail at 1625 North
                  Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445–1254 or (800)
                  952–5210.
                </p>
              </li>
              <li>
                <span>Termination and Monitoring.</span>
                <ol>
                  <li>
                    If you violate these Terms, MonarkHQ may suspend or terminate your use of the
                    Site. MonarkHQ's right to suspend or terminate your use of Site applies even if
                    a breach is committed unintentionally or without your authorization if MonarkHQ
                    believes that suspension or termination is necessary to ensure compliance with
                    Applicable Laws or to protect the rights, safety, privacy, security or property
                    of MonarkHQ, its customers or third parties.
                  </li>
                  <li>
                    MonarkHQ reserves the right, but does not assume the obligation, to investigate
                    any violation of these Terms or misuse of the Site. MonarkHQ has the right in
                    its sole discretion to edit, refuse to post or remove any Content posted,
                    displayed, published or made available for download or use on the Site that
                    MonarkHQ finds to be in violation of these Terms. MonarkHQ may report any
                    activity that it suspects violates any law or regulation to appropriate law
                    enforcement officials, regulators, or other appropriate third parties. Such
                    reporting may include disclosing appropriate customer data. MonarkHQ also may
                    cooperate with appropriate law enforcement agencies, regulators, or other
                    appropriate third parties to help with the investigation and prosecution of
                    illegal conduct by providing network and systems information related to alleged
                    violations.
                  </li>
                </ol>
              </li>
              <li>
                <span>Electronic Communications.</span>
                <p>
                  When you visit the Site or send emails to MonarkHQ, you are communicating with
                  MonarkHQ electronically; and you consent to receive communications from MonarkHQ
                  electronically. MonarkHQ will communicate with you by email or by posting notices
                  on the Site. You agree that all agreements, notices, disclosures and other
                  communications that MonarkHQ provides to you electronically satisfy any legal
                  requirement that such communications be in writing.
                </p>
              </li>
              <li>
                <span>Modifications.</span>
                <p>
                  MonarkHQ may modify these Terms at any time by posting a revised version on the
                  Site. By accessing the Site, you agree to the latest version of these Terms.
                </p>
              </li>
              <li>
                <span>Governing Law.</span>
                <p>
                  This Agreement will be interpreted, construed and enforced in all respects in
                  accordance with the laws of the State of California, without reference to its
                  choice of law principles. Any legal action or proceeding arising under or relating
                  to this Agreement shall be brought exclusively in the state or federal courts
                  located in Los Angeles county, CA, USA, and the Parties expressly consent to
                  personal jurisdiction and venue in those courts.
                </p>
              </li>
              <li>
                <span>Miscellaneous.</span>
                <p>
                  These Terms constitute the complete and exclusive statement of the agreement
                  between the Parties and supersede all proposals, oral or written, and all other
                  communications between the Parties relating to the subject matter of these Terms.
                  In the event any information posted on the Site from time to time conflicts with
                  any provision of these Terms, the applicable provision of these Terms shall
                  control. Any terms and conditions of any other instrument issued by you in
                  connection with these Terms which are in addition to, inconsistent with or
                  different from these Terms shall be of no force or effect. These Terms do not, and
                  shall not be construed to, create any partnership, joint venture,
                  employer-employee, agency or franchisor-franchisee relationship between you and
                  MonarkHQ. You may not assign, transfer or sublicense all or any of your rights or
                  obligations under these Terms without MonarkHQ's express prior written consent.
                  MonarkHQ may assign, transfer or sublicense all or any of MonarkHQ's rights or
                  obligations under these Terms without restriction. The failure of MonarkHQ to
                  exercise or enforce any condition, term or provision of these Terms will not
                  operate as a waiver of such condition, term or provision. Any waiver by MonarkHQ
                  of any condition, term or provision of these Terms shall not be construed as a
                  waiver of any other condition, term or provision. If any provision of these Terms
                  is held invalid or unenforceable, the remainder of these Terms shall continue in
                  full force and effect. You agree that a printed version of these Terms and of any
                  notice given in electronic form shall be admissible in judicial or administrative
                  proceedings based upon or relating to these Terms to the same extent and subject
                  to the same conditions as other business documents and records originally
                  generated and maintained in printed form. MonarkHQ will not be responsible for
                  failures to fulfill any obligations due to causes beyond its control. Non-English
                  translations of these Terms are provided for convenience only. In the event of any
                  ambiguity or conflict between translations, the English version is authoritative
                  and controls.
                </p>
              </li>
              <li>
                <span>Contact.</span>
                <p>
                  Please contact MonarkHQ at legal@MonarkHQ.com with any questions regarding these
                  Terms.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
