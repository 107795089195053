import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.browseBrokers;

export const issuerBrokersSelector = createSelector(getState, (state) => state.issuerBrokers);
export const issuerBrokersMetaSelector = createSelector(
  getState,
  (state) => state.issuerBrokersMeta,
);
export const isLoadingIssuerBrokersSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerBrokers,
);
