import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { setIssuerBrokersMeta } from './browseBrokers.slice';

export const getIssuerBrokers = createAsyncThunk(
  'browse-brokers/get-issuer-brokers',
  async (data, thunkApi) => {
    try {
      const { page, per_page, sort, q, due_date, coverage_type } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      let payload = { page, per_page, sort, q, due_date, coverage_type };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.browseBrokers.getIssuerBrokers(payload);

      thunkApi.dispatch(setIssuerBrokersMeta(response?.meta));

      return response.items;
    } catch (err) {
      console.warn(err);
    }
  },
);
