import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeActiveTab: '',
};

const EmployeeSlice = createSlice({
  name: 'Employee',
  initialState,
  reducers: {
    setEmployeeActiveTab: (state, action) => {
      state.employeeActiveTab = action.payload;
    },

    clearEmployeeState: () => initialState,
  },
});

export const { setEmployeeActiveTab, clearEmployeeState } = EmployeeSlice.actions;

export default EmployeeSlice.reducer;
