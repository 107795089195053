import React from 'react';

import { AdminBrokersTable, PageHeader } from 'src/components';

import classes from './adminImpersonate.module.scss';

export const AdminImpersonate = () => {
  return (
    <div className={classes.AdminImpersonate}>
      <PageHeader title="Broker Impersonate" />
      <AdminBrokersTable />
    </div>
  );
};
