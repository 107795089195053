export const normalizePlanLevel = (level) => {
  if (level === 'bronze') {
    return ['bronze', 'expanded_bronze'];
  } else {
    return level ? [level] : [];
  }
};

export const normalizePlanType = (type) => {
  if (type === 'PPO') {
    return 'PPO, POS';
  } else {
    return type ? [type] : [];
  }
};
