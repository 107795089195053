import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

export const createAccountContact = createAsyncThunk(
  'account-contacts/create-account-contact',
  async (data, thunkApi) => {
    try {
      const { accountId, payload, create_account } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.accountContacts.createAccountContact(accountId, payload);

      thunkApi.dispatch(getAccountContacts({ accountId }));

      if (!create_account) {
        toast('Contact was created successfully', { type: 'success' });
      }

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const getAccountContacts = createAsyncThunk(
  'account-contacts/get-account-contacts',
  async (data, thunkApi) => {
    try {
      const { accountId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.accountContacts.getAccountContacts(accountId);

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const updateAccountContact = createAsyncThunk(
  'account-contacts/update-account-contact',
  async (data, thunkApi) => {
    try {
      const { accountId, contactId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.accountContacts.updateAccountContact(
        accountId,
        contactId,
        payload,
      );

      thunkApi.dispatch(getAccountContacts({ accountId }));

      toast('Contact was updated successfully', { type: 'success' });

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const deleteAccountContact = createAsyncThunk(
  'account-contacts/delete-account-contact',
  async (data, thunkApi) => {
    try {
      const { accountId, contactId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.accountContacts.deleteAccountContact(accountId, contactId);

      thunkApi.dispatch(getAccountContacts({ accountId }));

      toast('Contact was deleted successfully', { type: 'success' });

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);
