import { Page, Text, View } from '@react-pdf/renderer';

import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';
import { currencyFormatter } from 'src/constants/currency';
import { HOSPITAL_FORM_NAMES } from 'src/constants/formNames';
import {
  COVERAGE_ALL_AGES_NAMES,
  coverageAllAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  COVERAGE_FIVE_YEAR_NAMES,
  coverageFiveYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  COVERAGE_TEN_YEAR_NAMES,
  coverageTenYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

import { renderTableRow, renderTableValue } from './renderRow';
import { renderWorksheetRow } from './render';

import { styles } from '../styles';

export const renderHospitalPage = (data, fullname, groupMembers) => {
  let counter = 0;

  return Object.keys(data).map((id) => {
    counter++;

    let updatedQuotes = !fullname ? data[id] : [];

    const member = groupMembers?.find((item) => item.relationship === 'employee');

    if (fullname) {
      for (const item of data[id]) {
        let payload = { ...item };

        let total = 0;

        if (!item.rate_tier_type.includes('tier')) {
          total += item.calculation[member.id];
        }

        if (item.rate_tier_type.includes('tier')) {
          if (member.relationship === 'employee') {
            total += item.calculation[member.family_id].rate;
          }
        }

        payload.calculation = {
          ...item.calculation,
          total,
        };

        updatedQuotes.push(payload);
      }
    }

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    const renderAgeRates = (rates, NAMES) => {
      return Object.keys(rates).map((item) => (
        <Text>
          ${rates[item] || '0'} ({NAMES[item]})
        </Text>
      ));
    };

    const brokerFields = updatedData?.map((section) =>
      section.find((item) => item.issuer_name === 'broker'),
    )[0];

    return updatedData?.map((section, index) => {
      const rate_tier_type_3 = section?.find(
        (item) => item.rate_tier_type === '3tier',
      )?.rate_tier_type;

      const rate_tier_type_4 = section?.find(
        (item) => item.rate_tier_type === '4tier',
      )?.rate_tier_type;

      const rate_tier_type_all = section?.find(
        (item) => item.rate_tier_type === 'all_ages',
      )?.rate_tier_type;

      const rate_tier_type_five = section?.find(
        (item) => item.rate_tier_type === 'banded_5',
      )?.rate_tier_type;

      const rate_tier_type_ten = section?.find(
        (item) => item.rate_tier_type === 'banded_10',
      )?.rate_tier_type;

      const tier_3 = rate_tier_type_3 === '3tier';
      const tier_4 = rate_tier_type_4 === '4tier';

      const all_ages = rate_tier_type_all === 'all_ages';
      const banded_5 = rate_tier_type_five === 'banded_5';
      const banded_10 = rate_tier_type_ten === 'banded_10';

      const longest_custom_fields = section?.reduce((prev, current) => {
        return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
      });

      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            Hospital RFP Quotes {counter} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Carrier', section, 'issuer_name')}
            {renderTableRow(
              HOSPITAL_FORM_NAMES.initial_hospital_confinement,
              section,
              'initial_hospital_confinement',
              null,
              '$',
            )}
            {renderTableRow(
              HOSPITAL_FORM_NAMES.per_day_hospitalization,
              section,
              'per_day_hospitalization',
              null,
              '$',
            )}
            {renderTableRow('Compensation Method', section, 'comp_method')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Compensation Amount</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, normalizeCompensationAmount(item));
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Currently Offered Benefit</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, item.is_current_rate ? 'Yes' : 'No');
              })}
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Custom Fields</Text>
              </View>
            </View>
            {longest_custom_fields?.custom_fields?.map((item, index) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>
                    {brokerFields.custom_fields[index]?.title ?? ''}
                  </Text>
                </View>
                {section?.map((item) => (
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>
                      {item.custom_fields[index]?.value ?? ''}
                    </Text>
                  </View>
                ))}
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Rate Tier Type</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeRateTierType(item.rate_tier_type),
                );
              })}
            </View>
            {(tier_3 || tier_4) && (
              <>
                {renderTableRow('EE', section, 'rate_ee', null, '$')}
                {tier_3 && renderTableRow('EE + 1', section, 'rate_ee_1', null, '$')}
                {tier_4 && (
                  <>
                    {renderTableRow('EE + SP', section, 'rate_ee_sp', null, '$')}
                    {renderTableRow('EE + CH', section, 'rate_ee_ch', null, '$')}
                  </>
                )}
                {renderTableRow('FAM', section, 'rate_fam', null, '$')}
              </>
            )}
            {(all_ages || banded_5 || banded_10) && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>Rates</Text>
                </View>
                {section?.map((item, index) => {
                  const allAgesRates = coverageAllAges(item);
                  const fiveYearAgesRates = coverageFiveYearAges(item);
                  const tenYearAgesRates = coverageTenYearAges(item);

                  return (
                    <View style={styles.tableCol}>
                      {item.rate_tier_type === 'all_ages' &&
                        renderAgeRates(allAgesRates, COVERAGE_ALL_AGES_NAMES)}
                      {item.rate_tier_type === 'banded_5' &&
                        renderAgeRates(fiveYearAgesRates, COVERAGE_FIVE_YEAR_NAMES)}
                      {item.rate_tier_type === 'banded_10' &&
                        renderAgeRates(tenYearAgesRates, COVERAGE_TEN_YEAR_NAMES)}
                    </View>
                  );
                })}
              </View>
            )}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Total</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.total),
                );
              })}
            </View>
            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      return (
                        <View style={styles.tableCol} key={item.id}>
                          {renderWorksheetRow(groupMember, item, 'rate', 'employee')}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
          </View>
        </Page>
      );
    });
  });
};
