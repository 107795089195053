import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'src/assets/broker/arrow.svg';
import classes from './selectedOfferCard.module.scss';

export const SelectedOfferCard = (props) => {
  const { type = 'default', children } = props;

  const [isVisibleContent, setIsVisibleContent] = useState(true);

  const hadleOpenContent = useCallback(() => {
    setIsVisibleContent((prev) => !prev);
  }, []);

  return (
    <div className={classes.SelectedOfferCard}>
      {type === 'default' && (
        <div className={classes.SelectedOfferCardHeader}>
          <div className={classes.SelectedOfferCardHeaderWrapper} onClick={hadleOpenContent}>
            <div className={classes.SelectedOfferCardHeaderImageWrapper}>
              <img
                src={ArrowIcon}
                alt={ArrowIcon}
                className={
                  isVisibleContent
                    ? classes.SelectedOfferCardHeaderArrowActive
                    : classes.SelectedOfferCardHeaderArrow
                }
              />
            </div>
            Selected Offers
          </div>
        </div>
      )}
      {isVisibleContent && <>{children}</>}
    </div>
  );
};

SelectedOfferCard.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};
