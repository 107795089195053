import React from 'react';

import classes from './detailsItem.module.scss';

export const DetailsItem = (props) => {
  const { title, value, contactType, isDelete, onDeleteEmail } = props;

  return (
    <tr className={classes.DetailsItem}>
      <th className={classes.DetailsItemTitle}>{title}</th>
      <td className={classes.DetailsItemValue}>{value}</td>
      {isDelete && (
        <>
          <td className={classes.DetailsItemContactType}>{contactType}</td>
          {contactType !== 'primary' && contactType !== 'broker' ? (
            <td className={classes.DetailsItemTrashIcon} onClick={onDeleteEmail}>
              <i className="fa fa-trash-o" />
            </td>
          ) : (
            <td className={classes.DetailsItemTrashIcon} />
          )}
        </>
      )}
    </tr>
  );
};
