export const ACCIDENT_FORM_NAMES = {
  plan_type: '24 hour / Off the Job',
  hospital_admission: 'Hospital Admission',
  icu_confinement: 'ICU Confinement',
  daily_hospital_confinement: 'Daily Hospital Confinement',
  confinement_days_covered: 'Confinement Days Covered',
  daily_icu_confinement: 'Daily ICU Confinement',
  icu_days_covered: 'ICU Days Covered',
  accidental_death: 'Accidental Death',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const HOSPITAL_FORM_NAMES = {
  initial_hospital_confinement: 'Initial Hospital Confinement',
  per_day_hospitalization: 'Per Day Hospitalization',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const CRITICAL_FORM_NAMES = {
  lump_sum_benefits: 'Lump Sum Benefit',
  gi_amount: 'GI Amount',
  include_cancer: 'Include Cancer',
  wellness_benefit: 'Wellness Benefit',
  recurrence_benefit: 'Recurrance Benefit',
  spouse_coverage: 'Spouse Coverage',
  child_coverage: 'Child Coverage',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};
