import React from 'react';

import { AdminBrokersTable } from 'src/components';

import classes from './dashboardBrokers.module.scss';

export const DashboardBrokers = () => {
  return (
    <div className={classes.DashboardBrokers}>
      <div className={classes.DashboardBrokersTitle}>Recent Active Brokers</div>
      <div className={classes.DashboardBrokersContent}>
        <AdminBrokersTable isImpersonate={false} />
      </div>
    </div>
  );
};
