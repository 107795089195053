export const medicalRenewalRates = (members) => {
  let renewalRates = {};

  for (const member of members) {
    renewalRates[member.current_medical_plan_name] = {};
  }

  const quoteMappingKeys = Object.keys(renewalRates);

  for (const key of quoteMappingKeys) {
    if (!key) {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'null') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waive') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waived') {
      delete renewalRates[key];
    }
  }

  return Object.keys(renewalRates);
};

export const dentalRenewalRates = (members) => {
  let renewalRates = {};

  for (const member of members) {
    renewalRates[member.dental_plan_name] = {};
  }

  const quoteMappingKeys = Object.keys(renewalRates);

  for (const key of quoteMappingKeys) {
    if (!key) {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'null') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waive') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waived') {
      delete renewalRates[key];
    }
  }

  return Object.keys(renewalRates);
};

export const visionRenewalRates = (members) => {
  let renewalRates = {};

  for (const member of members) {
    renewalRates[member.vision_plan_name] = {};
  }

  const quoteMappingKeys = Object.keys(renewalRates);

  for (const key of quoteMappingKeys) {
    if (!key) {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'null') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waive') {
      delete renewalRates[key];
    }

    if (key.toLowerCase() === 'waived') {
      delete renewalRates[key];
    }
  }

  return Object.keys(renewalRates);
};
