export const rateRegex = /^\d*([0-9]{0,100}\.\d[0-9]{0,100})?$/;

export const rateValueRegex = /^\d*(\.\d{0,2})?$/g;
export const threeDigitsRegex = /^\d*(\.\d{0,3})?$/g;

export const totalPremiumRegex = /(\.\d{0,2})?$/g;

export const inNetworkRegex = /In-Network: (.+?) \//;

export const lifeInputRegex = /^\d*(\.\d{0,4})?$/g;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const frequencyRegex = /\d{1,2}\/\d{1,2}\/\d{1,2}/g;

export const stdEliminationPeriodRegex = /\d{1,2}\/\d{1,2}/g;

// eslint-disable-next-line no-useless-escape
export const worksheetNameRegex = /[-[\]{}()*+?.,\\^$&|#\s\/:]/g;

export const phoneE164Regex = /^\+\d{1,2} \(\d{3}\) \d{3}-\d{4}$/g;
