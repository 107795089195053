import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useDisabilityBuild } from './useDisabilityBuild/useDisabilityBuild';

export const DisabilityQuotes = () => {
  const { build } = useDisabilityBuild();

  return <CoverageBuilder build={build} />;
};
