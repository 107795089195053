import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCookies } from 'react-cookie';
import _ from 'lodash';

import { auth } from 'src/app/database';

import { setRepAccountData } from 'src/store/issuer';
import { logout, getUserTypeSelector, setUserType } from 'src/store/app';
import { setBrokerData } from 'src/store/broker';

import { ROUTE } from 'src/constants/routes';

import { BurgerButton, Button } from 'src/components';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './header.module.scss';

export const Header = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const [, , removeCookie] = useCookies(['is_impersonate']);
  const userType = useSelector(getUserTypeSelector);

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);

  const isHiddenHeader = [
    ROUTE.SIGNUP,
    ROUTE.LOGIN,
    ROUTE.FORGOT_PASSWORD,
    ROUTE.ISSUER_LOGIN,
    ROUTE.ISSUER_SIGNUP,
    ROUTE.ISSUER_FORGOT_PASSWORD,
    ROUTE.VERIFICATION,
    ROUTE.EMAIL_VERIFICATION,
    ROUTE.NOT_AUTHORIZED,
  ].includes(pathname);

  const isBroker =
    pathname.includes(ROUTE.BROKER) ||
    pathname.includes(ROUTE.PROPOSALS) ||
    pathname.includes(ROUTE.BROKER_SETTIGNS) ||
    pathname.includes(ROUTE.CONTACTS);

  const isEmailVerification = [ROUTE.EMAIL_VERIFICATION].includes(pathname);

  const isIssuer = ['issuer', 'rep'].includes(pathname.split('/')[1]);
  const isHiddenQuoting = pathname.includes('/pricing') || pathname.includes('/about');

  const isReadOnlyMode = pathname.includes('/read-only');

  const onNavigateTo = useCallback(
    (route) => () => {
      setIsVisibleMenu(false);
      navigate(route);
    },
    [navigate],
  );

  const onClickLogout = useCallback(() => {
    dispatch(setBrokerData({}));
    dispatch(setRepAccountData({}));
    dispatch(setUserType(''));
    dispatch(logout(navigate));
    removeCookie('is_impersonate', { path: '/' });
  }, [dispatch, navigate, removeCookie]);

  const onClickBurgerButton = useCallback(() => {
    setIsVisibleMenu((prev) => !prev);
  }, []);

  const onClickBenefitsQuoting = useCallback(() => {
    document.getElementById('benefits_quoting').scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={classes.Header}>
      <div className={classes.HeaderWrapper}>
        {isEmailVerification && (
          <div className={classes.HeaderEmailVerification}>
            <Button title="Log Out" type="primary" onClick={onClickLogout} />
          </div>
        )}
        {!isHiddenHeader && (
          <>
            <div className={classes.HeaderLogo}>
              <img
                src={MonarkLogo}
                alt="MonarkLogo"
                onClick={onNavigateTo(isBroker ? ROUTE.BROKER : ROUTE.DEFAULT)}
              />
            </div>

            {!isReadOnlyMode && (
              <>
                <div className={classes.HeaderButtons}>
                  {!isHiddenQuoting && (
                    <div className={classes.ButtonWrapper}>
                      <Button title="Benefits Quoting" onClick={onClickBenefitsQuoting} />
                    </div>
                  )}
                  <div className={classes.ButtonWrapper}>
                    <Button title="Pricing" onClick={onNavigateTo(ROUTE.PRICING)} />
                  </div>
                  {_.isEmpty(user) && (
                    <div className={classes.ButtonWrapper}>
                      <Button
                        title="Sign in"
                        onClick={
                          isIssuer ? onNavigateTo(ROUTE.ISSUER_LOGIN) : onNavigateTo(ROUTE.LOGIN)
                        }
                      />
                    </div>
                  )}
                </div>
                {_.isEmpty(user) && (
                  <>
                    <div className={classes.ButtonWrapper}>
                      <Button
                        title="Sign Up"
                        type="primaryBlack"
                        size="m"
                        onClick={
                          isIssuer ? onNavigateTo(ROUTE.ISSUER_SIGNUP) : onNavigateTo(ROUTE.SIGNUP)
                        }
                      />
                    </div>
                  </>
                )}
                {!_.isEmpty(user) && (
                  <div className={classes.ButtonWrapper}>
                    <Button
                      title="Go to Dashboard"
                      type="primaryBlack"
                      size="m"
                      onClick={
                        userType === 'broker'
                          ? onNavigateTo(ROUTE.BROKER)
                          : onNavigateTo(`/rep/my-rfps`)
                      }
                    />
                  </div>
                )}
              </>
            )}

            <div className={classes.HeaderBurgerButton}>
              <BurgerButton isActive={isVisibleMenu} onClick={onClickBurgerButton} />
            </div>
          </>
        )}
        {isVisibleMenu && (
          <div className={classes.HeaderMenu}>
            {!isHiddenQuoting && (
              <div className={classes.HeaderMenuItem}>
                <Button title="Benefits Quoting" onClick={onClickBenefitsQuoting} />
              </div>
            )}
            <div className={classes.HeaderMenuItem}>
              <Button title="Pricing" onClick={onNavigateTo(ROUTE.PRICING)} />
            </div>
            <div className={classes.HeaderMenuItem}>
              {_.isEmpty(user) && (
                <Button
                  title="Sign in"
                  onClick={isIssuer ? onNavigateTo(ROUTE.ISSUER_LOGIN) : onNavigateTo(ROUTE.LOGIN)}
                />
              )}
            </div>
            <div className={classes.HeaderMenuItem}>
              {_.isEmpty(user) && (
                <Button
                  title="Sign Up"
                  type="primaryBlack"
                  size="m"
                  onClick={
                    isIssuer ? onNavigateTo(ROUTE.ISSUER_SIGNUP) : onNavigateTo(ROUTE.SIGNUP)
                  }
                />
              )}
              {!_.isEmpty(user) && (
                <Button
                  title="Go to Dashboard"
                  type="primaryBlack"
                  size="m"
                  onClick={
                    userType === 'broker'
                      ? onNavigateTo(ROUTE.BROKER)
                      : onNavigateTo(`/rep/my-rfps`)
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
