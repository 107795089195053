import React from 'react';

import { useDisabilityIssuerBuild } from './useDisabilityIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const DisabilityPlans = () => {
  const { build } = useDisabilityIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
