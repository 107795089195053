import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.broker;

export const getBrokerData = createSelector(getState, (state) => state.brokerData);

export const getAccountMode = createSelector(getState, (state) => state.accountMode);

export const getAccountData = createSelector(getState, (state) => state.accountData);
export const getIsLoadingCreateAccount = createSelector(
  getState,
  (state) => state.isLoadingCreateAccount,
);

export const getIsLoadingUpdateAccount = createSelector(
  getState,
  (state) => state.isLoadingUpdateAccount,
);

export const getBrokerAccountsSelector = createSelector(getState, (state) => state.brokerAccounts);
export const getBrokerAccountsMetaSelector = createSelector(
  getState,
  (state) => state.brokerAccountsMeta,
);
export const getIsLoadingBrokerAccounts = createSelector(
  getState,
  (state) => state.isLoadingBrokerAccounts,
);
export const getIsDeleteAccountModal = createSelector(
  getState,
  (state) => state.isDeleteAccountModal,
);

export const getIsLoadingBrokerAccountData = createSelector(
  getState,
  (state) => state.isLoadingBrokerAccountData,
);

// PROFILE
export const getIsEditProfile = createSelector(getState, (state) => state.isEditProfile);

export const getIsSubscriptionModal = createSelector(
  getState,
  (state) => state.isSubscriptionModal,
);

export const getPaymentMethodsSelector = createSelector(getState, (state) => state.paymentMethods);

export const getProposalListSelector = createSelector(getState, (state) => state.proposalsList);
export const getProposalsMetaSelector = createSelector(getState, (state) => state.proposalsMeta);
export const getIsLoadingProposal = createSelector(getState, (state) => state.isLoadingProposals);

export const isLoadingUpdateProfileSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateProfile,
);

export const fullProposalDataSelector = createSelector(getState, (state) => state.fullProposalData);
export const isLoadingFullProposalDataSelector = createSelector(
  getState,
  (state) => state.isLoadingFullProposalData,
);
