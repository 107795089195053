import React, { Fragment } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import classes from './memberModal.module.scss';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const MemberModal = (props) => {
  const { handleClose, isUserDetailsModal, selectedUser } = props;

  const normalizeUserType = (type) => {
    if (type === 'rep') {
      return 'Carrier';
    }

    if (type === 'broker') {
      return 'Broker';
    }

    if (type === 'primary') {
      return 'Primary';
    }

    return type;
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isUserDetailsModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {selectedUser?.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentItem}>
              <div className={classes.ModalContentItemTitle}>Email</div>
              <div>{selectedUser?.email}</div>
            </div>
            <div className={classes.ModalContentItem}>
              <div className={classes.ModalContentItemTitle}>User Type</div>
              <div>{normalizeUserType(selectedUser?.user_type)}</div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Fragment>
  );
};
