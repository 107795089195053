import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Tooltip,
} from '@mui/material';
import moment from 'moment';

import {
  getBrokerAccounts,
  getBrokerAccountsSelector,
  getIsLoadingProposal,
  getProposalListSelector,
  getProposalsList,
  getProposalsMetaSelector,
} from 'src/store/broker';
import {
  agencyAccountsSelector,
  agencyProposalsMetaSelector,
  agencyProposalsSelector,
  getAgencyAccounts,
  getAgencyProposals,
  isLoadingAgencyProposalsSelector,
} from 'src/store/agency';

import { EmptyField } from '../EmptyField/EmptyField';
import { PageHeader } from '../PageHeader/PageHeader';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { Loader, LoaderWrapper } from '../Loader/Loader';
import { Status } from '../Status/Status';

import { ROUTE } from 'src/constants/routes';

import classes from './brokerProposalsTable.module.scss';
import { MUISelect } from '../MuiSelect/MuiSelect';

const headerRows = [
  { id: 'name', name: 'Proposal Name', sortable: true },
  { id: 'company_name', name: 'Account Name', sortable: false },
  { id: 'status', name: 'Status', sortable: true },
  { id: 'created_timestamp', name: 'Created', sortable: true },
  { id: 'owner', name: 'Owner', sortable: false },
];

const filterData = [
  { title: 'All', value: 'all' },
  { title: 'Approved', value: 'approved' },
  { title: 'Submitted', value: 'submitted' },
  { title: 'Draft', value: 'draft' },
  { title: 'Rejected', value: 'rejected' },
];

export const BrokerProposalsTable = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const teamId = params?.teamId;

  const brokerAccounts = useSelector(getBrokerAccountsSelector);
  const brokerProposals = useSelector(getProposalListSelector);
  const brokerProposalsMeta = useSelector(getProposalsMetaSelector);
  const isLoadingProposals = useSelector(getIsLoadingProposal);

  const agencyAccounts = useSelector(agencyAccountsSelector);
  const agencyProposals = useSelector(agencyProposalsSelector);
  const agencyProposalsMeta = useSelector(agencyProposalsMetaSelector);
  const isLoadingAgencyProposals = useSelector(isLoadingAgencyProposalsSelector);

  const listProposals = !teamId ? brokerProposals : agencyProposals;
  const listAccounts = !teamId ? brokerAccounts : agencyAccounts;
  const listMeta = !teamId ? brokerProposalsMeta : agencyProposalsMeta;

  const [searchValue, setSearchValue] = useState('');
  const [sortMethod, setSortMethod] = useState('name');
  const [currentPage, setCurrentPage] = useState(listMeta?.page ? listMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    listMeta?.per_page ? listMeta?.per_page : 10,
  );

  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const [selectedOption, setSelectedOption] = useState('all');

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  useEffect(() => {
    if (!searchValue) {
      const payload = {
        searchValue: '',
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(getBrokerAccounts({}));
        dispatch(getProposalsList(payload));
      }

      if (teamId) {
        dispatch(getAgencyAccounts({ orgId: teamId }));
        dispatch(getAgencyProposals({ orgId: teamId, ...payload }));
      }
    }
  }, [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize]);

  const onChangeSearchInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearchButton = useCallback(() => {
    if (searchValue) {
      const payload = {
        searchValue,
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(getProposalsList(payload));
      }

      if (teamId) {
        dispatch(getAgencyProposals({ orgId: teamId, ...payload }));
      }
    }
  }, [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        const payload = {
          searchValue,
          sort: sortMethod,
          page: currentPage,
          per_page: selectedPageSize,
        };

        if (!teamId) {
          dispatch(getProposalsList(payload));
        }

        if (teamId) {
          dispatch(getAgencyProposals({ orgId: teamId, ...payload }));
        }
      }
    },
    [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize],
  );

  const onClearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  const onSelectFilterOption = useCallback(
    (event) => {
      setSelectedOption(event.target.value);

      const payload = {
        searchValue,
        status: event.target.value !== 'all' ? event.target.value : '',
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(getProposalsList(payload));
      }

      if (teamId) {
        dispatch(getAgencyProposals({ orgId: teamId, ...payload }));
      }
    },
    [dispatch, teamId, searchValue, currentPage, sortMethod, selectedPageSize],
  );

  return (
    <div className={classes.BrokerProposalsTable}>
      <PageHeader title="Proposals">
        <div className={classes.BrokerProposalsHeader}>
          <div className={classes.BrokerProposalsFilterWrapper}>
            <MUISelect
              label="Status"
              options={filterData}
              value={selectedOption}
              onChange={onSelectFilterOption}
            />
          </div>
          <div className={classes.BrokerProposalsSearch}>
            <div className={classes.BrokerProposalsSearchWrapper}>
              <Input
                placeholder="Proposal Name"
                value={searchValue}
                onChange={onChangeSearchInput}
                onKeyDown={searchOnKeyDown}
              />
              {searchValue && (
                <i
                  onClick={onClearInput}
                  className={`fas fa-plus ${classes.BrokerProposalsSearchClear}`}
                />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-search"
              onClick={onClickSearchButton}
              isLoading={isLoadingProposals || isLoadingAgencyProposals}
            />
          </div>
        </div>
      </PageHeader>

      <Paper>
        {isLoadingProposals || isLoadingAgencyProposals ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <>
            {listProposals?.length ? (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        {headerRows?.map((item) => (
                          <TableCell key={item.id}>
                            {item.sortable ? (
                              <TableSortLabel
                                active={selectedCell === item.id}
                                direction={cellDirection}
                                onClick={onChangeCellSort(item.id)}
                              >
                                {item.name}
                              </TableSortLabel>
                            ) : (
                              item.name
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listProposals.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Link
                              to={
                                teamId
                                  ? `/teams/${teamId}/accounts/${item.account_id}/proposals/${item.id}/account-info`
                                  : `${ROUTE.BROKER}/${item.account_id}/proposals/${item.id}/account-info`
                              }
                            >
                              {item.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link
                              to={
                                teamId
                                  ? `/teams/${teamId}/accounts/${item.account_id}`
                                  : `${ROUTE.BROKER}/${item.account_id}`
                              }
                            >
                              {
                                listAccounts?.find((account) => account.id === item.account_id)
                                  ?.company_name
                              }
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Status status={item?.status} />
                          </TableCell>
                          <TableCell>{moment(item.created_timestamp).format('lll')}</TableCell>
                          <TableCell>
                            <Tooltip title={item?.broker?.email}>
                              {item?.broker?.display_name}
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  component="div"
                  count={listMeta?.total ?? 0}
                  rowsPerPage={selectedPageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onChangePageSize}
                />
              </>
            ) : (
              <EmptyField title="Here is no active proposals yet." />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
