import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAccountData } from 'src/store/broker';
import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { BrokerProposalsTable } from 'src/components';

import classes from './agencyProposals.module.scss';

export const AgencyProposals = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  return (
    <div className={classes.AgencyProposals}>
      <div className={classes.AccountTableWrapper}>
        {/* {agencyProposals?.length ? (
                <BrokerProposalsTable
                  proposals={agencyProposals}
                  accounts={agencyAccounts}
                  sortMethod={sortMethod}
                  onClickSort={onClickSort}
                />
              ) : (
                'Here is no created proposals yet, start by creating one.'
              )}
            </div> */}
        <BrokerProposalsTable />
      </div>
    </div>
  );
};
