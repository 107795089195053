import {
  coinsuranceOptions,
  compMethodOptions,
  formattedValue,
  fundingTypeOptions,
  inpatientFacilityCostOptions,
  inpatientFacilityDaysOptions,
  inpatientFacilityOptions,
  networkSizeOptions,
  percentInpatientFacilityOptions,
  planTypeOptions,
  primaryCareOptions,
  specialistCopayOptions,
  tierOptions,
} from 'src/constants/coverage';
import { normalizePlanType } from 'src/constants/coveragePlans';
import { normalizeFundingType } from 'src/constants/proposalFormField';

export const useMedicalIssuerBuild = () => {
  const build = {
    coverage: 'medical',
    title: 'Medical',
    form_options: {
      contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Plan Type',
          key: 'plan_type',
          format: (value) => normalizePlanType(value),
        },
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Current Benefit',
          key: 'individual_medical_deductible',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Individual Deductible',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    offer_details: [
      {
        key: 'funding_type',
        format: (value) => normalizeFundingType(value),
        options: { render_type: 'select', render_data: fundingTypeOptions },
      },
      {
        key: 'plan_type',
        format: (value) => normalizePlanType(value),
        options: { render_type: 'select', render_data: planTypeOptions },
      },
      {
        key: 'network_size',
        options: { render_type: 'select', render_data: networkSizeOptions },
      },
      {
        key: 'level',
        options: { render_type: 'select', render_data: tierOptions },
      },
      {
        key: 'primary_care_physician',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: primaryCareOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'specialist',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: specialistCopayOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'individual_medical_deductible',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'family_medical_deductible',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'inpatient_facility_is_percent',
        format: (value) => (value ? 'Percent' : '$ Per Day'),
        render: (value) =>
          value
            ? [
                {
                  key: 'inpatient_facility_percent_value',
                  format: (value) => `${value ?? 0}%`,
                  options: {
                    render_type: 'select',
                    render_data: percentInpatientFacilityOptions,
                    symbolPosition: 'end',
                    type: 'other',
                    symbol: '%',
                    inputType: 'number',
                    maxValue: 100,
                    minValue: 0,
                  },
                },
              ]
            : [
                {
                  key: 'inpatient_facility_dollar_amount',
                  format: (value) => formattedValue(value),
                  options: {
                    render_type: 'select',
                    render_data: inpatientFacilityCostOptions,
                  },
                },
                {
                  key: 'inpatient_facility_dollar_days',
                  format: (value) => `${value} Days`,
                  options: {
                    render_type: 'select',
                    render_data: inpatientFacilityDaysOptions,
                    type: 'other',
                    inputType: 'number',
                    symbolPosition: 'end',
                    symbol: 'Days',
                    minValue: 0,
                  },
                },
              ],
        options: { render_type: 'select', render_data: inpatientFacilityOptions, disabled: true },
      },
      {
        key: 'plan_coinsurance',
        format: (value) => `${value ?? 0}%`,
        options: {
          render_type: 'select',
          render_data: coinsuranceOptions,
          symbolPosition: 'end',
          type: 'other',
          symbol: '%',
          inputType: 'number',
          maxValue: 100,
          minValue: 0,
        },
      },
      {
        key: 'emergency_room',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'urgent_care',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'individual_medical_moop',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
          minValue: 1000,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
