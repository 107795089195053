import { createSlice } from '@reduxjs/toolkit';

import { getVisionPlans, getVisionQuotePlans } from './visionConfiguration.actions';

const initialState = {
  visionPlans: [],
  visionQuotePlans: [],
  isLoadingVisionQuotePlans: false,
  isLoadingVisionPlans: false,

  // ISSUER
  issuerVisionPlans: [],
  isLoadingIssuerVisionPlans: false,
  isLoadingUpdateVisionOffer: false,
};

const visionConfigurationSlice = createSlice({
  name: 'visionConfiguration',
  initialState,
  reducers: {
    setVisionPlans: (state, action) => {
      state.visionPlans = action.payload;
    },
    setVisionQuotePlans: (state, action) => {
      state.visionQuotePlans = action.payload;
    },

    setIssuerVisionPlans: (state, action) => {
      state.issuerVisionPlans = action.payload;
    },

    clearVisionConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionQuotePlans.pending, (state) => {
      state.isLoadingVisionQuotePlans = true;
    });
    builder.addCase(getVisionQuotePlans.fulfilled, (state, action) => {
      state.visionQuotePlans = action.payload;
      state.isLoadingVisionQuotePlans = false;
    });
    builder.addCase(getVisionQuotePlans.rejected, (state) => {
      state.isLoadingVisionQuotePlans = false;
    });

    builder.addCase(getVisionPlans.pending, (state) => {
      state.isLoadingVisionPlans = true;
    });
    builder.addCase(getVisionPlans.fulfilled, (state, action) => {
      state.visionPlans = action.payload;
      state.isLoadingVisionPlans = false;
    });
    builder.addCase(getVisionPlans.rejected, (state) => {
      state.isLoadingVisionPlans = false;
    });
  },
});

export const {
  setVisionPlans,
  setVisionQuotePlans,
  setIssuerVisionPlans,
  clearVisionConfigurationState,
} = visionConfigurationSlice.actions;

export default visionConfigurationSlice.reducer;
