import React from 'react';
import classnames from 'classnames';

import classes from './tabs.module.scss';

export const Tabs = (props) => {
  const { type = 'default', children } = props;

  const tabItemStyles = classnames({
    [classes.TabItem]: type === 'default',
    [classes.TabItemUnderline]: type === 'underline',
  });

  return (
    <div className={classes.TabsWrapper}>
      <div className={tabItemStyles}>{children}</div>
    </div>
  );
};
