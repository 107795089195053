import { worksheetNameRegex } from 'src/constants/regularExpression';

export const normalizePetQuoteExport = (ExcelJSWorkbook, groupQuotes) => {
  for (const quote of groupQuotes) {
    const quoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      quote.name.length > 25
        ? quote.name.slice(0, 25).replace(worksheetNameRegex, '')
        : quote.name.replace(worksheetNameRegex, ''),
      {
        properties: { defaultColWidth: 40 },
      },
    );

    quoteWorkSheet.addRow(['Quote Name', quote?.name]).font = { bold: true };
    quoteWorkSheet.addRow(['Type', quote?.quote_type]).getCell(1).font = { bold: true };
    quoteWorkSheet.addRow(['Effective Date', quote?.effective_date]).getCell(1).font = {
      bold: true,
    };

    quoteWorkSheet.addRow(['Contribution Source', quote?.contribution_source]).getCell(1).font = {
      bold: true,
    };

    quoteWorkSheet.addRow([]);

    let coverage_waiting_period = ['Coverage Waiting Period'];
    let dental_cleanings = ['Dental Cleanings'];
    let diagnostic_testing = ['Diagnostic Testing'];
    let eligible_pets = ['Eligible Pets'];
    let employee_payment_method = ['Employee Payment Method'];
    let group_rate_discount = ['Group Rate Discount'];
    let group_size_requirement = ['Group Size Requirement'];
    let hereditary_conditions = ['Hereditary Conditions']; // boolean
    let medical_history_required = ['Medical History Required']; // boolean
    let medications = ['Medications'];
    let office_visits = ['Office Visits'];
    let old_age = ['Old Age'];
    let participation_requirement = ['Participation Requirement'];
    let portability = ['Portability'];
    let pre_existing_conditions = ['Pre Existing Conditions']; // Boolean
    let puppy_kittens_vaccines = ['Puppy Kittens Vaccines'];
    let radiology = ['Radiology'];
    let spay_neuter = ['Spay Neuter'];
    let surgeries_hospitalization = ['Surgeries Hospitalization'];
    let vaccinations = ['Vaccinations'];
    let value_addons = ['Value Addons'];
    let veterinary_access = ['Veterinary Access'];
    let virtual_vet_car = ['Virtual Vet Car'];
    let well_care_visits = ['Well Care Visits'];
    let pet_1 = ['1x Pet']; // currency
    let pet_2 = ['2x Pet']; // currency
    let pet_3 = ['3x Pet']; // currency
    let pet_4 = ['4x Pet']; // currency
    let pet_5 = ['5x Pet']; // currency
    let pet_n = ['Unlimited Pets'];

    let proposalRows = [
      coverage_waiting_period,
      dental_cleanings,
      diagnostic_testing,
      eligible_pets,
      employee_payment_method,
      group_rate_discount,
      group_size_requirement,
      hereditary_conditions,
      medical_history_required,
      medications,
      office_visits,
      old_age,
      participation_requirement,
      portability,
      pre_existing_conditions,
      puppy_kittens_vaccines,
      radiology,
      spay_neuter,
      surgeries_hospitalization,
      vaccinations,
      value_addons,
      veterinary_access,
      virtual_vet_car,
      well_care_visits,
      pet_1,
      pet_2,
      pet_3,
      pet_4,
      pet_5,
      pet_n,
    ];

    for (const plan of quote?.plans) {
      coverage_waiting_period.push(plan.coverage_waiting_period);
      dental_cleanings.push(plan.dental_cleanings);
      diagnostic_testing.push(plan.diagnostic_testing);
      eligible_pets.push(plan.eligible_pets);
      employee_payment_method.push(plan.employee_payment_method);
      group_rate_discount.push(plan.group_rate_discount);
      group_size_requirement.push(plan.group_size_requirement);
      hereditary_conditions.push(plan.hereditary_conditions ? 'Yes' : 'No');
      medical_history_required.push(plan.medical_history_required ? 'Yes' : 'No');
      medications.push(plan.medications);
      office_visits.push(plan.office_visits);
      old_age.push(plan.old_age);
      participation_requirement.push(plan.participation_requirement);
      portability.push(plan.portability);
      pre_existing_conditions.push(plan.pre_existing_conditions ? 'Yes' : 'No');
      puppy_kittens_vaccines.push(plan.puppy_kittens_vaccines);
      radiology.push(plan.radiology);
      spay_neuter.push(plan.spay_neuter);
      surgeries_hospitalization.push(plan.surgeries_hospitalization);
      vaccinations.push(plan.vaccinations);
      value_addons.push(plan.value_addons);
      veterinary_access.push(plan.veterinary_access);
      virtual_vet_car.push(plan.virtual_vet_car);
      well_care_visits.push(plan.well_care_visits);
      pet_1.push(plan.pet_1);
      pet_2.push(plan.pet_2);
      pet_3.push(plan.pet_3);
      pet_4.push(plan.pet_4);
      pet_5.push(plan.pet_5);
      pet_n.push(plan.pet_n);
    }

    for (const row of proposalRows) {
      if (row[0] === 'Plan Name' || row[0] === 'Carrier') {
        quoteWorkSheet.addRow(row).font = { bold: true };
        continue;
      }
      quoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    for (let columnIndex = 2; columnIndex <= quoteWorkSheet.columnCount; columnIndex++) {
      quoteWorkSheet.getColumn(columnIndex).numFmt =
        '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
      quoteWorkSheet.getColumn(columnIndex).width = 40;
      quoteWorkSheet.getColumn(columnIndex).textAlign = 'left';
    }

    for (let rowIndex = 1; rowIndex <= quoteWorkSheet.rowCount; rowIndex++) {
      quoteWorkSheet.getRow(rowIndex).alignment = {
        vertical: 'top',
        horizontal: 'left',
        wrapText: true,
      };
    }
  }
};
