import Base from './base';

class App extends Base {
  createBrokerAccount = (payload) => {
    return this.apiClient.post('brokers', payload);
  };

  getUserInfo = (id) => {
    return this.apiClient.get(`brokers/${id}`);
  };

  getEmailDomains = (id) => {
    return this.apiClient.get(`issuers${id ? `/${id}` : ''}`);
  };

  getIssuerDomain = (payload) => {
    return this.apiClient.get('issuers', payload);
  };

  sendEmailVereficationLink = (payload) => {
    return this.apiClient.post(`users/send-email-verification-link`, payload);
  };

  sendPasswordResetLink = (payload) => {
    return this.apiClient.post(`users/send-reset-password-link`, payload);
  };

  getCityByZipCode = (zipCode) => {
    return this.apiClient.get(`zip/${zipCode}`);
  };
}

export default App;
