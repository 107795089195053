import Base from './base';

class Issuer extends Base {
  createRepAccount = (payload) => {
    return this.apiClient.post('reps', payload);
  };

  createIssuerAccount = (payload) => {
    return this.apiClient.post('issuers', payload);
  };

  fetchRepAccountData = (repId) => {
    return this.apiClient.get(`reps/${repId}`);
  };

  updateRepAccount = (repId, payload) => {
    return this.apiClient.put(`reps/${repId}`, payload);
  };

  deleteRepAccount = (repId) => {
    return this.apiClient.delete(`reps/${repId}`);
  };

  getLinkedRfp = (issuerId, issuerToken) => {
    return this.apiClient.get(
      `issuers/${issuerId}/rfp/featured${issuerToken ? `?token=${issuerToken}` : ''}`,
    );
  };

  getOtherRfp = (issuerId, payload) => {
    return this.apiClient.get(`issuers/${issuerId}/rfp`, payload);
  };

  getRepOtherRfp = (repId, payload) => {
    return this.apiClient.get(`reps/${repId}/rfp`, payload);
  };

  createOffer = (accountId, proposalId, configId, medicalType, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalType}/config/${configId}/offers`,
      payload,
    );
  };

  updateOffer = (accountId, proposalId, configId, coverage, payload, offerId, read_token) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${configId}/offers/${offerId}${
        read_token ? `?token=${read_token}` : ''
      }`,
      payload,
    );
  };

  deleteOffer = (accountId, proposalId, medicalType, configId, offerId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalType}/config/${configId}/offers/${offerId}`,
    );
  };

  createCustomField = (accountId, proposalId, configId, medicalType, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalType}/config/${configId}/offers`,
      payload,
    );
  };
}

export default Issuer;
