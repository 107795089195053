import { currencyFormatter } from 'src/constants/currency';

export const exportRateToCsv = (clientPickedQuote, setCsvHeader, setCsvBody) => {
  let ageObject = {};

  const csvHeaders = [{ label: 'Age', key: 'age' }];

  for (const item of clientPickedQuote?.plans) {
    const payload = {
      label: item.current_plan_name
        ? `${item.current_plan_name} ${item.name}`
        : `${item.carrier_name} ${item.name}`,
      key: item.current_plan_name
        ? `${item.current_plan_name} ${item.name}`
        : `${item.carrier_name} ${item.name}`,
    };

    csvHeaders.push(payload);
  }

  const csvBodyData = [];

  for (const item of clientPickedQuote?.plans) {
    for (let i = 0; i <= 65; i++) {
      ageObject = {
        ...ageObject,
        [item.current_plan_name
          ? `${item.current_plan_name} ${item.name}`
          : `${item.carrier_name} ${item.name}`]: {
          ...ageObject[
            item.current_plan_name
              ? `${item.current_plan_name} ${item.name}`
              : `${item.carrier_name} ${item.name}`
          ],
          [i]: item[`age_${i}`],
        },
      };
    }
  }

  for (const item in ageObject) {
    for (const age of Object.keys(ageObject[item])) {
      const payload = {
        age: age,
        [item]: currencyFormatter(ageObject[item][age]).replace('$', ''),
      };

      csvBodyData.push(payload);
    }
  }

  setCsvHeader(csvHeaders);

  const mergedBodyData = csvBodyData.reduce((accumulator, current) => {
    if (accumulator[current.age]) {
      accumulator[current.age] = { ...accumulator[current.age], ...current };
    } else {
      accumulator[current.age] = current;
    }

    return accumulator;
  }, {});

  setCsvBody(Object.values(mergedBodyData));
};

const computeHousehold4TierType = (roles) => {
  if (roles.length === 1 && roles[0] === 'employee') {
    return { type: 'EE' };
  }

  let spCount = 0;
  let chCount = 0;

  for (const role of roles) {
    if (role === 'spouse' || role === 'life_partner') {
      spCount++;
    } else if (role === 'child') {
      chCount++;
    }
  }

  if (spCount > 0 && chCount > 0) {
    return { type: 'FAM' };
  } else if (spCount > 0) {
    return { type: 'EE + SP' };
  } else if (chCount > 0) {
    return { type: 'EE + CH' };
  } else {
    return { type: 'FAM' };
  }
};

export const onDownloadMembersCsv = (groupMembers, setCsvMembersData) => () => {
  let membersCsvData = [
    [
      'ee_id',
      'first_name',
      'last_name',
      'zip_code',
      'date_of_birth',
      'gender',
      'relationship',
      'Medical enrollment',
      'current_medical_plan_name',
      'Dental enrollment',
      'dental_plan_name',
      'Vision enrollment',
      'vision_plan_name',
      'life_benefit_amount',
      'is_tobacco_user',
      'salary_amount',
      'job_title',
    ],
  ];

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of groupMembers) {
    let updatedObject = { ...member };

    updatedObject.ee_id = member.family_id;

    delete updatedObject?.id;
    delete updatedObject?.created_timestamp;
    delete updatedObject?.group_id;
    delete updatedObject?.last_used_tobacco;
    delete updatedObject?.cobra;
    delete updatedObject?.retiree;
    delete updatedObject?.family_id;

    let memberRow = [];

    memberRow.push(updatedObject.ee_id);
    memberRow.push(updatedObject.first_name);
    memberRow.push(updatedObject.last_name);
    memberRow.push(updatedObject.zip_code);
    memberRow.push(updatedObject.date_of_birth);
    memberRow.push(updatedObject.gender);
    memberRow.push(updatedObject.relationship);
    if (member?.relationship === 'employee' && updatedObject.current_medical_plan_name) {
      memberRow.push(household4TierTypes[member?.family_id]?.type);
    } else {
      memberRow.push('');
    }
    memberRow.push(updatedObject.current_medical_plan_name);
    if (member?.relationship === 'employee' && updatedObject.dental_plan_name) {
      memberRow.push(household4TierTypes[member?.family_id]?.type);
    } else {
      memberRow.push('');
    }
    memberRow.push(updatedObject.dental_plan_name);
    if (member?.relationship === 'employee' && updatedObject.vision_plan_name) {
      memberRow.push(household4TierTypes[member?.family_id]?.type);
    } else {
      memberRow.push('');
    }
    memberRow.push(updatedObject.vision_plan_name);
    memberRow.push(updatedObject.life_benefit_amount);
    memberRow.push(updatedObject?.is_tobacco_user ? 'yes' : 'no');
    memberRow.push(updatedObject.salary_amount);
    memberRow.push(updatedObject.job_title);

    membersCsvData.push(memberRow);
  }

  setCsvMembersData(membersCsvData);
};
