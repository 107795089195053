export const reportingColors = [
  'rgb(245, 203, 146)',
  'rgb(82, 234, 178)',
  'rgb(69, 30, 36)',
  'rgb(242, 148, 228)',
  'rgb(117, 172, 134)',
  'rgb(14, 189, 9)',
  'rgb(36, 24, 5)',
  'rgb(116, 110, 191)',
  'rgb(204, 189, 187)',
  'rgb(190, 160, 185)',
  'rgb(76, 133, 136)',
  'rgb(137, 9, 227)',
  'rgb(189, 147, 184)',
  'rgb(206, 33, 109)',
  'rgb(202, 54, 25)',
  'rgb(145, 197, 227)',
  'rgb(188, 128, 40)',
  'rgb(47, 233, 155)',
  'rgb(252, 174, 85)',
  'rgb(176, 202, 198)',
  'rgb(83, 114, 245)',
  'rgb(97, 27, 39)',
  'rgb(195, 33, 221)',
  'rgb(160, 138, 140)',
  'rgb(85, 215, 161)',
  'rgb(112, 30, 93)',
  'rgb(177, 147, 79)',
  'rgb(60, 104, 121)',
  'rgb(136, 57, 156)',
  'rgb(157, 231, 225)',
  'rgb(12, 211, 183)',
  'rgb(154, 7, 244)',
  'rgb(64, 94, 102)',
  'rgb(12, 159, 41)',
  'rgb(193, 119, 237)',
  'rgb(122, 236, 203)',
  'rgb(69, 209, 215)',
  'rgb(107, 148, 128)',
  'rgb(232, 62, 118)',
  'rgb(227, 104, 212)',
  'rgb(64, 6, 178)',
  'rgb(29, 115, 183)',
  'rgb(237, 5, 103)',
  'rgb(65, 225, 100)',
  'rgb(76, 89, 19)',
  'rgb(1, 14, 16)',
  'rgb(138, 186, 69)',
  'rgb(85, 106, 48)',
  'rgb(213, 192, 56)',
  'rgb(122, 202, 154)',
  'rgb(195, 52, 19)',
  'rgb(114, 240, 14)',
  'rgb(135, 234, 150)',
  'rgb(246, 131, 96)',
  'rgb(8, 84, 129)',
  'rgb(127, 92, 110)',
  'rgb(174, 73, 36)',
  'rgb(138, 231, 89)',
  'rgb(238, 135, 96)',
  'rgb(167, 17, 46)',
  'rgb(160, 23, 150)',
  'rgb(133, 161, 244)',
  'rgb(180, 36, 3)',
  'rgb(220, 11, 55)',
  'rgb(189, 181, 13)',
  'rgb(245, 195, 111)',
  'rgb(218, 127, 26)',
  'rgb(61, 133, 113)',
  'rgb(249, 49, 187)',
  'rgb(128, 67, 212)',
  'rgb(150, 106, 54)',
  'rgb(135, 155, 60)',
  'rgb(127, 44, 52)',
  'rgb(66, 232, 186)',
  'rgb(103, 220, 147)',
  'rgb(43, 152, 112)',
  'rgb(51, 157, 116)',
  'rgb(141, 238, 175)',
  'rgb(122, 80, 205)',
  'rgb(36, 41, 26)',
  'rgb(213, 96, 188)',
  'rgb(241, 204, 219)',
  'rgb(137, 108, 62)',
  'rgb(192, 45, 57)',
  'rgb(92, 1, 119)',
  'rgb(129, 248, 194)',
  'rgb(37, 200, 227)',
  'rgb(146, 122, 157)',
  'rgb(54, 107, 111)',
  'rgb(82, 14, 6)',
  'rgb(104, 247, 217)',
  'rgb(123, 53, 241)',
  'rgb(197, 176, 153)',
  'rgb(140, 30, 198)',
  'rgb(82, 30, 127)',
  'rgb(45, 144, 191)',
  'rgb(73, 5, 175)',
  'rgb(157, 127, 94)',
  'rgb(168, 150, 169)',
];
