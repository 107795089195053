import _ from 'lodash';

const lifeUniFiveYearAges = (rate) => {
  return {
    age_0_17_uni_tobacco: !_.isEmpty(rate) ? rate['age_0_uni_tobacco'] : '',
    age_18_24_uni_tobacco: !_.isEmpty(rate) ? rate['age_18_uni_tobacco'] : '',
    age_25_29_uni_tobacco: !_.isEmpty(rate) ? rate['age_25_uni_tobacco'] : '',
    age_30_34_uni_tobacco: !_.isEmpty(rate) ? rate['age_30_uni_tobacco'] : '',
    age_35_39_uni_tobacco: !_.isEmpty(rate) ? rate['age_35_uni_tobacco'] : '',
    age_40_44_uni_tobacco: !_.isEmpty(rate) ? rate['age_40_uni_tobacco'] : '',
    age_45_49_uni_tobacco: !_.isEmpty(rate) ? rate['age_45_uni_tobacco'] : '',
    age_50_54_uni_tobacco: !_.isEmpty(rate) ? rate['age_50_uni_tobacco'] : '',
    age_55_59_uni_tobacco: !_.isEmpty(rate) ? rate['age_55_uni_tobacco'] : '',
    age_60_64_uni_tobacco: !_.isEmpty(rate) ? rate['age_60_uni_tobacco'] : '',
    age_65_69_uni_tobacco: !_.isEmpty(rate) ? rate['age_65_uni_tobacco'] : '',
    age_70_74_uni_tobacco: !_.isEmpty(rate) ? rate['age_70_uni_tobacco'] : '',
    age_75_79_uni_tobacco: !_.isEmpty(rate) ? rate['age_75_uni_tobacco'] : '',
    age_80_uni_tobacco: !_.isEmpty(rate) ? rate['age_80_uni_tobacco'] : '',
  };
};

const LIFE_UNI_FIVE_YEAR_NAMES = {
  age_0_17_uni_tobacco: 'Age 0-17',
  age_18_24_uni_tobacco: 'Age 18-24',
  age_25_29_uni_tobacco: 'Age 25-29',
  age_30_34_uni_tobacco: 'Age 30-34',
  age_35_39_uni_tobacco: 'Age 35-39',
  age_40_44_uni_tobacco: 'Age 40-44',
  age_45_49_uni_tobacco: 'Age 45-49',
  age_50_54_uni_tobacco: 'Age 50-54',
  age_55_59_uni_tobacco: 'Age 55-59',
  age_60_64_uni_tobacco: 'Age 60-64',
  age_65_69_uni_tobacco: 'Age 65-69',
  age_70_74_uni_tobacco: 'Age 70-74',
  age_75_79_uni_tobacco: 'Age 75-79',
  age_80_uni_tobacco: 'Age 80',
};

const normalizeLifeUniFiveYearAgeRates = (fiveYearAgeRates) => {
  let payload = {
    ...fiveYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_0_17_uni_tobacco'],
    };
  }
  for (let i = 18; i <= 24; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_18_24_uni_tobacco'],
    };
  }
  for (let i = 25; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_25_29_uni_tobacco'],
    };
  }
  for (let i = 30; i <= 34; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_30_34_uni_tobacco'],
    };
  }
  for (let i = 35; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_35_39_uni_tobacco'],
    };
  }
  for (let i = 40; i <= 44; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_40_44_uni_tobacco'],
    };
  }
  for (let i = 45; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_45_49_uni_tobacco'],
    };
  }
  for (let i = 50; i <= 54; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_50_54_uni_tobacco'],
    };
  }
  for (let i = 55; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_55_59_uni_tobacco'],
    };
  }
  for (let i = 60; i <= 64; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_60_64_uni_tobacco'],
    };
  }
  for (let i = 65; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_65_69_uni_tobacco'],
    };
  }
  for (let i = 70; i <= 74; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_70_74_uni_tobacco'],
    };
  }
  for (let i = 75; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: fiveYearAgeRates['age_75_79_uni_tobacco'],
    };
  }

  delete payload['age_0_17_uni_tobacco'];
  delete payload['age_18_24_uni_tobacco'];
  delete payload['age_25_29_uni_tobacco'];
  delete payload['age_30_34_uni_tobacco'];
  delete payload['age_35_39_uni_tobacco'];
  delete payload['age_40_44_uni_tobacco'];
  delete payload['age_45_49_uni_tobacco'];
  delete payload['age_50_54_uni_tobacco'];
  delete payload['age_55_59_uni_tobacco'];
  delete payload['age_60_64_uni_tobacco'];
  delete payload['age_65_69_uni_tobacco'];
  delete payload['age_70_74_uni_tobacco'];
  delete payload['age_75_79_uni_tobacco'];

  return payload;
};

const lifeTobaccoFiveYearAges = (rate) => {
  return {
    age_0_17_tobacco: !_.isEmpty(rate) ? rate['age_0_tobacco'] : '',
    age_18_24_tobacco: !_.isEmpty(rate) ? rate['age_18_tobacco'] : '',
    age_25_29_tobacco: !_.isEmpty(rate) ? rate['age_25_tobacco'] : '',
    age_30_34_tobacco: !_.isEmpty(rate) ? rate['age_30_tobacco'] : '',
    age_35_39_tobacco: !_.isEmpty(rate) ? rate['age_35_tobacco'] : '',
    age_40_44_tobacco: !_.isEmpty(rate) ? rate['age_40_tobacco'] : '',
    age_45_49_tobacco: !_.isEmpty(rate) ? rate['age_45_tobacco'] : '',
    age_50_54_tobacco: !_.isEmpty(rate) ? rate['age_50_tobacco'] : '',
    age_55_59_tobacco: !_.isEmpty(rate) ? rate['age_55_tobacco'] : '',
    age_60_64_tobacco: !_.isEmpty(rate) ? rate['age_60_tobacco'] : '',
    age_65_69_tobacco: !_.isEmpty(rate) ? rate['age_65_tobacco'] : '',
    age_70_74_tobacco: !_.isEmpty(rate) ? rate['age_70_tobacco'] : '',
    age_75_79_tobacco: !_.isEmpty(rate) ? rate['age_75_tobacco'] : '',
    age_80_tobacco: !_.isEmpty(rate) ? rate['age_80_tobacco'] : '',
  };
};

const LIFE_TOBACCO_FIVE_YEAR_NAMES = {
  age_0_17_tobacco: 'Age 0-17 Tobacco',
  age_18_24_tobacco: 'Age 18-24 Tobacco',
  age_25_29_tobacco: 'Age 25-29 Tobacco',
  age_30_34_tobacco: 'Age 30-34 Tobacco',
  age_35_39_tobacco: 'Age 35-39 Tobacco',
  age_40_44_tobacco: 'Age 40-44 Tobacco',
  age_45_49_tobacco: 'Age 45-49 Tobacco',
  age_50_54_tobacco: 'Age 50-54 Tobacco',
  age_55_59_tobacco: 'Age 55-59 Tobacco',
  age_60_64_tobacco: 'Age 60-64 Tobacco',
  age_65_69_tobacco: 'Age 65-69 Tobacco',
  age_70_74_tobacco: 'Age 70-74 Tobacco',
  age_75_79_tobacco: 'Age 75-79 Tobacco',
  age_80_tobacco: 'Age 80+ Tobacco',
};

const normalizeLifeTobaccoFiveYearAgeRates = (fiveYearAgeRates) => {
  let payload = {
    ...fiveYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_0_17_tobacco'],
    };
  }
  for (let i = 18; i <= 24; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_18_24_tobacco'],
    };
  }
  for (let i = 25; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_25_29_tobacco'],
    };
  }
  for (let i = 30; i <= 34; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_30_34_tobacco'],
    };
  }
  for (let i = 35; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_35_39_tobacco'],
    };
  }
  for (let i = 40; i <= 44; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_40_44_tobacco'],
    };
  }
  for (let i = 45; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_45_49_tobacco'],
    };
  }
  for (let i = 50; i <= 54; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_50_54_tobacco'],
    };
  }
  for (let i = 55; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_55_59_tobacco'],
    };
  }
  for (let i = 60; i <= 64; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_60_64_tobacco'],
    };
  }
  for (let i = 65; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_65_69_tobacco'],
    };
  }
  for (let i = 70; i <= 74; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_70_74_tobacco'],
    };
  }
  for (let i = 75; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: fiveYearAgeRates['age_75_79_tobacco'],
    };
  }

  delete payload['age_0_17_tobacco'];
  delete payload['age_18_24_tobacco'];
  delete payload['age_25_29_tobacco'];
  delete payload['age_30_34_tobacco'];
  delete payload['age_35_39_tobacco'];
  delete payload['age_40_44_tobacco'];
  delete payload['age_45_49_tobacco'];
  delete payload['age_50_54_tobacco'];
  delete payload['age_55_59_tobacco'];
  delete payload['age_60_64_tobacco'];
  delete payload['age_65_69_tobacco'];
  delete payload['age_70_74_tobacco'];
  delete payload['age_75_79_tobacco'];

  return payload;
};

const lifeNonTobaccoFiveYearAges = (rate) => {
  return {
    age_0_17_non_tobacco: !_.isEmpty(rate) ? rate['age_0_non_tobacco'] : '',
    age_18_24_non_tobacco: !_.isEmpty(rate) ? rate['age_18_non_tobacco'] : '',
    age_25_29_non_tobacco: !_.isEmpty(rate) ? rate['age_25_non_tobacco'] : '',
    age_30_34_non_tobacco: !_.isEmpty(rate) ? rate['age_30_non_tobacco'] : '',
    age_35_39_non_tobacco: !_.isEmpty(rate) ? rate['age_35_non_tobacco'] : '',
    age_40_44_non_tobacco: !_.isEmpty(rate) ? rate['age_40_non_tobacco'] : '',
    age_45_49_non_tobacco: !_.isEmpty(rate) ? rate['age_45_non_tobacco'] : '',
    age_50_54_non_tobacco: !_.isEmpty(rate) ? rate['age_50_non_tobacco'] : '',
    age_55_59_non_tobacco: !_.isEmpty(rate) ? rate['age_55_non_tobacco'] : '',
    age_60_64_non_tobacco: !_.isEmpty(rate) ? rate['age_60_non_tobacco'] : '',
    age_65_69_non_tobacco: !_.isEmpty(rate) ? rate['age_65_non_tobacco'] : '',
    age_70_74_non_tobacco: !_.isEmpty(rate) ? rate['age_70_non_tobacco'] : '',
    age_75_79_non_tobacco: !_.isEmpty(rate) ? rate['age_75_non_tobacco'] : '',
    age_80_non_tobacco: !_.isEmpty(rate) ? rate['age_80_non_tobacco'] : '',
  };
};

const LIFE_NON_TOBACCO_FIVE_YEAR_NAMES = {
  age_0_17_non_tobacco: 'Age 0-17 Non-Tobacco',
  age_18_24_non_tobacco: 'Age 18-24 Non-Tobacco',
  age_25_29_non_tobacco: 'Age 25-29 Non-Tobacco',
  age_30_34_non_tobacco: 'Age 30-34 Non-Tobacco',
  age_35_39_non_tobacco: 'Age 35-39 Non-Tobacco',
  age_40_44_non_tobacco: 'Age 40-44 Non-Tobacco',
  age_45_49_non_tobacco: 'Age 45-49 Non-Tobacco',
  age_50_54_non_tobacco: 'Age 50-54 Non-Tobacco',
  age_55_59_non_tobacco: 'Age 55-59 Non-Tobacco',
  age_60_64_non_tobacco: 'Age 60-64 Non-Tobacco',
  age_65_69_non_tobacco: 'Age 65-69 Non-Tobacco',
  age_70_74_non_tobacco: 'Age 70-74 Non-Tobacco',
  age_75_79_non_tobacco: 'Age 75-79 Non-Tobacco',
  age_80_non_tobacco: 'Age 80+ Non-Tobacco',
};

const normalizeLifeNonTobaccoFiveYearAgeRates = (fiveYearAgeRates) => {
  let payload = {
    ...fiveYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_0_17_non_tobacco'],
    };
  }
  for (let i = 18; i <= 24; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_18_24_non_tobacco'],
    };
  }
  for (let i = 25; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_25_29_non_tobacco'],
    };
  }
  for (let i = 30; i <= 34; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_30_34_non_tobacco'],
    };
  }
  for (let i = 35; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_35_39_non_tobacco'],
    };
  }
  for (let i = 40; i <= 44; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_40_44_non_tobacco'],
    };
  }
  for (let i = 45; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_45_49_non_tobacco'],
    };
  }
  for (let i = 50; i <= 54; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_50_54_non_tobacco'],
    };
  }
  for (let i = 55; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_55_59_non_tobacco'],
    };
  }
  for (let i = 60; i <= 64; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_60_64_non_tobacco'],
    };
  }
  for (let i = 65; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_65_69_non_tobacco'],
    };
  }
  for (let i = 70; i <= 74; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_70_74_non_tobacco'],
    };
  }
  for (let i = 75; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: fiveYearAgeRates['age_75_79_non_tobacco'],
    };
  }

  delete payload['age_0_17_non_tobacco'];
  delete payload['age_18_24_non_tobacco'];
  delete payload['age_25_29_non_tobacco'];
  delete payload['age_30_34_non_tobacco'];
  delete payload['age_35_39_non_tobacco'];
  delete payload['age_40_44_non_tobacco'];
  delete payload['age_45_49_non_tobacco'];
  delete payload['age_50_54_non_tobacco'];
  delete payload['age_55_59_non_tobacco'];
  delete payload['age_60_64_non_tobacco'];
  delete payload['age_65_69_non_tobacco'];
  delete payload['age_70_74_non_tobacco'];
  delete payload['age_75_79_non_tobacco'];

  return payload;
};

export {
  lifeUniFiveYearAges,
  LIFE_UNI_FIVE_YEAR_NAMES,
  normalizeLifeUniFiveYearAgeRates,
  lifeTobaccoFiveYearAges,
  LIFE_TOBACCO_FIVE_YEAR_NAMES,
  normalizeLifeTobaccoFiveYearAgeRates,
  lifeNonTobaccoFiveYearAges,
  LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
  normalizeLifeNonTobaccoFiveYearAgeRates,
};
