import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { brokerLicensesSelector, getBrokerLicenses } from 'src/store/brokerLicenses';

import { UploadImage } from 'src/features/uploadImage/UploadImage';
import { Button } from '../Button/Button';
import { Loader, LoaderWrapper } from '../Loader/Loader';

import classes from './accountDetails.module.scss';

export const AccountDetails = (props) => {
  const {
    readOnlyMode = false,
    companyName,
    previewImage,
    addressStreet,
    addressCity,
    addressState,
    addressZip,
    contactName,
    contactEmail,
    contactPhone,
    fipsCode,
    brokerName,
    brokerEmail,
    brokerPhone,
    effectiveDate,
    sicCode,
    ratingArea,
    dueDate,
    brokerData,
    onChangeAccountImage,
    onClickBackButton,
    onDeleteAccount,
    onEditAccount,
    isLoading,
  } = props;

  const dispatch = useDispatch();

  const brokerLicenses = useSelector(brokerLicensesSelector);

  useEffect(() => {
    if (!_.isEmpty(brokerData)) {
      dispatch(getBrokerLicenses({ brokerId: brokerData.id }));
    }
  }, [dispatch, brokerData]);

  const [licenseNumber, setLicenseNumber] = useState({});

  useEffect(() => {
    if (brokerLicenses?.length) {
      const licenseNumber = brokerLicenses?.find((item) => item.state === addressState);

      if (!_.isEmpty(licenseNumber)) {
        setLicenseNumber(licenseNumber);
      }
    }
  }, [addressState, brokerLicenses]);

  return (
    <div className={classes.AccountDetails}>
      {isLoading ? (
        <div className={classes.LoaderWrapper}>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </div>
      ) : (
        <div className={classes.AccountDetailsContent}>
          <div className={classes.AccountDetailsContentInfo}>
            <div className={classes.AccountDetailsImageWrapper}>
              <div className={classes.AccountDetailsImage}>
                <UploadImage previewImage={previewImage} isOnlyImage />
                {!readOnlyMode && (
                  <div className={classes.AccountDetailsEdit} onClick={onChangeAccountImage}>
                    <i className={`fas fa-edit ${classes.AccountDetailsIcon}`} />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.AccountDetailsWrapper}>
              <div className={classes.AccountDetailsColumn}>
                <div className={classes.AccountDetailsItemTitle}>{companyName}</div>
                <div className={classes.AccountDetailsItemDescription}>{addressStreet}</div>
                <div className={classes.AccountDetailsItemDescription}>{`${
                  addressCity ? `${addressCity}, ` : ''
                } ${addressState ? addressState : ''} ${addressZip ? addressZip : ''}`}</div>
              </div>
              <div className={classes.AccountDetailsColumn}>
                {contactName && (
                  <div className={classes.AccountDetailsItemWrapper}>
                    <div className={classes.AccountDetailsItemSubTitle}>{contactName}</div>
                    <div className={classes.AccountDetailsItemDescription}>{contactEmail}</div>
                    <div className={classes.AccountDetailsItemDescription}>{contactPhone}</div>
                  </div>
                )}
                <div className={classes.AccountDetailsItemWrapper}>
                  <div className={classes.AccountDetailsItemSubTitle}>FIPS Code</div>
                  <div className={classes.AccountDetailsItemDescription}>{fipsCode}</div>
                </div>
                <div className={classes.AccountDetailsItemWrapper}>
                  <div className={classes.AccountDetailsItemSubTitle}>Effective Date</div>
                  <div className={classes.AccountDetailsItemDescription}>{effectiveDate}</div>
                </div>
                {dueDate && (
                  <div className={classes.AccountDetailsItemWrapper}>
                    <div className={classes.AccountDetailsItemSubTitle}>Due Date</div>
                    <div className={classes.AccountDetailsItemDescription}>{dueDate}</div>
                  </div>
                )}
              </div>
              <div className={classes.AccountDetailsColumn}>
                <div className={classes.AccountDetailsItemWrapper}>
                  <div className={classes.AccountDetailsItemSubTitle}>
                    {brokerName ? `${brokerName} (Broker)` : ''}
                  </div>
                  <div className={classes.AccountDetailsItemDescription}>{brokerEmail}</div>
                  <div className={classes.AccountDetailsItemDescription}>{brokerPhone}</div>
                </div>
                <div className={classes.AccountDetailsItemWrapper}>
                  <div className={classes.AccountDetailsItemSubTitle}>SIC Code</div>
                  <div className={classes.AccountDetailsItemDescription}>{sicCode}</div>
                </div>
                <div className={classes.AccountDetailsItemWrapper}>
                  <div className={classes.AccountDetailsItemSubTitle}>Rating Area ID</div>
                  <div className={classes.AccountDetailsItemDescription}>{ratingArea}</div>
                </div>
                {!_.isEmpty(licenseNumber) && (
                  <div className={classes.AccountDetailsItemWrapper}>
                    <div className={classes.AccountDetailsItemSubTitle}>
                      License#: {licenseNumber?.license_number}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {onEditAccount && (
            <div className={classes.AccountDetailsEditContent}>
              <Button title="Edit" type="secondary" onClick={onEditAccount} />

              {onDeleteAccount && <i className="fa fa-trash-o" onClick={onDeleteAccount} />}
            </div>
          )}
        </div>
      )}

      {onClickBackButton && (
        <i
          className={`fas fa-arrow-left ${classes.AccountDetailsArrow}`}
          onClick={onClickBackButton}
        />
      )}
    </div>
  );
};
