import moment from 'moment';
import { currencyFormatter } from 'src/constants/currency';
import { v4 as uuid } from 'uuid';

import ArrowIcon from 'src/assets/broker/arrow.svg';
import classes from 'src/sass/styles/OfferTable/offerTable.module.scss';
import { Tooltip } from '@mui/material';

const TABLE_NAMES = {
  is_employee: { title: 'Employee', key: 'totalEmployee' },
  is_employer: { title: 'Employer', key: 'totalEmployer' },
  is_member_rates: { title: 'Member Rates', key: 'total' },
  is_total: { title: 'Total', key: 'total' },
  is_life_total: { title: 'Total', key: 'totalTobacco' },
};

const renderTableHeading = (offers, key, options, isDropdown = true) => {
  const { conditions, setConditions } = options;

  const onChangeCondition = () => {
    setConditions((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <tr onClick={isDropdown ? onChangeCondition : () => {}} className={classes.OfferTableRateRow}>
      <th>
        {isDropdown && (
          <img
            src={ArrowIcon}
            alt="ArrowIcon"
            className={!conditions[key] ? classes.Arrow : classes.ArrowActive}
          />
        )}{' '}
        {TABLE_NAMES[key].title}
      </th>
      {offers?.map((offer) => (
        <th key={uuid()}>{currencyFormatter(offer?.calculation[TABLE_NAMES[key]?.key ?? ''])}</th>
      ))}
    </tr>
  );
};

// TODO: Need to optimize calculation logic to be like MDV calculation Logic

export const renderMDVTable = (offers, accountMembers, options) => {
  const { conditions } = options;

  const renderRate = (member, key) => {
    // let member_age = moment().diff(moment(member.date_of_birth), 'years');

    return (
      <tr key={uuid()}>
        <td>
          {member?.first_name} {member?.last_name}
        </td>
        {offers?.map((offer) => {
          const finded_member = offer.calculation[member.family_id].find(
            (item) => item.id === member.id,
          );

          // const finded_member = offer?.rate_tier_type.includes('tier')
          //   ? offer.calculation[member.family_id]
          //   : offer.calculation[member.id];

          return (
            <td key={uuid()}>
              {currencyFormatter(finded_member[key])}
              {member.relationship === 'employee' ? ` (${finded_member.type})` : ''}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      {renderTableHeading(offers, 'is_employee', options)}
      {conditions.is_employee &&
        accountMembers?.map((member) => {
          return renderRate(member, 'rate_employee');
        })}
      {renderTableHeading(offers, 'is_employer', options)}
      {conditions.is_employer &&
        accountMembers?.map((member) => {
          return renderRate(member, 'rate_employer');
        })}
      {renderTableHeading(offers, 'is_total', options)}
      {conditions.is_total &&
        accountMembers?.map((member) => {
          return renderRate(member, 'rate_total');
        })}
    </>
  );
};

export const renderAHCTable = (offers, accountMembers, options) => {
  const { conditions } = options;

  const renderRate = (member, key) => {
    let member_age = moment().diff(moment(member.date_of_birth), 'years');

    return (
      <tr key={uuid()}>
        <td>
          {member?.first_name} {member?.last_name}
        </td>
        {offers?.map((offer) => {
          const finded_member = offer?.rate_tier_type.includes('tier')
            ? offer.calculation[member.family_id]
            : offer.calculation[member.id];

          return (
            <td key={uuid()}>
              {offer?.rate_tier_type.includes('tier') && (
                <>
                  {member.relationship === 'employee'
                    ? currencyFormatter(finded_member[key])
                    : '--'}
                  {member.relationship === 'employee' ? ` (${finded_member.type})` : ''}
                </>
              )}
              {!offer?.rate_tier_type.includes('tier') && (
                <>
                  {currencyFormatter(finded_member)}
                  {` (${`${member_age} Y/O`})`}
                </>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      {renderTableHeading(offers, 'is_member_rates', options)}
      {conditions.is_member_rates &&
        accountMembers?.map((member) => {
          return renderRate(member, 'rate');
        })}
      {renderTableHeading(offers, 'is_total', options, false)}
    </>
  );
};

export const renderLifeTable = (offers, accountMembers, options) => {
  const { conditions } = options;

  return (
    <>
      {renderTableHeading(offers, 'is_life_total', options)}
      {conditions.is_life_total &&
        accountMembers?.map((member) => {
          return (
            <tr key={member.id}>
              <td>
                <Tooltip
                  title={`Salary: ${
                    currencyFormatter(member?.salary_amount)?.replace('.00', '') ||
                    currencyFormatter(0)?.replace('.00', '')
                  } \n Tobacco user: ${member?.is_tobacco_user ? 'Yes' : 'No'}`}
                >
                  <span>
                    {member?.first_name} {member?.last_name}
                  </span>
                </Tooltip>
              </td>
              {offers.map((selectedPlan) => {
                const searchMember = selectedPlan?.calculation[member?.family_id]?.find(
                  (item) => item?.id === member?.id,
                );

                return (
                  <td key={selectedPlan?.id}>
                    {searchMember?.tobacco ? `${currencyFormatter(searchMember?.tobacco)}` : ''}
                    {searchMember?.nonTobacco
                      ? `${currencyFormatter(searchMember?.nonTobacco)}`
                      : ''}
                  </td>
                );
              })}
            </tr>
          );
        })}
      {renderTableHeading(offers, 'is_life_total', options, false)}
    </>
  );
};

export const renderDisabilityTable = (offers, accountMembers, options) => {
  const { conditions } = options;

  return (
    <>
      {renderTableHeading(offers, 'is_member_rates', options)}
      {conditions.is_member_rates &&
        accountMembers?.map((member) => {
          let memberAge = moment().diff(moment(member.date_of_birth), 'years');

          return (
            <tr key={member.id}>
              <td>
                <Tooltip
                  title={`Salary: ${
                    currencyFormatter(member?.salary_amount)?.replace('.00', '') ||
                    currencyFormatter(0)?.replace('.00', '')
                  }`}
                >
                  <span>
                    {member?.first_name} {member?.last_name}
                  </span>
                </Tooltip>
              </td>
              {offers.map((selectedPlan) => {
                const searchMember = selectedPlan?.calculation[member?.family_id]?.find(
                  (item) => item?.id === member?.id,
                );

                return (
                  <td key={selectedPlan?.id}>
                    {searchMember?.rate > 0 &&
                      `${currencyFormatter(searchMember?.rate ?? 0, 2)} (${
                        selectedPlan?.rate_tier_type === 'employee'
                          ? searchMember?.type
                          : `${memberAge} Y/O`
                      })`}
                  </td>
                );
              })}
            </tr>
          );
        })}
      {renderTableHeading(offers, 'is_member_rates', options, false)}
    </>
  );
};
