import _ from 'lodash';

const coverageLimit = 80;

const coverageAllAges = (rate) => {
  let ages = { age_0_14: !_.isEmpty(rate) ? rate['age_0'] : '' };

  for (let i = 15; i <= coverageLimit; i++) {
    ages = {
      ...ages,
      [`age_${i}`]: !_.isEmpty(rate) ? rate[`age_${i}`] : '',
    };
  }

  return ages;
};

let COVERAGE_ALL_AGES_NAMES = {
  age_0_14: 'Age 0-14',
};

for (let i = 15; i <= coverageLimit; i++) {
  COVERAGE_ALL_AGES_NAMES = {
    ...COVERAGE_ALL_AGES_NAMES,
    [`age_${i}`]: `Age ${i}`,
  };
}

export const normalizeAllAgeRates = (allAgeBandedRates) => {
  let payload = {
    ...allAgeBandedRates,
  };

  for (let i = 0; i <= 14; i++) {
    payload = {
      ...payload,
      [`age_${i}`]: allAgeBandedRates['age_0_14'],
    };
  }

  delete payload['age_0_14'];

  return payload;
};

export { coverageAllAges, COVERAGE_ALL_AGES_NAMES };
