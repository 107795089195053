import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';
import {
  normalizeDisabilityPlanType,
  normalizeDisabilityRateBasis,
  normalizeDisabilityRateType,
  normalizeLtdBenefitPeriod,
  normalizeStdBenefitPeriod,
} from 'src/constants/requestProposal';
import { capitalize } from './exportXlsx';

export const normalizeDisabilityMemberExport = (
  worksheet,
  disabilityQuotes,
  member,
  groupMembers,
) => {
  worksheet.addRow(['Term Disability']).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'e6b8b7',
    },
  };

  for (const quote in disabilityQuotes) {
    const isLtdQuote = !_.isEmpty(disabilityQuotes[quote].find((item) => item.plan_type === 'ltd'));
    const isStdQuote = !_.isEmpty(disabilityQuotes[quote].find((item) => item.plan_type === 'std'));
    const isCurrentRateRow = !_.isEmpty(
      disabilityQuotes[quote].find((item) => item.is_current_rate),
    );

    let carrierNameRow = ['Carrier'];
    let planTypeRow = ['Plan Type'];
    let companyContributionRow = ['Company Contribution'];
    let pctOfIncomeRequestRow = ['% of Income Requested'];
    let ltdMonthlyMax = ['LTD Monthly Max'];
    let ltdElimPeriod = ['LTD Elimination Period'];
    let ltdBenPeriod = ['LTD Benefit Period'];
    let stdWeeklyMax = ['STD Weekly Max'];
    let stdElimPeriod = ['STD Elimination Period (Accident/Sickness)'];
    let stdBenPeriod = ['STD Benefit Period'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let perUnitRow = ['$ Per Unit'];
    let rateBasis = ['Rate Basis'];
    let quoteRows = [carrierNameRow, planTypeRow, companyContributionRow, pctOfIncomeRequestRow];

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['Employee'];
    let rateRows = [rateTierTypeRow, eeRow];

    if (isLtdQuote) {
      quoteRows = [...quoteRows, ltdMonthlyMax, ltdElimPeriod, ltdBenPeriod, currentlyOfferedRow];
    }
    if (isStdQuote) {
      quoteRows = [...quoteRows, stdWeeklyMax, stdElimPeriod, stdBenPeriod, currentlyOfferedRow];
    }

    if (isCurrentRateRow) {
      quoteRows = [...quoteRows, perUnitRow, rateBasis, []];
    }

    for (const disabilityQuote of disabilityQuotes[quote]) {
      carrierNameRow.push(disabilityQuote.issuer_name);
      planTypeRow.push(normalizeDisabilityPlanType(disabilityQuote.plan_type));
      companyContributionRow.push(capitalize(disabilityQuote.contribution_type));
      pctOfIncomeRequestRow.push(`${disabilityQuote.income_pct}%`);

      if (disabilityQuote.plan_type === 'ltd') {
        ltdMonthlyMax.push(`$${disabilityQuote?.ltd_monthly_max}`);
        ltdElimPeriod.push(`${disabilityQuote.ltd_elimination_period} Days`);
        ltdBenPeriod.push(normalizeLtdBenefitPeriod(disabilityQuote.ltd_benefit_period));
      }

      if (disabilityQuote.plan_type === 'std') {
        stdWeeklyMax.push(`$${disabilityQuote?.std_weekly_max}`);
        stdElimPeriod.push(disabilityQuote.std_elimination_period);
        stdBenPeriod.push(normalizeStdBenefitPeriod(disabilityQuote.std_benefit_period));
      }

      currentlyOfferedRow.push(disabilityQuote.is_current_rate ? 'Yes' : 'No');

      if (!disabilityQuote.is_current_rate) {
        perUnitRow.push('');
        rateBasis.push('');
      }

      if (disabilityQuote.is_current_rate) {
        perUnitRow.push(disabilityQuote.rate_per_unit);
        rateBasis.push(normalizeDisabilityRateBasis(disabilityQuote.rate_basis));
      }

      rateTierTypeRow.push(normalizeDisabilityRateType(disabilityQuote.rate_tier_type));
      eeRow.push(currencyFormatter(disabilityQuote.rate_ee));
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        worksheet.addRow(row).font = { bold: true };
      } else {
        worksheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    for (const row of rateRows) {
      worksheet.addRow(row).getCell(1).font = { bold: true };
    }

    const filteredMembers = groupMembers?.filter((item) => item.family_id === member.family_id);

    const updatedQuotes = [];

    for (const item of disabilityQuotes[quote]) {
      let payload = { ...item };

      let total = 0;

      for (const groupMember of item.calculation[member.family_id]) {
        if (groupMember.rate) {
          total += groupMember.rate;
        }
      }

      payload.calculation = {
        ...item.calculation,
        total,
      };

      updatedQuotes.push(payload);
    }

    const addSection = (sectionTitle, sectionValue, sectionRate) => {
      let sectionRow = [sectionTitle];

      for (const item of updatedQuotes) {
        sectionRow.push(currencyFormatter(item.calculation[sectionValue]));
      }

      const sectionRowStyle = worksheet.addRow(sectionRow);
      sectionRowStyle.eachCell(
        (cell) =>
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'ffff00',
            },
          }),
      );
      sectionRowStyle.font = { bold: true };

      const rates = [];

      for (const groupMember of filteredMembers) {
        let updatedMember = [`${groupMember.first_name} ${groupMember.last_name}`];

        for (const item of updatedQuotes) {
          const quoteMember = item.calculation[groupMember.family_id].find(
            (item) => item.id === groupMember.id,
          );

          updatedMember.push(currencyFormatter(quoteMember[sectionRate]));
        }

        rates.push(updatedMember);
      }

      for (const rate of rates) {
        worksheet.addRow(rate).getCell(1).font = { bold: true };
      }
    };

    addSection('Total', 'total', 'rate');

    for (let rowIndex = 2; rowIndex <= worksheet.rowCount; rowIndex++) {
      worksheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
    worksheet.addRow([]);
  }
};
