import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';

import classes from '../planConfig.module.scss';

export const PlanConfigAgeRates = (props) => {
  const { build } = props;
  const { coverage, form_options } = build;
  const { form_data, setFormData } = form_options;

  const [localFormData, setLocalFormData] = useState(form_data); // Local state for inputs

  const rate_tier_type = form_data.rate_tier_type;

  useEffect(() => {
    setLocalFormData(form_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form_data.rate_tier_type, form_data.is_uni_tobacco]);
  // trigger only when rate_tier_type of is_uni_tobacco changed

  useEffect(() => {
    const debounce = setTimeout(() => {
      setFormData(localFormData);
    }, 300);

    return () => clearTimeout(debounce);
  }, [localFormData, setFormData]); // Trigger on localFormData changes

  const updateGroupedValues = (range, suffix, value) => {
    const updates = {};
    range.forEach((age) => {
      updates[`age_${age}${suffix}`] = value;
    });
    return updates;
  };

  const getAgeRange = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => i + start);

  const onChangeGroupedInput = (groupKey, value) => {
    const suffix = groupKey.includes('_uni_tobacco')
      ? '_uni_tobacco'
      : groupKey.includes('_tobacco')
      ? '_tobacco'
      : groupKey.includes('_non_tobacco')
      ? '_non_tobacco'
      : '';

    const updates = {};
    const maxAge = coverage !== 'medical' ? 80 : 65;

    if (rate_tier_type === 'all_ages') {
      if (groupKey.includes('0-14')) {
        Object.assign(updates, updateGroupedValues(getAgeRange(0, 14), suffix, value));
      } else {
        const start = parseInt(groupKey.split('_')[0], 10);
        const end = parseInt(groupKey.split('_')[1], 10);
        Object.assign(updates, updateGroupedValues(getAgeRange(start, end), suffix, value));
      }
    } else if (rate_tier_type === 'banded_5') {
      if (groupKey.includes('0-17')) {
        Object.assign(updates, updateGroupedValues(getAgeRange(0, 17), suffix, value));
      } else if (groupKey.includes('18-24')) {
        Object.assign(updates, updateGroupedValues(getAgeRange(18, 24), suffix, value));
      } else {
        const start = parseInt(groupKey.split('_')[0], 10);
        const end = Math.min(start + 4, maxAge);
        Object.assign(updates, updateGroupedValues(getAgeRange(start, end), suffix, value));
      }
    } else if (rate_tier_type === 'banded_10') {
      if (groupKey.includes('0-17')) {
        Object.assign(updates, updateGroupedValues(getAgeRange(0, 17), suffix, value));
      } else if (groupKey.includes('18-29')) {
        Object.assign(updates, updateGroupedValues(getAgeRange(18, 29), suffix, value));
      } else {
        const start = parseInt(groupKey.split('_')[0], 10);
        const end = Math.min(start + 9, maxAge);
        Object.assign(updates, updateGroupedValues(getAgeRange(start, end), suffix, value));
      }
    }

    // Update local state immediately
    setLocalFormData((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const renderGroupedInput = (label, groupKey, value) => (
    <TextField
      key={groupKey}
      label={label}
      onChange={(e) => onChangeGroupedInput(groupKey, e.target.value)}
      value={value}
      type="number"
      size="small"
      inputProps={{ step: 0.001, min: 0, max: 999999, inputMode: 'decimal' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      required
    />
  );

  const renderAgeGroups = (suffix = '', labelSuffix = '') => {
    const inputs = [];
    const maxAge = coverage !== 'medical' ? 80 : 65;

    if (rate_tier_type === 'all_ages') {
      inputs.push(
        renderGroupedInput(
          `Ages 0-14${labelSuffix}`,
          `0_14${suffix}`,
          localFormData[`age_0${suffix}`],
        ),
      );
      for (let start = 15; start <= maxAge; start++) {
        inputs.push(
          renderGroupedInput(
            `Age ${start}${labelSuffix}`,
            `${start}_${start}${suffix}`,
            localFormData[`age_${start}${suffix}`],
          ),
        );
      }
    } else if (rate_tier_type === 'banded_5') {
      inputs.push(
        renderGroupedInput(
          `Ages 0-17${labelSuffix}`,
          `0_17${suffix}`,
          localFormData[`age_0${suffix}`],
        ),
      );
      inputs.push(
        renderGroupedInput(
          `Ages 18-24${labelSuffix}`,
          `18_24${suffix}`,
          localFormData[`age_18${suffix}`],
        ),
      );
      for (let start = 25; start <= maxAge; start += 5) {
        const end = Math.min(start + 4, maxAge);
        inputs.push(
          renderGroupedInput(
            `Ages ${start}-${end}${labelSuffix}`,
            `${start}_${end}${suffix}`,
            localFormData[`age_${start}${suffix}`],
          ),
        );
      }
    } else if (rate_tier_type === 'banded_10') {
      inputs.push(
        renderGroupedInput(
          `Ages 0-17${labelSuffix}`,
          `0_17${suffix}`,
          localFormData[`age_0${suffix}`],
        ),
      );
      inputs.push(
        renderGroupedInput(
          `Ages 18-29${labelSuffix}`,
          `18_29${suffix}`,
          localFormData[`age_18${suffix}`],
        ),
      );
      for (let start = 30; start <= maxAge; start += 10) {
        const end = Math.min(start + 9, maxAge);
        inputs.push(
          renderGroupedInput(
            `Ages ${start}-${end}${labelSuffix}`,
            `${start}_${end}${suffix}`,
            localFormData[`age_${start}${suffix}`],
          ),
        );
      }
    }

    return inputs;
  };

  return (
    <>
      {coverage !== 'life' && <div className={classes.PlanConfigFormAges}>{renderAgeGroups()}</div>}
      {coverage === 'life' && (
        <>
          {form_data.is_uni_tobacco && (
            <div className={classes.PlanConfigFormAges}>
              {renderAgeGroups('_uni_tobacco', ' (Uni Tobacco)')}
            </div>
          )}
          {!form_data.is_uni_tobacco && (
            <>
              <div className={classes.PlanConfigFormAges}>
                {renderAgeGroups('_tobacco', ' (Tobacco)')}
              </div>
              <div className={classes.PlanConfigFormAges}>
                {renderAgeGroups('_non_tobacco', ' (Non-Tobacco)')}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
