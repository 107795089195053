import { currencyFormatter } from './currency';

export const normalizeRateTierType = (tierType) => {
  if (tierType === '3tier') {
    return '3 Tier';
  }

  if (tierType === '4tier') {
    return '4 Tier';
  }

  if (tierType === 'employee') {
    return 'Employee';
  }

  if (tierType === 'all_ages') {
    return 'All Ages';
  }

  if (tierType === 'banded_5') {
    return '5 Year Banded';
  }

  if (tierType === 'banded_10') {
    return '10 Year Banded';
  }
};

export const normalizeDisabilityRateBasis = (rateBasis) => {
  if (rateBasis === 'earnings') {
    return 'Earnings Amount';
  }

  if (rateBasis === 'benefit') {
    return 'Benefit Amount';
  }
};

export const normalizeDisabilityRateType = (rateType) => {
  if (rateType === 'employee') {
    return 'Employee';
  }
};

export const normalizeOrthodonticsValue = (value) => {
  if (value === 'none') {
    return 'None';
  }

  if (value === 'child') {
    return 'Child Only';
  }

  if (value === 'adult_child') {
    return 'Adult + Child';
  }
};

export const normalizeCompensationAmount = (data) => {
  const { comp_amount_pct, comp_pepm_dollar, comp_pppm_dollar } = data;
  if (comp_amount_pct) {
    return `Percent ${comp_amount_pct}%`;
  }

  if (comp_pepm_dollar) {
    return `PEPM $${comp_pepm_dollar}`;
  }

  if (comp_pppm_dollar) {
    return `PPPM $${comp_pppm_dollar}`;
  }
};

export const normalizeLtdBenefitPeriod = (benefitPeriod) => {
  if (benefitPeriod === 'SSRA') {
    return 'SSRA';
  }

  const updatedBenefitPeriod = Number(benefitPeriod);

  if (updatedBenefitPeriod === 2 || updatedBenefitPeriod === 5) {
    return `${updatedBenefitPeriod} Years`;
  }

  if (updatedBenefitPeriod === 65 || updatedBenefitPeriod === 67) {
    return `Age ${updatedBenefitPeriod}`;
  }
};

export const normalizeStdBenefitPeriod = (benefitPeriod) => {
  const updatedBenefitPeriod = Number(benefitPeriod);
  if (updatedBenefitPeriod === 1) {
    return `${updatedBenefitPeriod} Year`;
  }

  if (updatedBenefitPeriod === 2) {
    return `${updatedBenefitPeriod} Years`;
  }

  if (updatedBenefitPeriod > 10) {
    return `${updatedBenefitPeriod} Days`;
  }
};

export const normalizeDisabilityPlanType = (planType) => {
  if (planType === 'ltd') {
    return 'Long Term Disability';
  }

  if (planType === 'std') {
    return 'Short Term Disability';
  }
};

export const normalizeAccidentType = (type) => {
  if (type === 'off_job') {
    return 'Off the Job';
  }

  if (type === '24_hour') {
    return '24 hour';
  }
};

export const normalizeDeathBenefit = (deathBenefit) => {
  if (deathBenefit === '1xsalary') {
    return '1x Salary';
  }

  if (deathBenefit === '2xsalary') {
    return '2x Salary';
  }

  return currencyFormatter(deathBenefit);
};
