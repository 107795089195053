import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// import _, { isEmpty } from 'lodash';

import { getAccountData, getIsLoadingBrokerAccountData, setAccountMode } from 'src/store/broker';
import { getIsLoadingAccountProposal } from 'src/store/proposals';

import { ProposalHeader } from '../proposalHeader/ProposalHeader';
import { AccountDetails, Loader, LoaderWrapper } from 'src/components';

import { ROUTE } from 'src/constants/routes';

import classes from './accountInfo.module.scss';

export const AccountInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const accountData = useSelector(getAccountData);
  // const proposalData = useSelector(getAccountProposalSelector);
  // const groupMembers = useSelector(groupMembersSelector);

  // const isProposal = !_.isEmpty(accountData) && !_.isEmpty(proposalData) && isEmpty(groupMembers);

  const isLoadingProposal = useSelector(getIsLoadingAccountProposal);
  const isLoadingAccount = useSelector(getIsLoadingBrokerAccountData);

  const isReadOnlyMode = pathname.includes('/read-only');
  const accountId = params?.id;
  const proposalId = params?.proposalId;
  const teamId = params?.teamId;

  const onClickEdit = useCallback(() => {
    dispatch(setAccountMode('EDIT'));
    if (!teamId) {
      navigate(`${ROUTE.BROKER}/edit/${accountId}`);
    }

    if (teamId) {
      navigate(`/teams/${teamId}/accounts/edit/${accountId}`);
    }
  }, [dispatch, navigate, accountId, teamId]);

  // const onClickNext = useCallback(() => {
  //   if (teamId) {
  //     navigate(
  //       `/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`,
  //     );
  //   }

  //   if (!teamId) {
  //     navigate(`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/proposal-setup`);
  //   }
  // }, [navigate, teamId, accountData, proposalData]);

  return isLoadingAccount || isLoadingProposal ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <div className={classes.AccountInfo}>
      <ProposalHeader
        isReadOnlyMode={isReadOnlyMode}
        accountId={accountId}
        proposalId={proposalId}
        isEditable={true}
      />
      <AccountDetails
        readOnlyMode={isReadOnlyMode}
        previewImage={accountData?.logo_url}
        companyName={accountData?.company_name}
        addressStreet={accountData?.address_street}
        addressCity={accountData?.address_city}
        addressState={accountData?.address_state}
        addressZip={accountData?.address_zip_code}
        contactName={accountData?.contact_name}
        contactEmail={accountData?.contact_email}
        contactPhone={accountData?.contact_phone}
        fipsCode={accountData?.fips_code}
        effectiveDate={moment(accountData?.renewal_date).format('l')}
        brokerName={accountData?.broker?.display_name}
        brokerEmail={accountData?.broker?.email}
        brokerPhone={accountData?.broker?.phone_number}
        brokerData={accountData?.broker}
        sicCode={accountData?.sic_code}
        ratingArea={accountData?.rating_area_id}
        onChangeAccountImage={onClickEdit}
        onEditAccount={onClickEdit}
      />
      {/* {isProposal && (
        <div className={classes.AccountInfoNavigate}>
          <Button title="Next" type="primary" onClick={onClickNext} />
        </div>
      )} */}
    </div>
  );
};
