import React from 'react';

import { AdminRepsTable } from 'src/components';

import classes from './dashboardReps.module.scss';

export const DashboardReps = () => {
  return (
    <div className={classes.DashboardReps}>
      <div className={classes.DashboardRepsTitle}>Recent Active Reps</div>
      <div className={classes.DashboardRepsContent}>
        <AdminRepsTable isImpersonate={false} />
      </div>
    </div>
  );
};
