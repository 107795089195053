import React from 'react';

import { useVisionBuild } from './useVisionBuild/useVisionBuild';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

export const VisionQuotes = () => {
  const { build } = useVisionBuild();

  return <CoverageBuilder build={build} />;
};
