import { createSlice } from '@reduxjs/toolkit';
import {
  createPetQuote,
  deletePetQuote,
  fetchPetInsurancePlans,
  getPetQuotes,
  updatePetQuote,
} from './pet.actions';

const initialState = {
  petInsurancePlans: [],
  isLoadingPetInsurancePlans: false,

  isLoadingCreateQuote: false,

  petInsuranceData: {},
  isLoadingPetInsuranceData: false,
};

const petSlice = createSlice({
  name: 'pet',
  initialState,
  reducers: {
    setPetInsurancePlans: (state, action) => {
      state.petInsurancePlans = action.payload;
    },

    clearPetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPetInsurancePlans.pending, (state) => {
      state.isLoadingPetInsurancePlans = true;
    });
    builder.addCase(fetchPetInsurancePlans.fulfilled, (state, action) => {
      state.isLoadingPetInsurancePlans = false;
      state.petInsurancePlans = action.payload;
    });
    builder.addCase(fetchPetInsurancePlans.rejected, (state) => {
      state.isLoadingPetInsurancePlans = false;
    });

    builder.addCase(createPetQuote.pending, (state) => {
      state.isLoadingCreateQuote = true;
    });
    builder.addCase(createPetQuote.fulfilled, (state) => {
      state.isLoadingCreateQuote = false;
    });
    builder.addCase(createPetQuote.rejected, (state) => {
      state.isLoadingCreateQuote = false;
    });

    builder.addCase(updatePetQuote.pending, (state) => {
      state.isLoadingCreateQuote = true;
    });
    builder.addCase(updatePetQuote.fulfilled, (state) => {
      state.isLoadingCreateQuote = false;
    });
    builder.addCase(updatePetQuote.rejected, (state) => {
      state.isLoadingCreateQuote = false;
    });

    builder.addCase(getPetQuotes.pending, (state) => {
      state.isLoadingPetInsuranceData = true;
    });
    builder.addCase(getPetQuotes.fulfilled, (state, action) => {
      state.isLoadingPetInsuranceData = false;
      state.petInsuranceData = action.payload;
    });
    builder.addCase(getPetQuotes.rejected, (state) => {
      state.isLoadingPetInsuranceData = false;
    });

    builder.addCase(deletePetQuote.pending, (state) => {
      state.isLoadingPetInsuranceData = true;
    });
    builder.addCase(deletePetQuote.fulfilled, (state) => {
      state.isLoadingPetInsuranceData = false;
    });
    builder.addCase(deletePetQuote.rejected, (state) => {
      state.isLoadingPetInsuranceData = false;
    });
  },
});

export const { setPetInsurancePlans, clearPetState } = petSlice.actions;

export default petSlice.reducer;
