import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearHospitalConfigurationState } from './hospitalConfiguration.slice';

export const getHospitalPlans = createAsyncThunk(
  'hospital-configuration/get-hospital-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.hospitalConfiguration.getHospitalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getHospitalQuotePlans = createAsyncThunk(
  'hospital-configuration/get-hospital-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.hospitalConfiguration.getHospitalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearHospitalState = createAsyncThunk('hospital/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearHospitalConfigurationState());
});
