import {
  compMethodOptions,
  examCopayOptions,
  formattedValue,
  frameAllowanceOptions,
  frequencyOptions,
  fundingTypeOptions,
  materialsCopayOptions,
} from 'src/constants/coverage';
import { normalizeFundingType } from 'src/constants/proposalFormField';

export const useVisionIssuerBuild = () => {
  const build = {
    coverage: 'vision',
    title: 'Vision',
    form_options: {
      contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Frequency',
          key: 'frequency',
        },
        {
          title: 'Exam Copay',
          key: 'exam_copay',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Materials Copay',
          key: 'materials_copay',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Frame Allowance',
          key: 'frame_allowance',
          format: (value) => formattedValue(value),
        },
      ],
    },
    offer_details: [
      {
        key: 'funding_type',
        format: (value) => normalizeFundingType(value),
        options: { render_type: 'select', render_data: fundingTypeOptions },
      },
      {
        key: 'frequency',
        options: { render_type: 'select', render_data: frequencyOptions, type: 'other' },
      },
      {
        key: 'exam_copay',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: examCopayOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'materials_copay',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: materialsCopayOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'frame_allowance',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: frameAllowanceOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
