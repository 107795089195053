import React from 'react';

import { AdminRepsTable, PageHeader } from 'src/components';

import classes from './adminRepImpersonate.module.scss';

export const AdminRepImpersonate = () => {
  return (
    <div className={classes.AdminRepImpersonate}>
      <PageHeader title="Rep Impersonate" />
      <AdminRepsTable />
    </div>
  );
};
