import _ from 'lodash';
import ExcelJs from 'exceljs';
import saveAs from 'file-saver';

import { normalizeDentalExport } from './normalizeDentalExport';
import { normalizeVisionExport } from './normalizeVisionExport';
import { normalizeLifeExport } from './normalizeLifeExport';
import { normalizeDisabilityExport } from './normalizeDisabilityExport';
import { normalizeAccountExport } from './normalizeAccountExport';
import { normalizeMedicalQuoteExport } from './normalizeMedicalQuoteExport';
import { normalizeMedicalExport } from './normalizeMedicalExport';
import { normalizeAccidentExport } from './normalizeAccidentExport';
import { normalizeHospitalExport } from './normalizeHospitalExport';
import { normalizeCriticalExport } from './normalizeCriticalExport';
import { normalizeExecutiveExport } from './normalizeExecutiveExport';
import { normalizeMedicalRatesExport } from './normalizeMedicalRatesExport';
import { normalizeMedicalMemberExport } from './normalizeMedicalMemberExport';
import { normalizeDentalMemberExport } from './normalizeDentalMemberExport';
import { normalizeVisionMemberExport } from './normalizeVisionMemberExport';
import { normalizeLifeMemberExport } from './normalizeLifeMemberExport';
import { normalizeDisabilityMemberExport } from './normalizeDisabilityMemberExport';
import { normalizeAccidentMemberExport } from './normalizeAccidentMemberExport';
import { normalizeHospitalMemberExport } from './normalizeHospitalMemberExport';
import { normalizeCriticalMemberExport } from './normalizeCriticalMemberExport';
import { currencyFormatter } from 'src/constants/currency';
import { urlToImageFile } from './urlToImageFile';
import { normalizePetQuoteExport } from './normalizePetExport';
import { normalizePetMemberExport } from './normalizePetMemberExport';

export const capitalize = (string) => {
  return string ? string[0].toUpperCase() + string.slice(1) : '';
};

const columnAutoWidth = (worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0,
      );
    });
    column.width = maxColumnLength + 2;
  });
};

const computeHousehold4TierType = (roles) => {
  if (roles.length === 1 && roles[0] === 'employee') {
    return { type: 'EE' };
  }

  let spCount = 0;
  let chCount = 0;

  for (const role of roles) {
    if (role === 'spouse' || role === 'life_partner') {
      spCount++;
    } else if (role === 'child') {
      chCount++;
    }
  }

  if (spCount > 0 && chCount > 0) {
    return { type: 'FAM' };
  } else if (spCount > 0) {
    return { type: 'EE + SP' };
  } else if (chCount > 0) {
    return { type: 'EE + CH' };
  } else {
    return { type: 'FAM' };
  }
};

export const onDownloadMembersXlsx = (groupMembers, fileName, setValue) => () => {
  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members');

  groupMembersWorkSheet.addRow([
    'ee_id',
    'first_name',
    'last_name',
    'gender',
    'relationship',
    'zip_code',
    'date_of_birth',
    'Medical enrollment',
    'current_medical_plan_name',
    'Dental enrollment',
    'dental_plan_name',
    'Vision enrollment',
    'vision_plan_name',
    'life_benefit_amount',
    'is_tobacco_user',
    'salary_amount',
    'job_title',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  for (let columnIndex = 2; columnIndex <= groupMembersWorkSheet.columnCount; columnIndex++) {
    groupMembersWorkSheet.getColumn(columnIndex).numFmt =
      '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  columnAutoWidth(groupMembersWorkSheet);

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });

  if (setValue) {
    setValue(false);
  }
};

export const onDownloadProposalXlsx = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  executiveSummary,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const groupQuotes = proposalData?.groups[0].quotes;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;
  const petQuotes = proposalData?.pet_quotes;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `${updatedName} RFP Quote ${counter++}`,
      };
    }
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);

  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Sold Quotes',
    'selected',
  );
  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Proposals Summary',
    'approved',
  );

  normalizeMedicalQuoteExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalRatesExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, groupMembers, 'all');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers, 'all');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers, 'all');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers, 'all');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers, 'all');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers, 'all');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers, 'all');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers, 'all');
  normalizePetQuoteExport(ExcelJSWorkbook, petQuotes);

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Proposal.xlsx`,
    );
  });
};

export const onDownloadProposalRates = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  type,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;
  const petQuotes = rfpQuotes?.pet_quotes;

  const ExcelJSWorkbook = new ExcelJs.Workbook();

  for (const member of groupMembers) {
    if (member.relationship === 'employee') {
      const [firsPartId] = member.id.split('-');

      const memberWorkSheet = ExcelJSWorkbook.addWorksheet(
        `${member?.first_name} ${member?.last_name} ( ${member.family_id} | ${firsPartId} )`,
        {
          properties: { defaultColWidth: 20 },
        },
      );

      normalizeMedicalMemberExport(memberWorkSheet, medicalQuotes, member, groupMembers);
      normalizeDentalMemberExport(memberWorkSheet, dentalQuotes, member, groupMembers);
      normalizeVisionMemberExport(memberWorkSheet, visionQuotes, member, groupMembers);
      normalizeLifeMemberExport(memberWorkSheet, lifeQuotes, member, groupMembers);
      normalizeDisabilityMemberExport(memberWorkSheet, disabilityQuotes, member, groupMembers);
      normalizeAccidentMemberExport(memberWorkSheet, accidentQuotes, member, groupMembers);
      normalizeHospitalMemberExport(memberWorkSheet, hospitalQuotes, member, groupMembers);
      normalizeCriticalMemberExport(memberWorkSheet, criticalQuotes, member, groupMembers);
      normalizePetMemberExport(memberWorkSheet, petQuotes, member, groupMembers);
    }
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-employee worksheet${
        type === 'sold' ? '(sold)' : ''
      }.xlsx`,
    );
  });
};

const addComparisonSheet = (workbook, quotes, name) => {
  const carrierNames = ['Carrier', ''];

  const employeeRates = ['Employee Cost', ''];
  const employerRates = ['Employer Cost', ''];
  const totalRates = ['Total Monthly', ''];
  const quoteWorksheet = workbook.addWorksheet(name, {
    properties: { defaultColWidth: 20 },
  });

  for (const quoteId in quotes) {
    for (const quote of quotes[quoteId]) {
      carrierNames.push(quote.issuer_name);
      if (quote.calculation.totalEmployee) {
        employeeRates.push(currencyFormatter(quote.calculation.totalEmployee));
      }
      if (quote.calculation.totalEmployer) {
        employerRates.push(currencyFormatter(quote.calculation.totalEmployer));
      }
      totalRates.push(currencyFormatter(quote.calculation.total || quote.calculation.totalTobacco));
    }
  }

  quoteWorksheet.addRow(carrierNames);
  if (employeeRates.length !== 2) {
    quoteWorksheet.addRow(employeeRates);
  }
  if (employerRates.length !== 2) {
    quoteWorksheet.addRow(employerRates);
  }
  quoteWorksheet.addRow(totalRates);
};

export const onDownloadRatesComparison = async (
  accountData,
  proposalData,
  rfpQuotes,
  brokerData,
) => {
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });

  let longTermQuotes = {};
  let shortTermQuotes = {};

  for (const config_id in disabilityQuotes) {
    if (disabilityQuotes[config_id][0].plan_type === 'ltd') {
      longTermQuotes = {
        ...longTermQuotes,
        [config_id]: disabilityQuotes[config_id],
      };
    }

    if (disabilityQuotes[config_id][0].plan_type === 'std') {
      shortTermQuotes = {
        ...shortTermQuotes,
        [config_id]: disabilityQuotes[config_id],
      };
    }
  }

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  addComparisonSheet(ExcelJSWorkbook, medicalQuotes, 'Medical');
  addComparisonSheet(ExcelJSWorkbook, dentalQuotes, 'Dental');
  addComparisonSheet(ExcelJSWorkbook, visionQuotes, 'Vision');
  addComparisonSheet(ExcelJSWorkbook, lifeQuotes, 'Life');
  addComparisonSheet(ExcelJSWorkbook, shortTermQuotes, 'Short Term Disability');
  addComparisonSheet(ExcelJSWorkbook, longTermQuotes, 'Long Term Disability');
  addComparisonSheet(ExcelJSWorkbook, accidentQuotes, 'Accident');
  addComparisonSheet(ExcelJSWorkbook, hospitalQuotes, 'Hospital');
  addComparisonSheet(ExcelJSWorkbook, criticalQuotes, 'Critical Illness');

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Comparison Rates.xlsx`,
    );
  });
};

export const onDownloadSoldQuotesXlsx = async (
  accountData,
  brokerData,
  proposalData,
  rfpQuotes,
  executiveSummary,
) => {
  const groupMembers = proposalData?.groups[0].members;
  const groupQuotes = proposalData?.groups[0].quotes;
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `Sold ${updatedName} Quote ${counter++}`,
      };
    }
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  let householdRoles = {};

  for (const member of groupMembers) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    groupMembers,
    summaryQuoteNames,
    'Sold Quotes',
    'selected',
  );

  normalizeMedicalQuoteExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalRatesExport(ExcelJSWorkbook, groupQuotes, groupMembers);
  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, groupMembers, 'sold');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers, 'sold');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers, 'sold');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers, 'sold');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers, 'sold');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers, 'sold');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers, 'sold');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers, 'sold');

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Sold Quotes.xlsx`,
    );
  });
};

export const onDownloadBrokerRequestedProposalsXlsx = async (
  accountData,
  proposalData,
  brokerData,
  members,
  rfpQuotes,
) => {
  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const quoteNames = {
    medicalQuotes,
    dentalQuotes,
    visionQuotes,
    lifeQuotes,
    disabilityQuotes,
    accidentQuotes,
    hospitalQuotes,
    criticalQuotes,
  };

  let summaryQuoteNames = {};

  for (const quoteName in quoteNames) {
    const updatedName = capitalize(quoteName.replace('Quotes', ''));

    let counter = 1;
    for (const quoteId in quoteNames[quoteName]) {
      summaryQuoteNames = {
        ...summaryQuoteNames,
        [quoteId]: `${updatedName} RFP Quote ${counter++}`,
      };
    }
  }

  let executiveSummary = {};

  for (const quoteName in quoteNames) {
    const updatedName = quoteName.replace('Quotes', '');
    const executiveSummaryPayload = [];
    for (const quoteId in quoteNames[quoteName]) {
      for (const quote of quoteNames[quoteName][quoteId]) {
        const payload = {
          ...quote,
          config_id: quoteId,
          status: 'selected',
          name: 'Broker',
        };
        executiveSummaryPayload.push(payload);
      }
    }
    executiveSummary = {
      ...executiveSummary,
      [updatedName]: executiveSummaryPayload,
    };
  }

  const ExcelJSWorkbook = new ExcelJs.Workbook();

  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Viison enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of members) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of members) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  normalizeExecutiveExport(
    ExcelJSWorkbook,
    executiveSummary,
    members,
    summaryQuoteNames,
    'Proposals Summary',
    'approved',
  );

  normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, members, 'all');
  normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, members, 'all');
  normalizeVisionExport(ExcelJSWorkbook, visionQuotes, members, 'all');
  normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, members, 'all');
  normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, members, 'all');
  normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, members, 'all');
  normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, members, 'all');
  normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, members, 'all');

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Broker Requsted RFPs.xlsx`,
    );
  });
};

export const onDownloadCarrierProposalXlsx = async (
  accountData,
  proposalData,
  brokerData,
  members,
  rfpQuotes,
) => {
  const groupMembers = members;

  const medicalQuotes = rfpQuotes?.medical;
  const dentalQuotes = rfpQuotes?.dental;
  const visionQuotes = rfpQuotes?.vision;
  const lifeQuotes = rfpQuotes?.life;
  const disabilityQuotes = rfpQuotes?.disability;
  const accidentQuotes = rfpQuotes?.accident;
  const hospitalQuotes = rfpQuotes?.hospital;
  const criticalQuotes = rfpQuotes?.critical;

  const ExcelJSWorkbook = new ExcelJs.Workbook();
  const accountWorkSheet = ExcelJSWorkbook.addWorksheet('Account', {
    properties: { defaultColWidth: 40 },
  });
  const groupMembersWorkSheet = ExcelJSWorkbook.addWorksheet('Group Members', {
    properties: { defaultColWidth: 20 },
  });

  normalizeAccountExport(accountWorkSheet, accountData, brokerData);

  const image_file = await urlToImageFile(accountData.logo_url);
  const image_id = ExcelJSWorkbook.addImage({
    base64: image_file,
    extension: 'jpeg',
  });

  accountWorkSheet.spliceRows(1, 0, []);

  accountWorkSheet.addImage(image_id, {
    tl: { col: 0, row: 0 },
    ext: { width: 128, height: 128 },
  });

  accountWorkSheet.getRow(1).height = 128;

  groupMembersWorkSheet.addRow([
    'Employee ID',
    'First Name',
    'Last Name',
    'Gender',
    'Relationship',
    'Zip Code',
    'Date of Birth',
    'Medical enrollment',
    'Current Medical Plan',
    'Dental enrollment',
    'Current Dental',
    'Vision enrollment',
    'Current Vision',
    'Current Life',
    'Tobacco',
    'Salary',
    'Job',
  ]).font = { bold: true };

  let householdRoles = {};

  for (const member of members) {
    if (householdRoles[member.family_id]) {
      householdRoles[member.family_id].push(member.relationship);
    } else {
      householdRoles[member.family_id] = [member.relationship];
    }
  }
  const household4TierTypes = {};

  for (const eid in householdRoles) {
    const roles = householdRoles[eid];
    household4TierTypes[eid] = computeHousehold4TierType(roles);
  }

  for (const member of groupMembers) {
    const payload = [
      member?.family_id,
      member?.first_name,
      member?.last_name,
      member?.gender,
      member?.relationship,
      member?.zip_code,
      member?.date_of_birth,
      member?.relationship === 'employee' && member?.current_medical_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.current_medical_plan_name,
      member?.relationship === 'employee' && member?.dental_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.dental_plan_name,
      member?.relationship === 'employee' && member?.vision_plan_name
        ? household4TierTypes[member?.family_id]?.type
        : '',
      member?.vision_plan_name,
      member?.life_benefit_amount,
      member?.is_tobacco_user ? 'yes' : 'no',
      member?.salary_amount,
      member?.job_title,
    ];

    groupMembersWorkSheet.addRow(payload);
  }

  for (let columnIndex = 2; columnIndex <= groupMembersWorkSheet.columnCount; columnIndex++) {
    groupMembersWorkSheet.getColumn(columnIndex).numFmt =
      '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  columnAutoWidth(groupMembersWorkSheet);

  if (!_.isEmpty(dentalQuotes)) {
    normalizeMedicalExport(ExcelJSWorkbook, medicalQuotes, members);
  }

  if (!_.isEmpty(dentalQuotes)) {
    normalizeDentalExport(ExcelJSWorkbook, dentalQuotes, groupMembers);
  }

  if (!_.isEmpty(visionQuotes)) {
    normalizeVisionExport(ExcelJSWorkbook, visionQuotes, groupMembers);
  }

  if (!_.isEmpty(lifeQuotes)) {
    normalizeLifeExport(ExcelJSWorkbook, lifeQuotes, groupMembers);
  }

  if (!_.isEmpty(disabilityQuotes)) {
    normalizeDisabilityExport(ExcelJSWorkbook, disabilityQuotes, groupMembers);
  }

  if (!_.isEmpty(accidentQuotes)) {
    normalizeAccidentExport(ExcelJSWorkbook, accidentQuotes, groupMembers);
  }

  if (!_.isEmpty(hospitalQuotes)) {
    normalizeHospitalExport(ExcelJSWorkbook, hospitalQuotes, groupMembers);
  }

  if (!_.isEmpty(criticalQuotes)) {
    normalizeCriticalExport(ExcelJSWorkbook, criticalQuotes, groupMembers);
  }

  for (let rowIndex = 1; rowIndex <= accountWorkSheet.rowCount; rowIndex++) {
    accountWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  for (let rowIndex = 1; rowIndex <= groupMembersWorkSheet.rowCount; rowIndex++) {
    groupMembersWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }

  ExcelJSWorkbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${accountData?.company_name}-${proposalData?.name}-Carrier Proposal.xlsx`,
    );
  });
};
