import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useHospitalBuild } from './useHospitalBuild/useHospitalBuild';

export const HospitalQuotes = () => {
  const { build } = useHospitalBuild();

  return <CoverageBuilder build={build} />;
};
