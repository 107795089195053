import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserTypeSelector } from 'src/store/app';

import { ROUTE } from 'src/constants/routes';
import { Button } from 'src/components';

import { AboutImage, GraphicIcon, MedalIcon, VerifyIcon } from 'src/assets/pages';
import classes from './about.module.scss';

const AboutSectionItem = (props) => {
  const { title, subTitle } = props;

  return (
    <div className={classes.AboutSectionItem}>
      <div className={classes.AboutSectionItemTitle}>{title}</div>
      <div className={classes.AboutSectionItemSubTitle}>{subTitle}</div>
    </div>
  );
};

const AboutImageItem = (props) => {
  const { icon, title } = props;

  return (
    <div className={classes.AboutImageItem}>
      <img src={icon} alt={icon} />
      <div className={classes.AboutImageItemTitle}>{title}</div>
    </div>
  );
};

export const About = () => {
  const navigate = useNavigate();

  const userType = useSelector(getUserTypeSelector);

  const renderButton = useCallback(() => {
    let title = '';

    const onClickGetStrted = () => navigate(ROUTE.SIGNUP);
    const onClickDashboard = () => navigate(userType === 'broker' ? ROUTE.BROKER : '/rep/my-rfps');

    if (!userType) {
      title = 'Get Started';
    }

    if (userType) {
      title = 'Go to Dashboard';
    }

    return (
      <Button
        onClick={!userType ? onClickGetStrted : onClickDashboard}
        title={title}
        type="primaryPurple"
        size="l"
      />
    );
  }, [navigate, userType]);

  return (
    <div className={classes.About}>
      <div className={classes.AboutSection}>
        <div className={classes.AboutHeader}>ABOUT US</div>
        <div className={classes.AboutTitle}>
          The next generation of benefits sales marketing is here.
        </div>
        <div className={classes.AboutSubTitle}>
          With over 20 years in the industry, we’ve seen first hand the manual steps needed between
          employers, brokers and carriers to get quotes and then get a policy issued. Fast track
          that entire process from step 1 with Monark’s award winning and patent pending technology
          allowing you to track hundreds of Quotes and RFPs across dozens of carriers. Carriers
          reply faster since groups are pre formatted for them to keep their back office happy.
          Close higher volumes of business and do it faster than you ever thought possible with
          Monark.
        </div>

        <div className={classes.AboutContent}>
          <AboutSectionItem title="1000+" subTitle="Employers" />
          <AboutSectionItem title="100+" subTitle="Brokers" />
          <AboutSectionItem title="100+" subTitle="Carriers" />
        </div>

        <div className={classes.AboutButton}>{renderButton()}</div>
      </div>
      <div className={classes.AboutImageSection}>
        <img src={AboutImage} alt="AboutImage" />
        <div className={classes.AboutImageSectionContent}>
          <AboutImageItem icon={GraphicIcon} title="Financial Modeling" />
          <AboutImageItem icon={MedalIcon} title="Deductibles and Premiums" />
          <AboutImageItem icon={VerifyIcon} title="Advanced Analytics" />
        </div>
      </div>
    </div>
  );
};
