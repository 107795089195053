import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from 'src/constants/routes';
import { REACT_APP_VERSION } from 'src/constants/env';

import { FacebookIcon, SnapchatIcon, WhatsappIcon } from 'src/assets/pages';
import MonarkLogo from 'src/assets/header/monarkLogo.svg';
import classes from './footer.module.scss';

const FooterItem = (props) => {
  const { title, route } = props;

  const navigate = useNavigate();

  const onClickItem = useCallback(() => {
    if (route) {
      navigate(route);
    }
  }, [navigate, route]);

  return (
    <div className={classes.FooterItem}>
      <div className={classes.FooterItemTitle} onClick={onClickItem}>
        {title}
      </div>
    </div>
  );
};

export const Footer = () => {
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterContent}>
        <img src={MonarkLogo} alt="MonarkLogo" />

        <div className={classes.FooterSubTitle}>
          Welcome to Monark, your unfair advantage in employee benefits sales
        </div>
        <div className={classes.FooterNavigation}>
          <FooterItem title="Benefits Quoting" route={ROUTE.BENEFITSQUOTING} />
          <FooterItem title="Pricing" route={ROUTE.PRICING} />
          <FooterItem title="About Our Company" route={ROUTE.ABOUT} />
          <FooterItem title="Terms of Service Agreement" route={ROUTE.TERMS} />
        </div>

        <div className={classes.FooterSocialMedia}>
          <img src={WhatsappIcon} alt="WhatsappIcon" />
          <img src={SnapchatIcon} alt="SnapchatIcon" />
          <img src={FacebookIcon} alt="FacebookIcon" />
        </div>
      </div>
      <div className={classes.FooterAppVersion}>v{REACT_APP_VERSION}</div>
    </div>
  );
};
