import React from 'react';
import PropTypes from 'prop-types';

import classes from './ribbon.module.scss';

export const Ribbon = (props) => {
  const { title } = props;

  return <div className={classes.Ribbon}>{title}</div>;
};

Ribbon.propTypes = {
  title: PropTypes.string,
};
