import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import _ from 'lodash';

import { auth } from 'src/app/database';

import { ROUTE } from 'src/constants/routes';

import { IssuerRequestsTable } from './components';
import { Loader, LoaderWrapper, MUISelect, PageHeader } from 'src/components';

import { getUserTypeSelector } from 'src/store/app';
import {
  getLinkedRfp,
  getLinkedRfpSelector,
  getIsLoadingLinkedRfp,
  getOtherRfp,
  getOtherRfpSelector,
  getIsLoadingOtherRfp,
  setIssuerToken,
} from 'src/store/issuer';

import { coverageOptions, statusOptions, dateOptions } from './issuerOptions';

import ArrowIcon from 'src/assets/broker/arrow.svg';
import classes from './issuerRequests.module.scss';
import { ERROR } from 'src/constants/errorNames';

export const IssuerRequests = () => {
  const dispatch = useDispatch();
  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const linkedRfp = useSelector(getLinkedRfpSelector);
  const otherRfp = useSelector(getOtherRfpSelector);
  const isLoadingLinkedRfp = useSelector(getIsLoadingLinkedRfp);
  const isLoadingOtherRfp = useSelector(getIsLoadingOtherRfp);
  const userType = useSelector(getUserTypeSelector);

  const issuerToken = searchParams.get('token');
  const issuerId = params?.issuerId;

  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedCoverage, setSelectedCoverage] = useState('all');
  const [selectedDate, setSelectedDate] = useState('all');

  const [isVisibleOther, setIsVisibleOther] = useState(false);

  useEffect(() => {
    if (issuerToken) {
      dispatch(setIssuerToken(issuerToken));
    }
  }, [dispatch, issuerToken]);

  useEffect(() => {
    if (userType && userType === 'broker') {
      navigate(ROUTE.BROKER);
      toast(ERROR.INACCESSABLE, { type: 'error' });
    }
  }, [navigate, userType]);

  useEffect(() => {
    dispatch(getLinkedRfp({ issuerId, issuerToken, navigate }));
  }, [dispatch, navigate, issuerId, issuerToken]);

  useEffect(() => {
    if (isVisibleOther) {
      dispatch(getOtherRfp({ issuerId, issuerToken, navigate }));
    }
  }, [dispatch, navigate, issuerId, issuerToken, isVisibleOther]);

  const onSelectOption = useCallback((event, setSelectedOption) => {
    setSelectedOption(event.target.value);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (_.isEmpty(user)) {
      setIsLoading(true);
    }

    if (!_.isEmpty(user)) {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (linkedRfp.length > 0) {
      const { account_id, proposal_id, issuer_id } = linkedRfp[0];

      const url = `/issuer/${issuer_id}/rfps/accounts/${account_id}/proposals/${proposal_id}?token=${issuerToken}?issuerId=${issuer_id}`;

      if (_.isEmpty(user)) {
        return navigate(
          `/issuer-login?token=${issuerToken}&issuerId=${issuer_id}&continueUrl=${url}`,
        );
      }

      navigate(
        `/issuer/${issuer_id}/rfps/accounts/${account_id}/proposals/${proposal_id}?token=${issuerToken}&issuerId=${issuer_id}`,
      );
    }
  }, [navigate, linkedRfp, issuerToken, user]);

  const onClickLinkedRfp = useCallback(
    (linkedRfp, issuerId, accountId, proposalId) => () => {
      const url = `/issuer/${issuerId}/rfps/accounts/${accountId}/proposals/${proposalId}?token=${issuerToken}?issuerId=${issuerId}`;

      if (_.isEmpty(user)) {
        return navigate(
          `/issuer-login?token=${issuerToken}&issuerId=${issuerId}&continueUrl=${url}`,
        );
      }

      navigate(
        `/issuer/${issuerId}/rfps/accounts/${accountId}/proposals/${proposalId}?token=${issuerToken}&issuerId=${issuerId}`,
      );
    },
    [navigate, issuerToken, user],
  );

  const onClickOtherRfp = useCallback(
    (otherRfp, issuerId, accountId, proposalId) => () => {
      const url = `/issuer/${issuerId}/rfps/accounts/${accountId}/proposals/${proposalId}?token=${issuerToken}?issuerId=${issuerId}`;

      if (_.isEmpty(user)) {
        return navigate(
          `/issuer-login?token=${issuerToken}&issuerId=${issuerId}&continueUrl=${url}`,
        );
      }

      navigate(`/issuer/${issuerId}/rfps/accounts/${accountId}/proposals/${proposalId}`);
    },
    [issuerToken, navigate, user],
  );

  const onClickOtherRfpArrow = useCallback(() => {
    setIsVisibleOther((prev) => !prev);
  }, []);

  const arrowStyles = classNames({
    [classes.IssuerRequestsPageArrow]: !isVisibleOther,
    [classes.IssuerRequestsPageArrowRotate]: isVisibleOther,
  });

  return isLoading ? (
    <div className={classes.LoaderWrapper}>
      <Loader />
    </div>
  ) : (
    <div className={classes.IssuerRequests}>
      <div className={classes.IssuerRequestsContent}>
        <div className={classes.IssuerRequestsPage}>
          <PageHeader title="Linked RFP" />
          <div className={classes.IssuerRequestsRfpContent}>
            {isLoadingLinkedRfp ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <IssuerRequestsTable issuerRfp={linkedRfp} onRowClick={onClickLinkedRfp} />
            )}
          </div>
        </div>
        <div className={classes.IssuerRequestsPage}>
          <PageHeader
            name={
              <>
                <img
                  className={arrowStyles}
                  onClick={onClickOtherRfpArrow}
                  src={ArrowIcon}
                  alt="ArrowIcon"
                />
                Other RFPs
              </>
            }
          >
            <div className={classes.IssuerRequestsOtherContent}>
              <div className={classes.SelectOptionWrapper}>
                <MUISelect
                  label="Status"
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={(event) => onSelectOption(event, setSelectedStatus)}
                />
              </div>
              <div className={classes.SelectOptionWrapper}>
                <MUISelect
                  label="Coverage"
                  value={selectedCoverage}
                  options={coverageOptions}
                  onChange={(event) => onSelectOption(event, setSelectedCoverage)}
                />
              </div>
              <div className={classes.SelectOptionWrapper}>
                <MUISelect
                  label="Due Date"
                  value={selectedDate}
                  options={dateOptions}
                  onChange={(event) => onSelectOption(event, setSelectedDate)}
                />
              </div>
            </div>
          </PageHeader>
          {isVisibleOther && (
            <div className={classes.IssuerRequestsRfpContent}>
              {isLoadingOtherRfp ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <IssuerRequestsTable issuerRfp={otherRfp} onRowClick={onClickOtherRfp} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
