import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useDentalBuild } from './useDentalBuild/useDentalBuild';

export const DentalQuotes = () => {
  const { build } = useDentalBuild();

  return <CoverageBuilder build={build} />;
};
