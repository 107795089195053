import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import classes from './select.module.scss';

export const Select = (props) => {
  const { isRequired, value, options, label, tooltipText, onChange } = props;

  return (
    <div className={classes.Select}>
      {label && (
        <label>
          {label}
          {isRequired && <div className={classes.SelectRequired}>*</div>}
          {tooltipText && (
            <Tooltip title={tooltipText} className={classes.Tooltip}>
              <i className="far fa-question-circle fa-xs" />
            </Tooltip>
          )}
        </label>
      )}
      <select onChange={onChange} required={isRequired} value={value}>
        {options.map((item) => (
          <option key={item.code} value={item.code}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
};
