import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import {
  getIsLoadingRepOtherRfpSelector,
  getIssuerDataSelector,
  getMyRequestedProposals,
  getRepOtherRfpMeta,
  getRepOtherRfpSelector,
} from 'src/store/issuer';

import { coverageOptions, dateOptions } from '../../issuerOptions';

import {
  Button,
  EmptyField,
  Input,
  Loader,
  LoaderWrapper,
  MUISelect,
  PageHeader,
} from 'src/components';

import classes from './issuerRequestsTable.module.scss';

const headerRows = [
  { id: 'proposal_name', name: 'Proposal Name', sortable: true },
  { id: 'company_name', name: 'Company Name', sortable: true },
  { id: 'sic_code', name: 'Account SIC', sortable: true },
  { id: 'acc_zip_code', name: 'Account Zip Code', sortable: false },
  { id: 'coverage', name: 'Coverage', sortable: false },
  { id: 'ee_count', name: 'Employee Count', sortable: false },
  { id: 'broker', name: 'Broker', sortable: false },
  { id: 'effective_date', name: 'Effective Date', sortable: true },
  { id: 'due_date', name: 'Due Date', sortable: true },
];

export const IssuerRequestsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const issuerData = useSelector(getIssuerDataSelector);
  const repRequestedProposals = useSelector(getRepOtherRfpSelector);
  const isLoading = useSelector(getIsLoadingRepOtherRfpSelector);
  const rfpsMeta = useSelector(getRepOtherRfpMeta);

  const [selectedCoverage, setSelectedCoverage] = useState('all');
  const [selectedDate, setSelectedDate] = useState('all');

  const [searchValue, setSearchValue] = useState('');
  const [sortMethod, setSortMethod] = useState('-proposal_name');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const [currentPage, setCurrentPage] = useState(rfpsMeta?.page ? rfpsMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    rfpsMeta?.per_page ? rfpsMeta?.per_page : 10,
  );

  useEffect(() => {
    if (!_.isEmpty(issuerData)) {
      if (!searchValue) {
        const payload = {
          sort: sortMethod,
          page: currentPage,
          per_page: selectedPageSize,
          due_date: selectedDate === 'all' ? '' : selectedDate,
          coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
        };

        dispatch(getMyRequestedProposals({ repId: issuerData.id, payload }));
      }
    }
  }, [
    dispatch,
    issuerData,
    sortMethod,
    currentPage,
    searchValue,
    selectedDate,
    selectedCoverage,
    selectedPageSize,
  ]);

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  const onSelectOption = useCallback((event, setSelectedOption) => {
    setSelectedOption(event.target.value);
  }, []);

  const onChangeSearchInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearchButton = useCallback(() => {
    if (searchValue) {
      const payload = {
        q: searchValue,
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
        due_date: selectedDate === 'all' ? '' : selectedDate,
        coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
      };
      dispatch(getMyRequestedProposals({ repId: issuerData.id, payload }));
    }
  }, [
    dispatch,
    searchValue,
    sortMethod,
    currentPage,
    selectedPageSize,
    selectedDate,
    selectedCoverage,
    issuerData.id,
  ]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        const payload = {
          q: searchValue,
          sort: sortMethod,
          page: currentPage,
          per_page: selectedPageSize,
          due_date: selectedDate === 'all' ? '' : selectedDate,
          coverage_type: selectedCoverage === 'all' ? '' : selectedCoverage,
        };

        dispatch(getMyRequestedProposals({ repId: issuerData.id, payload }));
      }
    },
    [
      dispatch,
      searchValue,
      sortMethod,
      currentPage,
      selectedDate,
      selectedCoverage,
      selectedPageSize,
      issuerData.id,
    ],
  );

  const onClearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  const onRowClick = useCallback(
    (accountId, proposalId) => () => {
      navigate(`/rep/my-rfps/accounts/${accountId}/proposals/${proposalId}/account-info`);
    },
    [navigate],
  );

  return (
    <div className={classes.IssuerTable}>
      <PageHeader title="RFPs">
        <div className={classes.IssuerTablePageContent}>
          <div className={classes.IssuerTablePageContentSelect}>
            <MUISelect
              label="Coverage"
              value={selectedCoverage}
              options={coverageOptions}
              onChange={(event) => onSelectOption(event, setSelectedCoverage)}
              minWidth={140}
            />
          </div>
          <div className={classes.IssuerTablePageContentSelect}>
            <MUISelect
              label="Due Date"
              value={selectedDate}
              options={dateOptions}
              onChange={(event) => onSelectOption(event, setSelectedDate)}
            />
          </div>
          <div className={classes.IssuerTableSearch}>
            <div className={classes.IssuerTableSearchWrapper}>
              <Input
                label="Search"
                value={searchValue}
                onChange={onChangeSearchInput}
                onKeyDown={searchOnKeyDown}
              />
              {searchValue && (
                <i
                  onClick={onClearInput}
                  className={`fas fa-plus ${classes.IssuerTableSearchClear}`}
                />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-search"
              onClick={onClickSearchButton}
              isLoading={isLoading}
            />
          </div>
        </div>
      </PageHeader>

      <Paper>
        {isLoading ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <>
            {repRequestedProposals?.length > 0 ? (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        {headerRows?.map((item) => (
                          <TableCell key={item.id}>
                            {item.sortable ? (
                              <TableSortLabel
                                active={selectedCell === item.id}
                                direction={cellDirection}
                                onClick={onChangeCellSort(item.id)}
                              >
                                {item.name}
                              </TableSortLabel>
                            ) : (
                              item.name
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {repRequestedProposals.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            cursor: 'pointer',
                          }}
                          onClick={onRowClick(item?.account_id, item?.proposal_id)}
                          hover
                        >
                          <TableCell component="th" scope="row" width="150">
                            {item?.proposal_name}
                          </TableCell>
                          <TableCell component="th" scope="row" width="150">
                            {item?.company_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item?.account_sic_code}
                          </TableCell>
                          <TableCell component="th" scope="row" width="150">
                            {item?.account_zip_code}
                          </TableCell>
                          <TableCell component="th" scope="row" width="200">
                            {item?.lines_of_coverage?.join(', ')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item?.group_size}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item?.broker?.display_name}
                            <br />
                            {item?.broker?.address_street && `${item?.broker?.address_street} `}
                            {item?.broker?.address_city && `${item?.broker?.address_city}, `}
                            {item?.broker?.address_state && `${item?.broker?.address_state} `}
                            {item?.broker?.address_zip_code && `${item?.broker?.address_zip_code}`}
                            <br />
                            {item?.broker?.email && `${item?.broker?.email}`}
                            <br />
                            {item?.broker?.phone_number && `${item?.broker?.phone_number}`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(item?.effective_date).format('l')}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              color: moment().isAfter(moment(item?.due_date).format('MM-DD-YYYY'))
                                ? 'red'
                                : 'black',
                            }}
                          >
                            {moment(item?.due_date).format('l')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  component="div"
                  count={rfpsMeta?.total ?? 0}
                  rowsPerPage={selectedPageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onChangePageSize}
                />
              </>
            ) : (
              <EmptyField title="There are no available RFPs." />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
