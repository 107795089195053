import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';
import { toast } from 'react-toastify';
import { updateProposal } from '../proposals';
import { setGroupData, setGroupMembers } from './groupMembers.slice';

export const createProposalCensus = createAsyncThunk(
  'group-members/create-proposal-census',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, brokerId, members, org_id, navigate } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      // CREATE MEMBERS GROUP
      const groupDataResponse = await api.groupMembers.createMemberGroup(data);

      thunkApi.dispatch(setGroupData(groupDataResponse));

      const proposalPayload = {
        accountId,
        proposalId,
        accountProposalData: {
          group_ids: [groupDataResponse?.id],
          account_id: accountId,
          id: proposalId,
          broker_id: brokerId,
        },
      };

      // UPDATE PROPOSAL WITH GROUP_IDS
      thunkApi.dispatch(updateProposal(proposalPayload));

      // ASSIGN GROUP_ID TO MEMBERS
      for (const member of members) {
        member.group_id = groupDataResponse?.id;
      }

      // CREATE GROUPED MEMBERS
      const groupMembersPayload = {
        accountId,
        groupId: groupDataResponse?.id,
        members,
      };

      const groupMembersResponse = await api.groupMembers.createGroupMembers(groupMembersPayload);

      thunkApi.dispatch(setGroupMembers(groupMembersResponse));

      if (!org_id) {
        navigate(`/broker/accounts/${accountId}/proposals/${proposalId}/census`);
      }

      if (org_id) {
        navigate(`/teams/${org_id}/accounts/${accountId}/proposals/${proposalId}/census`);
      }
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const createGroupMembers = createAsyncThunk(
  'group-members/create-group-members',
  async (data, thunkApi) => {
    try {
      const { accountId, members } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const state = thunkApi.getState();
      const groupId = state?.groupMembers?.groupData?.id;

      for (const member of members) {
        member.group_id = groupId;
      }

      const payload = {
        accountId,
        groupId,
        members,
      };

      await api.groupMembers.createGroupMembers(payload);

      const response = await api.groupMembers.getGroupMembers(accountId, groupId);

      return response.items;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const getGroupMembers = createAsyncThunk('group-members/get-group-members', async (data) => {
  try {
    const { accountId, groupId } = data;

    const token = await auth.currentUser.getIdToken();
    api.apiClient.setToken(token);

    const response = await api.groupMembers.getGroupMembers(accountId, groupId);

    return response.items;
  } catch (err) {
    toast(err?.error, { type: 'error' });
  }
});

export const createCensusMember = createAsyncThunk(
  'group-members/create-census-member',
  async (data, thunkApi) => {
    try {
      const { accountId, groupId, payload } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      await api.groupMembers.createCensusMember(accountId, groupId, payload);

      thunkApi.dispatch(getGroupMembers({ accountId, groupId }));

      toast('Member created successfully', { type: 'success' });
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const updateCensusMember = createAsyncThunk(
  'group-members/update-census-member',
  async (data, thunkApi) => {
    try {
      const { accountId, groupId, memberId, payload, member, household } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      await api.groupMembers.updateCensusMember(accountId, groupId, memberId, payload);

      if (member.relationship === 'employee') {
        const employeeHousehold = household[member.family_id].filter(
          (item) => item.relationship !== 'employee',
        );

        const isChangedZipCode = payload.zip_code !== member.zip_code;
        const isChangedMedicalPlan =
          payload.current_medical_plan_name !== member.current_medical_plan_name;
        const isChangedDentalPlan = payload.dental_plan_name !== member.dental_plan_name;
        const isChangedVisionPlan = payload.vision_plan_name !== member.vision_plan_name;

        const isChangedValue =
          isChangedZipCode || isChangedMedicalPlan || isChangedDentalPlan || isChangedVisionPlan;

        if (isChangedValue) {
          const housholdPayload = {
            zip_code: payload.zip_code,
            current_medical_plan_name: payload.current_medical_plan_name,
            dental_plan_name: payload.dental_plan_name,
            vision_plan_name: payload.vision_plan_name,
          };

          for (const householdMember of employeeHousehold) {
            await api.groupMembers.updateCensusMember(
              accountId,
              groupId,
              householdMember.id,
              housholdPayload,
            );
          }
        }
      }

      thunkApi.dispatch(getGroupMembers({ accountId, groupId }));

      toast('Member updated successfully', { type: 'success' });
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const deleteCensusMember = createAsyncThunk(
  'group-members/delete-census-member',
  async (data, thunkApi) => {
    try {
      const { accountId, groupId, memberId } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      await api.groupMembers.deleteCensusMember(accountId, groupId, memberId);

      thunkApi.dispatch(getGroupMembers({ accountId, groupId }));

      toast('Member deleted successfully', { type: 'success' });
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);
