import _ from 'lodash';
import { currencyFormatter } from 'src/constants/currency';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { normalizeRateTierType } from 'src/constants/requestProposal';

export const normalizeVisionMemberExport = (worksheet, visionQuotes, member, groupMembers) => {
  worksheet.addRow(['Vision']).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'e6b8b7',
    },
  };

  for (const quote in visionQuotes) {
    const isOnly3Tier = _.isEmpty(
      visionQuotes[quote].find((item) => item.rate_tier_type === '4tier'),
    );
    const isOnly4Tier = _.isEmpty(
      visionQuotes[quote].find((item) => item.rate_tier_type === '3tier'),
    );
    const isTierTypes = !isOnly3Tier && !isOnly4Tier;

    let carrierNameRow = ['Carrier'];
    let contributionEeRow = ['Contribution EE'];
    let contributionDepRow = ['Contribution DEP'];
    let fundingTypeRow = ['Funding Type'];
    let frequencyRow = ['Frequency'];
    let examCopayRow = ['Exam Copay'];
    let materialsCopayRow = ['Materials Copay'];
    let frameAllowanceRow = ['Frame Allowance'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];
    let selectedPlanRow = ['Selected Plan Name'];

    let quoteRows = [
      carrierNameRow,
      contributionEeRow,
      contributionDepRow,
      fundingTypeRow,
      frequencyRow,
      examCopayRow,
      materialsCopayRow,
      frameAllowanceRow,
      currentlyOfferedRow,
      selectedPlanRow,
      [],
    ];

    let rateTierTypeRow = ['Rate Tier Type'];
    let eeRow = ['EE'];
    let eeOneRow = ['EE + 1'];
    let eeSpRow = ['EE + SP'];
    let eeChRow = ['EE + CH'];
    let famRow = ['FAM'];

    let rateRows = [];

    for (const visionQuote of visionQuotes[quote]) {
      carrierNameRow.push(visionQuote.issuer_name);
      contributionEeRow.push(
        visionQuote.contribution_type === 'percent'
          ? `${visionQuote.contribution_ee}%`
          : `${currencyFormatter(visionQuote.contribution_ee)}`,
      );
      contributionDepRow.push(
        visionQuote.contribution_type === 'percent'
          ? `${visionQuote.contribution_dep}%`
          : `${currencyFormatter(visionQuote.contribution_dep)}`,
      );
      fundingTypeRow.push(normalizeFundingType(visionQuote?.funding_type));
      frequencyRow.push(visionQuote.frequency);
      examCopayRow.push(currencyFormatter(visionQuote.exam_copay));
      materialsCopayRow.push(currencyFormatter(visionQuote.materials_copay));
      frameAllowanceRow.push(currencyFormatter(visionQuote.frame_allowance));

      currentlyOfferedRow.push(visionQuote.is_current_rate ? 'Yes' : 'No');
      selectedPlanRow.push(visionQuote.selected_plan_name);
      rateTierTypeRow.push(normalizeRateTierType(visionQuote.rate_tier_type));
      eeRow.push(visionQuote.rate_ee ? currencyFormatter(visionQuote.rate_ee) : '');

      if (visionQuote.rate_tier_type === '3tier') {
        eeOneRow.push(visionQuote.rate_ee_1 ? currencyFormatter(visionQuote.rate_ee_1) : '');
        eeSpRow.push('');
        eeChRow.push('');
      }

      if (visionQuote.rate_tier_type === '4tier') {
        eeSpRow.push(visionQuote.rate_ee_sp ? currencyFormatter(visionQuote.rate_ee_sp) : '');
        eeChRow.push(visionQuote.rate_ee_ch ? currencyFormatter(visionQuote.rate_ee_ch) : '');
        eeOneRow.push('');
      }

      famRow.push(visionQuote.rate_fam ? currencyFormatter(visionQuote.rate_fam) : '');

      if (isOnly3Tier && visionQuote.rate_tier_type === '3tier') {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, famRow];
      }

      if (isOnly4Tier && visionQuote.rate_tier_type === '4tier') {
        rateRows = [rateTierTypeRow, eeRow, eeSpRow, eeChRow, famRow];
      }

      if (isTierTypes) {
        rateRows = [rateTierTypeRow, eeRow, eeOneRow, eeSpRow, eeChRow, famRow];
      }
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        worksheet.addRow(row).font = { bold: true };
      } else {
        worksheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    for (const row of rateRows) {
      worksheet.addRow(row).getCell(1).font = { bold: true };
    }

    worksheet.addRow([]);

    const filteredMembers = groupMembers?.filter((item) => item.family_id === member.family_id);

    const updatedQuotes = [];

    for (const item of visionQuotes[quote]) {
      let payload = { ...item };

      let totalEmployee = 0;
      let totalEmployer = 0;
      let total = 0;

      for (const groupMember of item.calculation[member.family_id]) {
        if (groupMember.rate_employee) {
          totalEmployee += groupMember.rate_employee;
        }
        if (groupMember.rate_employer) {
          totalEmployer += groupMember.rate_employer;
        }
        if (groupMember.rate_total) {
          total += groupMember.rate_total;
        }
      }

      payload.calculation = {
        ...item.calculation,
        totalEmployee,
        totalEmployer,
        total,
      };

      updatedQuotes.push(payload);
    }

    const addSection = (sectionTitle, sectionValue, sectionRate) => {
      let sectionRow = [sectionTitle];

      for (const item of updatedQuotes) {
        sectionRow.push(currencyFormatter(item.calculation[sectionValue]));
      }

      const sectionRowStyle = worksheet.addRow(sectionRow);
      sectionRowStyle.eachCell(
        (cell) =>
          (cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'ffff00',
            },
          }),
      );
      sectionRowStyle.font = { bold: true };

      const rates = [];

      for (const groupMember of filteredMembers) {
        let updatedMember = [`${groupMember.first_name} ${groupMember.last_name}`];

        for (const item of updatedQuotes) {
          const quoteMember = item.calculation[groupMember.family_id].find(
            (item) => item.id === groupMember.id,
          );

          updatedMember.push(currencyFormatter(quoteMember[sectionRate]));
        }

        rates.push(updatedMember);
      }

      for (const rate of rates) {
        worksheet.addRow(rate).getCell(1).font = { bold: true };
      }
    };

    addSection('Employee', 'totalEmployee', 'rate_employee');
    addSection('Employer', 'totalEmployer', 'rate_employer');
    addSection('Total', 'total', 'rate_total');

    for (let rowIndex = 2; rowIndex <= worksheet.rowCount; rowIndex++) {
      worksheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }

    worksheet.getRow(1).getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'e6b8b7',
      },
    };

    worksheet.addRow([]);
  }
};
