import React from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prepareStatusColor } from 'src/constants/prepareStatusColor';

import classes from './status.module.scss';

export const Status = (props) => {
  const { status, size = 'small' } = props;

  const statusClasses = classNames({
    [classes.small]: size === 'small',
    [classes.medium]: size === 'medium',
  });

  return (
    <div className={statusClasses}>
      <Chip
        label={status?.toUpperCase()}
        color={prepareStatusColor(status)}
        size={size}
        style={{ minWidth: 80 }}
      />
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  size: PropTypes.string,
};
