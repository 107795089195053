import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from 'src/app/database';

import { Loader, LoaderWrapper } from 'src/components';
import { ROUTE } from 'src/constants/routes';

import classes from './springForward.module.scss';

export const SpringForward = () => {
  const navigate = useNavigate();

  useEffect(() => {
    analytics.logEvent('page_view');

    setTimeout(() => {
      navigate(ROUTE.SIGNUP);
    }, 1000);
  }, [navigate]);

  return (
    <div className={classes.SpringForward}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </div>
  );
};
