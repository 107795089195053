import Base from './base';

class Contats extends Base {
  createBrokerContact = (id, payload) => {
    delete payload.selectedOption;
    return this.apiClient.post(`brokers/${id}/contacts`, payload);
  };

  getAllBrokerContacts = (id, payload) => {
    return this.apiClient.get(`brokers/${id}/contacts`, payload);
  };

  getBrokerContact = (id, contactId) => {
    return this.apiClient.get(`brokers/${id}/contacts/${contactId}`);
  };

  updateBrokerContact = (brokerId, contactId, payload) => {
    delete payload.broker_id;
    delete payload.created_timestamp;
    delete payload.updated_timestamp;
    delete payload.last_active;

    return this.apiClient.put(`brokers/${brokerId}/contacts/${contactId}`, payload);
  };

  deleteBrokerContact = (id, contactId) => {
    return this.apiClient.delete(`brokers/${id}/contacts/${contactId}`);
  };

  getAllAgencyContacts = (org_id, payload) => {
    return this.apiClient.get(`orgs/${org_id}/contacts`, payload);
  };

  createAgencyContact = (org_id, payload) => {
    delete payload.selectedOption;
    return this.apiClient.post(`orgs/${org_id}/contacts`, payload);
  };

  getCarrierContacts = (params) => {
    return this.apiClient.get('reps', params);
  };

  updateAgencyContact = (org_id, contactId, payload) => {
    delete payload.org_id;
    delete payload.created_timestamp;
    delete payload.updated_timestamp;
    delete payload.last_active;

    return this.apiClient.put(`orgs/${org_id}/contacts/${contactId}`, payload);
  };

  deleteAgencyContact = (org_id, contactId) => {
    return this.apiClient.delete(`orgs/${org_id}/contacts/${contactId}`);
  };

  getUserByContactEmail = (email) => {
    return this.apiClient.get(`reps?f[email]=${email}`);
  };
}

export default Contats;
