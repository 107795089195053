import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'src/assets/broker/arrow.svg';
import classes from './pageHeader.module.scss';

export const PageHeader = (props) => {
  const { title, isVisibleContent, onClickBackButton, onClickArrowButton, children } = props;

  return (
    <div className={classes.PageHeader}>
      <div className={classes.PageHeaderWrapper}>
        {onClickBackButton && (
          <i
            className={`fas fa-arrow-left ${classes.PageHeaderArrow}`}
            onClick={onClickBackButton}
          />
        )}

        {onClickArrowButton && (
          <div
            onClick={onClickArrowButton}
            className={
              isVisibleContent ? classes.PageHeaderDropArrowActive : classes.PageHeaderDropArrow
            }
          >
            <img src={ArrowIcon} alt="ArrowIcon" />
          </div>
        )}
        <div className={classes.PageHeaderTitle}>{title}</div>
      </div>
      <div className={classes.PageHeaderContent}>{children}</div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  isVisibleContent: PropTypes.bool,
  onClickBackButton: PropTypes.func,
  onClickArrowButton: PropTypes.func,
};
