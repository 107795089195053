import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  brokerAgenciesSelector,
  brokerAgencySelector,
  getAgencyUserRole,
  getBrokerAgencies,
  getBrokerAgency,
} from 'src/store/agency';
import { getBrokerData } from 'src/store/broker';
import { getIssuerDataSelector } from 'src/store/issuer';
import { isSubscriptionSelector } from 'src/store/app';

import { ROUTE } from 'src/constants/routes';

import WhiteChevron from 'src/assets/app/chevronWhite.svg';
import PlusIcon from 'src/assets/app/plusIcon.svg';
import classes from './profile.module.scss';

export const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId } = useParams();

  const { pathname } = useLocation();

  const brokerData = useSelector(getBrokerData);
  const brokerAgencies = useSelector(brokerAgenciesSelector);
  const agencyOwner = brokerAgencies?.find((item) => item?.owner_id === brokerData?.id);
  const isAgencyOwner = _.isEmpty(agencyOwner);
  const isSubscription = useSelector(isSubscriptionSelector);

  const issuerData = useSelector(getIssuerDataSelector);
  const isIssuer = !_.isEmpty(issuerData);
  const agencyData = useSelector(brokerAgencySelector);

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState({});

  const profileRef = useRef();

  const chevronStyles = {
    visibility: !isIssuer ? 'visible' : 'hidden',
  };

  const menuStyles = {
    display: isVisibleMenu ? 'flex' : 'none',
    flexDirection: isVisibleMenu ? 'column' : 'none',
  };

  useEffect(() => {
    if (!_.isEmpty(brokerData) && pathname?.includes('broker')) {
      const payload = {
        logo: brokerData?.photo_url,
        first_name: brokerData?.display_name,
      };

      setSelectedProfile(payload);
    }

    if (!_.isEmpty(issuerData)) {
      const payload = {
        logo: issuerData?.photo_url,
        first_name: issuerData?.display_name,
      };

      setSelectedProfile(payload);
    }

    if (!_.isEmpty(agencyData) && pathname?.includes('teams')) {
      const payload = {
        logo: agencyData?.logo_url,
        first_name: agencyData?.name,
      };

      setSelectedProfile(payload);
    }
  }, [agencyData, brokerData, issuerData, pathname]);

  useEffect(() => {
    if (teamId) {
      dispatch(getBrokerAgency(teamId));
      dispatch(getAgencyUserRole(teamId));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (!_.isEmpty(brokerData) || !_.isEmpty(agencyData)) {
      const payload = {
        isSubscription,
        navigate,
      };

      dispatch(getBrokerAgencies(payload));
    }
  }, [dispatch, navigate, brokerData, agencyData, isSubscription]);

  const onMouseEnter = useCallback(() => {
    setIsMouseEnter(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsMouseEnter(false);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (profileRef && !profileRef.current?.contains(event.target)) {
        setIsVisibleMenu(false);
      }
    },
    [profileRef],
  );

  useEffect(() => {
    if (!isMouseEnter && isVisibleMenu) {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [handleClickOutside, isMouseEnter, isVisibleMenu]);

  const handleDropdownMenu = useCallback(() => {
    setIsVisibleMenu((prev) => !prev);
  }, []);

  const onNavigateTo = useCallback(
    (route) => () => {
      navigate(route);

      setIsVisibleMenu(false);
    },
    [navigate],
  );

  const emptyImage =
    'https://res.cloudinary.com/dlocussey/image/upload/v1589832771/website/128x128-placeholder_hvdami.png';

  return (
    <div
      className={classes.Profile}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={profileRef}
    >
      <div className={classes.ProfileWrapper} onClick={!isIssuer ? handleDropdownMenu : () => {}}>
        <img
          className={classes.ProfileLogo}
          src={selectedProfile?.logo ? selectedProfile?.logo : emptyImage}
          alt="Logo"
        />
        <div className={classes.ProfileName}>{selectedProfile?.first_name}</div>
        <img
          className={isVisibleMenu ? classes.ProfileChevronActive : classes.ProfileChevron}
          src={WhiteChevron}
          alt="Chevron"
          style={chevronStyles}
        />
      </div>
      <div className={`${classes.ProfileMenu} ${classes.ProfileMenuAnimation}`} style={menuStyles}>
        <div className={classes.ProfileMenuItem} onClick={onNavigateTo(ROUTE.BROKER)}>
          <img
            src={brokerData?.photo_url ? brokerData?.photo_url : emptyImage}
            className={classes.ProfileLogo}
            alt="ProfileLogo"
          />
          <span className={classes.ProfileMenuItemName}>{brokerData?.display_name}</span>
        </div>
        <hr />
        {brokerAgencies?.map((item) => (
          <div
            className={classes.ProfileMenuItem}
            key={item.id}
            onClick={onNavigateTo(`/teams/${item.id}/accounts`)}
          >
            <img
              src={item?.logo_url ? item?.logo_url : emptyImage}
              className={classes.ProfileLogo}
              alt="ProfileLogo"
            />
            <span className={classes.ProfileMenuItemName}>{item?.name}</span>
          </div>
        ))}
        {isAgencyOwner && (
          <div className={classes.ProfileMenuItem} onClick={onNavigateTo(ROUTE.CREATE_AGENCY)}>
            <img src={PlusIcon} alt="PlusIcon" />
            <span className={classes.ProfileMenuItemName}>Create Team</span>
          </div>
        )}
      </div>
    </div>
  );
};
