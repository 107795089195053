import { createSlice } from '@reduxjs/toolkit';
import {
  fetchRepAccountData,
  getLinkedRfp,
  getOtherRfp,
  getIssuerAccountMembers,
  getAllRequestedProposals,
  getMyRequestedProposals,
  getIssuerCoveragePlans,
} from './issuer.actions';

const initialState = {
  issuerData: {},
  isLoadingIssuerData: false,

  isEditProfile: false,

  linkedRfp: [],
  otherRfp: [],
  otherRfpMeta: {},
  repOtherRfp: [],
  repOtherRfpMeta: {},

  allRequestedProposals: [],
  isLoadingAllRequestedProposals: false,

  issuerAccountProposalData: {},
  isLoadingIssueraccountProposalData: false,

  issuerAccountMembers: [],
  isLoadingIssuerAccountMembers: false,

  issuerCoveragePlans: {},
  isLoadingIssuerCoveragePlans: false,

  issuerToken: '',
  isFetchRepDataError: false,

  isLoadingLinkedRfp: false,
  isLoadingOtherRfp: false,
  isLoadingRepOtherRfp: false,
};

const issuerSlice = createSlice({
  name: 'issuer',
  initialState,
  reducers: {
    setIsEditProfile: (state, action) => {
      state.isEditProfile = action.payload;
    },
    setIssuerToken: (state, action) => {
      state.issuerToken = action.payload;
    },
    setIsFetchRepDataError: (state, action) => {
      state.isFetchRepDataError = action.payload;
    },

    setRepAccountData: (state, action) => {
      state.issuerData = action.payload;
    },

    setIssuerAccountProposalData: (state, action) => {
      state.issuerAccountProposalData = action.payload;
    },

    setOtherRfpMeta: (state, action) => {
      state.otherRfpMeta = action.payload;
    },

    setRepOtherRfpMeta: (state, action) => {
      state.repOtherRfpMeta = action.payload;
    },

    clearIssuerState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRepAccountData.pending, (state) => {
      state.isLoadingIssuerData = true;
    });
    builder.addCase(fetchRepAccountData.fulfilled, (state) => {
      state.isLoadingIssuerData = false;
    });
    builder.addCase(fetchRepAccountData.rejected, (state) => {
      state.isLoadingIssuerData = false;
    });

    builder.addCase(getLinkedRfp.pending, (state) => {
      state.isLoadingLinkedRfp = true;
    });
    builder.addCase(getLinkedRfp.fulfilled, (state, action) => {
      state.linkedRfp = [action.payload];
      state.isLoadingLinkedRfp = false;
    });
    builder.addCase(getLinkedRfp.rejected, (state) => {
      state.isLoadingLinkedRfp = false;
    });

    builder.addCase(getOtherRfp.pending, (state) => {
      state.isLoadingOtherRfp = true;
    });
    builder.addCase(getOtherRfp.fulfilled, (state, action) => {
      state.otherRfp = action.payload;
      state.isLoadingOtherRfp = false;
    });
    builder.addCase(getOtherRfp.rejected, (state) => {
      state.isLoadingOtherRfp = false;
    });

    builder.addCase(getAllRequestedProposals.pending, (state) => {
      state.isLoadingAllRequestedProposals = true;
    });
    builder.addCase(getAllRequestedProposals.fulfilled, (state, action) => {
      state.allRequestedProposals = action.payload;
      state.isLoadingAllRequestedProposals = false;
    });
    builder.addCase(getAllRequestedProposals.rejected, (state) => {
      state.isLoadingAllRequestedProposals = false;
    });

    builder.addCase(getMyRequestedProposals.pending, (state) => {
      state.isLoadingRepOtherRfp = true;
    });
    builder.addCase(getMyRequestedProposals.fulfilled, (state, action) => {
      state.repOtherRfp = action.payload;
      state.isLoadingRepOtherRfp = false;
    });
    builder.addCase(getMyRequestedProposals.rejected, (state) => {
      state.isLoadingRepOtherRfp = false;
    });

    builder.addCase(getIssuerAccountMembers.pending, (state) => {
      state.isLoadingIssuerAccountMembers = true;
    });
    builder.addCase(getIssuerAccountMembers.fulfilled, (state, action) => {
      state.issuerAccountMembers = action.payload;
      state.isLoadingIssuerAccountMembers = false;
    });
    builder.addCase(getIssuerAccountMembers.rejected, (state) => {
      state.isLoadingIssuerAccountMembers = false;
    });

    builder.addCase(getIssuerCoveragePlans.pending, (state) => {
      state.isLoadingIssuerCoveragePlans = true;
    });
    builder.addCase(getIssuerCoveragePlans.fulfilled, (state, action) => {
      state.issuerCoveragePlans = action.payload;
      state.isLoadingIssuerCoveragePlans = false;
    });
    builder.addCase(getIssuerCoveragePlans.rejected, (state) => {
      state.isLoadingIssuerCoveragePlans = false;
    });
  },
});

export const {
  setIsEditProfile,
  setFiles,
  setFilesLoading,
  setIssuerToken,
  setIsFetchRepDataError,
  setRepAccountData,
  setIssuerAccountProposalData,
  setOtherRfpMeta,
  setRepOtherRfpMeta,
  clearIssuerState,
} = issuerSlice.actions;

export default issuerSlice.reducer;
