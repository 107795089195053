import React from 'react';

import { ROUTE } from 'src/constants/routes';
import { MenuItem } from '../MenuItem/MenuItem';

import classes from './adminMenu.module.scss';

export const AdminMenu = () => {
  return (
    <div className={classes.AdminMenu}>
      <MenuItem title="Dashboard" routeTo={ROUTE.ADMIN_DASHBOARD} />
      <MenuItem title="Rep Impersonate" routeTo={ROUTE.ADMIN_REP_IMPERSONATE} />
      <MenuItem title="Broker Impersonate" routeTo={ROUTE.ADMIN_IMPERSONATE} />
    </div>
  );
};
