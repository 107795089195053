import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageHeader } from 'src/components';
import {
  AccidentPlans,
  CriticalPlans,
  DentalPlans,
  DisabilityPlans,
  HospitalPlans,
  LifePlans,
  MedicalPlans,
  VisionPlans,
} from '../issuerRfpView/features';

import classes from './issuerProposalQuotes.module.scss';

export const IssuerProposalQuotes = () => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');

  return (
    <div className={classes.CarrierRfpViewPage}>
      <PageHeader title="Plan Configurations" />
      <div className={classes.CarrierRfpViewPageContent}>
        {activeTab === 'medical' && <MedicalPlans activeTab={activeTab} />}
        {activeTab === 'dental' && <DentalPlans activeTab={activeTab} />}
        {activeTab === 'vision' && <VisionPlans activeTab={activeTab} />}
        {activeTab === 'life' && <LifePlans activeTab={activeTab} />}
        {activeTab === 'disability' && <DisabilityPlans activeTab={activeTab} />}
        {activeTab === 'accident' && <AccidentPlans activeTab={activeTab} />}
        {activeTab === 'hospital' && <HospitalPlans activeTab={activeTab} />}
        {activeTab === 'critical_illness' && <CriticalPlans activeTab={activeTab} />}
      </div>
    </div>
  );
};
