import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { getBrokerData } from 'src/store/broker';
import {
  agencyMembersMetaSelector,
  brokerAgencySelector,
  createAgencyCheckoutSession,
  createAgencyCustomerPortalSession,
  getAgencyMembers,
  getPendingInvites,
  isLoadingBrokerAgencySelector,
  isLoadingCheckoutSessionSelector,
  isLoadingCustomerPortalSessionSelector,
  isLoadingUpdateSubscriptionQuantitySelector,
  pendingInvitesMetaSelector,
  updateSubscriptionQuantity,
} from 'src/store/agency';

import { ERROR } from 'src/constants/errorNames';
import { STRIPE_YEARLY_KEY, STRIPE_MONTHLY_KEY } from 'src/constants/env';

import { Button, Loader, LoaderWrapper, Modal, Status } from 'src/components';

import classes from './agencyBilling.module.scss';

export const AgencyBilling = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const teamId = params?.teamId;

  const brokerAgencyData = useSelector(brokerAgencySelector);
  const brokerData = useSelector(getBrokerData);

  const agencyMembersMeta = useSelector(agencyMembersMetaSelector);
  const pendingIvitesMeta = useSelector(pendingInvitesMetaSelector);
  const currentSeats = agencyMembersMeta.total + pendingIvitesMeta.total;

  const isLoadingCheckout = useSelector(isLoadingCheckoutSessionSelector);
  const isLoadingCustomerPortal = useSelector(isLoadingCustomerPortalSessionSelector);
  const isLoadingBrokerAgencyData = useSelector(isLoadingBrokerAgencySelector);
  const isLoadingUpdateSubscriptionQuantity = useSelector(
    isLoadingUpdateSubscriptionQuantitySelector,
  );

  const [seats, setSeats] = useState();
  const [updatedSeats, setUpdatedSeats] = useState();
  const [isOrderModal, setIsOrderModal] = useState(false);

  useEffect(() => {
    dispatch(getPendingInvites(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    dispatch(getAgencyMembers(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (!_.isEmpty(brokerAgencyData)) {
      setSeats(brokerAgencyData?.str_quantity);
    }
  }, [brokerAgencyData]);

  useEffect(() => {
    setUpdatedSeats(Math.abs(brokerAgencyData?.str_quantity - seats));
  }, [brokerAgencyData?.str_quantity, seats]);

  const normalizeUpdatedSeatsValue = useCallback(() => {
    if (seats > brokerAgencyData?.str_quantity) {
      return `: +$${updatedSeats * 50}`;
    }

    if (seats < brokerAgencyData?.str_quantity) {
      return `: -$${updatedSeats * 50}`;
    }
  }, [brokerAgencyData?.str_quantity, seats, updatedSeats]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('success') || queryParams.has('canceled')) {
      if (queryParams.has('success')) {
        toast('Subscription updated', { type: 'success' });
        queryParams.delete('success');
      }

      if (queryParams.has('canceled')) {
        toast(ERROR.SYSTEM_ERROR, { type: 'error' });
        queryParams.delete('canceled');
      }

      navigate(`?${queryParams.toString()}`, { replace: true });
    }
  }, [navigate, location]);

  const onClickYearlySubscribe = useCallback(() => {
    const payload = {
      price_id: STRIPE_YEARLY_KEY,
      customer_email: brokerData?.email,
      org_id: teamId,
    };

    dispatch(createAgencyCheckoutSession(payload));
  }, [dispatch, teamId, brokerData?.email]);

  const onClickMonthlySubscribe = useCallback(() => {
    const payload = {
      price_id: STRIPE_MONTHLY_KEY,
      customer_email: brokerData?.email,
      org_id: teamId,
    };

    dispatch(createAgencyCheckoutSession(payload));
  }, [dispatch, teamId, brokerData?.email]);

  const onClickManageBilling = useCallback(() => {
    const payload = {
      org_id: teamId,
    };

    dispatch(createAgencyCustomerPortalSession(payload));
  }, [dispatch, teamId]);

  const onDecrementSeats = useCallback(() => {
    if (seats <= 1) {
      return setSeats(1);
    }

    setSeats((prev) => prev - 1);
  }, [seats]);
  const onIncrementSeats = useCallback(() => {
    setSeats((prev) => prev + 1);
  }, []);

  const onClickUpgrade = useCallback(() => {
    setIsOrderModal(true);
  }, []);

  const onClickCancel = useCallback(() => {
    setUpdatedSeats(0);
    setSeats(brokerAgencyData?.str_quantity);
  }, [brokerAgencyData?.str_quantity]);

  const onClickUpgradeOrder = useCallback(async () => {
    const payload = {
      org_id: teamId,
      quantity: seats,
    };

    await dispatch(updateSubscriptionQuantity(payload));

    setUpdatedSeats(0);
    setIsOrderModal(false);
  }, [dispatch, seats, teamId]);

  const onCloseOrderModal = useCallback(() => {
    setIsOrderModal(false);
  }, []);

  return (
    <>
      {isOrderModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseOrderModal}>
          <div className={classes.OrderModal}>
            <div className={classes.OrderModalTitle}>Review Order</div>
            <div className={classes.OrderModalContent}>
              <div className={classes.OrderModalContentItem}>Qty: {seats}</div>
              <div className={classes.OrderModalContentItem}>
                New monthly subscription: ${seats * 50}
              </div>
            </div>
            <div className={classes.OrderModalFooter}>
              <Button
                title="Upgrade"
                type="primary"
                onClick={onClickUpgradeOrder}
                isLoading={isLoadingUpdateSubscriptionQuantity}
              />
              <Button title="Cancel" type="secondary" onClick={onCloseOrderModal} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.SettingsBilling}>
        <div className={classes.SettingsBillingHeader}>
          <div className={classes.SettingsBillingTitle}>Subscription</div>
          <div className={classes.SettingsBillingHeaderStatus}>
            <Status status={brokerAgencyData?.status} />
          </div>
        </div>
        <div className={classes.SettingsBillingContent}>
          {isLoadingBrokerAgencyData ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              {brokerAgencyData?.status !== 'active' ? (
                <>
                  <div className={classes.SettingsBillingContentItem}>
                    <div className={classes.SettingsBillingContentItemTitleFree}>
                      Get 2 months free
                    </div>
                    <div className={classes.SettingsBillingContentItemTitle}>$500 / license</div>
                    <div className={classes.SettingsBillingContentItemSubTitle}>Yearly</div>
                    <div className={classes.SettingsBillingContentItemButton}>
                      <Button
                        title="Subscribe"
                        type="primary"
                        onClick={onClickYearlySubscribe}
                        isLoading={isLoadingCheckout}
                      />
                    </div>
                  </div>
                  <div className={classes.SettingsBillingContentItem}>
                    <div className={classes.SettingsBillingContentItemTitleFree}>&nbsp;</div>
                    <div className={classes.SettingsBillingContentItemTitle}>$50 / license</div>
                    <div className={classes.SettingsBillingContentItemSubTitle}>Monthly</div>
                    <div className={classes.SettingsBillingContentItemButton}>
                      <Button
                        title="Subscribe"
                        type="primary"
                        onClick={onClickMonthlySubscribe}
                        isLoading={isLoadingCheckout}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.SettingsManageBilling}>
                  <div className={classes.SettingsBillingContentFooter}>
                    <div className={classes.SettingsBillingContentSeats}>
                      <div className={classes.SettingsBillingContentSeatsTitle}>
                        Seats quantity{normalizeUpdatedSeatsValue()}
                      </div>
                      <div className={classes.SettingsBillingContentSeatsWrapper}>
                        <Button
                          title="-"
                          type="primary"
                          onClick={onDecrementSeats}
                          isDisabled={currentSeats === seats}
                          tooltip="Please remove one or more team members or invites to lower subscription quantity"
                        />
                        <div className={classes.SettingsBillingContentSeatsCounter}>{seats}</div>
                        <Button title="+" type="primary" onClick={onIncrementSeats} />
                      </div>
                      {seats !== brokerAgencyData.str_quantity && (
                        <div className={classes.SettingsBillingContentSeatsFooter}>
                          <Button title="Update" type="primary" onClick={onClickUpgrade} />
                          <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                        </div>
                      )}
                    </div>
                    <div className={classes.SettingsBillingContentSeatsTitle}>Billing</div>
                    <Button
                      title="Manage Billing"
                      type="primary"
                      onClick={onClickManageBilling}
                      isLoading={isLoadingCustomerPortal}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
