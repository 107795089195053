import moment from 'moment';

const validRelationships = [
  'employee',
  'self',
  'spouse',
  'domestic_partner',
  'life_partner',
  'child',
  'step_child',
  'foster_child',
  'ward',
  'court_appointed_guardian',
];

export const validateCensusRow = (row, newErrorMessages) => {
  const dateOfBirth = row.date_of_birth;
  let gender = row.gender;
  let relationship = row.relationship;

  try {
    new Date(dateOfBirth);
    row.date_of_birth = moment(dateOfBirth).format('YYYY-MM-DD');
  } catch (err) {
    newErrorMessages?.push(
      `Invalid date_of_birth value "${dateOfBirth}". Must be in a valid date format MM/DD/YYYY`,
    );
  }

  if (isNaN(row.salary_amount) && typeof row.salary_amount !== 'undefined') {
    newErrorMessages?.push(`Invalid salary_amount value "${row.salary_amount}". Must be a number`);
  }

  if (isNaN(row.life_benefit_amount) && typeof row.life_benefit_amount !== 'undefined') {
    newErrorMessages?.push(
      `Invalid life_benefit_amount value "${row.life_benefit_amount}" . Must be a number`,
    );
  }

  try {
    row.gender = row.gender.toUpperCase();
    gender = row.gender;
  } catch (e) {
    newErrorMessages?.push(`Invalid gender value "${gender}". Must be either "M" or "F"`);
  }

  if (gender !== 'M' && gender !== 'F') {
    newErrorMessages?.push(`Invalid gender value "${gender}". Must be either "M" or "F"`);
  }

  try {
    relationship = row.relationship.toLowerCase().replace(/ /g, '_');
    row.relationship = relationship;
  } catch (e) {
    newErrorMessages?.push(
      `Invalid relationship value "${relationship}". Must be one of the following: ${validRelationships.join(
        ', ',
      )}`,
    );
  }

  if (!validRelationships.includes(relationship)) {
    newErrorMessages?.push(
      `Invalid relationship value "${relationship}". Must be one of the following: ${validRelationships.join(
        ', ',
      )}`,
    );
  }
};
