import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { BuilderSelect, BuilderCASelect } from 'src/features/coverageBuilder';

import { groupMembersSelector } from 'src/store/groupMembers';

import { visionRenewalRates } from 'src/constants/renewalRates';
import {
  compMethodOptions,
  examCopayOptions,
  frameAllowanceOptions,
  frequencyOptions,
  fundingOptions,
  materialsCopayOptions,
} from 'src/constants/coverage';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { currencyFormatter } from 'src/constants/currency';

export const useVisionBuild = () => {
  const initialState = {
    id: uuid(),
    is_current_rate: false,
    contribution_type: 'percent',
    contribution_ee: 50,
    contribution_dep: 0,
    funding_type: '',
    frequency: '',
    exam_copay: '',
    materials_copay: '',
    frame_allowance: '',
    comp_method: '',
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    selected_plan_name: '',
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
  };

  const members = useSelector(groupMembersSelector);
  const plan_names = visionRenewalRates(members);

  const [formData, setFormData] = useState(initialState);
  const [planRates, setPlanRates] = useState([]);
  const [initialPlanRates, setInitialPlanRates] = useState([]);

  useEffect(() => {
    const planRates = [];

    for (const plan_name of plan_names) {
      let payload = {
        ...initialState,
        id: uuid(),
        selected_plan_name: plan_name,
        is_current_rate: true,
        is_configured: false,
      };

      planRates.push(payload);
    }

    setInitialPlanRates(planRates);
    setPlanRates(planRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'vision',
    title: 'Vision',
    coverage_plan_name: 'vision_plan_name',
    card_options: {
      proposal: [
        {
          title: 'Frequency',
          key: 'frequency',
        },
        {
          title: 'Exam Copay',
          key: 'exam_copay',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Materials Copay',
          key: 'materials_copay',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Frame Allowance',
          key: 'frame_allowance',
          format: (value) => formattedValue(value),
        },
      ],
    },
    form_options: {
      contribution: true, // enables switcher('Percent' | 'Dollar') and contribution inputs
      contribution_title: 'Map Employees',
      initial_state: initialState,
      form_data: formData, // required
      setFormData, // required
      plan_names, // Required only for coverages with plan_names like : Medical Plan Name ...
      planRates,
      setPlanRates,
      initialPlanRates,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="Funding Type"
        value={formData.funding_type}
        options={fundingOptions}
        onChange={(event) => onChange(event, 'funding_type')}
      />,
      <BuilderSelect
        key="2"
        label="Frequency"
        value={formData.frequency}
        options={frequencyOptions}
        onChange={(event) => onChange(event, 'frequency')}
        type="other"
      />,
      <BuilderSelect
        key="3"
        label="Exam Copay"
        value={formData.exam_copay}
        options={examCopayOptions}
        onChange={(event) => onChange(event, 'exam_copay')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="4"
        label="Materials Copay"
        value={formData.materials_copay}
        options={materialsCopayOptions}
        onChange={(event) => onChange(event, 'materials_copay')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="5"
        label="Frame Allowance"
        value={formData.frame_allowance}
        options={frameAllowanceOptions}
        onChange={(event) => onChange(event, 'frame_allowance')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="6"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="7"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'funding_type', format: (value) => normalizeFundingType(value) },
      { key: 'frequency' },
      { key: 'exam_copay', format: (value) => currencyFormatter(value) },
      { key: 'materials_copay', format: (value) => currencyFormatter(value) },
      { key: 'frame_allowance', format: (value) => currencyFormatter(value) },
      { key: 'comp_method' },
    ],
  };

  return { build };
};
