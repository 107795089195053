import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ERROR } from 'src/constants/errorNames';

import { ROUTE } from 'src/constants/routes';

import { ResetPassword } from '../resetPassword/ResetPassword';
import { VerifyEmail } from '../verifyEmail/VerifyEmail';

export const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const verificationMode = searchParams.get('mode');

  useEffect(() => {
    switch (verificationMode) {
      case 'resetPassword':
      case 'verifyEmail':
        break;
      default:
        navigate(ROUTE.DEFAULT);
        toast(ERROR.INACCESSABLE, { type: 'error' });
    }
  }, [dispatch, navigate, verificationMode]);

  return (
    <>
      {verificationMode === 'verifyEmail' && <VerifyEmail />}
      {verificationMode === 'resetPassword' && <ResetPassword />}
    </>
  );
};
