import React from 'react';

import { BrowseBrokersTable } from 'src/components/BrowseBrokersTable/BrowseBrokersTable';
import classes from './browseBrokers.module.scss';

export const BrowseBrokers = () => {
  return (
    <div className={classes.BrowseBrokers}>
      <div className={classes.BrowseBrokersContent}>
        <BrowseBrokersTable />
      </div>
    </div>
  );
};
