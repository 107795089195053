import { createSlice } from '@reduxjs/toolkit';
import { getIssuerCoveragePlans } from './issuerCoverageBuilder.actions';

const initialState = {
  coverage: {
    medical_plans: [],
    medical_quotes: [],

    dental_plans: [],
    dental_quotes: [],

    vision_plans: [],
    vision_quotes: [],

    life_plans: [],
    life_quotes: [],

    disability_plans: [],
    disability_quotes: [],

    accident_plans: [],
    accident_quotes: [],

    hospital_plans: [],
    hospital_quotes: [],

    critical_illness_plans: [],
    critical_illness_quotes: [],
  },

  isLoadingCoverage: false,
};

const issuerCoverageBuilderSlice = createSlice({
  name: 'issuerCoverageBuilder',
  initialState,
  reducers: {
    clearIssuerCoveragesBuilderState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getIssuerCoveragePlans.pending, (state) => {
      state.isLoadingCoverage = true;
    });
    builder.addCase(getIssuerCoveragePlans.fulfilled, (state, action) => {
      const { coverage, response } = action.payload;

      state.coverage[`${coverage}_plans`] = response;
      state.isLoadingCoverage = false;
    });
    builder.addCase(getIssuerCoveragePlans.rejected, (state) => {
      state.isLoadingCoverage = false;
    });
  },
});

export const { clearIssuerCoveragesBuilderState } = issuerCoverageBuilderSlice.actions;

export default issuerCoverageBuilderSlice.reducer;
