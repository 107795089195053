export const initialConfinementOptions = [
  { title: '$500', value: '500' },
  { title: '$1000', value: '1000' },
  { title: '$1500', value: '1500' },
  { title: '$2000', value: '2000' },
];

export const hospitalizationOptions = [
  { title: '$50', value: '50' },
  { title: '$100', value: '100' },
  { title: '$150', value: '150' },
  { title: '$200', value: '200' },
];
