import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import {
  getCityByZipCode,
  getCityStateData,
  getIsLoadingZipCode,
  setCityStateData,
} from 'src/store/app';
import { createBrokerAgency } from 'src/store/agency';

import { stateCodes } from 'src/constants/stateCodes';

import { UploadImage } from '../../uploadImage/UploadImage';
import { PageHeader, Input, Button, SearchSelect } from 'src/components';

import classes from './createAgency.module.scss';
import { ERROR } from 'src/constants/errorNames';

export const CreateAgency = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const cityStateData = useSelector(getCityStateData);
  const isCityStateData = !_.isEmpty(cityStateData);
  const isLoadingZipCode = useSelector(getIsLoadingZipCode);

  const [agencyName, setAgencyName] = useState('');
  const [addressStreet, setAddressStreet] = useState('');

  const [addressZipCode, setAddressZipCode] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressState, setAddressState] = useState('');
  const [addressStateSearchValue, setAddressStateSearchValue] = useState('');
  const [agencyLogo, setAgencyLogo] = useState('');

  const [agencyId, setAgencyId] = useState('');

  useEffect(() => {
    setAgencyId(uuid());
  }, []);

  useEffect(() => {
    if (addressZipCode) {
      const debounceSearch = setTimeout(() => {
        dispatch(getCityByZipCode(addressZipCode));
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, addressZipCode]);

  useEffect(() => {
    if (isCityStateData) {
      setAddressCity(cityStateData?.primary_city ?? '');
      setAddressState(cityStateData?.state ?? '');
      setAddressStateSearchValue(cityStateData?.state ?? '');
    }
  }, [isCityStateData, cityStateData]);

  useEffect(() => {
    if (!isCityStateData) {
      setAddressCity('');
      setAddressState('');
      setAddressStateSearchValue('');
    }
  }, [isCityStateData]);

  useEffect(() => {
    if (!addressZipCode) {
      dispatch(setCityStateData({}));
    }
  }, [dispatch, addressZipCode]);

  const onChangeAgencyInput = useCallback((event, setAgencyValue) => {
    setAgencyValue(event.target.value);
  }, []);

  const onSetAgencyLogo = useCallback((image) => {
    setAgencyLogo(image);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const labels = {
    name: 'Name',
    address_street: 'Address',
    address_city: 'City',
    address_state: 'State',
    address_zip_code: 'Zip Code',
  };

  const onCreateAgency = useCallback(() => {
    const payload = {
      id: agencyId,
      name: agencyName,
      address_street: addressStreet,
      address_state: addressState,
      address_city: addressCity,
      address_zip_code: addressZipCode,
      logo_url: agencyLogo,
    };

    if (!isCityStateData) {
      return toast(ERROR.ZIP_CODE_ERROR, { type: 'error' });
    }

    for (const key of Object.keys(payload)) {
      if (key === 'logo_url') continue;

      if (!payload[key]) {
        return toast(`${labels[key]} cannot be empty`, { type: 'error' });
      }
    }

    if (isCityStateData) {
      return dispatch(createBrokerAgency({ payload, navigate }));
    }
  }, [
    dispatch,
    navigate,
    isCityStateData,
    agencyId,
    agencyName,
    addressStreet,
    addressState,
    addressCity,
    addressZipCode,
    agencyLogo,
    labels,
  ]);

  const onClickState = useCallback((value) => {
    setAddressState(value);
    setAddressStateSearchValue(value);
  }, []);

  const onClearState = useCallback(() => {
    setAddressState('');
    setAddressStateSearchValue('');
  }, []);

  return (
    <div className={classes.CreateAgency}>
      <PageHeader title="Create Agency" />
      <div className={classes.CreateAgencyContent}>
        <div className={classes.CreateAgencyCard}>
          <div className={classes.CreateAgencyCardContent}>
            <span className={classes.CreateAgencyCardContentTitle}>Agency Logo</span>
            <UploadImage
              path={`orgs/${agencyId}/logo`}
              setPreviewImage={onSetAgencyLogo}
              previewImage={agencyLogo}
            />
          </div>
          <div className={classes.CreateAgencyCardContent}>
            <Input
              label={labels.name}
              value={agencyName}
              onChange={(event) => onChangeAgencyInput(event, setAgencyName)}
              isRequired
            />
            <Input
              label={labels.address_street}
              value={addressStreet}
              onChange={(event) => onChangeAgencyInput(event, setAddressStreet)}
              isRequired
            />
            <Input
              label={labels.address_zip_code}
              value={addressZipCode}
              onChange={(event) => onChangeAgencyInput(event, setAddressZipCode)}
              isLoading={isLoadingZipCode}
              isRequired
            />
            {isCityStateData && (
              <div className={classes.CreateAgencyCardAddressWrapper}>
                <Input
                  label={labels.address_city}
                  value={addressCity}
                  onChange={(event) => onChangeAgencyInput(event, setAddressCity)}
                  isRequired
                />
                <SearchSelect
                  label="State"
                  value={addressStateSearchValue}
                  onClick={onClickState}
                  onChange={(event) => onChangeAgencyInput(event, setAddressStateSearchValue)}
                  onClear={onClearState}
                  options={stateCodes}
                  isRequired
                />
              </div>
            )}

            <div className={classes.CreateAgencyCardButton}>
              <Button title="Create" type="primary" onClick={onCreateAgency} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
