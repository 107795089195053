import React, { useCallback, useEffect, useState } from 'react';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { BrokerPricing, CarrierPricing } from 'src/components';
import { Tabs } from 'src/components/Tabs/Tabs';
import { TabItem } from 'src/components/Tabs/TabItem';

import classes from './pricing.module.scss';

export const Pricing = () => {
  const [isToggle, setIsToggle] = useState('false');
  const [selectedTab, setSelectedTab] = useState('monthly');

  useEffect(() => {
    document.title = 'Monark | Pricing';
  }, []);

  const onClickTab = useCallback(
    (tab) => () => {
      setSelectedTab(tab);
    },
    [],
  );

  const onClickToggle = useCallback((event, value) => {
    setIsToggle(value ? value : 'false');
  }, []);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />
      <div className={classes.Pricing}>
        <div className={classes.PricingContent}>
          <div className={classes.PricingContentHeader}>
            <div className={classes.PricingContentHeaderToggle}>
              <ToggleButtonGroup
                color="primary"
                value={isToggle}
                exclusive
                onChange={onClickToggle}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="false">Broker</ToggleButton>
                <ToggleButton value="true">Carrier</ToggleButton>
              </ToggleButtonGroup>
            </div>

            <Tabs>
              <TabItem
                title="Monthly billing"
                onClick={onClickTab('monthly')}
                isActive={selectedTab === 'monthly'}
              />
              <TabItem
                title="Annual billing"
                onClick={onClickTab('annual')}
                isActive={selectedTab === 'annual'}
              />
            </Tabs>
          </div>

          {!JSON.parse(isToggle) && <BrokerPricing selectedTab={selectedTab} />}
          {JSON.parse(isToggle) && <CarrierPricing selectedTab={selectedTab} />}
        </div>
      </div>
    </div>
  );
};
