import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { getAccountData, getIsLoadingBrokerAccountData } from 'src/store/broker';
import {
  getAccountProposalSelector,
  getExecutiveSummaryProposals,
  getIsLoadingAccountProposal,
  getReadOnly,
  isExpandedQuotesSelector,
  proposalManagement,
  setIsExpandedQuotes,
  setIsProposalFinalize,
} from 'src/store/proposals';
import { employeeActiveTabSelector } from 'src/store/employee';
import { getPetQuotes, petInsuranceDataSelector } from 'src/store/pet';

import { AccountDetails, Button, Loader, LoaderWrapper } from 'src/components';

import { ProposalHeader } from '../proposalHeader/ProposalHeader';
import { GroupMembers } from '../groupMembers/GroupMembers';
import { MedicalQuotes } from '../../medical/medicalQuotes/MedicalQuotes';
import { DentalQuotes } from '../../dental/dentalQuotes/DentalQuotes';
import { VisionQuotes } from '../../vision/visionQuotes/VisionQuotes';
import { LifeQuotes } from '../../life/lifeQuotes/LifeQuotes';
import { DisabilityQuotes } from '../../disability/disabilityQuotes/DisabilityQuotes';
import { HospitalQuotes } from '../../hospital/hospitalQuotes/HospitalQuotes';
import { AccidentQuotes } from '../../accident/accidentQuotes/AccidentQuotes';
import { CriticalQuotes } from '../../critical/criticalQuotes/CriticalQuotes';
import { PetQuotes } from 'src/features/pet/petQuotes/PetQuotes';
import { ExecutiveSummary } from '../executiveSummar/ExecutiveSummary';

import classes from './proposalFinalize.module.scss';
import { coverageSelector, getCoverageQuotePlans } from 'src/store/coverageBuilder';

export const ProposalFinalize = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams();

  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const isLoadingProposal = useSelector(getIsLoadingAccountProposal);
  const isLoadingAccount = useSelector(getIsLoadingBrokerAccountData);
  const activeTab = useSelector(employeeActiveTabSelector);
  const coverageData = useSelector(coverageSelector);

  const medicalQuotes = !_.isEmpty(accountProposalData)
    ? accountProposalData?.groups[0]?.quotes?.filter((item) => item.coverage_type !== 'pet')
    : [];

  const isMedicalQuotes = medicalQuotes?.length > 0 || coverageData['medical_quotes']?.length > 0;
  const petInsuranceData = useSelector(petInsuranceDataSelector);

  const isExpandedQuotes = useSelector(isExpandedQuotesSelector);

  const medicalRef = useRef();
  const dentalRef = useRef();
  const visionRef = useRef();
  const lifeRef = useRef();
  const disabilityRef = useRef();
  const accidentRef = useRef();
  const hospitalRef = useRef();
  const criticalRef = useRef();
  const petRef = useRef();

  const isReadOnlyMode = pathname.includes('/read-only');

  const readOnlyToken = params?.token;

  const validToken = `${readOnlyToken}${search}`;

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const isDisabledButton = ['rejected', 'approved'].includes(accountProposalData?.status);

  useEffect(() => {
    dispatch(setIsProposalFinalize(true));

    return () => {
      dispatch(setIsProposalFinalize(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isReadOnlyMode) {
      dispatch(getReadOnly({ validToken, navigate }));
    }
  }, [dispatch, navigate, validToken, isReadOnlyMode]);

  useEffect(() => {
    if (accountId && proposalId) {
      const fetchQuote = (coverage) =>
        dispatch(
          getCoverageQuotePlans({
            accountId,
            proposalId,
            coverage,
            read_token: readOnlyToken,
          }),
        );

      fetchQuote('medical');
      fetchQuote('dental');
      fetchQuote('vision');
      fetchQuote('life');
      fetchQuote('disability');
      fetchQuote('accident');
      fetchQuote('hospital');
      fetchQuote('critical_illness');

      dispatch(getPetQuotes({ accountId, proposalId, read_token: readOnlyToken }));
    }
  }, [dispatch, accountId, proposalId, readOnlyToken]);

  useEffect(() => {
    if (accountId && proposalId) {
      dispatch(getExecutiveSummaryProposals({ accountId, proposalId, read_token: readOnlyToken }));
    }
  }, [dispatch, accountId, proposalId, readOnlyToken]);

  useEffect(() => {
    if (isReadOnlyMode) {
      if (activeTab === 'medical') {
        medicalRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'dental') {
        dentalRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'vision') {
        visionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'life') {
        lifeRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'disability') {
        disabilityRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'accident') {
        accidentRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'hospital') {
        hospitalRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'critical') {
        criticalRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      if (activeTab === 'pet') {
        petRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [activeTab, isReadOnlyMode]);

  const onRejectAll = useCallback(() => {
    const payload = {
      proposal_id: accountProposalData?.id,
      status: 'rejected',
    };

    dispatch(
      proposalManagement({ accountId, proposalId, validToken, token: readOnlyToken, payload }),
    );
  }, [dispatch, validToken, accountProposalData, readOnlyToken, accountId, proposalId]);

  const onClickExpandButton = useCallback(() => {
    dispatch(setIsExpandedQuotes(!isExpandedQuotes));
  }, [dispatch, isExpandedQuotes]);

  return isLoadingProposal || isLoadingAccount ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <div className={classes.ProposalFinalize}>
      <ProposalHeader
        isReadOnlyMode={isReadOnlyMode}
        accountId={accountId}
        proposalId={proposalId}
        isEditable={false}
      />
      <AccountDetails
        readOnlyMode={true}
        previewImage={accountData?.logo_url}
        companyName={accountData?.company_name}
        addressStreet={accountData?.address_street}
        addressCity={accountData?.address_city}
        addressState={accountData?.address_state}
        addressZip={accountData?.address_zip_code}
        contactName={accountData?.contact_name}
        contactEmail={accountData?.contact_email}
        contactPhone={accountData?.contact_phone}
        fipsCode={accountData?.fips_code}
        effectiveDate={moment(accountData?.renewal_date).format('l')}
        brokerName={accountData?.broker?.display_name}
        brokerEmail={accountData?.broker?.email}
        brokerPhone={accountData?.broker?.phone_number}
        brokerData={accountData?.broker}
        sicCode={accountData?.sic_code}
        ratingArea={accountData?.rating_area_id}
      />
      <GroupMembers />
      <ExecutiveSummary />
      <div className={classes.ProposalFinalizeExpand}>
        <Button
          title={isExpandedQuotes ? 'Collapse All' : 'Expand All'}
          type="primary"
          onClick={onClickExpandButton}
        />
      </div>
      {isMedicalQuotes && (
        <div ref={medicalRef}>
          <MedicalQuotes />
        </div>
      )}
      {coverageData['dental_quotes']?.length > 0 && (
        <div ref={dentalRef}>
          <DentalQuotes />
        </div>
      )}
      {coverageData['vision_quotes']?.length > 0 && (
        <div ref={visionRef}>
          <VisionQuotes />
        </div>
      )}
      {coverageData['life_quotes']?.length > 0 && (
        <div ref={lifeRef}>
          <LifeQuotes />
        </div>
      )}
      {coverageData['disability_quotes']?.length > 0 && (
        <div ref={disabilityRef}>
          <DisabilityQuotes />
        </div>
      )}
      {coverageData['accident_quotes']?.length > 0 && (
        <div ref={accidentRef}>
          <AccidentQuotes />
        </div>
      )}
      {coverageData['hospital_quotes']?.length > 0 && (
        <div ref={hospitalRef}>
          <HospitalQuotes />
        </div>
      )}
      {coverageData['critical_illness_quotes']?.length > 0 && (
        <div ref={criticalRef}>
          <CriticalQuotes />
        </div>
      )}
      {petInsuranceData?.quotes?.length > 0 && (
        <div ref={petRef}>
          <PetQuotes />
        </div>
      )}

      {isReadOnlyMode && (
        <div className={classes.ProposalFinalizeFooter}>
          <hr className={classes.ProposalFinalizeFooterDivider} />
          <div className={classes.ProposalFinalizeFooterButton}>
            <Button
              title="Reject All"
              type="danger"
              onClick={onRejectAll}
              isDisabled={isDisabledButton}
            />
          </div>
        </div>
      )}
    </div>
  );
};
