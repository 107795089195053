import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';

import {
  setSelectedQuotes,
  setSelectedEditQuote,
  isProposalFinalizeSelector,
  updateMedicalQuotesPlanOrder,
} from 'src/store/proposals';

import { totalPremiumRegex } from 'src/constants/regularExpression';
import { currencyFormatter } from 'src/constants/currency';
import { MODAL_NAMES } from 'src/constants/modalNames';
import { hiddenBarsIcon } from 'src/constants/hiddenBarsIcon';
import { reorder } from 'src/utils/reorder';

import { EmptyField } from '../EmptyField/EmptyField';
import { Button, Modal, Ribbon, SelectedQuotesTable, Status } from 'src/components';

import cardClasses from 'src/sass/styles/ConfigurationQuoteCard/configurationQuoteCard.module.scss';
import classes from './quoteCard.module.scss';

export const QuoteCard = (props) => {
  const { accountProposalData, onDeleteQuote, onSubmitQuote, onDeselectQuote } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const accountId = params?.id;
  const isReadOnlyMode = pathname.includes('/read-only');

  const isProposalFinalize = useSelector(isProposalFinalizeSelector);

  const [selectedQuoteIds, setSelectedQuoteIds] = useState([]);

  const handleOpenQuote = (id) => () => {
    if (selectedQuoteIds.includes(id)) {
      const updatedSelectedQuotes = _.reject(selectedQuoteIds, (el) => el === id);

      setSelectedQuoteIds(updatedSelectedQuotes);
      return;
    }

    setSelectedQuoteIds([...selectedQuoteIds, id]);
  };

  // TODO REMOVE TO CONSTANTS
  const totalPremium = useCallback((quote) => {
    if (quote.quote_type === 'advanced') {
      let sum = 0;
      for (const key of quote.plans) {
        const plan = key;
        sum += plan.total_premium;
      }
      return `${currencyFormatter(sum).replace(totalPremiumRegex, '')}`;
    } else if (quote.quote_type === 'standard') {
      let min = 0,
        max = 0;
      for (const plan of quote.plans) {
        if (plan.total_premium > max || max === 0) {
          max = plan.total_premium;
        }
        if (plan.total_premium < min || min === 0) {
          min = plan.total_premium;
        }
      }

      return `${currencyFormatter(min).replace(totalPremiumRegex, '')} - ${currencyFormatter(
        max,
      ).replace(totalPremiumRegex, '')}`;
    }
  }, []);

  const onClickEdit = useCallback(
    (quote) => () => {
      dispatch(setSelectedEditQuote(quote));
      dispatch(setSelectedQuotes(quote?.plans));
    },
    [dispatch],
  );

  const isEmptyQuotes = accountProposalData?.groups?.map((group) =>
    group?.quotes.filter((item) => item.coverage_type === 'medical'),
  )[0]?.length;

  const medicalQuotes =
    accountProposalData?.groups?.map((group) =>
      group?.quotes.filter((item) => item.coverage_type === 'medical'),
    )[0] ?? [];

  const [sortableQuotes, setSortableQuotes] = useState(medicalQuotes);

  const onDragQuotesEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const reorderedList = reorder(sortableQuotes, result.source.index, result.destination.index);

      const payload = [];

      const groupId = sortableQuotes[0]?.group_id;

      for (const item of reorderedList) {
        payload.push({ id: item.id });
      }

      dispatch(
        updateMedicalQuotesPlanOrder({
          accountId,
          groupId,
          payload,
        }),
      );

      setSortableQuotes(reorderedList);
    },
    [dispatch, sortableQuotes, accountId],
  );

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});

  const onOpenModal = useCallback(
    (group_id, id) => () => {
      setSelectedQuote({ group_id, id });
      setIsVisibleModal(true);
    },
    [],
  );

  const onCloseModal = useCallback(() => {
    setSelectedQuote({});
    setIsVisibleModal(false);
  }, []);

  const normalizeButtonText = (quote) => {
    const { status } = quote;

    if (status !== 'approved') {
      return 'Select';
    }

    if (status === 'approved') {
      return 'Deselect';
    }
  };

  return (
    <>
      {isVisibleModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseModal}>
          <div className={cardClasses.ModalContent}>
            <div className={cardClasses.ModalContentTitle}>{MODAL_NAMES.EXCLUDE_QUOTE}</div>
            <div className={cardClasses.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseModal} />
              <Button
                type="danger"
                title="Yes"
                onClick={onDeleteQuote(selectedQuote.group_id, selectedQuote.id)}
              />
            </div>
          </div>
        </Modal>
      )}

      {isEmptyQuotes > 0 ? (
        <DragDropContext onDragEnd={onDragQuotesEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortableQuotes?.map((quote, index) => {
                  const planTypes = [];

                  for (const plan of quote?.plans) {
                    planTypes?.push(plan?.plan_type);
                  }

                  const collectedPlanTypes = [...new Set(planTypes)];

                  return (
                    <Draggable
                      draggableId={quote.id}
                      index={index}
                      key={quote.id}
                      isDragDisabled={isReadOnlyMode || isProposalFinalize}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            key={quote.id}
                            className={
                              quote?.status === 'approved'
                                ? classes.QuotesCardPicked
                                : classes.QuotesCard
                            }
                            id={'quoteCard'}
                          >
                            {quote?.status === 'approved' && <Ribbon title="Sold" />}
                            <div
                              className={classes.QuotesCardHeader}
                              onClick={handleOpenQuote(quote.id)}
                              id="quoteCardHeader"
                            >
                              <div className={classes.HeaderIcon}>
                                <div className={classes.HeaderIconWrapper}>
                                  {hiddenBarsIcon(isReadOnlyMode, isProposalFinalize) && (
                                    <i className={`fas fa-bars ${classes.HeaderBarIcon}`} />
                                  )}

                                  <i
                                    className={`fas fa-chevron-down ${
                                      selectedQuoteIds.includes(quote.id)
                                        ? classes.QuotesCardHeaderIconActive
                                        : classes.QuotesCardHeaderIcon
                                    }`}
                                  />
                                </div>
                              </div>
                              <div className={cardClasses.ConfigurationQuoteCardHeaderWrapper}>
                                <div className={cardClasses.ConfigurationQuoteCardHeaderIndividual}>
                                  <div className={cardClasses.ConfigurationQuoteCardHeaderItem}>
                                    <div
                                      className={
                                        cardClasses.ConfigurationQuoteCardHeaderItemHeading
                                      }
                                    >
                                      Quote Name
                                    </div>
                                    <div
                                      className={
                                        cardClasses.ConfigurationQuoteCardHeaderIndividualTitle
                                      }
                                    >
                                      {quote.name}
                                    </div>
                                  </div>
                                </div>
                                <div className={cardClasses.ConfigurationQuoteCardHeaderItem}>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemHeading}
                                  >
                                    Type
                                  </div>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemTitle}
                                  >
                                    {collectedPlanTypes?.join(', ')}
                                  </div>
                                </div>
                                <div className={cardClasses.ConfigurationQuoteCardHeaderItem}>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemHeading}
                                  >
                                    Contribution
                                  </div>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemTitle}
                                  >
                                    {quote.contribution_source}
                                  </div>
                                </div>
                                <div className={cardClasses.ConfigurationQuoteCardHeaderItem}>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemHeading}
                                  >
                                    {quote.contribution_type === 'dollar' && 'Fixed Contribution'}
                                    {quote.contribution_type === 'percent' &&
                                      'Contribution Percentage'}
                                  </div>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemTitle}
                                  >
                                    {quote.contribution_type === 'dollar' && '$'}
                                    {quote.contribution_ee}
                                    {quote.contribution_type === 'percent' && '%'} EE /&nbsp;
                                    {quote.contribution_type === 'dollar' && '$'}
                                    {quote.contribution_dep}
                                    {quote.contribution_type === 'percent' && '%'} DEP
                                  </div>
                                </div>

                                <div className={cardClasses.ConfigurationQuoteCardHeaderItem}>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemHeading}
                                  >
                                    Premiums
                                  </div>
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderItemTitle}
                                  >
                                    {totalPremium(quote)}
                                  </div>
                                </div>
                                {quote.status !== 'approved' && (
                                  <div
                                    className={cardClasses.ConfigurationQuoteCardHeaderButtons}
                                    // onMouseEnter={mouseIn}
                                    // onMouseLeave={mouseOut}
                                  >
                                    {!isReadOnlyMode && (
                                      <>
                                        {quote.status !== 'approved' && (
                                          <div
                                            className={
                                              cardClasses.ConfigurationQuoteCardHeaderItemStatus
                                            }
                                          >
                                            <Status status={quote.status} />
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {!isReadOnlyMode && quote.status !== 'approved' && (
                                      <div
                                        className={cardClasses.ConfigurationQuoteCardDeleteButton}
                                        onClick={onOpenModal(quote.group_id, quote.id)}
                                      >
                                        <i className="fa fa-trash-o" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                selectedQuoteIds.includes(quote.id)
                                  ? classes.VisibleQuote
                                  : classes.HiddenQuote
                              }
                              id="quoteContent"
                            >
                              <SelectedQuotesTable
                                selectedQoutes={quote.plans}
                                accountProposalData={accountProposalData}
                                quotesMetaData={{ quote }}
                              />
                              <div className={classes.QuotesCardFooter}>
                                <div className={classes.QuotesCardFooterReadOnly}>
                                  <Button
                                    type="primary"
                                    title={normalizeButtonText(quote)}
                                    onClick={
                                      quote.status !== 'approved'
                                        ? onSubmitQuote(
                                            quote?.coverage_type,
                                            accountProposalData?.id,
                                            quote.id,
                                          )
                                        : onDeselectQuote(quote.id)
                                    }
                                  />

                                  {!isReadOnlyMode && (
                                    <Button
                                      title="Edit"
                                      type="secondary"
                                      onClick={onClickEdit(quote)}
                                    />
                                  )}
                                </div>
                                {quote?.status !== 'approved' && !isReadOnlyMode && (
                                  <Button
                                    type="danger"
                                    title="Delete Quote"
                                    onClick={onDeleteQuote(quote.group_id, quote.id)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyField title={`No instant medical quotes created yet.`} />
      )}
    </>
  );
};
