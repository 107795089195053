import React from 'react';

import CensusFile from 'src/files/census/census-csv-template.csv';
import CensusXlsx from 'src/files/census/census-xlsx-template.xlsx';

import classes from './uploadCensus.module.scss';

export const UploadCensus = (props) => {
  const { isReUpload = false } = props;

  return (
    <div className={classes.UploadCensus}>
      <ul>
        <li>
          Download our sample template -
          <a className={classes.UploadCensusFile} href={CensusXlsx} download="census-xlsx-template">
            {` XLSX`}
          </a>
          or
          <a className={classes.UploadCensusFile} href={CensusFile} download="census-csv-template">
            {` CSV`}
          </a>
          - if you do not already have it.
        </li>
        <li>
          The <code>ee_id</code> column should have the same value for all dependents of that
          employee.
        </li>
        <li>
          Acceptable values for <code>relationship</code> include:
          <ul>
            <li>
              <code>employee</code>
            </li>
            <li>
              <code>spouse</code>
            </li>
            <li>
              <code>life_partner</code>
            </li>
            <li>
              <code>child</code>
            </li>
            <li>
              <code>step_child</code>
            </li>
            <li>
              <code>foster_child</code>
            </li>
            <li>
              <code>ward</code>
            </li>
            <li>
              <code>court_appointed_guardian</code>
            </li>
          </ul>
        </li>
        {!isReUpload && (
          <>
            <li>
              Acceptable date format for <code>date_of_birth</code> is <code>MM/DD/YYYY</code>.
            </li>

            <li>
              Acceptable value length for <code>current_life</code> is <code>6 digits</code>.
            </li>
          </>
        )}
      </ul>
    </div>
  );
};
