import React from 'react';

import { CoverageBuilder } from 'src/features/coverageBuilder/CoverageBuilder';

import { useLifeBuild } from './useLifeBuild/useLifeBuild';

export const LifeQuotes = () => {
  const { build } = useLifeBuild();

  return <CoverageBuilder build={build} />;
};
