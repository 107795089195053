import React, { useEffect } from 'react';

import classes from './privacy.module.scss';

export const Privacy = () => {
  useEffect(() => {
    document.title = 'Monark | Privacy Policy';
  }, []);

  return (
    <div className={classes.Privacy}>
      <div className={classes.PrivacyContent}>
        <h3>Privacy Policy</h3>
        <br />
        <h5 dir="ltr" className={classes.EffectiveDateWrapper}>
          <span className={classes.EffectiveDateTitle}>Effective Date: July 1st, 2019</span>
        </h5>
        <h5 dir="ltr" className={classes.LastUpdateWrapper}>
          <span className={classes.LastUpdateTitle}>Last Updated Date: July 1st, 2019</span>
        </h5>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            MonarkHQ (“Provider” or “we” or “us”) is committed to protecting your privacy. We have
            prepared this Privacy Policy to describe to you our practices regarding the Personal
            Data (as defined below) that we collect from users of our website (“Users”), located
            at&nbsp;
          </span>
          <a href="https://www.monarkhq.com/" className={classes.Link}>
            <span className={classes.LinkWrapper}>www.monarkhq.com</span>
          </a>
          <span className={classes.TitleWrapper}>
            &nbsp;(“Website”), and related services (“Services”). Any terms not defined herein shall
            have the meaning given to them in the associated&nbsp;
          </span>
          <a href="https://monarkhq.com/privacy" className={classes.Link}>
            <span className={classes.LinkWrapper}>www.monarkhq.com/privacy</span>
          </a>
          <span className={classes.TitleWrapper}>.</span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>USER CONSENT</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            By submitting Personal Data through our Website or Services, you agree to the terms of
            this Privacy Policy and you expressly consent to the collection, use and disclosure of
            your Personal Data in accordance with this Privacy Policy.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>TYPES OF DATA WE COLLECT.</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            “Personal Data” means data that allows someone to identify or contact you, including,
            for example, your name, gender, date of birth, address, telephone number, e-mail
            address, as well as any other non-public information about you that is associated with
            or linked to any of the foregoing data. “Anonymous Data” means data that is not
            associated with or linked to your Personal Data; Anonymous Data does not, by itself,
            permit the identification of individual persons. We collect Personal Data and Anonymous
            Data, as described below.
          </span>
        </p>
        <h4 dir="ltr" className={classes.Heading1}>
          <span className={classes.ParagraphTitle}>Information You Provide to Us.</span>
        </h4>
        <ol className={classes.NoMargin}>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphWrapper}>
              <span className={classes.TitleWrapper}>
                We may collect Personal Data from you when you create an account (“BrokerAccount”)
                using your username and password to log in to our network.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                Our Website lets you store preferences like how your content is displayed, your
                location, safe search settings, and favorite widgets. We may associate these choices
                with your BrokerAccount, browser or the mobile device, and you can edit these
                preferences at any time.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                When connecting to our Services via a service provider that uniquely identifies your
                mobile device, we may receive this identification and use it to offer extended
                services and/or functionality.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                We retain information on your behalf, such as files and messages that you store
                using your BrokerAccount.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                If you provide us feedback or contact us via e-mail, we will collect your name and
                e-mail address, as well as any other content included in the e-mail, in order to
                send you a reply.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                If you choose to participate in one of our surveys, we may collect additional
                profile information.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphCustom}>
              <span className={classes.TitleWrapper}>
                We may also collect Personal Data, such as at other points in our Website that state
                that Personal Data is being collected.
              </span>
            </p>
          </li>
        </ol>
        <h4 dir="ltr" className={classes.HeadingCustom1}>
          <span className={classes.ParagraphTitle}>Information Collected via Technology.</span>
        </h4>
        <ol className={classes.NoMargin}>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphWrapper}>
              <span className={classes.ListSpanTitle}>Information Collected by Our Servers.</span>
              <span className={classes.TitleWrapper}>
                &nbsp;To make our Website and Services more useful to you, our servers (which may be
                hosted by a third-party service provider) collect information from you, including
                your browser type, operating system, Internet Protocol (“IP”) address (a number that
                is automatically assigned to your computer when you use the Internet, which may vary
                from session to session), domain name, and/or a date/time stamp for your visit.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.ListSpanTitle}>Log Files.</span>
              <span className={classes.TitleWrapper}>
                &nbsp;As is true of most websites, we gather certain information automatically and
                store it in log files. This information includes IP addresses, browser type,
                Internet service provider (“ISP”), referring/exit pages, operating system, date/time
                stamp, and clickstream data. We use this information to analyze trends, administer
                the Website track Users` movements around the Website, gather demographic
                information about our user base as a whole, and better tailor our Services to our
                Users` needs. Except as noted in this Privacy Policy, we do not link this
                automatically-collected data to Personal Data.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.ListSpanTitle}>Cookies.</span>
              <span className={classes.TitleWrapper}>
                &nbsp;Like many online services, we use cookies to collect information. “Cookies”
                are small pieces of information that a website sends to your computer`s hard drive
                while you are viewing the website. We may use both session Cookies (which expire
                once you close your web browser) and persistent Cookies (which stay on your computer
                until you delete them) to provide you with a more personal and interactive
                experience on our Website. This type of information is collected to make the Website
                more useful to you and to tailor the experience with us to meet your special
                interests and needs.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.ListSpanTitle}>Pixel Tags</span>
              <span className={classes.TitleWrapper}>
                &nbsp;In addition, we use “Pixel Tags” (also referred to as clear Gifs, Web beacons,
                or Web bugs). Pixel Tags are tiny graphic images with a unique identifier, similar
                in function to Cookies, that are used to track online movements of Web Users. In
                contrast to Cookies, which are stored on a User`s computer hard drive, Pixel Tags
                are embedded invisibly in Web pages. Pixel Tags also allow us to send e-mail
                messages in a format Users can read, and they tell us whether e-mails have been
                opened to ensure that we are sending only messages that are of interest to our
                Users. We may use this information to reduce or eliminate messages sent to a User.
                We do not tie the information gathered by Pixel Tags to our Users` Personal Data.
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.ListSpanTitle}>Flash LSOs.</span>
              <span className={classes.TitleWrapper}>
                &nbsp;When we post videos, third parties may use local shared objects, known as
                “Flash Cookies” to store your preferences for volume control or to personalize
                certain video features. Flash Cookies are different from browser Cookies because of
                the amount and type of data and how the data is stored. Cookie management tools
                provided by your browser will not remove Flash Cookies. To learn how to manage
                privacy and storage settings for Flash Cookies, click here:&nbsp;
              </span>
              <span className={classes.SpanLink}>
                http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
              </span>
              <span className={classes.TitleWrapper}>.</span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphCustom}>
              <span className={classes.ListSpanTitle}>Analytics Services.</span>
              <span className={classes.TitleWrapper}>
                &nbsp;In addition to the tracking technologies we place, other companies may set
                their own Cookies or similar tools when you visit our Website. This includes third
                party analytics services, including but not limited to Google Analytics (“Analytics
                Services”), that we engage to help analyze how Users use the Website, as well as
                third parties that deliver content or offers. We may receive reports based on these
                parties` use of these tools on an individual or aggregate basis. We use the
                information we get from Analytics Services only to improve our Website and Services.
                The information generated by the Cookies or other technologies about your use of our
                Website and Services (the “Analytics Information”) is transmitted to the Analytics
                Services. The Analytics Services use Analytics Information to compile reports on
                User activity. The Analytics Services may also transfer information to third parties
                where required to do so by law, or where such third parties process Analytics
                Information on their behalf. Each Analytics Services` ability to use and share
                Analytics Information is restricted by such Analytics Services` Terms of Use and
                Privacy Policy. By using our Website and Services, you consent to the processing of
                data about you by Analytics Services in the manner and for the purposes set out
                above. For a full list of Analytics Services, please contact us at&nbsp;
              </span>
              <span className={classes.SpanLink}>support@monarkhq.com</span>
            </p>
          </li>
        </ol>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Information Collected from Third Parties. We may receive Personal Data and/or Anonymous
            Data about Users from Anthem, Inc. and its affiliates, and UnitedHealthcare, Inc. and
            their affiliates. We may add this information to the information we have already
            collected from you via our Website in order to improve the Services we provide.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>USE OF YOUR PERSONAL DATA</span>
        </h4>
        <p dir="ltr" className={classes.ParagraphCustom1}>
          <span className={classes.TitleWrapper}>
            General Use. In general, Personal Data you submit to us is used either to respond to
            requests that you make, or to aid us in serving you better. We use your Personal Data in
            the following ways:
          </span>
        </p>
        <ol className={classes.NoMargin}>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphWrapper}>
              <span className={classes.TitleWrapper}>
                facilitate the creation of and secure your BrokerAccount on our network;
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>identify you as a User in our system;</span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                provide improved administration of our Website and Services;
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                provide the Services you request, such as, providing a comparison between Anthem and
                UnitedHealthcare`s insurance pricing information and the values inputted by you;
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>contact you to solicit feedback;</span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                improve the quality of experience when you interact with our Website and Services;
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraph}>
              <span className={classes.TitleWrapper}>
                send you administrative e-mail notifications, such as security or support and
                maintenance advisories; and
              </span>
            </p>
          </li>
          <li dir="ltr" className={classes.ListWrapper}>
            <p dir="ltr" className={classes.ListParagraphCustom}>
              <span className={classes.TitleWrapper}>
                send newsletters, surveys, offers, and other promotional materials related to our
                Services and for other marketing purposes of Provider.
              </span>
            </p>
          </li>
        </ol>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            To the extent that you, as an insurance broker, submit or upload your clients` Personal
            Data to us (in the form of a census file, etc.), you confirm that you own or have all
            the necessary rights to provide such Personal Data and agree to indemnify MonarkHQ for
            any losses resulting from such submission by you or use of such Personal Date by
            MonarkHQ or its partners.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Creation of Anonymous Data. We may create Anonymous Data records from Personal Data by
            excluding information (such as your name) that makes the data personally identifiable to
            you. We use this Anonymous Data to analyze request and usage patterns so that we may
            enhance the content of our Services and improve Website navigation.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>DISCLOSURE OF YOUR PERSONAL DATA</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            We disclose your Personal Data as described below and as described elsewhere in this
            Privacy Policy.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Insurance Provider Users. We will share a User`s Personal Data with various employee
            benefits vendors and its affiliates, solely for the purpose of providing the Services.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Corporate Restructuring. We may share some or all of your Personal Data in connection
            with or during negotiation of any merger, or acquisition of all or substantially of our
            business or assets. If another company acquires our company, business, or assets, that
            company will possess the Personal Data collected by us and will assume the rights and
            obligations regarding your Personal Data as described in this Privacy Policy.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Disclosure to Third Party Individuals or Companies.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            This Privacy Policy addresses our use and disclosure of information we collect from
            and/or about you on the Website, or through the Service. The use and disclosure
            restrictions contained in this Privacy Policy will not apply to any third party. We do
            not control the privacy policies of third parties, and you are subject to the privacy
            policies of those third parties where applicable.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Other Disclosures. Regardless of any choices you make regarding your Personal Data,
            Provider may disclose Personal Data if it believes in good faith that such disclosure is
            necessary (a) in connection with any legal investigation; (b) to comply with relevant
            laws or to respond to subpoenas or warrants served on Provider; (c) to protect or defend
            the rights or property of Provider or Users of the Website or Services; and/or (d) to
            investigate or assist in preventing any violation or potential violation of the law,
            this Privacy Policy, or our Terms of Use.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>THIRD PARTY WEBSITES</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Our Website may contain links to third party websites. When you click on a link to any
            other website or location, you will leave our Website and go to another site, and
            another entity may collect Personal Data, or Anonymous Data from you. We have no control
            over, do not review, and cannot be responsible for, these third-party websites or their
            content. Please be aware that the terms of this Privacy Policy do not apply to these
            third-party websites or content, or to any collection of your Personal Data after you
            click on links to such third-party websites. We encourage you to read the privacy
            policies of every website you visit. The links to third party websites or locations are
            for your convenience and do not signify our endorsement of such third parties or their
            products, content or websites.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>YOUR CHOICES REGARDING INFORMATION</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            You have several choices regarding the use of information on our Service:
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Email Communications.We will periodically send you free newsletters and e-mails that
            directly promote the use of our Website or Services. When you receive newsletters or
            promotional communications from us, you may indicate a preference to stop receiving
            further communications from us and you will have the opportunity to “opt-out” by
            following the unsubscribe instructions provided in the e-mail you receive or by
            contacting us directly (please see contact information below). Despite your indicated
            e-mail preferences, we may send you service related communications, including notices of
            any updates to our Terms of Use or Privacy Policy.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Cookies. If you decide at any time that you no longer wish to accept Cookies from our
            Service for any of the purposes described above, then you can instruct your browser, by
            changing its settings, to stop accepting Cookies or to prompt you before accepting a
            Cookie from the websites you visit. Consult your browser`s technical information. If you
            do not accept Cookies, however, you may not be able to use all portions of the Service
            or all functionality of the Service. If you have any questions about how to disable or
            modify Cookies, please let us know at the contact information provided below.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            Changing or Deleting Your Personal Data. All Registered Users may review, update,
            correct or delete the Personal Data in their BrokerAccount (including any imported
            contacts) by contacting us or editing their profile via the Services. If you completely
            delete all of your Personal Data, then your BrokerAccount may become deactivated. We
            will use commercially reasonable efforts to honor your request. We may retain an
            archived copy of your records as required by law or for legitimate business purposes.
          </span>
        </p>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            QUESTIONS; CONTACTING PROVIDER; REPORTING VIOLATIONS. If you have any questions or
            concerns or complaints about our Privacy Policy or our data collection or processing
            practices, or if you want to report any security violations to us, please contact us at
            the following email address:&nbsp;
          </span>
          <span className={classes.SpanLink}>support@monarkhq.com</span>
          <span className={classes.TitleWrapper}>.</span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>A NOTE ABOUT CHILDREN</span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            We do not intentionally gather Personal Data from any person who is under the age of 13.
            If a child under 13 submits Personal Data to Provider and we become aware that the
            Personal Data is the information of a child under 13, we will endeavor to delete the
            information as soon as possible. If you believe that we might have any Personal Data
            from a child under 13, please contact us at&nbsp;
          </span>
          <span className={classes.SpanLink}>support@monarkhq.com</span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>
            A NOTE TO USERS OUTSIDE OF THE UNITED STATES
          </span>
        </h4>
        <p dir="ltr" className={classes.TextWrapper}>
          <span className={classes.TitleWrapper}>
            This Website is not intended for non-U.S. users. Our Website is located and targeted to
            United States citizens and our policies are directed at compliance with laws in the
            United States.
          </span>
        </p>
        <h4 dir="ltr" className={classes.ParagrapWrapper}>
          <span className={classes.ParagraphTitle}>CHANGES TO THIS PRIVACY POLICY.</span>
        </h4>
        <p dir="ltr" className={classes.ParagraphCustom2}>
          <span className={classes.TitleWrapper}>
            This Privacy Policy may be updated from time to time for any reason. We will notify you
            of any changes to our Privacy Policy by posting the new Privacy Policy here&nbsp;
          </span>
          <a href="https://monarkhq.com/privacy" className={classes.Link}>
            <span className={classes.LinkWrapper}>www.monarkhq.com/privacy</span>
          </a>
          <span className={classes.TitleWrapper}>
            &nbsp;and we will change the “Last Updated” date above. If you do not agree to any
            change(s) after receiving a notice of such change(s), you shall stop using the Website
            and/or the Services. Otherwise, your continued use of the Website and/or Services
            constitutes your acceptance of such change(s). YOU SHOULD CONSULT THIS PRIVACY POLICY
            REGULARLY FOR ANY CHANGES.
          </span>
        </p>
      </div>
    </div>
  );
};
