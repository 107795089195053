import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.brokerLicenses;

export const brokerLicensesSelector = createSelector(getState, (state) => state.brokerLicenses);
export const isLoadingBrokerLicensesSelector = createSelector(
  getState,
  (state) => state.isLoadingBrokerLicenses,
);

export const isLoadingUpdateLicenseSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateLicense,
);

export const isLoadingDeleteLicenseSelector = createSelector(
  getState,
  (state) => state.isLoadingDeleteLicense,
);
