import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { setIsSubscription } from 'src/store/app';
import { agencyUserRoleSelector } from 'src/store/agency';
import { setAccountData } from 'src/store/broker';
import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { ROUTE } from 'src/constants/routes';
import { ERROR } from 'src/constants/errorNames';

import { BrokerProfile, AgencyProfile, AgencyUsers, AgencyBilling } from './features';
import { BrokerLicenses } from './features/brokerLicenses/BrokerLicenses';
import { PageHeader } from 'src/components';

import classes from './settings.module.scss';

export const Settings = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const isBilling = searchParams.get('isBilling');
  const searchParamsActiveTab = searchParams.get('activeTab');

  const isBroker = pathname.includes(ROUTE.BROKER_SETTIGNS);
  const isAgency = pathname.includes(ROUTE.AGENCY);

  const agencyUserRole = useSelector(agencyUserRoleSelector);

  const [activeTab, setActiveTab] = useState('profile');

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
    dispatch(setIsSubscription(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchParamsActiveTab) {
      setActiveTab(searchParamsActiveTab);
    }
  }, [searchParamsActiveTab]);

  useEffect(() => {
    if (isAgency && agencyUserRole !== 'owner') {
      toast(ERROR.INACCESSABLE, { type: 'error' });
      navigate(`/teams/${params?.teamId}/accounts`);
    }
  }, [navigate, isAgency, agencyUserRole, params]);

  useEffect(() => {
    if (isBilling) {
      setActiveTab('billing');
    }
  }, [isBilling]);

  useEffect(() => {
    document.title = 'Monark | Settings';
  }, []);

  const onClickBackButton = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <PageHeader title={activeTab} onClickBackButton={onClickBackButton} />
      <div className={classes.Settings}>
        <div className={classes.SettingsContent}>
          {activeTab === 'profile' && isBroker && <BrokerProfile />}
          {activeTab === 'licenses' && isBroker && <BrokerLicenses />}

          {activeTab === 'profile' && isAgency && <AgencyProfile />}
          {activeTab === 'users' && isAgency && <AgencyUsers />}
          {activeTab === 'billing' && isAgency && <AgencyBilling />}
        </div>
      </div>
    </>
  );
};
