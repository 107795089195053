import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { groupMembersSelector } from 'src/store/groupMembers';

import { BuilderSelect, BuilderCASelect } from 'src/features/coverageBuilder';

import {
  fundingTypeOptions,
  compMethodOptions,
  dentalPlanTypeOptions,
  orthodonticOptions,
  orthodonticCoverageOptions,
  booleanOptions,
  networkCoverageOptions,
  annualMaxOptions,
  deductibleOptions,
  preventativeOptions,
  basicOptions,
  majorOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { dentalRenewalRates } from 'src/constants/renewalRates';
import { normalizeFundingType } from 'src/constants/proposalFormField';

export const useDentalBuild = () => {
  const members = useSelector(groupMembersSelector);
  const plan_names = dentalRenewalRates(members);

  const initialState = {
    id: uuid(),
    is_current_rate: false,
    contribution_type: 'percent',
    contribution_ee: 50,
    contribution_dep: 0,
    funding_type: '',
    plan_type: '',
    waiting_period: null,
    dppo_out_coverage: '',
    dppo_annual_max: null,
    dppo_deductible: null,
    dppo_preventative: null,
    dppo_basic: null,
    dppo_major: null,
    orthodontics: '',
    orthodontics_coverage: null,
    implant_coverage: null,
    comp_method: null,
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    selected_plan_name: null,
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
  };

  const [formData, setFormData] = useState(initialState);
  const [planRates, setPlanRates] = useState([]);
  const [initialPlanRates, setInitialPlanRates] = useState([]);

  useEffect(() => {
    const planRates = [];

    for (const plan_name of plan_names) {
      let payload = {
        ...initialState,
        id: uuid(),
        selected_plan_name: plan_name,
        is_current_rate: true,
        is_configured: false,
      };

      planRates.push(payload);
    }

    setPlanRates(planRates);
    setInitialPlanRates(planRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'dental',
    title: 'Dental',
    coverage_plan_name: 'dental_plan_name',
    card_options: {
      proposal: [
        {
          title: 'Type',
          key: 'plan_type',
        },
        {
          title: 'Orthodontics',
          key: 'orthodontics',
        },
        {
          title: 'Implant Coverage',
          key: 'implant_coverage',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          title: 'Annual Max',
          key: 'dppo_annual_max',
          format: (value) => (value ? value : 'No Max'),
        },
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    form_options: {
      contribution: true,
      contribution_title: 'Map Employees',
      initial_state: initialState,
      form_data: formData,
      setFormData,
      plan_names,
      planRates,
      setPlanRates,
      initialPlanRates,
    },
    form_content: (mode) => [
      <BuilderSelect
        key="1"
        label="Funding Type"
        value={formData.funding_type}
        options={fundingTypeOptions}
        onChange={(event) => onChange(event, 'funding_type')}
      />,
      <BuilderSelect
        key="2"
        label="Plan Type"
        value={formData.plan_type}
        options={dentalPlanTypeOptions}
        onChange={(event) => onChange(event, 'plan_type')}
        disabled={mode === 'EDIT'}
      />,
      <BuilderSelect
        key="3"
        label="Waiting Period"
        value={formData.waiting_period}
        options={booleanOptions}
        onChange={(event) => onChange(event, 'waiting_period')}
      />,
      ...(formData.plan_type === 'PPO'
        ? [
            <BuilderSelect
              key="4"
              label="DPPO: Out of Network Coverage"
              value={formData.dppo_out_coverage}
              options={networkCoverageOptions}
              onChange={(event) => onChange(event, 'dppo_out_coverage')}
            />,
            <BuilderSelect
              key="5"
              label="DPPO: Annual Max"
              value={formData.dppo_annual_max}
              options={annualMaxOptions}
              onChange={(event) => onChange(event, 'dppo_annual_max')}
              type="other"
              symbol="$"
              inputType="number"
            />,
            <BuilderSelect
              key="6"
              label="DPPO: Deductible"
              value={formData.dppo_deductible}
              options={deductibleOptions}
              onChange={(event) => onChange(event, 'dppo_deductible')}
            />,
            <BuilderSelect
              key="7"
              label="DPPO: Preventative"
              value={formData.dppo_preventative}
              options={preventativeOptions}
              onChange={(event) => onChange(event, 'dppo_preventative')}
            />,
            <BuilderSelect
              key="8"
              label="DPPO: Basic"
              value={formData.dppo_basic}
              options={basicOptions}
              onChange={(event) => onChange(event, 'dppo_basic')}
            />,
            <BuilderSelect
              key="9"
              label="DPPO: Major"
              value={formData.dppo_major}
              options={majorOptions}
              onChange={(event) => onChange(event, 'dppo_major')}
            />,
          ]
        : []),
      <BuilderSelect
        key="10"
        label="Orthodontics"
        value={formData.orthodontics}
        options={orthodonticOptions}
        onChange={(event) => onChange(event, 'orthodontics')}
        disabled={mode === 'EDIT'}
      />,
      ...(formData.orthodontics !== 'none'
        ? [
            <BuilderSelect
              key="11"
              label="Orthodontics Coverage"
              value={formData.orthodontics_coverage}
              options={orthodonticCoverageOptions}
              onChange={(event) => onChange(event, 'orthodontics_coverage')}
            />,
          ]
        : []),
      <BuilderSelect
        key="12"
        label="Implant Coverage"
        value={formData.implant_coverage}
        options={booleanOptions}
        onChange={(event) => onChange(event, 'implant_coverage')}
      />,
      <BuilderSelect
        key="13"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="14"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'funding_type', format: (value) => normalizeFundingType(value) },
      { key: 'waiting_period', format: (value) => (value ? 'Yes' : 'No') },
      {
        key: 'plan_type',
        render: (value) =>
          value === 'PPO'
            ? [
                {
                  key: 'dppo_out_coverage',
                  format: (value) => value,
                },
                {
                  key: 'dppo_annual_max',
                  format: (value) => formattedValue(value),
                },
                {
                  key: 'dppo_deductible',
                  format: (value) => formattedValue(value),
                },
                {
                  key: 'dppo_preventative',
                  format: (value) => `${value}%`,
                },
                {
                  key: 'dppo_basic',
                  format: (value) => `${value}%`,
                },
                {
                  key: 'dppo_major',
                  format: (value) => `${value}%`,
                },
              ]
            : [],
      },
      {
        key: 'orthodontics',
        render: (value) =>
          value !== 'none'
            ? [
                {
                  key: 'orthodontics_coverage',
                  format: (value) => formattedValue(value),
                },
              ]
            : [],
      },
      {
        key: 'implant_coverage',
        format: (value) => (value ? 'Yes' : 'No'),
      },
    ],
  };

  return { build };
};
