import {
  compMethodOptions,
  disabilityCompanyContributionOptions,
  disabilityPlanTypeOptions,
  ltdBenefitPeriodOptions,
  ltdEliminationPeriodOptions,
  pctIncomeRequestOptions,
  rateBasisOptions,
  stdBenefitPeriodOptions,
  stdEliminationPeriodOptions,
} from 'src/constants/coverage';
import {
  normalizeDisabilityPlanType,
  normalizeLtdBenefitPeriod,
  normalizeStdBenefitPeriod,
} from 'src/constants/requestProposal';

export const useDisabilityIssuerBuild = () => {
  const build = {
    coverage: 'disability',
    title: 'Disability',
    form_options: {
      // contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Contribution',
          key: 'contribution_type',
        },
        {
          title: '% of Income',
          key: 'income_pct',
          format: (value) => `${value} %`,
        },
        {
          title: 'Plan Type',
          key: 'plan_type',
          format: (value) => value.toUpperCase(),
          render: (value) =>
            value === 'std'
              ? [
                  { title: 'Elimination Period', key: 'std_elimination_period' },
                  {
                    title: 'Benefit Period',
                    key: 'std_benefit_period',
                    format: (value) => normalizeStdBenefitPeriod(value),
                  },
                ]
              : [
                  {
                    title: 'Elimination Period',
                    key: 'ltd_elimination_period',
                    format: (value) => `${value} Days`,
                  },
                  {
                    title: 'Benefit Period',
                    key: 'ltd_benefit_period',
                    format: (value) => normalizeLtdBenefitPeriod(value),
                  },
                ],
        },
        {
          title: 'Current Benefit',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    offer_details: [
      {
        key: 'income_pct',
        format: (value) => `${value} %`,
        options: {
          render_type: 'select',
          render_data: pctIncomeRequestOptions,
          type: 'other',
          symbol: '%',
          symbolPosition: 'end',
          inputType: 'number',
          maxValue: 100,
          minValue: 0,
        },
      },
      {
        key: 'contribution_type',
        options: {
          render_type: 'select',
          render_data: disabilityCompanyContributionOptions,
        },
      },
      {
        key: 'plan_type',
        format: (value) => normalizeDisabilityPlanType(value),
        render: (value) =>
          value === 'std'
            ? [
                {
                  key: 'std_weekly_max',
                  format: (value) => `$${value}`,
                  options: { render_type: 'input', symbol: '$', inputType: 'number' },
                },
                {
                  key: 'std_elimination_period',
                  options: {
                    render_type: 'select',
                    render_data: stdEliminationPeriodOptions,
                    type: 'other',
                  },
                },
                {
                  key: 'std_benefit_period',
                  format: (value) => normalizeStdBenefitPeriod(value),
                  options: {
                    render_type: 'select',
                    render_data: stdBenefitPeriodOptions,
                    type: 'other',
                    inputType: 'number',
                  },
                },
              ]
            : [
                {
                  key: 'ltd_monthly_max',
                  format: (value) => `$${value}`,
                  options: { render_type: 'input', symbol: '$', inputType: 'number' },
                },
                {
                  key: 'ltd_elimination_period',
                  format: (value) => `${value} Days`,
                  options: {
                    render_type: 'select',
                    render_data: ltdEliminationPeriodOptions,
                    symbol: 'Days',
                    symbolPosition: 'end',
                    type: 'other',
                  },
                },
                {
                  key: 'ltd_benefit_period',
                  format: (value) => normalizeLtdBenefitPeriod(value),
                  options: {
                    render_type: 'select',
                    render_data: ltdBenefitPeriodOptions,
                  },
                },
              ],
        options: { render_type: 'select', render_data: disabilityPlanTypeOptions, disabled: true },
      },
      {
        key: 'rate_per_unit',
        format: (value) => `$${value}`,
        options: {
          render_type: 'input',
          symbol: '$',
          inputType: 'number',
        },
      },
      {
        key: 'rate_basis',
        options: {
          render_type: 'select',
          render_data: rateBasisOptions,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
