import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';

import { getDentalIssuers } from 'src/store/planConfiguration';
import { getBrokerData } from 'src/store/broker';
import {
  carrierContactsSelector,
  deleteAgencyContact,
  deleteBrokerContact,
  getAllAgencyContacts,
  getAllBrokerContacts,
  getBrokerContactsMetaSelector,
  getBrokerContactsSelector,
  getCarrierContacts,
  isLoadingBrokerContactsSelector,
  isLoadingCarrierContactsSelector,
  updateAgencyContact,
  updateBrokerContact,
} from 'src/store/contacts';

import { AddContacts } from 'src/features/broker/addContacts/AddContacts';

import { Button } from '../Button/Button';
import { PageHeader } from '../PageHeader/PageHeader';
import { EmptyField } from '../EmptyField/EmptyField';
import { Loader, LoaderWrapper } from '../Loader/Loader';
import { EditContactModal } from '../EditContactModal/EditContactModal';

import classes from './brokerContactsTable.module.scss';

const headerRows = [
  { id: 'display_name', name: 'Rep Name', sortable: true },
  { id: 'company_name', name: 'Carrier', sortable: false },
  { id: 'email', name: 'Rep Email', sortable: true },
  { id: 'lines', name: 'Lines', sortable: true },
  { id: 'markets', name: 'Markets', sortable: true },
  { id: '1', name: 'Group size', sortable: false },
  { id: '2', name: '', sortable: false },
  { id: '3', name: '', sortable: false },
];

export const BrokerContactsTable = (props) => {
  const { onClickAdd, mode = 'add' } = props;
  const dispatch = useDispatch();

  const params = useParams();
  const org_id = params?.teamId;

  const brokerData = useSelector(getBrokerData);
  const brokerContacts = useSelector(getBrokerContactsSelector);
  const brokerContactsMeta = useSelector(getBrokerContactsMetaSelector);
  const isLoadingBrokerContacts = useSelector(isLoadingBrokerContactsSelector);

  const carrierContacts = useSelector(carrierContactsSelector);
  const isLoadingCarrierContacts = useSelector(isLoadingCarrierContactsSelector);

  const [selectedContact, setSelectedContact] = useState({});
  const [originalContactData, setOriginalContactData] = useState({});
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    brokerContactsMeta?.page ? brokerContactsMeta?.page : 1,
  );
  const [selectedPageSize, setSelectedPageSize] = useState(
    brokerContactsMeta?.per_page ? brokerContactsMeta?.per_page : 10,
  );
  const [searchValue, setSearchValue] = useState('');

  const [sortMethod, setSortMethod] = useState('email');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(brokerData) && !searchValue) {
      if (mode !== 'share') {
        const payload = {
          page: currentPage,
          per_page: selectedPageSize,
          sort: sortMethod,
        };

        if (!org_id) {
          dispatch(
            getAllBrokerContacts({
              id: brokerData.id,
              ...payload,
            }),
          );
        }
        if (org_id) {
          dispatch(
            getAllAgencyContacts({
              id: org_id,
              ...payload,
            }),
          );
        }
      }

      dispatch(getDentalIssuers({ read_token: null }));
    }
  }, [dispatch, mode, brokerData, currentPage, sortMethod, selectedPageSize, org_id, searchValue]);

  useEffect(() => {
    if (mode === 'share') {
      const debounceSearch = setTimeout(() => {
        dispatch(
          getCarrierContacts({
            page: currentPage,
            per_page: selectedPageSize,
            sort: sortMethod,
            q: searchValue,
          }),
        );
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, mode, currentPage, searchValue, selectedPageSize, sortMethod]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        dispatch(
          getCarrierContacts({
            page: currentPage,
            per_page: selectedPageSize,
            q: searchValue,
            sort: sortMethod,
          }),
        );
      }
    },
    [dispatch, currentPage, searchValue, selectedPageSize, sortMethod],
  );

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  const onChangeSearch = useCallback((event) => {
    setCurrentPage(1);

    setSearchValue(event.target.value);
  }, []);

  const onDeleteContact = useCallback(
    (item) => () => {
      if (!org_id) {
        const payload = {
          brokerId: brokerData?.id,
          contactId: item.id,
        };

        dispatch(deleteBrokerContact(payload));
      }

      if (org_id) {
        const payload = {
          org_id,
          contactId: item.id,
        };

        dispatch(deleteAgencyContact(payload));
      }
    },
    [dispatch, org_id, brokerData?.id],
  );

  const onClickEditIcon = useCallback(
    (item) => () => {
      setSelectedContact(item);
      setOriginalContactData(item);
      setIsVisibleEditModal(true);
    },
    [],
  );

  const onCloseEditModal = useCallback(() => {
    setSelectedContact({});
    setOriginalContactData({});
    setIsVisibleEditModal(false);
  }, []);

  const onUpdateContact = useCallback(
    (event) => {
      event.preventDefault();

      if (selectedContact?.min_group_size > selectedContact?.max_group_size) {
        return toast('Maximum group size should be grader than or equals to minimum group size!', {
          type: 'warning',
        });
      }

      if (!org_id) {
        dispatch(
          updateBrokerContact({ broker_id: brokerData?.id, ...selectedContact, team_id: org_id }),
        );
      }

      if (org_id) {
        dispatch(updateAgencyContact({ ...selectedContact, org_id }));
      }
    },
    [dispatch, brokerData?.id, selectedContact, org_id],
  );

  const contacts = mode !== 'share' ? brokerContacts : carrierContacts.items;
  const contactsMeta = mode !== 'share' ? brokerContactsMeta : carrierContacts.meta;

  const updatedHeaderRows =
    mode === 'share' ? headerRows.filter((item) => item.id !== '3') : headerRows;

  return (
    <>
      {isVisibleEditModal && (
        <EditContactModal
          onCloseEditModal={onCloseEditModal}
          onUpdateContact={onUpdateContact}
          selectedContact={selectedContact}
          originalContactData={originalContactData}
          setSelectedContact={setSelectedContact}
        />
      )}
      {mode !== 'share' && (
        <PageHeader title="Contacts">
          <AddContacts />
        </PageHeader>
      )}
      <div className={classes.ContactsTable}>
        <Paper>
          {isLoadingBrokerContacts || isLoadingCarrierContacts ? (
            <EmptyField>
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            </EmptyField>
          ) : (
            <>
              {mode === 'share' && (
                <div className={classes.TableContainer}>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchValue}
                    onChange={onChangeSearch}
                    onKeyDown={searchOnKeyDown}
                  />
                </div>
              )}
              {contacts?.length > 0 ? (
                <>
                  <TableContainer style={{ maxHeight: '500px' }}>
                    <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                      <TableHead>
                        <TableRow>
                          {updatedHeaderRows?.map((item) => (
                            <TableCell key={item.id}>
                              {item.sortable ? (
                                <TableSortLabel
                                  active={selectedCell === item.id}
                                  direction={cellDirection}
                                  onClick={onChangeCellSort(item.id)}
                                >
                                  {item.name}
                                </TableSortLabel>
                              ) : (
                                item.name
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contacts.map((item) => (
                          <TableRow
                            key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {item.display_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.issuer_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.lines?.join(', ')}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.markets?.join(', ')}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${item.min_group_size} - ${item.max_group_size}`}
                            </TableCell>
                            {mode === 'add' && (
                              <>
                                <TableCell component="th" scope="row">
                                  <i
                                    className={`${classes.EditIcon} fas fa-edit`}
                                    onClick={onClickEditIcon(item)}
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <i
                                    className={`${classes.TrashIcon} fa fa-trash-o`}
                                    onClick={onDeleteContact(item)}
                                  />
                                </TableCell>
                              </>
                            )}
                            {mode === 'share' && (
                              <TableCell component="th" scope="row">
                                <Button
                                  title="Add to contact"
                                  type="primary"
                                  onClick={onClickAdd(item)}
                                  size="s"
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    component="div"
                    count={contactsMeta?.total ?? 0}
                    rowsPerPage={selectedPageSize}
                    page={currentPage - 1}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onChangePageSize}
                  />
                </>
              ) : (
                'There are no created contacts yet. Start by creating one.'
              )}
            </>
          )}
        </Paper>
      </div>
    </>
  );
};
