import React, { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { IssuerProfile } from './features';
import { PageHeader } from 'src/components';

import classes from './issuerSettings.module.scss';

export const IssuerSettings = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');

  const isIssuer = pathname.includes('issuer');

  const onClickBackButton = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <PageHeader title="Settings" onClickBackButton={onClickBackButton} />
      <div className={classes.Settings}>
        <div className={classes.SettingsContent}>
          {isIssuer && <div className={classes.SettingsTitle}>{activeTab}</div>}
          {activeTab === 'profile' && isIssuer && <IssuerProfile />}
        </div>
      </div>
    </>
  );
};
