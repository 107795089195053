import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getBrokerData, setAccountData } from 'src/store/broker';
import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { ROUTE } from 'src/constants/routes';

import { BrokerProposalsTable } from 'src/components';

import classes from './brokerProposals.module.scss';

export const BrokerProposals = () => {
  const dispatch = useDispatch();

  const brokerData = useSelector(getBrokerData);

  useEffect(() => {
    document.title = 'Monark | Proposals';
  }, []);

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  return (
    <div className={classes.Proposals}>
      {brokerData?.status === 'inactive' ? (
        <div className={classes.ProposalsUpdateBilling}>
          Please update your
          <Link
            className={classes.ProposalsUpdateBillingLink}
            to={`${ROUTE.BROKER_SETTIGNS}?isBilling=true`}
          >
            {` billing information `}
          </Link>
          to continue.
        </div>
      ) : (
        <>
          <div className={classes.AccountTableWrapper}>
            <BrokerProposalsTable />
          </div>
        </>
      )}
    </div>
  );
};
