import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { auth } from 'src/app/database';
import { ROUTE } from 'src/constants/routes';
import {
  getIsLoadingVerificationLink,
  getUserTypeSelector,
  reSendEmailVerification,
} from 'src/store/app';

import { Button, Loader, LoaderWrapper } from 'src/components';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';
import classes from './emailVerification.module.scss';
import { getIssuerDataSelector } from 'src/store/issuer';

export const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const [searchParams] = useSearchParams();
  const continueUrl = searchParams.get('continueUrl');
  const replacedUrl = continueUrl?.replaceAll('?issuerId', '&issuerId');

  const issuerData = useSelector(getIssuerDataSelector);
  const userType = useSelector(getUserTypeSelector);
  const isLoadingVerificationLink = useSelector(getIsLoadingVerificationLink);

  const [seconds, setSeconds] = useState(60);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  useEffect(() => {
    setIsDisabledButton(true);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (userType === 'broker' && user.emailVerified) {
        navigate(ROUTE.BROKER);
      }

      if (userType === 'rep' && user.emailVerified) {
        navigate(`/issuer/${issuerData?.issuer_id}/rfps`);
      }
    }
  }, [issuerData, navigate, user, userType]);

  const resendVerificationLink = useCallback(() => {
    setIsDisabledButton(true);
    setSeconds(60);
    dispatch(
      reSendEmailVerification({
        email: user?.email,
        continueUrl: replacedUrl ? replacedUrl : '',
      }),
    );
  }, [dispatch, user?.email, replacedUrl]);

  useEffect(() => {
    if (seconds === 0) {
      setIsDisabledButton(false);
    }
  }, [isDisabledButton, seconds]);

  useEffect(() => {
    if (isDisabledButton) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isDisabledButton, seconds]);

  return (
    <div className={classes.EmailVerification}>
      <div className={classes.EmailVerificationCard}>
        <div className={classes.EmailVerificationLogo}>
          <img src={MonarkLogo} alt="loginMonarkLogo" />
        </div>
        {isLoadingVerificationLink ? (
          <div className={classes.LoaderWrapper}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </div>
        ) : (
          <>
            <div className={classes.EmailVerificationContent}>
              <div className={classes.EmailVerificationContentTitle}>Email Verification</div>
              <div className={classes.EmailVerificationContentSubTitle}>
                Email verification link sent to your email
              </div>
              <div className={classes.EmailVerificationContentEmail}>{user?.email}</div>
            </div>
            <Button
              type="primary"
              onClick={resendVerificationLink}
              title={`Send Verification Link Again ${seconds ? seconds : ''}`}
              isDisabled={isDisabledButton}
            />
          </>
        )}
      </div>
      <div className={classes.EmailVerificationCopyright}>
        <i className="far fa-copyright" />
        {moment().year()} Monark
      </div>
    </div>
  );
};
