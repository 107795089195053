import _ from 'lodash';

const coverageLimit = 80;

const lifeUniAllAges = (rate) => {
  let ages = { age_0_14_uni_tobacco: !_.isEmpty(rate) ? rate['age_0_uni_tobacco'] : '' };

  for (let i = 15; i <= coverageLimit; i++) {
    ages = {
      ...ages,
      [`age_${i}_uni_tobacco`]: !_.isEmpty(rate) ? rate[`age_${i}_uni_tobacco`] : '',
    };
  }

  return ages;
};

let LIFE_UNI_ALL_AGES_NAMES = {
  age_0_14_uni_tobacco: 'Age 0-14',
};

for (let i = 15; i <= coverageLimit; i++) {
  LIFE_UNI_ALL_AGES_NAMES = {
    ...LIFE_UNI_ALL_AGES_NAMES,
    [`age_${i}_uni_tobacco`]: i === 80 ? `Age ${i}+` : `Age ${i}`,
  };
}

export const normalizeAllAgeUniRates = (allAgeBandedRates) => {
  let payload = {
    ...allAgeBandedRates,
  };

  for (let i = 0; i <= 14; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: allAgeBandedRates['age_0_14_uni_tobacco'],
    };
  }

  delete payload['age_0_14_uni_tobacco'];

  return payload;
};

const lifeTobaccoAllAges = (rate) => {
  let ages = { age_0_14_tobacco: !_.isEmpty(rate) ? rate['age_0_tobacco'] : '' };

  for (let i = 15; i <= coverageLimit; i++) {
    ages = {
      ...ages,
      [`age_${i}_tobacco`]: !_.isEmpty(rate) ? rate[`age_${i}_tobacco`] : '',
    };
  }

  return ages;
};

let LIFE_TOBACCO_ALL_AGES_NAMES = {
  age_0_14_tobacco: 'Age 0-14 Tobacco',
};

for (let i = 15; i <= coverageLimit; i++) {
  LIFE_TOBACCO_ALL_AGES_NAMES = {
    ...LIFE_TOBACCO_ALL_AGES_NAMES,
    [`age_${i}_tobacco`]: i === 80 ? `Age ${i}+ Tobacco` : `Age ${i} Tobacco`,
  };
}

export const normalizeAllAgeTobaccoRates = (allAgeBandedRates) => {
  let payload = {
    ...allAgeBandedRates,
  };

  for (let i = 0; i <= 14; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: allAgeBandedRates['age_0_14_tobacco'],
    };
  }

  delete payload['age_0_14_tobacco'];

  return payload;
};

const lifeNonTobaccoAllAges = (rate) => {
  let ages = { age_0_14_non_tobacco: !_.isEmpty(rate) ? rate['age_0_non_tobacco'] : '' };

  for (let i = 15; i <= coverageLimit; i++) {
    ages = {
      ...ages,
      [`age_${i}_non_tobacco`]: !_.isEmpty(rate) ? rate[`age_${i}_non_tobacco`] : '',
    };
  }

  return ages;
};

let LIFE_NON_TOBACCO_ALL_AGES_NAMES = {
  age_0_14_non_tobacco: 'Age 0-14 Non-Tobacco',
};

for (let i = 15; i <= coverageLimit; i++) {
  LIFE_NON_TOBACCO_ALL_AGES_NAMES = {
    ...LIFE_NON_TOBACCO_ALL_AGES_NAMES,
    [`age_${i}_non_tobacco`]: i === 80 ? `Age ${i}+ Non-Tobacco` : `Age ${i} Non-Tobacco`,
  };
}

export const normalizeAllAgeNonTobaccoRates = (allAgeBandedRates) => {
  let payload = {
    ...allAgeBandedRates,
  };

  for (let i = 0; i <= 14; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: allAgeBandedRates['age_0_14_non_tobacco'],
    };
  }

  delete payload['age_0_14_non_tobacco'];

  return payload;
};

export {
  lifeUniAllAges,
  LIFE_UNI_ALL_AGES_NAMES,
  lifeTobaccoAllAges,
  LIFE_TOBACCO_ALL_AGES_NAMES,
  lifeNonTobaccoAllAges,
  LIFE_NON_TOBACCO_ALL_AGES_NAMES,
};
