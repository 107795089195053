import { Page, Text, Image, View } from '@react-pdf/renderer';
import moment from 'moment';
import _ from 'lodash';

import { styles } from '../styles';
import { renderSingleRow } from './render';

export const renderAccountPage = (proposalData, accountData, accountProposalData, brokerData) => {
  const quotedCoverages = [];

  for (const item of Object.keys(proposalData)) {
    if (!_.isEmpty(proposalData[item])) {
      quotedCoverages.push(item);
    }
  }

  const placeholderImage =
    'https://res.cloudinary.com/dlocussey/image/upload/v1589832771/website/128x128-placeholder_hvdami.png';

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Account</Text>
      <View style={styles.table}>
        {renderSingleRow('Prepared for', accountData.company_name)}
        {renderSingleRow(
          'Company',
          <Image
            style={{ height: 128, width: 128 }}
            src={accountData.logo_url ?? placeholderImage}
          />,
        )}
        {renderSingleRow('Prepared by', accountData.broker.display_name)}
        {renderSingleRow(
          'Effective Date of Proposal',
          moment(accountProposalData.effective_date).format('ll'),
        )}
        {renderSingleRow(
          'Lines of Coverage Quoted ',
          <Text style={{ textTransform: 'capitalize' }}>{quotedCoverages.join(', ')}</Text>,
        )}
      </View>
    </Page>
  );
};
