import {
  compMethodOptions,
  formattedValue,
  hospitalizationOptions,
  initialConfinementOptions,
} from 'src/constants/coverage';

export const useHospitalIssuerBuild = () => {
  const build = {
    coverage: 'hospital',
    title: 'Hospital',
    form_options: {
      // contribution: true,
    },
    card_options: {
      plan: [
        {
          title: 'Initial Hospital Confinement',
          key: 'initial_hospital_confinement',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Per Day Hospitalization',
          key: 'per_day_hospitalization',
          format: (value) => formattedValue(value),
        },
      ],
    },
    offer_details: [
      {
        key: 'initial_hospital_confinement',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: initialConfinementOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'per_day_hospitalization',
        format: (value) => formattedValue(value),
        options: {
          render_type: 'select',
          render_data: hospitalizationOptions,
          type: 'other',
          symbol: '$',
          inputType: 'number',
          minValue: 0,
        },
      },
      {
        key: 'comp_method',
        options: { render_type: 'select', render_data: compMethodOptions },
      },
    ],
  };

  return { build };
};
