import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getAccountData, getIsLoadingBrokerAccountData } from 'src/store/broker';
import {
  getAccountProposalSelector,
  getIsLoadingAccountProposal,
  setIsProposalFinalize,
} from 'src/store/proposals';
import { groupMembersSelector } from 'src/store/groupMembers';

import { Button, Loader, LoaderWrapper } from 'src/components';
import { GroupMembers } from '../groupMembers/GroupMembers';
import { ProposalHeader } from '../proposalHeader/ProposalHeader';

import classes from './proposalCensus.module.scss';

export const ProposalCensus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const accountId = params?.id;
  const proposalId = params?.proposalId;
  const teamId = params?.teamId;
  const isReadOnlyMode = pathname.includes('/read-only');

  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getAccountProposalSelector);
  const groupMembers = useSelector(groupMembersSelector);

  const isLoadingProposal = useSelector(getIsLoadingAccountProposal);
  const isLoadingAccount = useSelector(getIsLoadingBrokerAccountData);

  const isProposalMode =
    !_.isEmpty(accountData) && !_.isEmpty(proposalData) && !_.isEmpty(groupMembers);

  useEffect(() => {
    dispatch(setIsProposalFinalize(false));
  }, [dispatch]);

  const onClickNext = useCallback(() => {
    if (teamId) {
      navigate(`/teams/${teamId}/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`);
    }

    if (!teamId) {
      navigate(`/broker/accounts/${accountData?.id}/proposals/${proposalData?.id}/quotes`);
    }
  }, [navigate, teamId, accountData, proposalData]);

  return isLoadingAccount || isLoadingProposal ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <div className={classes.ProposalCensus}>
      <ProposalHeader
        isReadOnlyMode={isReadOnlyMode}
        accountId={accountId}
        proposalId={proposalId}
        isEditable={false}
      />
      <GroupMembers />
      {isProposalMode && (
        <div className={classes.ProposalCensusNavigate}>
          <Button title="Next" type="primary" onClick={onClickNext} />
        </div>
      )}
    </div>
  );
};
