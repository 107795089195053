import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './rangeInput.css';

export const RangeInput = (props) => {
  const {
    id,
    type = 'range',
    label,
    value,
    setValue,
    onChange,
    minRange,
    maxRange,
    step = 1,
    isRequired,
    isDisabled,
  } = props;

  const inputStyles = classnames({
    'styled-slider slider-progress': type === 'range',
  });

  useEffect(() => {
    for (let event of document.querySelectorAll(`#${id}`)) {
      event.style.setProperty('--value', value);
      event.style.setProperty('--min', event.min === '' ? '0' : event.min);
      event.style.setProperty('--max', event.max === '' ? '100' : event.max);
      event.addEventListener('input', () => event.style.setProperty('--value', event.value));
    }
  }, [value, id]);

  const onChangeRangeValue = useCallback(
    (event) => {
      if (!minRange && !maxRange) {
        if (!event.target.value) {
          setValue(0);
          return;
        }

        if (event.target.value.length > 3) {
          setValue(event.target.value.slice(0, 3));
          return;
        }

        if (event.target.value < 0) {
          setValue(0);
          return;
        }

        if (event.target.value > 100) {
          setValue(100);
          return;
        }

        if (event.target.value.match(/[0-9]/)[0] === '0') {
          setValue(event.target.value.replace(0, ''));
          return;
        }
      }

      if (maxRange) {
        if (!event.target.value) {
          setValue(minRange);
          return;
        }

        if (event.target.value.match(/[0-9]/)[0] === '0') {
          setValue(event.target.value.replace(0, ''));
          return;
        }

        if (event.target.value < minRange) {
          setValue(minRange);
          return;
        }

        if (event.target.value > maxRange) {
          setValue(maxRange);
          return;
        }
      }

      setValue(event.target.value);
    },
    [maxRange, minRange, setValue],
  );

  return (
    <div className={'input-wrapper'}>
      {label && (
        <label>
          {label}
          {isRequired && <span className={'required-start'}>*</span>}
        </label>
      )}
      <div className={'input-content'}>
        <input
          id={id}
          type="range"
          value={value}
          onChange={onChange}
          className={`${inputStyles}`}
          required={isRequired}
          disabled={isDisabled}
          min={minRange}
          max={maxRange}
          step={step}
        />
        <input
          className={'range-value'}
          type="number"
          value={value}
          onChange={onChangeRangeValue}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
