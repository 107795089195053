import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearAccidentConfigurationState } from './accidentConfiguration.slice';

export const getAccidentPlans = createAsyncThunk(
  'accident-configuration/get-accident-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.accidentConfiguration.getAccidentPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getAccidentQuotePlans = createAsyncThunk(
  'accident-configuration/get-accident-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.accidentConfiguration.getAccidentQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearAccidentState = createAsyncThunk('accident/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearAccidentConfigurationState());
});
