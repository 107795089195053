import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getIsLoadingIssuerAccountMembers, issuerAccountMembersSelector } from 'src/store/issuer';

import { Loader, LoaderWrapper, MembersTable, PageHeader } from 'src/components';
import { IssuerProposalHeader } from '../issuerProposalHeader/IssuerProposalHeader';

import classes from './issuerProposalCensus.module.scss';

export const IssuerProposalCensus = () => {
  const accountMembers = useSelector(issuerAccountMembersSelector);
  const isLoadingAccountMembers = useSelector(getIsLoadingIssuerAccountMembers);

  const [isVisibleGroupMembers, setIsVisibleGroupMembers] = useState(true);

  const handleShowGroupMembers = useCallback(() => {
    setIsVisibleGroupMembers((prev) => !prev);
  }, []);

  return (
    <div className={classes.IssuerProposalCensus}>
      <IssuerProposalHeader />

      <div className={classes.IssuerProposalCensusPage}>
        <PageHeader
          title="Group Members"
          isVisibleContent={isVisibleGroupMembers}
          onClickArrowButton={handleShowGroupMembers}
        />
        <div className={classes.IssuerProposalCensusMembers}>
          {isLoadingAccountMembers ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            isVisibleGroupMembers && <MembersTable membersData={accountMembers} />
          )}
        </div>
      </div>
    </div>
  );
};
