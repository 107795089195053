import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import api from 'src/app/apiSingleton';
import { auth } from 'src/app/database';

import { ROUTE } from 'src/constants/routes';
import { ERROR } from 'src/constants/errorNames';

import {
  setIsEditProfile,
  setIsFetchRepDataError,
  setIssuerAccountProposalData,
  setOtherRfpMeta,
  setRepAccountData,
  setRepOtherRfpMeta,
} from './issuer.slice';

export const logout = () => {
  localStorage.clear();
  auth.signOut();
};

export const fetchRepAccountData = createAsyncThunk(
  'issuer/get-rep-data',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      const userId = auth.currentUser.uid;

      api.apiClient.setToken(token);

      const response = await api.issuer.fetchRepAccountData(userId);
      thunkApi.dispatch(setRepAccountData(response));
      return response;
    } catch (err) {
      const { navigate, issuerToken } = data;

      if (issuerToken) {
        logout();
        thunkApi.dispatch(setRepAccountData({ message: 'error' }));
        thunkApi.dispatch(setIsFetchRepDataError(true));
        // toast('Something went wrong. Please, register your account again!', { type: 'warning' });
        navigate(`/issuer-signup?token=${issuerToken}`);
      }

      if (!issuerToken) {
        toast(ERROR.SYSTEM_ERROR, { type: 'error' });
        navigate(ROUTE.DEFAULT);
      }

      logout();
    }
  },
);

export const updateProfileData = createAsyncThunk(
  'broker/issuer-profile',
  async (data, thunkApi) => {
    try {
      const {
        email,
        fullName,
        // zipCode,
        photo_url,
        // address_street,
        // address_city,
        // address_state,
        phone_number,
      } = data;
      const token = await auth.currentUser.getIdToken();
      const currentUser = auth.currentUser;
      const userId = currentUser?.uid;

      await currentUser.updateProfile({
        email,
        photo_url,
        displayName: fullName,
      });

      api.apiClient.setToken(token);

      const payload = {
        email,
        photo_url,
        display_name: fullName,
        // address_zip_code: zipCode,
        // address_street,
        // address_city,
        // address_state,
        phone_number,
      };

      const response = await api.issuer.updateRepAccount(userId, payload);

      thunkApi.dispatch(fetchRepAccountData());
      thunkApi.dispatch(setIsEditProfile(false));

      return response;
    } catch (err) {
      console.warn('[ERROR]', err);
    }
  },
);

export const getLinkedRfp = createAsyncThunk('issuer/get-linked-rfp', async (data, thunkApi) => {
  try {
    const { issuerId, issuerToken } = data;

    const response = await api.issuer.getLinkedRfp(issuerId, issuerToken);

    return response;
  } catch (err) {
    const { navigate } = data;
    toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    navigate(ROUTE.DEFAULT);
  }
});

export const getOtherRfp = createAsyncThunk('issuer/get-other-rfp', async (data, thunkApi) => {
  try {
    const { issuerId, issuerToken } = data;

    const response = await api.issuer.getOtherRfp(issuerId, issuerToken);
    let convertedRfps = [];

    for (const rfp of response?.items) {
      const payload = {
        ...rfp,
        id: uuid(),
      };

      convertedRfps.push(payload);
    }

    return convertedRfps;
  } catch (err) {
    const { navigate } = data;
    toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    navigate(ROUTE.DEFAULT);
  }
});

export const getAllRequestedProposals = createAsyncThunk(
  'issuer/get-all-requested-proposals',
  async (data, thunkApi) => {
    try {
      const { issuerId, payload } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const otherRfps = await api.issuer.getOtherRfp(issuerId, payload);

      let convertedRfps = [];

      for (const rfp of otherRfps?.items) {
        const payload = {
          ...rfp,
          id: uuid(),
        };

        convertedRfps.push(payload);
      }

      thunkApi.dispatch(setOtherRfpMeta(otherRfps.meta));

      return [...convertedRfps];
    } catch (err) {
      const { navigate } = data;
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
      navigate(ROUTE.DEFAULT);
    }
  },
);

export const getMyRequestedProposals = createAsyncThunk(
  'issuer/get-my-requested-proposals',
  async (data, thunkApi) => {
    try {
      const { repId, payload } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const otherRfps = await api.issuer.getRepOtherRfp(repId, payload);

      let convertedRfps = [];

      for (const rfp of otherRfps?.items) {
        const payload = {
          ...rfp,
          id: uuid(),
        };

        convertedRfps.push(payload);
      }

      thunkApi.dispatch(setRepOtherRfpMeta(otherRfps.meta));

      return [...convertedRfps];
    } catch (err) {
      const { navigate } = data;
      toast('System Error. Please email us.', { type: 'error' });
      navigate(ROUTE.DEFAULT);
    }
  },
);

export const getIssuerAccountMembers = createAsyncThunk(
  'issuer/get-account-members',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const proposalPayload = {
        accountId,
        proposalId,
      };

      const response = await api.proposal.getAccountProposal(proposalPayload);

      thunkApi.dispatch(setIssuerAccountProposalData(response));

      const members = await api.groupMembers.getGroupMembers(accountId, response.group_ids[0]);

      return members.items;
    } catch (err) {
      const { navigate } = data;
      console.error(err);
      toast(ERROR.INACCESSABLE, { type: 'error' });
      navigate(ROUTE.NOT_AUTHORIZED);
    }
  },
);

export const getIssuerCoveragePlans = createAsyncThunk(
  'issuer/get-issuer-coverage-plans',
  async (data) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const medicalResponse = await api.planConfiguration.getMedicalPlans(accountId, proposalId);
      const dentalResponse = await api.planConfiguration.getDentalPlans(accountId, proposalId);
      const visionResponse = await api.planConfiguration.getVisionPlans(accountId, proposalId);
      const lifeResponse = await api.planConfiguration.getLifePlans(accountId, proposalId);
      const disabilityResponse = await api.planConfiguration.getDisabilityPlans(
        accountId,
        proposalId,
      );
      const accidentResponse = await api.accidentConfiguration.getAccidentPlans(
        accountId,
        proposalId,
      );
      const hospitalResponse = await api.hospitalConfiguration.getHospitalPlans(
        accountId,
        proposalId,
      );
      const criticalResponse = await api.criticalConfiguration.getCriticalPlans(
        accountId,
        proposalId,
      );

      let activeTabs = {
        medical: medicalResponse,
        dental: dentalResponse,
        vision: visionResponse,
        life: lifeResponse,
        disability: disabilityResponse,
        accident: accidentResponse,
        hospital: hospitalResponse,
        critical_illness: criticalResponse,
      };

      // CLEARING EMPTY PLANS
      for (const item in activeTabs) {
        if (!activeTabs[item].length) {
          delete activeTabs[item];
        }
      }

      // DEFINING FIRST ACTIVE TAB
      for (const item in activeTabs) {
        activeTabs = {
          ...activeTabs,
          active_tab: item,
        };
        break;
      }

      return activeTabs;
    } catch (err) {
      console.warn(err);
    }
  },
);
