export const lumpGiOptions = [
  { title: '$5000', value: '5000' },
  { title: '$10000', value: '10000' },
  { title: '$20000', value: '20000' },
  { title: '$30000', value: '30000' },
  { title: '$40000', value: '40000' },
  { title: '$50000', value: '50000' },
];

export const spouseChildOptions = [
  { title: '25%', value: '25' },
  { title: '50%', value: '50' },
  { title: 'N/A', value: 'N/A' },
];

export const booleanOptions = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
];
