import Base from './base';

class IssuerCoverageBuilder extends Base {
  getPlans = (accountId, proposalId, coverage, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getOffers = (accountId, proposalId, coverage, configId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${configId}/offers${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  createOffer = (accountId, proposalId, configId, coverage, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${configId}/offers`,
      payload,
    );
  };

  updateOffer = (accountId, proposalId, configId, coverage, payload, offerId, read_token) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${coverage}/config/${configId}/offers/${offerId}${
        read_token ? `?token=${read_token}` : ''
      }`,
      payload,
    );
  };

  deleteOffer = (accountId, proposalId, medicalType, configId, offerId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalType}/config/${configId}/offers/${offerId}`,
    );
  };
}

export default IssuerCoverageBuilder;
