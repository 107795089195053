import moment from 'moment';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AccountDetails, Loader, LoaderWrapper, PageHeader } from 'src/components';
import { getAccountData, getIsLoadingBrokerAccountData } from 'src/store/broker';
import { getIsLoadingIssuerAccountMembers, getIssuerAccountProposalData } from 'src/store/issuer';
import { IssuerProposalHeader } from '../issuerProposalHeader/IssuerProposalHeader';
import {
  AccidentPlans,
  CriticalPlans,
  DentalPlans,
  DisabilityPlans,
  HospitalPlans,
  LifePlans,
  MedicalPlans,
  VisionPlans,
} from '../issuerRfpView/features';

import classes from './issuerProposalFinalize.module.scss';

export const IssuerProposalFinalize = () => {
  const accountData = useSelector(getAccountData);
  const proposalData = useSelector(getIssuerAccountProposalData);
  const isLoadingAccountData = useSelector(getIsLoadingBrokerAccountData);
  const isLoadingissuerMembers = useSelector(getIsLoadingIssuerAccountMembers);

  const [isVisibleMedicalQuotes, setIsVisibleMedicalQuotes] = useState(true);
  const [isVisibleDentalQuotes, setIsVisibleDentalQuotes] = useState(true);
  const [isVisibleVisionQuotes, setIsVisibleVisionQuotes] = useState(true);
  const [isVisibleLifeQuotes, setIsVisibleLifeQuotes] = useState(true);
  const [isVisibleDisabilityQuotes, setIsVisibleDisabilityQuotes] = useState(true);
  const [isVisibleAccidentQuotes, setIsVisibleAccidentQuotes] = useState(true);
  const [isVisibleHospitalQuotes, setIsVisibleHospitalQuotes] = useState(true);
  const [isVisibleCriticalQuotes, setIsVisibleCriticalQuotes] = useState(true);

  const handleShowMedicalQuotes = useCallback(() => {
    setIsVisibleMedicalQuotes((prev) => !prev);
  }, []);

  const handleShowDentalQuotes = useCallback(() => {
    setIsVisibleDentalQuotes((prev) => !prev);
  }, []);

  const handleShowVisionQuotes = useCallback(() => {
    setIsVisibleVisionQuotes((prev) => !prev);
  }, []);

  const handleShowLifeQuotes = useCallback(() => {
    setIsVisibleLifeQuotes((prev) => !prev);
  }, []);

  const handleShowDisabilityQuotes = useCallback(() => {
    setIsVisibleDisabilityQuotes((prev) => !prev);
  }, []);

  const handleShowAccidentQuotes = useCallback(() => {
    setIsVisibleAccidentQuotes((prev) => !prev);
  }, []);

  const handleShowHospitalQuotes = useCallback(() => {
    setIsVisibleHospitalQuotes((prev) => !prev);
  }, []);

  const handleShowCriticalQuotes = useCallback(() => {
    setIsVisibleCriticalQuotes((prev) => !prev);
  }, []);

  return isLoadingAccountData || isLoadingissuerMembers ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <div className={classes.IssuerProposalFinalize}>
      <IssuerProposalHeader />

      <AccountDetails
        previewImage={accountData?.logo_url}
        companyName={accountData?.company_name}
        addressStreet={accountData?.address_street}
        addressCity={accountData?.address_city}
        addressState={accountData?.address_state}
        addressZip={accountData?.address_zip_code}
        fipsCode={accountData?.fips_code}
        effectiveDate={moment(proposalData?.effective_date).format('l')}
        dueDate={moment(proposalData?.due_date).format('l')}
        brokerName={accountData?.broker?.display_name}
        brokerEmail={accountData?.broker?.email}
        brokerPhone={accountData?.broker?.phone_number}
        brokerData={accountData?.broker}
        sicCode={accountData?.sic_code}
        ratingArea={accountData?.rating_area_id}
        isLoading={isLoadingAccountData}
        readOnlyMode
      />

      <PageHeader
        title="Medical Quotes"
        isVisibleContent={isVisibleMedicalQuotes}
        onClickArrowButton={handleShowMedicalQuotes}
      />
      {isVisibleMedicalQuotes && <MedicalPlans activeTab="medical" />}
      <PageHeader
        title="Dental Quotes"
        isVisibleContent={isVisibleDentalQuotes}
        onClickArrowButton={handleShowDentalQuotes}
      />
      {isVisibleDentalQuotes && <DentalPlans activeTab="dental" />}
      <PageHeader
        title="Vision Quotes"
        isVisibleContent={isVisibleVisionQuotes}
        onClickArrowButton={handleShowVisionQuotes}
      />
      {isVisibleVisionQuotes && <VisionPlans activeTab="vision" />}
      <PageHeader
        title="Life Quotes"
        isVisibleContent={isVisibleLifeQuotes}
        onClickArrowButton={handleShowLifeQuotes}
      />
      {isVisibleLifeQuotes && <LifePlans activeTab="life" />}
      <PageHeader
        title="Disability Quotes"
        isVisibleContent={isVisibleDisabilityQuotes}
        onClickArrowButton={handleShowDisabilityQuotes}
      />
      {isVisibleDisabilityQuotes && <DisabilityPlans activeTab="disability" />}
      <PageHeader
        title="Accident Quotes"
        isVisibleContent={isVisibleAccidentQuotes}
        onClickArrowButton={handleShowAccidentQuotes}
      />
      {isVisibleAccidentQuotes && <AccidentPlans activeTab="accident" />}
      <PageHeader
        title="Hospital Quotes"
        isVisibleContent={isVisibleHospitalQuotes}
        onClickArrowButton={handleShowHospitalQuotes}
      />
      {isVisibleHospitalQuotes && <HospitalPlans activeTab="hospital" />}
      <PageHeader
        title="Critical Illness Quotes"
        isVisibleContent={isVisibleCriticalQuotes}
        onClickArrowButton={handleShowCriticalQuotes}
      />
      {isVisibleCriticalQuotes && <CriticalPlans activeTab="critical_illness" />}
    </div>
  );
};
