import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.groupMembers;

export const groupMembersSelector = createSelector(getState, (state) => state.groupMembers);
export const groupDataSelector = createSelector(getState, (state) => state.groupData);
export const isLoadingCreateGroupMembersSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateGroupMembers,
);

export const isLoadingCreateCensusSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateCensus,
);

export const isLoadingGroupMembersSelector = createSelector(
  getState,
  (state) => state.isLoadingGroupMembers,
);

export const isLoadingCreateCensusMemberSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateCensusMember,
);
export const isLoadingUpdateCensusMemberSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateCensusMember,
);
export const isLoadingDeleteCensusMemberSelector = createSelector(
  getState,
  (state) => state.isLoadingDeleteCensusMember,
);
