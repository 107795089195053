import React, { useEffect } from 'react';

import classes from './aboutCompany.module.scss';

export const AboutCompany = () => {
  useEffect(() => {
    document.title = 'Monark | About Us';
  }, []);

  return (
    <div className={classes.Layout}>
      <div className={classes.LayoutEllipseLeft} />
      <div className={classes.LayoutEllipseRight} />
      <div className={classes.LayoutLightLine} />

      <div className={classes.About}>
        <div className={classes.AboutImage} />

        <div className={classes.AboutContent}>
          <div className={classes.AboutSectionLeft}>
            <div className={classes.AboutSectionLeftSubTitle}>ABOUT US</div>
            <div className={classes.AboutSectionLeftTitle}>About Our Company</div>
          </div>
          <div className={classes.AboutSectionRight}>
            <p>
              Spending almost 10 years in broker sales for a fortune 100 insurance carrier and an
              equal time spent on the broker side, driving new sales and renewals with clients, we
              were always frustrated by the slow pace of quoting and proposals as they go back and
              forth between Clients to Brokers to General Agencies to Carriers and back again every
              time an edit was needed led us to look hard at finding a better way. Time kills deals
              and hurts service, so we wanted to fix this!
            </p>
            <p>
              The existing quoting systems were old and clunky, harder to use than they should be,
              provided limited functionality and worst of all are expensive. Due to these
              constraints many brokers simply outsource their quoting to general agents but then get
              back static pdf or excel reports that can take a day or 2 to edit if the client
              requests changes and may not provide insights into all plan options available.
            </p>
            <p>
              MonarkHQ allows you to now run quotes for free and share proposals instantly with your
              client and make live updates to their web based quote, all they need is an email
              account and a web browser (These days it`s pretty safe to assume everyone in HR has
              these now!)
            </p>
            <p>
              Vendors gain useful sales feedback into which plans are being quoted most, which types
              of companies are resulting in more closed sales for them and help speed up the quoting
              and implementation workloads to get the deal closed faster with less effort.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
