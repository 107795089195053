import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.accountContacts;

export const accountContactsSelector = createSelector(getState, (state) => state.accountContacts);

export const isLoadingCreateContactSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateContact,
);
export const isLoadingUpdateContactSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateContact,
);
export const isLoadingDeleteContactSelector = createSelector(
  getState,
  (state) => state.isLoadingDeleteContact,
);
