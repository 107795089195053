import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import {
  deleteCoveragePlan,
  getCoverageOffers,
  updateCoverageOffer,
} from 'src/store/coverageBuilder';
import { getAccountProposalSelector } from 'src/store/proposals';

import { Button, EmptyField, Loader, LoaderWrapper, Modal, Status } from 'src/components';
import { CardOffers } from '../CardOffers/CardOffers';

import { MODAL_NAMES } from 'src/constants/modalNames';

import classes from 'src/sass/styles/Proposal/proposal.module.scss';

export const ProposalCard = (props) => {
  // type - proposal | quote, currency, boolean, custom(value1 - function, value2 - object property)

  const { onClickConfigDetails, config, type = 'proposal', build } = props;

  const { card_options, coverage } = build;

  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();

  const accountProposalData = useSelector(getAccountProposalSelector);

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;

  const read_token = params?.token;

  const isReadOnlyMode = pathname.includes('/read-only');

  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isVisibleCardContent, setIsVisibleCardContent] = useState(false);
  const [offers, setOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);

  const fetchCoveregeOffers = useCallback(() => {
    dispatch(
      getCoverageOffers({
        accountId,
        proposalId,
        coverage,
        configId: config.id,
        brokerConfig: config,
        setOffers,
        setIsLoading: setIsLoadingOffers,
        read_token,
        params: type === 'quote' ? { 'f[status]': 'selected,approved', token: read_token } : {},
      }),
    );
  }, [dispatch, accountId, config, coverage, proposalId, type, read_token]);

  useEffect(() => {
    if (isVisibleCardContent) {
      fetchCoveregeOffers();
    } else {
      setOffers([]);
    }

    return () => {
      setOffers([]);
    };
  }, [fetchCoveregeOffers, isVisibleCardContent]);

  const onCardClick = useCallback(() => {
    setIsVisibleCardContent((prev) => !prev);
  }, []);

  const onShowDeleteModal = useCallback(() => {
    setIsVisibleDeleteModal(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setIsVisibleDeleteModal(false);
  }, []);

  const onClickDelete = useCallback(async () => {
    if (type === 'proposal') {
      return dispatch(deleteCoveragePlan({ accountId, proposalId, coverage, planId: config?.id }));
    }

    const response = await dispatch(
      getCoverageOffers({
        accountId,
        proposalId,
        coverage,
        configId: config.id,
        brokerConfig: config,
        setOffers,
        setIsLoading: setIsLoadingOffers,
        read_token,
        params: type === 'quote' ? { 'f[status]': 'selected,approved', token: read_token } : {},
      }),
    );

    const offers = response.payload;

    for (const offer of offers) {
      const payload = {
        ...offer,
        accountId,
        proposalId,
        isBroker: true,
      };

      payload.status = 'completed';

      dispatch(updateCoverageOffer({ payload, coverage, offerId: payload.id, read_token }));
    }
  }, [dispatch, type, accountId, proposalId, coverage, config, read_token]);

  const onSelectCoverageOffer = useCallback(
    (item, status) => async () => {
      const payload = { ...item, accountId, proposalId };

      payload.status = status;
      delete payload.calculation;

      await dispatch(updateCoverageOffer({ payload, coverage, offerId: payload.id }));
      await fetchCoveregeOffers();
    },
    [dispatch, fetchCoveregeOffers, accountId, proposalId, coverage],
  );

  const coverageOffers = offers?.filter((item) => item.issuer_name !== 'broker');

  const brokerConfig = offers?.find((item) => item.issuer_name === 'broker');

  return (
    <>
      {isVisibleDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          {isLoadingOffers ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <div className={classes.ModalContent}>
              <div className={classes.ModalContentTitle}>
                {type === 'proposal' ? MODAL_NAMES.DELETE_QUOTE : MODAL_NAMES.EXCLUDE_QUOTE}
              </div>
              <div className={classes.ModalContentFooter}>
                <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
                <Button type="danger" title="Yes" onClick={onClickDelete} />
              </div>
            </div>
          )}
        </Modal>
      )}

      <div className={classes.Proposal}>
        <div className={classes.ProposalHeader} onClick={onCardClick}>
          <div className={classes.HeaderIcon}>
            <div className={classes.HeaderIconWrapper}>
              {/* <i className={`fas fa-bars ${classes.ProposalHeaderBarIcon}`} /> */}
              <i
                className={`fas fa-chevron-down ${
                  isVisibleCardContent
                    ? classes.ProposalHeaderIconActive
                    : classes.ProposalHeaderIcon
                }`}
              />
            </div>
          </div>
          <div className={classes.ProposalHeaderWrapper}>
            {card_options?.proposal?.map((option) => (
              <>
                <div className={classes.ProposalHeaderItem} key={option.key}>
                  <div className={classes.ProposalHeaderItemHeading}>
                    <Tooltip title={option.title}>{option.title}</Tooltip>
                  </div>

                  <div className={classes.ProposalHeaderItemTitle}>
                    <Tooltip
                      title={
                        option?.format ? option?.format(config[option.key]) : config[option.key]
                      }
                    >
                      {option?.format ? option?.format(config[option.key]) : config[option.key]}
                    </Tooltip>
                  </div>
                </div>
                {option?.render &&
                  option.render(config[option.key])?.map((renderItem) => (
                    <div className={classes.ProposalHeaderItem} key={renderItem.key}>
                      <div className={classes.ProposalHeaderItemHeading}>
                        <Tooltip title={renderItem.title}>{renderItem.title}</Tooltip>
                      </div>

                      <div className={classes.ProposalHeaderItemTitle}>
                        <Tooltip
                          title={
                            renderItem?.format
                              ? renderItem?.format(config[renderItem.key])
                              : config[renderItem.key]
                          }
                        >
                          {renderItem?.format
                            ? renderItem?.format(config[renderItem.key])
                            : config[renderItem.key]}
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </>
            ))}
            <div className={classes.ProposalHeaderButtons}>
              <div className={classes.ProposalHeaderStatus}>
                <Status status={config.status} />
              </div>
              {!isReadOnlyMode && type === 'proposal' && (
                <Button
                  title="Config Details"
                  type="secondary"
                  onClick={onClickConfigDetails(config)}
                />
              )}
            </div>
          </div>
        </div>
        {!isReadOnlyMode && (
          <div className={classes.ProposalDeleteButton} onClick={onShowDeleteModal}>
            <i className="fa fa-trash-o" />
          </div>
        )}
      </div>

      {isVisibleCardContent && (
        <>
          {isLoadingOffers ? (
            <div className={classes.LoaderCard}>
              <div className={classes.LoaderWrapper}>
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              </div>
            </div>
          ) : coverageOffers?.length ? (
            <CardOffers
              type={type}
              build={build}
              offers={coverageOffers}
              brokerConfig={brokerConfig}
              fetchOffers={fetchCoveregeOffers}
              onSelectCoverageOffer={onSelectCoverageOffer}
            />
          ) : (
            <EmptyField title="No offers received" />
          )}
        </>
      )}
    </>
  );
};
