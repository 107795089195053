import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowDown from 'src/assets/app/arrow-down.svg';
import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './selectV2.module.scss';

export const SelectV2 = (props) => {
  const {
    selectedOption,
    options,
    label,
    placeholder = 'Select Option',
    onOptionClick,
    type = 'default',
  } = props;

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const [optionsList, setOptionsList] = useState(options);
  const [searchValue, setSearchValue] = useState(selectedOption);

  useEffect(() => {
    setSearchValue(selectedOption);
  }, [selectedOption]);

  const selectRef = useRef();

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  useEffect(() => {
    if (type === 'search') {
      if (!searchValue) {
        setOptionsList(options);

        if (!isVisibleMenu) {
          setSearchValue(selectedOption);
        }
      }

      if (searchValue) {
        const searchOptions = options.filter((item) =>
          item?.option.toLowerCase().includes(searchValue?.toLowerCase()),
        );
        setOptionsList(searchOptions);
      }
    }
  }, [type, options, searchValue, isVisibleMenu, selectedOption]);

  const onOpenMenu = useCallback(() => {
    setIsVisibleMenu((prev) => !prev);
  }, []);

  const onSelect = useCallback(
    (value) => () => {
      if (type === 'multiple') {
        return onOptionClick(value);
      }

      onOptionClick(value);
      setIsVisibleMenu(false);
    },
    [onOptionClick, type],
  );

  const onChangeInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickOutside = useCallback(
    (event) => {
      if (selectRef && !selectRef.current?.contains(event.target) && isVisibleMenu) {
        setIsVisibleMenu(false);
      }
    },
    [selectRef, isVisibleMenu],
  );

  useEffect(() => {
    if (isVisibleMenu) {
      document.addEventListener('mousedown', onClickOutside);

      return () => {
        document.removeEventListener('mousedown', onClickOutside);
      };
    }
  }, [onClickOutside, isVisibleMenu]);

  const menuStyles = classNames({
    [classes.SelectMenuHidden]: !isVisibleMenu,
    [classes.SelectMenuActive]: isVisibleMenu,
  });

  const selectedOptionTitle = options?.find((item) => item?.option === selectedOption);

  return (
    <div className={classes.Wrapper} ref={selectRef}>
      {label && <div className={classes.SelectLabel}>{label}</div>}
      <div className={classes.Select} onClick={onOpenMenu} style={!label ? { marginTop: 0 } : {}}>
        {selectedOptionTitle?.label ?? placeholder}
        {type === 'search' && isVisibleMenu && (
          <input
            value={searchValue}
            onChange={onChangeInput}
            className={classes.SelectInput}
            autoFocus
          />
        )}
        <img src={ArrowDown} alt="ArrowDown" />
      </div>
      <div className={menuStyles} style={!label ? { top: '49px' } : {}}>
        {optionsList?.map((item) => (
          <div className={classes.SelectMenuItem} key={item.option} onClick={onSelect(item.option)}>
            {type === 'multiple' &&
              (item?.selected ? (
                <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" />
              ) : (
                <img src={PurpleCircle} alt="PurpleCircle" />
              ))}

            {item?.label}
          </div>
        ))}
      </div>
    </div>
  );
};

SelectV2.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.string,
  onOptionClick: PropTypes.func,
  type: PropTypes.oneOf(['default', 'search', 'multiple']),
};
