import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.chat;

export const chatRoomsSelector = createSelector(getState, (state) => state.chatRooms);

export const chatRoomMessagesSelector = createSelector(getState, (state) => state.chatRoomMessages);

export const isLoadingStartChatSelector = createSelector(
  getState,
  (state) => state.isLoadingStartChat,
);

export const isLoadingChatRoomsSelector = createSelector(
  getState,
  (state) => state.isLoadingChatRooms,
);

export const isLoadingChatRoomMessagesSelector = createSelector(
  getState,
  (state) => state.isLoadingChatRoomMessages,
);
