import React, { useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';

import { auth } from 'src/app/database';

import {
  getBrokerData,
  getIsEditProfile,
  isLoadingUpdateProfileSelector,
  setIsEditProfile,
  updateProfileData,
} from 'src/store/broker';
import { sendPasswordResetEmail } from 'src/store/app';

import { stateCodes } from 'src/constants/stateCodes';
import { formatPhoneNumber } from 'src/utils/format';

import { UploadImage } from 'src/features/uploadImage/UploadImage';
import { Button, DetailsItem, Input, InputPhone, SearchSelect, Status } from 'src/components';

import classes from './brokerProfile.module.scss';

export const BrokerProfile = () => {
  const dispatch = useDispatch();

  const [user] = useAuthState(auth);
  const brokerData = useSelector(getBrokerData);
  const isEditMode = useSelector(getIsEditProfile);
  const isLoadingUpdateProfile = useSelector(isLoadingUpdateProfileSelector);

  // TODO: Refactor to formData like usage

  const [fullName, setFullName] = useState(user?.displayName);
  const [email, setEmail] = useState(user?.email);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState(brokerData?.address_zip_code);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [previewImage, setPreviewImage] = useState(brokerData?.photo_url);
  const [stateSearchValue, setStateSearchValue] = useState('');

  useEffect(() => {
    dispatch(setIsEditProfile(false));
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode) {
      setZipCode(brokerData?.address_zip_code);
      setAddress(brokerData?.address_street ?? '');
      setCity(brokerData?.address_city ?? '');
      setState(brokerData?.address_state ?? '');
      setPhoneNumber(brokerData?.phone_number ?? '');
      setStateSearchValue(brokerData?.address_state ?? '');
    }
  }, [isEditMode, brokerData]);

  useEffect(() => {
    if (brokerData?.photo_url) {
      setPreviewImage(brokerData?.photo_url);
    }
  }, [brokerData]);

  const onSetPreviewImage = useCallback((image) => {
    setPreviewImage(image);
  }, []);

  const onChangeFullName = useCallback((event) => {
    setFullName(event.target.value);
  }, []);

  const onChangeAddress = useCallback((event) => {
    setAddress(event.target.value);
  }, []);

  const onChangeCity = useCallback((event) => {
    setCity(event.target.value);
  }, []);

  const onChangeState = useCallback((event) => {
    setState(event.target.value);
    setStateSearchValue(event.target.value);
  }, []);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangeZipCode = useCallback((event) => {
    if (event.target.value.length > 5) {
      setZipCode(event.target.value.slice(0, 5));
      return;
    }
    setZipCode(event.target.value);
  }, []);

  const onChangePhoneNumber = useCallback((phone) => {
    setPhoneNumber(phone);
  }, []);

  const onClickEditProfile = useCallback(() => {
    dispatch(setIsEditProfile((prev) => !prev));
  }, [dispatch]);

  const onClickState = useCallback((value) => {
    setState(value);
    setStateSearchValue(value);
  }, []);

  const onClearState = useCallback(() => {
    setState('');
    setStateSearchValue('');
  }, []);

  const onSendResetPassword = useCallback(() => {
    dispatch(sendPasswordResetEmail({ email, replacedUrl: '/broker/settings?activeTab=profile' }));
  }, [dispatch, email]);

  const onSaveUpdatedUserProfile = useCallback(() => {
    // if (phoneNumber.includes('+') && !phoneNumber.match(phoneE164Regex)) {
    //   return toast(ERROR.INVALID_PHONE_NUMBER, { type: 'warning' });
    // }

    // if (!phoneNumber.includes('+') && !formatPhoneNumber(phoneNumber).match(phoneE164Regex)) {
    //   return toast(ERROR.INVALID_PHONE_NUMBER, { type: 'warning' });
    // }

    const payload = {
      email,
      fullName,
      zipCode,
      photo_url: previewImage,
      address_street: address,
      address_city: city,
      address_state: state,
      phone_number: !phoneNumber.includes('+')
        ? formatPhoneNumber(phoneNumber)
        : phoneNumber.replace('+', ''),
    };

    dispatch(updateProfileData(payload));
  }, [dispatch, email, fullName, zipCode, previewImage, address, city, state, phoneNumber]);

  const onCancelEditProfile = useCallback(() => {
    dispatch(setIsEditProfile(false));
    setFullName(user?.displayName);
    setEmail(user?.email);
    setPreviewImage(brokerData?.photo_url || '');
    setAddress(brokerData?.address_street ?? '');
    setCity(brokerData?.address_city ?? '');
    setState(brokerData?.address_state ?? '');
  }, [dispatch, user, brokerData]);

  return (
    <div>
      <div className={classes.SettingsContentWrapper}>
        <div className={classes.SettingsContentImage} style={{ position: 'relative' }}>
          <UploadImage
            path={`users/${user?.uid}/avatar`}
            previewImage={previewImage}
            setPreviewImage={onSetPreviewImage}
            isOnlyImage={!isEditMode}
            type="profile"
            isCirclePreviewImage
          />
        </div>
        <div className={classes.TableContent}>
          <table className={classes.SettingsTable}>
            <tbody>
              <DetailsItem
                title="Full Name"
                value={
                  !isEditMode ? (
                    user?.displayName
                  ) : (
                    <Input value={fullName} onChange={onChangeFullName} />
                  )
                }
              />
              <DetailsItem
                title="Email"
                value={!isEditMode ? user?.email : <Input value={email} onChange={onChangeEmail} />}
              />
              <DetailsItem
                title="Address"
                value={
                  !isEditMode ? (
                    brokerData?.address_street
                  ) : (
                    <Input value={address} onChange={onChangeAddress} />
                  )
                }
              />
              <DetailsItem
                title="City"
                value={
                  !isEditMode ? (
                    brokerData?.address_city
                  ) : (
                    <Input value={city} onChange={onChangeCity} />
                  )
                }
              />
              <DetailsItem
                title="State"
                value={
                  !isEditMode ? (
                    brokerData?.address_state
                  ) : (
                    <SearchSelect
                      options={stateCodes}
                      value={stateSearchValue}
                      onChange={onChangeState}
                      onClear={onClearState}
                      onClick={onClickState}
                    />
                  )
                }
              />
              <DetailsItem
                title="Zip Code"
                value={
                  !isEditMode ? (
                    brokerData?.address_zip_code
                  ) : (
                    <Input value={zipCode} type="default-number" onChange={onChangeZipCode} />
                  )
                }
              />
              <DetailsItem
                title="Phone Number"
                value={
                  !isEditMode ? (
                    brokerData?.phone_number
                  ) : (
                    <InputPhone value={phoneNumber} onChange={onChangePhoneNumber} />
                  )
                }
              />
              {!isEditMode && (
                <>
                  <DetailsItem
                    title="Password"
                    value={
                      <div>
                        ********
                        <button className={classes.ChangePassword} onClick={onSendResetPassword}>
                          change
                        </button>
                      </div>
                    }
                  />
                  <DetailsItem
                    title="Subscription Status"
                    value={
                      <div className={classes.SettingsStatus}>
                        <Status status={brokerData?.status} />
                      </div>
                    }
                  />
                </>
              )}
              {isEditMode && (
                <DetailsItem title="Email Verified" value={user?.emailVerified.toString()} />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {!isEditMode && (
        <div className={classes.EditProfileButton}>
          <Button title="Edit profile" type="secondary" onClick={onClickEditProfile} />
        </div>
      )}
      {isEditMode && (
        <div className={classes.FooterButton}>
          <Button
            title="Save"
            type="primary"
            onClick={onSaveUpdatedUserProfile}
            isLoading={isLoadingUpdateProfile}
          />
          <Button title="Cancel" type="secondary" onClick={onCancelEditProfile} />
        </div>
      )}
    </div>
  );
};
