import {
  calculateConfigurationAgeRates,
  calculateConfigurationRates,
  calculateLifeConfigurationAgeRates,
} from './calculateConfigurationRates';
import { calculateDentalVisionRates } from './dentalVisionRates';
import { calculateDisabilityAgeRates, calculateDisabilityRates } from './disabilityRates';
import { calculateLifeRates, calculateUniLifeRates } from './lifeRates';
import { calculateMedicalAgeRates, calculateMedicalRates } from './medicalRates';

export const calculateExecutiveSummary = (executiveSummary, accountMembers) => {
  const { medical, dental, vision, life, disability, accident, hospital, critical_illness } =
    executiveSummary;

  let payload = {};

  if (medical?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;

    const quotes = [];

    for (const offer of medical) {
      if (offer?.rate_tier_type?.includes('tier')) {
        const calculatedRate = calculateMedicalRates(accountMembers, offer);

        totalEmployee += calculatedRate?.totalEmployee;
        totalEmployer += calculatedRate?.totalEmployer;
        totalRate += calculatedRate?.total;

        const quotesPayload = {
          id: offer.config_id,
          ee_rate: calculatedRate?.totalEmployee,
          er_rate: calculatedRate?.totalEmployer,
          total_rate: calculatedRate?.total,
          name: offer?.issuer_name,
        };

        quotes.push(quotesPayload);
      }
      if (!offer?.rate_tier_type?.includes('tier')) {
        const calculatedRate = calculateMedicalAgeRates(accountMembers, offer);

        totalEmployee += calculatedRate?.totalEmployee;
        totalEmployer += calculatedRate?.totalEmployer;
        totalRate += calculatedRate?.total;

        const quotesPayload = {
          id: offer.config_id,
          ee_rate: calculatedRate?.totalEmployee,
          er_rate: calculatedRate?.totalEmployer,
          total_rate: calculatedRate?.total,
          name: offer?.issuer_name,
        };

        quotes.push(quotesPayload);
      }
    }
    payload = {
      ...payload,
      medical: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (dental?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;

    const quotes = [];

    for (const offer of dental) {
      const calculatedRate = calculateDentalVisionRates(
        accountMembers,
        offer,
        {},
        offer?.selected_plan_name,
        'dental',
      );

      totalEmployee += calculatedRate?.totalEmployee;
      totalEmployer += calculatedRate?.totalEmployer;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.totalEmployee,
        er_rate: calculatedRate?.totalEmployer,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }
    payload = {
      ...payload,
      dental: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (vision?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;

    const quotes = [];

    for (const offer of vision) {
      const calculatedRate = calculateDentalVisionRates(
        accountMembers,
        offer,
        {},
        offer?.selected_plan_name,
        'vision',
      );

      totalEmployee += calculatedRate?.totalEmployee;
      totalEmployer += calculatedRate?.totalEmployer;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.totalEmployee,
        er_rate: calculatedRate?.totalEmployer,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }
    payload = {
      ...payload,
      vision: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (life?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;

    const quotes = [];

    for (const offer of life) {
      let calculatedRate = {};

      if (offer?.rate_tier_type === '4tier') {
        if (offer?.is_uni_tobacco) {
          calculatedRate = calculateUniLifeRates(accountMembers, offer);
        }

        if (!offer?.is_uni_tobacco) {
          calculatedRate = calculateLifeRates(accountMembers, offer);
        }
      }

      if (offer?.rate_tier_type !== '4tier') {
        calculatedRate = calculateLifeConfigurationAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.totalTobacco;
      totalRate += calculatedRate?.totalTobacco;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.totalTobacco,
        er_rate: 0,
        total_rate: calculatedRate?.totalTobacco,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      life: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (disability?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;
    const quotes = [];

    for (const offer of disability) {
      let calculatedRate = {};

      if (offer?.rate_tier_type === 'employee') {
        calculatedRate = calculateDisabilityRates(accountMembers, offer);
      }

      if (offer?.rate_tier_type !== 'employee') {
        calculatedRate = calculateDisabilityAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.total;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.total,
        er_rate: 0,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      disability: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (accident?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;
    const quotes = [];

    for (const offer of accident) {
      let calculatedRate = {};

      if (offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationRates(accountMembers, offer, {});
      }

      if (!offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.total;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.total,
        er_rate: 0,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      accident: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (accident?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;
    const quotes = [];

    for (const offer of accident) {
      let calculatedRate = {};

      if (offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationRates(accountMembers, offer, {});
      }

      if (!offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.total;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.total,
        er_rate: 0,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      accident: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (hospital?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;
    const quotes = [];

    for (const offer of hospital) {
      let calculatedRate = {};

      if (offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationRates(accountMembers, offer, {});
      }

      if (!offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.total;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.total,
        er_rate: 0,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      hospital: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  if (critical_illness?.length) {
    let totalEmployee = 0;
    let totalEmployer = 0;
    let totalRate = 0;
    const quotes = [];

    for (const offer of critical_illness) {
      let calculatedRate = {};

      if (offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationRates(accountMembers, offer, {});
      }

      if (!offer?.rate_tier_type?.includes('tier')) {
        calculatedRate = calculateConfigurationAgeRates(accountMembers, offer);
      }

      totalEmployee += calculatedRate?.total;
      totalRate += calculatedRate?.total;

      const quotesPayload = {
        id: offer.config_id,
        ee_rate: calculatedRate?.total,
        er_rate: 0,
        total_rate: calculatedRate?.total,
        name: offer?.issuer_name,
      };

      quotes.push(quotesPayload);
    }

    payload = {
      ...payload,
      critical_illness: {
        totalEmployee,
        totalEmployer,
        totalRate,
        quotes,
      },
    };
  }

  let summaryTotalEmployee = 0;
  let summaryTotalEmployer = 0;
  let summaryTotal = 0;
  for (const coverage in payload) {
    if (coverage) {
      summaryTotalEmployee += payload[coverage]?.totalEmployee;
      summaryTotalEmployer += payload[coverage]?.totalEmployer;
      summaryTotal += payload[coverage]?.totalRate;
    }
  }

  return {
    ...payload,
    total: {
      totalEmployee: summaryTotalEmployee ?? 0,
      totalEmployer: summaryTotalEmployer ?? 0,
      totalRate: summaryTotal ?? 0,
    },
  };
};
