import Base from './base';

class Chat extends Base {
  startChat = (payload) => {
    return this.apiClient.post(`chat/rooms`, payload);
  };

  addRoomMember = (room_id, payload) => {
    return this.apiClient.post(`chat/rooms/${room_id}/members`, payload);
  };

  getChatRooms = (payload) => {
    // &f[room_type]=employer
    return this.apiClient.get('chat/rooms', payload);
  };

  getChatRoomMessages = (room_id) => {
    return this.apiClient.get(`chat/rooms/${room_id}/messages`);
  };

  sendChatRoomMessage = (room_id, payload) => {
    return this.apiClient.post(`chat/rooms/${room_id}/messages`, payload);
  };

  getAllChatRooms = (payload) => {
    return this.apiClient.get('chat/rooms');
  };
}

export default Chat;
