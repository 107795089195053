import { worksheetNameRegex } from 'src/constants/regularExpression';

export const normalizeMedicalRatesExport = (ExcelJSWorkbook, groupQuotes) => {
  for (const quote of groupQuotes) {
    const quoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      `${
        quote.name.length > 25
          ? quote.name.slice(0, 25).replace(worksheetNameRegex, '')
          : quote.name.replace(worksheetNameRegex, '')
      } Rates`,
      {
        properties: { defaultColWidth: 20 },
      },
    );

    let name_row = [''];
    let age_0 = ['age_0'];
    let age_1 = ['age_1'];
    let age_2 = ['age_2'];
    let age_3 = ['age_3'];
    let age_4 = ['age_4'];
    let age_5 = ['age_5'];
    let age_6 = ['age_6'];
    let age_7 = ['age_7'];
    let age_8 = ['age_8'];
    let age_9 = ['age_9'];
    let age_10 = ['age_10'];
    let age_11 = ['age_11'];
    let age_12 = ['age_12'];
    let age_13 = ['age_13'];
    let age_14 = ['age_14'];
    let age_15 = ['age_15'];
    let age_16 = ['age_16'];
    let age_17 = ['age_17'];
    let age_18 = ['age_18'];
    let age_19 = ['age_19'];
    let age_20 = ['age_20'];
    let age_21 = ['age_21'];
    let age_22 = ['age_22'];
    let age_23 = ['age_23'];
    let age_24 = ['age_24'];
    let age_25 = ['age_25'];
    let age_26 = ['age_26'];
    let age_27 = ['age_27'];
    let age_28 = ['age_28'];
    let age_29 = ['age_29'];
    let age_30 = ['age_30'];
    let age_31 = ['age_31'];
    let age_32 = ['age_32'];
    let age_33 = ['age_33'];
    let age_34 = ['age_34'];
    let age_35 = ['age_35'];
    let age_36 = ['age_36'];
    let age_37 = ['age_37'];
    let age_38 = ['age_38'];
    let age_39 = ['age_39'];
    let age_40 = ['age_40'];
    let age_41 = ['age_41'];
    let age_42 = ['age_42'];
    let age_43 = ['age_43'];
    let age_44 = ['age_44'];
    let age_45 = ['age_45'];
    let age_46 = ['age_46'];
    let age_47 = ['age_47'];
    let age_48 = ['age_48'];
    let age_49 = ['age_49'];
    let age_50 = ['age_50'];
    let age_51 = ['age_51'];
    let age_52 = ['age_52'];
    let age_53 = ['age_53'];
    let age_54 = ['age_54'];
    let age_55 = ['age_55'];
    let age_56 = ['age_56'];
    let age_57 = ['age_57'];
    let age_58 = ['age_58'];
    let age_59 = ['age_59'];
    let age_60 = ['age_60'];
    let age_61 = ['age_61'];
    let age_62 = ['age_62'];
    let age_63 = ['age_63'];
    let age_64 = ['age_64'];
    let age_65 = ['age_65'];

    let ageRows = [
      name_row,
      age_0,
      age_1,
      age_2,
      age_3,
      age_4,
      age_5,
      age_6,
      age_7,
      age_8,
      age_9,
      age_10,
      age_11,
      age_12,
      age_13,
      age_14,
      age_15,
      age_16,
      age_17,
      age_18,
      age_19,
      age_20,
      age_21,
      age_22,
      age_23,
      age_24,
      age_25,
      age_26,
      age_27,
      age_28,
      age_29,
      age_30,
      age_31,
      age_32,
      age_33,
      age_34,
      age_35,
      age_36,
      age_37,
      age_38,
      age_39,
      age_40,
      age_41,
      age_42,
      age_43,
      age_44,
      age_45,
      age_46,
      age_47,
      age_48,
      age_49,
      age_50,
      age_51,
      age_52,
      age_53,
      age_54,
      age_55,
      age_56,
      age_57,
      age_58,
      age_59,
      age_60,
      age_61,
      age_62,
      age_63,
      age_64,
      age_65,
    ];

    for (const plan of quote?.plans) {
      name_row.push(`${quote.name.replace(worksheetNameRegex, '')} (${plan?.carrier_name})`);
      age_0.push(plan['age_0']);
      age_1.push(plan['age_1']);
      age_2.push(plan['age_2']);
      age_3.push(plan['age_3']);
      age_4.push(plan['age_4']);
      age_5.push(plan['age_5']);
      age_6.push(plan['age_6']);
      age_7.push(plan['age_7']);
      age_8.push(plan['age_8']);
      age_9.push(plan['age_9']);
      age_10.push(plan['age_10']);
      age_11.push(plan['age_11']);
      age_12.push(plan['age_12']);
      age_13.push(plan['age_13']);
      age_14.push(plan['age_14']);
      age_15.push(plan['age_15']);
      age_16.push(plan['age_16']);
      age_17.push(plan['age_17']);
      age_18.push(plan['age_18']);
      age_19.push(plan['age_19']);
      age_20.push(plan['age_20']);
      age_21.push(plan['age_21']);
      age_22.push(plan['age_22']);
      age_23.push(plan['age_23']);
      age_24.push(plan['age_24']);
      age_25.push(plan['age_25']);
      age_26.push(plan['age_26']);
      age_27.push(plan['age_27']);
      age_28.push(plan['age_28']);
      age_29.push(plan['age_29']);
      age_30.push(plan['age_30']);
      age_31.push(plan['age_31']);
      age_32.push(plan['age_32']);
      age_33.push(plan['age_33']);
      age_34.push(plan['age_34']);
      age_35.push(plan['age_35']);
      age_36.push(plan['age_36']);
      age_37.push(plan['age_37']);
      age_38.push(plan['age_38']);
      age_39.push(plan['age_39']);
      age_40.push(plan['age_40']);
      age_41.push(plan['age_41']);
      age_42.push(plan['age_42']);
      age_43.push(plan['age_43']);
      age_44.push(plan['age_44']);
      age_45.push(plan['age_45']);
      age_46.push(plan['age_46']);
      age_47.push(plan['age_47']);
      age_48.push(plan['age_48']);
      age_49.push(plan['age_49']);
      age_50.push(plan['age_50']);
      age_51.push(plan['age_51']);
      age_52.push(plan['age_52']);
      age_53.push(plan['age_53']);
      age_54.push(plan['age_54']);
      age_55.push(plan['age_55']);
      age_56.push(plan['age_56']);
      age_57.push(plan['age_57']);
      age_58.push(plan['age_58']);
      age_59.push(plan['age_59']);
      age_60.push(plan['age_60']);
      age_61.push(plan['age_61']);
      age_62.push(plan['age_62']);
      age_63.push(plan['age_63']);
      age_64.push(plan['age_64']);
      age_65.push(plan['age_65']);
    }

    for (const row of ageRows) {
      if (row[0] === 'Plan Name' || row[0] === 'Carrier') {
        quoteWorkSheet.addRow(row).font = { bold: true };
        continue;
      }
      quoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    for (let rowIndex = 1; rowIndex <= quoteWorkSheet.rowCount; rowIndex++) {
      quoteWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
    }
  }
};
