import _ from 'lodash';
import { ACCIDENT_FORM_NAMES } from 'src/constants/formNames';
import { currencyFormatter } from 'src/constants/currency';
import {
  normalizeAccidentType,
  normalizeCompensationAmount,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import {
  coverageAllAges,
  COVERAGE_ALL_AGES_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  coverageFiveYearAges,
  COVERAGE_FIVE_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  coverageTenYearAges,
  COVERAGE_TEN_YEAR_NAMES,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';
import { prepareCustomFields } from './prepareCustomFields';
import { capitalize } from './exportXlsx';

export const normalizeAccidentExport = (ExcelJSWorkbook, accidentQuotes, groupMembers, type) => {
  let accidentCounter = 1;

  for (const quote in accidentQuotes) {
    const accidentQuoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      type === 'all'
        ? `Accident RFP Quote ${accidentCounter++}`
        : `Sold Accident Quote ${accidentCounter++}`,
      {
        properties: { defaultColWidth: 40 },
      },
    );
    const preparedCustomFields = prepareCustomFields(accidentQuotes[quote]);

    const isAllAgesRate = !_.isEmpty(
      accidentQuotes[quote].find((item) => item?.rate_tier_type === 'all_ages'),
    );
    const isFiveAgesRate = !_.isEmpty(
      accidentQuotes[quote].find((item) => item?.rate_tier_type === 'banded_5'),
    );
    const isTenAgesRate = !_.isEmpty(
      accidentQuotes[quote].find((item) => item?.rate_tier_type === 'banded_10'),
    );

    let offerRow = [''];
    let carrierRow = ['Carrier'];
    let planTypeRow = ['Plan Type'];
    let hospitalAdmission = [ACCIDENT_FORM_NAMES.hospital_admission];
    let icuConfinement = [ACCIDENT_FORM_NAMES.icu_confinement];
    let dailyHospitalConfinement = [ACCIDENT_FORM_NAMES.daily_hospital_confinement];
    let confinementDaysCovered = [ACCIDENT_FORM_NAMES.confinement_days_covered];
    let dailyIcuConfinement = [ACCIDENT_FORM_NAMES.daily_icu_confinement];
    let icuDaysCovered = [ACCIDENT_FORM_NAMES.icu_days_covered];
    let accidentalDeath = [ACCIDENT_FORM_NAMES.accidental_death];
    let compMethodRow = ['Compensation Method'];
    let compAmountRow = ['Compensation Amount'];
    let currentlyOfferedRow = ['Currently Offered Benefit'];

    for (const accidentQuote of accidentQuotes[quote]) {
      if (accidentQuote.issuer_name === 'broker') {
        offerRow.push('Current Offer');
      } else {
        offerRow.push('Carrier Offer');
      }
    }

    let quoteRows = [
      offerRow,
      carrierRow,
      planTypeRow,
      hospitalAdmission,
      icuConfinement,
      dailyHospitalConfinement,
      confinementDaysCovered,
      dailyIcuConfinement,
      icuDaysCovered,
      accidentalDeath,
      compMethodRow,
      compAmountRow,
      currentlyOfferedRow,
      [],
    ];

    let rateTierTypeRow = ['Rate Tier Type'];

    for (const accidentQuote of accidentQuotes[quote]) {
      carrierRow.push(capitalize(accidentQuote.issuer_name));
      planTypeRow.push(normalizeAccidentType(accidentQuote));
      hospitalAdmission.push(currencyFormatter(accidentQuote.hospital_admission));
      icuConfinement.push(currencyFormatter(accidentQuote.icu_confinement));
      dailyHospitalConfinement.push(currencyFormatter(accidentQuote.daily_hospital_confinement));
      confinementDaysCovered.push(`${accidentQuote.confinement_days_covered} Days`);
      dailyIcuConfinement.push(currencyFormatter(accidentQuote.daily_icu_confinement));
      icuDaysCovered.push(`${accidentQuote.icu_days_covered} Days`);
      accidentalDeath.push(currencyFormatter(accidentQuote.accidental_death));
      compMethodRow.push(capitalize(accidentQuote.comp_method));
      compAmountRow.push(normalizeCompensationAmount(accidentQuote));
      currentlyOfferedRow.push(accidentQuote.is_current_rate ? 'Yes' : 'No');
      rateTierTypeRow.push(normalizeRateTierType(accidentQuote.rate_tier_type));
    }

    for (const row of quoteRows) {
      if (row.find((item) => item === 'Carrier')) {
        accidentQuoteWorkSheet.addRow(row).font = { bold: true };
      } else {
        accidentQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
      }
    }

    const customFields = ['Custom Fields'];
    accidentQuoteWorkSheet.addRow(customFields).getCell(1).font = { bold: true };

    let customRows = [];
    for (const accidentQuote of preparedCustomFields) {
      for (let [index, field] of accidentQuote?.custom_fields.entries()) {
        if (!customRows[index]) {
          customRows[index] = [field.title, field.value];
        } else {
          customRows[index].push(field.value || '');
        }
      }
    }

    customRows.push([]);

    for (const row of customRows) {
      accidentQuoteWorkSheet.addRow(row).font = { bold: true };
    }

    accidentQuoteWorkSheet.addRow(rateTierTypeRow).getCell(1).font = { bold: true };

    let rateRows = [];

    for (let i = 1; i <= 81; i++) {
      rateRows.push(['']);
    }

    for (const accidentQuote of accidentQuotes[quote]) {
      let ageBandedRates = {};

      if (
        accidentQuote.rate_tier_type === 'all_ages' ||
        accidentQuote.rate_tier_type === 'banded_5' ||
        accidentQuote.rate_tier_type === 'banded_10'
      ) {
        for (const item of Object.keys(accidentQuote)) {
          if (item.includes('age')) {
            ageBandedRates = {
              ...ageBandedRates,
              [item]: accidentQuote[item],
            };
          }
        }
      }

      let allAgesRatesPayload = coverageAllAges(ageBandedRates);
      let fiveYearsRatesPayload = coverageFiveYearAges(ageBandedRates);
      let tenYearRatesPayload = coverageTenYearAges(ageBandedRates);

      if (accidentQuote.rate_tier_type === '3tier' || accidentQuote.rate_tier_type === '4tier') {
        rateRows[0].push(`${currencyFormatter(accidentQuote.rate_ee) || '--'} (EE)`);
        if (accidentQuote.rate_tier_type === '3tier') {
          rateRows[1].push(`${currencyFormatter(accidentQuote.rate_ee_1) || '--'} (EE + 1)`);
          rateRows[2].push(`${currencyFormatter(accidentQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            // 66 - because range for all ages is from 0 to 14
            for (let i = 3; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 3; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 7; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 3; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
        if (accidentQuote.rate_tier_type === '4tier') {
          rateRows[1].push(`${currencyFormatter(accidentQuote.rate_ee_sp) || '--'} (EE + SP)`);
          rateRows[2].push(`${currencyFormatter(accidentQuote.rate_ee_ch) || '--'} (EE + CH)`);
          rateRows[3].push(`${currencyFormatter(accidentQuote.rate_fam) || '--'} (FAM)`);
          if (isAllAgesRate) {
            for (let i = 4; i <= 66; i++) {
              rateRows[i]?.push('');
            }
          } else {
            if (isFiveAgesRate && !isTenAgesRate) {
              for (let i = 4; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }

            if (!isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 7; i++) {
                rateRows[i]?.push('');
              }
            }

            if (isFiveAgesRate && isTenAgesRate) {
              for (let i = 4; i <= 13; i++) {
                rateRows[i]?.push('');
              }
            }
          }
        }
      }

      if (accidentQuote?.rate_tier_type === 'all_ages') {
        rateRows[0].push(
          `${currencyFormatter(allAgesRatesPayload.age_0_14) || '--'} (${
            COVERAGE_ALL_AGES_NAMES.age_0_14
          })`,
        );
        for (let i = 1; i < 66; i++) {
          rateRows[i].push(
            `${currencyFormatter(allAgesRatesPayload[`age_${i + 14}`]) || '--'} (${
              COVERAGE_ALL_AGES_NAMES[`age_${i + 14}`]
            })`,
          );
        }
      }

      if (accidentQuote?.rate_tier_type === 'banded_5') {
        rateRows[0].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_0_17) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_18_24) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_18_24
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_25_29) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_25_29
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_30_34) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_30_34
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_35_39) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_35_39
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_40_44) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_40_44
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_45_49) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_45_49
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_50_54) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_50_54
          })`,
        );
        rateRows[8].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_55_59) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_55_59
          })`,
        );
        rateRows[9].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_60_64) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_60_64
          })`,
        );
        rateRows[10].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_65_69) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_65_69
          })`,
        );
        rateRows[11].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_70_74) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_70_74
          })`,
        );
        rateRows[12].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_75_79) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_75_79
          })`,
        );
        rateRows[13].push(
          `${currencyFormatter(fiveYearsRatesPayload.age_80) || '--'} (${
            COVERAGE_FIVE_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 14; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        }
      }

      if (accidentQuote?.rate_tier_type === 'banded_10') {
        rateRows[0].push(
          `${currencyFormatter(tenYearRatesPayload.age_0_17) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_0_17
          })`,
        );
        rateRows[1].push(
          `${currencyFormatter(tenYearRatesPayload.age_18_29) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_18_29
          })`,
        );
        rateRows[2].push(
          `${currencyFormatter(tenYearRatesPayload.age_30_39) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_30_39
          })`,
        );
        rateRows[3].push(
          `${currencyFormatter(tenYearRatesPayload.age_40_49) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_40_49
          })`,
        );
        rateRows[4].push(
          `${currencyFormatter(tenYearRatesPayload.age_50_59) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_50_59
          })`,
        );
        rateRows[5].push(
          `${currencyFormatter(tenYearRatesPayload.age_60_69) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_60_69
          })`,
        );
        rateRows[6].push(
          `${currencyFormatter(tenYearRatesPayload.age_70_79) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_70_79
          })`,
        );
        rateRows[7].push(
          `${currencyFormatter(tenYearRatesPayload.age_80) || '--'} (${
            COVERAGE_TEN_YEAR_NAMES.age_80
          })`,
        );
        if (isAllAgesRate) {
          for (let i = 8; i <= 66; i++) {
            rateRows[i]?.push('');
          }
        } else {
          if (isFiveAgesRate) {
            for (let i = 8; i <= 13; i++) {
              rateRows[i]?.push('');
            }
          }
        }
      }
    }

    for (const row of [...rateRows.filter((e) => e.length !== 1), []]) {
      accidentQuoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    const totalRows = ['Total'];

    for (const accidentQuote of accidentQuotes[quote]) {
      totalRows.push(currencyFormatter(accidentQuote?.calculation?.total));
    }

    const totalRowStyle = accidentQuoteWorkSheet.addRow(totalRows);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    for (let rowIndex = 2; rowIndex <= accidentQuoteWorkSheet.rowCount; rowIndex++) {
      accidentQuoteWorkSheet.getRow(rowIndex).alignment = { horizontal: 'left', wrapText: true };
    }
  }
};
