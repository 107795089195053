import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Input, Loader, LoaderWrapper, PageHeader } from 'src/components';

import { ERROR } from 'src/constants/errorNames';
import { emailRegex } from 'src/constants/regularExpression';

import {
  agencyMembersMetaSelector,
  agencyUserRoleSelector,
  brokerAgencySelector,
  getAgencyMembers,
  getPendingInvites,
  inviteTeamMember,
  isLoadingAgencyMembersSelector,
  isLoadingInviteTeamMemberSelector,
  isLoadingPendingInvitesSelector,
  pendingInvitesMetaSelector,
  pendingInvitesSelector,
} from 'src/store/agency';
import { AgencyPendingInvites } from '../agencyPendingInvites/AgencyPendingInvites';
import { AgencyTeam } from '../agencyTeam/AgencyTeam';
import { PermissionsTable } from '../permissionsTable/PermissionsTable';

import classes from './agencyUsers.module.scss';

export const AgencyUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const teamId = params?.teamId;

  const agencyData = useSelector(brokerAgencySelector);
  const agencyUserRole = useSelector(agencyUserRoleSelector);
  const agencyMembersMeta = useSelector(agencyMembersMetaSelector);
  const pendingInvites = useSelector(pendingInvitesSelector);
  const pendingIvitesMeta = useSelector(pendingInvitesMetaSelector);
  const isLoadingAgencyMembers = useSelector(isLoadingAgencyMembersSelector);
  const isLoadingPendingInvites = useSelector(isLoadingPendingInvitesSelector);
  const isLoadingInvite = useSelector(isLoadingInviteTeamMemberSelector);

  const maximumSeats = agencyData?.str_quantity;
  const totalSeats = agencyMembersMeta?.total;

  const [userEmail, setUserEmail] = useState('');
  const [addUserErrorMessage, setAddUserErrorMessage] = useState('');
  const [remainingSeats, setRemainingSeats] = useState();

  useEffect(() => {
    dispatch(getPendingInvites(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    dispatch(getAgencyMembers(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (!_.isEmpty(agencyData) && agencyData.status === 'inactive') {
      navigate(`/teams/${agencyData.id}/settings?activeTab=billing`);
    }
  }, [agencyData, navigate]);

  useEffect(() => {
    if (!_.isEmpty(agencyData)) {
      const availableSeats = maximumSeats - totalSeats - pendingIvitesMeta?.total;

      if (availableSeats < 0) {
        return setRemainingSeats(0);
      }

      setRemainingSeats(availableSeats);
    }
  }, [agencyData, maximumSeats, pendingIvitesMeta?.total, totalSeats]);

  useEffect(() => {
    if (!_.isEmpty(agencyData)) {
      if (agencyData.status !== 'active') {
        return setAddUserErrorMessage(ERROR.INACTIVE_AGENCY_SUBSCRIPTION);
      }

      if (remainingSeats <= 0) {
        return setAddUserErrorMessage(ERROR.SEATS_ERROR);
      }

      if (remainingSeats > 0) {
        return setAddUserErrorMessage('');
      }
    }
  }, [agencyData, remainingSeats]);

  const onClickAddUser = useCallback(async () => {
    if (!userEmail.match(emailRegex)) {
      return toast('Please, enter a valid email!', { type: 'warning' });
    }

    if (userEmail) {
      const payload = { id: teamId, payload: { target_email: userEmail } };

      await dispatch(inviteTeamMember(payload));

      setUserEmail('');
    }
  }, [dispatch, userEmail, teamId]);

  const onChangeUserEmail = useCallback((event) => {
    setUserEmail(event.target.value.trim());
  }, []);

  const onClearInput = useCallback(() => {
    setUserEmail('');
  }, []);

  const addUserOnKeyDown = useCallback(
    async (event) => {
      if (event.keyCode === 13 && userEmail) {
        if (!userEmail.match(emailRegex)) {
          return toast('Please, enter a valid email!', { type: 'warning' });
        }
        const payload = { id: teamId, payload: { target_email: userEmail } };

        await dispatch(inviteTeamMember(payload));

        setUserEmail('');
      }
    },
    [dispatch, userEmail, teamId],
  );

  return (
    <div className={classes.AgencyUsers}>
      <PageHeader title="Agency Users">
        {agencyUserRole === 'owner' && (
          <div className={classes.AddAgencyUsers}>
            <div className={classes.AddAgencyUsersWrapper}>
              <Input
                placeholder={`johnDoe@mail.com (${remainingSeats} seats remaining)`}
                value={userEmail}
                onChange={onChangeUserEmail}
                onKeyDown={addUserOnKeyDown}
              />
              {userEmail && (
                <i
                  onClick={onClearInput}
                  className={`fas fa-plus ${classes.AddAgencyUsersClear}`}
                />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-plus"
              title="Add User"
              onClick={onClickAddUser}
              isLoading={isLoadingInvite}
              tooltip={addUserErrorMessage}
              isDisabled={addUserErrorMessage}
            />
          </div>
        )}
      </PageHeader>
      <div className={classes.AgencyUsersHeader}></div>
      {pendingInvites?.length > 0 && (
        <>
          <PageHeader title="Pending Invites" />
          <div className={classes.AgencyUsersContent}>
            <div className={classes.AgencyUsersContentUsersWrapper}>
              {isLoadingPendingInvites ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <AgencyPendingInvites />
              )}
            </div>
          </div>
        </>
      )}
      <PageHeader title="Team Members" />
      <div className={classes.AgencyUsersContent}>
        <div className={classes.AgencyUsersContentUsersWrapper}>
          {isLoadingAgencyMembers ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <AgencyTeam />
          )}
        </div>
        {agencyUserRole === 'owner' && (
          <div className={classes.AgencyUsersContentPermissionsWrapper}>
            <PermissionsTable />
          </div>
        )}
      </div>
    </div>
  );
};
