import React from 'react';
import PropTypes from 'prop-types';

import { validateError } from 'src/constants/validateErrors';

import classes from './errorBox.module.scss';

export const ErrorBox = (props) => {
  const { message } = props;

  return <div className={classes.ErrorBox}>{validateError(message)}</div>;
};

ErrorBox.propTypes = {
  message: PropTypes.string,
};
