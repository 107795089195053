import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.agency;

export const brokerAgencySelector = createSelector(getState, (state) => state.brokerAgency);
export const isLoadingBrokerAgencySelector = createSelector(
  getState,
  (state) => state.isLoadingBrokerAgency,
);

export const isLoadingCreateAgencySelector = createSelector(
  getState,
  (state) => state.isLoadingCreateAgency,
);

export const brokerAgenciesSelector = createSelector(getState, (state) => state.brokerAgencies);
export const isLoadingBrokerAgenciesSelector = createSelector(
  getState,
  (state) => state.isLoadingBrokerAgencies,
);

export const agencyTeamIdSelector = createSelector(getState, (state) => state.agencyTeamId);

export const isLoadingInviteTeamMemberSelector = createSelector(
  getState,
  (state) => state.isLoadingInviteTeamMember,
);

export const agencyMembersSelector = createSelector(getState, (state) => state.agencyMembers);
export const agencyMembersMetaSelector = createSelector(
  getState,
  (state) => state.agencyMembersMeta,
);
export const isLoadingAgencyMembersSelector = createSelector(
  getState,
  (state) => state.isLoadingAgencyMembers,
);

export const isLoadingUpdateUserRoleSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateUserRole,
);

export const pendingInvitesSelector = createSelector(getState, (state) => state.pendingInvites);
export const pendingInvitesMetaSelector = createSelector(
  getState,
  (state) => state.pendingInvitesMeta,
);
export const isLoadingPendingInvitesSelector = createSelector(
  getState,
  (state) => state.isLoadingPendingInvites,
);

export const agencyUserRoleSelector = createSelector(getState, (state) => state.agencyUserRole);

export const invitedAgencySelector = createSelector(getState, (state) => state.invitedAgency);
export const isInviteModalSelector = createSelector(getState, (state) => state.isInviteModal);

export const isLoadingDeleteMemberSelector = createSelector(
  getState,
  (state) => state.isLoadingDeleteMember,
);

export const agencyAccountsSelector = createSelector(getState, (state) => state.agencyAccounts);
export const agencyAccountsMetaSelector = createSelector(
  getState,
  (state) => state.agencyAccountsMeta,
);
export const isLoadingAgencyAccountsSelector = createSelector(
  getState,
  (state) => state.isLoadingAgencyAccounts,
);

export const agencyAccountProposalsSelector = createSelector(
  getState,
  (state) => state.agencyAccountProposals,
);
export const agencyProposalsMetaSelector = createSelector(
  getState,
  (state) => state.agencyProposalsMeta,
);
export const isLoadingAgencyAccountProposalsSelector = createSelector(
  getState,
  (state) => state.isLoadingAgencyAccountProposals,
);

export const agencyProposalsSelector = createSelector(getState, (state) => state.agencyProposals);
export const isLoadingAgencyProposalsSelector = createSelector(
  getState,
  (state) => state.isLoadingAgencyProposals,
);

export const isLoadingCheckoutSessionSelector = createSelector(
  getState,
  (state) => state.isLoadingCheckoutSession,
);
export const isLoadingCustomerPortalSessionSelector = createSelector(
  getState,
  (state) => state.isLoadingCustomerPortalSession,
);

export const agencyReportingSelector = createSelector(getState, (state) => state.agencyReporting);
export const isLoadingAgencyReportingSelector = createSelector(
  getState,
  (state) => state.isLoadingAgencyReporting,
);

export const isLoadingUpdateSubscriptionQuantitySelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateSubscriptionQuantity,
);

export const isLoadingResendInviteSelector = createSelector(
  getState,
  (state) => state.isLoadingResendInvite,
);
