import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Document, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import _ from 'lodash';

import {
  fullProposalDataSelector,
  getAccountData,
  getBrokerAccountData,
  getBrokerData,
  getRfpQuotes,
  isLoadingFullProposalDataSelector,
} from 'src/store/broker';
import { getPetQuotes, petInsuranceDataSelector } from 'src/store/pet';
import { getAccountProposal, getAccountProposalSelector } from 'src/store/proposals';

import { Button, Loader, LoaderWrapper } from 'src/components';

import { renderMedicalRfpsPage } from './render/renderMedicalRfpsPage';
import { renderDentalPage } from './render/renderDentalPage';
import { renderVisionPage } from './render/renderVisionPage';
import { renderLifePage } from './render/renderLifePage';
import { renderDisabilityPage } from './render/renderDisabilityPage';
import { renderAccidentPage } from './render/renderAccidentPage';
import { renderHospitalPage } from './render/renderHospitalPage';
import { renderCriticalPage } from './render/renderCriticalPage';
import { renderMedicalQuotesPage } from './render/renderMedicalQuotesPage';
import { renderAccountPage } from './render/renderAccountPage';
import { renderPetPage } from './render/renderPetPage';

const PDFDocument = (proposalData, accountData, accountProposalData, brokerData) => {
  const medicalData = proposalData.medical;
  const dentalData = proposalData.dental;
  const visionData = proposalData.vision;
  const lifeData = proposalData.life;
  const disabilityData = proposalData.disability;
  const accidentData = proposalData.accident;
  const hospitalData = proposalData.hospital;
  const criticalData = proposalData.critical;

  const groupQuotes = !_.isEmpty(accountProposalData?.groups)
    ? accountProposalData?.groups[0].quotes
    : [];
  const groupMembers = !_.isEmpty(accountProposalData?.groups)
    ? accountProposalData?.groups[0].members
    : [];
  const petQuotes = !_.isEmpty(proposalData?.pet) ? proposalData?.pet?.groups[0]?.quotes : [];

  return (
    <Document>
      {renderAccountPage(proposalData, accountData, accountProposalData, brokerData)}
      {renderMedicalQuotesPage(groupQuotes, groupMembers)}
      {renderMedicalRfpsPage(medicalData)}
      {renderDentalPage(dentalData)}
      {renderVisionPage(visionData)}
      {renderLifePage(lifeData)}
      {renderDisabilityPage(disabilityData)}
      {renderAccidentPage(accidentData)}
      {renderHospitalPage(hospitalData)}
      {renderCriticalPage(criticalData)}
      {renderPetPage(petQuotes)}
    </Document>
  );
};

export const ExportFullProposalPDF = () => {
  const dispatch = useDispatch();
  const { id: accountId, proposalId } = useParams();

  const proposalData = useSelector(fullProposalDataSelector);
  const isLoadingProposalData = useSelector(isLoadingFullProposalDataSelector);
  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const brokerData = useSelector(getBrokerData);
  const petData = useSelector(petInsuranceDataSelector);

  useEffect(() => {
    const payload = {
      accountId,
      proposalId,
      type: 'all',
    };

    const asyncDispatch = async () => {
      await dispatch(getBrokerAccountData({ paramsId: accountId }));
      await dispatch(getAccountProposal(payload));
      await dispatch(getRfpQuotes(payload));
      await dispatch(getPetQuotes({ accountId, proposalId }));
    };

    asyncDispatch();
  }, [dispatch, accountId, proposalId]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {!_.isEmpty(proposalData) && !_.isEmpty(accountData) && !_.isEmpty(accountProposalData) && (
        <div style={{ width: '80%' }}>
          <PDFViewer style={{ width: '100%', height: '99%' }}>
            {PDFDocument(
              { ...proposalData, pet: petData },
              accountData,
              accountProposalData,
              brokerData,
            )}
          </PDFViewer>
        </div>
      )}
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingProposalData ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          !_.isEmpty(proposalData) && (
            <PDFDownloadLink
              document={PDFDocument(proposalData, accountData, accountProposalData)}
              fileName={`${accountData?.company_name}-${accountProposalData?.name}-Proposal.pdf`}
            >
              <Button type="primaryPurple" title="Download Document" />
            </PDFDownloadLink>
          )
        )}
      </div>
    </div>
  );
};
