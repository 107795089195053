import moment from 'moment';
import { normalizeRateValue } from '../lifeRates';

export const selectedPlanHousehold3Tier = (householdFamilies, selectedOffer, memberPlanName) => {
  let payload = {};

  const selectedPlanName = selectedOffer?.selected_plan_name;

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    if (employee && spouse && child) {
      if (selectedPlanName) {
        let householdPayload = [];
        for (const member of household) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberPayload = {
                ...member,
                type: `FAM (${selectedPlanName})`,
              };

              const ee_contribution = selectedOffer?.contribution_ee;
              const ee_rate = selectedOffer?.rate_ee;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  ee_rate - (ee_rate / 100) * ee_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue((ee_rate / 100) * ee_contribution);
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (ee_contribution > ee_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(ee_rate);
                } else {
                  const employeePay = ee_rate - ee_contribution;
                  const employerPay = ee_rate - employeePay;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(ee_rate);

              householdPayload?.push(memberPayload);
            } else {
              let memberPayload = {
                ...member,
              };

              const dependetsCount = household?.filter(
                (item) => item?.relationship !== 'employee',
              )?.length;

              const dep_contribution = selectedOffer?.contribution_dep;

              const dep_rate = (selectedOffer?.rate_fam - selectedOffer?.rate_ee) / dependetsCount;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  dep_rate - (dep_rate / 100) * dep_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue(
                  (dep_rate / 100) * dep_contribution,
                );
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (dep_contribution > dep_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(dep_rate);
                } else {
                  // prettier-ignore
                  const dep_dollar_rate = ((selectedOffer?.rate_fam - selectedOffer?.rate_ee) - dep_contribution) / dependetsCount;

                  const employeePay = dep_dollar_rate;
                  const employerPay = dep_contribution / dependetsCount;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(dep_rate);

              householdPayload?.push(memberPayload);
            }
          }

          if (member[memberPlanName] !== selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_total = 0;

              householdPayload?.push(memberPayload);
            } else {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_totla = 0;

              householdPayload?.push(memberPayload);
            }
          }
        }
        payload = {
          ...payload,
          [family_id]: householdPayload,
        };
      }
    } else {
      if (employee && !spouse && !child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member[memberPlanName] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                  type: `EE (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer?.rate_ee;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                householdPayload?.push(member);
              }
            }

            if (member[memberPlanName] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
      if (employee && (spouse || child)) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member[memberPlanName] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                  type: `EE + 1 (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer?.rate_ee;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                const dependetsCount = household?.filter(
                  (item) => item?.relationship !== 'employee',
                )?.length;

                const dep_contribution = selectedOffer?.contribution_dep;

                const dep_rate =
                  (selectedOffer?.rate_ee_1 - selectedOffer?.rate_ee) / dependetsCount;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    dep_rate - (dep_rate / 100) * dep_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (dep_rate / 100) * dep_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (dep_contribution > dep_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(dep_rate);
                  } else {
                    // prettier-ignore
                    const dep_dollar_rate = ((selectedOffer?.rate_ee_1 - selectedOffer?.rate_ee) - dep_contribution) / dependetsCount;

                    const employeePay = dep_dollar_rate;
                    const employerPay = dep_contribution / dependetsCount;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(dep_rate);

                householdPayload?.push(memberPayload);
              }
            }

            if (member[memberPlanName] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
    }
  }

  return payload;
};

export const selectedPlanHousehold4Tier = (householdFamilies, selectedOffer, memberPlanName) => {
  let payload = {};

  const selectedPlanName = selectedOffer?.selected_plan_name;

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    if (employee && spouse && child) {
      if (selectedPlanName) {
        let householdPayload = [];
        for (const member of household) {
          if (member[memberPlanName] === selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberPayload = {
                ...member,
                type: `FAM (${selectedPlanName})`,
              };

              const ee_contribution = selectedOffer?.contribution_ee;
              const ee_rate = selectedOffer?.rate_ee;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  ee_rate - (ee_rate / 100) * ee_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue((ee_rate / 100) * ee_contribution);
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (ee_contribution > ee_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(ee_rate);
                } else {
                  const employeePay = ee_rate - ee_contribution;
                  const employerPay = ee_rate - employeePay;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(ee_rate);

              householdPayload?.push(memberPayload);
            } else {
              let memberPayload = {
                ...member,
              };

              const dependetsCount = household?.filter(
                (item) => item?.relationship !== 'employee',
              )?.length;

              const dep_contribution = selectedOffer?.contribution_dep;

              const dep_rate = (selectedOffer?.rate_fam - selectedOffer?.rate_ee) / dependetsCount;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  dep_rate - (dep_rate / 100) * dep_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue(
                  (dep_rate / 100) * dep_contribution,
                );
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (dep_contribution > dep_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(dep_rate);
                } else {
                  const employeePay = dep_rate - dep_contribution;
                  const employerPay = dep_rate - employeePay;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(dep_rate);

              householdPayload?.push(memberPayload);
            }
          }

          if (member[memberPlanName] !== selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_total = 0;

              householdPayload?.push(memberPayload);
            } else {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_total = 0;

              householdPayload?.push(memberPayload);
            }
          }
        }
        payload = {
          ...payload,
          [family_id]: householdPayload,
        };
      }
    } else {
      if (employee && !spouse && !child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member[memberPlanName] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                  type: `EE (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer?.rate_ee;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                householdPayload?.push(member);
              }
            }

            if (member[memberPlanName] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
      if (employee && spouse && !child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member[memberPlanName] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                  type: `EE + SP (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer?.rate_ee;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                const dependetsCount = household?.filter(
                  (item) => item?.relationship !== 'employee',
                )?.length;

                const dep_contribution = selectedOffer?.contribution_dep;

                const dep_rate =
                  (selectedOffer?.rate_ee_sp - selectedOffer?.rate_ee) / dependetsCount;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    dep_rate - (dep_rate / 100) * dep_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (dep_rate / 100) * dep_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (dep_contribution > dep_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(dep_rate);
                  } else {
                    // prettier-ignore
                    const dep_dollar_rate = ((selectedOffer?.rate_ee_sp - selectedOffer?.rate_ee) - dep_contribution) / dependetsCount;

                    const employeePay = dep_dollar_rate;
                    const employerPay = dep_contribution / dependetsCount;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(dep_rate);

                householdPayload?.push(memberPayload);
              }
            }

            if (member[memberPlanName] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }

      if (employee && !spouse && child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member[memberPlanName] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                  type: `EE + CH (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer?.rate_ee;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                const dependetsCount = household?.filter(
                  (item) => item?.relationship !== 'employee',
                )?.length;

                const dep_contribution = selectedOffer?.contribution_dep;

                const dep_rate =
                  (selectedOffer?.rate_ee_ch - selectedOffer?.rate_ee) / dependetsCount;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    dep_rate - (dep_rate / 100) * dep_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (dep_rate / 100) * dep_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (dep_contribution > dep_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(dep_rate);
                  } else {
                    // prettier-ignore
                    const dep_dollar_rate = ((selectedOffer?.rate_ee_ch - selectedOffer?.rate_ee) - dep_contribution) / dependetsCount;

                    const employeePay = dep_dollar_rate;
                    const employerPay = dep_contribution / dependetsCount;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(dep_rate);

                householdPayload?.push(memberPayload);
              }
            }

            if (member[memberPlanName] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
    }
  }

  return payload;
};

export const selectedPlanMedicalAgeRates = (householdFamilies, selectedOffer) => {
  let payload = {};

  const selectedPlanName = selectedOffer?.selected_plan_name;

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    if (employee && spouse && child) {
      if (selectedPlanName) {
        let householdPayload = [];
        for (const member of household) {
          if (member['current_medical_plan_name'] === selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberAge = moment().diff(moment(member.date_of_birth), 'years');
              const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

              if (memberAge > 65) memberAge = 65;

              let memberPayload = {
                ...member,
                type: `${originalMemberAge} Y/O (${selectedPlanName})`,
              };

              const ee_contribution = selectedOffer?.contribution_ee;
              const ee_rate = selectedOffer[`age_${memberAge}`] ?? 0;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  ee_rate - (ee_rate / 100) * ee_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue((ee_rate / 100) * ee_contribution);
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (ee_contribution > ee_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(ee_rate);
                } else {
                  const employeePay = ee_rate - ee_contribution;
                  const employerPay = ee_rate - employeePay;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(ee_rate);

              householdPayload?.push(memberPayload);
            } else {
              let memberAge = moment().diff(moment(member.date_of_birth), 'years');
              const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

              if (memberAge > 65) memberAge = 65;

              let memberPayload = {
                ...member,
                type: `${originalMemberAge} Y/O (${selectedPlanName})`,
              };

              const dep_contribution = selectedOffer?.contribution_dep;

              const dep_rate = selectedOffer[`age_${memberAge}`] ?? 0;

              if (selectedOffer?.contribution_type === 'percent') {
                memberPayload.rate_employee = normalizeRateValue(
                  dep_rate - (dep_rate / 100) * dep_contribution,
                );
                memberPayload.rate_employer = normalizeRateValue(
                  (dep_rate / 100) * dep_contribution,
                );
              }

              if (selectedOffer?.contribution_type === 'dollar') {
                if (dep_contribution > dep_rate) {
                  memberPayload.rate_employee = 0;
                  memberPayload.rate_employer = normalizeRateValue(dep_rate);
                } else {
                  const employeePay = dep_rate - dep_contribution;
                  const employerPay = dep_rate - employeePay;

                  memberPayload.rate_employee = normalizeRateValue(employeePay);
                  memberPayload.rate_employer = normalizeRateValue(employerPay);
                }
              }

              memberPayload.rate_total = normalizeRateValue(dep_rate);

              householdPayload?.push(memberPayload);
            }
          }

          if (member['current_medical_plan_name'] !== selectedPlanName) {
            if (member?.relationship === 'employee') {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_total = 0;

              householdPayload?.push(memberPayload);
            } else {
              let memberPayload = {
                ...member,
              };

              memberPayload.rate_employee = 0;
              memberPayload.rate_employer = 0;
              memberPayload.rate_total = 0;

              householdPayload?.push(memberPayload);
            }
          }
        }
        payload = {
          ...payload,
          [family_id]: householdPayload,
        };
      }
    } else {
      if (employee && !spouse && !child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member['current_medical_plan_name'] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberAge = moment().diff(moment(member.date_of_birth), 'years');
                const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

                if (memberAge > 65) memberAge = 65;

                let memberPayload = {
                  ...member,
                  type: `${originalMemberAge} Y/O (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer[`age_${memberAge}`] ?? 0;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                householdPayload?.push(member);
              }
            }
            if (member['current_medical_plan_name'] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };
                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
      if (employee && spouse && !child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member['current_medical_plan_name'] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberAge = moment().diff(moment(member.date_of_birth), 'years');
                const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

                if (memberAge > 65) memberAge = 65;

                let memberPayload = {
                  ...member,
                  type: `${originalMemberAge} Y/O (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer[`age_${memberAge}`] ?? 0;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                let memberAge = moment().diff(moment(member.date_of_birth), 'years');
                const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

                if (memberAge > 65) memberAge = 65;

                let memberPayload = {
                  ...member,
                  type: `${originalMemberAge} Y/O (${selectedPlanName})`,
                };

                const dep_contribution = selectedOffer?.contribution_dep;
                const dep_rate = selectedOffer[`age_${memberAge}`] ?? 0;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    dep_rate - (dep_rate / 100) * dep_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (dep_rate / 100) * dep_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (dep_contribution > dep_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(dep_rate);
                  } else {
                    const employeePay = dep_rate - dep_contribution;
                    const employerPay = dep_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(dep_rate);

                householdPayload?.push(memberPayload);
              }
            }
            if (member['current_medical_plan_name'] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
      if (employee && !spouse && child) {
        if (selectedPlanName) {
          let householdPayload = [];
          for (const member of household) {
            if (member['current_medical_plan_name'] === selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberAge = moment().diff(moment(member.date_of_birth), 'years');
                const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

                if (memberAge > 65) memberAge = 65;

                let memberPayload = {
                  ...member,
                  type: `${originalMemberAge} Y/O (${selectedPlanName})`,
                };

                const ee_contribution = selectedOffer?.contribution_ee;
                const ee_rate = selectedOffer[`age_${memberAge}`] ?? 0;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    ee_rate - (ee_rate / 100) * ee_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (ee_rate / 100) * ee_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (ee_contribution > ee_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(ee_rate);
                  } else {
                    const employeePay = ee_rate - ee_contribution;
                    const employerPay = ee_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(ee_rate);

                householdPayload?.push(memberPayload);
              } else {
                let memberAge = moment().diff(moment(member.date_of_birth), 'years');
                const originalMemberAge = moment().diff(moment(member.date_of_birth), 'years');

                if (memberAge > 65) memberAge = 65;

                let memberPayload = {
                  ...member,
                  type: `${originalMemberAge} Y/O (${selectedPlanName})`,
                };

                const dep_contribution = selectedOffer?.contribution_dep;
                const dep_rate = selectedOffer[`age_${memberAge}`] ?? 0;

                if (selectedOffer?.contribution_type === 'percent') {
                  memberPayload.rate_employee = normalizeRateValue(
                    dep_rate - (dep_rate / 100) * dep_contribution,
                  );
                  memberPayload.rate_employer = normalizeRateValue(
                    (dep_rate / 100) * dep_contribution,
                  );
                }

                if (selectedOffer?.contribution_type === 'dollar') {
                  if (dep_contribution > dep_rate) {
                    memberPayload.rate_employee = 0;
                    memberPayload.rate_employer = normalizeRateValue(dep_rate);
                  } else {
                    const employeePay = dep_rate - dep_contribution;
                    const employerPay = dep_rate - employeePay;

                    memberPayload.rate_employee = normalizeRateValue(employeePay);
                    memberPayload.rate_employer = normalizeRateValue(employerPay);
                  }
                }

                memberPayload.rate_total = normalizeRateValue(dep_rate);

                householdPayload?.push(memberPayload);
              }
            }
            if (member['current_medical_plan_name'] !== selectedPlanName) {
              if (member?.relationship === 'employee') {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              } else {
                let memberPayload = {
                  ...member,
                };

                memberPayload.rate_employee = 0;
                memberPayload.rate_employer = 0;
                memberPayload.rate_total = 0;

                householdPayload?.push(memberPayload);
              }
            }
          }
          payload = {
            ...payload,
            [family_id]: householdPayload,
          };
        }
      }
    }
  }

  return payload;
};
