export const offJobOptions = [
  { title: '24 hour', value: '24_hour' },
  { title: 'Off the Job', value: 'off_job' },
];

export const hospitalOptions = [
  { title: '$500', value: 500 },
  { title: '$1000', value: 1000 },
  { title: '$1500', value: 1500 },
  { title: '$2000', value: 2000 },
];

export const icuOptions = [
  { title: '$1000', value: 1000 },
  { title: '$2000', value: 2000 },
  { title: '$3000', value: 3000 },
  { title: '$4000', value: 4000 },
];

export const dailyOptions = [
  { title: '$100', value: 100 },
  { title: '$150', value: 150 },
  { title: '$200', value: 200 },
  { title: '$250', value: 250 },
  { title: '$300', value: 300 },
  { title: '$400', value: 400 },
];

export const confinementOptions = [{ title: '365', value: 365 }];

export const dailyIcuOptions = [
  { title: '$200', value: 200 },
  { title: '$300', value: 300 },
  { title: '$400', value: 400 },
];

export const icuDaysOptions = [{ title: '30', value: 30 }];

export const accidentalOptions = [
  { title: '$10000', value: 10000 },
  { title: '$20000', value: 20000 },
  { title: '$30000', value: 30000 },
  { title: '$40000', value: 40000 },
];
