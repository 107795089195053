import { isEmpty } from 'lodash';
import moment from 'moment';

import { sumPremium } from 'src/store/proposals';

import { currencyFormatter, titleCase } from 'src/constants/currency';
import { inNetworkRegex, worksheetNameRegex } from 'src/constants/regularExpression';
import { normalizeMedicalQuoteType } from 'src/constants/medicalQuotes';

const getNormalizedMemberAge = (dateOfBirth, quote) => {
  let memberAge = moment(quote?.effective_date).diff(moment(dateOfBirth), 'years');
  if (memberAge > 65) memberAge = 65;

  return memberAge;
};

export const calculateEmployeeTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (member) => member.current_medical_plan_name === rate.current_plan_name,
  );

  let sumPerPlan = 0;

  for (const member of planMembers) {
    let employerCost = 0;
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_ee, 10) / 100
        : quote?.contribution_ee;
    const depContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_dep, 10) / 100
        : quote?.contribution_dep;

    if (true) {
      if (member.relationship === 'employee') {
        if (quote?.contribution_type === 'percent') {
          employerCost += memberRate * eeContributionPercent;
        }
        if (quote?.contribution_type === 'dollar') {
          if (eeContributionPercent < memberRate) {
            employerCost += eeContributionPercent;
          }

          if (eeContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      } else {
        if (quote?.contribution_type === 'percent') {
          employerCost += memberRate * depContributionPercent;
        }
        if (quote?.contribution_type === 'dollar') {
          if (depContributionPercent < memberRate) {
            employerCost += depContributionPercent;
          }

          if (depContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      }
    }

    employerCost =
      quote?.contribution_type === 'percent'
        ? Math.round((employerCost + Number.EPSILON) * 100) / 100
        : employerCost;

    sumPerPlan += rate[`age_${memberAge}`] - employerCost;
  }

  return currencyFormatter(sumPerPlan);
};

export const calculateMemberRate = (
  member,
  rate,
  quote,
  selectedBasePlanQuote,
  withContribution = true,
) => {
  let employerCost = 0;
  let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

  const memberRate = !isEmpty(selectedBasePlanQuote)
    ? selectedBasePlanQuote[`age_${memberAge}`]
    : rate[`age_${memberAge}`];

  const eeContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_ee, 10) / 100
      : quote?.contribution_ee;
  const depContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_dep, 10) / 100
      : quote?.contribution_dep;

  if (withContribution) {
    if (member.relationship === 'employee') {
      if (quote?.contribution_type === 'percent') {
        employerCost += memberRate * eeContributionPercent;
      }
      if (quote?.contribution_type === 'dollar') {
        if (eeContributionPercent < memberRate) {
          employerCost += eeContributionPercent;
        }

        if (eeContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    } else {
      if (quote?.contribution_type === 'percent') {
        employerCost += memberRate * depContributionPercent;
      }
      if (quote?.contribution_type === 'dollar') {
        if (depContributionPercent < memberRate) {
          employerCost += depContributionPercent;
        }

        if (depContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    }
  }

  employerCost =
    quote?.contribution_type === 'percent'
      ? Math.round((employerCost + Number.EPSILON) * 100) / 100
      : employerCost;

  if (quote?.quote_type === 'advanced') {
    return rate[`age_${memberAge}`] - employerCost;
  }

  if (quote?.quote_type !== 'advanced') {
    return memberRate - employerCost;
  }
};

export const calculateEmployerTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (member) => member.current_medical_plan_name === rate.current_plan_name,
  );

  let totalCost = 0;

  for (const member of planMembers) {
    let employerCost = 0; // need to reset this each iteration
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_ee, 10) / 100
        : quote?.contribution_ee;
    const depContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_dep, 10) / 100
        : quote?.contribution_dep;

    if (member.relationship === 'employee') {
      if (quote?.contribution_type === 'percent') {
        employerCost +=
          Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
      }

      if (quote?.contribution_type === 'dollar') {
        if (eeContributionPercent < memberRate) {
          employerCost += eeContributionPercent;
        }

        if (eeContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    } else {
      if (quote?.contribution_type === 'percent') {
        employerCost +=
          Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
      }
      if (quote?.contribution_type === 'dollar') {
        if (depContributionPercent < memberRate) {
          employerCost += depContributionPercent;
        }

        if (depContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    }

    if (quote?.quote_type === 'advanced') {
      if (rate[`age_${memberAge}`] > employerCost) {
        totalCost += employerCost;
      } else {
        totalCost += rate[`age_${memberAge}`];
      }
    }

    if (quote?.quote_type !== 'advanced') {
      totalCost += employerCost;
    }
  }

  return currencyFormatter(totalCost);
};

export const calculateEmployerCost = (member, rate, quote, selectedBasePlanQuote) => {
  let employerCost = 0;
  let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

  const memberRate = !isEmpty(selectedBasePlanQuote)
    ? selectedBasePlanQuote[`age_${memberAge}`]
    : rate[`age_${memberAge}`];

  const eeContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_ee, 10) / 100
      : quote?.contribution_ee;
  const depContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_dep, 10) / 100
      : quote?.contribution_dep;

  if (member.relationship === 'employee') {
    if (quote?.contribution_type === 'percent') {
      employerCost += Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
    }
    if (quote?.contribution_type === 'dollar') {
      if (eeContributionPercent < memberRate) {
        employerCost += eeContributionPercent;
      }

      if (eeContributionPercent > memberRate) {
        employerCost += memberRate;
      }
    }
  } else {
    if (quote?.contribution_type === 'percent') {
      employerCost +=
        Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
    }
    if (quote?.contribution_type === 'dollar') {
      if (depContributionPercent < memberRate) {
        employerCost += depContributionPercent;
      }

      if (depContributionPercent > memberRate) {
        employerCost += memberRate;
      }
    }
  }

  if (quote?.quote_type === 'advanced') {
    const valueToDecrease = rate[`age_${memberAge}`] - employerCost;
    if (valueToDecrease < 0) {
      return employerCost + valueToDecrease;
    } else {
      return employerCost;
    }
  }

  if (quote?.quote_type !== 'advanced') {
    return employerCost;
  }
};

export const calculatePlanTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (item) => item.current_medical_plan_name === rate.current_plan_name,
  );

  let employerCost = 0;
  let sumCost = 0;

  for (const member of planMembers) {
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent = Number.parseInt(quote?.contribution_ee, 10) / 100;
    const depContributionPercent = Number.parseInt(quote?.contribution_dep, 10) / 100;

    if (true) {
      if (member.relationship === 'employee') {
        employerCost += memberRate * eeContributionPercent;
      } else {
        employerCost += memberRate * depContributionPercent;
      }
    }

    employerCost = Math.round((employerCost + Number.EPSILON) * 100) / 100;
    sumCost += rate[`age_${memberAge}`];
  }
  return currencyFormatter(sumCost);
};

export const normalizeMedicalQuoteExport = (ExcelJSWorkbook, groupQuotes, groupMembers) => {
  for (const quote of groupQuotes) {
    const quoteWorkSheet = ExcelJSWorkbook.addWorksheet(
      quote.name.length > 25
        ? quote.name.slice(0, 25).replace(worksheetNameRegex, '')
        : quote.name.replace(worksheetNameRegex, ''),
      {
        properties: { defaultColWidth: 40 },
      },
    );

    const selectedBasePlanQuote = quote?.plans.find((item) => item.id === quote?.base_plan_id);

    const updatedQuotes = sumPremium(
      quote?.plans,
      groupMembers,
      quote,
      null,
      selectedBasePlanQuote,
    );

    quoteWorkSheet.addRow(['Quote Name', quote?.name]).font = { bold: true };
    quoteWorkSheet.addRow(['Type', normalizeMedicalQuoteType(quote?.quote_type)]).getCell(1).font =
      { bold: true };
    quoteWorkSheet.addRow(['Effective Date', quote?.effective_date]).getCell(1).font = {
      bold: true,
    };
    if (quote?.contribution_type === 'percent') {
      quoteWorkSheet
        .addRow(['Contribution', `${quote?.contribution_ee}% EE / ${quote?.contribution_dep}% DEP`])
        .getCell(1).font = { bold: true };
    } else {
      quoteWorkSheet
        .addRow([
          'Contribution',
          `${currencyFormatter(quote?.contribution_ee)} EE / ${currencyFormatter(
            quote?.contribution_dep,
          )} DEP`,
        ])
        .getCell(1).font = { bold: true };
    }
    quoteWorkSheet.addRow(['Contribution Source', quote?.contribution_source]).getCell(1).font = {
      bold: true,
    };

    quoteWorkSheet.addRow([]);

    let carrierRow = ['Carrier'];
    let planNameRow = ['Plan Name'];
    let infertilityRow = ['INF'];
    let planTypeRow = ['Plan Type'];
    let networkSizeRow = ['Network Size'];
    let tierRow = ['Tier'];
    let primaryCareRow = ['Primary Care Physician Copay'];
    let specialistCopayRow = ['Specialist Copay'];
    let individualDeductibleRow = ['Individual Deductible'];
    let familyDeductibleRow = ['Family Deductible'];
    let inpatientFacilityRow = ['Inpatient Facility'];
    let coinsuranceRow = ['Coinsurance'];
    let outOfPocketRow = ['Max Out-of-Pocket'];
    let emergencyRoomRow = ['Emergency Room'];
    let urgentCareRow = ['Urgent Care'];
    let benefitsSummaryRow = ['Benefits Summary'];

    let proposalRows = [
      carrierRow,
      planNameRow,
      infertilityRow,
      planTypeRow,
      networkSizeRow,
      tierRow,
      primaryCareRow,
      specialistCopayRow,
      individualDeductibleRow,
      familyDeductibleRow,
      inpatientFacilityRow,
      coinsuranceRow,
      outOfPocketRow,
      emergencyRoomRow,
      urgentCareRow,
      benefitsSummaryRow,
    ];

    for (const plan of quote?.plans) {
      carrierRow.push(plan.carrier_name);
      planNameRow.push(plan.display_name);
      infertilityRow.push(plan.infertility_treatment_rider ? 'Yes' : 'No');
      planTypeRow.push(plan.plan_type);
      networkSizeRow.push(plan.network_size.toLocaleString());
      tierRow.push(titleCase(plan.level));
      primaryCareRow.push(plan.primary_care_physician.match(inNetworkRegex)[1]);
      specialistCopayRow.push(plan.specialist);
      individualDeductibleRow.push(plan.individual_medical_deductible.match(inNetworkRegex)[1]);
      familyDeductibleRow.push(plan.family_medical_deductible.match(inNetworkRegex)[1]);
      inpatientFacilityRow.push(plan.inpatient_facility.match(inNetworkRegex)[1]);
      coinsuranceRow.push(plan.plan_coinsurance);
      outOfPocketRow.push(plan.individual_medical_moop.match(inNetworkRegex)[1]);
      emergencyRoomRow.push(plan.emergency_room);
      urgentCareRow.push(plan.urgent_care);
      benefitsSummaryRow.push(plan.benefits_summary_url);
    }

    for (const row of proposalRows) {
      if (row[0] === 'Plan Name' || row[0] === 'Carrier') {
        quoteWorkSheet.addRow(row).font = { bold: true };
        continue;
      }
      quoteWorkSheet.addRow(row).getCell(1).font = { bold: true };
    }

    const selectedPayer = quote.contribution_source;

    let employeeRow = ['Employer'];

    for (const item of updatedQuotes) {
      if (selectedPayer === 'employee') {
        if (quote.quote_type === 'advanced') {
          employeeRow.push(
            calculateEmployeeTotal(item, quote, groupMembers, selectedBasePlanQuote),
          );
        }

        if (quote.quote_type === 'standard') {
          employeeRow.push(currencyFormatter(item?.employee_cost));
        }
      }

      if (selectedPayer === 'employer') {
        if (quote.quote_type === 'advanced') {
          employeeRow.push(
            calculateEmployerTotal(item, quote, groupMembers, selectedBasePlanQuote),
          );
        }

        if (quote.quote_type === 'standard') {
          employeeRow.push(currencyFormatter(item?.employer_cost));
        }
      }
    }

    const employeeRowStyle = quoteWorkSheet.addRow(employeeRow);

    employeeRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employeeRowStyle.font = { bold: true };

    const employeeMembers = [];

    for (const member of groupMembers) {
      let updatedMember = [`${member.first_name} ${member.last_name}`];

      for (const item of updatedQuotes) {
        if (selectedPayer === 'employee') {
          if (quote?.quote_type === 'advanced') {
            if (member.current_medical_plan_name === item.current_plan_name) {
              updatedMember.push(
                currencyFormatter(calculateMemberRate(member, item, quote, selectedBasePlanQuote)),
              );
            } else {
              updatedMember.push('--');
            }
          }
          if (quote?.quote_type === 'standard') {
            updatedMember.push(currencyFormatter(calculateMemberRate(member, item, quote)));
          }
        }

        if (selectedPayer === 'employer') {
          if (quote?.quote_type === 'advanced') {
            if (member.current_medical_plan_name === item.current_plan_name) {
              updatedMember.push(
                currencyFormatter(
                  calculateEmployerCost(member, item, quote, selectedBasePlanQuote),
                ),
              );
            } else {
              updatedMember.push('--');
            }
          }
          if (quote?.quote_type === 'standard') {
            updatedMember.push(currencyFormatter(calculateEmployerCost(member, item, quote)));
          }
        }
      }

      employeeMembers.push(updatedMember);
    }

    for (const member of employeeMembers) {
      quoteWorkSheet.addRow(member).getCell(1).font = { bold: true };
    }

    let employerRow = ['Employee'];

    for (const item of updatedQuotes) {
      if (selectedPayer === 'employee') {
        if (quote.quote_type === 'advanced') {
          employerRow.push(
            calculateEmployerTotal(item, quote, groupMembers, selectedBasePlanQuote),
          );
        }

        if (quote.quote_type === 'standard') {
          employerRow.push(currencyFormatter(item?.employer_cost));
        }
      }

      if (selectedPayer === 'employer') {
        if (quote.quote_type === 'advanced') {
          employerRow.push(
            calculateEmployeeTotal(item, quote, groupMembers, selectedBasePlanQuote),
          );
        }

        if (quote.quote_type === 'standard') {
          employerRow.push(currencyFormatter(item?.employee_cost));
        }
      }
    }

    const employerRowStyle = quoteWorkSheet.addRow(employerRow);
    employerRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    employerRowStyle.font = { bold: true };

    const employerMembers = [];

    for (const member of groupMembers) {
      let updatedMember = [`${member.first_name} ${member.last_name}`];

      for (const item of updatedQuotes) {
        if (selectedPayer === 'employee') {
          if (quote?.quote_type === 'advanced') {
            if (member.current_medical_plan_name === item.current_plan_name) {
              updatedMember.push(
                currencyFormatter(
                  calculateEmployerCost(member, item, quote, selectedBasePlanQuote),
                ),
              );
            } else {
              updatedMember.push('--');
            }
          }
          if (quote?.quote_type === 'standard') {
            updatedMember.push(currencyFormatter(calculateEmployerCost(member, item, quote)));
          }
        }

        if (selectedPayer === 'employer') {
          if (quote?.quote_type === 'advanced') {
            if (member.current_medical_plan_name === item.current_plan_name) {
              updatedMember.push(
                currencyFormatter(calculateMemberRate(member, item, quote, selectedBasePlanQuote)),
              );
            } else {
              updatedMember.push('--');
            }
          }
          if (quote?.quote_type === 'standard') {
            updatedMember.push(currencyFormatter(calculateMemberRate(member, item, quote)));
          }
        }
      }

      employerMembers.push(updatedMember);
    }

    for (const member of employerMembers) {
      quoteWorkSheet.addRow(member).getCell(1).font = { bold: true };
    }

    let totalRow = ['Total'];

    for (const item of updatedQuotes) {
      if (quote.quote_type === 'advanced') {
        totalRow.push(calculatePlanTotal(item, quote, groupMembers, selectedBasePlanQuote));
      }

      if (quote.quote_type === 'standard') {
        totalRow.push(currencyFormatter(item?.total_premium));
      }
    }

    const totalRowStyle = quoteWorkSheet.addRow(totalRow);
    totalRowStyle.eachCell(
      (cell) =>
        (cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffff00',
          },
        }),
    );
    totalRowStyle.font = { bold: true };

    const totalMembers = [];

    for (const member of groupMembers) {
      let updatedMember = [`${member.first_name} ${member.last_name}`];

      for (const item of updatedQuotes) {
        if (quote?.quote_type === 'advanced') {
          if (member.current_medical_plan_name === item.current_plan_name) {
            updatedMember.push(
              currencyFormatter(
                calculateMemberRate(member, item, quote, selectedBasePlanQuote, false),
              ),
            );
          } else {
            updatedMember.push('--');
          }
        }
        if (quote?.quote_type === 'standard') {
          updatedMember.push(
            currencyFormatter(calculateMemberRate(member, item, quote, null, false)),
          );
        }
      }

      totalMembers.push(updatedMember);
    }

    for (const member of totalMembers) {
      quoteWorkSheet.addRow(member).getCell(1).font = { bold: true };
    }

    for (let columnIndex = 2; columnIndex <= quoteWorkSheet.columnCount; columnIndex++) {
      quoteWorkSheet.getColumn(columnIndex).numFmt =
        '[$$-1] #,##########0.00;[$$-1][Red]-#,##########0.00';
      quoteWorkSheet.getColumn(columnIndex).width = 40;
    }

    for (let rowIndex = 1; rowIndex <= quoteWorkSheet.rowCount; rowIndex++) {
      quoteWorkSheet.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
    }
  }
};
