import xlsx from 'xlsx';

export const normalizeFundingType = (type) => {
  if (type === 'level_funded') {
    return 'Level Funded';
  }

  if (type === 'fully_insured') {
    return 'Fully Insured';
  }
};

export const onUploadAgeRates = (e, setAgebandedRates) => {
  e.preventDefault();

  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet, { raw: true });

      let payload = {};

      for (const item of json) {
        payload = {
          ...payload,
          [item.rate]: item.value,
        };
      }

      setAgebandedRates((prev) => ({ ...prev, ...payload }));
    };

    reader.onerror = () => {
      e.target.files = null;
    };

    reader.readAsArrayBuffer(e.target.files[0]);
  }
};

export const onUploadLifeAgeRates = (e, setAgebandedRates, isUniTobacco) => {
  e.preventDefault();

  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet, { raw: true });

      let payload = {};

      if (isUniTobacco) {
        for (const item of json) {
          payload = {
            ...payload,
            [`${item.rate}_uni_tobacco`]: item.uni_tobacco,
            [`${item.rate}_tobacco`]: null,
            [`${item.rate}_non_tobacco`]: null,
          };
        }
      }

      if (!isUniTobacco) {
        for (const item of json) {
          payload = {
            ...payload,
            [`${item.rate}_uni_tobacco`]: null,
            [`${item.rate}_tobacco`]: item.tobacco,
            [`${item.rate}_non_tobacco`]: item.non_tobacco,
          };
        }
      }

      setAgebandedRates((prev) => ({ ...prev, ...payload }));
    };

    reader.onerror = () => {
      e.target.files = null;
    };

    reader.readAsArrayBuffer(e.target.files[0]);
  }
};
