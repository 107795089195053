import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.app;

export const getContinueRouteSelector = createSelector(getState, (state) => state.continueRoute);

export const getRegistrationEmailSelector = createSelector(
  getState,
  (state) => state.registrationEmail,
);

export const getUserClaimsSelector = createSelector(getState, (state) => state.userClaims);
export const getIsLoadingUserClaims = createSelector(
  getState,
  (state) => state.isLoadingUserClaims,
);

export const getIsLoadingLoginSelector = createSelector(getState, (state) => state.isLoadingLogin);
export const getLoginError = createSelector(getState, (state) => state.loginError);
export const getPasswordResetError = createSelector(getState, (state) => state.passwordResetError);
export const getIsLoadingRegister = createSelector(getState, (state) => state.isLoadingRegister);
export const getRegisterError = createSelector(getState, (state) => state.registerError);

export const getIsLoadingSendPasswordReset = createSelector(
  getState,
  (state) => state.isLoadingSendPasswordReset,
);

export const getIsLoadingOobCode = createSelector(getState, (state) => state.isLoadingOobCode);
export const getIsLoadingPasswordReset = createSelector(
  getState,
  (state) => state.isLoadingPasswordReset,
);

export const getIsLoadingVerificationLink = createSelector(
  getState,
  (state) => state.isLoadingVerificationLink,
);

export const getIsLoadingVerifyEmail = createSelector(
  getState,
  (state) => state.isLoadingVerifyEmail,
);

export const getIsVerifiedEmail = createSelector(getState, (state) => state.isVerifiedEmail);

export const getEmailDomainsSelector = createSelector(getState, (state) => state.emailDomains);

export const getUserTypeSelector = createSelector(getState, (state) => state.userType);

export const getCityStateData = createSelector(getState, (state) => state.cityStateData);

export const getIsLoadingZipCode = createSelector(getState, (state) => state.isLoadingZipCode);

export const issuerEmailDomainSelector = createSelector(
  getState,
  (state) => state.issuerEmailDomain,
);

export const isSubscriptionSelector = createSelector(getState, (state) => state.isSubscription);
