export const currencyFormatter = function (val, digits) {
  if (isNaN(val)) {
    val = 0;
  }

  if (val < 0) {
    val = 0;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits ? digits : 2,
    maximumFractionDigits: digits ? digits : 2,
  }).format(val);
};

export const percentFormatter = function (val) {
  if (isNaN(val)) {
    val = 0;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  }).format(val);
};

export const titleCase = function (str) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : '';
};
