import { Page, Text, View } from '@react-pdf/renderer';

import {
  normalizeCompensationAmount,
  normalizeDisabilityPlanType,
  normalizeDisabilityRateBasis,
  normalizeLtdBenefitPeriod,
} from 'src/constants/requestProposal';
import { currencyFormatter } from 'src/constants/currency';

import { renderTableRow, renderTableValue } from './renderRow';

import { styles } from '../styles';
import {
  COVERAGE_ALL_AGES_NAMES,
  coverageAllAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  COVERAGE_FIVE_YEAR_NAMES,
  coverageFiveYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  COVERAGE_TEN_YEAR_NAMES,
  coverageTenYearAges,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

export const renderDisabilityPage = (data, fullname, groupMembers) => {
  let counter = 0;

  return Object.keys(data).map((id) => {
    counter++;

    let updatedQuotes = !fullname ? data[id] : [];

    const member = groupMembers?.find((item) => item.relationship === 'employee');

    if (fullname) {
      for (const item of data[id]) {
        let payload = { ...item };

        let total = 0;

        for (const groupMember of item.calculation[member.family_id]) {
          if (groupMember.rate) {
            total += groupMember.rate;
          }
        }

        payload.calculation = {
          ...item.calculation,
          total,
        };

        updatedQuotes.push(payload);
      }
    }

    const updatedData = updatedQuotes.reduce((acc, value, index, array) => {
      if (index % 2 === 0) {
        acc.push(array.slice(index, index + 2));
      }
      return acc;
    }, []);

    const renderAgeRates = (rates, NAMES) => {
      return Object.keys(rates).map((item) => (
        <Text>
          ${rates[item] || '0'} ({NAMES[item]})
        </Text>
      ));
    };

    const brokerFields = updatedData?.map((section) =>
      section.find((item) => item.issuer_name === 'broker'),
    )[0];

    return updatedData?.map((section, index) => {
      const plan_type = section[0]?.plan_type;

      const rate_tier_type_ee = section?.find(
        (item) => item.rate_tier_type === 'employee',
      )?.rate_tier_type;

      const rate_tier_type_all = section?.find(
        (item) => item.rate_tier_type === 'all_ages',
      )?.rate_tier_type;

      const rate_tier_type_five = section?.find(
        (item) => item.rate_tier_type === 'banded_5',
      )?.rate_tier_type;

      const rate_tier_type_ten = section?.find(
        (item) => item.rate_tier_type === 'banded_10',
      )?.rate_tier_type;

      const tier_ee = rate_tier_type_ee === 'employee';

      const all_ages = rate_tier_type_all === 'all_ages';
      const banded_5 = rate_tier_type_five === 'banded_5';
      const banded_10 = rate_tier_type_ten === 'banded_10';

      const longest_custom_fields = section?.reduce((prev, current) => {
        return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
      });

      return (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.title}>
            Disability RFP Quotes {counter} {fullname ? `(${fullname})` : ''}
          </Text>
          <View style={styles.table}>
            {renderTableRow('Carrier', section, 'issuer_name')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Plan Type</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeDisabilityPlanType(item.plan_type),
                );
              })}
            </View>
            {renderTableRow('Company Contribution', section, 'contribution_type')}
            {renderTableRow('% of Income Requested', section, 'income_pct', null, '%')}
            {plan_type === 'std' && (
              <>
                {renderTableRow('STD Weekly Max', section, 'income_pct', null, '$')}
                {renderTableRow(
                  'STD Elimination Period (Accident/Sickness)',
                  section,
                  'std_elimination_period',
                )}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>STD Benefit Period</Text>
                  </View>
                  {section?.map((item, index) => {
                    return renderTableValue(
                      item,
                      index,
                      section,
                      `${item.std_benefit_period} days`,
                    );
                  })}
                </View>
              </>
            )}
            {plan_type === 'ltd' && (
              <>
                {renderTableRow('LTD Monthly Max', section, 'ltd_monthly_max', null, '$')}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>
                      LTD Elimination Period
                    </Text>
                  </View>
                  {section?.map((item, index) => {
                    return renderTableValue(
                      item,
                      index,
                      section,
                      `${item.ltd_elimination_period} days`,
                    );
                  })}
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>LTD Benefit Period</Text>
                  </View>
                  {section?.map((item, index) => {
                    return renderTableValue(
                      item,
                      index,
                      section,
                      normalizeLtdBenefitPeriod(item.ltd_benefit_period),
                    );
                  })}
                </View>
              </>
            )}
            {renderTableRow('Compensation Method', section, 'comp_method')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Compensation Amount</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, normalizeCompensationAmount(item));
              })}
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Currently Offered Benefit</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(item, index, section, item.is_current_rate ? 'Yes' : 'No');
              })}
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Custom Fields</Text>
              </View>
            </View>
            {longest_custom_fields?.custom_fields?.map((item, index) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>
                    {brokerFields.custom_fields[index]?.title ?? ''}
                  </Text>
                </View>
                {section?.map((item) => (
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableTitle]}>
                      {item.custom_fields[index]?.value ?? ''}
                    </Text>
                  </View>
                ))}
              </View>
            ))}

            {renderTableRow('$ Per Unit', section, 'rate_per_unit', null, '$')}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Rate Basis</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  normalizeDisabilityRateBasis(item.rate_basis),
                );
              })}
            </View>
            {renderTableRow('Rate Tier Type', section, 'rate_tier_type')}
            {(all_ages || banded_5 || banded_10 || tier_ee) && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.tableTitle]}>Rates</Text>
                </View>
                {section?.map((item, index) => {
                  const allAgesRates = coverageAllAges(item);
                  const fiveYearAgesRates = coverageFiveYearAges(item);
                  const tenYearAgesRates = coverageTenYearAges(item);

                  return (
                    <View style={styles.tableCol}>
                      {item.rate_tier_type === 'employee' && <Text>${item.rate_ee}(Employee)</Text>}
                      {item.rate_tier_type === 'all_ages' &&
                        renderAgeRates(allAgesRates, COVERAGE_ALL_AGES_NAMES)}
                      {item.rate_tier_type === 'banded_5' &&
                        renderAgeRates(fiveYearAgesRates, COVERAGE_FIVE_YEAR_NAMES)}
                      {item.rate_tier_type === 'banded_10' &&
                        renderAgeRates(tenYearAgesRates, COVERAGE_TEN_YEAR_NAMES)}
                    </View>
                  );
                })}
              </View>
            )}

            {fullname &&
              groupMembers?.map((groupMember) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text
                        style={[styles.tableCell, styles.tableTitle]}
                      >{`${groupMember.first_name} ${groupMember.last_name}`}</Text>
                    </View>
                    {section?.map((item) => {
                      const currentRate = item.calculation[groupMember.family_id].find(
                        (calcMember) => calcMember.id === groupMember.id,
                      );

                      return (
                        <View style={styles.tableCol} key={item.id}>
                          <Text>{currentRate.rate ? `$${currentRate.rate}` : '--'}</Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.tableTitle]}>Total</Text>
              </View>
              {section?.map((item, index) => {
                return renderTableValue(
                  item,
                  index,
                  section,
                  currencyFormatter(item.calculation.total),
                );
              })}
            </View>
          </View>
        </Page>
      );
    });
  });
};
