// :
export const validateError = (error) => {
  switch (error) {
    case 'auth/user-not-found':
      return 'Email not found';

    case 'auth/wrong-password':
      return 'Wrong password';

    case 'auth/email-already-in-use':
      return 'Email already in use';

    case 'auth/too-many-requests':
      return 'Too many attempts. Please try again later.';

    default:
      return 'Invalid error, please contact your system administrator!';
  }
};
