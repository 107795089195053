import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.issuer;

export const getIssuerDataSelector = createSelector(getState, (state) => state.issuerData);
export const getIsEditProfileSelector = createSelector(getState, (state) => state.isEditProfile);

export const getLinkedRfpSelector = createSelector(getState, (state) => state.linkedRfp);
export const getIsLoadingLinkedRfp = createSelector(getState, (state) => state.isLoadingLinkedRfp);

export const getOtherRfpSelector = createSelector(getState, (state) => state.otherRfp);
export const getIsLoadingOtherRfp = createSelector(getState, (state) => state.isLoadingOtherRfp);

export const getAllRequestedProposalsSelector = createSelector(
  getState,
  (state) => state.allRequestedProposals,
);
export const getIsLoadingAllRequestedProposalsSelector = createSelector(
  getState,
  (state) => state.isLoadingAllRequestedProposals,
);

export const getRepOtherRfpSelector = createSelector(getState, (state) => state.repOtherRfp);
export const getIsLoadingRepOtherRfpSelector = createSelector(
  getState,
  (state) => state.isLoadingRepOtherRfp,
);

export const issuerAccountMembersSelector = createSelector(
  getState,
  (state) => state.issuerAccountMembers,
);
export const getIsLoadingIssuerAccountMembers = createSelector(
  getState,
  (state) => state.isLoadingIssuerAccountMembers,
);

export const getIssuerTokenSelector = createSelector(getState, (state) => state.issuerToken);
export const getIsFetchRepDataErrorSelector = createSelector(
  getState,
  (state) => state.isFetchRepDataError,
);

export const getIssuerAccountProposalData = createSelector(
  getState,
  (state) => state.issuerAccountProposalData,
);

export const getOtherRfpMeta = createSelector(getState, (state) => state.otherRfpMeta);
export const getRepOtherRfpMeta = createSelector(getState, (state) => state.repOtherRfpMeta);

export const issuerCoveragePlansSelector = createSelector(
  getState,
  (state) => state.issuerCoveragePlans,
);
export const isLoadingIssuerCoveragePlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerCoveragePlans,
);
