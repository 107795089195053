import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import moment from 'moment';

import {
  getBrokerAccounts,
  getBrokerAccountsMetaSelector,
  getBrokerAccountsSelector,
  getIsLoadingBrokerAccounts,
  setAccountMode,
} from 'src/store/broker';
import {
  agencyAccountsMetaSelector,
  agencyAccountsSelector,
  getAgencyAccounts,
  isLoadingAgencyAccountsSelector,
} from 'src/store/agency';

import { ROUTE } from 'src/constants/routes';

import { EmptyField } from '../EmptyField/EmptyField';
import { PageHeader } from '../PageHeader/PageHeader';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';

import classes from './accountTable.module.scss';
import { Loader, LoaderWrapper } from '../Loader/Loader';

const headerRow = [
  { id: 'company_name', name: 'Account Name' },
  { id: 'contact_name', name: 'Contact Name' },
  { id: 'contact_email', name: 'Contact Email' },
  { id: 'fips_code', name: 'County' },
  { id: 'renewal_date', name: 'Renewal Date' },
  { id: 'created_timestamp', name: 'Created' },
];

export const AccountTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const teamId = params?.teamId;

  const brokerAccounts = useSelector(getBrokerAccountsSelector);
  const brokerAccountsMeta = useSelector(getBrokerAccountsMetaSelector);
  const isLoadingBrokerAccounts = useSelector(getIsLoadingBrokerAccounts);
  const agencyAccounts = useSelector(agencyAccountsSelector);
  const agencyAccountsMeta = useSelector(agencyAccountsMetaSelector);
  const isLoadingAgencyAccounts = useSelector(isLoadingAgencyAccountsSelector);

  const list = !teamId ? brokerAccounts : agencyAccounts;
  const listMeta = !teamId ? brokerAccountsMeta : agencyAccountsMeta;

  const [searchValue, setSearchValue] = useState('');
  const [sortMethod, setSortMethod] = useState('company_name');
  const [currentPage, setCurrentPage] = useState(listMeta?.page ? listMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    listMeta?.per_page ? listMeta?.per_page : 10,
  );

  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  useEffect(() => {
    if (!searchValue) {
      const payload = {
        searchValue: '',
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(getBrokerAccounts(payload));
      }

      if (teamId) {
        dispatch(getAgencyAccounts({ orgId: teamId, ...payload }));
      }
    }
  }, [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize]);

  const onChangeSearchInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClickSearchButton = useCallback(() => {
    if (searchValue) {
      const payload = {
        searchValue,
        sort: sortMethod,
        page: currentPage,
        per_page: selectedPageSize,
      };

      if (!teamId) {
        dispatch(getBrokerAccounts(payload));
      }

      if (teamId) {
        dispatch(getAgencyAccounts({ orgId: teamId, ...payload }));
      }
    }
  }, [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize]);

  const onClickAddAccount = useCallback(() => {
    dispatch(setAccountMode('CREATE'));
    navigate(!teamId ? ROUTE.BROKER_CREATE : `/teams/${teamId}/accounts/create`);
  }, [dispatch, navigate, teamId]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        const payload = {
          searchValue,
          sort: sortMethod,
          page: currentPage,
          per_page: selectedPageSize,
        };

        if (!teamId) {
          dispatch(getBrokerAccounts(payload));
        }

        if (teamId) {
          dispatch(getAgencyAccounts({ orgId: teamId, ...payload }));
        }
      }
    },
    [dispatch, teamId, searchValue, sortMethod, currentPage, selectedPageSize],
  );

  const onClearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  return (
    <div className={classes.Account}>
      <PageHeader title="Accounts">
        <div className={classes.AccountHeader}>
          <div className={classes.AccountSearch}>
            <div className={classes.AccountSearchWrapper}>
              <Input
                placeholder="Account Name"
                value={searchValue}
                onChange={onChangeSearchInput}
                onKeyDown={searchOnKeyDown}
              />
              {searchValue && (
                <i onClick={onClearInput} className={`fas fa-plus ${classes.AccountSearchClear}`} />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-search"
              onClick={onClickSearchButton}
              isLoading={isLoadingBrokerAccounts || isLoadingAgencyAccounts}
            />
          </div>
          <div className={classes.AccountCreate}>
            <Button type="primary" title="Add Account" onClick={onClickAddAccount} />
          </div>
        </div>
      </PageHeader>
      <Paper>
        {isLoadingBrokerAccounts || isLoadingAgencyAccounts ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <>
            {list?.length ? (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        {headerRow?.map((item) => (
                          <TableCell key={item.id}>
                            <TableSortLabel
                              active={selectedCell === item.id}
                              direction={cellDirection}
                              onClick={onChangeCellSort(item.id)}
                            >
                              {item.name}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Link
                              to={
                                teamId
                                  ? `/teams/${teamId}/accounts/${item.id}`
                                  : `${ROUTE.BROKER}/${item.id}`
                              }
                            >
                              {item.company_name}
                            </Link>
                          </TableCell>
                          <TableCell>{item.contact_name}</TableCell>
                          <TableCell>{item.contact_email}</TableCell>
                          <TableCell>{item.fips_code}</TableCell>
                          <TableCell>{moment(item.renewal_date).format('MM/DD/YYYY')}</TableCell>
                          <TableCell>{moment(item.created_timestamp).format('lll')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  component="div"
                  count={listMeta?.total ?? 0}
                  rowsPerPage={selectedPageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onChangePageSize}
                />
              </>
            ) : (
              <EmptyField title="There are no active accounts yet." />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
