import { createSlice } from '@reduxjs/toolkit';

import {
  createBrokerAgency,
  getBrokerAgency,
  getBrokerAgencies,
  getAgencyMembers,
  inviteTeamMember,
  acceptOrganizationInvite,
  getAgencyUserRole,
  deleteAgencyMember,
  getAgencyAccounts,
  getAgencyProposals,
  getAgencyAccountProposals,
  createAgencyCheckoutSession,
  createAgencyCustomerPortalSession,
  getAgencyReporting,
  updateUserRole,
  getPendingInvites,
  updateSubscriptionQuantity,
  resendInviteTeamMember,
} from './agency.actions';

const initialState = {
  isLoadingCreateAgency: false,
  agencyTeamId: '',

  brokerAgency: {},
  isLoadingBrokerAgency: false,

  brokerAgencies: [],
  isLoadingBrokerAgencies: false,

  isLoadingInviteTeamMember: false,

  agencyMembers: [],
  agencyMembersMeta: {},
  isLoadingAgencyMembers: false,

  pendingInvites: [],
  pendingInvitesMeta: {},
  isLoadingPendingInvites: false,

  invitedAgency: {},
  isInviteModal: false,

  agencyUserRole: '',
  isLoadingAgencyUserRole: false,

  isLoadingDeleteMember: false,

  agencyAccounts: [],
  agencyAccountsMeta: {},
  isLoadingAgencyAccounts: false,

  agencyAccountProposals: [],
  agencyProposalsMeta: {},
  isLoadingAgencyAccountProposals: false,

  agencyProposals: [],
  isLoadingAgencyProposals: false,

  isLoadingCheckoutSession: false,
  isLoadingCustomerPortalSession: false,

  agencyReporting: {},
  isLoadingAgencyReporting: false,

  isLoadingUpdateUserRole: false,

  isLoadingUpdateSubscriptionQuantity: false,

  isLoadingResendInvite: false,
};

const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    setBrokerAgency: (state, action) => {
      state.brokerAgency = action.payload;
    },
    setAgencyTeamId: (state, action) => {
      state.agencyTeamId = action.payload;
    },
    setInviteAgencyData: (state, action) => {
      state.invitedAgency = action.payload;
    },
    setIsInviteModal: (state, action) => {
      state.isInviteModal = action.payload;
    },

    setAgencyMembers: (state, action) => {
      state.agencyMembers = action.payload;
    },
    setAgencyMembersMeta: (state, action) => {
      state.agencyMembersMeta = action.payload;
    },

    setAgencyPendingInvitesMeta: (state, action) => {
      state.pendingInvitesMeta = action.payload;
    },

    setAgencyAccountsMeta: (state, action) => {
      state.agencyAccountsMeta = action.payload;
    },
    setAgencyProposalsMeta: (state, action) => {
      state.agencyProposalsMeta = action.payload;
    },

    clearAgencyState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createBrokerAgency.pending, (state) => {
      state.isLoadingCreateAgency = true;
    });
    builder.addCase(createBrokerAgency.fulfilled, (state, action) => {
      state.brokerAgency = action.payload;
      state.isLoadingCreateAgency = false;
    });
    builder.addCase(createBrokerAgency.rejected, (state) => {
      state.isLoadingCreateAgency = false;
    });

    builder.addCase(getBrokerAgency.pending, (state) => {
      state.isLoadingBrokerAgency = true;
    });
    builder.addCase(getBrokerAgency.fulfilled, (state, action) => {
      state.brokerAgency = action.payload;
      state.isLoadingBrokerAgency = false;
    });
    builder.addCase(getBrokerAgency.rejected, (state) => {
      state.isLoadingBrokerAgency = false;
    });

    builder.addCase(getBrokerAgencies.pending, (state) => {
      state.isLoadingBrokerAgencies = true;
    });
    builder.addCase(getBrokerAgencies.fulfilled, (state, action) => {
      state.brokerAgencies = action.payload;
      state.isLoadingBrokerAgencies = false;
    });
    builder.addCase(getBrokerAgencies.rejected, (state) => {
      state.isLoadingBrokerAgencies = false;
    });

    builder.addCase(inviteTeamMember.pending, (state) => {
      state.isLoadingInviteTeamMember = true;
    });
    builder.addCase(inviteTeamMember.fulfilled, (state) => {
      state.isLoadingInviteTeamMember = false;
    });
    builder.addCase(inviteTeamMember.rejected, (state) => {
      state.isLoadingInviteTeamMember = false;
    });

    builder.addCase(getAgencyMembers.pending, (state) => {
      state.isLoadingAgencyMembers = true;
    });
    builder.addCase(getAgencyMembers.fulfilled, (state, action) => {
      state.agencyMembers = action.payload;
      state.isLoadingAgencyMembers = false;
    });
    builder.addCase(getAgencyMembers.rejected, (state) => {
      state.isLoadingAgencyMembers = false;
    });

    builder.addCase(getPendingInvites.pending, (state) => {
      state.isLoadingPendingInvites = true;
    });
    builder.addCase(getPendingInvites.fulfilled, (state, action) => {
      state.pendingInvites = action.payload;
      state.isLoadingPendingInvites = false;
    });
    builder.addCase(getPendingInvites.rejected, (state) => {
      state.isLoadingPendingInvites = false;
    });

    builder.addCase(getAgencyUserRole.pending, (state) => {
      state.isLoadingAgencyUserRole = true;
    });
    builder.addCase(getAgencyUserRole.fulfilled, (state, action) => {
      state.agencyUserRole = action.payload;
      state.isLoadingAgencyUserRole = false;
    });
    builder.addCase(getAgencyUserRole.rejected, (state) => {
      state.isLoadingAgencyUserRole = false;
    });

    builder.addCase(acceptOrganizationInvite.pending, (state) => {
      state.isInviteModal = false;
    });
    builder.addCase(acceptOrganizationInvite.fulfilled, (state, action) => {
      state.invitedAgency = action.payload;
    });
    builder.addCase(acceptOrganizationInvite.rejected, (state) => {
      state.isInviteModal = false;
    });

    builder.addCase(deleteAgencyMember.pending, (state) => {
      state.isLoadingDeleteMember = true;
    });
    builder.addCase(deleteAgencyMember.fulfilled, (state) => {
      state.isLoadingDeleteMember = false;
    });
    builder.addCase(deleteAgencyMember.rejected, (state) => {
      state.isLoadingDeleteMember = false;
    });

    builder.addCase(getAgencyAccounts.pending, (state) => {
      state.isLoadingAgencyAccounts = true;
    });
    builder.addCase(getAgencyAccounts.fulfilled, (state, action) => {
      state.agencyAccounts = action.payload;
      state.isLoadingAgencyAccounts = false;
    });
    builder.addCase(getAgencyAccounts.rejected, (state) => {
      state.isLoadingAgencyAccounts = false;
    });

    builder.addCase(getAgencyAccountProposals.pending, (state) => {
      state.isLoadingAgencyAccountProposals = true;
    });
    builder.addCase(getAgencyAccountProposals.fulfilled, (state, action) => {
      state.agencyAccountProposals = action.payload;
      state.isLoadingAgencyAccountProposals = false;
    });
    builder.addCase(getAgencyAccountProposals.rejected, (state) => {
      state.isLoadingAgencyAccountProposals = false;
    });

    builder.addCase(getAgencyProposals.pending, (state) => {
      state.isLoadingAgencyProposals = true;
    });
    builder.addCase(getAgencyProposals.fulfilled, (state, action) => {
      state.agencyProposals = action.payload;
      state.isLoadingAgencyProposals = false;
    });
    builder.addCase(getAgencyProposals.rejected, (state) => {
      state.isLoadingAgencyProposals = false;
    });

    builder.addCase(createAgencyCheckoutSession.pending, (state) => {
      state.isLoadingCheckoutSession = true;
    });
    builder.addCase(createAgencyCheckoutSession.fulfilled, (state) => {
      state.isLoadingCheckoutSession = false;
    });
    builder.addCase(createAgencyCheckoutSession.rejected, (state) => {
      state.isLoadingCheckoutSession = false;
    });

    builder.addCase(createAgencyCustomerPortalSession.pending, (state) => {
      state.isLoadingCustomerPortalSession = true;
    });
    builder.addCase(createAgencyCustomerPortalSession.fulfilled, (state) => {
      state.isLoadingCustomerPortalSession = false;
    });
    builder.addCase(createAgencyCustomerPortalSession.rejected, (state) => {
      state.isLoadingCustomerPortalSession = false;
    });

    builder.addCase(getAgencyReporting.pending, (state) => {
      state.isLoadingAgencyReporting = true;
    });
    builder.addCase(getAgencyReporting.fulfilled, (state, action) => {
      state.agencyReporting = action.payload;
      state.isLoadingAgencyReporting = false;
    });
    builder.addCase(getAgencyReporting.rejected, (state) => {
      state.isLoadingAgencyReporting = false;
    });

    builder.addCase(updateUserRole.pending, (state) => {
      state.isLoadingUpdateUserRole = true;
    });
    builder.addCase(updateUserRole.fulfilled, (state) => {
      state.isLoadingUpdateUserRole = false;
    });
    builder.addCase(updateUserRole.rejected, (state) => {
      state.isLoadingUpdateUserRole = false;
    });

    builder.addCase(updateSubscriptionQuantity.pending, (state) => {
      state.isLoadingUpdateSubscriptionQuantity = true;
    });
    builder.addCase(updateSubscriptionQuantity.fulfilled, (state) => {
      state.isLoadingUpdateSubscriptionQuantity = false;
    });
    builder.addCase(updateSubscriptionQuantity.rejected, (state) => {
      state.isLoadingUpdateSubscriptionQuantity = false;
    });

    builder.addCase(resendInviteTeamMember.pending, (state) => {
      state.isLoadingResendInvite = true;
    });
    builder.addCase(resendInviteTeamMember.fulfilled, (state) => {
      state.isLoadingResendInvite = false;
    });
    builder.addCase(resendInviteTeamMember.rejected, (state) => {
      state.isLoadingResendInvite = false;
    });
  },
});

export const {
  setBrokerAgency,
  setAgencyTeamId,
  setIsInviteModal,
  setInviteAgencyData,
  setAgencyAccountsMeta,
  setAgencyProposalsMeta,
  setAgencyMembersMeta,
  setAgencyPendingInvitesMeta,
  setAgencyMembers,
  clearAgencyState,
} = agencySlice.actions;

export default agencySlice.reducer;
