import { createSlice } from '@reduxjs/toolkit';

import { getIssuerBrokers } from './browseBrokers.actions';

const initialState = {
  issuerBrokers: [],
  issuerBrokersMeta: {},
  isLoadingIssuerBrokers: false,
};

const browseBrokersSlice = createSlice({
  name: 'browseBrokers',
  initialState,
  reducers: {
    setIssuerBrokersMeta: (state, action) => {
      state.issuerBrokersMeta = action.payload;
    },

    clearBrowseBrokersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getIssuerBrokers.pending, (state) => {
      state.isLoadingIssuerBrokers = true;
    });
    builder.addCase(getIssuerBrokers.fulfilled, (state, action) => {
      state.issuerBrokers = action.payload;
      state.isLoadingIssuerBrokers = false;
    });
    builder.addCase(getIssuerBrokers.rejected, (state) => {
      state.isLoadingIssuerBrokers = false;
    });
  },
});

export const { setIssuerBrokersMeta, clearBrowseBrokersState } = browseBrokersSlice.actions;

export default browseBrokersSlice.reducer;
