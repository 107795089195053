import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import api from 'src/app/apiSingleton';

import { auth } from 'src/app/database';

import { getRequestedProposalsStatus } from '../planConfiguration';

export const fetchPetInsurancePlans = createAsyncThunk(
  'pet/fetch-pet-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const state = thunkApi.getState();
      const groupIds = state?.proposal?.accountProposal?.group_ids;

      let response;
      for (const groupId of groupIds) {
        response = await api.pet.fetchPetQuotesRates(accountId, groupId, payload);
      }

      if (!response.items.length) {
        return "There are no result's for your effective date";
      }

      return response.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const createPetQuote = createAsyncThunk('pet/create-pet-quote', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, payload } = data;

    const token = await auth.currentUser.getIdToken();
    const state = thunkApi.getState();
    const groupIds = state?.proposal?.accountProposal?.group_ids;

    api.apiClient.setToken(token);

    for (const groupId of groupIds) {
      await api.pet.createPetQuote(accountId, groupId, payload);
    }

    await thunkApi.dispatch(getPetQuotes({ accountId, proposalId }));
    // thunkApi.dispatch(setQuotesRates([]));
    await thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));

    toast('Your quote was created successfully!', { type: 'success' });
  } catch (err) {
    console.warn(err);
  }
});

export const getPetQuotes = createAsyncThunk('pet/get-pet-quotes', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, read_token } = data;

    if (!read_token) {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);
    }

    const proposalPayload = {
      accountId,
      proposalId,
    };

    const response = await api.proposal.getAccountProposal(proposalPayload, read_token);

    const groups = [];
    for (const groupId of response.group_ids) {
      const group = await api.proposal.getGroup(accountId, groupId, read_token);

      const quotes = await api.pet.getGroupPetQuotes(accountId, groupId, read_token);
      // quotes = quotes.filter((item) => item.status === 'active');

      // thunkApi.dispatch(setResponseQuotes(quotes));

      for (const quote of quotes) {
        const plans = await thunkApi.dispatch(
          getPetQuotePlans({ accountId, proposalId, groupId, quoteId: quote.id }),
        );

        quote.plans = plans.payload;
      }

      group.quotes = quotes;
      response.quotes = quotes;
      response.group = group;
      groups.push(group);

      // thunkApi.dispatch(setGroupData(group));
    }

    response.groups = groups;

    return response;
  } catch (err) {
    console.warn('[ERROR]', err);
  }
});

export const updatePetQuote = createAsyncThunk('pet/update-pet-quote', async (data, thunkApi) => {
  try {
    const { accountId, proposalId, quoteId, payload } = data;

    const token = await auth.currentUser.getIdToken();
    const state = thunkApi.getState();
    const groupIds = state?.proposal?.accountProposal?.group_ids;

    api.apiClient.setToken(token);

    for (const groupId of groupIds) {
      await api.pet.updatePetQuote(accountId, groupId, quoteId, payload);
    }

    await thunkApi.dispatch(getPetQuotes({ accountId, proposalId }));
    // thunkApi.dispatch(setQuotesRates([]));
    // thunkApi.dispatch(setSelectedEditQuote({}));

    toast('Your quote was updated successfully!', { type: 'success' });
  } catch (err) {
    console.warn(err);
  }
});

export const deletePetQuote = createAsyncThunk('pet/delete-pet-quote', async (data, thunkApi) => {
  try {
    const { accountId, groupId, quoteId, proposalId } = data;

    await api.pet.deletePetQuote(accountId, groupId, quoteId);

    await thunkApi.dispatch(getPetQuotes({ accountId, proposalId }));
    thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
  } catch (err) {
    console.warn(err);
  }
});

export const getPetQuotePlans = createAsyncThunk(
  'pet/get-pet-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, groupId, quoteId, setPlansList, read_token } = data;

      const response = await api.pet.getPetQuotePlans(accountId, groupId, quoteId, read_token);

      if (setPlansList) {
        setPlansList(response);
      }

      // await thunkApi.dispatch(getPetQuotes({ accountId, proposalId }));
      thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);
