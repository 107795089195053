import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from 'src/app/database';

import { ROUTE } from 'src/constants/routes';

import api from 'src/app/apiSingleton';
import { toast } from 'react-toastify';
import { setUserClaims, setUserType } from '../app';
import { setBrokerUsersMeta, setRepUsersMeta } from './admin.slice';
import { setBrokerData } from '../broker';
import { setRepAccountData } from '../issuer';

export const adminSignin = createAsyncThunk('admin/signin', async (data, thunkApi) => {
  try {
    const { email, password, navigation } = data;

    await auth.signInWithEmailAndPassword(email, password);

    const tokenResult = await auth.currentUser.getIdTokenResult();
    const userType = tokenResult?.claims?.user_type;
    const userPermission = tokenResult?.claims?.is_superadmin;
    const isVerifiedEmail = tokenResult?.claims?.email_verified;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    thunkApi.dispatch(setUserClaims(tokenResult?.claims));

    if (userPermission) {
      return navigation(ROUTE.ADMIN_DASHBOARD);
    }

    if (!userPermission) {
      if (!isVerifiedEmail) {
        return navigation(ROUTE.EMAIL_VERIFICATION);
      }

      if (userType === 'broker') {
        return navigation(ROUTE.BROKER);
      }

      if (userType === 'rep') {
        return navigation(ROUTE.REP_MY_RFPS);
      }
    }
  } catch (err) {
    toast(err?.error, { type: 'error' });
  }
});

export const getAdminBrokers = createAsyncThunk(
  'admin/get-admin-brokers',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      for (const item in data) {
        if (!data[item]) {
          delete data[item];
        }
      }

      const response = await api.admin.getAdminBrokers(data);

      thunkApi.dispatch(setBrokerUsersMeta(response?.meta));

      return response?.items;
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);

export const getAdminReps = createAsyncThunk('admin/get-admin-reps', async (data, thunkApi) => {
  try {
    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    for (const item in data) {
      if (!data[item]) {
        delete data[item];
      }
    }

    const response = await api.admin.getAdminReps(data);

    thunkApi.dispatch(setRepUsersMeta(response?.meta));

    return response?.items;
  } catch (err) {
    toast(err?.error, { type: 'error' });
  }
});

export const createCustomToken = createAsyncThunk(
  'admin/create-custom-token',
  async (data, thunkApi) => {
    try {
      const { user_id, navigate } = data;
      const appToken = await auth.currentUser.getIdToken();

      api.apiClient.setToken(appToken);

      const payload = {
        user_id,
      };

      const response = await api.admin.createCustomToken(payload);
      const { token } = response?.data;

      auth?.signOut();

      await thunkApi.dispatch(setBrokerData({}));
      await thunkApi.dispatch(setRepAccountData({}));
      await thunkApi.dispatch(setUserType(''));

      await auth?.signInWithCustomToken(token);
      await auth?.currentUser?.reload();

      const tokenResult = await auth.currentUser.getIdTokenResult();

      const userType = tokenResult?.claims?.user_type;
      const isVerifiedEmail = tokenResult?.claims?.email_verified;

      if (!isVerifiedEmail) {
        return navigate(ROUTE.EMAIL_VERIFICATION);
      }

      if (userType === 'rep') {
        return navigate(ROUTE.REP_MY_RFPS);
      }

      if (userType === 'broker') {
        return navigate(ROUTE.BROKER);
      }
    } catch (err) {
      toast(err?.error, { type: 'error' });
    }
  },
);
