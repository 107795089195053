// id: '89acb8c7-8d88-4aaf-954d-711c8737a980';
// issuer_id: '1b558847-9efa-440c-a447-3124d6c68613';
// updated_timestamp: '2024-05-16T03:25:01.906Z';

export const petHeaderRows = [
  { id: '', name: '' },
  { id: 'eligible_pets', name: 'Eligible Pets' },
  { id: 'employee_payment_method', name: 'Payment Method' },
  { id: 'group_rate_discount', name: 'Group Rate Discount' },
  { id: 'pet_1', name: '1x Pet' },
  { id: 'pet_2', name: '2x Pet' },
  { id: 'pet_3', name: '3x Pet' },
  { id: 'pet_4', name: '4x Pet' },
  { id: 'pet_5', name: '5x Pet' },
  { id: 'pet_n', name: 'Unlimited Pets' },
];

export const selectedPetTableRows = [
  { title: '', key: 'logo_url', type: 'image' }, // image, default, currency, boolean
  { title: 'Coverage Waiting Period', key: 'coverage_waiting_period' },
  { title: 'Dental Cleanings', key: 'dental_cleanings' },
  { title: 'Diagnostic Testing', key: 'diagnostic_testing' },
  { title: 'Eligible Pets', key: 'eligible_pets' },
  { title: 'Employee Payment Method', key: 'employee_payment_method' },
  { title: 'Group Rate Discount', key: 'group_rate_discount' },
  { title: 'Group Size Requirement', key: 'group_size_requirement' },
  { title: 'Hereditary Conditions', key: 'hereditary_conditions', type: 'boolean' },
  { title: 'Medical History Required', key: 'medical_history_required', type: 'boolean' },
  { title: 'Medications', key: 'medications' },
  { title: 'Office Visits', key: 'office_visits' },
  { title: 'Old Age', key: 'old_age' },
  { title: 'Participation Requirement', key: 'participation_requirement' },
  { title: 'Portability', key: 'portability' },
  { title: 'Pre Existing Conditions', key: 'pre_existing_conditions', type: 'boolean' },
  { title: 'Puppy Kittens Vaccines', key: 'puppy_kittens_vaccines' },
  { title: 'Radiology', key: 'radiology' },
  { title: 'Spay Neuter', key: 'spay_neuter' },
  { title: 'Surgeries Hospitalization', key: 'surgeries_hospitalization' },
  { title: 'Vaccinations', key: 'vaccinations' },
  { title: 'Value Addons', key: 'value_addons' },
  { title: 'Veterinary Access', key: 'veterinary_access' },
  { title: 'Virtual Vet Car', key: 'virtual_vet_car' },
  { title: 'Well Care Visits', key: 'well_care_visits' },
  { title: '1x Pet', key: 'pet_1', type: 'currency' },
  { title: '2x Pet', key: 'pet_2', type: 'currency' },
  { title: '3x Pet', key: 'pet_3', type: 'currency' },
  { title: '4x Pet', key: 'pet_4', type: 'currency' },
  { title: '5x Pet', key: 'pet_5', type: 'currency' },
  { title: 'Unlimited Pets', key: 'pet_n', type: 'currency' },
];
