import Base from './base';

class Pet extends Base {
  fetchPetQuotesRates = (accountId, groupId, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/groups/${groupId}/quotes/rates/pets`,
      payload,
    );
  };

  createPetQuote = (accountId, groupId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/groups/${groupId}/quotes`, payload);
  };

  getGroupPetQuotes = (accountId, groupId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/groups/${groupId}/quotes?f[coverage_type]=pet${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  updatePetQuote = (accountId, groupId, quoteId, payload) => {
    return this.apiClient.put(`accounts/${accountId}/groups/${groupId}/quotes/${quoteId}`, payload);
  };

  deletePetQuote = (accountId, groupId, quoteId) => {
    return this.apiClient.delete(`accounts/${accountId}/groups/${groupId}/quotes/${quoteId}`);
  };

  getPetQuotePlans = (accountId, groupId, quoteId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/groups/${groupId}/quotes/${quoteId}/plans${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };
}

export default Pet;
