import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { groupMembersSelector } from 'src/store/groupMembers';
import { getAccountProposalSelector, getExecutiveSummaryProposals } from 'src/store/proposals';
import { updateCoverageOffer } from 'src/store/coverageBuilder';

import {
  renderCoverageAllAges,
  renderCoverageFiveYearAges,
  renderCoverageTenYearAges,
} from '../../../components/renderCoverageRates/renderCoverageRates';
import { renderTierRates } from '../../medical/components/SelectedMedicalOffersTable/renderMedicalRates';
import { coverageAllAges } from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import { coverageFiveYearAges } from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import { coverageTenYearAges } from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

import { DROP_ZONE_NAMES } from 'src/constants/dropZoneNames';
import { normalizeCompensationAmount, normalizeRateTierType } from 'src/constants/requestProposal';
import { COVERAGE_NAMES } from '../cb_constants';
import {
  renderAHCTable,
  renderDisabilityTable,
  renderLifeTable,
  renderMDVTable,
} from '../cb_render';

import { Button, Status } from 'src/components';
import {
  LIFE_NON_TOBACCO_ALL_AGES_NAMES,
  LIFE_UNI_ALL_AGES_NAMES,
  lifeNonTobaccoAllAges,
  lifeTobaccoAllAges,
  lifeUniAllAges,
} from 'src/features/life/lifeAgeBandedRates/lifeAllAges';
import {
  LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
  LIFE_UNI_FIVE_YEAR_NAMES,
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeFiveYearAges';
import {
  LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
  LIFE_UNI_TEN_YEAR_NAMES,
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
} from 'src/features/life/lifeAgeBandedRates/lifeTenYearAges';
import {
  renderTierTobaccoRates,
  renderTierUniRates,
  renderTobaccoRates,
  renderUniTobaccoRates,
} from 'src/components/renderLifeRates/renderLifeRates';
import { DropZone } from '../../../components/DropZone/DropZone';

import classes from 'src/sass/styles/OfferTable/offerTable.module.scss';

export const OfferDetailsTable = (props) => {
  const { build, offers, type = 'details', coverageType } = props;

  const { offer_details, form_options, coverage } = build;

  const { contribution } = form_options;

  const dispatch = useDispatch();

  const accountMembers = useSelector(groupMembersSelector);
  const accountProposalData = useSelector(getAccountProposalSelector);

  const params = useParams();

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const read_token = params?.token;

  const [conditions, setConditions] = useState({
    is_employee: false,
    is_employer: false,
    is_member_rates: false,
    is_total: false,
    is_life_total: false,
  });

  const prepareAgeRates = (rates) => {
    Object.keys(rates).forEach((key) => {
      if (!key.includes('age')) {
        delete rates[key];
      }
    });

    delete rates.is_age_attained;

    return rates;
  };

  const spanStyles = {
    height: '38px',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #dbdbdb',
  };

  const divStyles = {
    width: '100%',
    padding: '7px 10px',
  };

  const renderRates = useCallback(() => {
    return (
      <tr className={classes.AgeRatesWrapper}>
        <th></th>
        {offers?.map((offer) => {
          const rates = _.cloneDeep(offer);

          const ageRates = prepareAgeRates(rates);

          const allAges = coverageAllAges(ageRates);
          const fiveAges = coverageFiveYearAges(ageRates);
          const tenAges = coverageTenYearAges(ageRates);
          const lifeAllAgesRates = lifeUniAllAges(offer);
          const lifeFiveBandedRates = lifeUniFiveYearAges(offer);
          const lifeTenBandedRates = lifeUniTenYearAges(offer);
          const lifeTobaccoAllAgeRates = lifeTobaccoAllAges(offer);
          const lifeNonTobaccoAllAgeRates = lifeNonTobaccoAllAges(offer);
          const lifeTobaccoFiveBandedRates = lifeTobaccoFiveYearAges(offer);
          const lifeNonTobaccoFiveBandedRates = lifeNonTobaccoFiveYearAges(offer);
          const lifeTobaccoTenBandedRates = lifeTobaccoTenYearAges(offer);
          const lifeNonTobaccoTenBandedRates = lifeNonTobaccoTenYearAges(offer);

          const isBrokerUniRates = offer?.rate_tier_type === '4tier' && offer?.is_uni_tobacco;
          const isBrokerUniAllAges = offer?.rate_tier_type === 'all_ages' && offer?.is_uni_tobacco;
          const isBrokerUniFive = offer?.rate_tier_type === 'banded_5' && offer?.is_uni_tobacco;
          const isBrokerUniTen = offer?.rate_tier_type === 'banded_10' && offer?.is_uni_tobacco;

          const isBrokerTierTobacco = offer?.rate_tier_type === '4tier' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoAllAges =
            offer?.rate_tier_type === 'all_ages' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoFive =
            offer?.rate_tier_type === 'banded_5' && !offer?.is_uni_tobacco;
          const isBrokerTobaccoTen =
            offer?.rate_tier_type === 'banded_10' && !offer?.is_uni_tobacco;

          return (
            <td style={{ padding: '0 !important' }} key={uuid()}>
              {coverage !== 'life' && (
                <>
                  {(offer?.rate_tier_type === '3tier' || offer?.rate_tier_type === '4tier') &&
                    renderTierRates(offer)}
                  {offer?.rate_tier_type === 'employee' ? (
                    <span key={uuid()} style={spanStyles}>
                      <div style={divStyles}>{offer.rate_ee ? `$${offer.rate_ee}` : '$0'}</div>
                    </span>
                  ) : (
                    ''
                  )}
                  {offer?.rate_tier_type === 'all_ages' && renderCoverageAllAges(allAges)}
                  {offer?.rate_tier_type === 'banded_5' && renderCoverageFiveYearAges(fiveAges)}
                  {offer?.rate_tier_type === 'banded_10' && renderCoverageTenYearAges(tenAges)}
                </>
              )}
              {coverage === 'life' && (
                <>
                  {isBrokerUniRates && renderTierUniRates(offer)}
                  {isBrokerUniAllAges &&
                    renderUniTobaccoRates(lifeAllAgesRates, LIFE_UNI_ALL_AGES_NAMES)}
                  {isBrokerUniFive &&
                    renderUniTobaccoRates(lifeFiveBandedRates, LIFE_UNI_FIVE_YEAR_NAMES)}
                  {isBrokerUniTen &&
                    renderUniTobaccoRates(lifeTenBandedRates, LIFE_UNI_TEN_YEAR_NAMES)}
                  {isBrokerTierTobacco && renderTierTobaccoRates(offer)}
                  {isBrokerTobaccoAllAges &&
                    renderTobaccoRates(
                      lifeTobaccoAllAgeRates,
                      lifeNonTobaccoAllAgeRates,
                      LIFE_NON_TOBACCO_ALL_AGES_NAMES,
                    )}
                  {isBrokerTobaccoFive &&
                    renderTobaccoRates(
                      lifeTobaccoFiveBandedRates,
                      lifeNonTobaccoFiveBandedRates,
                      LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
                    )}
                  {isBrokerTobaccoTen &&
                    renderTobaccoRates(
                      lifeTobaccoTenBandedRates,
                      lifeNonTobaccoTenBandedRates,
                      LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
                    )}
                </>
              )}
            </td>
          );
        })}
      </tr>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverage, offers]);

  const onSelectPlan = useCallback(
    (item) => async () => {
      const payload = {
        ...item,
        accountId,
        proposalId,
        isBroker: true,
      };

      payload.status = 'approved';

      await dispatch(updateCoverageOffer({ payload, coverage, offerId: payload.id, read_token }));

      await dispatch(getExecutiveSummaryProposals({ accountId, proposalId, read_token }));
    },
    [dispatch, accountId, proposalId, coverage, read_token],
  );

  const onDeselectPlan = useCallback(
    (item) => async () => {
      const payload = {
        ...item,
        accountId,
        proposalId,
        isBroker: true,
      };

      payload.status = 'selected';

      await dispatch(updateCoverageOffer({ payload, coverage, offerId: payload.id, read_token }));

      await dispatch(getExecutiveSummaryProposals({ accountId, proposalId, read_token }));
    },
    [dispatch, accountId, proposalId, coverage, read_token],
  );

  const isCustomFields = offers?.find((offer) => offer?.custom_fields?.length > 0);
  const currentOffer = offers?.find((offer) => offer?.issuer_name === 'broker');
  const carrierOffer = offers?.find((offer) => offer?.issuer_name !== 'broker');

  const isDisabledSelectButton = !_.isEmpty(offers.find((item) => item.status === 'approved'));

  const mdvCoverages = ['medical', 'dental', 'vision'];
  const ahcCoverages = ['accident', 'hospital', 'critical_illness'];

  const longest_custom_fields = offers?.reduce((prev, current) => {
    return current?.custom_fields?.length > prev?.custom_fields?.length ? current : prev;
  });

  return (
    <div className={classes.Offer}>
      <div className={classes.OfferTableContent}>
        <div className={classes.OfferTable}>
          <table>
            <thead>
              <tr>
                <th></th>
                {offers?.map((offer) => (
                  <th key={uuid()}>
                    {offer.issuer_name === 'broker' ? 'Current Offer' : 'Carrier Offer'}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Issuer Name</th>
                {offers?.map((offer) => (
                  <td key={uuid()}>
                    <div className={classes.IssuerName}>
                      {offer.issuer_name}
                      {offer?.status === 'approved' && (
                        <div className={classes.StatusWrapper}>
                          <Status status={offer?.status} />
                        </div>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
              {contribution && (
                <>
                  <tr>
                    <th>Contribution EE</th>
                    {offers?.map((offer) => (
                      <td key={uuid()}>
                        {offer?.contribution_type === 'dollar' && '$'}
                        {offer?.contribution_ee}
                        {offer?.contribution_type === 'percent' && '%'}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Contribution DEP</th>
                    {offers?.map((offer) => (
                      <td key={uuid()}>
                        {offer?.contribution_type === 'dollar' && '$'}
                        {offer?.contribution_dep}
                        {offer?.contribution_type === 'percent' && '%'}
                      </td>
                    ))}
                  </tr>
                </>
              )}
              {offer_details.map((item) => (
                <>
                  <tr key={uuid()}>
                    <th>{COVERAGE_NAMES[item.key]}</th>
                    {offers?.map((offer) => (
                      <td key={uuid()}>
                        {item.format ? item.format(offer[item.key]) : offer[item.key]}
                      </td>
                    ))}
                  </tr>

                  {item?.render &&
                    item.render(offers[0][item.key])?.map((renderItem) => (
                      <tr key={uuid()}>
                        <th>{COVERAGE_NAMES[renderItem.key]}</th>
                        {offers?.map((offer) => (
                          <td key={uuid()}>
                            {renderItem.format
                              ? renderItem.format(offer[renderItem.key])
                              : offer[renderItem.key]}
                          </td>
                        ))}
                      </tr>
                    ))}

                  {item.key === 'inpatient_facility_is_percent' && (
                    <>
                      <tr>
                        <th></th>
                        {offers.map((offer) => {
                          return (
                            <td key={offer?.id}>
                              {offer?.inpatient_facility_is_percent
                                ? `${offer.inpatient_facility_percent_value} %`
                                : `$${offer.inpatient_facility_dollar_amount}`}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th></th>
                        {offers.map((offer) => {
                          return (
                            <td key={offer?.id}>
                              {offer?.inpatient_facility_is_percent
                                ? ''
                                : `${offer.inpatient_facility_dollar_days} Days`}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  )}
                </>
              ))}
              <tr>
                <th>Compensation Amount</th>
                {offers?.map((offer) => (
                  <td key={uuid()}>{normalizeCompensationAmount(offer)}</td>
                ))}
              </tr>
              <tr>
                <th>Current Rate</th>
                {offers?.map((offer) => (
                  <td key={uuid()}>{offer.is_current_rate ? 'Yes' : 'No'}</td>
                ))}
              </tr>
              {isCustomFields && (
                <>
                  <tr className={classes.OfferTableEmptyField}></tr>
                  <tr>
                    <th className={classes.CustomFields}>Custom Fields</th>
                    {offers?.map(() => (
                      <th className={classes.CustomFields} key={uuid()}></th>
                    ))}
                  </tr>

                  {longest_custom_fields?.custom_fields?.map((item, index) => (
                    <tr>
                      <th>{currentOffer.custom_fields[index]?.title ?? ''}</th>
                      {offers?.map((item) => (
                        <th>{item.custom_fields[index]?.value ?? ''}</th>
                      ))}
                    </tr>
                  ))}
                </>
              )}
              <tr className={classes.OfferTableEmptyField}></tr>
              <tr>
                <th>Rate Tier Type</th>
                {offers?.map((offer) => (
                  <th key={uuid()}>{normalizeRateTierType(offer.rate_tier_type)}</th>
                ))}
              </tr>

              {coverage === 'life' && (
                <tr>
                  <th></th>
                  {offers?.map((offer) => (
                    <th key={uuid()}>
                      {offer?.is_uni_tobacco ? 'Uni-Tobacco' : 'Tobacco / Non-Tobacco'}
                    </th>
                  ))}
                </tr>
              )}

              {renderRates()}

              <tr className={classes.OfferTableEmptyField}></tr>

              {mdvCoverages.includes(coverage) &&
                renderMDVTable(offers, accountMembers, { conditions, setConditions })}

              {coverage === 'life' &&
                renderLifeTable(offers, accountMembers, { conditions, setConditions })}

              {coverage === 'disability' &&
                renderDisabilityTable(offers, accountMembers, { conditions, setConditions })}

              {ahcCoverages.includes(coverage) &&
                renderAHCTable(offers, accountMembers, { conditions, setConditions })}

              {coverageType === 'quote' && (
                <tr>
                  <th></th>
                  {offers.map((offer) => {
                    return (
                      <td key={uuid()} className={classes.Button}>
                        {offer.issuer_name !== 'broker' &&
                          (offer?.status !== 'approved' ? (
                            <Button
                              title="Select Plan"
                              type="primary"
                              onClick={onSelectPlan(offer)}
                              isDisabled={isDisabledSelectButton}
                            />
                          ) : (
                            <Button
                              title="Unselect"
                              type="inverse"
                              onClick={onDeselectPlan(offer)}
                            />
                          ))}
                      </td>
                    );
                  })}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {type === 'details' && (
        <div className={classes.DropZone}>
          <div className={classes.DropZoneCurrent}>
            <DropZone
              label={DROP_ZONE_NAMES.BROKER}
              maxFiles={100}
              path={`/proposals/${currentOffer?.proposal_id}/uploads/broker/plan-configs/${currentOffer?.id}/`}
              isDisabled={true}
            />
          </div>
          <DropZone
            label={DROP_ZONE_NAMES.CARRIER}
            maxFiles={100}
            path={`/proposals/${currentOffer?.proposal_id}/uploads/carrier/plan-configs/${carrierOffer?.config_id}/offers/${carrierOffer?.id}/`}
            isDisabled={true}
          />
        </div>
      )}
    </div>
  );
};
