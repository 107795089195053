import queryString from 'query-string';

export default class ApiClient {
  constructor({ apiUrl } = {}) {
    this.token = localStorage.getItem('access_token');
    this.apiUrl = apiUrl;
  }

  async request({ endpoint, method, params = {}, headers = {}, body, asJson = true }) {
    const head = { ...headers };
    const asFormData = !asJson;

    if (this.token) {
      head.Authorization = `Bearer ${this.token}`;
    }

    const query = Object.keys(params).length
      ? `?${decodeURIComponent(queryString?.stringify(params))}`
      : '';
    const url = `${this.apiUrl}/${endpoint}${query}`;

    try {
      let payload = body;

      if (asJson && !asFormData) {
        payload = JSON.stringify(body);
        head['Content-Type'] = 'application/json';
      }

      if (asFormData) {
        payload = body;
      }

      const response = await fetch(url, {
        method,
        headers: head,
        body: payload,
      });

      if (response.status === 204) {
        return { message: 'Created' };
      }

      const data = await response.json();

      if (data && (response.status === 200 || response.status === 201)) {
        return data;
      }

      const errorCode = data?.error?.code;

      if (errorCode === 'WRONG_TOKEN' || errorCode === 'AUTHENTICATION_FAILED') {
        localStorage.clear();
        this.clearToken();
      }

      throw data;
    } catch (err) {
      console.warn('Unhandled exeption');
      console.warn(err);
      throw err;
    }
  }

  get(endpoint, params, headers, asJson = true) {
    return this.request({ endpoint, method: 'GET', params, headers, asJson });
  }

  post(endpoint, body = {}, headers = undefined, asJson) {
    return this.request({
      endpoint,
      method: 'POST',
      body,
      headers,
      asJson,
    });
  }

  patch({ endpoint, body = {}, headers = undefined, asJson }) {
    return this.request({ endpoint, method: 'PATCH', body, headers, asJson });
  }

  put(endpoint, body = {}, headers = undefined, asJson = true) {
    return this.request({ endpoint, method: 'PUT', body, headers, asJson });
  }

  delete(endpoint, body = {}, headers, asJson = true) {
    return this.request({ endpoint, method: 'DELETE', body, headers, asJson });
  }

  setToken(token) {
    this.token = token;
  }

  clearToken() {
    this.token = undefined;
  }
}
