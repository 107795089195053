import _ from 'lodash';

const lifeUniTenYearAges = (rate) => {
  return {
    age_0_17_uni_tobacco: !_.isEmpty(rate) ? rate['age_0_uni_tobacco'] : '',
    age_18_29_uni_tobacco: !_.isEmpty(rate) ? rate['age_18_uni_tobacco'] : '',
    age_30_39_uni_tobacco: !_.isEmpty(rate) ? rate['age_30_uni_tobacco'] : '',
    age_40_49_uni_tobacco: !_.isEmpty(rate) ? rate['age_40_uni_tobacco'] : '',
    age_50_59_uni_tobacco: !_.isEmpty(rate) ? rate['age_50_uni_tobacco'] : '',
    age_60_69_uni_tobacco: !_.isEmpty(rate) ? rate['age_60_uni_tobacco'] : '',
    age_70_79_uni_tobacco: !_.isEmpty(rate) ? rate['age_70_uni_tobacco'] : '',
    age_80_uni_tobacco: !_.isEmpty(rate) ? rate['age_80_uni_tobacco'] : '',
  };
};

const LIFE_UNI_TEN_YEAR_NAMES = {
  age_0_17_uni_tobacco: 'Age 0-17',
  age_18_29_uni_tobacco: 'Age 18-29',
  age_30_39_uni_tobacco: 'Age 30-39',
  age_40_49_uni_tobacco: 'Age 40-49',
  age_50_59_uni_tobacco: 'Age 50-59',
  age_60_69_uni_tobacco: 'Age 60-69',
  age_70_79_uni_tobacco: 'Age 70-79',
  age_80_uni_tobacco: 'Age 80+',
};

const normalizeLifeUniTenYearAgeRates = (tenYearAgeRates) => {
  let payload = {
    ...tenYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_0_17_uni_tobacco'],
    };
  }
  for (let i = 18; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_18_29_uni_tobacco'],
    };
  }
  for (let i = 30; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_30_39_uni_tobacco'],
    };
  }
  for (let i = 40; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_40_49_uni_tobacco'],
    };
  }
  for (let i = 50; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_50_59_uni_tobacco'],
    };
  }
  for (let i = 60; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_60_69_uni_tobacco'],
    };
  }
  for (let i = 70; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_70_79_uni_tobacco'],
    };
  }
  for (let i = 80; i <= 80; i++) {
    payload = {
      ...payload,
      [`age_${i}_uni_tobacco`]: tenYearAgeRates['age_80_uni_tobacco'],
    };
  }

  delete payload['age_0_17_uni_tobacco'];
  delete payload['age_18_29_uni_tobacco'];
  delete payload['age_30_39_uni_tobacco'];
  delete payload['age_40_49_uni_tobacco'];
  delete payload['age_50_59_uni_tobacco'];
  delete payload['age_60_69_uni_tobacco'];
  delete payload['age_70_79_uni_tobacco'];

  return payload;
};

const lifeTobaccoTenYearAges = (rate) => {
  return {
    age_0_17_tobacco: !_.isEmpty(rate) ? rate['age_0_tobacco'] : '',
    age_18_29_tobacco: !_.isEmpty(rate) ? rate['age_18_tobacco'] : '',
    age_30_39_tobacco: !_.isEmpty(rate) ? rate['age_30_tobacco'] : '',
    age_40_49_tobacco: !_.isEmpty(rate) ? rate['age_40_tobacco'] : '',
    age_50_59_tobacco: !_.isEmpty(rate) ? rate['age_50_tobacco'] : '',
    age_60_69_tobacco: !_.isEmpty(rate) ? rate['age_60_tobacco'] : '',
    age_70_79_tobacco: !_.isEmpty(rate) ? rate['age_70_tobacco'] : '',
    age_80_tobacco: !_.isEmpty(rate) ? rate['age_80_tobacco'] : '',
  };
};

const LIFE_TOBACCO_TEN_YEAR_NAMES = {
  age_0_17_tobacco: 'Age 0-17 Tobacco',
  age_18_29_tobacco: 'Age 18-29 Tobacco',
  age_30_39_tobacco: 'Age 30-39 Tobacco',
  age_40_49_tobacco: 'Age 40-49 Tobacco',
  age_50_59_tobacco: 'Age 50-59 Tobacco',
  age_60_69_tobacco: 'Age 60-69 Tobacco',
  age_70_79_tobacco: 'Age 70-79 Tobacco',
  age_80_tobacco: 'Age 80+ Tobacco',
};

const normalizeLifeTobaccoTenYearAgeRates = (tenYearAgeRates) => {
  let payload = {
    ...tenYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_0_17_tobacco'],
    };
  }
  for (let i = 18; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_18_29_tobacco'],
    };
  }
  for (let i = 30; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_30_39_tobacco'],
    };
  }
  for (let i = 40; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_40_49_tobacco'],
    };
  }
  for (let i = 50; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_50_59_tobacco'],
    };
  }
  for (let i = 60; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_60_69_tobacco'],
    };
  }
  for (let i = 70; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_70_79_tobacco'],
    };
  }
  for (let i = 80; i <= 80; i++) {
    payload = {
      ...payload,
      [`age_${i}_tobacco`]: tenYearAgeRates['age_80_tobacco'],
    };
  }

  delete payload['age_0_17_tobacco'];
  delete payload['age_18_29_tobacco'];
  delete payload['age_30_39_tobacco'];
  delete payload['age_40_49_tobacco'];
  delete payload['age_50_59_tobacco'];
  delete payload['age_60_69_tobacco'];
  delete payload['age_70_79_tobacco'];

  return payload;
};

const lifeNonTobaccoTenYearAges = (rate) => {
  return {
    age_0_17_non_tobacco: !_.isEmpty(rate) ? rate['age_0_non_tobacco'] : '',
    age_18_29_non_tobacco: !_.isEmpty(rate) ? rate['age_18_non_tobacco'] : '',
    age_30_39_non_tobacco: !_.isEmpty(rate) ? rate['age_30_non_tobacco'] : '',
    age_40_49_non_tobacco: !_.isEmpty(rate) ? rate['age_40_non_tobacco'] : '',
    age_50_59_non_tobacco: !_.isEmpty(rate) ? rate['age_50_non_tobacco'] : '',
    age_60_69_non_tobacco: !_.isEmpty(rate) ? rate['age_60_non_tobacco'] : '',
    age_70_79_non_tobacco: !_.isEmpty(rate) ? rate['age_70_non_tobacco'] : '',
    age_80_non_tobacco: !_.isEmpty(rate) ? rate['age_80_non_tobacco'] : '',
  };
};

const LIFE_NON_TOBACCO_TEN_YEAR_NAMES = {
  age_0_17_non_tobacco: 'Age 0-17 Non-Tobacco',
  age_18_29_non_tobacco: 'Age 18-29 Non-Tobacco',
  age_30_39_non_tobacco: 'Age 30-39 Non-Tobacco',
  age_40_49_non_tobacco: 'Age 40-49 Non-Tobacco',
  age_50_59_non_tobacco: 'Age 50-59 Non-Tobacco',
  age_60_69_non_tobacco: 'Age 60-69 Non-Tobacco',
  age_70_79_non_tobacco: 'Age 70-79 Non-Tobacco',
  age_80_non_tobacco: 'Age 80+ Non-Tobacco',
};

const normalizeLifeNonTobaccoTenYearAgeRates = (tenYearAgeRates) => {
  let payload = {
    ...tenYearAgeRates,
  };

  for (let i = 0; i <= 17; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_0_17_non_tobacco'],
    };
  }
  for (let i = 18; i <= 29; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_18_29_non_tobacco'],
    };
  }
  for (let i = 30; i <= 39; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_30_39_non_tobacco'],
    };
  }
  for (let i = 40; i <= 49; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_40_49_non_tobacco'],
    };
  }
  for (let i = 50; i <= 59; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_50_59_non_tobacco'],
    };
  }
  for (let i = 60; i <= 69; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_60_69_non_tobacco'],
    };
  }
  for (let i = 70; i <= 79; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_70_79_non_tobacco'],
    };
  }
  for (let i = 80; i <= 80; i++) {
    payload = {
      ...payload,
      [`age_${i}_non_tobacco`]: tenYearAgeRates['age_80_non_tobacco'],
    };
  }

  delete payload['age_0_17_non_tobacco'];
  delete payload['age_18_29_non_tobacco'];
  delete payload['age_30_39_non_tobacco'];
  delete payload['age_40_49_non_tobacco'];
  delete payload['age_50_59_non_tobacco'];
  delete payload['age_60_69_non_tobacco'];
  delete payload['age_70_79_non_tobacco'];

  return payload;
};

export {
  lifeUniTenYearAges,
  LIFE_UNI_TEN_YEAR_NAMES,
  normalizeLifeUniTenYearAgeRates,
  lifeTobaccoTenYearAges,
  LIFE_TOBACCO_TEN_YEAR_NAMES,
  normalizeLifeTobaccoTenYearAgeRates,
  lifeNonTobaccoTenYearAges,
  LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
  normalizeLifeNonTobaccoTenYearAgeRates,
};
