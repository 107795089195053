import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearLifeConfigurationState } from './lifeConfiguration.slice';

export const getLifePlans = createAsyncThunk(
  'life-configuration/get-life-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getLifePlans(accountId, proposalId, read_token);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getLifeQuotePlans = createAsyncThunk(
  'life-configuration/get-life-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getLifeQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearLifeState = createAsyncThunk('life/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearLifeConfigurationState());
});
