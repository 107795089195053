import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { Button, Select } from 'src/components';

import { usePagination, DOTS } from './usePagination';

import classes from './pagination.module.scss';

const selectOptions = [
  { name: 10, code: 10 },
  { name: 20, code: 20 },
  { name: 30, code: 30 },
  { name: 40, code: 40 },
  { name: 50, code: 50 },
];

export const Pagination = (props) => {
  const {
    onPageChange,
    onChangePageSize,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 20,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount: totalCount ?? 0,
    siblingCount,
    pageSize: pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange ? paginationRange[paginationRange?.length - 1] : 0;

  const containerStyles = classnames(classes.PaginationContainer, { [className]: className });

  return (
    <ul className={containerStyles}>
      {paginationRange?.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`${pageNumber}${uuidv4()}`}
              className={`${classes.PaginationItem} ${classes.Dots}`}
            >
              &#8230;
            </li>
          );
        }

        const itemStyles = classnames(classes.PaginationItem, {
          [classes.Selected]: pageNumber === currentPage,
        });

        return (
          <li key={pageNumber} className={itemStyles} onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <div className={classes.PaginationButton}>
        {totalCount > 10 && onChangePageSize && (
          <Select value={pageSize} options={selectOptions} onChange={onChangePageSize} />
        )}
        {currentPage !== 1 && <Button title="Previous" type="secondary" onClick={onPrevious} />}
        {currentPage !== lastPage && <Button title="Next Page" type="secondary" onClick={onNext} />}
      </div>
    </ul>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  onChangePageSize: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
};
