import { currencyFormatter } from './currency';

export const normalizeRateValue = (value, digits = 3) => {
  return parseFloat(currencyFormatter(value, digits)?.replaceAll('$', '').replace(/,/g, ''));
};

const calculateHouseholdTobaccoFamilies = (householdFamilies, selectedOffer) => {
  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    const isTobaccoUsers = household?.find((item) => item?.is_tobacco_user);

    if (employee && spouse && child) {
      let householdPayload = [];
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = {
            ...member,
            type: 'Family',
          };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const tobaccoValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_tobacco ?? 0;
              const nonTobaccoValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const tobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const tobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const tobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_tobacco ?? 0;
              const nonTobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }

          householdPayload?.push(memberPayload);
        } else {
          householdPayload?.push(member);
        }
      }
      payload = {
        ...payload,
        [family_id]: householdPayload,
      };
    } else {
      let householdPayload = [];
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = { ...member, type: 'Employee' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const tobaccoValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_tobacco ?? 0;
              const nonTobaccoValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const tobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const tobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const tobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_tobacco ?? 0;
              const nonTobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }
          householdPayload?.push(memberPayload);
        }

        if (member?.relationship === 'spouse' || member?.relationship === 'life_partner') {
          let memberPayload = { ...member, type: 'Spouse' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const tobaccoValue = 0;
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              //     selectedOffer?.rate_sp_tobacco ??
              const nonTobaccoValue = 0;
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              //     selectedOffer?.rate_sp_non_tobacco ??

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const tobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const tobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const tobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_tobacco ?? 0;
              const nonTobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }
          householdPayload?.push(memberPayload);
        }

        if (member?.relationship === 'child') {
          let memberPayload = { ...member, type: 'Child' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const tobaccoValue = 0;
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              //     selectedOffer?.rate_ch_tobacco ??
              const nonTobaccoValue = 0;
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              //     selectedOffer?.rate_ch_non_tobacco ??

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const tobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const tobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_tobacco ?? 0;
              const nonTobaccoValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const tobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_tobacco ?? 0;
              const nonTobaccoValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_non_tobacco ?? 0;

              if (isTobaccoUsers) {
                memberPayload.tobacco = normalizeRateValue(tobaccoValue);
                memberPayload.tobaccoType = 'Tobacco';
              }

              if (!isTobaccoUsers) {
                memberPayload.nonTobacco = normalizeRateValue(nonTobaccoValue);
                memberPayload.tobaccoType = 'Non-Tobacco';
              }
            }
          }
          householdPayload?.push(memberPayload);
        }
      }
      payload = {
        ...payload,
        [family_id]: householdPayload,
      };
    }
  }
  return payload;
};

export const calculateLifeRates = (accountMembers, selectedOffer) => {
  let household4TierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  household4TierTypes = calculateHouseholdTobaccoFamilies(householdFamilies, selectedOffer);

  household4TierTypes.totalTobacco = 0;
  for (const family_id in household4TierTypes) {
    if (family_id !== 'totalTobacco') {
      for (const member of household4TierTypes[family_id]) {
        household4TierTypes.totalTobacco += member?.tobacco || 0;
        household4TierTypes.totalTobacco += member?.nonTobacco || 0;
      }
    }
  }

  household4TierTypes.totalTobacco = normalizeRateValue(household4TierTypes.totalTobacco);

  return household4TierTypes;
};

const calculateHouseholdFamilies = (householdFamilies, selectedOffer) => {
  let payload = {};

  for (const family_id in householdFamilies) {
    const household = householdFamilies[family_id];

    const employee = household?.find((item) => item?.relationship === 'employee');
    const spouse = household?.find(
      (item) => item?.relationship === 'spouse' || item?.relationship === 'life_partner',
    );
    const child = household?.find((item) => item?.relationship === 'child');

    if (employee && spouse && child) {
      let householdPayload = [];
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = {
            ...member,
            type: 'Family',
          };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const rateValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const rateValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const rateValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const rateValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_fam_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          householdPayload?.push(memberPayload);
        } else {
          householdPayload?.push(member);
        }
      }
      payload = {
        ...payload,
        [family_id]: householdPayload,
      };
    } else {
      let householdPayload = [];
      for (const member of household) {
        if (member?.relationship === 'employee') {
          let memberPayload = { ...member, type: 'Employee' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              const rateValue =
                (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const rateValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const rateValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const rateValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ee_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }
          householdPayload?.push(memberPayload);
        }
        if (member?.relationship === 'spouse' || member?.relationship === 'life_partner') {
          let memberPayload = { ...member, type: 'Spouse' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              // selectedOffer?.rate_sp_uni_tobacco ??
              const rateValue = 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const rateValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const rateValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const rateValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_sp_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }
          householdPayload?.push(memberPayload);
        }
        if (member?.relationship === 'child') {
          let memberPayload = { ...member, type: 'Child' };

          if (!selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.contribution_type === 'employer') {
              // (selectedOffer?.death_benefit / selectedOffer?.rate_per_unit) *
              //     selectedOffer?.rate_ch_uni_tobacco ??
              const rateValue = 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.contribution_type === 'voluntary') {
              const rateValue =
                (member?.life_benefit_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          if (selectedOffer?.death_benefit?.includes('salary')) {
            if (selectedOffer?.death_benefit === '1xsalary') {
              const rateValue =
                (member?.salary_amount / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }

            if (selectedOffer?.death_benefit === '2xsalary') {
              const rateValue =
                ((member?.salary_amount * 2) / selectedOffer?.rate_per_unit) *
                  selectedOffer?.rate_ch_uni_tobacco ?? 0;

              memberPayload.tobacco = normalizeRateValue(rateValue);
            }
          }

          householdPayload?.push(memberPayload);
        }
      }
      payload = {
        ...payload,
        [family_id]: householdPayload,
      };
    }
  }
  return payload;
};

export const calculateUniLifeRates = (accountMembers, selectedOffer) => {
  let household4TierTypes = {};

  // compute household rate tier type
  const householdFamilies = accountMembers.reduce((previousValue, currentValue) => {
    if (typeof previousValue[currentValue.family_id] === 'undefined') {
      previousValue[currentValue.family_id] = [];
    }

    previousValue[currentValue.family_id].push(currentValue);

    return previousValue;
  }, {});

  household4TierTypes = calculateHouseholdFamilies(householdFamilies, selectedOffer);

  household4TierTypes.totalTobacco = 0;
  for (const family_id in household4TierTypes) {
    if (family_id !== 'totalTobacco') {
      for (const member of household4TierTypes[family_id]) {
        household4TierTypes.totalTobacco += member?.tobacco || 0;
      }
    }
  }

  household4TierTypes.totalTobacco = normalizeRateValue(household4TierTypes.totalTobacco);

  return household4TierTypes;
};
