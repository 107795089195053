export const hiddenBarsIcon = (isReadOnlyMode, isProposalFinalize) => {
  if (isReadOnlyMode) {
    return false;
  }

  if (isProposalFinalize) {
    return false;
  }

  return true;
};
