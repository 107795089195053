import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ROUTE } from 'src/constants/routes';

import { Button } from 'src/components';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './notAuthorized.module.scss';
import { ERROR } from 'src/constants/errorNames';

export const NotAuthorized = () => {
  const navigate = useNavigate();

  const onRedirectUser = useCallback(() => {
    navigate(ROUTE.DEFAULT);
  }, [navigate]);

  return (
    <div className={classes.NotAuthorized}>
      <div className={classes.NotAuthorizedCard}>
        <div className={classes.NotAuthorizedLogo}>
          <img src={MonarkLogo} alt="loginMonarkLogo" />
        </div>
        <div className={classes.NotAuthorizedContent}>
          <div className={classes.NotAuthorizedContentTitle}>Acces Denied</div>
          <div className={classes.NotAuthorizedContentSubTitle}>{ERROR.INACCESSABLE}</div>

          <div className={classes.NotAuthorizedButton}>
            <Button onClick={onRedirectUser} title="Go to Start Page" type="primary" />
          </div>
        </div>
      </div>
      <div className={classes.NotAuthorizedCopyright}>
        <i className="far fa-copyright" />
        {moment().year()} Monark
      </div>
    </div>
  );
};
