import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import Cropper from 'react-easy-crop';
import classnames from 'classnames';

import { storage } from 'src/app/database';

import { Button, Loader, Modal, RangeInput } from 'src/components';

import classes from './uploadImage.module.scss';
import getCroppedImg from './cropImage';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export const UploadImage = (props) => {
  const {
    path,
    isOnlyImage,
    setPreviewImage,
    imageKey,
    previewImage,
    isCirclePreviewImage,
    type = 'default',
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect, setAspect] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    if (croppedImage) {
      const fetchFunction = async () => {
        setIsLoading(true);
        await fetch(croppedImage)
          .then((res) => res.blob())
          .then(async (blob) => {
            const file = new File([blob], 'AccountLogo', { type: 'image/png' });

            if (file.size > 5000000) {
              return toast('File cannot exceed 5 mb', { type: 'error' });
            }

            const storageRef = storage.ref();
            const logoImageRef = storageRef.child(path);
            const snapshot = await logoImageRef.put(file);
            const imageURL = await snapshot.ref.getDownloadURL();

            setImageUrl(imageURL);
          });
        setCroppedImage();
        setIsLoading(false);
      };
      fetchFunction();
    }
  }, [croppedImage, path]);

  useEffect(() => {
    if (imageUrl) {
      setIsLoading(true);

      if (type === 'default') {
        setPreviewImage((prev) => ({ ...prev, [imageKey]: imageUrl }));
      }
      if (type !== 'default') {
        setPreviewImage(imageUrl);
      }

      setIsLoading(false);
    }
  }, [setPreviewImage, imageKey, imageUrl, type]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onUploadImage = useCallback((event) => {
    const _URL = window.URL || window.webkitURL;
    const file = event.target.files[0];

    setUploadedImage(_URL.createObjectURL(file));
  }, []);

  const onChangeZoom = useCallback((event) => {
    setZoom(event.target.value);
  }, []);

  const onSaveCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const croppedImage = await getCroppedImg(uploadedImage, croppedAreaPixels, aspect);
        setCroppedImage(croppedImage);

        setUploadedImage();
      } catch (e) {
        console.error(e);
      }
    },
    [uploadedImage, croppedAreaPixels, aspect],
  );

  const onCancelCrop = useCallback(() => {
    setUploadedImage();
    setCroppedImage(null);
  }, []);

  const onClickUploadImage = useCallback((event) => {
    return (event.target.value = '');
  }, []);

  const emptyImage =
    'https://res.cloudinary.com/dlocussey/image/upload/v1589832771/website/128x128-placeholder_hvdami.png';

  const previewImageStyles = classnames({
    [classes.PreviewImage]: !isCirclePreviewImage,
    [classes.PreviewImageCircle]: isCirclePreviewImage,
  });

  const onSelectAspect = useCallback((event, value) => {
    setAspect(value);
  }, []);

  return (
    <>
      {uploadedImage && (
        <Modal closeButtonType="inside" onClose={onCancelCrop}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentCropper}>
              <Cropper
                aspect={aspect}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                image={uploadedImage}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                restrictPosition={false}
              />
            </div>
            <div className={classes.ModalContentAspect}>
              <ToggleButtonGroup
                color="primary"
                value={aspect}
                exclusive
                onChange={onSelectAspect}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value={1}>Square</ToggleButton>
                <ToggleButton value={3 / 2}>Rectangle</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div>
              <RangeInput
                step={0.1}
                minRange={0.1}
                maxRange={2}
                value={zoom}
                setValue={setZoom}
                onChange={onChangeZoom}
              />
            </div>
            <div className={classes.ModalContentFooter}>
              <Button title="Save" type="primary" onClick={onSaveCroppedImage} />
              <Button title="Cancel" type="secondary" onClick={onCancelCrop} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.UploadImage}>
        {isLoading ? (
          <Loader />
        ) : (
          <img
            className={previewImageStyles}
            src={previewImage ? previewImage : emptyImage}
            alt="defaultImage"
            id="UploadImage"
          />
        )}

        {!isOnlyImage && (
          <>
            <label className={classes.UploadLabel}>
              <span className={classes.UploadTitle}>
                <i className="fas fa-upload" />
                Change
              </span>
              <input
                type="file"
                className={classes.UploadInput}
                onChange={onUploadImage}
                onClick={onClickUploadImage}
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </>
        )}
      </div>
    </>
  );
};
