import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import moment from 'moment';

import {
  getProposalsList,
  getIsLoadingProposals,
  getProposalsListSelector,
  getProposalsMetaSelector,
} from 'src/store/proposals';
import {
  agencyAccountProposalsSelector,
  agencyProposalsMetaSelector,
  getAgencyAccountProposals,
  isLoadingAgencyAccountProposalsSelector,
} from 'src/store/agency';

import { ROUTE } from 'src/constants/routes';

import { PageHeader } from '../PageHeader/PageHeader';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { EmptyField } from '../EmptyField/EmptyField';
import { Loader, LoaderWrapper } from '../Loader/Loader';
import { Status } from '../Status/Status';

import classes from './proposalTable.module.scss';

const headerRow = [
  { id: 'name', name: 'Proposal Name' },
  { id: 'status', name: 'Status' },
  { id: 'created_timestamp', name: 'Created' },
];

export const ProposalTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const teamId = params?.teamId;
  const paramsId = params?.id;

  const proposalsList = useSelector(getProposalsListSelector);
  const proposalsMeta = useSelector(getProposalsMetaSelector);
  const agencyProposalsList = useSelector(agencyAccountProposalsSelector);
  const agencyProposalsMeta = useSelector(agencyProposalsMetaSelector);
  const isLoadingProposals = useSelector(getIsLoadingProposals);
  const isLoadingAgencyProposals = useSelector(isLoadingAgencyAccountProposalsSelector);

  const list = !teamId ? proposalsList : agencyProposalsList;
  const listMeta = !teamId ? proposalsMeta : agencyProposalsMeta;

  const [searchValue, setSearchValue] = useState('');

  const [currentPage, setCurrentPage] = useState(listMeta?.page ? listMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    listMeta?.per_page ? listMeta?.per_page : 10,
  );

  const [sortMethod, setSortMethod] = useState('name');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  const fetchProposalList = useCallback(() => {
    const payload = {
      paramsId,
      searchValue,
      page: currentPage,
      per_page: selectedPageSize,
      sort: sortMethod,
    };

    if (!teamId) {
      dispatch(getProposalsList(payload));
    }

    if (teamId) {
      dispatch(
        getAgencyAccountProposals({
          ...payload,
          org_id: teamId,
        }),
      );
    }
  }, [dispatch, currentPage, paramsId, searchValue, selectedPageSize, sortMethod, teamId]);

  useEffect(() => {
    if (!searchValue) {
      fetchProposalList();
    }
  }, [fetchProposalList, searchValue]);

  const onClickSearchButton = useCallback(() => {
    if (searchValue) {
      fetchProposalList();
    }
  }, [fetchProposalList, searchValue]);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        fetchProposalList();
      }
    },
    [fetchProposalList, searchValue],
  );

  const onAddNewProposal = useCallback(() => {
    navigate(ROUTE.PROPOSAL_CREATE);
  }, [navigate]);

  const onChangeSearchInput = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onClearInput = useCallback(() => {
    setSearchValue('');
  }, []);

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  return (
    <div className={classes.ProposalTable}>
      <PageHeader title="Proposals">
        <div className={classes.ProposalTableHeader}>
          <div className={classes.ProposalTableSearch}>
            <div className={classes.ProposalTableSearchWrapper}>
              <Input
                placeholder="Account Name"
                value={searchValue}
                onChange={onChangeSearchInput}
                onKeyDown={searchOnKeyDown}
              />
              {searchValue && (
                <i
                  onClick={onClearInput}
                  className={`fas fa-plus ${classes.ProposalTableSearchClear}`}
                />
              )}
            </div>
            <Button
              type="primary"
              icon="fas fa-search"
              onClick={onClickSearchButton}
              isLoading={isLoadingProposals || isLoadingAgencyProposals}
            />
          </div>
          <div className={classes.ProposalTableCreate}>
            <Button title="New Proposal" type="primary" onClick={onAddNewProposal} />
          </div>
        </div>
      </PageHeader>

      <Paper>
        {isLoadingProposals || isLoadingAgencyProposals ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <>
            {list?.length ? (
              <>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        {headerRow?.map((item) => (
                          <TableCell key={item.id}>
                            <TableSortLabel
                              active={selectedCell === item.id}
                              direction={cellDirection}
                              onClick={onChangeCellSort(item.id)}
                            >
                              {item.name}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Link to={`proposals/${item.id}/account-info`}>{item.name}</Link>
                          </TableCell>
                          <TableCell>
                            <Status status={item?.status} />
                          </TableCell>
                          <TableCell>{moment(item.created_timestamp).format('lll')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  component="div"
                  count={listMeta?.total ?? 0}
                  rowsPerPage={selectedPageSize}
                  page={currentPage - 1}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onChangePageSize}
                />
              </>
            ) : (
              <EmptyField title="You don't have any proposals yet, start by creating one" />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
