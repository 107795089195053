import React from 'react';
import classnames from 'classnames';

import classes from './burgerButton.module.scss';

export const BurgerButton = (props) => {
  const { isActive, onClick } = props;

  const firstLine = classnames({
    [classes.MenuBtnBurger]: !isActive,
    [classes.MenuBtnBurgerFirstOpen]: isActive,
  });

  const secondLine = classnames({
    [classes.MenuBtnBurger]: !isActive,
  });

  const thirdLine = classnames({
    [classes.MenuBtnBurger]: !isActive,
    [classes.MenuBtnBurgerThirdOpen]: isActive,
  });

  return (
    <div className={classes.MenuBtn} onClick={onClick}>
      <div className={firstLine} />
      <div className={secondLine} />
      <div className={thirdLine} />
    </div>
  );
};
