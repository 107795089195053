import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUserTypeSelector } from 'src/store/app';

import { ROUTE } from 'src/constants/routes';

import { Button } from '../Button/Button';

import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './carrierPricing.module.scss';

const tableData = [
  { title: 'Reply to any Inivted RFP', isIndividual: true, isTeams: true },
  { title: 'Track your reply and win rates', isIndividual: true, isTeams: true },
  { title: 'Medical', isIndividual: true, isTeams: true },
  { title: 'Dental', isIndividual: true, isTeams: true },
  { title: 'Vision', isIndividual: true, isTeams: true },
  { title: 'Life', isIndividual: true, isTeams: true },
  { title: 'Disability', isIndividual: true, isTeams: true },
  { title: 'Accident', isIndividual: true, isTeams: true },
  { title: 'Hospital', isIndividual: true, isTeams: true },
  { title: 'Critical Illness', isIndividual: true, isTeams: true },
  { title: 'RFP underwriting exports', isIndividual: true, isTeams: true },
  { title: 'Instant web based proposals', isIndividual: true, isTeams: true },
  { title: 'Broker deal room chat access *coming soon', isIndividual: true, isTeams: true },
  { title: 'Search for new broker RFPs', isIndividual: false, isTeams: true },
  { title: 'Reporting', isIndividual: false, isTeams: true },
  { title: 'Unlimited Team Users', isIndividual: false, isTeams: true },
  { title: 'Team Workflows', isIndividual: false, isTeams: true },
  { title: 'Deal recommendations *coming soon', isIndividual: false, isTeams: true },
  { title: 'Deal loss alerts *coming soon', isIndividual: false, isTeams: true },
];

export const CarrierPricing = (props) => {
  // const { selectedTab } = props;

  const navigate = useNavigate();

  const userType = useSelector(getUserTypeSelector);

  const onClickFree = useCallback(() => {
    if (userType) {
      navigate(ROUTE.REP_MY_RFPS);
    }

    if (!userType) {
      navigate(ROUTE.ISSUER_SIGNUP);
    }
  }, [navigate, userType]);

  const renderTable = useCallback((data) => {
    return (
      <table>
        <tbody>
          {data?.map((item) => (
            <tr key={item.title}>
              <th>{item.title}</th>
              <td>
                {item.isIndividual ? (
                  <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" />
                ) : (
                  ''
                )}
              </td>
              <td>
                {item.isTeams ? <img src={CheckedPurpleCircle} alt="CheckedPurpleCircle" /> : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, []);

  return (
    <div className={classes.CarrierPricing}>
      <div className={classes.CarrierPricingPlans}>
        <div className={classes.CarrierPricingPlansCard}>
          <div className={classes.CarrierPricingPlansCardHeader}>Features</div>
          <div className={classes.CarrierPricingPlansCardContent}>
            <div className={classes.CarrierPricingPlansCardTitle}></div>
            <div className={classes.CarrierPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.CarrierPricingPlansCardFooter}></div>
        </div>
        <div className={classes.CarrierPricingPlansCard}>
          <div className={classes.CarrierPricingPlansCardHeader}>Base</div>
          <div className={classes.CarrierPricingPlansCardContent}>
            <div className={classes.CarrierPricingPlansCardTitle}>
              <span>Free</span>
            </div>
            <div className={classes.CarrierPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.CarrierPricingPlansCardFooter}>
            <Button title="Get Started" type="primary" onClick={onClickFree} />
          </div>
        </div>
        <div className={classes.CarrierPricingPlansCard}>
          <div className={classes.CarrierPricingPlansCardHeader}>Pro</div>
          <div className={classes.CarrierPricingPlansCardContent}>
            <div className={classes.CarrierPricingPlansCardTitle}>
              <span>Free</span> (while still in Beta)
            </div>
            <div className={classes.CarrierPricingPlansCardSubTitle}></div>
          </div>
          <div className={classes.CarrierPricingPlansCardFooter}>
            <Button title="Get Started" type="primary" onClick={onClickFree} />
          </div>
        </div>
      </div>
      {renderTable(tableData)}
    </div>
  );
};
