import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { getMedicalPlansSelector } from 'src/store/medicalConfiguration';
import { getDentalPlanSelector } from 'src/store/dentalConfiguration';
import { getVisionPlansSelector } from 'src/store/visionConfiguration';
import { getLifePlansSelector } from 'src/store/lifeConfiguration';
import { getDisabilityPlansSelector } from 'src/store/disabilityConfiguration';
import { getAccidentPlansSelector } from 'src/store/accidentCnfiguration';
import { getHospitalPlansSelector } from 'src/store/hospitalConfiguration';
import { getCriticalPlansSelector } from 'src/store/criticalConfiguration';
import {
  getAccountProposalSelector,
  getExecutiveSummaryProposals,
  getResponseQuotes,
} from 'src/store/proposals';
import {
  getAccountData,
  getBrokerData,
  getBrokerRequestedProposals,
  getRfpQuotes,
} from 'src/store/broker';

import { exportRateToCsv, onDownloadMembersCsv } from 'src/utils/exportCsv';
import {
  onDownloadBrokerRequestedProposalsXlsx,
  onDownloadProposalXlsx,
  onDownloadMembersXlsx,
  onDownloadSoldQuotesXlsx,
  onDownloadProposalRates,
  onDownloadRatesComparison,
} from 'src/utils/exportXlsx';

import { Button, Modal } from 'src/components';

import DownloadPDFIcon from 'src/assets/proposal/downloadPDFIcon.svg';
import DownloadXLSXIcon from 'src/assets/proposal/downloadXLSXIcon.svg';
import DownloadCSVIcon from 'src/assets/proposal/downloadCSVIcon.svg';
import classes from './exportModal.module.scss';
import { getPetQuotes } from 'src/store/pet';

export const ExportModal = (props) => {
  const { isEdit, setIsLoadingExport } = props;
  const dispatch = useDispatch();
  const params = useParams();

  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const brokerData = useSelector(getBrokerData);
  const accountData = useSelector(getAccountData);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const responseQuotes = useSelector(getResponseQuotes);

  const medicalPlans = useSelector(getMedicalPlansSelector);
  const dentalPlans = useSelector(getDentalPlanSelector);
  const visionPlans = useSelector(getVisionPlansSelector);
  const lifePlans = useSelector(getLifePlansSelector);
  const disabilityPlans = useSelector(getDisabilityPlansSelector);
  const accidentPlans = useSelector(getAccidentPlansSelector);
  const hospitalPlans = useSelector(getHospitalPlansSelector);
  const criticalPlans = useSelector(getCriticalPlansSelector);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvBody, setCsvBody] = useState([]);
  const [csvMembersData, setCsvMembersData] = useState([]);

  const isVisibleExport = useCallback(
    (status) => {
      if (
        medicalPlans.find((item) => item.status === status) ||
        dentalPlans.find((item) => item.status === status) ||
        visionPlans.find((item) => item.status === status) ||
        lifePlans.find((item) => item.status === status) ||
        disabilityPlans.find((item) => item.status === status) ||
        accidentPlans.find((item) => item.status === status) ||
        hospitalPlans.find((item) => item.status === status) ||
        criticalPlans.find((item) => item.status === status)
      ) {
        return true;
      } else {
        return false;
      }
    },
    [
      medicalPlans,
      dentalPlans,
      visionPlans,
      lifePlans,
      disabilityPlans,
      accidentPlans,
      hospitalPlans,
      criticalPlans,
    ],
  );
  const handleOpenDropdown = useCallback(() => {
    setIsVisibleModal((prev) => !prev);
  }, []);

  const onExportToXLSX = async () => {
    setIsLoadingExport(true);
    const payload = {
      accountId,
      proposalId,
      type: 'all',
    };

    const response = await dispatch(getRfpQuotes(payload));
    const petResponse = await dispatch(getPetQuotes({ accountId, proposalId }));

    const executiveResponse = await dispatch(
      getExecutiveSummaryProposals({ accountId, proposalId }),
    );

    const executiveSummary = executiveResponse?.payload;

    const rfpQuotes = response?.payload;

    const account = {
      ...accountData,
      effective_date: accountProposalData?.effective_date,
    };

    onDownloadProposalXlsx(
      account,
      brokerData,
      { ...accountProposalData, pet_quotes: petResponse?.payload?.quotes },
      rfpQuotes,
      executiveSummary,
    );
    setIsVisibleModal(false);
    setIsLoadingExport(false);
  };

  const onExportRates = async (type = 'all') => {
    setIsLoadingExport(true);
    const payload = {
      accountId,
      proposalId,
      type,
    };

    const response = await dispatch(getRfpQuotes(payload));
    const petResponse = await dispatch(getPetQuotes({ accountId, proposalId }));

    const rfpQuotes = response?.payload;

    const account = {
      ...accountData,
      effective_date: accountProposalData?.effective_date,
    };

    onDownloadProposalRates(
      account,
      brokerData,
      accountProposalData,
      { ...rfpQuotes, pet_quotes: petResponse?.payload?.quotes },
      type,
    );
    setIsVisibleModal(false);
    setIsLoadingExport(false);
  };

  const onExportRatesComparison = async (type) => {
    setIsLoadingExport(true);
    const payload = {
      accountId,
      proposalId,
      type: 'all',
    };

    const response = await dispatch(getRfpQuotes(payload));

    const rfpQuotes = response?.payload;

    onDownloadRatesComparison(accountData, accountProposalData, rfpQuotes, brokerData);
    setIsVisibleModal(false);
    setIsLoadingExport(false);
  };

  const onExportSoldQuotes = async () => {
    setIsLoadingExport(true);
    const payload = {
      accountId,
      proposalId,
      type: 'sold',
    };

    const response = await dispatch(getRfpQuotes(payload));

    const executiveResponse = await dispatch(
      getExecutiveSummaryProposals({ accountId, proposalId }),
    );

    const executiveSummary = executiveResponse?.payload;

    const rfpQuotes = response?.payload;

    const account = {
      ...accountData,
      effective_date: accountProposalData?.effective_date,
    };

    onDownloadSoldQuotesXlsx(account, brokerData, accountProposalData, rfpQuotes, executiveSummary);
    setIsVisibleModal(false);
    setIsLoadingExport(false);
  };

  const onExportRequestedProposals = useCallback(async () => {
    setIsLoadingExport(true);

    const payload = {
      accountId,
      proposalId,
    };

    const response = await dispatch(getBrokerRequestedProposals(payload));

    const brokerProposals = response?.payload;

    const account = {
      ...accountData,
      effective_date: accountProposalData?.effective_date,
    };

    onDownloadBrokerRequestedProposalsXlsx(
      account,
      accountProposalData,
      brokerData,
      accountProposalData?.groups[0].members,
      brokerProposals,
    );

    setIsVisibleModal(false);
    setIsLoadingExport(false);
  }, [
    dispatch,
    setIsLoadingExport,
    accountId,
    proposalId,
    accountData,
    accountProposalData,
    brokerData,
  ]);

  const onExportToCSV = useCallback(async () => {
    const clientPickedQuote = responseQuotes.find((item) => item.status === 'approved');

    await exportRateToCsv(clientPickedQuote, setCsvHeader, setCsvBody);
    setIsVisibleModal(false);
  }, [responseQuotes]);

  const groupMembers = accountProposalData?.groups?.map((group) => group.members)[0];
  const fullProposalLink = `/broker/accounts/${accountId}/proposals/${proposalId}/full-proposal-download`;
  const employeeWorksheetLink = `/broker/accounts/${accountId}/proposals/${proposalId}/employee-worksheet-download`;
  const employeeWorksheetSoldLink = `/broker/accounts/${accountId}/proposals/${proposalId}/employee-worksheet-download?type=sold`;

  const renderDropdownItem = useCallback((func) => {
    return (
      <div className={classes.OfferTableDownloadItem} onClick={func}>
        <img src={DownloadXLSXIcon} alt={DownloadXLSXIcon} />
      </div>
    );
  }, []);

  return (
    <div className={classes.ExportButton}>
      {!isEdit && <Button title="Export" type="secondary" onClick={handleOpenDropdown} />}
      {isVisibleModal && (
        <Modal type="xlarge" onClose={handleOpenDropdown} title="Export" closeButtonType="inside">
          <div className={classes.OfferTable}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>Full Proposal</TableCell>
                    <TableCell>
                      This export is designed to show employer clients all selected quotes and
                      instant quotes, census information and group account details.{' '}
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(onExportToXLSX)}

                        <Link
                          to={fullProposalLink}
                          target="_blank"
                          className={classes.OfferTableDownloadItem}
                        >
                          <img src={DownloadPDFIcon} alt={DownloadPDFIcon} />
                        </Link>
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>
                      Employee Worksheet All
                    </TableCell>
                    <TableCell>
                      This exports a separate page for each employee showing all plans quoted.
                      Designed for employee level conversations
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(onExportRates)}
                        <Link
                          to={employeeWorksheetLink}
                          target="_blank"
                          className={classes.OfferTableDownloadItem}
                        >
                          <img src={DownloadPDFIcon} alt={DownloadPDFIcon} />
                        </Link>
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>
                      Employee Worksheet Sold
                    </TableCell>
                    <TableCell>
                      This exports a separate page for employee showing all plans marked with a
                      "Sold" status. This is designed for employee level conversations
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(() => onExportRates('sold'))}
                        <Link
                          to={employeeWorksheetSoldLink}
                          target="_blank"
                          className={classes.OfferTableDownloadItem}
                        >
                          <img src={DownloadPDFIcon} alt={DownloadPDFIcon} />
                        </Link>
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>Comparison</TableCell>
                    <TableCell>
                      This export is designed to compare selected plans side by side.{' '}
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(onExportRatesComparison)}
                        <span />
                      </span>
                    </TableCell>
                  </TableRow>
                  {isVisibleExport('approved') && (
                    <TableRow>
                      <TableCell className={classes.OfferTableTitle}>Sold Quotes</TableCell>
                      <TableCell>description</TableCell>
                      <TableCell>
                        <span className={classes.OfferTableDownload}>
                          {renderDropdownItem(onExportSoldQuotes)}
                          <span />
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>Requested RFPs</TableCell>
                    <TableCell>
                      This export is designed to be shared with carrier sales teams and underwriters
                      to help them quote. It provides a summary of all plans configurations being
                      requested and separate sheets for the account details and census information.
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(onExportRequestedProposals)}
                        <span />
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.OfferTableTitle}>Census</TableCell>
                    <TableCell>
                      This export provides the census as it is showing in the proposal. This can be
                      used for third party systems.
                    </TableCell>
                    <TableCell>
                      <span className={classes.OfferTableDownload}>
                        {renderDropdownItem(
                          onDownloadMembersXlsx(
                            groupMembers,
                            `${accountData?.company_name}-${accountProposalData?.name}-Members`,
                            () => {},
                          ),
                        )}
                        <CSVLink
                          data={csvMembersData}
                          className={classes.ExportButtonDropDownLink}
                          onClick={onDownloadMembersCsv(groupMembers, setCsvMembersData)}
                          filename={`${accountData?.company_name}-${accountProposalData?.name}-Members`}
                        >
                          <div className={classes.OfferTableDownloadItem}>
                            <img src={DownloadCSVIcon} alt={DownloadCSVIcon} />
                          </div>
                        </CSVLink>
                      </span>
                    </TableCell>
                  </TableRow>
                  {accountProposalData?.status === 'approved' && (
                    <TableRow>
                      <TableCell className={classes.OfferTableTitle}>Rate File</TableCell>
                      <TableCell>description</TableCell>
                      <TableCell>
                        <span className={classes.OfferTableDownload}>
                          <CSVLink
                            className={classes.ExportButtonDropDownLink}
                            onClick={onExportToCSV}
                            headers={csvHeader}
                            data={csvBody}
                            filename={`${accountProposalData?.name} Rate`}
                          >
                            <div className={classes.OfferTableDownloadItem}>
                              <img src={DownloadXLSXIcon} alt={DownloadXLSXIcon} />
                            </div>
                          </CSVLink>
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal>
      )}
    </div>
  );
};
