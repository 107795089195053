import React from 'react';

import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';
import { useDentalIssuerBuild } from './useDentalIssuerBuild';

export const DentalPlans = () => {
  const { build } = useDentalIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
