import React, { useCallback, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { auth } from 'src/app/database';

import { getBrokerData, setAccountData } from 'src/store/broker';
import {
  acceptOrganizationInvite,
  invitedAgencySelector,
  isInviteModalSelector,
  setInviteAgencyData,
  setIsInviteModal,
} from 'src/store/agency';
import { setAccountProposal } from 'src/store/proposals';
import { setGroupMembers } from 'src/store/groupMembers';

import { ROUTE } from 'src/constants/routes';

import { AccountTable, Button, Modal } from 'src/components';

import classes from './brokerAccounts.module.scss';

export const BrokerAccounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user] = useAuthState(auth);

  const [searchParams] = useSearchParams();

  const invite = searchParams.get('invite');

  const brokerData = useSelector(getBrokerData);

  const invitedAgencyData = useSelector(invitedAgencySelector);
  const isInviteModal = useSelector(isInviteModalSelector);

  useEffect(() => {
    document.title = 'Monark | Accounts';
  }, []);

  useEffect(() => {
    dispatch(setAccountData({}));
    dispatch(setAccountProposal({}));
    dispatch(setGroupMembers([]));
  }, [dispatch]);

  useEffect(() => {
    if (!user?.emailVerified && pathname !== '/verification') {
      navigate(`${ROUTE.EMAIL_VERIFICATION}${invite ? `?invite=${invite}` : ''}`);
    }
  }, [navigate, user, pathname, invite]);

  useEffect(() => {
    if (!_.isEmpty(brokerData)) {
      if (invite) {
        const payload = {
          invite_id: invite,
          navigate,
        };

        dispatch(acceptOrganizationInvite(payload));
      }
    }
  }, [dispatch, navigate, brokerData, invite]);

  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    if (isInviteModal) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isInviteModal, seconds]);

  useEffect(() => {
    if (isInviteModal && seconds === 0) {
      dispatch(setIsInviteModal(false));
      dispatch(setInviteAgencyData({}));
      return navigate(`/teams/${invitedAgencyData?.id}/accounts`);
    }
  }, [dispatch, navigate, isInviteModal, seconds, invitedAgencyData]);

  const onContinueClick = useCallback(() => {
    navigate(`/teams/${invitedAgencyData?.id}/accounts`);
    dispatch(setInviteAgencyData({}));
    dispatch(setIsInviteModal(false));
  }, [dispatch, navigate, invitedAgencyData]);

  const onCloseAcceptModal = useCallback(() => {
    dispatch(setInviteAgencyData({}));
    dispatch(setIsInviteModal(false));
  }, [dispatch]);

  return (
    <div className={classes.Broker}>
      {brokerData?.status === 'inactive' ? (
        <div className={classes.BrokerUpdateBilling}>
          Please update your
          <Link
            className={classes.BrokerUpdateBillingLink}
            to={`${ROUTE.BROKER_SETTIGNS}?isBilling=true`}
          >
            {` billing information `}
          </Link>
          to continue.
        </div>
      ) : (
        <>
          {isInviteModal && (
            <Modal closeButtonType="inside" onClose={onCloseAcceptModal}>
              <div className={classes.ModalContent}>
                <div className={classes.ModalContentTitle}>Invitation Accepted</div>
                <div className={classes.ModalContentSubTitle}>
                  Your invitation has been successfully accepted
                </div>
                <div className={classes.ModalContentAgencyName}>{invitedAgencyData?.name}</div>
                {true && (
                  <div className={classes.ModalContentSubTitle}>
                    <Button
                      type="primary"
                      title={`Continue ${seconds}`}
                      onClick={onContinueClick}
                    />
                  </div>
                )}
              </div>
            </Modal>
          )}
          <div className={classes.AccountTableWrapper}>
            <AccountTable />
          </div>
        </>
      )}
    </div>
  );
};
