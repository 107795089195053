import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.pet;

export const petInsurancePlansSelector = createSelector(
  getState,
  (state) => state.petInsurancePlans,
);
export const isLoadingPetInsurancePlansSelector = createSelector(
  getState,
  (state) => state.isLoadingPetInsurancePlans,
);

export const isLoadingCreateQuoteSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateQuote,
);

export const petInsuranceDataSelector = createSelector(getState, (state) => state.petInsuranceData);
export const isLoadingPetInsuranceDataSelector = createSelector(
  getState,
  (state) => state.isLoadingPetInsuranceData,
);
