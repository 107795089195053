import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.admin;

export const isLoadingAdminSigninSelector = createSelector(
  getState,
  (state) => state.isLoadingAdminSignin,
);

export const brokerUsersSelector = createSelector(getState, (state) => state.brokerUsers);
export const brokerUsersMetaSelector = createSelector(getState, (state) => state.brokerUsersMeta);

export const isLoadingBrokerUsersSelector = createSelector(
  getState,
  (state) => state.isLoadingBrokerUsers,
);

export const repUsersSelector = createSelector(getState, (state) => state.repUsers);
export const repUsersMetaSelector = createSelector(getState, (state) => state.repUsersMeta);

export const isLoadingRepUsersSelector = createSelector(
  getState,
  (state) => state.isLoadingRepUsers,
);
