import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  accountContactsSelector,
  createAccountContact,
  deleteAccountContact,
  isLoadingCreateContactSelector,
  isLoadingDeleteContactSelector,
  isLoadingUpdateContactSelector,
  updateAccountContact,
} from 'src/store/accountContacts';
import { emailRegex } from 'src/constants/regularExpression';

import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { Loader } from '../Loader/Loader';

import classes from './accountContacts.module.scss';

export const AccountContacts = (props) => {
  const { accountId } = props;

  const dispatch = useDispatch();

  const accountContacts = useSelector(accountContactsSelector);

  const isLoadingCreateContact = useSelector(isLoadingCreateContactSelector);
  const isLoadingUpdateContact = useSelector(isLoadingUpdateContactSelector);
  const isLoadingDeleteContact = useSelector(isLoadingDeleteContactSelector);

  const [createContactsData, setCreateContactsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedEditRow, setSelectedEditRow] = useState({});
  const [selectedDeleteId, setSelectedDeleteId] = useState('');

  const onClickAddContact = useCallback(() => {
    const payload = {
      id: uuid(),
      name: '',
      email: '',
      contact_type: 'cc',
    };

    setCreateContactsData((prev) => [...prev, payload]);
  }, []);

  const onChangeInput = useCallback(
    (event, rowField) => {
      const row = createContactsData.find((item) => item.id === selectedRow.id);
      row[rowField] = event.target.value;

      const payload = createContactsData.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj[rowField] === o[rowField])) {
          unique.push(o);
        }
        return unique;
      }, []);

      setCreateContactsData([...payload]);
    },
    [createContactsData, selectedRow.id],
  );

  const onChangeEditInput = useCallback(
    (event, target) => {
      const payload = {
        ...selectedEditRow,
        [target]: event.target.value,
      };

      setSelectedEditRow(payload);
    },
    [selectedEditRow],
  );

  const onSelectRow = useCallback(
    (row) => () => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const onDeleteRow = useCallback(
    (id) => () => {
      const payload = _.reject(createContactsData, (row) => row.id === id);

      setCreateContactsData(payload);
      setSelectedRow({});
    },
    [createContactsData],
  );

  const onClickEdit = useCallback(
    (item) => () => {
      setSelectedEditRow(item);
    },
    [],
  );

  const onClickDeleteContact = useCallback(
    (accountId, contactId) => () => {
      setSelectedDeleteId(contactId);
      dispatch(deleteAccountContact({ accountId, contactId }));
    },
    [dispatch],
  );

  const onClickSaveNewContact = useCallback(async () => {
    for (const item of createContactsData) {
      if (!item.name) {
        return toast('Name cannot be empty', { type: 'warning' });
      }

      if (!item.email) {
        return toast('Email Number cannot be empty', { type: 'warning' });
      }

      if (!item.email.match(emailRegex)) {
        return toast('Please, enter valid email', { type: 'warning' });
      }
    }

    const payload = {
      account_id: accountId,
      ...createContactsData[0],
    };

    delete payload.id;

    await dispatch(createAccountContact({ accountId: accountId, payload }));

    setCreateContactsData([]);
  }, [dispatch, createContactsData, accountId]);

  const onClickUpdateContact = useCallback(async () => {
    await dispatch(
      updateAccountContact({
        accountId: selectedEditRow?.account_id,
        contactId: selectedEditRow?.id,
        payload: selectedEditRow,
      }),
    );

    setSelectedEditRow({});
  }, [dispatch, selectedEditRow]);

  const onClickCancelRow = useCallback(() => {
    setSelectedEditRow({});
  }, []);

  return (
    <div className={classes.AccountContacts}>
      <div className={classes.AccountContactsBody}>
        <Paper>
          <TableContainer>
            <Table size="small" className={classes.AccountContactsTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Contact Email</TableCell>
                  <TableCell>Contact Type</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountContacts?.map((item) => {
                  const isEditMode = !_.isEmpty(selectedEditRow) && selectedEditRow.id === item.id;
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        {!isEditMode ? (
                          item?.name
                        ) : (
                          <Input
                            value={selectedEditRow.name || ''}
                            placeholder="eg. John Doe"
                            onChange={(event) => onChangeEditInput(event, 'name')}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!isEditMode ? (
                          item?.email
                        ) : (
                          <Input
                            value={selectedEditRow.email || ''}
                            placeholder="john.doe@mail.com"
                            onChange={(event) => onChangeEditInput(event, 'email')}
                          />
                        )}
                      </TableCell>
                      <TableCell>{item?.contact_type}</TableCell>
                      <TableCell>
                        {!isEditMode ? (
                          <span className={classes.AccountContactsBodyTableEdit}>
                            <Button
                              type="secondary"
                              title="Edit"
                              onClick={onClickEdit(item)}
                              isDisabled={isLoadingDeleteContact && item.id === selectedDeleteId}
                            />
                            {item.contact_type === 'cc' && (
                              <span className={classes.AccountContactsBodyTableDelete}>
                                {isLoadingDeleteContact && item.id === selectedDeleteId ? (
                                  <Loader size="small" />
                                ) : (
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={onClickDeleteContact(item?.account_id, item.id)}
                                  />
                                )}
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className={classes.AccountContactsBodyTableEdit}>
                            <Button
                              type="primary"
                              title="Save"
                              onClick={onClickUpdateContact}
                              isLoading={isLoadingUpdateContact}
                            />
                            <Button type="secondary" title="Cancel" onClick={onClickCancelRow} />
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {createContactsData?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Input
                        value={item.name || ''}
                        placeholder="eg. John Doe"
                        onFocus={onSelectRow(item)}
                        onChange={(event) => onChangeInput(event, 'name')}
                        isRequired
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        value={item.email || ''}
                        placeholder="john.doe@mail.com"
                        onFocus={onSelectRow(item)}
                        onChange={(event) => onChangeInput(event, 'email')}
                        isRequired
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <span className={classes.AccountContactsBodyTableEdit}>
                        <Button
                          type="primary"
                          title="Save"
                          onClick={onClickSaveNewContact}
                          isLoading={isLoadingCreateContact}
                        />
                        <i className="fa fa-trash-o" onClick={onDeleteRow(item?.id)} />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <div className={classes.AccountContactsBodyFooter}>
        {!createContactsData.length && (
          <Button title="Add Contact" type="primary" onClick={onClickAddContact} />
        )}
      </div>
    </div>
  );
};

AccountContacts.propTypes = {
  accountId: PropTypes.string,
};
