const getCroppedImg = async (src, pixelCrop, ratio = 1) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();

  canvas.width = 128;
  canvas.height = ratio === 1 ? 128 : 64;

  image.src = src;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        128,
        ratio === 1 ? 128 : 64,
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const blobURL = URL.createObjectURL(blob);
          resolve(blobURL);
        } else {
          reject(new Error('Error creating Blob'));
        }
      }, 'image/jpeg');
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
};

export default getCroppedImg;
