import React from 'react';

import { useVisionIssuerBuild } from './useVisionIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const VisionPlans = () => {
  const { build } = useVisionIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
