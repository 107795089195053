import React from 'react';

import { DashboardStatistic } from './components/DashboardStatistic/DashboardStatistic';
import { DashboardBrokers } from './components/DashboardBrokers/DashboardBrokers';
import { DashboardReps } from './components/DashboardReps/DashboardReps';
// import { DashboardUnresponsive } from './components/DashboardUnresponsive/DashboardUnresponsive';

import classes from './adminDashboard.module.scss';

export const AdminDashboard = () => {
  return (
    <div className={classes.AdminDashboard}>
      <DashboardStatistic />
      <DashboardBrokers />
      <DashboardReps />
      {/* <DashboardUnresponsive /> */}
    </div>
  );
};
