import React, { useState, useEffect, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { auth } from 'src/app/database';

import {
  getIsLoadingVerifyEmail,
  getIsVerifiedEmail,
  getUserTypeSelector,
  verifyEmailAddress,
} from 'src/store/app';

import { Button, Loader, LoaderWrapper } from 'src/components';

import MonarkLogo from 'src/assets/header/monarkLogo.svg';

import classes from './verifyEmail.module.scss';
import { ROUTE } from 'src/constants/routes';
import { getIssuerDataSelector } from 'src/store/issuer';

export const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user] = useAuthState(auth);

  const oobCode = searchParams.get('oobCode');
  const domain = new URL(window.location).origin;
  const continueUrl = decodeURIComponent(searchParams.get('continueUrl'));
  const replacedUrl = continueUrl?.replaceAll(domain, '') ?? '';

  const userType = useSelector(getUserTypeSelector);
  const issuerData = useSelector(getIssuerDataSelector);
  const isVerifiedEmail = useSelector(getIsVerifiedEmail);
  const isLoadingVerifyEmail = useSelector(getIsLoadingVerifyEmail);

  const isUser = !_.isEmpty(user);

  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    async function verifyEmail() {
      await dispatch(verifyEmailAddress({ oobCode, navigate }));
    }

    verifyEmail();
  }, [dispatch, navigate, oobCode]);

  useEffect(() => {
    if (isVerifiedEmail) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [isVerifiedEmail, seconds]);

  const onContinueClick = useCallback(() => {
    if (isUser) {
      if (userType === 'broker') {
        return navigate(replacedUrl ? replacedUrl : ROUTE.BROKER);
      }
      if (userType === 'rep') {
        return navigate(replacedUrl ? replacedUrl : `/issuer/${issuerData?.issuer_id}/rfp`);
      }
    }

    if (!isUser) {
      return navigate(ROUTE.LOGIN);
    }
  }, [isUser, issuerData, navigate, replacedUrl, userType]);

  useEffect(() => {
    if (isVerifiedEmail && seconds === 0) {
      onContinueClick();
    }
  }, [isVerifiedEmail, onContinueClick, seconds]);

  return (
    <div className={classes.VerifyEmail}>
      <div className={classes.VerifyEmailCard}>
        <div className={classes.VerifyEmailLogo}>
          <img src={MonarkLogo} alt="loginMonarkLogo" />
        </div>
        {isLoadingVerifyEmail ? (
          <div className={classes.LoaderWrapper}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </div>
        ) : (
          <div className={classes.VerifyEmailContent}>
            <div className={classes.VerifyEmailContentTitle}>Email Verified</div>
            <div className={classes.VerifyEmailContentSubTitle}>
              Your email has been successfully verified
            </div>
            <div className={classes.VerifyEmailContentEmail}>{user?.email}</div>
            {isVerifiedEmail && (
              <div className={classes.VerifyEmailContentSubTitle}>
                <Button type="primary" title={`Continue ${seconds}`} onClick={onContinueClick} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={classes.VerifyEmailCopyright}>
        <i className="far fa-copyright" />
        {moment().year()} Monark
      </div>
    </div>
  );
};
