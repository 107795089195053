import Base from './base';

const allowedCensusFields = [
  'ee_id',
  'first_name',
  'last_name',
  'zip_code',
  'date_of_birth',
  'gender',
  'relationship',
  'is_tobacco_user',
  'salary_amount',
  'job_title',
  'current_medical_plan_name',
  'dental_plan_name',
  'vision_plan_name',
  'life_benefit_amount',
  'group_id',
];

class GroupMembers extends Base {
  createMemberGroup = (payload) => {
    const { accountId, name } = payload;

    const payloadData = {
      name,
      account_id: accountId,
    };

    return this.apiClient.post(`accounts/${accountId}/groups`, payloadData);
  };

  createGroupMembers = (payload) => {
    const { accountId, groupId, members } = payload;

    const updatedMembers = members?.map((member) => {
      const keys = Object.keys(member);

      let allowedFields = {};

      for (const field of allowedCensusFields) {
        allowedFields[field] = member[field] ?? '';
      }

      let payload = { ...allowedFields };

      for (const key of keys) {
        if (!payload[key]) {
          delete payload[key];
        }
      }

      return payload;
    });

    return this.apiClient.post(
      `accounts/${accountId}/groups/${groupId}/members/bulk`,
      updatedMembers,
    );
  };

  updateMembersCensus = (payload) => {
    const { accountId, groupId, members } = payload;

    const updatedMembers = members?.map((member) => {
      const keys = Object.keys(member);

      let allowedFields = {};

      for (const field of allowedCensusFields) {
        allowedFields[field] = member[field] ?? '';
      }

      let payload = { ...allowedFields };

      for (const key of keys) {
        if (!payload[key]) {
          delete payload[key];
        }
      }

      return payload;
    });

    return this.apiClient.post(
      `accounts/${accountId}/groups/${groupId}/members/bulk`,
      updatedMembers,
    );
  };

  createCensusMember = (accountId, groupId, payload) => {
    return this.apiClient.post(`accounts/${accountId}/groups/${groupId}/members`, payload);
  };

  updateCensusMember = (accountId, groupId, memberId, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/groups/${groupId}/members/${memberId}`,
      payload,
    );
  };

  deleteCensusMember = (accountId, groupId, memberId) => {
    return this.apiClient.delete(`accounts/${accountId}/groups/${groupId}/members/${memberId}`);
  };

  getGroupMembers = (accountId, groupId) => {
    return this.apiClient.get(`accounts/${accountId}/groups/${groupId}/members`);
  };
}

export default GroupMembers;
