import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import ArrowIcon from 'src/assets/broker/arrow.svg';

import classes from './dropdownComponent.module.scss';

export const DropdownComponent = (props) => {
  const { title, children } = props;

  const [isVisibleContent, setIsVisibleContent] = useState(true);

  const handleClickDropdown = useCallback(() => {
    setIsVisibleContent((prev) => !prev);
  }, []);

  const iconStyles = classnames({
    [classes.IconActive]: isVisibleContent,
    [classes.IconDisabled]: !isVisibleContent,
  });

  return (
    <div className={classes.Dropdown}>
      <div className={classes.DropdownHeader}>
        <div onClick={handleClickDropdown} className={classes.DropdownHeaderIcon}>
          <img className={iconStyles} src={ArrowIcon} alt="DropdownArrowIcon" />
        </div>
        <div className={classes.DropdownHeaderTitle}>{title}</div>
      </div>
      {isVisibleContent && <div className={classes.DropdownContent}>{children}</div>}
    </div>
  );
};
