import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './loader.module.scss';

export const Loader = (props) => {
  const { size = 'default', color } = props;

  const loaderStyles = classnames({
    [classes.Loader]: size === 'default',
    [classes.LoaderSmall]: size === 'small',
  });

  return (
    <div className={loaderStyles} style={{ borderTopColor: color, borderRightColor: color }} />
  );
};

export const LoaderWrapper = (props) => {
  const { children } = props;

  return <div className={classes.LoaderWrapper}>{children}</div>;
};

Loader.propTypes = {
  size: PropTypes.string,
};
