import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearVisionConfigurationState } from './visionConfiguration.slice';

export const getVisionPlans = createAsyncThunk(
  'vision-configuration/get-vision-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getVisionPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getVisionQuotePlans = createAsyncThunk(
  'vision-configuration/get-vision-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getVisionQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearVisionState = createAsyncThunk('vision/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearVisionConfigurationState());
});
