import { Text, View } from '@react-pdf/renderer';

import { inNetworkRegex } from 'src/constants/regularExpression';

import { styles } from '../styles';

export const renderRow = (rowTitle, rowValue) => {
  return (
    <View style={styles.rowWrapper}>
      <Text style={styles.rowTitle}>{rowTitle ? `${rowTitle}:` : ''}</Text>
      <Text style={styles.rowValue}>{rowValue}</Text>
    </View>
  );
};

export const renderTableRow = (title, data, value, regex, symbol) => {
  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={[styles.tableCell, styles.tableTitle]}>{title}</Text>
      </View>
      {data?.map((item, index) => {
        let rowValue;

        if (regex) {
          rowValue = item[value]?.match(inNetworkRegex)[1];
        }

        if (!regex) {
          rowValue = `${symbol === '$' ? '$' : ''}${item[value] ?? 'None Available'}${
            symbol === '%' ? '%' : ''
          }`;
        }

        return (
          <View
            style={[styles.tableCol, index === data.length - 1 ? styles.lastTableCol : {}]}
            key={item.id}
          >
            <Text style={styles.tableCell}>{rowValue}</Text>
          </View>
        );
      })}
    </View>
  );
};

export const renderTableValue = (item, index, section, value) => {
  return (
    <View
      style={[styles.tableCol, index === section.length - 1 ? styles.lastTableCol : {}]}
      key={item.id}
    >
      <Text style={styles.tableCell}>{value}</Text>
    </View>
  );
};
