import _ from 'lodash';
export const prepareCustomFields = (data) => {
  const clonedData = _.cloneDeep(data);
  const maxLength = Math.max(...clonedData.map((item) => item?.custom_fields?.length));

  clonedData.forEach((item) => {
    if (_.isNull(item.custom_fields)) {
      item.custom_fields = [];
    }

    const missingCount = maxLength - item?.custom_fields?.length;

    item.custom_fields =
      item?.custom_fields?.concat(
        Array(!_.isNaN(missingCount) ? missingCount : 0).fill({ value: '' }),
      ) ?? [];
  });

  return clonedData;
};
