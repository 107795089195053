import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from './menuSubItem.module.scss';

export const MenuSubItem = (props) => {
  const {
    title,
    routeTo,
    onClick,
    isActive,
    isLink = true,
    isVisibleStatus = false,
    isDisabled = false,
  } = props;

  // TODO rework that

  return isLink ? (
    <Link
      className={isActive ? classes.MenuSubItemActive : classes.MenuSubItem}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      to={isDisabled ? '#' : routeTo}
    >
      <div className={classes.MenuSubItemTitle}>
        {title} {isVisibleStatus && <div className={classes.MenuSubItemStatus} />}
      </div>
      <div className={isActive ? classes.MenuSubItemSelected : classes.MenuSubItemSelectedHidden} />
    </Link>
  ) : (
    <div
      className={isActive ? classes.MenuSubItemActive : classes.MenuSubItem}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={isDisabled ? () => {} : onClick}
    >
      <div className={classes.MenuSubItemTitle}>{title}</div>
      <div className={isActive ? classes.MenuSubItemSelected : classes.MenuSubItemSelectedHidden} />
    </div>
  );
};

MenuSubItem.propTypes = {
  title: PropTypes.string.isRequired,
  routeTo: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isLink: PropTypes.bool,
  isVisibleStatus: PropTypes.bool,
};
