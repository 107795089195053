import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';

import api from 'src/app/apiSingleton';
import { setBrokerContactsMeta } from './contacts.slice';

export const getAllBrokerContacts = createAsyncThunk(
  'contacts/get-broker-contacts',
  async (data, thunkApi) => {
    try {
      const { id, page, per_page, sort } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        'f[broker_id]': id,
        page,
        per_page,
        sort,
      };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.contacts.getAllBrokerContacts(id, payload);

      thunkApi.dispatch(setBrokerContactsMeta(response.meta));

      return response.items;
    } catch (err) {
      console.error(err);

      return [];
    }
  },
);

export const createBrokerContact = createAsyncThunk(
  'contacts/create-broker-contact',
  async (data, thunkApi) => {
    try {
      const { broker_id, selectedOption } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      let issuer_id = '';

      if (selectedOption === 'custom') {
        const emailDomain = data?.email.split('@')[1];
        const issuerPayload = {
          name: data?.issuer_name,
          email_domains: [emailDomain],
        };

        const issuerResponse = await api.issuer.createIssuerAccount(issuerPayload);

        issuer_id = issuerResponse?.id;
      }

      const updatedPayload = {
        ...data,
        issuer_id: data?.issuer_id ? data?.issuer_id : issuer_id,
      };

      await api.contacts.createBrokerContact(broker_id, updatedPayload);

      thunkApi.dispatch(getAllBrokerContacts(broker_id));

      toast('You have been successfully added new contact!', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateBrokerContact = createAsyncThunk(
  'contacts/update-broker-contact',
  async (data, thunkApi) => {
    try {
      const { broker_id, id, team_id } = data;

      delete data?.team_id;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.contacts.updateBrokerContact(broker_id, id, data);

      if (!team_id) {
        thunkApi.dispatch(getAllBrokerContacts(broker_id));
      }

      if (team_id) {
        thunkApi.dispatch(getAllAgencyContacts({ id: team_id }));
      }

      toast('You have been successfully updated contact!', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteBrokerContact = createAsyncThunk(
  'contacts/delete-broker-contact',
  async (data, thunkApi) => {
    try {
      const { brokerId, contactId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.contacts.deleteBrokerContact(brokerId, contactId);

      thunkApi.dispatch(getAllBrokerContacts(brokerId));

      toast('Deleted', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const getCarrierContacts = createAsyncThunk(
  'contacts/get-carrier-contacts',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const response = await api.contacts.getCarrierContacts(data);

      return response;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getAllAgencyContacts = createAsyncThunk(
  'contacts/get-agency-contacts',
  async (data, thunkApi) => {
    try {
      const { id, page, per_page, sort } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        page,
        per_page,
        sort,
      };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.contacts.getAllAgencyContacts(id, payload);

      thunkApi.dispatch(setBrokerContactsMeta(response.meta));

      return response.items;
    } catch (err) {
      console.error(err);

      return [];
    }
  },
);

export const createAgencyContact = createAsyncThunk(
  'contacts/create-agency-contact',
  async (data, thunkApi) => {
    try {
      const { org_id, selectedOption } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      let issuer_id = '';

      if (selectedOption === 'custom') {
        const emailDomain = data?.email.split('@')[1];
        const issuerPayload = {
          name: data?.issuer_name,
          email_domains: [emailDomain],
        };

        const issuerResponse = await api.issuer.createIssuerAccount(issuerPayload);

        issuer_id = issuerResponse?.id;
      }

      const updatedPayload = {
        ...data,
        issuer_id: data?.issuer_id ? data?.issuer_id : issuer_id,
        min_group_size: 1,
        max_group_size: 100,
      };

      await api.contacts.createAgencyContact(org_id, updatedPayload);

      thunkApi.dispatch(getAllAgencyContacts({ id: org_id }));

      toast('You have been successfully added new contact!', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateAgencyContact = createAsyncThunk(
  'contacts/update-agency-contact',
  async (data, thunkApi) => {
    try {
      const { org_id, id } = data;

      delete data?.team_id;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.contacts.updateAgencyContact(org_id, id, data);

      thunkApi.dispatch(getAllAgencyContacts({ id: org_id }));

      toast('You have been successfully updated contact!', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteAgencyContact = createAsyncThunk(
  'contacts/delete-agency-contact',
  async (data, thunkApi) => {
    try {
      const { org_id, contactId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.contacts.deleteAgencyContact(org_id, contactId);

      thunkApi.dispatch(getAllAgencyContacts({ id: org_id }));

      toast('Deleted', { type: 'success' });
    } catch (err) {
      console.error(err);
    }
  },
);

export const getUserByContactEmail = createAsyncThunk(
  'contacts/get-user-by-contact-email',
  async (data) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.contacts.getUserByContactEmail(data);

      return response?.items[0];
    } catch (err) {
      console.error(err);
    }
  },
  [],
);
