import { createSlice } from '@reduxjs/toolkit';

import {
  createCensusMember,
  createGroupMembers,
  createProposalCensus,
  deleteCensusMember,
  getGroupMembers,
  updateCensusMember,
} from './groupMembers.actions';

const initialState = {
  groupData: {},
  groupMembers: [],
  isLoadingCreateCensus: false,

  isLoadingGroupMembers: false,
  isLoadingCreateGroupMembers: false,

  isLoadingCreateCensusMember: false,
  isLoadingUpdateCensusMember: false,
  isLoadingDeleteCensusMember: false,
};

const groupMembersSlice = createSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    setGroupMembers: (state, action) => {
      state.groupMembers = action.payload;
    },
    setGroupData: (state, action) => {
      state.groupData = action.payload;
    },

    clearGroupMembersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createProposalCensus.pending, (state) => {
      state.isLoadingCreateCensus = true;
    });
    builder.addCase(createProposalCensus.fulfilled, (state) => {
      state.isLoadingCreateCensus = false;
    });
    builder.addCase(createProposalCensus.rejected, (state) => {
      state.isLoadingCreateCensus = false;
    });

    builder.addCase(createGroupMembers.pending, (state) => {
      state.isLoadingCreateGroupMembers = true;
    });
    builder.addCase(createGroupMembers.fulfilled, (state, action) => {
      state.groupMembers = action.payload;
      state.isLoadingCreateGroupMembers = false;
    });
    builder.addCase(createGroupMembers.rejected, (state) => {
      state.isLoadingCreateGroupMembers = false;
    });

    builder.addCase(getGroupMembers.pending, (state) => {
      state.isLoadingGroupMembers = true;
    });
    builder.addCase(getGroupMembers.fulfilled, (state, action) => {
      state.groupMembers = action.payload;
      state.isLoadingGroupMembers = false;
    });
    builder.addCase(getGroupMembers.rejected, (state) => {
      state.isLoadingGroupMembers = false;
    });

    builder.addCase(createCensusMember.pending, (state) => {
      state.isLoadingCreateCensusMember = true;
    });
    builder.addCase(createCensusMember.fulfilled, (state) => {
      state.isLoadingCreateCensusMember = false;
    });
    builder.addCase(createCensusMember.rejected, (state) => {
      state.isLoadingCreateCensusMember = false;
    });

    builder.addCase(updateCensusMember.pending, (state) => {
      state.isLoadingUpdateCensusMember = true;
    });
    builder.addCase(updateCensusMember.fulfilled, (state) => {
      state.isLoadingUpdateCensusMember = false;
    });
    builder.addCase(updateCensusMember.rejected, (state) => {
      state.isLoadingUpdateCensusMember = false;
    });

    builder.addCase(deleteCensusMember.pending, (state) => {
      state.isLoadingDeleteCensusMember = true;
    });
    builder.addCase(deleteCensusMember.fulfilled, (state) => {
      state.isLoadingDeleteCensusMember = false;
    });
    builder.addCase(deleteCensusMember.rejected, (state) => {
      state.isLoadingDeleteCensusMember = false;
    });
  },
});

export const { setGroupMembers, setGroupData, clearGroupMembersState } = groupMembersSlice.actions;

export default groupMembersSlice.reducer;
