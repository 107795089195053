import React from 'react';

import { useAccidentIssuerBuild } from './useAccidentIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const AccidentPlans = () => {
  const { build } = useAccidentIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
