import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
} from '@mui/material';
import _ from 'lodash';

import { getAccountProposalSelector } from 'src/store/proposals';

import { AddContacts } from 'src/features/broker/addContacts/AddContacts';

import { BrokerContactsTable, Button, Loader, LoaderWrapper } from 'src/components';
import { Tabs } from 'src/components/Tabs/Tabs';
import { TabItem } from 'src/components/Tabs/TabItem';

import {
  isLoadingContactsProposalRequestSelector,
  resendContactsProposalRequest,
} from 'src/store/planConfiguration';
import {
  createAgencyContact,
  createBrokerContact,
  getBrokerContactsMetaSelector,
  getBrokerContactsSelector,
  isLoadingBrokerContactsSelector,
} from 'src/store/contacts';
import { getMedicalPlansSelector } from 'src/store/medicalConfiguration';
import { getDentalPlanSelector } from 'src/store/dentalConfiguration';
import { getVisionPlansSelector } from 'src/store/visionConfiguration';
import { getLifePlansSelector } from 'src/store/lifeConfiguration';
import { getDisabilityPlansSelector } from 'src/store/disabilityConfiguration';
import { getAccidentPlansSelector } from 'src/store/accidentCnfiguration';
import { getHospitalPlansSelector } from 'src/store/hospitalConfiguration';
import { getCriticalPlansSelector } from 'src/store/criticalConfiguration';
import { getBrokerData } from 'src/store/broker';

import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './modalContent.module.scss';

const headerRows = [
  { id: 'display_name', name: 'Rep Name', sortable: true },
  { id: 'issuer_name', name: 'Carrier', sortable: true },
  { id: '1', name: '', sortable: false },
  { id: 'medical', name: 'Medical', sortable: false },
  { id: 'dental', name: 'Dental', sortable: false },
  { id: 'vision', name: 'Vision', sortable: false },
  { id: 'life', name: 'Life', sortable: false },
  { id: 'disability', name: 'Disability', sortable: false },
  { id: 'accident', name: 'Accident', sortable: false },
  { id: 'hospital', name: 'Hospital', sortable: false },
  { id: 'critical_illness', name: 'Critical Illness', sortable: false },
  { id: '2', name: '', sortable: false },
];

export const ModalContent = (props) => {
  const {
    contactsProductLines,
    originalContactsData,
    onSelectContactsMedicalType,
    onRemoveContactsMedicalType,
    sortMethod,
    setSortMethod,
    onSendMedicalData,
    currentPage,
    selectedPageSize,
    onChangePage,
    onChangePageSize,
    selectedCell,
    setSelectedCell,
    cellDirection,
    setCellDirection,
  } = props;
  const dispatch = useDispatch();

  const accountProposalData = useSelector(getAccountProposalSelector);

  const params = useParams();
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const org_id = params?.teamId;

  const brokerData = useSelector(getBrokerData);

  const contacts = useSelector(getBrokerContactsSelector);
  const contactsMeta = useSelector(getBrokerContactsMetaSelector);

  const medicalPlans = useSelector(getMedicalPlansSelector);
  const dentalPlans = useSelector(getDentalPlanSelector);
  const visionPlans = useSelector(getVisionPlansSelector);
  const lifePlans = useSelector(getLifePlansSelector);
  const disabilityPlans = useSelector(getDisabilityPlansSelector);
  const accidentPlans = useSelector(getAccidentPlansSelector);
  const hospitalPlans = useSelector(getHospitalPlansSelector);
  const criticalPlans = useSelector(getCriticalPlansSelector);

  const isLoadingBrokerContacts = useSelector(isLoadingBrokerContactsSelector);
  const isLoadingSendAll = useSelector(isLoadingContactsProposalRequestSelector);

  const [isVisibleContacts] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedTab, setSelectedTab] = useState('recepients');

  const onSendSingleContactsData = useCallback(
    (id) => async () => {
      const payload = {
        [id]: contactsProductLines[id],
      };

      setSelectedId(id);

      await dispatch(
        resendContactsProposalRequest({ accountId, proposalId, payload, setIsLoading }),
      );
    },
    [dispatch, contactsProductLines, accountId, proposalId],
  );

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  const onClickCopyIcon = useCallback(
    (value) => () => {
      navigator.clipboard.writeText(value);
      toast('Email is copied to clipboard', { type: 'success' });
    },
    [],
  );

  const onClickTab = useCallback(
    (tab) => () => {
      setSelectedTab(tab);
    },
    [],
  );

  const onClickAddContact = useCallback(
    (item) => async () => {
      let payload = {
        email: item.email,
        issuer_name: item.issuer_name,
        broker_id: brokerData.id,
        contact_name: item.display_name,
        issuer_id: item.issuer_id,
      };

      if (!org_id) {
        await dispatch(createBrokerContact(payload));
      }

      if (org_id) {
        await dispatch(createAgencyContact({ org_id, ...payload }));
      }

      setSelectedTab('recepients');
    },
    [dispatch, brokerData, org_id],
  );

  const renderTableItem = useCallback(
    (plans, item, type) => {
      return (
        <TableCell component="th" scope="row">
          {plans?.length > 0 && (
            <>
              {contactsProductLines[item?.rep_id]?.includes(type) ? (
                <img
                  src={CheckedPurpleCircle}
                  alt="CheckedPurpleCircle"
                  onClick={onRemoveContactsMedicalType(item?.rep_id, type)}
                  className={classes.ModalContentTableCheckIcon}
                />
              ) : (
                <img
                  src={PurpleCircle}
                  alt="PurpleCircle"
                  onClick={onSelectContactsMedicalType(item?.rep_id, type)}
                  className={classes.ModalContentTableCheckIcon}
                />
              )}
            </>
          )}
        </TableCell>
      );
    },
    [contactsProductLines, onRemoveContactsMedicalType, onSelectContactsMedicalType],
  );

  return (
    <div className={classes.ModalContent}>
      <div className={classes.ModalContentHeader}>
        Select {selectedTab === 'recepients' ? 'Recipients' : 'Carrier'}
      </div>
      <div className={classes.ModalContentTabs}>
        <Tabs type="underline">
          <TabItem
            title="Recipients"
            onClick={onClickTab('recepients')}
            isActive={selectedTab === 'recepients'}
            type="underline"
          />
          <TabItem
            title="Community Contacts"
            onClick={onClickTab('add-carrier')}
            isActive={selectedTab === 'add-carrier'}
            type="underline"
          />
        </Tabs>
      </div>
      {selectedTab === 'recepients' && (
        <>
          <div className={classes.ModalContentBody}>
            <Paper>
              {isLoadingBrokerContacts ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : (
                <TableContainer sx={{ maxHeight: '500px' }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      {headerRows?.map((item) => (
                        <TableCell key={item.id}>
                          {item.sortable ? (
                            <TableSortLabel
                              active={selectedCell === item.id}
                              direction={cellDirection}
                              onClick={onChangeCellSort(item.id)}
                            >
                              {item.name}
                            </TableSortLabel>
                          ) : (
                            item.name
                          )}
                        </TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {contacts?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            {item.display_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.issuer_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Tooltip title={item.email}>
                              <i
                                className={`${classes.ModalContentCloneIcon} fa fa-clone`}
                                onClick={onClickCopyIcon(item.email)}
                              />
                            </Tooltip>
                          </TableCell>
                          {renderTableItem(medicalPlans, item, 'medical')}
                          {renderTableItem(dentalPlans, item, 'dental')}
                          {renderTableItem(visionPlans, item, 'vision')}
                          {renderTableItem(lifePlans, item, 'life')}
                          {renderTableItem(disabilityPlans, item, 'disability')}
                          {renderTableItem(accidentPlans, item, 'accident')}
                          {renderTableItem(hospitalPlans, item, 'hospital')}
                          {renderTableItem(criticalPlans, item, 'critical_illness')}
                          <TableCell>
                            {!_.isEqual(
                              contactsProductLines[item?.rep_id],
                              originalContactsData[item?.rep_id],
                            ) && (
                              <Button
                                title="Save"
                                type="primary"
                                onClick={onSendSingleContactsData(item?.rep_id)}
                                isLoading={item.rep_id === selectedId ? isLoading : false}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                component="div"
                count={contactsMeta?.total ?? 0}
                rowsPerPage={selectedPageSize}
                page={currentPage - 1}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangePageSize}
              />
            </Paper>
          </div>
          {isVisibleContacts && <AddContacts isBodyScroll={false} />}
          <div className={classes.ModalContentFooter}>
            <Button
              title="Send All"
              type="primary"
              onClick={onSendMedicalData}
              isLoading={isLoadingSendAll}
            />
          </div>
        </>
      )}

      {selectedTab === 'add-carrier' && (
        <div className={classes.ModalContentBrokerContacts}>
          <BrokerContactsTable onClickAdd={onClickAddContact} mode="share" />
        </div>
      )}
    </div>
  );
};
