import { currencyFormatter } from '../currency';

export * from './medical/medicalOptions';
export * from './vision/visionOptions';
export * from './dental/dentalOptions';
export * from './life/lifeOptions';
export * from './disability/disabilityOptions';
export * from './accident/accidentOptions';
export * from './hospital/hospitalOptions';
export * from './critical/criticalOptions';

export const compMethodOptions = [
  { title: 'Levelized', value: 'levelized' },
  { title: 'Heaped', value: 'heaped' },
];

export const formattedValue = (value) => {
  return currencyFormatter(value).replace('.00', '');
};
