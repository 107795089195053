import React from 'react';

import { IssuerRequestsTable } from '../issuerRequests/components';

import classes from './issuerMyRequests.module.scss';

export const IssuerMyRequests = () => {
  return (
    <div className={classes.IssuerMyRequests}>
      <div className={classes.IssuerMyRequestsContent}>
        <IssuerRequestsTable />
      </div>
    </div>
  );
};
