import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { getIssuerCoveragePlans, issuerCoverageSelector } from 'src/store/issuerCoverageBuilder';

import { COVERAGE_NAMES } from '../coverageBuilder/cb_constants';

import { EmptyField, Loader, LoaderWrapper } from 'src/components';
import { IssuerPlanCard } from './IssuerPlanCard/IssuerPlanCard';

import classes from 'src/features/coverageBuilder/coverageBuilder.module.scss';

export const IssuerCoverageBuilder = (props) => {
  const { build } = props;

  const { coverage, title } = build;

  const dispatch = useDispatch();

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const coverageData = useSelector(issuerCoverageSelector);

  const [isLoadingPlans, setIsLoadingPlans] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (accountId && proposalId) {
      const fetchProposalsList = async () => {
        if (!isMounted) return;

        setIsLoadingPlans(true);
        try {
          await dispatch(getIssuerCoveragePlans({ accountId, proposalId, coverage }));
        } catch (error) {
          if (isMounted) setIsLoadingPlans(false);
        } finally {
          if (isMounted) setIsLoadingPlans(false);
        }
      };

      fetchProposalsList();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, accountId, proposalId, coverage]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={classes.CoverageBuilderTitle}
      >
        {title} Plans
      </AccordionSummary>
      <AccordionDetails>
        {isLoadingPlans ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : coverageData[`${coverage}_plans`]?.length ? (
          coverageData[`${coverage}_plans`]?.map((plan) => (
            <IssuerPlanCard key={plan.id} planId={plan.id} build={build} config={plan} />
          ))
        ) : (
          <EmptyField title={`No ${COVERAGE_NAMES[coverage]} RFP created yet.`} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
