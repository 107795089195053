import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.planConfiguration;

export const getDentalIssuersSelector = createSelector(getState, (state) => state.dentalIssuers);

export const getSelectedDentalPlansSelector = createSelector(
  getState,
  (state) => state.selectedDentalPlans,
);

export const isLoadingDentalIssuers = createSelector(
  getState,
  (state) => state.isLoadingDentalIssuers,
);

export const isLoadingProposalRequest = createSelector(
  getState,
  (state) => state.isLoadingProposalRequest,
);

export const isLoadingContactsProposalRequestSelector = createSelector(
  getState,
  (state) => state.isLoadingContactsProposalRequest,
);

export const getSharedCarriersSelector = createSelector(getState, (state) => state.sharedCarriers);
export const getSharedContactsSelector = createSelector(getState, (state) => state.sharedContacts);

export const isLoadingResendContactSelector = createSelector(
  getState,
  (state) => state.isLoadingResendContact,
);

export const isShareModalSelector = createSelector(getState, (state) => state.isShareModal);
export const isLoadingSharedPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingSharedPlans,
);

export const requestedProposalsStatusSelector = createSelector(
  getState,
  (state) => state.requestedProposalsStatus,
);
export const isLoadingRequstedProposalsStatusSelector = createSelector(
  getState,
  (state) => state.isLoadingRequstedProposalsStatus,
);
